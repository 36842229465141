import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";

@Component({
  selector: "record-recipient-card",
  templateUrl: "./recipient-card.component.html",
  styleUrls: ["./recipient-card.component.scss"],
})
export class RecipientCardComponent implements OnInit, OnDestroy {
  @Input() isInvalid: boolean = false;
  @Input() recipient: RecordRecipientModel;
  @Input() showAvatar: boolean = true;
  @Input() showEmail: boolean = false;
  @Input() showPhone: boolean = false;
  @Input() who: "self" | "other" = "other";

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
