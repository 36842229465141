import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "inertHtml",
})
export class InertHtmlPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    // Regular expression to match interactive attributes (tabindex and contenteditable) in any HTML tag
    const interactiveAttributesRegex = /<[^>]+\s(?:tabindex|contenteditable)="[^"]*"/gi;
    // Remove all occurrences of interactive attributes
    const sanitizedValue = value.replace(interactiveAttributesRegex, (match) =>
      match.replace(/\s(?:tabindex|contenteditable)="[^"]*"/g, ""),
    );
    // Add tabindex="-1" attribute to anchor tags
    const inertHtml = sanitizedValue.replace(/<a\b/gi, '<a tabindex="-1"');

    return inertHtml;
  }
}
