<ng-container *ngIf="session$ | async as session">
  <!-- Template -->
  <ng-template #sessionUnitCardTemplate>
    <session-unit-card [unit]="session.unit" [userRole]="session.userRole"></session-unit-card>
  </ng-template>
  <!-- Unit Selection Button -->
  <p-button styleClass="p-button-outlined surface-border p-button-sm p-1" (click)="handleClick($event)">
    <ng-template pTemplate="content">
      <ng-container *ngIf="themingService.avatarUrl$ | async; else sessionUnitCardTemplate">
        <div class="flex align-items-center">
          <ng-template #unitImage>
            <img
              width="40px"
              height="40px"
              [src]="(themingService.avatarUrl$ | async) ?? '/assets/images/logo-icon.svg'"
              class="logo-small"
              alt=""
            />
          </ng-template>

          <ui-user-avatar *ngIf="session.unit.isPublic; else unitImage" [user]="session?.user"></ui-user-avatar>
          <span class="ml-2 font-medium text-color clamp hidden lg:block" style="text-wrap: nowrap">{{
            session.unit.name
          }}</span>
        </div>
      </ng-container>
      <i class="pi pi-angle-down text-color-secondary ml-1 lg:ml-2"></i>
    </ng-template>
  </p-button>

  <!-- Units overlay panel -->
  <p-overlayPanel styleClass="unit-menu" [style]="themingService.customStyles$ | async">
    <ng-template pTemplate="content">
      <ng-container *ngIf="groupedUnits$ | async as units">
        <div class="units-menu__container">
          <div class="units-menu__list">
            <ng-container *ngFor="let userUnit of units">
              <header class="unit-category">
                <span class="clamp">{{
                  userUnit.unit.isPublic
                    ? authorizationService.isGuest()
                      ? ("COMMON.nestor" | translate : { default: "Nestor" })
                      : ("SESSION.unit-menu.personal.header" | translate : { default: "Personal space" })
                    : userUnit.unit.name
                }}</span>
                <button
                  pButton
                  class="p-button-text p-button-sm p-button-secondary"
                  style="--p-button-width: 2.28rem; --p-button-height: 2.28rem"
                  *ngIf="authorizationService.canManageUnit(userUnit)"
                  icon="pi pi-ellipsis-v"
                  type="button"
                  (click)="showOrganizationMenu($event, userUnit)"
                ></button>
              </header>
              <session-unit-menu-item
                [userUnit]="userUnit"
                (onClick)="handleUnitClick($event)"
                (onShowWorkspaceOptions)="handleWorkspaceMenu($event, userUnit)"
              ></session-unit-menu-item>
              <ng-container *ngIf="userUnit?.children?.length > 0">
                <session-unit-menu-item
                  *ngFor="let childUserUnit of userUnit.children"
                  [userUnit]="childUserUnit"
                  (onClick)="handleUnitClick($event)"
                  (onShowWorkspaceOptions)="handleWorkspaceMenu($event, childUserUnit)"
                ></session-unit-menu-item>
              </ng-container>
            </ng-container>
          </div>
          <!-- CREATE NEW ORGANIZATION -->
          <footer class="units-menu__footer" *ngIf="authorizationService.canCreateOrganization()">
            <p-button
              type="button"
              (click)="handleCreateOrganization()"
              styleClass="p-button-sm p-button-tertiary w-full"
            >
              <ng-template pTemplate="content">
                <div class="flex justify-content-center w-full font-medium">
                  <i class="pi pi-plus mr-2"></i>
                  <span>{{
                    "SESSION.unit-menu.buttons.create-organization" | translate : { default: "Create organization" }
                  }}</span>
                </div>
              </ng-template>
            </p-button>
          </footer>
        </div>
      </ng-container>
    </ng-template>
  </p-overlayPanel>

  <p-menu
    #organizationOptionMenu
    styleClass="generic-actions-menu"
    appendTo="body"
    [popup]="true"
    [model]="organizationOptionItems"
  ></p-menu>
  <p-menu
    #workspaceOptionMenu
    appendTo="body"
    [popup]="true"
    [model]="workspaceOptionItems"
    [style]="{ '--menu-width': '18.5rem' }"
  ></p-menu>
</ng-container>
