import BaseModel from "../../base.model";
import RecordMessageFormItemModel from "../../record/record-message-form-item.model";

export class RecordMessageTemplateContentModel extends BaseModel {
  public body: string | null = null;
  public formItems: RecordMessageFormItemModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.formItems)
      this.formItems = input.formItems.map((item) => new RecordMessageFormItemModel().deserialize(item));

    return this;
  }
}
