import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { FileHelper } from "@app/@shared/helpers/file.helper";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";
import { CloudAPIService } from "@app/@shared/services/cloud-api.service";
import { Logger, UntilDestroy, untilDestroyed } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { isEmpty, sortBy } from "lodash";
import { MessageService } from "primeng/api";
import { BehaviorSubject, catchError, map, switchMap, tap, throwError } from "rxjs";

export type CloudFilePickerMode = "standard" | "folders";
const log = new Logger("MAIN/CloudFilePickerComponent");

@UntilDestroy()
@Component({
  selector: "main-cloud-file-picker",
  templateUrl: "./cloud-file-picker.component.html",
  styleUrls: ["./cloud-file-picker.component.scss"],
})
export class CloudFilePickerComponent implements OnInit {
  crumbs$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  currentPath$: BehaviorSubject<string> = new BehaviorSubject("/");
  currentResource$: BehaviorSubject<ProviderItemModel> = new BehaviorSubject(undefined);
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resources$: BehaviorSubject<ProviderItemModel[]> = new BehaviorSubject([]);
  selectedResource$: BehaviorSubject<ProviderItemModel> = new BehaviorSubject(undefined);

  // Folder creation mode
  isCreationModeEnabled: boolean = false;
  isCreatingFolder: boolean = false;
  newFolderName: string;

  @Input() enableFolderCreation: boolean = false;
  @Input() mode: CloudFilePickerMode = "standard";
  @Input() multiple: boolean = false;
  @Input() pdfOnly: boolean = false;
  @Input() submitLabel: string;
  @Input() accept: string[] = null; // Restrict expected file extension

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<ProviderItemModel> = new EventEmitter();

  @ViewChild("newFolderInput", { static: false }) newFolderInput: ElementRef;

  constructor(
    private translateService: TranslateService,
    private cloudAPIService: CloudAPIService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.currentPath$
      .pipe(
        tap(() => this.isLoading$.next(true)),
        switchMap((path: string) => this.cloudAPIService.getFolderContent(path, this.mode === "folders", this.pdfOnly)),
        catchError((error) => {
          log.error(error);
          this.isLoading$.next(false);
          return throwError(() => error);
        }),
        map((resources: ProviderItemModel[]) => {
          // Extract current resource from array
          this.currentResource$.next(resources.shift());
          return resources;
        }),
        map((resources: ProviderItemModel[]) => sortBy(resources, (r) => !r.isDirectory)),
        tap((resources: ProviderItemModel[]) => {
          this.resources$.next(resources);

          // If folders mode, select current resource
          if (this.mode === "folders") {
            this.selectedResource$.next(this.currentResource);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.setBreadcrumb();
        this.isLoading$.next(false);
      });

    if (!this.submitLabel) {
      this.submitLabel = this.translateService.instant("COMMON.buttons.select", { default: "Select" });
    }
  }

  get selectedResource() {
    return this.selectedResource$.getValue();
  }

  get currentPath() {
    return this.currentPath$.getValue();
  }

  get currentResource() {
    return this.currentResource$.getValue();
  }

  get crumbs() {
    return this.crumbs$.getValue();
  }

  select(event: Event, resource: ProviderItemModel) {
    // If folders mode, automatically navigate on click
    if (this.mode === "folders") {
      this.navigateToPath(resource.path);
    } else {
      let valid = !(Boolean(this.accept) && !FileHelper.isProviderItemAccepted(resource, this.accept));
      if (valid) {
        this.selectedResource$.next(resource);
      } else {
        this.messageService.add({
          severity: MessageSeverityEnum.SEVERITY_INFO,
          summary: this.translateService.instant("MAIN.messages.file-not-supported.title", {
            default: "This file is not supported",
          }),
          detail: this.translateService.instant("MAIN.messages.file-not-supported.subtitle", {
            default: "Please select a file in the supported format",
            accept: this.accept,
          }),
        });
      }
    }
  }

  navigate(event: Event, resource: ProviderItemModel) {
    event.stopPropagation();
    if (resource.isDirectory) {
      this.navigateToPath(resource.path);
    }
  }

  navigateToPath(path: string) {
    if (path) {
      this.selectedResource$.next(undefined);
      this.currentPath$.next(path);
    }
  }

  navigateToCrumb(index: number) {
    const crumbs = this.crumbs.slice(0, index + 1);
    const path = crumbs.join("/");
    this.navigateToPath(path);
  }

  private setBreadcrumb() {
    const path = this.currentPath;
    const crumbs = path.split("/").filter((c: string) => !isEmpty(c));
    this.crumbs$.next(crumbs);
  }

  handleNewFolderClick() {
    this.isCreationModeEnabled = true;
    setTimeout(() => {
      if (this.newFolderInput) {
        this.newFolderInput.nativeElement?.focus();
      }
    });
  }

  get newFolderInvalid() {
    return (
      this.newFolderName &&
      !isEmpty(this.newFolderName) &&
      this.resources$
        .getValue()
        .map((r: ProviderItemModel) => r.name.toLowerCase())
        .includes(this.newFolderName.toLowerCase())
    );
  }

  submitCreationMode(event: Event) {
    if (this.newFolderName && !isEmpty(this.newFolderName)) {
      this.isCreatingFolder = true;
      this.cloudAPIService
        .createFolder(this.currentResource.path, this.newFolderName)
        .pipe(untilDestroyed(this))
        .subscribe((done: boolean) => {
          if (done) {
            this.currentPath$.next(this.currentResource.path);
            this.cancelCreationMode(event);
          } else {
          }

          this.isCreatingFolder = false;
        });
    }
  }

  cancelCreationMode(event: Event) {
    event.stopPropagation();
    this.newFolderName = undefined;
    this.isCreationModeEnabled = false;
  }

  submit() {
    if (this.selectedResource) {
      this.onSubmit.emit(this.selectedResource);
    }
  }

  cancel() {
    this.onCancel.emit();
  }
}
