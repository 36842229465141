import BaseModel from "../base.model";
import { ListScopeEnum } from "@app/@shared/enums/list-scope.enum";
import { ClassHelper } from "@app/@shared/helpers/class.helper";

export default class ListModel extends BaseModel {
  // MINIMIZE (default)
  public listClass = "";
  public values: JsonArray = [];
  public scope?: ListScopeEnum;
  public defaultValue?: string;

  constructor() {
    super();
  }

  getListClass(): string[] {
    return ClassHelper.splitClass(this.listClass);
  }

  setListClass(...args: string[]): this {
    this.listClass = ClassHelper.normalizeClass(...args);
    return this;
  }
}
