<div class="flex flex-column gap-3">
  <p-splitButton
    [label]="'COMMON.buttons.add-files' | translate: { 'default': 'Add attachments' }"
    (onClick)="handleFoldersUpload(null)"
    [model]="items"
    styleClass="p-button-sm"
  ></p-splitButton>

  <input
    #uploadInput
    class="files-upload-button__input"
    type="file"
    [accept]="htmlAccept"
    [id]="id"
    [title]="title"
    [multiple]="multiple ? multiple : undefined"
    (change)="handleChange($event)"
    [attr.directory]="uploadFolders"
    [attr.webkitdirectory]="uploadFolders"
    [attr.mozdirectory]="uploadFolders"
    [attr.msdirectory]="uploadFolders"
    [attr.odirectory]="uploadFolders"
  />

  <ng-container *ngIf="uploads && uploads.length > 0"
    ><main-files-upload-list
      [disabled]="disabled"
      [isUploading]="isUploading"
      [uploads]="uploads"
      [variant]="uploadListVariant"
      (onRemove)="handleRemove($event)"
    ></main-files-upload-list
  ></ng-container>
</div>
