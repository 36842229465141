export enum ContactOrderEnum {
  FIRSTNAME_ASC = "CONTACTS_FIRSTNAME_ASC",
  FIRSTNAME_DESC = "CONTACTS_FIRSTNAME_DESC",
  LASTNAME_ASC = "CONTACTS_LASTNAME_ASC",
  LASTNAME_DESC = "CONTACTS_LASTNAME_DESC",
  EMAIL_ASC = "CONTACTS_EMAIL_ASC",
  EMAIL_DESC = "CONTACTS_EMAIL_DESC",
  PHONE_ASC = "CONTACTS_PHONE_ASC",
  PHONE_DESC = "CONTACTS_PHONE_DESC",
}
