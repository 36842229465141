<p-dropdown
  [name]="name"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  appendTo="body"
  [options]="options"
  [filter]="filter"
  [showClear]="showClear"
  [autoDisplayFirst]="false"
  [ngModel]="selectedValue"
  (ngModelChange)="handleChange($event)"
  (onChange)="handleChange($event)"
  panelStyleClass="answer-dropdown"
>
  <ng-template let-option pTemplate="item">
    <div class="flex flex-column">
      <span>{{ option.label }}</span>
      <span class="text-sm text-gray-500 clamp" *ngIf="option.description">
        {{ option.description }}
      </span>
    </div>
  </ng-template>
</p-dropdown>
