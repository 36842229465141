<p-calendar
  [name]="name"
  [inputId]="inputId"
  [title]="title"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [readonlyInput]="readonly"
  [inline]="inline"
  appendTo="body"
  [showOtherMonths]="false"
  [showWeek]="showWeek"
  [showButtonBar]="true"
  [view]="view"
  [dateFormat]="dateFormat"
  [dataType]=""
  [minDate]="minDate"
  [maxDate]="maxDate"
  [defaultDate]="defaultDate"
  [disabledDates]="disabledDates"
  [multipleSeparator]="multipleSeparator"
  [rangeSeparator]="rangeSeparator"
  [showTime]="showTime"
  [timeOnly]="timeOnly"
  [hourFormat]="hourFormat"
  [stepHour]="stepHour"
  [stepMinute]="stepMinute"
  [todayButtonStyleClass]="todayButtonStyleClass"
  dataType="string"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  (onSelect)="handleChange($event)"
></p-calendar>
