import { get, set } from "lodash";
import WithPropertiesModel from "../with-properties.model";

export default class UnitModel extends WithPropertiesModel {
  // MINIMIZE (default)
  public unitIdentifier: string | null = null;
  public name = "";
  public unitSlug: string | null = null;
  public userRole?: string;
  public isPublic: boolean = false;

  // UNIT_DETAILS
  public avatar: string | null = null;
  public deleteAvatar?: boolean;
  public avatarContent: string | null = null;
  public locale: string | null = null;

  // UNIT_CUSTOMS
  public logoContent: string | null = null;
  public deleteLogo?: boolean;
  public logo: string | null = null;
  public baseUrl: string | null = null;
  public title: string | null = null;

  public styles: Object = {};

  // UNIT_PARENT
  public parentUnit?: UnitModel;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.parentUnit) this.parentUnit = new UnitModel().deserialize(input.parentUnit);

    return this;
  }

  /**
   * Properties related helpers
   */
  get hideSupport(): boolean {
    return this.getProperty("hide-support");
  }

  set hideSupport(value: boolean) {
    this.setProperty("hide-support", value);
  }
}
