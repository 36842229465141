<div
  class="flex flex-column justify-content-center align-items-center py-8 px-3"
  *ngIf="(currentApplication$ | async) == null && !(isLoadingApplications$ | async); else haveActiveProviderApplication"
>
  <div class="flex justify-content-center gap-2 opacity-80">
    <i class="pi pi-microsoft text-7xl"></i>
    <i class="pi pi-folder text-7xl"></i>
    <i class="pi pi-google text-7xl"></i>
  </div>
  <p class="text-center">
    {{
      "MAIN.empty-states.providers.subtitle"
        | translate : { default: "Link Nestor to your external drives to easily import files" }
    }}
  </p>
  <a
    pButton
    [routerLink]="['/user-settings', 'apps']"
    class="p-button-sm no-underline"
    icon="pi pi-link"
    [label]="'MAIN.empty-states.providers.button' | translate : { default: 'Go to applications' }"
  ></a>
  <!-- Else there is an active app -->
</div>

<ng-template #haveActiveProviderApplication>
  <!-- Application providers selection -->
  <div *ngIf="applications$ | async" class="mx-3 mt-3">
    <provider-file-switcher
      [applications]="applications$ | async"
      [isLoadingApplications]="isLoadingApplications$ | async"
      [currentApplication]="currentApplication$ | async"
      (onSelectApplication)="handleSelectApplication($event)"
    ></provider-file-switcher>
  </div>
  <ng-container *ngIf="currentApplication$ | async">
    <provider-contact-table
      [contacts]="contacts$ | async"
      [currentApplication]="currentApplication$ | async"
      [isLoadingContacts]="isLoadingContacts$ | async"
      [(selectedContacts)]="selectedContacts"
    ></provider-contact-table>
  </ng-container>

  <footer class="dialog-nested-footer flex align-items-center gap-3 justify-content-end mt-auto">
    <span class="mr-auto font-medium" *ngIf="selectedContacts?.length > 0">
      {{
        "MAIN.provider-contacts.count" | translate : { default: "Contact selected", count: selectedContacts?.length }
      }}
    </span>
    <button
      pButton
      type="button"
      class="p-button-sm p-button-outlined"
      [label]="'COMMON.buttons.cancel' | translate"
      (click)="onCancel.emit()"
    ></button>
    <button
      pButton
      type="button"
      class="p-button-sm"
      [disabled]="!(selectedContacts?.length > 0)"
      [label]="'COMMON.buttons.add-selection' | translate"
      (click)="submit()"
    ></button>
  </footer>
</ng-template>
