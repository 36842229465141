import { Injectable } from "@angular/core";
import { Logger } from "@app/@core";
import { SessionAPIService } from "./session-api.service";
import { UserRoleEnum } from "../enums/user-role.enum";
import UserUnitModel from "../models/user/user-unit.model";
import UserModel from "../models/user/user.model";
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, switchMap, tap } from "rxjs";
import { BillingAccountScopeEnum } from "../enums/billing-account-scope.enum";
import { KeycloakService } from "keycloak-angular";
import { KeycloakClientRoleEnum } from "../enums/keycloak/keycloak-client-role-enum";
import { some } from "lodash";
import { KeycloakRealmRoleEnum } from "../enums/keycloak/keycloak-realm-role-enum";
import { BillingService } from "@app/billing/services/billing.service";
import { KeycloakRoles } from "keycloak-js";
import UnitUserModel from "../models/domain/unit-user.model";
import { DomainAPIService } from "./domain-api.service";
import { ConfigurationClassEnum } from "../enums/configuration-class.enum";

const log = new Logger("AuthorizationService");

@Injectable({
  providedIn: "root",
})
export class AuthorizationService {
  DEFAULT_MIN_EXPIRATION_PERIOD = 1;
  DEFAULT_EXPIRATION_PERIOD = 90;

  MAX_CONVERSATIONS_FREE = 10;
  MAX_CONVERSATIONS_PAID = Infinity;

  MAX_EXPIRATION_PERIOD_FREE = 90; // 90 days / 3 months (there's not really free users)
  MAX_EXPIRATION_PERIOD_PAID = 1825; // 5 years

  EXPIRATION_INCREMENTS = [1, 30, 90, 365];

  MAX_RECIPIENTS_PER_CONVERSATION_FREE = 30;

  // PROVIDERS LIMITS
  YOUSIGN_SIGNATURE_MAX_FILESIZE = 47185920;

  // Authorizations initialized observable
  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  initialized$: Observable<boolean> = this._initialized$.asObservable();

  get initialized() {
    return this._initialized$.getValue();
  }

  // current user unit observable
  private _currentUserUnit$: BehaviorSubject<UserUnitModel> = new BehaviorSubject(undefined);
  currentUserUnit$: Observable<UserUnitModel> = this._currentUserUnit$.asObservable();

  get currentUserUnit() {
    return this._currentUserUnit$.getValue();
  }
  set currentUserUnit(user: UserUnitModel) {
    this._currentUserUnit$.next(user);
  }

  // keycloak realm roles observable
  private _keycloakRealmRoles$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  keycloakRealmRoles$: Observable<string[]> = this._keycloakRealmRoles$.asObservable();

  get keycloakRealmRoles() {
    return this._keycloakRealmRoles$.getValue();
  }

  // keycloak user roles observable
  private _keycloakClientRoles$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  keycloakClientRoles$: Observable<string[]> = this._keycloakClientRoles$.asObservable();

  get keycloakClientRoles() {
    return this._keycloakClientRoles$.getValue();
  }

  // reload subject
  _refreshAuthorizations$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  set refreshAuthorizations(value: any) {
    this._refreshAuthorizations$.next(value);
  }

  constructor(
    private keycloakService: KeycloakService,
    private sessionAPIService: SessionAPIService,
    private billingService: BillingService,
    private domainAPIService: DomainAPIService,
  ) {
    // Initialize watching session to set _currentUserUnit$ value
    combineLatest([this.sessionAPIService.session$, this.billingService.isLoaded$, this._refreshAuthorizations$])
      .pipe(
        tap(() => this._initialized$.next(false)),
        filter(([session, loaded]) => Boolean(session) && Boolean(loaded)),
        tap(([session]) => {
          let userUnit = session.user.units.find(
            (u: UserUnitModel) => session.unit.unitIdentifier === u.unit.unitIdentifier,
          );

          this._currentUserUnit$.next(userUnit);
        }),
        tap(() => {
          const realmAccess: KeycloakRoles = this.keycloakService.getKeycloakInstance().realmAccess;

          if (realmAccess.roles) {
            this._keycloakRealmRoles$.next(realmAccess.roles);
          }

          this._keycloakClientRoles$.next(this.keycloakService.getUserRoles(false));
          this._initialized$.next(true);
        }),
        switchMap(([session, _loaded, _refresh]) => {
          return this.domainAPIService
            .getConfiguration(session.unit.unitSlug, ConfigurationClassEnum.GLOBAL_LIMITS)
            .pipe(
              map((configuration) => (configuration?.value["enabled"] ? configuration?.value : null)),
              catchError((error) => {
                log.error(error);
                return of(null);
              }),
            );
        }),
      )
      .subscribe((value) => {
        // FREE USER QUOTAS
        this.MAX_CONVERSATIONS_FREE = value?.["freeUser"]?.["maxConversations"] ?? this.MAX_CONVERSATIONS_FREE;
        this.MAX_EXPIRATION_PERIOD_FREE =
          value?.["freeUser"]?.["maxExpirationDelay"] ?? this.MAX_EXPIRATION_PERIOD_FREE;
        this.MAX_RECIPIENTS_PER_CONVERSATION_FREE =
          value?.["freeUser"]?.["maxRecipientsPerConversation"] ?? this.MAX_RECIPIENTS_PER_CONVERSATION_FREE;

        this.MAX_EXPIRATION_PERIOD_PAID =
          value?.["paidUser"]?.["maxExpirationDelay"] ?? this.MAX_EXPIRATION_PERIOD_PAID;
        // PROVIDERS
        this.YOUSIGN_SIGNATURE_MAX_FILESIZE =
          value?.["signature"]?.["yousign"]?.["maxFileSize"] ?? this.YOUSIGN_SIGNATURE_MAX_FILESIZE;
      });
  }

  /**
   * Keycloak roles related authorizations
   */
  hasKeycloakRole(role: KeycloakClientRoleEnum | KeycloakRealmRoleEnum | string): boolean {
    return this.hasKeycloakClientRole(role) || this.hasKeycloakRealmRole(role);
  }

  hasAnyKeycloakRole(roles: (KeycloakClientRoleEnum | KeycloakRealmRoleEnum | string)[]): boolean {
    return some(roles, (role: KeycloakClientRoleEnum | KeycloakRealmRoleEnum | string) => this.hasKeycloakRole(role));
  }

  hasKeycloakClientRole(role: KeycloakClientRoleEnum | string): boolean {
    return this.keycloakClientRoles.includes(role);
  }

  hasAnyKeycloakClientRole(roles: (KeycloakClientRoleEnum | string)[]): boolean {
    return some(roles, (role: KeycloakClientRoleEnum | string) => this.hasKeycloakClientRole(role));
  }

  hasKeycloakRealmRole(role: KeycloakRealmRoleEnum | string): boolean {
    return this.keycloakRealmRoles.includes(role);
  }

  hasAnyKeycloakRealmRole(roles: (KeycloakRealmRoleEnum | string)[]): boolean {
    return some(roles, (role: KeycloakRealmRoleEnum | string) => this.hasKeycloakRealmRole(role));
  }

  /**
   * Roles related helpers
   */
  PRODUCT_ROLES = [KeycloakRealmRoleEnum.PAPRWORK_SIGNATURE.valueOf()];

  /**
   * Returns all roles that does not correspond to a guest user
   */
  get notGuestRoles(): string[] {
    return this.keycloakRealmRoles.filter(
      (r: string) =>
        r !== KeycloakRealmRoleEnum.PAPRWORK_GUEST && !r.startsWith("default-roles") && !this.PRODUCT_ROLES.includes(r),
    );
  }

  /**
   * Returns whether a user is a guest or not
   */
  isGuest(): boolean {
    return this.notGuestRoles.length === 0;
  }

  /**
   * Returns whether a user is a free user or not
   */
  isFreeUser(): boolean {
    return this.notGuestRoles.includes(KeycloakRealmRoleEnum.PAPRWORK_FREE_USER);
  }

  /**
   * Returns whether a user is not a guest or free user (e.g must have a paid role)
   */
  isPaidUser(): boolean {
    return (
      this.hasRunningSubscription() &&
      this.notGuestRoles.filter((r: string) => r !== KeycloakRealmRoleEnum.PAPRWORK_FREE_USER).length > 0
    );
  }

  /**
   * Returns whether a user has a billing account loaded on the current unit
   * He can either be the owner of the billing account, or inherit it through the unit
   */
  hasBillingAccount() {
    return Boolean(this.billingService.billingAccount);
  }

  /**
   * Returns whether a user has a running subscription for the current billing account (loaded in the unit)
   */
  hasRunningSubscription() {
    return this.hasBillingAccount() && Boolean(this.billingService.billingAccount.hasRunningSubscription);
  }

  /**
   * Returns whether user is current billing account's owner (if not, the billing account is inherited)
   */
  isBillingAccountOwner() {
    return this.hasBillingAccount() && this.billingService.billingAccount.scope === BillingAccountScopeEnum.OWNER;
  }

  /**
   * Features related authorizations
   */
  canAccessRecordsStandard() {
    return !this.isGuest() && this.hasKeycloakClientRole(KeycloakClientRoleEnum.FEATURE_RECORDS_STANDARD);
  }

  canAccessRecordsBatch() {
    return this.isPaidUser() && this.hasKeycloakClientRole(KeycloakClientRoleEnum.FEATURE_RECORDS_BATCH);
  }

  canAccessUnitCustomization() {
    return this.isPaidUser() && this.hasKeycloakClientRole(KeycloakClientRoleEnum.FEATURE_UNITS_CUSTOM);
  }

  canAccessSignature() {
    return this.isPaidUser() && this.hasKeycloakRealmRole(KeycloakRealmRoleEnum.PAPRWORK_SIGNATURE);
  }

  canAccessNestorDrive() {
    return !this.isGuest();
  }
  canAccessExternalDrive() {
    return true;
  }

  /**
   * Unit related authorizations
   */
  MIN_ROLE_MANAGER = [UserRoleEnum.MANAGER, UserRoleEnum.OWNER];
  MIN_ROLE_USER = [UserRoleEnum.USER, UserRoleEnum.MANAGER, UserRoleEnum.OWNER];

  hasMinRole(minRole: UserRoleEnum, userUnit: UserUnitModel = this.currentUserUnit) {
    if (minRole === UserRoleEnum.USER) {
      return this.MIN_ROLE_USER.includes(userUnit.userRole);
    } else if (minRole === UserRoleEnum.MANAGER) {
      return this.MIN_ROLE_MANAGER.includes(userUnit.userRole);
    } else if (minRole === UserRoleEnum.OWNER) {
      return userUnit.userRole === UserRoleEnum.OWNER;
    }

    return false;
  }

  isUnitGuest(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return userUnit?.userRole && UserRoleEnum.GUEST == userUnit.userRole;
  }

  isCurrentUser(user: UserModel) {
    return this.sessionAPIService.session?.user?.userIdentifier === user.userIdentifier;
  }

  canManageUnit(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return userUnit?.userRole && [UserRoleEnum.OWNER, UserRoleEnum.MANAGER].includes(userUnit.userRole);
  }

  hasOrganization(): boolean {
    return this.sessionAPIService.session?.user?.units.some((userUnit) => userUnit.userRole === UserRoleEnum.OWNER);
  }
  canCreateOrganization(): boolean {
    return this.isPaidUser() && !this.hasOrganization();
  }

  canCreateChildUnit(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return userUnit.userRole && [UserRoleEnum.OWNER, UserRoleEnum.MANAGER].includes(userUnit.userRole);
  }

  canAccessTags(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return (
      userUnit.userRole && [UserRoleEnum.OWNER, UserRoleEnum.MANAGER, UserRoleEnum.USER].includes(userUnit.userRole)
    );
  }

  canCreateRecord(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    if (userUnit.unit.isPublic) {
      return !this.isGuest();
    }
    return !this.isUnitGuest(userUnit);
  }

  canInviteUnitUser(userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return this.canManageUnit(userUnit);
  }

  canUpdateUnitUserRole(user: UserModel, userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return this.canManageUnit(userUnit) && !this.isCurrentUser(user);
  }

  canRemoveUnitUser(user: UserModel, userUnit: UserUnitModel = this.currentUserUnit): boolean {
    return this.canManageUnit(userUnit) && !this.isCurrentUser(user);
  }

  /**
   * Billing account related authorizations
   */
  canManageBillingAccount(): boolean {
    return this.billingService.billingAccount?.scope === BillingAccountScopeEnum.OWNER;
  }

  canInviteBillingAccountUser() {
    return this.canManageBillingAccount();
  }

  /**
   * Expiration Period in Days
   */

  /**
   * Get the default expiration period for records.
   * @returns The default number of days before a record expires.
   */
  getDefaultExpirationPeriod(): number {
    return this.DEFAULT_EXPIRATION_PERIOD;
  }
  /**
   * Get the minimum allowed expiration period for records.
   * @returns The minimum allowed number of days before a record expires.
   */
  getMinExpirationPeriod(): number {
    return this.DEFAULT_MIN_EXPIRATION_PERIOD;
  }
  /**
   * Get the maximum allowed expiration period for records.
   * @returns The maximum allowed number of days for record expiration.
   */
  getMaxExpirationPeriod(): number {
    return this.isPaidUser ? this.MAX_EXPIRATION_PERIOD_PAID : this.MAX_EXPIRATION_PERIOD_FREE;
  }
}
