import { Component, Input, OnInit } from "@angular/core";
import SessionModel from "@app/@shared/models/session/session.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { KeycloakService } from "keycloak-angular";
import { MenuItem } from "primeng/api";
import { UntilDestroy, untilDestroyed } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { AuthorizationService } from "@shared/services/authorization.service";
import { LocalStorageKeysEnum } from "@shared/enums/local-storage-keys.enum";
import { LocalStorageService } from "@shared/services/local-storage.service";
import { environment } from "@env/environment";
import { filter } from "rxjs";

@UntilDestroy()
@Component({
  selector: "session-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
export class UserMenuComponent implements OnInit {
  _session: SessionModel = new SessionModel();
  @Input()
  set session(session: SessionModel) {
    this._session = session;
  }
  get session(): SessionModel {
    return this._session;
  }
  items: MenuItem[] = [];

  constructor(
    private translateService: TranslateService,
    private readonly keycloakService: KeycloakService,
    private sessionAPIService: SessionAPIService,
    private authorizationService: AuthorizationService,
    private localStorageService: LocalStorageService,
  ) {
    this.setItems();

    this.authorizationService.initialized$
      .pipe(
        filter((initialized) => Boolean(initialized)),
        untilDestroyed(this),
      )
      .subscribe(() => this.setItems());
  }

  ngOnInit(): void {
    this.sessionAPIService.session$.pipe(untilDestroyed(this)).subscribe((data) => {
      this._session = data;
    });
  }

  get billingItemLabel(): string {
    if (this.authorizationService.isGuest()) {
      return this.translateService.instant("SESSION.user-menu.subscribe.try.label", {
        default: "Try for free",
      });
    } else {
      // Starter Plan
      return this.translateService.instant("SESSION.user-menu.subscribe.upgrade.label", {
        default: "Upgrade now",
      });
    }
  }
  setItems() {
    this.items = [
      {
        label: this.translateService.instant("SESSION.user-menu.drive.label", {
          default: "Nestor Drive",
        }),
        icon: "pi pi-cloud",
        url: environment.cloud.url,
        visible: !this.authorizationService.isGuest(),
      },
      { separator: true, visible: !this.authorizationService.isUnitGuest() },

      {
        label: this.billingItemLabel,
        icon: "pi pi-star-fill",
        routerLink: "billing",
        visible: !this.authorizationService.hasBillingAccount(),
      },
      {
        label: this.translateService.instant("SESSION.user-menu.account.user-settings.label", {
          default: "Personal settings",
        }),
        icon: "msr-icon-account_box icon-18px msr-icon-fw",
        routerLink: "user-settings",
      },
      { separator: true },
      {
        label: this.translateService.instant("SESSION.user-menu.account.logout.label", { default: "Disconnect" }),
        icon: "msr-icon-power_settings_new icon-18px msr-icon-fw",
        command: () => this.doLogout(),
        styleClass: "p-error",
      },
    ];
  }

  doLogout(): void {
    this.localStorageService.clear(LocalStorageKeysEnum.CURRENT_UNIT);

    this.keycloakService.logout();
  }
}
