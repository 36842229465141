<div
  class="flex flex-wrap align-items-center justify-content-between"
  style="gap: 0.1rem"
  [tooltip]="isInvalid ? ('RECORD.recipients.is-invalid' | translate : { 'default': 'Missing information' }) : null"
>
  <div class="flex flex-wrap align-items-center gap-2" style="gap: 0.1rem">
    <p-avatar
      *ngIf="showAvatar"
      [label]="recipient | getRecipientInitials"
      shape="circle"
      [styleClass]="who === 'self' ? 'avatar-self' : 'avatar-other'"
    ></p-avatar>
    <div class="flex flex-column justify-content-center" [ngClass]="{ 'invalid': isInvalid }">
      <span data-avatar--name *ngIf="recipient?.fullName"> {{ recipient | getRecipientFullName }} </span>
      <div
        class="flex align-items-center flex-wrap gap-1"
        *ngIf="(showEmail && recipient.emailAddress) || (showPhone && recipient.phoneNumber)"
      >
        <span class="mr-1" *ngIf="showEmail && recipient.emailAddress" data-avatar--email>{{
          recipient.emailAddress
        }}</span>
        <span data-avatar--divider *ngIf="showEmail && recipient.emailAddress && showPhone && recipient.phoneNumber"
          >-</span
        >
        <span *ngIf="showPhone && recipient.phoneNumber" data-avatar--phone>{{ recipient.phoneNumber }}</span>
      </div>
    </div>
  </div>
</div>
