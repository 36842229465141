import { AbstractControl, ValidationErrors } from "@angular/forms";

export function dueDateGtOrEqExpirationDateValidator(control: AbstractControl): ValidationErrors | null {
  let { dueDate, expirationDate } = control.value;

  if (dueDate >= expirationDate) {
    return { dueDateGtOrEqExpirationDate: true };
  }

  return null;
}
