import BaseModel from "@shared/models/base.model";

export default class BillingInvoiceModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public number: string | null = null;
  public total: number | null = null;
  public currency: string | null = null;
  public periodStart: number | null = null;
  public periodEnd: number | null = null;
  public status: string | null = null;
  public pdfUrl: string | null = null;
  public portalUrl: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  get currencyCode(): string {
    return this.currency?.toUpperCase();
  }
}
