import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UploadState } from "ngx-uploadx";

export type UploadListVariant = "box" | "inline";

@Component({
  selector: "main-files-upload-list",
  templateUrl: "./files-upload-list.component.html",
  styleUrls: ["./files-upload-list.component.scss"],
})
export class FilesUploadListComponent implements OnInit, OnDestroy {
  @Input() allowRemove: boolean = true;
  @Input() displaySignatureTrigger: boolean = false;
  @Input() displaySignatureIndicator: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isUploading: boolean = false;
  @Input() uploads: UploadState[] = [];
  @Input() variant: UploadListVariant = "box";
  @Input() addHeightConstraints: boolean = true;
  @Input() signatureUploadIds: string[] = [];

  @Output() onRemove: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSignatureUploadIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}

  remove(uploadId: string) {
    this.onRemove.emit(uploadId);
  }

  handleSignatureUploadChange(event: string[]) {
    this.onSignatureUploadIdsChange.emit(event);
  }
}
