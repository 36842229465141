<p-avatar
  *ngIf="unit"
  [label]="!unit.avatar && (unit | getUnitInitials)"
  [size]="size"
  shape="square"
  [image]="unit.avatar"
  styleClass="unit-avatar"
  [tooltip]="showTooltip ? unit.name : null"
  [placement]="tooltipPlacement"
></p-avatar>
