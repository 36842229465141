import { Component, Input } from "@angular/core";
import { MenuItem } from "primeng/api";

@Component({
  selector: "main-navigation-menu",
  templateUrl: "./navigation-menu.component.html",
  styleUrls: ["./navigation-menu.component.scss"],
})
export class NavigationMenuComponent {
  @Input() showInlineMenu: boolean = false;
  @Input() inlineMenuItems = [];
  @Input() hideBottomBar: boolean = true;

  constructor() {}
}
