<div class="flex flex-column gap-1" *ngIf="editedTags?.length > 0; else noTags">
  <ng-container *ngIf="editedTags?.length < maxDisplayedTags">
    <button
      pButton
      data-button="noLabel"
      class="p-button-sm p-button-secondary p-button-text py-0 px-2 flex ml-auto"
      (click)="handleTogglePanel($event)"
      [ngClass]="{
        'no-interactive': !authorizeTagEdition
      }"
    >
      <i class="pi pi-tag mr-2"></i>
      <span class="inline-flex align-items-center">
        <span class="clamp suffix-comma text-left" [style]="{ 'max-width': '15ch' }" *ngFor="let tag of editedTags">{{
          tag
        }}</span>
      </span>
    </button>
  </ng-container>
  <!-- MORE TAGS THAN DISPLAYABLE -> HIDE THE REST -->
  <ng-container *ngIf="editedTags?.length >= maxDisplayedTags">
    <button
      pButton
      data-button="noLabel"
      class="p-button-sm p-button-secondary p-button-text py-0 px-2 ml-auto"
      (click)="handleTogglePanel($event)"
      [ngClass]="{
        'no-interactive': !authorizeTagEdition
      }"
    >
      <span class="flex flex-wrap font-medium align-items-center">
        <ng-container *ngIf="isTagsPanelOpen; else tagsSummary">
          <div class="flex align-items-center p-2">
            <i class="pi pi-tag mr-2"></i>
            <span>
              {{ "RECORD.record-tags.count" | translate : { count: editedTags.length } }}
            </span>
          </div>
        </ng-container>
        <ng-template #tagsSummary>
          <i class="pi pi-tag mr-2"></i>
          <span class="inline-flex align-items-center">
            <span
              class="clamp suffix-comma text-left"
              [style]="{ 'max-width': '15ch' }"
              *ngFor="let tag of editedTags.slice(0, maxDisplayedTags)"
            >
              {{ tag }}
            </span>
          </span>
          <span
            class="font-semibold"
            *ngIf="editedTags.length - maxDisplayedTags !== 0"
            variant="tag"
            prefix="+"
            size="small"
            [tooltip]="isTagsPanelOpen ? undefined : editedTags.join(', ')"
            [delay]="900"
            container="body"
            placement="right"
          >
            , +{{ editedTags.length - maxDisplayedTags + "" }}
          </span>
        </ng-template>
      </span>
    </button>
  </ng-container>
</div>

<!-- No tags added yet -->
<ng-template #noTags>
  <div class="flex" *ngIf="authorizeTagEdition">
    <button
      (click)="tagsPanel.toggle($event)"
      pButton
      class="p-button-text p-button-secondary p-button-sm"
      icon="pi pi-tag"
      [label]="'RECORD.record-tags.empty.description' | translate : { 'default': 'Add tags to find them easily later' }"
    ></button>
  </div>
</ng-template>

<!-- Tags Overlay Panel  -->
<p-overlayPanel
  #tagsPanel
  styleClass="tags-panel"
  (onHide)="handleHidePanel()"
  (onShow)="handleShowPanel()"
  [showCloseIcon]="true"
  [style]="{ '--p-overlaypanel-close-label': '\'' + ('COMMON.buttons.close' | translate : { default: 'Edit' }) + '\'' }"
  [appendTo]="themingService.rootContainerRef"
>
  <ng-template pTemplate="content">
    <div class="tags-panel__container">
      <div class="tags-panel__main">
        <tags-form
          #tagsForm
          [tags]="editedTags"
          (tagsChange)="handleTagsChange($event)"
          [isEditing]="isEditing"
          [isTagsPanelOpen]="isTagsPanelOpen"
        ></tags-form>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
