import { Component, OnInit, ViewChild } from "@angular/core";
import SessionModel from "@app/@shared/models/session/session.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { MenuItem, MessageService } from "primeng/api";
import UnitModel from "@shared/models/domain/unit.model";
import { TranslateService } from "@ngx-translate/core";
import { UserRoleEnum } from "@shared/enums/user-role.enum";
import { UntilDestroy, untilDestroyed } from "@core";
import { Router } from "@angular/router";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { BehaviorSubject, filter, map, Observable, tap } from "rxjs";
import { ThemingService } from "@app/@shared/services/theming.service";
import { OverlayPanel } from "primeng/overlaypanel";
import UserUnitModel from "@app/@shared/models/user/user-unit.model";
import { Menu } from "primeng/menu";
import { AuthorizationService } from "@app/@shared/services/authorization.service";

interface GroupedUserUnit extends UserUnitModel {
  children?: GroupedUserUnit[]; //TIL: Recursive Interface property 🤔
}

type ChildrenUnitMap = {
  [key: string]: GroupedUserUnit[];
};

enum UnitSettingsPaths {
  UNIT_LIST = "/units/list",
  UNIT_EDIT = "/units/edit",
  UNIT_CUSTOM = "/units/custom",
  UNIT_USERS = "/units/users",
  UNIT_NOTIFICATION = "/units/notification-templates",
  UNIT_SETTINGS = "/units/settings",
  UNIT_BILLING = "/units/billing",
}
@UntilDestroy()
@Component({
  selector: "session-unit-menu",
  templateUrl: "./unit-menu.component.html",
  styleUrls: ["./unit-menu.component.scss"],
})
export class UnitMenuComponent implements OnInit {
  @ViewChild(OverlayPanel) unitsPanel: OverlayPanel;
  @ViewChild("organizationOptionMenu") organizationOptionMenu: Menu;
  @ViewChild("workspaceOptionMenu") workspaceOptionMenu: Menu;

  organizationOptionItems: MenuItem[];
  workspaceOptionItems: MenuItem[];

  session$: BehaviorSubject<SessionModel> = new BehaviorSubject(undefined);
  groupedUnits$: Observable<GroupedUserUnit[]>;

  UserRoleEnum = UserRoleEnum;

  get session() {
    return this.session$.getValue();
  }

  constructor(
    private router: Router,
    private messageService: MessageService,
    public translateService: TranslateService,
    public sessionAPIService: SessionAPIService,
    public authorizationService: AuthorizationService,
    public themingService: ThemingService,
  ) {}

  ngOnInit() {
    this.groupedUnits$ = this.sessionAPIService.session$.pipe(
      filter((session) => Boolean(session)),
      map((session) => {
        const h = this.generateHierarchy();
        return this.reorderUnits(h, session.unit.unitIdentifier);
      }),
      untilDestroyed(this),
    );

    this.sessionAPIService.session$.subscribe(this.session$);
  }

  handleClick(event: any) {
    if (this.unitsPanel) {
      this.unitsPanel.toggle(event);
    }
  }

  handleUnitClick(userUnit: UserUnitModel) {
    this.selectUnit(userUnit.unit);
  }

  selectUnit(unit: UnitModel): void {
    this.sessionAPIService
      .selectUnit(unit)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(["/"]);
      });
  }

  close() {
    if (this.unitsPanel) {
      this.unitsPanel.hide();
    }
  }

  setOrganizationOptionItems(userUnit: UserUnitModel) {
    this.organizationOptionItems = [
      {
        label: this.translateService.instant("SESSION.unit-menu.buttons.organization-settings", {
          default: "Workspace settings",
        }),
        icon: "pi pi-cog",
        command: () => this.handleOpenUnitSettings(userUnit, UnitSettingsPaths.UNIT_EDIT),
        visible: this.authorizationService.canManageUnit(userUnit),
      },
      {
        label: this.translateService.instant("SESSION.unit-menu.buttons.create-workspace", {
          default: "Add a new workspace",
        }),
        icon: "pi pi-plus",
        command: () => this.handleCreateChildUnit(userUnit),
        visible: this.authorizationService.canCreateChildUnit(userUnit),
      },
    ];
  }
  setWorkspaceOptionItems(userUnit: UserUnitModel) {
    this.workspaceOptionItems = [
      {
        label: this.translateService.instant("SESSION.unit-menu.buttons.workspace-settings", {
          default: "Workspace Settings",
        }),
        items: [
          {
            label: this.translateService.instant("UNIT.menu.edit", { default: "Edit unit" }),
            icon: "pi pi-palette",
            command: () => this.handleOpenUnitSettings(userUnit, UnitSettingsPaths.UNIT_EDIT),
          },
          {
            label: this.translateService.instant("UNIT.menu.users", { default: "Unit users" }),
            command: () => this.handleOpenUnitSettings(userUnit, UnitSettingsPaths.UNIT_USERS),
            icon: "pi pi-users",
            visible: this.authorizationService.canManageUnit(userUnit),
          },
          {
            label: this.translateService.instant("UNIT.menu.notification-templates", { default: "Mail Templates" }),
            icon: "pi pi-envelope",
            command: () => this.handleOpenUnitSettings(userUnit, UnitSettingsPaths.UNIT_NOTIFICATION),
            visible:
              this.authorizationService.canManageUnit(userUnit) &&
              this.authorizationService.canAccessUnitCustomization(),
          },
          {
            label: this.translateService.instant("UNIT.menu.billing", { default: "Billing" }),
            command: () => this.handleOpenUnitSettings(userUnit, UnitSettingsPaths.UNIT_BILLING),
            icon: "pi pi-credit-card",
            visible: this.authorizationService.canManageUnit(userUnit),
          },
          {
            label: this.translateService.instant("UNIT.menu.settings", { default: "Settings" }),
            icon: "pi pi-cog",
            command: () => this.handleOpenUnitSettings(userUnit, UnitSettingsPaths.UNIT_SETTINGS),
            visible:
              this.authorizationService.canManageUnit(userUnit) &&
              this.authorizationService.canAccessUnitCustomization(),
          },
        ],
      },
    ];
  }

  showOrganizationMenu(event: any, userUnit: UserUnitModel) {
    this.setOrganizationOptionItems(userUnit);
    this.organizationOptionMenu.toggle(event);
  }
  handleWorkspaceMenu(event: MouseEvent, userUnit: GroupedUserUnit) {
    this.setWorkspaceOptionItems(userUnit);
    this.workspaceOptionMenu.toggle(event);
  }
  handleOpenUnitSettings(userUnit: UserUnitModel, path: UnitSettingsPaths) {
    this.sessionAPIService
      .selectUnit(userUnit.unit)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate([path]);
      });
  }

  handleCreateChildUnit(userUnit: UserUnitModel) {
    this.sessionAPIService
      .selectUnit(userUnit.unit)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.router.navigate(["/units/create"]);
      });
  }

  handleCreateOrganization() {
    if (this.unitsPanel) {
      this.unitsPanel.hide();
    }
    this.router.navigate(["/units", "create-root"]);
  }

  generateHierarchy(): GroupedUserUnit[] {
    return this.flattenHierarchyChildren(this.session.user.units.map((userUnit) => this.buildHierarchy(userUnit)));
  }

  /**
   * Recursive function to reorder units and their children
   * @returns Ordered units
   */
  reorderUnits(units: GroupedUserUnit[], currentUnitIdentifier: string): GroupedUserUnit[] {
    // Use the custom sorting function to sort the items
    const sortedUnits = units.sort(customUnitSort);
    // Recursively reorder children
    sortedUnits.forEach((unit) => {
      if (unit.children.length > 0) {
        unit.children = this.reorderUnits(unit.children, currentUnitIdentifier);
      }
    });
    return sortedUnits;

    /**
     * Define a custom sorting function
     */
    function customUnitSort(a: GroupedUserUnit, b: GroupedUserUnit): number {
      // Keep the selected workspace to the top
      if (
        a.unit.unitIdentifier === currentUnitIdentifier ||
        a.children?.some((c) => c.unit.unitIdentifier == currentUnitIdentifier)
      )
        return -1;
      if (b.unit.unitIdentifier === currentUnitIdentifier) return 1;

      // Leave the personal workspace (Common) to the bottom
      if (a.unit.isPublic && !b.unit.isPublic) return 1;
      if (!a.unit.isPublic && b.unit.isPublic) return -1;

      // For the rest, sort by the role the user have: OWNER then MANAGER GUEST units.
      const roleOrder = { OWNER: 1, MANAGER: 2, GUEST: 3 };
      return roleOrder[a.userRole] - roleOrder[b.userRole];
    }
  }

  /**
   * Flattens the whole UserUnit hierarchy
   * @param hierarchy
   * @returns
   */
  flattenHierarchyChildren(hierarchy: GroupedUserUnit[]): GroupedUserUnit[] {
    // Create a Mapping of Id: children[]
    let childrenMap: ChildrenUnitMap = {};
    for (const { children, unit } of hierarchy) childrenMap[unit.unitIdentifier] = children;
    const topLevelIds = this.session.user.units
      /**
       * Units without a parent unit
       * but also units with a parent unit that they do not have access to
       * e.g: current user invited to a sub unit and not the organization
       * -> the sub unit is a top level unit from his point of view
       */
      .filter(
        (userUnit) =>
          !userUnit.unit.parentUnit ||
          (userUnit.unit.parentUnit && !Object.keys(childrenMap).includes(userUnit.unit.parentUnit.unitIdentifier)),
      )
      .map(({ unit }) => unit.unitIdentifier);
    hierarchy = hierarchy.filter(({ unit }) => topLevelIds.includes(unit.unitIdentifier));

    return hierarchy.map((topLevelUnit) => {
      const { children } = topLevelUnit;
      topLevelUnit.children = children.flatMap((child) => [child, ...this.flatten(childrenMap, child.unit)]);
      return topLevelUnit;
    });
  }

  /**
   * Nest direct children inside the provided UserUnit
   * @param unitIdentifier
   */
  buildHierarchy(userUnit: GroupedUserUnit): GroupedUserUnit {
    const children = this.session.user.units.filter(
      (childUserUnit) => userUnit.unit.unitIdentifier === childUserUnit.unit.parentUnit?.unitIdentifier,
    );

    userUnit.children = children.map((child) => this.buildHierarchy(child));
    return userUnit;
  }

  /**
   * Flattens all descendants of a GroupedUnit
   * @param childUnit
   * @param depth Recursive level
   */
  private flatten(childrenMap: ChildrenUnitMap, childUnit: UnitModel, depth: number = 0): GroupedUserUnit[] {
    const children = childrenMap[childUnit.unitIdentifier];
    const decendants = children.flatMap(({ unit }) => this.flatten(childrenMap, unit, depth + 1));
    return [...children, ...decendants];
  }
}
