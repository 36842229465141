import { Injectable } from "@angular/core";
import { Logger } from "@app/@core";
import { environment } from "@env/environment";

const log = new Logger("YousignIframeService");

type YousignModel = {
  signatureLink: string;
  iframeContainerId: string;
  isSandbox?: boolean;
};

type YousignEvent = "started" | "success" | "error" | "ping" | "declined";
/**
 * Based on the Yousign Iframe SDK API
 * Types infered from usage and https://developers.yousign.com/docs/iframe-advanced
 */
@Injectable({
  providedIn: "root",
})
export class YousignIframeService {
  private yousignInstance: Yousign;

  constructor() {}

  initialize(
    signatureLink: string,
    iframeContainerId: string = "iframe-container",
    isSandbox: boolean = environment.yousignV3.isSandbox,
  ) {
    // Initialize Yousign instance
    this.yousignInstance = new Yousign({
      signatureLink,
      iframeContainerId,
      isSandbox,
    });
  }

  getYousignInstance() {
    return this.yousignInstance;
  }
}

class Yousign {
  childOrigin: RegExp;
  eventCallbacks: { [key in YousignEvent]?: Function };
  urlParams: any;
  iframe: HTMLIFrameElement;
  messageHandler: (e: MessageEvent) => void;

  constructor({ signatureLink, iframeContainerId, isSandbox }: YousignModel) {
    this.childOrigin = /^https:\/\/yousign.app$/;
    this.eventCallbacks = {};

    let url: URL;
    try {
      url = new URL(signatureLink);
    } catch (e) {
      throw new Error("The signature link is invalid.");
    }

    const container = document.getElementById(iframeContainerId);
    if (!container) {
      throw new Error(`The iFrame container with the id "${iframeContainerId}" is not found.`);
    }

    if (isSandbox) url.searchParams.append("disable_domain_validation", "true");

    this.urlParams = new Proxy(url.searchParams, {
      get: (object, property: string) => object.get(property),
    });

    this.iframe = document.getElementById("yousign-iframe") as HTMLIFrameElement;
    if (!this.iframe) {
      this.iframe = document.createElement("iframe");
      this.iframe.id = "yousign-iframe";
      container.appendChild(this.iframe);
    }

    this.iframe.src = url.href;
    this.messageHandler = this.receiveMessage.bind(this);
    window.addEventListener("message", this.messageHandler, false);
  }

  receiveMessage(message: MessageEvent) {
    const { origin, data } = message;
    if (
      origin.match(this.childOrigin) &&
      "yousign" === data.type &&
      this.eventCallbacks[data.event] &&
      typeof this.eventCallbacks[data.event] === "function"
    ) {
      this.eventCallbacks[data.event](data);
    } else if ("__ubble" === data.type && data.payload.redirectUrl) {
      this.iframe.src = `${data.payload.redirectUrl}&k=${this.urlParams.k}`;
    }
  }

  /**
   * A function that is triggered when the signature is opened.
   * @param callback
   */
  onStarted(callback: Function) {
    if (typeof callback !== "function") {
      throw new Error("Callback on STARTED event is not a function.");
    }
    this.eventCallbacks.started = callback;
  }

  /**
   * A function that is triggered when the signature is signed successfully.
   * @param callback
   */
  onSuccess(callback: Function) {
    if (typeof callback !== "function") {
      throw new Error("Callback on SUCCESS event is not a function.");
    }
    this.eventCallbacks.success = callback;
  }

  /**
   * A function that is triggered when the signature encountered an error when signing.
   * @param callback
   */
  onError(callback: Function) {
    if (typeof callback !== "function") {
      throw new Error("Callback on ERROR event is not a function.");
    }
    this.eventCallbacks.error = callback;
  }

  /**
   * A function that is triggered every 5 seconds to inform the signature request is loaded.
   * @param callback
   */
  onPing(callback: Function) {
    if (typeof callback !== "function") {
      throw new Error("Callback on PING event is not a function.");
    }
    this.eventCallbacks.ping = callback;
  }

  /**
   * A function that is triggered when the signer declined the signature.
   * @param callback
   */
  onDeclined(callback: Function) {
    if (typeof callback !== "function") {
      throw new Error("Callback on DECLINED event is not a function.");
    }
    this.eventCallbacks.declined = callback;
  }

  /**
   * Cleanup function needed to stop listening to events
   */
  removeMessageListener() {
    window.removeEventListener("message", this.messageHandler);
  }
}
