<form class="flex flex-column gap-3" (ngSubmit)="submit()">
  <p-table
    [value]="contacts$ | async"
    [(selection)]="selectedContacts"
    selectionMode="multiple"
    dataKey="contactIdentifier"
    responsiveLayout="scroll"
    [loading]="isLoading$ | async"
    #contactsListTable
  >
    <ng-template pTemplate="caption">
      <div class="p-fluid">
        <div class="p-inputgroup">
          <input #searchInput pInputText autofocus type="search" (keydown.enter)="handleFiltering($event)" />
          <button type="button" (click)="handleFiltering($event)" pButton icon="pi pi-search"></button>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr class="relative cursor-pointer">
        <td class="w-1rem">
          <p-tableCheckbox #checkbox [value]="rowData"></p-tableCheckbox>
        </td>
        <td class="pl-0 py-1" (click)="checkbox.onClick($event)">
          <contacts-contact-card [contact]="rowData"></contacts-contact-card>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <div
        class="text-color-secondary flex flex-column align-items-center justify-content-center surface-card border-round py-4 lg:py-8"
      >
        <i class="pi pi-search text-4xl"></i>
        <p class="h4 mb-0">
          {{ "ADDRESS-BOOK.search.empty" | translate : { "default": "No contacts found for your search" } }}
        </p>
        <button
          pButton
          class="p-button-sm mt-3"
          *ngIf="searchText"
          [label]="('COMMON.buttons.save' | translate) + ' (' + searchText + ')'"
          type="button"
          (click)="onContactSearched.emit(searchText)"
        ></button>
      </div>
    </ng-template>
  </p-table>

  <div class="dialog-nested-footer flex flex-wrap align-items-center">
    <span class="text-color-secondary" *ngIf="selectedContacts">
      {{
        selectedContacts.length > 0
          ? ("LAYOUTS.adress-book.contact-list.count" | translate : { count: selectedContacts.length })
          : ("LAYOUTS.adress-book.contact-list.none-selected" | translate : { count: selectedContacts.length })
      }}
    </span>
    <button
      pButton
      [label]="'COMMON.buttons.cancel' | translate : { 'default': 'Cancel' }"
      type="button"
      (click)="cancel()"
      class="p-button-outlined p-button-sm mr-3 ml-auto"
      [disabled]="isLoading || isSubmitting"
    ></button>
    <button
      pButton
      [label]="'COMMON.buttons.add-selection' | translate : { 'default': 'Add selection' }"
      type="submit"
      class="p-button-sm"
      [loading]="isSubmitting"
    ></button>
  </div>
</form>
