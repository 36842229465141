import BaseModel from "@shared/models/base.model";
import ExportResultModel from "./export.model";

export default class ExportModel extends BaseModel {
  // MINIMIZE (default)
  public export: ExportResultModel[] = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
