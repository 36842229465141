import PaginationModel from "@shared/models/pagination.model";
import RecordMessageTemplateModel from "./record-message-template.model";

export default class RecordMessageTemplatesResultModel extends PaginationModel {
  public templates: RecordMessageTemplateModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.templates && Array.isArray(input.templates))
      this.templates = input.templates.map((template: any) => new RecordMessageTemplateModel().deserialize(template));

    return this;
  }
}
