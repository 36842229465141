<ng-container *ngIf="record$ | async as record">
  <ng-container *ngIf="answeredMessages$ | async as answeredMessages">
    <div
      class="dialog-nested-content measured-container measure-xs flex flex-column gap-3"
      style="min-height: 30rem"
      *ngIf="answeredMessages.length > 0; else answersEmpty"
    >
      <div *ngIf="canValidateFormAnswers">
        <p class="text-color-secondary">
          {{
            "RECORD.form-item-answer.validation.description"
              | translate
                : {
                    default:
                      "You can carefully review each answer from your recipients and decide whether to approve or reject it based on its correctness. If an item is rejected, the recipient has the opportunity to fill it again."
                  }
          }}
        </p>
        <!-- Reviewed Percentage -->
        <ng-container *ngIf="displayStatistics && (answeredReviewedPercentage$ | async) > 0">
          <span>Pourcentage de réponses examinées</span>
          <p-progressBar [showValue]="true" [value]="answeredReviewedPercentage$ | async"></p-progressBar>
        </ng-container>
      </div>

      <ng-container *ngFor="let message of answeredMessages">
        <div class="surface-card border-round p-3 surface-border border-1">
          <div class="flex flex-wrap align-items-center justify-content-between gap-1">
            <span
              style="cursor: default"
              class="flex flex-wrap align-items-center"
              [tooltip]="message.sender.emailAddress"
              [delay]="300"
            >
              <span>{{ message.sender.toString() }}</span>
              <span
                *ngIf="authorizationService.isCurrentUser(message.sender)"
                class="font-normal text-color-secondary text-sm ml-1"
                >({{ "COMMON.you" | translate : { default: "You" } }})</span
              >
            </span>
            <span
              style="
                text-decoration: underline;
                text-decoration-color: var(--text-color-secondary);
                text-decoration-style: dotted;
                text-underline-offset: 2px;
              "
              class="text-color-secondary"
              placement="bottom"
              [tooltip]="message.createdAt | date : 'medium'"
              [delay]="300"
            >
              {{ message.createdAt | timeAgo | async }}
            </span>
          </div>
          <p-divider styleClass="my-1"></p-divider>
          <div class="stack gap-3">
            <div class="pt-3" *ngFor="let answer of message.answers">
              <record-form-item-answer
                *ngIf="answer.isFormAnswer"
                [enableAnswersValidation]="canValidateFormAnswers"
                [showValidationBadge]="false"
                [answerValue]="answer"
                [answerAttachments]="getAnswerAttachments(answer.itemIdentifier, message)"
                [messageIdentifier]="message.messageIdentifier"
                [formItem]="getFormItem(answer.itemIdentifier)"
                [record]="record"
                [wrappedKeys]="wrappedKeys"
              ></record-form-item-answer>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <footer
      class="dialog-nested-footer gap-3 flex flex-wrap justify-content-between"
      *ngIf="answeredMessages.length > 0"
    >
      <button
        pButton
        [label]="'COMMON.buttons.back-to-conversation' | translate : { 'default': 'Back to conversation' }"
        type="button"
        icon="pi pi-arrow-left"
        (click)="cancel()"
        class="p-button-outlined p-button-secondary p-button-sm hidden md:flex"
      ></button>
      <button
        pButton
        [label]="'RECORD.actions.answers.buttons.export-csv' | translate : { 'default': 'Export answers' }"
        type="button"
        (click)="handleExportFormAnswers()"
        icon="pi pi-download"
        *ngIf="answeredMessages.length > 0"
        class="p-button-sm p-button-secondary w-full md:w-auto"
        [loading]="isDownloading$ | async"
        [disabled]="isDownloading$ | async"
      ></button>
    </footer>

    <ng-template #answersEmpty>
      <div class="border-round flex flex-column align-items-center py-4 lg:py-8 justify-content-center">
        <svg width="3rem" viewBox="0 0 19 18" style="fill: var(--primary-color, inherit)">
          <path
            d="M3.4691966,8.96961455 C3.49562516,9.00003118 3.53394581,9.01747637 3.57424023,9.01747637 L3.57433478,9.01747637 C3.61458565,9.01747637 3.65285727,9.00001625 3.6792366,8.96961455 L4.20454932,8.36540364 C4.24982164,8.31295392 4.24982164,8.23524972 4.20454932,8.1828 L3.14743296,6.96675636 C2.65245143,6.40196783 2.40380347,5.66273396 2.45684023,4.91361455 C2.5643456,3.49115969 3.75105788,2.39256262 5.1775675,2.39490545 C5.99687029,2.39475933 6.77600843,2.74977065 7.31349114,3.36813091 L12.140633,8.92118545 C12.1758079,8.96164933 12.2267922,8.9848841 12.2804075,8.9848841 C12.3340228,8.9848841 12.3850071,8.96164933 12.4201821,8.92118545 L12.8844511,8.38719273 C12.9446914,8.31738197 12.9446914,8.2139744 12.8844511,8.14416364 L8.12183296,2.66539636 C6.74196387,1.07802909 4.3283675,0.871403636 2.74161478,2.20498545 C1.95638469,2.8644103 1.46891321,3.8115882 1.38866012,4.83383418 C1.30840702,5.85608017 1.64209792,6.86772477 2.31480387,7.64160727 L3.4691966,8.96961455 Z"
          ></path>
          <path
            d="M9.95166205,4.00444 L9.95166205,4.00448727 C9.97103512,4.02512166 9.99808141,4.03682217 10.026385,4.03681321 C10.0546886,4.03680426 10.0817275,4.02508664 10.1010875,4.00444 L10.6896402,3.32730545 C11.1739384,2.77017805 11.8617306,2.43093808 12.5985453,2.38577562 C13.3353601,2.34061316 14.0594349,2.59331389 14.6081493,3.08712364 C15.7176039,4.08444364 15.807673,5.83069455 14.8089166,6.97977091 L10.7681493,11.6280255 C10.3191105,12.1445676 9.66826097,12.4411701 8.98382569,12.4411701 C8.2993904,12.4411701 7.64854086,12.1445676 7.19950205,11.6280255 L5.81826932,10.0392218 C5.78310381,9.99875885 5.73212686,9.97552367 5.67851841,9.97552367 C5.62490997,9.97552367 5.57393302,9.99875885 5.5387675,10.0392218 L5.04822205,10.6034073 C5.00289833,10.655882 5.00289833,10.7336525 5.04822205,10.7861273 L6.39105114,12.3308545 C7.04352404,13.0814221 7.98924263,13.5124046 8.98376387,13.5124046 C9.97828511,13.5124046 10.9240037,13.0814221 11.5764766,12.3308545 L15.6172766,7.68250545 C16.9973348,6.09511273 16.8659384,3.67625091 15.3243602,2.29047636 C14.5620724,1.6046504 13.5562583,1.25374759 12.532787,1.3165679 C11.5093156,1.37938821 10.5539667,1.85066651 9.8812766,2.62457091 L9.37321841,3.20901455 C9.34102919,3.24633839 9.34102919,3.30160707 9.37321841,3.33893091 L9.95166205,4.00444 Z"
          ></path>
          <path
            d="M15.9384693,11.6572982 L14.4896984,9.99072727 C14.4632623,9.96032151 14.4249478,9.9428627 14.3846566,9.9428627 C14.3443654,9.9428627 14.3060509,9.96032151 14.2796148,9.99072727 L13.7806111,10.5648145 C13.7203719,10.6346271 13.7203719,10.7380348 13.7806111,10.8078473 L15.1299821,12.3602109 C15.6382763,12.9449421 15.7582879,13.7726381 15.4370003,14.4776544 C15.1157128,15.1826707 14.4123153,15.6351256 13.6375421,15.6351418 L4.33006205,15.6351418 C3.55527482,15.6351561 2.85184731,15.1827194 2.53052871,14.4777019 C2.20921011,13.7726845 2.32919574,12.9449691 2.83748023,12.3602109 L8.54986205,5.78888 C8.61052787,5.71855694 8.61052787,5.6144067 8.54986205,5.54408364 L8.05161478,4.97099273 C8.02517665,4.94058901 7.98686209,4.92313178 7.94657114,4.92313178 C7.90628019,4.92313178 7.86796564,4.94058901 7.8415275,4.97099273 L2.02913841,11.6572982 C1.24548427,12.5587973 1.06045517,13.8348821 1.55578929,14.9218313 C2.05112342,16.0087805 3.13556868,16.7063891 4.33006205,16.7063891 L13.5975384,16.7063891 C14.8188075,16.7063891 15.9257602,16.0008764 16.4175639,14.9090291 C16.9138299,13.8255877 16.7261227,12.551573 15.9384693,11.6572982 L15.9384693,11.6572982 Z"
          ></path>
        </svg>
        <h3 class="mb-0 px-3 text-center">
          {{ "RECORD.empty-states.answers.title" | translate : { default: "Nothing to show here" } }}
        </h3>
        <p class="text-center mb-0 mt-2 measured-container" style="--measure: var(--legible-measure)">
          {{
            "RECORD.empty-states.answers.subtitle"
              | translate
                : {
                    default: "Answers to your requests will show here"
                  }
          }}
        </p>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
