<div
  class="flex flex-column gap-2 p-fluid"
  #tagsMultiselectContainer
  *ngIf="!(isLoadingTagsList$ | async); else tagsMultiselectLoader"
>
  <p-listbox
    [options]="filteredTagSuggestions"
    [(ngModel)]="tags"
    [checkbox]="false"
    [filter]="true"
    filterBy="label"
    optionLabel="label"
    [showToggleAll]="false"
    [disabled]="isEditing"
    optionValue="label"
    [multiple]="true"
    [metaKeySelection]="false"
    #tagsListbox
  >
    <ng-template pTemplate="filter" let-options="options">
      <div class="p-listbox-filter-container">
        <div class="field">
          <label for="tagsFormInput">
            {{ "RECORD.record-tags.filter.label" | translate }}
          </label>
          <p-chips
            [attr.aria-label]="'RECORD.record-tags.filter.label' | translate"
            (input)="options.filter($event)"
            [allowDuplicate]="false"
            [(ngModel)]="tags"
            inputId="tagsFormInput"
            (onAdd)="handleTagAdded($event)"
          >
          </p-chips>
        </div>
      </div>
    </ng-template>
    <!-- Suggestions -->
    <ng-template pTemplate="item" let-tag>
      <!-- TODO: Store truncate value and use accross -->
      <ui-badge
        cssMaxWidth="20ch"
        [hoverable]="true"
        size="small"
        icon="pi pi-plus-circle text-sm opacity-70"
        variant="suggestion"
        [label]="tag.label"
      ></ui-badge>
    </ng-template>
    <!-- Create new label button -->
    <ng-template pTemplate="footer">
      <ng-container *ngIf="tagsListbox.filterValue">
        <p-button
          type="button"
          styleClass="text-left p-button-sm p-button-text py-1 px-1 flex mt-3"
          [style]="{ 'background-color': 'rgba(var(--primary-color-rgb), 0.04)' }"
          (click)="handleCreateNewTag(tagsListbox.filterValue)"
        >
          <ng-template pTemplate="content">
            <div class="flex px-1 align-items-baseline">
              <span class="font-medium">
                {{ "RECORD.record-tags.buttons.create" | translate : { default: "Create tag" } }}
              </span>
            </div>
            <ui-badge
              cssMaxWidth="20ch"
              size="small"
              variant="suggestion"
              icon="pi pi-plus-circle text-sm opacity-70"
              class="ml-1"
              [label]="tagsListbox.filterValue"
            ></ui-badge>
          </ng-template>
        </p-button>
      </ng-container>
    </ng-template>
  </p-listbox>
</div>

<!-- TAGS MULTISELECT LOADER -->
<ng-template #tagsMultiselectLoader>
  <div
    [style]="{
      '--skeleton-background': 'var(--primary-100)'
    }"
  >
    <p-skeleton
      animation="none"
      width="30ch"
      height="1rem"
      styleClass="border-noround mt-1"
      [style]="{
        '--skeleton-background': 'var(--surface-300)'
      }"
    ></p-skeleton>
    <p-skeleton
      animation="none"
      width="100%"
      height="2.2rem"
      styleClass="mt-2 mb-3 opacity-70 border-1 surface-border border-round"
    ></p-skeleton>
    <div class="flex flex-wrap gap-1">
      <p-skeleton
        width="15ch"
        height="1.5rem"
        styleClass="mt-1 opacity-70 border-1 surface-border border-round"
        [style]="{ '--border-radius': '2rem' }"
      ></p-skeleton>
      <p-skeleton
        width="10ch"
        height="1.5rem"
        styleClass="mt-1 opacity-70 border-1 surface-border border-round"
        [style]="{ '--border-radius': '2rem' }"
      ></p-skeleton>
    </div>
  </div>
</ng-template>
