import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Logger } from "@app/@core";
import { PDFDocumentProxy } from "ng2-pdf-viewer";
import { KeycloakService } from "keycloak-angular";
import RecordModel from "@shared/models/record/record.model";
import { environment } from "@env/environment";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";

/** https://github.com/VadimDez/ng2-pdf-viewer **/

const logger = new Logger("PdfViewerComponent");

@Component({
  selector: "main-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  @Input() attachment: RecordMessageAttachmentModel;

  @Input() record: RecordModel;
  @Input() height: number = 500;
  @Output() loadingCompleted = new EventEmitter<boolean>();
  @Output() allPagesRendered = new EventEmitter<boolean>();
  @Output() onPDFLoadedChange = new EventEmitter<boolean>();
  @Output() onCurrentPageChange = new EventEmitter<number>();
  @Output() onTotalPagesChange = new EventEmitter<number>();

  @Input() pdfLoaded: boolean = false;
  @Input() totalPages: number;
  @Input() currentPage: number;
  @Input() zoom: number = 50;
  accessToken: string;

  get currentZoom() {
    return this.zoom / 100;
  }

  constructor(private keycloakService: KeycloakService) {}

  ngOnInit(): void {
    this.keycloakService.getToken().then((accessToken) => {
      this.accessToken = accessToken;
    });
  }

  ngOnDestroy() {}

  get fileUrl() {
    return (
      environment.services.baseUrls.filesApiUrl +
      environment.services.methodUrls.files.recordDownload.replace(
        "{fileIdentifier}",
        this.attachment.file.fileIdentifier,
      )
    );
  }

  onPageRendered(e: CustomEvent) {
    logger.info("Page rendered", e);
  }

  onPagesInitialized(e: CustomEvent) {
    logger.info("Pages initialized", e);

    if (this.totalPages > 0) {
      this.onCurrentPageChange.emit(1);
      if (this.currentPage === this.totalPages) {
        this.allPagesRendered.emit(true);
      }
    }
  }

  onLoadComplete(pdf: PDFDocumentProxy) {
    logger.info("File loading complete: ", pdf);

    this.onPDFLoadedChange.emit(true);
    this.onTotalPagesChange.emit(pdf.numPages);
    this.loadingCompleted.emit(true);
  }

  onTextLayerRendered(e: CustomEvent) {
    logger.info("Text layer rendered", e);
  }
}
