<li class="flex lg:justify-content-center">
  <a
    data-focus
    *ngIf="sidebarItem.link"
    (click)="handleMenuItemClick($event)"
    [routerLink]="sidebarItem.link"
    p-button
    [pTooltip]="sidebarItem.tooltip"
    tooltipPosition="right"
    appendTo="body"
    routerLinkActive="active"
    class="sidebarLink w-full lg:w-5rem flex flex-row lg:flex-column align-items-center cursor-pointer py-2 lg:justify-content-center border-round transition-duration-150 transition-colors no-underline"
  >
    <i
      [ngClass]="[
        sidebarItem.primeIcon ? sidebarItem.primeIcon + 'text-base lg:text-2xl' : '',
        sidebarItem.msrIcon ? 'material-symbols-rounded icon-28px' : ''
      ]"
      class="sidebarLink__icon ml-2 lg:ml-0 mr-2 lg:mr-0 mb-0 text-base lg:text-2xl"
    >
      <ng-container *ngIf="sidebarItem.msrIcon">{{ sidebarItem.msrIcon }}</ng-container>
    </i>
    <span class="font-medium inline text-base lg:text-xs text-center lg:mt-2">{{ sidebarItem.label }}</span>
  </a>
  <div class="flex w-full justify-content-center text-center lg:text-left mb-0" *ngIf="sidebarItem.separator">
    <p-divider></p-divider>
  </div>
</li>
