import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ContactOrderEnum } from "@app/@shared/enums/contact-order.enum";
import ProviderApplicationModel from "@app/@shared/models/providers/provider-application.model";
import ProviderContactModel from "@app/@shared/models/providers/provider-contact.model";
import { ProviderContactsService } from "@app/contacts/services/provider-contacts.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { LazyLoadEvent } from "primeng/api";
type TableColumn = {
  field: string;
  header: string;
  classnames?: string;
};
@UntilDestroy()
@Component({
  selector: "provider-contact-table",
  templateUrl: "./provider-contact-table.component.html",
  styleUrls: ["./provider-contact-table.component.scss"],
})
export class ProviderContactTableComponent {
  @Input() contacts: ProviderContactModel[] = [];
  @Input() isLoadingContacts: boolean = true;
  @Input() currentApplication: ProviderApplicationModel;

  _selectedContacts: ProviderContactModel[];
  get total() {
    return this.providerContactsService.total;
  }
  @Input()
  set selectedContacts(value: ProviderContactModel[]) {
    this._selectedContacts = value;
    this.selectedContactsChange.emit(value);
  }
  get selectedContacts() {
    return this._selectedContacts;
  }

  @Output() selectedContactsChange = new EventEmitter<ProviderContactModel[]>();
  paginationTemplate: string;
  cols: TableColumn[];

  constructor(private translateService: TranslateService, private providerContactsService: ProviderContactsService) {}
  ngOnInit() {
    this.setTableColumns();
    this.setPaginationTemplate();
  }

  setTableColumns() {
    this.cols = [
      {
        field: "emailAddresses",
        header: this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" }),
        classnames: "font-medium",
      },
      {
        field: "givenName",
        header: this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" }),
      },
      {
        field: "surname",
        header: this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" }),
      },
      {
        field: "phoneNumbers",
        header: this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" }),
      },
    ];
  }
  setPaginationTemplate() {
    this.paginationTemplate =
      "{first} " +
      this.translateService.instant("COMMON.text.to") +
      " {last} " +
      this.translateService.instant("COMMON.text.of") +
      " {totalRecords} " +
      this.translateService.instant("COMMON.text.contacts");
  }

  loadContactsLazy(event: LazyLoadEvent) {
    let order: ContactOrderEnum;

    switch (event.sortField) {
      case "emailAddress":
        order = event.sortOrder > 0 ? ContactOrderEnum.EMAIL_ASC : ContactOrderEnum.EMAIL_DESC;
        break;
      case "phoneNumber":
        order = event.sortOrder > 0 ? ContactOrderEnum.PHONE_ASC : ContactOrderEnum.PHONE_DESC;
        break;
      case "firstName":
        order = event.sortOrder > 0 ? ContactOrderEnum.FIRSTNAME_ASC : ContactOrderEnum.FIRSTNAME_DESC;
        break;
      case "lastName":
        order = event.sortOrder > 0 ? ContactOrderEnum.LASTNAME_ASC : ContactOrderEnum.LASTNAME_DESC;
        break;
      default:
        order = null;
        break;
    }

    if (this.currentApplication) this.providerContactsService.initialize(this.currentApplication.applicationIdentifier);
  }
}
