import { AbstractControl, ValidationErrors } from "@angular/forms";
import { dueDateGtOrEqExpirationDateValidator } from "./due-date-gte-expiration-date.validator";
import { recipientsNotEmptyValidator } from "./recipients-not-empty.validator";
import { recipientsValidForSignatureValidator } from "./recipients-valid-for-signature.validator";

export class RecordValidators {
  /**
   * Validates that a record's due date is greater or equals its expiration date
   * Can be used in form groups as RecordValidators.dueDateGtOrEqExpirationDate
   * @param control
   * @returns
   */
  static dueDateGtOrEqExpirationDate(control: AbstractControl): ValidationErrors | null {
    return dueDateGtOrEqExpirationDateValidator(control);
  }

  /**
   * Validates that a recipients list is not empty (contains at least 1 writer)
   * Can be used in form groups as RecordValidators.recipientsNotEmpty
   * @param control
   * @returns
   */
  static recipientsNotEmpty(control: AbstractControl): ValidationErrors | null {
    return recipientsNotEmptyValidator(control);
  }

  /**
   * Validates that a recipients list contains only recipients that are valid for signature
   * A valid recipient must have :
   * *  First name not empty
   * *  Last name not empty
   * *  Email not empty and valid
   * *  Phone number not empty and valid
   * Can be used in form groups as RecordValidators.recipientsValidForSignature
   * @param control
   * @returns
   */
  static recipientsValidForSignature(control: AbstractControl): ValidationErrors | null {
    return recipientsValidForSignatureValidator(control);
  }
}
