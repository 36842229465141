import { Component, Input, OnInit } from "@angular/core";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { orderBy } from "lodash";
import { BehaviorSubject, Observable, tap } from "rxjs";

/**
 * Enrich message with custom attributes used for styling (answered, validation)
 * @class RecordMessageDecryptedComponent
 */
@UntilDestroy()
@Component({
  selector: "record-message-decrypted",
  templateUrl: "./message-decrypted.component.html",
  styleUrls: ["./message-decrypted.component.scss"],
})
export class RecordMessageDecryptedComponent implements OnInit {
  @Input() wrapperClassnames: string = null;
  @Input() decryptedMessage: string = null;
  @Input() message: RecordMessageModel;
  processedMessage$: BehaviorSubject<string> = new BehaviorSubject(null);

  // htmlDocument observable
  private _htmlDocument$: BehaviorSubject<Document> = new BehaviorSubject(null);
  htmlDocument$: Observable<Document> = this._htmlDocument$.asObservable();

  ngOnInit(): void {
    let answeredFormItems = this.getAnsweredFormItems();
    if (answeredFormItems?.length > 0 && this.decryptedMessage) {
      this._htmlDocument$.next(new DOMParser().parseFromString(this.decryptedMessage, "text/html"));
    }

    this.htmlDocument$.pipe(untilDestroyed(this)).subscribe((messageDocument) => {
      if (messageDocument) {
        let answeredSelectors = answeredFormItems.map(
          (formItem) => `.ql-form-item[data-identifier="${formItem.uniqueId}"]`,
        );
        if (answeredSelectors?.length > 0) {
          let answeredElements = messageDocument.querySelectorAll(answeredSelectors.join(","));
          answeredElements.forEach((el) => el.setAttribute("data-answered", ""));
        }
        this.processedMessage$.next(messageDocument.body.innerHTML);
      }
    });
  }

  getAnsweredFormItems() {
    return orderBy(
      this.message?.formItems.filter((formItem: RecordMessageFormItemModel) =>
        Boolean(this.getAnswerValue(formItem.itemIdentifier)),
      ),
      "position",
      "asc",
    );
  }

  getAnswerValue(formItemIdentifier: string): RecordMessageAnswerModel | undefined {
    return this.message.getLastFormAnswer(formItemIdentifier);
  }
}
