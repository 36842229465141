import { get } from "lodash";
import { DataTypeFormatEnum } from "../enums/data-type-format.enum";
import { FormDataControlEnum } from "../enums/form-data/form-data-control.enum";
import { FormDataFormatterTypeEnum } from "../enums/form-data/form-data-formatter-type.enum";
import { FormDataImportanceEnum } from "../enums/form-data/form-data-importance.enum";
import { FormDataValidationTypeEnum } from "../enums/form-data/form-data-validation-type.enum";
import BaseModel from "./base.model";

export default class FormDataModel extends BaseModel {
  public format: DataTypeFormatEnum = null;
  public properties: JsonValue = {};

  constructor() {
    super();
  }

  /**
   * Importance
   */
  getImportance(): FormDataImportanceEnum {
    return get(this.properties, ["importance"], null);
  }

  /**
   * Validations
   */

  getValidations(): FormDataValidation[] {
    return get(this.properties, ["validation"], {}) as FormDataValidation[];
  }

  /**
   *  Labels
   */
  getLabels(): FormDataLabels {
    return get(this.properties, ["labels"], null) as FormDataLabels;
  }

  getLabel(locale: string): string | undefined {
    let labels = this.getLabels();
    return labels && labels[locale];
  }

  /**
   *  Descriptions
   */
  getDescriptions(): FormDataDescriptions {
    return get(this.properties, ["descriptions"], null) as FormDataDescriptions;
  }

  getDescription(locale: string): string | undefined {
    let descriptions = this.getDescriptions();
    return descriptions && descriptions[locale];
  }

  /**
   *  Placeholders
   */
  getPlaceholders(): FormDataPlaceholders {
    return get(this.properties, ["placeholders"], null) as FormDataPlaceholders;
  }

  getPlaceholder(locale: string): string | undefined {
    let placeholders = this.getPlaceholders();
    return placeholders && placeholders[locale];
  }

  /**
   * Control texts
   */
  getControlTexts(): FormDataControlTexts {
    return get(this.properties, ["controlTexts"], null) as FormDataControlTexts;
  }

  getControlTextValues(locale: string): { [x: string]: string } | undefined {
    return this.getControlTexts()[locale];
  }

  getControlTextValue(locale: string, value: string): string | undefined {
    let controlTextValues = this.getControlTextValues(locale);

    if (controlTextValues) {
      return controlTextValues[value];
    }

    return undefined;
  }

  /**
   * Default value
   */
  getDefaultValue(): string | number | boolean {
    return get(this.properties, ["defaultValue"], null);
  }

  /**
   * Control
   */
  getControl(): FormDataControlEnum | undefined {
    return get(this.properties, ["control"], this.getDefaultControl()) as FormDataControlEnum;
  }

  getDefaultControl(): FormDataControlEnum {
    let format = this.format;

    switch (format) {
      case DataTypeFormatEnum.ADDRESS:
        return FormDataControlEnum.ADDRESS_FORM;
      case DataTypeFormatEnum.BOOLEAN:
        return FormDataControlEnum.SWITCH;
      case DataTypeFormatEnum.CHOICE:
        return FormDataControlEnum.RADIO_BUTTONS;
      case DataTypeFormatEnum.CURRENCY:
        return FormDataControlEnum.NUMBER;
      case DataTypeFormatEnum.DATE:
      case DataTypeFormatEnum.DATETIME:
      case DataTypeFormatEnum.TIME:
        return FormDataControlEnum.CALENDAR;
      case DataTypeFormatEnum.DOCUMENTS:
        return FormDataControlEnum.UPLOAD_DROPZONE;
      case DataTypeFormatEnum.EMAIL:
        return FormDataControlEnum.EMAIL;
      case DataTypeFormatEnum.LONGTEXT:
        return FormDataControlEnum.TEXTAREA;
      case DataTypeFormatEnum.MULTICHOICE:
        return FormDataControlEnum.CHECKBOXES;
      case DataTypeFormatEnum.NUMBER:
        return FormDataControlEnum.NUMBER;
      case DataTypeFormatEnum.PHONE:
        return FormDataControlEnum.PHONE;
      case DataTypeFormatEnum.RANGE:
        return FormDataControlEnum.SLIDER_RANGE;
      case DataTypeFormatEnum.RATING:
        return FormDataControlEnum.RATING;
      case DataTypeFormatEnum.TEXT:
        return FormDataControlEnum.MASKED_TEXT;
      default:
        return null;
    }
  }

  /**
   * Options
   */
  getOptions(): FormDataOption[] {
    const options = get(this.properties, ["options"], null);

    if (options) {
      return options.map((o) => new FormDataOption().deserialize(o));
    }

    return null;
  }

  getOption(value: string): FormDataOption {
    let options = this.getOptions();
    return options && options.find((option: FormDataOption) => option.value === value);
  }

  /**
   * Formatters
   */
  getFormatters(): FormDataFormatter[] {
    return get(this.properties, ["formatters"], null) as FormDataFormatter[];
  }

  getFormatter(type: FormDataFormatterTypeEnum): FormDataFormatter | undefined {
    let formatters = this.getFormatters();
    return formatters && formatters.find((formatter: FormDataFormatter) => formatter.type === type);
  }

  /**
   * Control properties & formatters
   */
  hasControlProperty(property: string): boolean {
    return this.getControlProperties().includes(property);
  }

  hasControlFormatter(formatter: FormDataFormatterTypeEnum): boolean {
    return this.hasControlProperty("formatters") && this.getControlFormatters().includes(formatter);
  }

  getControlProperties(): string[] {
    switch (this.format) {
      case DataTypeFormatEnum.ADDRESS:
        return ["importance"];
      case DataTypeFormatEnum.BOOLEAN:
        return ["controlTexts", "formatters", "importance"];
      case DataTypeFormatEnum.CHOICE:
        return ["options", "defaultValue", "importance"];
      case DataTypeFormatEnum.CURRENCY:
        return ["formatters", "placeholders", "importance"];
      case DataTypeFormatEnum.DATE:
        return ["formatters", "defaultValue", "importance"];
      case DataTypeFormatEnum.DATETIME:
        return ["formatters", "defaultValue", "importance"];
      case DataTypeFormatEnum.DOCUMENTS:
        return ["formatters", "importance"];
      case DataTypeFormatEnum.EMAIL:
        return ["placeholders", "importance"];
      case DataTypeFormatEnum.LONGTEXT:
        return ["placeholders", "importance"];
      case DataTypeFormatEnum.MULTICHOICE:
        return ["options", "importance"];
      case DataTypeFormatEnum.NUMBER:
        return ["formatters", "placeholders", "importance"];
      case DataTypeFormatEnum.PHONE:
        return ["importance"];
      case DataTypeFormatEnum.RANGE:
        return ["formatters", "importance"];
      case DataTypeFormatEnum.RATING:
        return ["formatters", "importance"];
      case DataTypeFormatEnum.TEXT:
        return ["formatters", "defaultValue", "placeholders", "importance"];
      case DataTypeFormatEnum.TIME:
        return ["formatters", "importance"];
      default:
        return [];
    }
  }

  getControlFormatters(): FormDataFormatterTypeEnum[] {
    switch (this.format) {
      case DataTypeFormatEnum.BOOLEAN:
        return [FormDataFormatterTypeEnum.ORIENTATION];
      case DataTypeFormatEnum.CHOICE:
        return [FormDataFormatterTypeEnum.ORIENTATION];
      case DataTypeFormatEnum.CURRENCY:
        return [
          FormDataFormatterTypeEnum.MIN,
          FormDataFormatterTypeEnum.MAX,
          FormDataFormatterTypeEnum.STEP,
          FormDataFormatterTypeEnum.CURRENCY,
          FormDataFormatterTypeEnum.CURRENCY_DISPLAY,
        ];
      case DataTypeFormatEnum.DATE:
        return [
          FormDataFormatterTypeEnum.MIN_DATE,
          FormDataFormatterTypeEnum.MAX_DATE,
          FormDataFormatterTypeEnum.SPECIFIC_DATES,
        ];
      case DataTypeFormatEnum.DATETIME:
        return [
          FormDataFormatterTypeEnum.MIN_DATE,
          FormDataFormatterTypeEnum.MAX_DATE,
          FormDataFormatterTypeEnum.SPECIFIC_DATES,
          FormDataFormatterTypeEnum.HOUR_STEP,
          FormDataFormatterTypeEnum.MINUTE_STEP,
        ];
      case DataTypeFormatEnum.DOCUMENTS:
        return [
          FormDataFormatterTypeEnum.MAX_FILES,
          FormDataFormatterTypeEnum.MAX_FILE_SIZE,
          FormDataFormatterTypeEnum.FILE_ACCEPT,
          FormDataFormatterTypeEnum.FILE_RENAMING,
        ];
      case DataTypeFormatEnum.NUMBER:
        return [
          FormDataFormatterTypeEnum.MIN,
          FormDataFormatterTypeEnum.MAX,
          FormDataFormatterTypeEnum.STEP,
          FormDataFormatterTypeEnum.NUMBER_TYPE,
          FormDataFormatterTypeEnum.DECIMAL_COUNT,
        ];
      case DataTypeFormatEnum.RANGE:
        return [
          FormDataFormatterTypeEnum.MIN,
          FormDataFormatterTypeEnum.MAX,
          FormDataFormatterTypeEnum.STEP,
          FormDataFormatterTypeEnum.PREFIX,
          FormDataFormatterTypeEnum.SUFFIX,
        ];
      case DataTypeFormatEnum.RATING:
        return [FormDataFormatterTypeEnum.RATING_STARS];
      case DataTypeFormatEnum.TEXT:
        return [
          FormDataFormatterTypeEnum.MASK,
          FormDataFormatterTypeEnum.MASK_CHARACTER_PATTERN,
          FormDataFormatterTypeEnum.MAX_LENGTH,
        ];
      case DataTypeFormatEnum.TIME:
        return [FormDataFormatterTypeEnum.HOUR_STEP, FormDataFormatterTypeEnum.MINUTE_STEP];
      default:
        return [];
    }
  }

  parseFormatterTypeValue(formatterType: FormDataFormatterTypeEnum, value: any) {
    if (!value) {
      return value;
    }

    switch (formatterType) {
      case FormDataFormatterTypeEnum.MIN_DATE:
      case FormDataFormatterTypeEnum.MAX_DATE:
        return new Date(value);
      case FormDataFormatterTypeEnum.SPECIFIC_DATES:
        return Array.isArray(value) ? value.map((val) => new Date(val)) : null;
      case FormDataFormatterTypeEnum.FILE_ACCEPT:
        return value;
      case FormDataFormatterTypeEnum.NUMBER_TYPE:
        return "decimal";
      case FormDataFormatterTypeEnum.DECIMAL_COUNT:
      case FormDataFormatterTypeEnum.MASK:
      case FormDataFormatterTypeEnum.MASK_CHARACTER_PATTERN:
      case FormDataFormatterTypeEnum.MIN:
      case FormDataFormatterTypeEnum.MAX:
      case FormDataFormatterTypeEnum.STEP:
      case FormDataFormatterTypeEnum.CURRENCY:
      case FormDataFormatterTypeEnum.CURRENCY_DISPLAY:
      case FormDataFormatterTypeEnum.MAX_LENGTH:
      case FormDataFormatterTypeEnum.MAX_FILES:
      case FormDataFormatterTypeEnum.MAX_FILE_SIZE:
      case FormDataFormatterTypeEnum.HOUR_STEP:
      case FormDataFormatterTypeEnum.MINUTE_STEP:
      case FormDataFormatterTypeEnum.PREFIX:
      case FormDataFormatterTypeEnum.SUFFIX:
      case FormDataFormatterTypeEnum.RATING_STARS:
      case FormDataFormatterTypeEnum.ORIENTATION:
      default:
        return value.toString();
    }
  }

  /**
   * Default values
   */
  static DEFAULT = {
    rating: 5,
  };
}

/**
 * Types
 */
export type FormDataValidation = {
  type: FormDataValidationTypeEnum;
  regexp?: string;
};

export type FormDataLabels = {
  [x: string]: string;
};

export type FormDataDescriptions = {
  [x: string]: string;
};

export type FormDataPlaceholders = {
  [x: string]: string;
};

export type FormDataControlTexts = {
  [x: string]: {
    [x: string]: string;
  };
};

export class FormDataOption {
  label: string;
  value: string;

  constructor() {}

  deserialize(input: any): this {
    Object.assign(this, input);

    if (!this.value) {
      this.value = this.label;
    }

    return this;
  }
}

export type FormDataFormatter = {
  type: FormDataFormatterTypeEnum;
  value: string | number | boolean | string[];
};

export type FormDataAddress = {
  addressCity: string;
  addressPostalCode: string;
  addressState: string;
  addressCountry: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
};
