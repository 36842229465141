import BaseModel from "@shared/models/base.model";
import { ChannelEnum } from "@shared/enums/channel.enum";

export default class IdentityModel extends BaseModel {
  // MINIMIZE (default)
  public identityIdentifier: string | null = null;
  public name: string | null = null;
  public channel?: ChannelEnum;
  public emailAddress: string | null = null;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public phoneNumber: string | null = null;
  public companyName: string | null = null;
  public avatar: string | null = null;
  public isShareable = false;
  public isMain = false;
  public isVerified = false;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
