<div class="stack-grid gap-1" *ngIf="answersWithMessages">
  <div
    class="flex gap-2 p-2 border-round border-1 border-red-300 bg-red-50"
    *ngFor="let answer of answersWithMessages"
    [pTooltip]="
      'RECORD.form-item-answer.validation.rejection.explanation'
        | translate : { default: 'This answer was rejected and the following reason was provided' }
    "
    [showDelay]="300"
    tooltipStyleClass="generic-tooltip"
  >
    <span>
      <i *ngIf="!answer?.validatedBy" class="pi pi-comments text-lg font-medium" style="vertical-align: middle"></i>
      <ui-user-avatar [size]="24" *ngIf="answer.validatedBy" [user]="answer.validatedBy"></ui-user-avatar>
    </span>
    <div>
      <b class="clamp font-medium text-sm text-color-secondary" *ngIf="answer.validatedBy">{{
        answer.validatedBy.emailAddress
      }}</b>
      <span class="text-sm text-color">
        {{ answer.validationMessage }}
      </span>
    </div>
  </div>
</div>
