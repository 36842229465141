import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";

export type CloudUploadListVariant = "box" | "inline";

@Component({
  selector: "main-cloud-file-upload-list",
  templateUrl: "./cloud-file-upload-list.component.html",
  styleUrls: ["./cloud-file-upload-list.component.scss"],
})
export class CloudFileUploadListComponent implements OnInit {
  @Input() allowRemove: boolean = true;
  @Input() cloudFiles: ProviderItemModel[] = [];
  @Input() disabled: boolean = false;
  @Input() displaySignatureTrigger: boolean = false;
  @Input() displaySignatureIndicator: boolean = false;
  @Input() isUploading: boolean = false;
  @Input() variant: CloudUploadListVariant = "box";
  @Input() addHeightConstraints: boolean = true;
  @Input() signatureResources: ProviderItemModel[];

  @Output() onRemove: EventEmitter<ProviderItemModel> = new EventEmitter<ProviderItemModel>();
  @Output() onSignatureResourcesChange: EventEmitter<ProviderItemModel[]> = new EventEmitter<ProviderItemModel[]>();

  constructor() {}

  ngOnInit(): void {}

  remove(resource: ProviderItemModel) {
    this.onRemove.emit(resource);
  }

  handleSignatureResourcesChange(event: ProviderItemModel[]) {
    this.onSignatureResourcesChange.emit(event);
  }
}
