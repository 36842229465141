<!-- Menu on Mobile - triggered with a burger menu-->
<p-sidebar class="lg:hidden" [(visible)]="showSidebarMenu" (visibleChange)="handleSidebarVisibility($event)">
  <nav class="flex-shrink-0 pt-8">
    <div class="flex flex-column h-full">
      <div class="mt-3">
        <ul class="list-none p-3 m-0">
          <main-sidebar-link
            *ngFor="let item of items"
            [sidebarItem]="item"
            (onMenuItemClick)="handleSidebarVisibility(false)"
          ></main-sidebar-link>
        </ul>
      </div>
    </div>
  </nav>
</p-sidebar>
<!-- Menu on Desktop -->
<!-- <nav
  class="desktop-sidebar h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 w-18rem lg:w-7rem pt-8"
>
  <div class="flex flex-column h-full">
    <div class="mt-3">
      <ul class="list-none p-3 m-0 flex flex-column gap-1">
        <main-sidebar-link *ngFor="let item of items" [sidebarItem]="item"></main-sidebar-link>
      </ul>
    </div>
  </div>
</nav> -->
