import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { partition } from "lodash";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";

@Component({
  selector: "record-message-invitation-avatar-group",
  templateUrl: "./message-invitation-avatar-group.component.html",
  styleUrls: ["./message-invitation-avatar-group.component.scss"],
})
export class RecordMessageInvitationAvatarGroupComponent implements OnInit, OnChanges {
  @Input() message: RecordMessageModel;
  @Input()
  get recipients() {
    return this._recipients;
  }
  set recipients(recipients: RecordRecipientModel[]) {
    this._recipients = recipients;
  }

  _recipients: RecordRecipientModel[] = [];
  recipientsAccepted: RecordRecipientModel[] = [];
  recipientsDeclined: RecordRecipientModel[] = [];
  recipientsPending: RecordRecipientModel[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recipients) {
      this.sortRecipients();
    }
  }

  sortRecipients() {
    let [answered, pending] = partition(this._recipients, (recipient) => this.hasAnsweredInvitation(recipient));
    this.recipientsPending = pending;

    let [accepted, declined] = partition(answered, (recipient) => this.hasAcceptedInvitation(recipient));
    this.recipientsAccepted = accepted;
    this.recipientsDeclined = declined;
  }

  hasAnsweredInvitation(recipient: RecordRecipientModel) {
    return Boolean(this.message) && this.message.hasAnsweredInvitation(recipient.identityIdentifier);
  }

  hasAcceptedInvitation(recipient: RecordRecipientModel) {
    return Boolean(this.message) && this.message.hasAcceptedInvitation(recipient.identityIdentifier);
  }

  get tooltipPendingRecipientsContent() {
    return (
      '<div class="flex flex-column">' +
      this.recipientsPending.map((recipient) => "<div>" + recipient.emailAddress + "</div>").join("") +
      "</div>"
    );
  }

  get tooltipAcceptedRecipientsContent() {
    return (
      '<div class="flex flex-column">' +
      this.recipientsAccepted.map((recipient) => "<div>" + recipient.emailAddress + "</div>").join("") +
      "</div>"
    );
  }

  get tooltipDeclinedRecipientsContent() {
    return (
      '<div class="flex flex-column">' +
      this.recipientsDeclined.map((recipient) => "<div>" + recipient.emailAddress + "</div>").join("") +
      "</div>"
    );
  }
}
