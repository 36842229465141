<div class="flex flex-column">
  <div class="text-center p-3 pb-0" *ngIf="filterRecipientsByRole(recipients, recipientRole).length == 0">
    <span class="text-color-secondary">
      {{
        descriptionText ||
          ("RECORD.roles-" + recordType + "." + recipientRole + ".description"
            | translate : { "default": recipientRole })
      }}
    </span>
  </div>
  <div class="scrollable shadowed px-3 border-round">
    <div class="flex" *ngFor="let recipient of filterRecipientsByRole(recipients, recipientRole); let index = index">
      <div
        class="flex align-items-center justify-content-between w-full py-3 border-bottom-1 surface-border-light cursor-pointer"
        (click)="doEdit($event, recipient)"
      >
        <record-recipient-card
          [recipient]="recipient"
          [showEmail]="true"
          [showPhone]="true"
          [showAvatar]="true"
          [isInvalid]="enableValidation && !recipient.isValid(isSignerRequired)"
        >
        </record-recipient-card>
      </div>
      <div class="flex gap-2 align-items-center">
        <div *ngIf="enableSignature && isSignerRequired">
          <!-- Real checkbox -->
          <ng-container *ngIf="recipient.isValid(true); else invalidSignerCheckbox">
            <p-checkbox
              [inputId]="recipient.emailAddress"
              name="enableSignature"
              [checkboxIcon]="'msr-icon-draw icon-18px msr-icon-fw'"
              class="signature-checkbox sr-only"
              (ngModelChange)="handleSignatureRecipientChange($event, recipient)"
              [value]="recipient.emailAddress"
              [ngModel]="signatureRecipientEmails"
              [disabled]="!recipient.isValid(true)"
            ></p-checkbox>
            <label
              [for]="recipient.emailAddress"
              style="
                --border-radius: 4px;
                border-color: var(--secondary-color);
                background-color: var(--surface-b);
                width: 2.5rem;
                height: 2.5rem;
              "
              class="cursor-pointer border-1 border-round flex align-items-center justify-content-center"
              [ngClass]="
                signatureRecipientEmails.includes(recipient.emailAddress)
                  ? 'bg-primary text-white border-primary'
                  : 'text-secondary'
              "
            >
              <i class="msr-icon-draw icon-20px msr-icon-fw"></i>
            </label>
          </ng-container>

          <ng-template #invalidSignerCheckbox>
            <div
              (click)="doEdit($event, recipient)"
              [tooltip]="'RECORD.fields.recipients-signers.missing-information' | translate"
              style="--border-radius: 4px; border-color: var(--border-color)"
              class="cursor-pointer opacity-50 w-2rem h-2rem border-2 border-round flex align-items-center justify-content-center surface-100 text-color"
            >
              <i class="msr-icon-draw icon-18px msr-icon-fw"></i>
            </div>
          </ng-template>
        </div>
        <button
          type="button"
          pButton
          icon="pi pi-times"
          [tooltip]="'COMMON.buttons.remove' | translate : { 'default': 'Remove' }"
          class="p-button-text p-button-danger p-button-sm p-1 flex-shrink-0 w-2rem h-2rem p-1"
          (click)="remove($event, recipient)"
          [disabled]="disabled"
        ></button>
      </div>
    </div>
  </div>

  <!-- Add recipient from contacts-->
  <div class="flex flex-wrap gap-2 p-3 w-full align-items-center justify-content-center">
    <button
      pButton
      type="button"
      *ngIf="showSelfSign"
      class="p-button p-button-link p-button-sm mt-1"
      [label]="'RECORD.fields.owner-signer.label' | translate : { 'default': 'I want to sign the document' }"
      (click)="onAddSelfAsSigner.emit()"
    ></button>
    <p-splitButton
      type="button"
      [label]="
        buttonText ||
        ('RECORD.roles-' + recordType + '.' + recipientRole + '.add' | translate : { 'default': 'Add recipient' })
      "
      styleClass="p-button-outlined p-button-sm"
      [model]="addRecipientsItems"
      (onClick)="add()"
      [disabled]="disabled"
      appendTo="body"
      menuStyleClass="filesSplitButtonMenu"
    ></p-splitButton>
  </div>
</div>

<p-dialog
  class="recipientFormDialog"
  [(visible)]="showContactsManager"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '960px': 'var(--small-measure)', '640px': '100vw' }"
  [style]="{ width: 'var(--small-measure)' }"
  styleClass="dialog-nested"
>
  <ng-template pTemplate="header">
    <h3 class="my-0">
      {{
        dialogTitleText ||
          ("RECORD.roles-" + recordType + "." + recipientRole + ".add" | translate : { "default": "Add recipient" })
      }}
    </h3>
  </ng-template>
  <ng-template pTemplate="content">
    <contacts-contact-manager
      (onCancel)="handleContactsManagerCancel()"
      (onContactsSelected)="handleContactsSelected($event)"
    ></contacts-contact-manager>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="showRecipientEditForm"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '960px': 'var(--xsmall-measure)', '640px': '100vw' }"
  [style]="{ width: 'var(--xsmall-measure)' }"
  styleClass="dialog-nested"
  (onHide)="handleEditClose()"
>
  <ng-template pTemplate="header">
    <header>
      <h3 class="my-0">
        {{ "RECORD.recipients.edit-recipient" | translate : { "default": "Edit recipient" } }}
      </h3>
    </header>
  </ng-template>
  <record-recipient-form
    *ngIf="selectedRecipient"
    [recipient]="selectedRecipient"
    (onSubmit)="onSaveRecipient($event)"
    (onCancel)="handleEditClose()"
  ></record-recipient-form>
</p-dialog>

<!-- Provider Contact Picker -->
<p-dialog
  [(visible)]="showProviderContactPicker"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '960px': 'var(--small-measure)', '640px': '100vw' }"
  [style]="{ width: 'var(--small-measure)' }"
  styleClass="dialog-nested"
  (onHide)="handleProviderContactClose()"
>
  <ng-template pTemplate="header">
    <header>
      <h3 class="my-0">
        {{ "MAIN.provider-contacts.import-from-provider" | translate : { "default": "Import from Microsoft" } }}
      </h3>
    </header>
  </ng-template>
  <provider-contact-picker
    *ngIf="showProviderContactPicker"
    [recipientRole]="recipientRole"
    (onSubmitProviderRecipients)="handleProviderContactSubmit($event)"
    (onCancel)="handleProviderContactClose()"
  ></provider-contact-picker>
</p-dialog>
