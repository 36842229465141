<p-dialog
  [(visible)]="displayPreviewDialog"
  [appendTo]="'body'"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [style]="{ 'width': '99vw', 'height': '90%' }"
  [showHeader]="true"
  [header]="header"
  styleClass="dialog-nested"
  class="dialog-nested"
  (onHide)="close()"
>
  <ng-template pTemplate="content">
    <ui-page-spinner [absolute]="true" *ngIf="isLoading"></ui-page-spinner>
    <iframe [src]="url" *ngIf="url" (load)="handleLoad()"></iframe>
  </ng-template>
</p-dialog>
