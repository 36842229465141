<!-- Parent message -->
<ng-container *ngIf="parentMessage">
  <record-parent-message
    [id]="'parent-' + message.parentMessageIdentifier"
    [message]="message"
    [parentMessage]="parentMessage"
    [wrappedKeys]="wrappedKeys"
    [isLoading]="isLoading"
    (onDecryptionCompleted)="handleDecryptionCompleted()"
    (onClick)="handleParentMessageClicked($event)"
  ></record-parent-message>
</ng-container>

<ng-container *ngIf="!message.isBotMessage; else messageBot">
  <record-message-human-type
    [id]="'message-' + message.messageIdentifier"
    [message]="message"
    [parentMessage]="parentMessage"
    [wrappedKeys]="wrappedKeys"
    [publicKey]="publicKey"
    [isLoading]="isLoading"
    [enableAnswer]="enableActions"
    [enableSignature]="enableActions"
    [enableReply]="enableReply"
    [showBroadcasted]="showBroadcasted"
    (onFormItemClick)="handleFormItemClick($event)"
    (onFormAction)="handleMessageFormAction($event)"
    (onInvitationReplied)="handleInvitationReplied()"
    (onSignatureAction)="handleMessageSignatureAction($event)"
    (onWithdrawSignatureAction)="handleWithdrawSignatureAction($event)"
    (onDecryptionCompleted)="handleDecryptionCompleted()"
    (onReplyClick)="handleReplyClick($event)"
  ></record-message-human-type>
</ng-container>

<!-- BOT -->
<ng-template #messageBot>
  <record-message-bot-type [id]="'message-' + message.messageIdentifier" [message]="message"></record-message-bot-type>
</ng-template>
