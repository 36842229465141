import { Component, Input } from "@angular/core";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";

@Component({
  selector: "form-item-validation-messages",
  templateUrl: "./form-item-validation-messages.component.html",
  styleUrls: ["./form-item-validation-messages.component.scss"],
})
export class RecordFormItemValidationMessagesComponent {
  @Input() answersWithMessages: RecordMessageAnswerModel[];
}
