import BaseModel from "@shared/models/base.model";

export default class SignatureAttachmentModel extends BaseModel {
  public attachmentIdentifier: string | null = null;
  public type?: string;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
