import { Injectable } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { registerLocaleData } from "@angular/common";
import { Subscription } from "rxjs";
import { PrimeNGConfig } from "primeng/api";

import { Logger, UntilDestroy, untilDestroyed } from "@core";

import EN_GB from "../../../translations/en-GB.json";
import FR_FR from "../../../translations/fr-FR.json";
import EN_GB_STATIC from "../../../translations/static-en-GB.json";
import FR_FR_STATIC from "../../../translations/static-fr-FR.json";

// Angular EN locale data
import localeEn from "@angular/common/locales/en";
import localeEnExtra from "@angular/common/locales/extra/en";
// Angular FR locale data
import localeFr from "@angular/common/locales/fr";
import localeFrExtra from "@angular/common/locales/extra/fr";

const log = new Logger("I18nService");
const languageKey = "language";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService, private primeNGConfig: PrimeNGConfig) {
    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation("en-GB", EN_GB);
    translateService.setTranslation("en-GB", EN_GB_STATIC, true);

    translateService.setTranslation("fr-FR", FR_FR);
    translateService.setTranslation("fr-FR", FR_FR_STATIC, true);

    registerLocaleData(localeEn, "en-GB", localeEnExtra);
    registerLocaleData(localeFr, "fr-FR", localeFrExtra);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = "";
    this.translateService.setDefaultLang(this.defaultLanguage);

    // Warning: this subscribe will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((event: LangChangeEvent) => {
        localStorage.setItem(languageKey, event.lang);
      });
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || localStorage.getItem(languageKey) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split("-")[0];
      language = this.supportedLanguages.find((supportedLanguage) => supportedLanguage.startsWith(language)) || "";
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language}`);
    registerLocaleData(language);
    this.translateService.use(language);
    this.translateService.get("primeng").subscribe((res) => {
      const translations = this.fixPrimeNGTranslations(res);
      return this.primeNGConfig.setTranslation(translations);
    });
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  get languages(): string[] {
    return this.supportedLanguages;
  }

  get languagesItems(): JsonArray {
    return this.supportedLanguages.map((language: string) => {
      return {
        value: language,
        label: this.translateService.instant("LANGUAGES." + language + ".fullName"),
        labelAbbr: this.translateService.instant("LANGUAGES." + language + ".abbr"),
      };
    });
  }

  private fixPrimeNGTranslations(translations: any) {
    switch (typeof translations) {
      case "function":
        return translations();
      case "object":
        if (Array.isArray(translations)) {
          return translations.map((t) => this.fixPrimeNGTranslations(t));
        }

        for (const key in translations) {
          translations[key] = this.fixPrimeNGTranslations(translations[key]);
        }
        return translations;
      default:
        return translations;
    }
  }
}
