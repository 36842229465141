<div class="uploads-wrapper border-round" [ngClass]="{ 'uploads-wrapper--box': variant == 'box' }">
  <ul class="uploads stack-grid gap-3" [ngClass]="{ 'scrollable': addHeightConstraints }">
    <li
      class="uploads__file align-items-center"
      [ngClass]="{ 'p-disabled': disabled || isUploading }"
      *ngFor="let resource of cloudFiles"
    >
      <div class="flex gap-3 min-w-0 align-self-start">
        <div class="file__icon align-items-center justify-content-center border-round hidden sm:flex">
          <ng-template #fileIcon>
            <ng-template #mimeTypeIcon>
              <i [class]="resource.mimeType | getMimeTypeIcon"></i>
            </ng-template>
            <i class="msr-icon-folder icon-24px" *ngIf="resource.isDirectory; else mimeTypeIcon"></i>
          </ng-template>
          <ng-container *ngIf="isUploading; else fileIcon">
            <ui-spinner [size]="20"></ui-spinner>
          </ng-container>
        </div>
        <div class="file__content stack-grid">
          <span [tooltip]="resource.name" placement="top" [delay]="1000" class="file__name clamp">{{
            resource.name
          }}</span>

          <span class="file__description clamp" *ngIf="!resource.isDirectory"
            >{{ resource.size | filesize }} -
            {{ resource.mimeType || ("COMMON.unknown-mime-type" | translate : { "default": "Unknown" }) }} -
            <i
              style="vertical-align: middle"
              tooltipStyleClass="generic-tooltip"
              [pTooltip]="'MAIN.cloud-file-picker.external-resource' | translate"
              class="pi pi-cloud text-color-secondary"
            ></i
          ></span>
        </div>
      </div>
      <!-- Actions (sign & close) -->
      <div class="flex ml-2 gap-3 align-items-center">
        <!-- Signature Indicator -->
        <div
          class="flex align-items-center justify-content-center"
          *ngIf="displaySignatureIndicator && signatureResources.includes(resource)"
          [pTooltip]="
            'RECORD.fields.signature-uploads.to-sign'
              | translate : { default: 'A signature will be requested for this document' }
          "
          tooltipStyleClass="generic-tooltip"
        >
          <i class="msr-icon-draw icon-22px font-light text-primary msr-icon-fw"></i>
        </div>
        <!-- SIGNATURE TRIGGER -->
        <div
          *ngIf="displaySignatureTrigger && resource.mimeType === 'application/pdf' && resource.size < 45 * 1024 * 1024"
        >
          <!-- Signature checkbox -->
          <p-checkbox
            name="enableSignature"
            [checkboxIcon]="'msr-icon-draw icon-20px msr-icon-fw'"
            class="signature-checkbox sr-only"
            (ngModelChange)="handleSignatureResourcesChange($event)"
            [value]="resource"
            [(ngModel)]="signatureResources"
            [inputId]="resource.id ?? resource.path"
          ></p-checkbox>
          <label
            [for]="resource.id ?? resource.path"
            style="
              --border-radius: 4px;
              border-color: var(--secondary-color);
              background-color: var(--surface-b);
              width: 2.5rem;
              height: 2.5rem;
            "
            class="cursor-pointer border-1 border-round flex align-items-center justify-content-center"
            [ngClass]="
              signatureResources.includes(resource) ? 'bg-primary text-white border-primary' : 'text-secondary'
            "
          >
            <i class="msr-icon-draw icon-20px font-light msr-icon-fw"></i>
          </label>
        </div>

        <!-- Button icon to close -->
        <button
          [disabled]="isUploading || disabled"
          (click)="remove(resource)"
          pButton
          type="button"
          icon="pi pi-times"
          class="p-button-text p-button-secondary p-button-sm file__button flex-shrink-0"
          data-attr="file-upload-remove"
          *ngIf="allowRemove"
        ></button>
      </div>
    </li>
  </ul>
</div>
