export enum KeycloakRealmRoleEnum {
  READ_TOKEN = "read-token",
  MANAGE_ACCOUNT = "manage-account",
  MANAGE_ACCOUNT_LINKS = "manage-account-links",
  VIEW_PROFILE = "view-profile",

  // Default roles (depending on environments)
  DEFAULT_ROLES_PAPRWORK_DEV = "default-roles-paprwork-dev",
  DEFAULT_ROLES_PAPRWORK_STAGING = "default-roles-paprwork-staging",
  DEFAULT_ROLES_PAPRWORK = "default-roles-paprwork",

  // Guest role
  PAPRWORK_GUEST = "paprwork-guest",

  // Subscription roles
  PAPRWORK_FREE_USER = "paprwork-free-user",
  PAPRWORK_LEVEL1 = "paprwork-level1",
  PAPRWORK_LEVEL2 = "paprwork-level2",

  // Products roles
  PAPRWORK_SIGNATURE = "paprwork-signature",
}
