import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { DataTypeFormatEnum } from "@app/@shared/enums/data-type-format.enum";
import { FormDataControlEnum } from "@app/@shared/enums/form-data/form-data-control.enum";
import { UploadTypeEnum } from "@app/@shared/enums/upload-type.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import RecordModel from "@app/@shared/models/record/record.model";
import { UntilDestroy } from "@core";
import { isEmpty } from "lodash";
import { UploadState, UploadxService } from "ngx-uploadx";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "record-form-item-control",
  templateUrl: "./form-item-control.component.html",
  styleUrls: ["./form-item-control.component.scss"],
})
export class RecordFormItemControlComponent implements OnInit, OnChanges {
  @Input() record: RecordModel;
  @Input() isFormItemAnswerable: boolean = false;
  @Input() formItem: RecordMessageFormItemModel;
  @Input() answers: RecordMessageAnswerModel[];
  @Input() uploads: UploadState[];
  @Input() uploadState$: Observable<UploadState>;
  @Input() answerAttachments: RecordMessageAttachmentModel[];

  ControlEnum = FormDataControlEnum;
  FormatEnum = DataTypeFormatEnum;
  control: FormDataControlEnum;
  description: string;
  label: string;
  parentFormGroup: UntypedFormGroup;

  constructor(
    public controlContainer: ControlContainer,
    private i18nService: I18nService,
    private uploadxService: UploadxService,
  ) {}

  ngOnInit(): void {
    this.updateParentFormGroup();
    if (this.isFormItemAnswerable && this.answers?.length > 0) {
      this.parentFormGroup.patchValue({ answer: null, files: null });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem) {
      this.updateParentFormGroup();
      this.setControl();
      this.setContent();
    }
  }

  updateParentFormGroup() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
  }

  get answersWithMessages() {
    if (!this.answers) return null;
    return this.answers.filter((answer) => !isEmpty(answer.validationMessage));
  }

  get alreadyAnsweredDocumentItem() {
    return Boolean([DataTypeFormatEnum.DOCUMENTS].includes(this.formItem.format) && this.answerAttachments?.length > 0);
  }

  setControl() {
    if (this.alreadyAnsweredDocumentItem) {
      this.control = FormDataControlEnum.UPLOAD_BUTTON;
    } else if (this.formItem.getControl()) {
      // Retrieve control
      let control = this.formItem.getControl();
      this.control = control ? control : this.formItem.getDefaultControl();
    } else {
      this.control = this.formItem.getDefaultControl();
    }
  }

  setContent() {
    // Retrieve label
    // if (this.formItem.getLabels()) {
    //   let label = this.formItem.getLabel(this.i18nService.language);
    //   this.label = label ? label : this.formItem.label;
    // } else {
    this.label = this.formItem.label;
    // }

    // Retrieve descripiton
    // if (this.formItem.getDescriptions()) {
    //   let description = this.formItem.getDescription(this.i18nService.language);
    //   this.description = description ? description : this.formItem.description;
    // } else {
    this.description = this.formItem.description;
    // }
  }

  handleUploadRemove(uploadId: string) {
    this.uploadxService.control({ action: "cancel", uploadId });
  }

  handleFilesAdded(files: File[]) {
    this.uploadxService.handleFiles(files, {
      metadata: { type: UploadTypeEnum.FORM_ANSWER, itemIdentifier: this.formItem.itemIdentifier },
    });
  }
}
