<div class="form-answer h-full">
  <div class="form-answer__content h-full">
    <form class="h-full gap-0" [formGroup]="form" (ngSubmit)="submit()">
      <!-- Message Summary -->
      <div
        class="form-answer__message surface-card border-bottom-1 surface-border my-auto p-3"
        *ngIf="decryptedBody"
        [attr.aria-expanded]="messageExpanded"
      >
        <header class="measured-container measure-xs w-full flex flex-wrap align-items-center mb-2">
          <span class="flex flex-wrap align-items-center">
            <ui-user-avatar [size]="14" [user]="message.sender"></ui-user-avatar>
            <span class="ml-1 text-color-secondary">{{ message.sender.toString() }}</span>
          </span>
          <span aria-hidden class="mx-2 text-color-secondary">·</span>
          <span
            style="
              text-decoration: underline;
              text-decoration-color: var(--text-color-secondary);
              text-decoration-style: dotted;
              text-underline-offset: 2px;
            "
            class="text-color-secondary"
            placement="bottom"
            [tooltip]="message.createdAt | date : 'medium'"
          >
            {{ message.createdAt | timeAgo | async }}
          </span>
        </header>
        <ng-container *ngIf="!isDecrypting; else loadingTemplate">
          <div class="measured-container measure-xs w-full pb-3 overflow-hidden form-answer__message__html">
            <record-message-decrypted [message]="message" [decryptedMessage]="decryptedBody"></record-message-decrypted>
          </div>
        </ng-container>
        <footer class="measured-container measure-xs w-full flex justify-content-end mt-2">
          <button
            pButton
            type="button"
            iconPos="right"
            [icon]="messageExpanded ? 'pi pi-angle-up' : 'pi pi-angle-down'"
            class="p-button-sm p-button-text p-1"
            (click)="messageExpanded = !messageExpanded"
            [label]="
              messageExpanded
                ? ('COMMON.buttons.collapse' | translate : { default: 'Collapse' })
                : ('COMMON.buttons.see-all' | translate : { default: 'See all' })
            "
          ></button>
        </footer>
      </div>
      <!-- Form -->
      <div class="stack-grid pb-6 p-3" *ngIf="!record.isClosed && !record.isCancelled; else cannotReplyTemplate">
        <!-- Unanswered Items -->
        <ng-container *ngIf="unansweredFormItems?.length > 0">
          <header class="form-answer__header measured-container measure-xs w-full mb-4 mt-5">
            <span>{{ "RECORD.record-message-form.unanswered" | translate : { "default": "Pending requests" } }}</span>
          </header>
          <div
            class="surface-card border-round border-1 surface-border p-3 sm:p-5 measured-container measure-xs w-full stack-grid gap-2"
            style="box-sizing: border-box; --full-bleed-background: var(--surface-card)"
          >
            <div formArrayName="answers" *ngFor="let formItem of unansweredFormItems; let index = index">
              <div class="field">
                <!-- Form item control -->
                <record-form-item-control
                  [isFormItemAnswerable]="shouldFormItemBeDisplayed(formItem)"
                  [answers]="getAnswers(formItem.itemIdentifier)"
                  [formGroupName]="index"
                  [answerAttachments]="getFormItemAttachments(formItem.itemIdentifier)"
                  [record]="record"
                  [formItem]="formItem"
                  [uploadState$]="uploadState$"
                  [uploads]="getUploads(formItem.itemIdentifier)"
                ></record-form-item-control>
              </div>
            </div>
            <!-- Form Buttons -->
            <footer
              class="measured-container measure-xs flex flex-column sm:flex-row gap-2 w-full justify-content-between"
            >
              <button
                pButton
                class="p-button-sm w-full sm:w-auto"
                type="submit"
                [label]="'RECORD.record-message-form.buttons.submit' | translate : { 'default': 'Submit' }"
                [disabled]="formDisabled || isSubmitting"
                [loading]="isSubmitting"
              ></button>
            </footer>
          </div>
        </ng-container>
        <!-- Answered Items -->
        <ng-container *ngIf="answeredFormItems?.length > 0">
          <header class="form-answer__header measured-container measure-xs w-full mt-4">
            <span>{{ "RECORD.record-message-form.answered" | translate : { "default": "Answered requests" } }}</span>
          </header>
          <div class="measured-container measure-xs w-full stack-grid gap-2" style="box-sizing: border-box">
            <div class="mt-4" *ngFor="let formItem of answeredFormItems; let index = index">
              <record-form-item-answer
                *ngFor="let answerValue of getAnswers(formItem.itemIdentifier); let first = first"
                [showLabel]="first"
                [enableAnswerShortcut]="false"
                [answerValue]="answerValue"
                [answerAttachments]="getFormItemAttachments(formItem.itemIdentifier, answerValue)"
                [formItem]="formItem"
                [record]="record$ | async"
                [wrappedKeys]="wrappedKeys"
              ></record-form-item-answer>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-template #cannotReplyTemplate>
        <p class="text-color-secondary text-center">
          <i class="pi pi-info-circle text-sm"></i>
          {{
            "RECORD.record-message-form.archived"
              | translate : { "default": "You can no longer respond to requests if your conversation is archived." }
          }}
        </p>
      </ng-template>
    </form>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="flex flex-row gap-2 align-items-center h-full">
    <ui-spinner [size]="20"></ui-spinner>
    <span class="text-color">{{
      "COMPONENTS.record-message.decrypting" | translate : { "default": "Decrypting message..." }
    }}</span>
  </div>
</ng-template>
