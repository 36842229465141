import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import RecordMessageModel, { Signer } from "@shared/models/record/record-message.model";
import { MessageFormatEnum } from "@shared/enums/message-format.enum";
import { CryptographyService } from "@shared/services/cryptography.service";
import WrappedKeyModel from "@shared/models/vault/wrapped-key.model";
import RecordModel from "@shared/models/record/record.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import IdentityModel from "@app/@shared/models/user/identity.model";
import { intersection, some } from "lodash";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import PublicKeyModel from "@app/@shared/models/vault/public-key.model";
import { MessageInvitationStatusEnum } from "@app/@shared/enums/message-invitation-status.enum";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { BehaviorSubject, catchError, combineLatest, filter, finalize, map, mergeMap, of, tap } from "rxjs";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";
import { MessageAnswerTypeEnum } from "@app/@shared/enums/message-answer-type.enum";
import RecordMessageAnswerContentModel from "@app/@shared/models/record/record-message-answer-content.model";
import RecordMessageAnswerContentInvitationModel from "@app/@shared/models/record/record-message-answer-content-invitation.model";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import { Logger, UntilDestroy, untilDestroyed } from "@core";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { RecordService } from "@app/record/services/record.service";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import { RecordAuthorizationService } from "@app/@shared/services/record-authorization.service";
import { OverlayPanel } from "primeng/overlaypanel";
import RecordMessageTemplateModel from "@app/@shared/models/masterdata/record-message-template.model";
import { RecordMessageTemplateScopeEnum } from "@app/@shared/enums/record-message-template-scope.enum";
import { RecordMessageTemplateContentModel } from "@app/@shared/models/masterdata/record-message-template/record-message-template-content.model";
import { TemplatesService } from "@app/templates/services/templates.service";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import { MatomoEventEnum } from "@app/@shared/enums/matomo-event-enum";
import { MatomoTracker } from "ngx-matomo-client";

const log = new Logger("Record/MessageHumanType");

export type FormItemClickEvent = {
  originalEvent: MouseEvent;
  message: RecordMessageModel;
  formItem: RecordMessageFormItemModel;
};

@UntilDestroy()
@Component({
  selector: "record-message-human-type",
  templateUrl: "./message-human-type.component.html",
  styleUrls: ["./message-human-type.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecordMessageHumanTypeComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("saveAsTemplatePanel") saveAsTemplatePanel: OverlayPanel;
  @Input() enableAnswer: boolean = true;
  @Input() enableInvitation: boolean = true;
  @Input() enableReply: boolean = true;
  @Input() enableSignature: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;
  @Input() message: RecordMessageModel;
  @Input() parentMessage: RecordMessageModel;
  @Input() publicKey: PublicKeyModel;
  @Input() showBroadcasted: boolean = false;
  @Input() wrappedKeys: WrappedKeyModel[] = [];

  @Output() onFormItemClick: EventEmitter<FormItemClickEvent> = new EventEmitter<FormItemClickEvent>();
  @Output() onFormAction = new EventEmitter<RecordMessageModel>();
  @Output() onDecryptionCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() onInvitationReplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSignatureAction = new EventEmitter<RecordMessageModel>();
  @Output() onWithdrawSignatureAction = new EventEmitter<RecordMessageModel>();
  @Output() onReplyClick: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();

  allFormItems$: BehaviorSubject<RecordMessageFormItemModel[]> = new BehaviorSubject([]);
  decryptedMessage: string;
  displayInvitationAnswerModal: boolean = false;
  invitationAnswerStatus: MessageInvitationStatusEnum = MessageInvitationStatusEnum.ACCEPTED;
  isDecrypting: boolean = false;
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  messages$: BehaviorSubject<RecordMessageModel[]> = new BehaviorSubject<RecordMessageModel[]>(undefined);
  messageMenuItems: MenuItem[] = null;
  showMessageMenuItems: boolean = false;
  canViewPeripheralElements: boolean = false;

  createdTemplate: RecordMessageTemplateModel = undefined;
  isCreatingTemplate: boolean = false;

  get allFormItems() {
    return this.allFormItems$.getValue();
  }
  get record() {
    return this.record$.getValue();
  }

  get messages() {
    return this.messages$.getValue();
  }

  get signatureAttachments() {
    return this.message?.attachments?.filter((attachment) =>
      this.message.isSignatureAttachment(attachment.file.fileIdentifier),
    );
  }

  constructor(
    private cryptographyService: CryptographyService,
    public sessionAPIService: SessionAPIService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private recordService: RecordService,
    private recordAuthorizationService: RecordAuthorizationService,
    private confirmationService: ConfirmationService,
    private templatesService: TemplatesService,
    private i18nService: I18nService,
    private authorizationService: AuthorizationService,
    public themingService: ThemingService,
    private readonly tracker: MatomoTracker,
  ) {
    // Put this in a BehaviorSubject to access its value at any time
    this.recordService.record$.subscribe(this.record$);
    // Put this in a BehaviorSubject to access its value at any time
    this.recordService.formItems$.subscribe(this.allFormItems$);
    // Put this in a BehaviorSubject to access its value at any time
    this.recordService.messages$.pipe(untilDestroyed(this)).subscribe(this.messages$);

    combineLatest([this.sessionAPIService.isLoaded$, this.authorizationService.initialized$])
      .pipe(
        filter(([loaded, initialized]) => Boolean(loaded) && Boolean(initialized)),
        untilDestroyed(this),
      )
      .subscribe(([_, __]) => {
        this.canViewPeripheralElements = this.sessionAPIService.unit.isPublic
          ? !this.authorizationService.isGuest()
          : !this.authorizationService.isUnitGuest();
        this.setMessageMenuItems();
      });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.wrappedKeys || changes.message) {
      this.doDecrypt();
    }
  }

  ngOnDestroy() {}

  handleReplyClick() {
    this.onReplyClick.emit(this.message);
  }

  get displayLoading() {
    return (
      Object.values<string>([MessageFormatEnum.ENCRYPTED_TEXT, MessageFormatEnum.ENCRYPTED_HTML]).includes(
        this.message.format,
      ) &&
      (this.isLoading || this.isDecrypting)
    );
  }

  doDecrypt(): void {
    let body = this.message.body;

    if (body) {
      if (
        [MessageFormatEnum.ENCRYPTED_TEXT, MessageFormatEnum.ENCRYPTED_HTML].includes(this.message.format) &&
        this.wrappedKeys != null
      ) {
        this.isDecrypting = true;
        this.cryptographyService
          .decryptText(this.wrappedKeys, body)
          .then((decrypted) => {
            this.decryptedMessage = decrypted;
          })
          .finally(() => {
            this.isDecrypting = false;
            this.onDecryptionCompleted.emit();
          });
      } else {
        this.decryptedMessage = body;
      }
    }
  }

  /*
    Signature related actions
  */
  get isSigner(): boolean {
    if (this.sessionAPIService.currentUser) {
      let userIdentities = this.sessionAPIService.currentUser.identities.map(
        (identity: IdentityModel) => identity.identityIdentifier,
      );

      return some(userIdentities, (identityIdentifier) => {
        return this.message.isSigner(identityIdentifier);
      });
    }
    return false;
  }

  get hasAlreadySigned(): boolean {
    if (this.sessionAPIService.currentUser) {
      let userIdentities = this.sessionAPIService.currentUser.identities.map(
        (identity: IdentityModel) => identity.identityIdentifier,
      );

      return some(userIdentities, (identityIdentifier) => {
        return this.message.hasSigned(identityIdentifier);
      });
    }

    return false;
  }

  get signers(): RecordRecipientModel[] {
    let recipients = this.message?.metadata?.["signature"]?.["recipients"];
    // Use Metadata to retrieve signers (new method)
    if (this.record && recipients?.length > 0 && recipients.every((r) => Boolean(r["emailAddress"]))) {
      let signers = recipients.map((recipient: any) => {
        return Object.assign(new RecordRecipientModel(), recipient);
      });
      return signers;
    }
    // Fallback to old method when no metadata found on signers
    return this.record?.recipients.filter((recipient) => this.message.isSigner(recipient.identityIdentifier)) ?? [];
  }

  get displaySignatureAvatars(): boolean {
    return (
      this.enableSignature &&
      !(this.message.isSignatureDeclined || this.message.isSignatureExpired || this.message.isSignatureError) &&
      Boolean(this.record)
    );
  }

  get displaySignatureButton(): boolean {
    return (
      this.enableSignature &&
      this.message.isSignatureAvailable &&
      this.isSigner &&
      !this.hasAlreadySigned &&
      !this.record.isClosed
    );
  }
  get displayCancelSignatureAction(): boolean {
    return (
      this.enableSignature &&
      this.message.isSignatureAvailable &&
      this.recordAuthorizationService.isOwner(this.record) &&
      !this.hasAlreadySigned &&
      !this.record.isClosed
    );
  }

  get displaySignatureInactive(): boolean {
    return this.enableSignature && this.message.isSignatureInactive;
  }

  doViewSign(): void {
    if (this.isSigner) {
      this.onSignatureAction.emit(this.message);
    }
  }

  doWithdrawSign() {
    this.confirmationService.confirm({
      header: this.translateService.instant("RECORD.record-message-signature.cancel-confirmation.header", {
        default: "Cancel this signature ?",
      }),
      message: this.translateService.instant("RECORD.record-message-signature.cancel-confirmation.body", {
        default: "Are you sure you want to cancel this signature ?",
      }),
      rejectLabel: this.translateService.instant("RECORD.record-message-signature.cancel-confirmation.buttons.no", {
        default: "No, keep the signature",
      }),
      acceptLabel: this.translateService.instant("RECORD.record-message-signature.cancel-confirmation.buttons.yes", {
        default: "Yes, cancel this signature",
      }),
      acceptButtonStyleClass: "p-button-sm p-button-danger",
      rejectButtonStyleClass: "p-button-outlined p-button-sm",
      acceptIcon: "hidden",
      rejectIcon: "hidden",
      accept: () => {
        this.onWithdrawSignatureAction.emit(this.message);
      },
    });
  }

  /**
   * Form related actions
   */
  get displayAnswerButton(): boolean {
    return this.enableAnswer && this.message.hasForm && !this.record.isClosed;
  }

  get displayViewAnswersButton(): boolean {
    return this.enableAnswer && this.message.hasAnswers;
  }

  /**
   * Retrieve the parent message containing the form and display it
   */
  showAnswerModal() {
    this.onFormAction.emit(this.message);
  }

  @HostListener("click", ["$event"])
  handleClick(event) {
    let parentElement = event.target.parentElement,
      element = event.target;
    let fromElement = element && element.classList.contains("ql-form-item"),
      fromParent = parentElement && parentElement.classList.contains("ql-form-item");
    if (fromParent || fromElement) {
      let uniqueId = fromParent
        ? parentElement.getAttribute("data-identifier")
        : element.getAttribute("data-identifier");
      let formItem = this.message.formItems.find(
        (formItem: RecordMessageFormItemModel) => formItem.uniqueId === uniqueId,
      );

      this.onFormItemClick.emit({ originalEvent: event, message: this.message, formItem });
    }
  }

  handleShortcutClick(formItem: RecordMessageFormItemModel) {
    const message = this.messages.find((message) => message.messageIdentifier === this.message.parentMessageIdentifier);
    if (message) this.onFormItemClick.emit({ originalEvent: null, message, formItem });
  }

  /**
   * Form answers related actions
   */
  getFormItem(formItemIdentifier: string): RecordMessageFormItemModel | undefined {
    return this.allFormItems.find((formItem) => formItem.itemIdentifier === formItemIdentifier);
  }

  getAnswerAttachments(formItemIdentifier: string): RecordMessageAttachmentModel[] | undefined {
    return this.message.getDirectFormAnswerAttachments(formItemIdentifier);
  }

  /**
   * Invitation related actions
   */
  get currentUserIdentityIdentifier(): string | null {
    if (this.message && this.sessionAPIService.currentUser?.identities) {
      let identities: string[] = intersection(
        this.sessionAPIService.currentUser.identities.map((identity: IdentityModel) => identity.identityIdentifier),
        this.message.invitedIdentities,
      );

      return identities[0];
    }

    return null;
  }

  get isInvitedIdentity(): boolean {
    return (
      Boolean(this.currentUserIdentityIdentifier) && this.message.isInvitedIdentity(this.currentUserIdentityIdentifier)
    );
  }

  get hasAlreadyAnswered(): boolean {
    if (this.isInvitedIdentity) {
      return this.message.hasAnsweredInvitation(this.currentUserIdentityIdentifier);
    }

    return false;
  }

  get invitedIdentities(): RecordRecipientModel[] {
    if (this.record) {
      return this.record.recipients.filter((recipient) =>
        this.message.invitedIdentities.includes(recipient.identityIdentifier),
      );
    }

    return [];
  }

  get displayInvitationAvatars(): boolean {
    return this.enableInvitation && Boolean(this.record);
  }

  get displayInvitationButtons(): boolean {
    return (
      this.enableInvitation &&
      this.message.isInvitationAvailable(this.currentUserIdentityIdentifier) &&
      this.isInvitedIdentity &&
      !this.hasAlreadyAnswered &&
      !this.record.isClosed
    );
  }

  acceptInvitation() {
    this.invitationAnswerStatus = MessageInvitationStatusEnum.ACCEPTED;
    this.displayInvitationAnswerModal = true;

    this.sendAnswer(MessageInvitationStatusEnum.ACCEPTED);
  }

  declineInvitation() {
    this.invitationAnswerStatus = MessageInvitationStatusEnum.DECLINED;
    this.displayInvitationAnswerModal = true;

    this.sendAnswer(MessageInvitationStatusEnum.DECLINED);
  }

  sendAnswer(value: MessageInvitationStatusEnum) {
    if (this.currentUserIdentityIdentifier) {
      this.isSubmitting = true;

      of({ value, publicKey: this.publicKey })
        .pipe(
          // Encrypt message body
          // Answer record message
          mergeMap(({ publicKey, value }) => {
            let replyMessage = new RecordMessageModel();
            replyMessage.format = MessageFormatEnum.TEXT;
            replyMessage.body = this.translateService.instant(
              "RECORD.record-message-invitation." + value.toLowerCase(),
              {
                default: "Answered",
              },
            );

            // Building answer
            let answer = new RecordMessageAnswerModel();
            answer.type = MessageAnswerTypeEnum.INVITATION;

            let content = new RecordMessageAnswerContentModel();
            content.invitation = new RecordMessageAnswerContentInvitationModel();
            content.invitation.identityIdentifier = this.currentUserIdentityIdentifier;
            content.invitation.status = value;

            answer.content = content;

            return this.recordService
              .answerRecordMessage(this.message.messageIdentifier, replyMessage, [answer], [ResponseLevelEnum.ALL])
              .pipe(map(() => content));
          }),
          finalize(() => {
            this.isSubmitting = false;
            this.displayInvitationAnswerModal = false;
          }),
          untilDestroyed(this),
        )
        .subscribe((content: RecordMessageAnswerContentModel) => {
          if (content.invitation.status === MessageInvitationStatusEnum.ACCEPTED) {
            this.tracker.trackEvent("Workspaces", "Invitations", MatomoEventEnum.WORKSPACE_INVITATION_ACCEPTED, 1);
          } else {
            this.tracker.trackEvent("Workspaces", "Invitations", MatomoEventEnum.WORKSPACE_INVITATION_DECLINED, 1);
          }

          this.onInvitationReplied.emit();
        });
    } else {
      this.messageService.add({
        severity: MessageSeverityEnum.SEVERITY_ERROR,
        summary: this.translateService.instant("RECORD.messages.identity-not-found.title"),
        detail: this.translateService.instant("RECORD.messages.identity-not-found.detail"),
      });
    }
  }

  handleCancel() {
    this.displayInvitationAnswerModal = false;
  }

  setMessageMenuItems() {
    this.messageMenuItems = [
      {
        label: this.translateService.instant("TEMPLATES.buttons.save-as-template"),
        visible: this.canViewPeripheralElements,
        icon: "pi pi-save",
        command: (event) => {
          this.saveAsTemplatePanel.toggle(event.originalEvent);
        },
      },
    ];

    this.showMessageMenuItems =
      this.messageMenuItems.filter((item) => {
        return item.visible == true;
      }).length > 0;
  }

  handleCreateNewTemplate(templateName: string) {
    let template = new RecordMessageTemplateModel();
    template.name = templateName ?? this.record.title;
    template.locale = this.i18nService.defaultLanguage;
    template.scope = RecordMessageTemplateScopeEnum.USER;

    let templateContent = new RecordMessageTemplateContentModel();
    templateContent.body = this.decryptedMessage;
    templateContent.formItems = this.message.formItems;
    template.content = templateContent;

    // Submitting the creation
    this.isCreatingTemplate = true;
    this.templatesService
      .saveRecordMessageTemplate(template)
      .pipe(
        catchError((error) => {
          log.error(error);
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((template: RecordMessageTemplateModel) => {
        this.isCreatingTemplate = false;
        this.createdTemplate = template;
        if (template) {
          this.tracker.trackEvent("Templates", "Created", template.scope);
        }
      });
  }
}
