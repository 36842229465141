<p-avatar
  *ngIf="user && user.avatar"
  size="small"
  [style]="{ 'width': size + 'px', 'height': size + 'px' }"
  shape="circle"
  [image]="user.avatar"
  [tooltip]="showTooltip ? user.toString() : null"
  [placement]="tooltipPlacement"
  container="body"
></p-avatar>

<div
  [style]="{ 'width': size + 'px', 'height': size + 'px' }"
  *ngIf="user && !user.avatar"
  [tooltip]="showTooltip ? user.toString() : null"
  [placement]="tooltipPlacement"
  container="body"
>
  <avatar
    variant="beam"
    [title]="false"
    [size]="size"
    [name]="user.toAvatarString()"
    [colors]="['#D5D7DD', '#b3f4ff', '#a585ff', '#527dff', '#6e52ff', '#d5d7dd', '#b8b3ff', '#85e9ff']"
    [square]="false"
  ></avatar>
</div>
