import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ThemingService } from "@app/@shared/services/theming.service";
import { UntilDestroy } from "@core";
import { isEmpty } from "lodash";

@UntilDestroy()
@Component({
  selector: "main-file-preview",
  templateUrl: "./file-preview.component.html",
  styleUrls: ["./file-preview.component.scss"],
})
export class FilePreviewComponent implements OnInit {
  displayPreviewDialog: boolean = false;
  header: string;
  isLoading: boolean = true;
  url: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer, public themingService: ThemingService) {}

  ngOnInit(): void {}

  open(url: string, header?: string) {
    if (!isEmpty(url)) {
      this.header = header;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.isLoading = true;
      this.displayPreviewDialog = true;
    }
  }

  close() {
    this.displayPreviewDialog = false;
    this.header = undefined;
    this.url = undefined;
  }

  handleLoad() {
    this.isLoading = false;
  }
}
