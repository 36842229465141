import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SecurityContext,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DataTypeFormatEnum } from "@app/@shared/enums/data-type-format.enum";
import { FormDataControlEnum } from "@app/@shared/enums/form-data/form-data-control.enum";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import FormDataModel, { FormDataAddress, FormDataOption } from "@app/@shared/models/form-data.model";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import RecordModel from "@app/@shared/models/record/record.model";
import WrappedKeyModel from "@app/@shared/models/vault/wrapped-key.model";
import { DatePipe } from "@app/@shared/pipes/date.pipe";
import { CryptographyService } from "@app/@shared/services/cryptography.service";
import { get, isEmpty } from "lodash";
import { CurrencyDisplay } from "../form-items/item-format-number/item-format-number.component";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import * as chrono from "chrono-node";
import { OverlayPanel } from "primeng/overlaypanel";
import { ThemingService } from "@app/@shared/services/theming.service";
import { RecordAPIService } from "@app/@shared/services/record-api.service";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, catchError, of, throwError } from "rxjs";
import { MessageService } from "primeng/api";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { TranslateService } from "@ngx-translate/core";
import { RecordService } from "@app/record/services/record.service";

@UntilDestroy()
@Component({
  selector: "record-form-item-answer",
  templateUrl: "./form-item-answer.component.html",
  styleUrls: ["./form-item-answer.component.scss"],
})
export class RecordFormItemAnswerComponent implements OnInit, OnChanges {
  @ViewChild("validationMessagePromptPanel") validationMessagePromptPanel: OverlayPanel;
  @Input() answerValue: RecordMessageAnswerModel;
  @Input() answerAttachments: RecordMessageAttachmentModel[];
  @Input() formItem: RecordMessageFormItemModel;
  @Input() record: RecordModel;
  @Input() wrappedKeys: WrappedKeyModel[];
  @Input() enableAnswersValidation: boolean = false;
  @Input() enableAnswerShortcut: boolean = true;
  @Input() showValidationBadge: boolean = true;
  @Input() messageIdentifier: string;

  @Output() onShowAnswerForm: EventEmitter<RecordMessageFormItemModel> = new EventEmitter<RecordMessageFormItemModel>();
  @Output() onAnswerValidationUpdated: EventEmitter<any> = new EventEmitter<any>();

  ControlEnum = FormDataControlEnum;
  FormatEnum = DataTypeFormatEnum;
  control: FormDataControlEnum;
  decryptedValue: any;
  description: string;
  isDecrypting: boolean = false;
  isUpdatingValidationStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  label: string;
  validityOptions: any[];
  validationMessage: string;
  @Input() showLabel: boolean = true;

  constructor(
    private i18nService: I18nService,
    private datePipe: DatePipe,
    private cryptographyService: CryptographyService,
    public themingService: ThemingService,
    private recordService: RecordService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.answerValue.validationMessage) this.validationMessage = this.answerValue.validationMessage;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem) {
      this.setContent();
    }

    if (changes.answerValue) {
      this.doDecrypt();
    }
  }

  doDecrypt(): void {
    let value = this.answerValue.content.value;

    if (value) {
      if (this.wrappedKeys != null) {
        this.isDecrypting = true;
        this.cryptographyService
          .decryptText(this.wrappedKeys, value)
          .then((decrypted) => {
            this.decryptedValue = JSON.parse(decrypted);
          })
          .finally(() => {
            this.isDecrypting = false;
          });
      } else {
        this.decryptedValue = value;
      }
    }
  }

  setContent() {
    // Retrieve label
    // if (this.formItem.getLabels()) {
    //   let label = this.formItem.getLabel(this.i18nService.language);
    //   this.label = label ? label : this.formItem.label;
    // } else {
    this.label = this.formItem.label;
    // }

    // Retrieve descripiton
    // if (this.formItem.getDescriptions()) {
    //   let description = this.formItem.getDescription(this.i18nService.language);
    //   this.description = description ? description : this.formItem.description;
    // } else {
    this.description = this.formItem.description;
    // }
  }

  validateFormAnswer() {
    if (this.answerValue.isValid === true) return;
    let { answerIdentifier } = this.answerValue;
    let { recordIdentifier } = this.record;
    this.isUpdatingValidationStatus$.next(true);
    this.recordService
      .validateAnswers(answerIdentifier, null, this.messageIdentifier, recordIdentifier)
      .pipe(
        catchError((error) => {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_ERROR,
            detail: this.translateService.instant("RECORD.form-item-answer.errors.validate.subtitle"),
          });
          this.isUpdatingValidationStatus$.next(false);
          return throwError(() => error);
        }),
        untilDestroyed(this),
      )
      .subscribe((message) => {
        this.isUpdatingValidationStatus$.next(false);
        // Suppose it went well
        if (message && message.messageIdentifier === this.messageIdentifier) {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_SUCCESS,
            detail: this.translateService.instant("RECORD.messages.form-item-answer-validated.detail"),
          });
          this.onAnswerValidationUpdated.emit();
        }
      });
  }

  rejectFormAnswer(addValidationMessage: boolean) {
    let { answerIdentifier } = this.answerValue;
    let { recordIdentifier } = this.record;
    let validationMessage = addValidationMessage ? this.validationMessage : null;
    this.isUpdatingValidationStatus$.next(true);
    this.recordService
      .rejectAnswers(answerIdentifier, null, this.messageIdentifier, recordIdentifier, validationMessage)
      .pipe(
        catchError((error) => {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_ERROR,
            detail: this.translateService.instant("RECORD.form-item-answer.errors.reject.subtitle"),
          });
          this.isUpdatingValidationStatus$.next(false);
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((message) => {
        this.isUpdatingValidationStatus$.next(false);
        this.validationMessagePromptPanel.hide();
        // Suppose it went well
        if (message && message.messageIdentifier === this.messageIdentifier) {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_SUCCESS,
            detail: this.translateService.instant("RECORD.messages.form-item-answer-rejected.detail"),
          });
          this.onAnswerValidationUpdated.emit();
        }
      });
  }

  handleShowAnswerForm() {
    this.onShowAnswerForm.emit(this.formItem);
  }

  get controlTextValues(): { [x: string]: string } {
    if (this.formItem && this.formItem.getControlTexts()) {
      return this.formItem.getControlTextValues(this.i18nService.language);
    }

    return undefined;
  }

  get controlTextValue(): string {
    if (this.controlTextValues) {
      let value = this.formItem.getControlTextValue(this.i18nService.language, this.decryptedValue.toString());
      return value || this.label;
    }

    return undefined;
  }

  getOptionLabel(value: string): string {
    if (this.formItem && this.formItem.getOptions()) {
      let option: FormDataOption = this.formItem.getOption(value);
      return option?.label;
    }

    return undefined;
  }

  get ratingValue(): number {
    return Number.parseInt(this.decryptedValue.toString());
  }

  get ratingMax(): number {
    let ratingFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.RATING_STARS);
    return ratingFormatter ? Number.parseInt(ratingFormatter.value.toString()) : FormDataModel.DEFAULT.rating;
  }

  get formattedAnswerValue() {
    switch (this.formItem.format) {
      case DataTypeFormatEnum.ADDRESS:
        return this.formattedAddressHtml;
      case DataTypeFormatEnum.DATE:
        return this.datePipe.transform(chrono.parseDate(this.decryptedValue.toString()));
      case DataTypeFormatEnum.DATETIME:
        return this.datePipe.transform(chrono.parseDate(this.decryptedValue.toString()), "medium");
      case DataTypeFormatEnum.TIME:
        return this.datePipe.transform(chrono.parseDate(this.decryptedValue.toString()), "mediumTime");
      case DataTypeFormatEnum.PHONE:
        return get(this.decryptedValue, "e164Number", null);
      case DataTypeFormatEnum.MULTICHOICE:
        return this.decryptedValue as any[];
      case DataTypeFormatEnum.RANGE:
        return this.rangeHtml;
      case DataTypeFormatEnum.NUMBER:
        return new Intl.NumberFormat(this.i18nService.language).format(
          Number.parseFloat(this.decryptedValue.toString()),
        );
      case DataTypeFormatEnum.BOOLEAN:
        return this.controlTextValue;
      case DataTypeFormatEnum.CHOICE:
        return this.getOptionLabel(this.decryptedValue.toString());
      case DataTypeFormatEnum.CURRENCY:
        return this.currencyValue;
      case DataTypeFormatEnum.RATING:
      case DataTypeFormatEnum.DOCUMENTS:
      case DataTypeFormatEnum.EMAIL:
      case DataTypeFormatEnum.LONGTEXT:
      case DataTypeFormatEnum.TEXT:
      default:
        return this.decryptedValue;
    }
  }

  get formattedAddressHtml() {
    let value = this.decryptedValue as FormDataAddress;

    switch (this.i18nService.language) {
      default:
        return (
          (value.addressLine1 ? "<div>" + StringHelper.escapeHtml(value.addressLine1) + "</div>" : "") +
          (value.addressLine2 ? "<div>" + StringHelper.escapeHtml(value.addressLine2) + "</div>" : "") +
          (value.addressLine3 ? "<div>" + StringHelper.escapeHtml(value.addressLine3) + "</div>" : "") +
          "<div>" +
          [value.addressPostalCode, value.addressCity]
            .filter((value) => !isEmpty(value))
            .map((v) => StringHelper.escapeHtml(v))
            .join(" ") +
          "</div>" +
          "<div>" +
          [value.addressState, value.addressCountry]
            .filter((value) => !isEmpty(value))
            .map((v) => StringHelper.escapeHtml(v))
            .join(" ") +
          "</div>"
        );
    }
  }

  get rangeHtml() {
    let value = this.decryptedValue as any[];
    return value.join(" - ");
  }

  get currencyValue() {
    let currencyFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.CURRENCY);
    let currency = currencyFormatter?.value?.toString();

    let currencyDisplay;
    let currencyDisplayFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.CURRENCY_DISPLAY);
    if (currencyDisplayFormatter) {
      let currencyDisplayValue = currencyDisplayFormatter.value.toString();
      ["symbol", "code"].includes(currencyDisplayValue) && (currencyDisplay = currencyDisplayValue as CurrencyDisplay);
    }

    return new Intl.NumberFormat(this.i18nService.language, { currency, currencyDisplay, style: "currency" }).format(
      Number.parseFloat(this.decryptedValue.toString()),
    );
  }
}
