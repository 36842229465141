import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UploadState } from "ngx-uploadx";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import { UploadDropDirective } from "@app/@shared/directives/upload-drop.directive";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { FileSizePipe } from "@app/@shared/pipes/file-size.pipe";
import { FileHelper } from "@app/@shared/helpers/file.helper";
import { UploadListVariant } from "../file-upload-list/files-upload-list.component";
import { FileWithPath } from "file-selector";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";
import { AuthorizationService } from "@app/@shared/services/authorization.service";

@Component({
  selector: "main-files-upload",
  templateUrl: "./files-upload.component.html",
  styleUrls: ["./files-upload.component.scss"],
  providers: [UploadDropDirective],
  encapsulation: ViewEncapsulation.None,
})
export class FilesUploadComponent implements OnInit, OnDestroy {
  @ViewChild("uploadInput") input: ElementRef<HTMLInputElement>;

  @Input() enableFoldersUpload: boolean = true;
  signatureAuthorizedFileTypes: string[] = [".pdf"]; // auhtorized files types
  foldersUploadSupported: boolean = false;
  @Input() signatureUploadIds: string[] = [];
  @Input() signatureResources: ProviderItemModel[] = [];
  @Input() displaySignatureTrigger: boolean = false;
  @Input() displayOtherProviders: boolean = false;

  @Output() onCloudFileRemove = new EventEmitter<ProviderItemModel>();
  @Output() onFilesAdded = new EventEmitter<File[]>();
  @Output() onSignatureFilesAdded = new EventEmitter<File[]>();
  @Output() onImportFromCloudClick = new EventEmitter();
  @Output() onImportFromProviderClick = new EventEmitter();
  @Output() onUploadRemove = new EventEmitter<string>();
  @Output() onSignatureUploadIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() onSignatureResourcesChange: EventEmitter<ProviderItemModel[]> = new EventEmitter<ProviderItemModel[]>();

  @Input() id: string = v4();
  @Input() title: string;
  @Input() maxFileSize: number;
  @Input() accept: string[] = null;
  @Input() max: number = null;
  @Input() disabled: boolean = false;
  @Input() isUploading: boolean = false;
  @Input() cloudFiles: ProviderItemModel[] = [];
  @Input() uploads: UploadState[] = [];
  @Input() uploadListVariant: UploadListVariant = "box";

  constructor(
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private fileSizePipe: FileSizePipe,
    private messageService: MessageService,
    public authorizationService: AuthorizationService,
  ) {}

  ngOnInit(): void {
    if (this.deviceDetectorService.isDesktop()) {
      this.foldersUploadSupported = true;
    }
  }

  ngOnDestroy() {}

  handleRemove(uploadId?: string): void {
    this.onUploadRemove.emit(uploadId);
  }

  validMaxFiles(fileIndex: number): boolean {
    /**
     * Check file number limit
     */
    if (Boolean(this.max) && this.uploads.length + fileIndex + 1 > this.max) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.max-files-reached.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.max-files-reached.body", { count: this.max }),
        ),
      );

      return false;
    }

    return true;
  }

  validFile(file: File): boolean {
    /**
     * Check is dot file
     */
    if (FileHelper.isDotFile(file.name)) {
      return false;
    }

    /**
     * Check file size limit
     */
    if (Boolean(this.maxFileSize) && file.size > this.maxFileSize) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.body", {
            limit: this.fileSizePipe.transform(this.maxFileSize),
          }),
        ),
      );

      return false;
    }

    /**
     * Check file type
     */
    if (Boolean(this.accept) && !FileHelper.isAccepted(file, this.accept)) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.wrong-type.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.wrong-type.body", {
            limit: this.fileSizePipe.transform(this.maxFileSize),
          }),
        ),
      );

      return false;
    }

    return true;
  }

  // handleSignatureChange(event, folderUpload = false, markForSignature = true) {
  //   const element = event.currentTarget as HTMLInputElement;
  //   if (element.files) {
  //     this.addFiles(element.files, folderUpload, markForSignature);
  //     element.value = ""; // Reset the input file element to allow the change event to trigger again for the same files
  //   }
  // }

  handleChange(event, folderUpload = false, markForSignature = false): void {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files) {
      this.addFiles(element.files, folderUpload, markForSignature);
      element.value = ""; // Reset the input file element to allow the change event to trigger again for the same files
    }
  }

  handleFilesDropped(files: (FileWithPath | DataTransferItem)[]) {
    this.addFiles(files);
  }

  handleSignatureUploadIdsChange(event: string[]) {
    this.onSignatureUploadIdsChange.emit(event);
  }

  handleSignatureResourcesChange(event: ProviderItemModel[]) {
    this.onSignatureResourcesChange.emit(event);
  }

  handleCloudUpload() {
    this.onImportFromCloudClick.emit();
  }
  handleProviderUpload() {
    this.onImportFromProviderClick.emit();
  }

  handleCloudFileRemove(resource: ProviderItemModel) {
    this.onCloudFileRemove.emit(resource);
  }

  private async addFiles(
    files: FileList | (FileWithPath | DataTransferItem)[],
    folderUpload = false,
    markForSignature = false,
  ) {
    let newFiles = [];

    if (folderUpload) {
      const zip: File = await FileHelper.zipFiles(Array.from(files));
      newFiles.push(zip);
      this.onFilesAdded.emit(newFiles);
    } else {
      Array.from(files).forEach((file: File, index: number) => {
        if (this.validFile(file) && this.validMaxFiles(index)) {
          let existingFilenames = [
            ...this.uploads.map((upload: UploadState) => upload.file.name),
            ...newFiles.map((f: File) => f.name),
          ];
          const newFilename = FileHelper.generateAvailableName(file.name, existingFilenames);
          const newFile = new File([file], newFilename, { type: file.type });
          newFiles.push(newFile);
        }
      });
      markForSignature ? this.onSignatureFilesAdded.emit(newFiles) : this.onFilesAdded.emit(newFiles);
    }
  }

  /**
   * Returns whether multiple files can be attached
   */
  get multiple() {
    return this.max !== null || this.max !== undefined || this.max > 1;
  }

  /**
   * Returns a well formatted value for [accept] HTML attribute
   */
  get htmlAccept() {
    return this.accept
      ? this.accept
          .map((accepted: string) => {
            if (Object.keys(FileHelper.FILE_TYPES).includes(accepted)) {
              let fileTypes = FileHelper.FILE_TYPES[accepted];
              return fileTypes.map((type: string) => (type.includes("*") ? type : "." + type)).join(",");
            }
            return accepted;
          })
          .join(",")
      : "";
  }
}
