<ngx-intl-tel-input
  [cssClass]="'phone-input ' + cssClass"
  [enablePlaceholder]="enablePlaceholder"
  [customPlaceholder]="customPlaceholder"
  [searchCountryFlag]="false"
  [selectedCountryISO]="selectedCountryISO"
  [maxLength]="maxLength"
  [phoneValidation]="phoneValidation"
  [separateDialCode]="separateDialCode"
  [numberFormat]="PhoneNumberFormat.National"
  [inputId]="inputId"
  [title]="title"
  [formControlName]="formControlName"
  [numberFormat]="PhoneNumberFormat.National"
>
</ngx-intl-tel-input>
