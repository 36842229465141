<div class="file gap-2 sm:gap-3" [ngClass]="{ 'disabled': isLoading }">
  <i [class]="file.mimeType | getMimeTypeIcon"></i>
  <div class="file__content" (click)="handleClick()">
    <span class="file__name clamp"> {{ file.name }}</span>
    <span class="file__description clamp"
      >{{ file.size | filesize }} - {{ file.extension }}
      <span *ngIf="displayDate && file.statusDate"> - {{ file.statusDate | date }}</span>
      <span *ngIf="isSignable">
        -
        <i
          class="msr-icon-draw icon-14px msr-icon-fw"
          [pTooltip]="
            'RECORD.fields.signature-uploads.to-sign'
              | translate : { default: 'A signature will be requested for this document' }
          "
          tooltipStyleClass="generic-tooltip"
          [showDelay]="300"
        ></i>
      </span>
    </span>
  </div>

  <div class="file__actions">
    <ui-badge
      *ngIf="showValidationBadge && [false, true].includes(isValidated)"
      [variant]="isValidated === true ? 'success' : 'error'"
      size="small"
      [label]="
        isValidated === true
          ? ('RECORD.form-item-answer.validation.approval.status' | translate)
          : ('RECORD.form-item-answer.validation.rejection.status' | translate)
      "
    ></ui-badge>
    <button
      pButton
      class="file__actions__button p-button-text p-1 w-2rem h-2rem"
      style="border-radius: 2px"
      icon="msr-icon-more_vert"
      [disabled]="isLoading"
      type="button"
      (click)="menu.toggle($event)"
      [tooltip]="'FILES.actions.more-actions' | translate : { 'default': 'More actions' }"
      placement="top"
      [container]="'body'"
      [delay]="600"
    ></button>
  </div>
</div>
<p-menu #menu appendTo="body" [popup]="true" [model]="downloadActions" styleClass="generic-actions-menu"></p-menu>

<!-- File preview -->
<main-file-preview #filePreviewer></main-file-preview>

<!-- Nestor Drive folder picker -->
<p-dialog
  [(visible)]="displayCloudFolderPickerModal"
  appendTo="body"
  [modal]="true"
  position="center"
  [header]="'MAIN.export-to-cloud.title' | translate : { 'default': 'Select a directory where to import the file' }"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="themingService.customStyles$ | async"
  styleClass="dialog-nested"
  (onHide)="handleCloudFolderPickerClose()"
>
  <ng-template pTemplate="content">
    <main-cloud-file-picker
      mode="folders"
      [enableFolderCreation]="true"
      (onSubmit)="handleCloudFolderPickerSubmitted($event)"
      (onCancel)="handleCloudFolderPickerClose()"
    ></main-cloud-file-picker>
  </ng-template>
</p-dialog>

<!-- Provider folder picker -->
<p-dialog
  [(visible)]="displayProviderFolderPickerModal"
  appendTo="body"
  [modal]="true"
  position="center"
  [header]="'MAIN.export-to-cloud.title' | translate : { 'default': 'Select a directory where to import the file' }"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  [breakpoints]="{ '960px': 'var(--medium-measure, 80rem)', '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="themingService.customStyles$ | async"
  styleClass="dialog-nested"
  (onHide)="handleProviderFolderPickerClose()"
>
  <ng-template pTemplate="content">
    <provider-file-picker
      mode="folders"
      [foldersOnly]="true"
      [includeShared]="false"
      [enableFolderCreation]="true"
      (onSubmit)="handleCloudFolderPickerSubmitted($event)"
      (onCancel)="handleProviderFolderPickerClose()"
    ></provider-file-picker>
  </ng-template>
</p-dialog>
