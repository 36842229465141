import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import UserModel from "@shared/models/user/user.model";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { UserAPIService } from "@app/@shared/services/user-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UserConsentsFieldComponent } from "../user-consents-field/user-consents-field.component";

@UntilDestroy()
@Component({
  selector: "main-consents-panel",
  templateUrl: "./consents-panel.component.html",
  styleUrls: ["./consents-panel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ConsentsPanelComponent implements OnInit {
  @Input() showConsents: boolean = false;

  @Output() userChange = new EventEmitter<UserModel>();
  @Input()
  set user(user: UserModel) {
    this._user = user;
    if (
      user &&
      (user.properties["consent-analytics"] === undefined ||
        user.properties["consent-newsletters"] === undefined ||
        user.properties["consent-debug-logs"] === undefined)
    ) {
      this.showConsents = true;
    } else {
      this.showConsents = false;
    }
  }
  get user(): UserModel {
    return this._user;
  }
  private _user: UserModel = new UserModel();

  @ViewChild("consentsField") consentsField: UserConsentsFieldComponent;

  constructor(private userAPIService: UserAPIService) {}

  ngOnInit(): void {}

  doUpdateUser(): void {
    if (this.consentsField) {
      this._user.properties["consent-debug-logs"] = this.consentsField.consentLogs;
      this._user.properties["consent-newsletters"] = this.consentsField.consentNewsletter;
      this._user.properties["consent-analytics"] = this.consentsField.consentAnalytics;
    }

    this.userAPIService
      .updateUser(this._user, [ResponseLevelEnum.USER_DETAILS])
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this._user = user;
        this.userChange.emit(this._user);
        this.showConsents = false;
      });
  }

  doAcceptAll() {
    this._user.properties["consent-debug-logs"] = true;
    this._user.properties["consent-newsletters"] = true;
    this._user.properties["consent-analytics"] = true;

    this.doUpdateUser();
  }

  doClose() {
    this.showConsents = false;
  }
}
