<div class="flex flex-row justify-content-between">
  <div class="flex flex-column gap-1" *ngIf="recipientsAccepted.length">
    <label class="text-sm text-gray-500">{{
      "RECORD.record-message-invitation.accepted" | translate: { "default": "Accepted invitation" }
    }}</label>
    <p-avatarGroup [tooltip]="acceptedRecipientsTemplate" placement="top" parent="body">
      <p-avatar
        *ngFor="let recipient of recipientsAccepted"
        shape="circle"
        [label]="recipient | getRecipientInitials"
      ></p-avatar>
    </p-avatarGroup>
  </div>

  <div class="flex flex-column gap-1" *ngIf="recipientsDeclined.length">
    <label class="text-sm text-gray-500">{{
      "RECORD.record-message-invitation.declined" | translate: { "default": "Declined invitation" }
    }}</label>
    <p-avatarGroup [tooltip]="declinedRecipientsTemplate" placement="top" parent="body">
      <p-avatar
        *ngFor="let recipient of recipientsDeclined"
        shape="circle"
        [label]="recipient | getRecipientInitials"
        class="declined"
      ></p-avatar>
    </p-avatarGroup>
  </div>

  <div class="flex flex-column gap-1" *ngIf="recipientsPending.length">
    <label class="text-sm text-gray-500">{{
      "RECORD.record-message-invitation.pending" | translate: { "default": "Pending invitation" }
    }}</label>
    <p-avatarGroup [tooltip]="pendingRecipientsTemplate" placement="top" parent="body">
      <p-avatar
        *ngFor="let recipient of recipientsPending"
        shape="circle"
        [label]="recipient | getRecipientInitials"
        class="inactive"
      ></p-avatar>
    </p-avatarGroup>
  </div>

  <ng-template #acceptedRecipientsTemplate>
    <div [innerHtml]="tooltipAcceptedRecipientsContent"></div>
  </ng-template>

  <ng-template #declinedRecipientsTemplate>
    <div [innerHtml]="tooltipDeclinedRecipientsContent"></div>
  </ng-template>

  <ng-template #pendingRecipientsTemplate>
    <div [innerHtml]="tooltipPendingRecipientsContent"></div>
  </ng-template>
</div>
