import { Component, EventEmitter, Input, Output } from "@angular/core";
import RecordMessageTemplateModel from "@app/@shared/models/masterdata/record-message-template.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "save-message-as-template",
  templateUrl: "./save-message-as-template.component.html",
  styleUrls: ["./save-message-as-template.component.scss"],
})
export class SaveMessageAsTemplateComponent {
  @Input() createdTemplate: RecordMessageTemplateModel;
  @Input() isCreatingTemplate: boolean = false;

  @Input() newTemplateName: string;

  @Output() onCancelSaveAsTemplate: EventEmitter<any> = new EventEmitter();
  @Output() onRetryCreatingNewTemplate: EventEmitter<any> = new EventEmitter();
  @Output() onCreateNewTemplate: EventEmitter<string> = new EventEmitter();

  constructor(private translateService: TranslateService) {}
}
