import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ControlContainer, UntypedFormGroup } from "@angular/forms";
import { DataTypeFormatEnum } from "@app/@shared/enums/data-type-format.enum";
import { FormDataControlEnum } from "@app/@shared/enums/form-data/form-data-control.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";

@Component({
  selector: "record-form-item-preview",
  templateUrl: "./form-item-preview.component.html",
  styleUrls: ["./form-item-preview.component.scss"],
})
export class RecordFormItemPreviewComponent implements OnInit, OnChanges {
  @Input() formItem: RecordMessageFormItemModel;

  ControlEnum = FormDataControlEnum;
  FormatEnum = DataTypeFormatEnum;
  control: FormDataControlEnum;
  description: string;
  label: string;
  parentFormGroup: UntypedFormGroup;

  constructor(public controlContainer: ControlContainer, private i18nService: I18nService) {}

  ngOnInit(): void {
    this.updateParentFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem) {
      this.updateParentFormGroup();
      this.setControl();
      this.setContent();
    }
  }

  updateParentFormGroup() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
  }

  setControl() {
    // Retrieve control
    if (this.formItem.getControl()) {
      let control = this.formItem.getControl();
      control && (this.control = control);
    } else {
      this.control = this.formItem.getDefaultControl();
    }
  }

  setContent() {
    // Retrieve label
    // if (this.formItem.getLabels()) {
    //   let label = this.formItem.getLabel(this.i18nService.language);
    //   label && (this.label = label);
    // } else {
    this.label = this.formItem.label;
    // }

    // Retrieve descripiton
    // if (this.formItem.getDescriptions()) {
    //   let description = this.formItem.getDescription(this.i18nService.language);
    //   this.description = description;
    // } else {
    this.description = this.formItem.description;
    // }
  }
}
