import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree } from "@angular/router";
import { filter, map, Observable } from "rxjs";
import { Logger, UntilDestroy } from "@core";
import { NavigationService } from "../services/navigation.service";
import { AuthorizationService } from "../services/authorization.service";

const log = new Logger("Shared/HasRoleGuard");

/**
 * Provides a guard for components that can be accessed only when a unit has a billing account
 */
@UntilDestroy()
@Injectable()
export class HasRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private authorizationService: AuthorizationService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let roles = route.data.roles as Array<string>;

    return this.authorizationService.initialized$.pipe(
      filter((initialized: boolean) => initialized),
      map(() => {
        if (this.authorizationService.hasAnyKeycloakRole(roles)) {
          return true;
        }

        log.debug("Has not required role, redirecting...");
        this.router.navigate(["/"], { replaceUrl: true });

        return false;
      }),
    );
  }
}
