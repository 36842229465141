<p-rating
  [name]="name"
  [id]="inputId"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  [stars]="stars"
  [cancel]="cancel"
  iconCancelClass="pi pi-times"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  (onRate)="handleChange($event)"
></p-rating>
