import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { v4 as v4 } from "uuid";

@Component({
  selector: "record-form-item-format-slider-range",
  templateUrl: "./item-format-slider-range.component.html",
  styleUrls: ["./item-format-slider-range.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatSliderRangeComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatSliderRangeComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Slider options
  @Input() orientation: "horizontal" | "vertical" = "horizontal";
  @Input() prefix: string;
  @Input() suffix: string;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() step: number = 1;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = [];
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(private formBuilder: UntypedFormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  get lowerBound() {
    return (this.prefix ? this.prefix + " " : "") + this.value[0] + (this.suffix ? " " + this.suffix : "");
  }

  get higherBound() {
    return (this.prefix ? this.prefix + " " : "") + this.value[1] + (this.suffix ? " " + this.suffix : "");
  }

  writeValue(value: any): void {
    this.value = value;
    if (!this.value) {
      this.value = [this.min, this.max];
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    if (this.formItem.getFormatters()) {
      // Min formatter
      let minFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MIN);
      if (minFormatter && minFormatter.value) {
        let min = Number.parseFloat(minFormatter.value.toString());
        this.min = min;
      }

      // Max formatter
      let maxFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MAX);
      if (maxFormatter && maxFormatter.value) {
        let max = Number.parseFloat(maxFormatter.value.toString());
        this.max = max;
      }

      // Step formatter
      let stepFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.STEP);
      if (stepFormatter && stepFormatter.value) {
        let step = Number.parseFloat(stepFormatter.value.toString());
        this.step = step;
      }

      // Prefix formatter
      let prefixFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.PREFIX);
      if (prefixFormatter && prefixFormatter.value) {
        this.prefix = prefixFormatter.value.toString();
      }

      // Suffix formatter
      let suffixFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.SUFFIX);
      if (suffixFormatter && suffixFormatter.value) {
        this.suffix = suffixFormatter.value.toString();
      }
    }
  }

  handleChange(event) {
    if (event) {
      this.onModelTouched();
      this.onModelChange(event.values);
      this.writeValue(event.values);
      this.onChange.emit(event.values);
    }
  }
}
