<input
  pInputText
  type="email"
  [name]="name"
  [id]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  [pKeyFilter]="allowedChard"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  [value]="value"
  [ngClass]="{
    'ng-invalid ng-dirty':
      parentFormGroup.get(formItem.uniqueId)?.invalid && parentFormGroup.get(formItem.uniqueId)?.touched
  }"
/>

<small
  [hidden]="parentFormGroup.get(formItem.uniqueId)?.valid || parentFormGroup.get(formItem.uniqueId)?.untouched"
  class="p-error"
>
  <span *ngIf="parentFormGroup.get(formItem.uniqueId)?.errors?.email">{{
    "COMMON.fields.email-address.errors.email" | translate: { "default": "Email is invalid" }
  }}</span>
</small>
