export enum UploadTypeEnum {
  STANDARD = "standard",
  BATCH = "batch",

  // Record message form
  MESSAGE = "message",

  // Form answers
  FORM_ANSWER = "formAnswer",

  // Signature
  SIGNATURE = "signature",
  SIGNATURE_ATTACHMENT = "signatureAttachment",
}
