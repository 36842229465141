import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { Message } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class MessageHelper {
  constructor() {}

  static createErrorMessage(severity: MessageSeverityEnum, error: HttpErrorResponse): Message {
    if (error) {
      return {
        severity: severity || MessageSeverityEnum.SEVERITY_INFO,
        summary: error.status.toString(),
        detail: error.message,
      };
    }
    return undefined;
  }

  static createTextMessage(
    severity: MessageSeverityEnum,
    summary: string,
    detail: string,
    data?: any,
    key?: string,
    life?: number,
  ): Message {
    return {
      severity: severity || MessageSeverityEnum.SEVERITY_INFO,
      summary: summary,
      detail: detail,
      data,
      key,
      life,
    };
  }
}
