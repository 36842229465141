import { Injectable } from "@angular/core";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { RecordMessageTemplateOrderEnum } from "@app/@shared/enums/record-message-template-order.enum";
import { RecordMessageTemplateScopeEnum } from "@app/@shared/enums/record-message-template-scope.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import RecordMessageTemplateModel from "@app/@shared/models/masterdata/record-message-template.model";
import RecordMessageTemplatesResultModel from "@app/@shared/models/masterdata/record-message-templates-result.model";
import { MasterDataAPIService } from "@app/@shared/services/masterdata-api.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { Logger } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  map,
  mergeMap,
  Observable,
  Subscription,
  switchMap,
  tap,
  throwError,
} from "rxjs";

const log = new Logger("TemplatesService");

@Injectable({
  providedIn: "root",
})
export class TemplatesService {
  DEFAULT_LIMIT = 10;

  // contacts observable
  private _recordMessageTemplates$: BehaviorSubject<RecordMessageTemplateModel[]> = new BehaviorSubject([]);
  recordMessageTemplates$: Observable<RecordMessageTemplateModel[]> = this._recordMessageTemplates$.asObservable();

  get recordMessageTemplates(): RecordMessageTemplateModel[] {
    return this._recordMessageTemplates$.getValue();
  }
  set recordMessageTemplates(recordMessageTemplates: RecordMessageTemplateModel[]) {
    this._recordMessageTemplates$.next(recordMessageTemplates);
  }

  // searchText observable
  private _searchText$: BehaviorSubject<string> = new BehaviorSubject(null);
  searchText$: Observable<string> = this._searchText$.asObservable();

  get searchText(): string {
    return this._searchText$.getValue();
  }
  set searchText(searchText: string) {
    this._searchText$.next(searchText);
  }

  // order observable
  private _order$: BehaviorSubject<RecordMessageTemplateOrderEnum> = new BehaviorSubject(null);
  order$: Observable<RecordMessageTemplateOrderEnum> = this._order$.asObservable();

  get order(): RecordMessageTemplateOrderEnum {
    return this._order$.getValue();
  }
  set order(order: RecordMessageTemplateOrderEnum) {
    this._order$.next(order);
  }

  // scope observable
  private _scope$: BehaviorSubject<RecordMessageTemplateScopeEnum> = new BehaviorSubject(
    RecordMessageTemplateScopeEnum.USER,
  );
  scope$: Observable<RecordMessageTemplateScopeEnum> = this._scope$.asObservable();

  get scope(): RecordMessageTemplateScopeEnum {
    return this._scope$.getValue();
  }
  set scope(scope: RecordMessageTemplateScopeEnum) {
    this._scope$.next(scope);
  }

  // offset observable
  private _offset$: BehaviorSubject<number> = new BehaviorSubject(0);
  offset$: Observable<number> = this._offset$.asObservable();

  get offset(): number {
    return this._offset$.getValue();
  }
  set offset(offset: number) {
    this._offset$.next(offset);
  }

  // limit observable
  private _limit$: BehaviorSubject<number> = new BehaviorSubject(this.DEFAULT_LIMIT);
  limit$: Observable<number> = this._limit$.asObservable();

  get limit(): number {
    return this._limit$.getValue();
  }
  set limit(limit: number) {
    this._limit$.next(limit);
  }

  // total observable
  private _total$: BehaviorSubject<number> = new BehaviorSubject(0);
  total$: Observable<number> = this._total$.asObservable();

  get total(): number {
    return this._total$.getValue();
  }

  // totalPages observable
  private _totalPages$: BehaviorSubject<number> = new BehaviorSubject(0);
  totalPages$: Observable<number> = this._totalPages$.asObservable();

  get totalPages(): number {
    return this._totalPages$.getValue();
  }

  // isLoading observable
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isLoading$ = this._isLoading$.asObservable();

  // isSaving observable
  private _isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSaving$ = this._isSaving$.asObservable();

  // isDeleting observable
  private _isDeleting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDeleting$ = this._isDeleting$.asObservable();

  private subscription: Subscription;

  constructor(
    private sessionAPIService: SessionAPIService,
    private masterdataAPIService: MasterDataAPIService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  initialize(
    offset: number = 0,
    limit: number = this.DEFAULT_LIMIT,
    scope: RecordMessageTemplateScopeEnum = RecordMessageTemplateScopeEnum.USER,
    order: RecordMessageTemplateOrderEnum = null,
    searchText: string = null,
  ) {
    if (this.subscription) this.subscription.unsubscribe();

    if (offset !== this.offset) this._offset$.next(offset);
    if (limit !== this.limit) this._limit$.next(limit);
    if (scope !== this.scope) this._scope$.next(scope);
    if (order !== this.order) this._order$.next(order);
    if (searchText !== this.searchText) this._searchText$.next(searchText);

    this.subscription = combineLatest([
      this.sessionAPIService.isLoaded$,
      this.offset$,
      this.limit$,
      this.scope$,
      this.order$,
      this.searchText$,
    ])
      .pipe(
        filter(([sessionLoaded]) => sessionLoaded),
        switchMap(() => this.getRecordMessageTemplates()),
      )
      .subscribe((templates: RecordMessageTemplateModel[]) => {});
  }

  getRecordMessageTemplates(
    scope?: RecordMessageTemplateScopeEnum,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<RecordMessageTemplateModel[]> {
    this._isLoading$.next(true);

    return this.masterdataAPIService
      .getRecordMessageTemplates(
        this.sessionAPIService.unit.unitSlug,
        { searchText: this.searchText, scope: this.scope, orderBy: this.order },
        this.offset,
        this.limit,
        responseLevels,
      )
      .pipe(
        tap((result: RecordMessageTemplatesResultModel) => {
          this.updatePageInfo(result);
          this._recordMessageTemplates$.next(result.templates);
        }),
        tap(() => this._isLoading$.next(false)),
        switchMap(() => this.recordMessageTemplates$),
      );
  }

  getRecordMessageTemplate(
    templateIdentifier?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    this._isLoading$.next(true);

    return this.masterdataAPIService
      .getRecordMessageTemplate(this.sessionAPIService.unit.unitSlug, templateIdentifier, responseLevels)
      .pipe(
        tap(() => this._isLoading$.next(false)),
        catchError((error) => {
          return throwError(() => error);
        }),
        map((template: RecordMessageTemplateModel) => template),
      );
  }

  saveRecordMessageTemplate(
    template?: RecordMessageTemplateModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    this._isSaving$.next(true);

    return this.masterdataAPIService
      .saveRecordMessageTemplate(this.sessionAPIService.unit.unitSlug, template, responseLevels)
      .pipe(
        mergeMap((template: RecordMessageTemplateModel) =>
          this.getRecordMessageTemplates(null, [ResponseLevelEnum.ALL]).pipe(map(() => template)),
        ),
        tap(() => this._isSaving$.next(false)),
      );
  }

  duplicateRecordMessageTemplate(
    template?: RecordMessageTemplateModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    this._isSaving$.next(true);

    return this.masterdataAPIService
      .duplicateRecordMessageTemplate(this.sessionAPIService.unit.unitSlug, template, responseLevels)
      .pipe(
        mergeMap((template: RecordMessageTemplateModel) =>
          this.getRecordMessageTemplates(null, [ResponseLevelEnum.ALL]).pipe(map(() => template)),
        ),
        tap(() => this._isSaving$.next(false)),
      );
  }

  shareRecordMessageTemplate(
    template?: RecordMessageTemplateModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    this._isSaving$.next(true);

    return this.masterdataAPIService
      .shareRecordMessageTemplate(this.sessionAPIService.unit.unitSlug, template, responseLevels)
      .pipe(
        mergeMap((template) => this.getRecordMessageTemplates(null, [ResponseLevelEnum.ALL]).pipe(map(() => template))),
        tap(() => this._isSaving$.next(false)),
      );
  }

  deleteRecordMessageTemplates(
    params: {
      templates: RecordMessageTemplateModel[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel[]> {
    this._isDeleting$.next(true);

    return this.masterdataAPIService
      .deleteRecordMessageTemplates(this.sessionAPIService.unit.unitSlug, params, responseLevels)
      .pipe(
        tap(() => this._isDeleting$.next(false)),
        switchMap(() => this.resetPage(true)),
      );
  }

  /**
   * Pagination
   */
  updatePageInfo(result: RecordMessageTemplatesResultModel) {
    this._total$.next(result.totalCount);

    const pages = this.limit <= 0 ? 0 : Math.ceil(result.totalCount / this.limit);
    this._totalPages$.next(pages);
  }

  nextPage(): Observable<RecordMessageTemplateModel[]> {
    if (this.offset + this.limit < this.total) {
      this._offset$.next(this.offset + this.limit);
    }

    return this._recordMessageTemplates$;
  }

  prevPage(): Observable<RecordMessageTemplateModel[]> {
    if (this.offset != 0) {
      if (this.offset - this.limit < 0) {
        this.resetPage();
      } else {
        this._offset$.next(this.offset - this.limit);
      }
    }

    return this._recordMessageTemplates$;
  }

  resetPage(force: boolean = false): Observable<RecordMessageTemplateModel[]> {
    if (this.offset > 0 || force) {
      this._offset$.next(0);
    }

    return this._recordMessageTemplates$;
  }
}
