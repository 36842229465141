import BaseModel from "@shared/models/base.model";
import CouponModel from "./coupon.model";
import PromotionCodeModel from "./promotion-code.model";

export default class DiscountModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public coupon: CouponModel;
  public end: number | null = null;
  public promotionCode: PromotionCodeModel;
  public start: number | null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.coupon) this.coupon = new CouponModel().deserialize(input.coupon);

    if (input.promotionCode) this.promotionCode = new PromotionCodeModel().deserialize(input.promotionCode);

    return this;
  }
}
