<!-- Search field in signing mode -->
<div *ngIf="isSelectingSigners && !displaySignatureIndicator">
  <span class="text-color-secondary">
    {{ "RECORD.fields.signers.search" | translate : { default: "Search through recipients to designate signers" } }}
  </span>
  <span class="p-input-icon-left w-full mt-1">
    <i class="pi pi-search"></i>
    <input
      class="w-full"
      #searchRecipientsInput
      (keydown.enter)="handleSearchRecipient($event)"
      pInputText
      autofocus
      type="search"
      [(ngModel)]="searchRecipient"
    />
  </span>
</div>

<div
  class="border-round surface-card border-1 surface-border"
  [ngClass]="{
    'card-invalid': markAsInvalid && !isValidRecipientEmail,
    'shadow-1 mt-1': !(filteredRecipients?.length > 0) && isValidRecipientEmail,
    'mt-3': !isValidRecipientEmail || recipients?.length == 0
  }"
>
  <!-- No recipient -->
  <div
    class="flex flex-column justify-content-center align-items-center text-center"
    style="min-height: 18rem"
    *ngIf="!recipients || recipients?.length == 0"
  >
    <i class="pi pi-user text-4xl text-primary"></i>
    <span class="text-color-secondary mt-3">{{
      "RECORD.recipients.empty" | translate : { default: "No recipients" }
    }}</span>
    <span class="text-color-tertiary mt-1">{{
      "RECORD.recipients.prompt" | translate : { default: "Add recipients via the search bar or" }
    }}</span>
    <button
      type="button"
      pButton
      class="p-button-text mt-1"
      style="padding: 0.5rem 0.75rem"
      icon="pi pi-user-plus text-xl"
      [label]="'ADDRESS-BOOK.manager.create-modal.header' | translate"
      (click)="displayContactCreationModal()"
    ></button>
  </div>
  <!-- Recipients list -->
  <div *ngIf="recipients?.length > 0" class="border-round overflow-y-auto" style="max-height: 18rem">
    <!-- Filtered recipients -->
    <ng-container *ngIf="filteredRecipients?.length > 0; else emptyFilterMessage">
      <div
        *ngFor="let recipient of filteredRecipients; let first = first"
        class="flex align-items-center gap-2 py-2 pr-3 pl-3"
        [ngClass]="{
          'border-top-1 surface-border': !first,
          'surface-c': signatureRecipientEmails.includes(recipient.emailAddress)
        }"
      >
        <!-- Marker -->
        <span class="custom-marker hidden md:flex">
          {{ recipient | getRecipientInitials }}
        </span>
        <!-- Details -->
        <div
          class="flex flex-column md:pl-1 min-w-0"
          [ngClass]="{
            'cursor-pointer':
              !isSelectingSigners ||
              (signatureRecipientEmails.includes(recipient.emailAddress) && !recipient.isValid(true))
          }"
          (click)="
            (!isSelectingSigners ||
              (signatureRecipientEmails.includes(recipient.emailAddress) && !recipient.isValid(true))) &&
              handleEditRecipient(recipient)
          "
        >
          <span class="font-medium text-color clamp block">{{ recipient.toString }} </span>
          <div class="flex flex-wrap text-sm text-color-secondary" style="column-gap: 0.5rem">
            <div class="flex align-items-center gap-1 min-w-0" *ngIf="recipient.emailAddress" data-avatar--email>
              <i class="text-sm msr-icon-mail"></i>
              <span class="clamp block">{{ recipient.emailAddress }}</span>
            </div>
            <div class="flex align-items-center gap-1 min-w-0" *ngIf="recipient.phoneNumber" data-avatar--phone>
              <i class="text-sm msr-icon-smartphone"></i>
              <span class="clamp block">{{ recipient.phoneNumber }}</span>
            </div>
          </div>
        </div>

        <aside class="flex gap-2 align-items-center ml-auto">
          <!-- Tags -->
          <ng-container *ngIf="recipient?.contactSource?.tags as tags">
            <div class="hidden md:flex gap-1 lg:pr-2" *ngIf="tags.length > 0 && !isSelectingSigners">
              <ui-badge
                cssMaxWidth="18ch"
                *ngIf="tags?.[0]"
                variant="default"
                size="small"
                [label]="tags?.[0]"
              ></ui-badge>
              <ui-badge
                [pTooltip]="tags?.slice(1).join(', ')"
                tooltipStyleClass="generic-tooltip"
                [showDelay]="600"
                [appendTo]="'body'"
                *ngIf="tags?.length > 1"
                variant="default"
                size="small"
                [label]="'+' + (tags?.length - 1)"
              ></ui-badge>
            </div>
          </ng-container>
          <!-- Buttons -->
          <!-- EDIT BUTTON -->
          <button
            type="button"
            pButton
            icon="pi pi-user-edit"
            [pTooltip]="
              isSelectingSigners
                ? 'Certaines informations du contact sont manquantes pour signer un document. Cliquez sur l’icône pour ouvrir la modale de modification.'
                : ('COMMON.buttons.edit' | translate)
            "
            [appendTo]="'body'"
            tooltipStyleClass="generic-tooltip"
            [tooltipDisabled]="isSelectingSigners && recipient.isValid(true)"
            [showDelay]="600"
            style="--p-button-width: 1.8rem; --p-button-height: 1.8rem; --border-radius: 2px"
            class="p-button-text p-button-secondary p-2 ml-3"
            *ngIf="
              !isSelectingSigners ||
              (signatureRecipientEmails.includes(recipient.emailAddress) && !recipient.isValid(true))
            "
            [ngClass]="{ 'p-button-danger': isSelectingSigners && !recipient.isValid(true) }"
            (click)="handleEditRecipient(recipient)"
          ></button>
          <!-- SIGNATURE INDICATOR -->
          <div
            class="flex align-items-center justify-content-center"
            *ngIf="displaySignatureIndicator && signatureRecipientEmails.includes(recipient.emailAddress)"
            [pTooltip]="'COMMON.signer' | translate : { default: 'Signer' }"
            tooltipStyleClass="generic-tooltip"
          >
            <i class="msr-icon-draw icon-22px font-light text-primary msr-icon-fw"></i>
          </div>
          <!-- SIGNATURE BUTTON -->
          <ng-container *ngIf="isSelectingSigners && !displaySignatureIndicator">
            <p-checkbox
              [inputId]="recipient.emailAddress"
              name="enableSignature"
              [checkboxIcon]="'msr-icon-draw icon-20px msr-icon-fw'"
              class="signature-checkbox sr-only"
              (ngModelChange)="handleSignatureRecipientChange($event)"
              [value]="recipient.emailAddress"
              [ngModel]="signatureRecipientEmails"
            ></p-checkbox>
            <label
              [for]="recipient.emailAddress"
              style="
                --border-radius: 4px;
                border-color: var(--secondary-color);
                background-color: var(--surface-b);
                width: 2.5rem;
                height: 2.5rem;
              "
              class="cursor-pointer md:mr-6 border-1 border-round flex align-items-center justify-content-center ml-3"
              [ngClass]="
                signatureRecipientEmails.includes(recipient.emailAddress)
                  ? 'bg-primary text-white border-primary'
                  : 'text-secondary'
              "
            >
              <i class="msr-icon-draw icon-20px font-light msr-icon-fw"></i>
            </label>
          </ng-container>
          <!-- REMOVE BUTTON -->
          <button
            type="button"
            pButton
            icon="pi pi-times"
            [tooltip]="'COMMON.buttons.remove' | translate"
            [delay]="600"
            style="--p-button-width: 1.8rem; --p-button-height: 1.8rem; --border-radius: 2px"
            class="p-button-text p-button-secondary p-2 ml-3"
            *ngIf="!isSelectingSigners"
            (click)="handleRemoveRecipient(recipient)"
          ></button>
        </aside>
      </div>
    </ng-container>
    <!-- Filtering led to no results -->
    <ng-template #emptyFilterMessage>
      <div class="p-1">
        <!-- User input is a valid email -->
        <ng-container *ngIf="isValidRecipientEmail">
          <p-button
            type="button"
            class="align-self-baseline w-full"
            styleClass="text-left p-button-text w-full flex border-noround"
            [style]="{ 'background-color': 'var(--background-hover-color)' }"
            (click)="handleSearchRecipient($event)"
          >
            <ng-template pTemplate="content">
              <div class="flex px-1 align-items-baseline">
                <span class="font-medium text-color">
                  {{
                    "RECORD.recipients.search.buttons.create-contact"
                      | translate : { default: "Create new contact for : " }
                  }}
                  <span class="font-bold">{{ searchRecipient }}</span>
                </span>
              </div>
            </ng-template>
          </p-button>
        </ng-container>
        <!-- User input is not a valid email -->
        <div
          class="flex flex-column justify-content-center align-items-center"
          style="min-height: 8rem"
          *ngIf="!isValidRecipientEmail"
        >
          <i class="pi pi-user-plus text-3xl"></i>
          <span class="mt-2">
            {{
              "RECORD.recipients.search.empty-filter.title"
                | translate : { default: "We could not find an existing contact for " }
            }}
            <span class="font-bold text-color" *ngIf="searchRecipient">{{ searchRecipient }}</span>
          </span>
          <span class="mt-1">
            {{
              "RECORD.recipients.search.empty-filter.subtitle"
                | translate : { default: "You can also enter a valid email" }
            }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<!-- EDIT RECIPIENT FROM LIST -->
<p-dialog
  [(visible)]="showRecipientEditModal"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '960px': 'var(--xsmall-measure)', '640px': '100vw' }"
  [style]="{ width: 'var(--xsmall-measure)' }"
  styleClass="dialog-nested"
  (onHide)="handleEditRecipientClose()"
>
  <ng-template pTemplate="header">
    <header>
      <h3 class="my-0">
        {{
          isSelectingSigners
            ? ("RECORD.recipients.fill-out-signer.header" | translate : { "default": "Fill out" })
            : ("RECORD.recipients.edit-recipient" | translate : { "default": "Edit recipient" })
        }}
      </h3>
    </header>
  </ng-template>
  <record-recipient-form
    *ngIf="selectedRecipient"
    [readonlyEmail]="readonlyEmail"
    [isEditingSigner]="isSelectingSigners"
    [recipient]="selectedRecipient"
    (onSubmit)="handleSaveRecipient($event)"
    (onCancel)="handleEditRecipientClose()"
  ></record-recipient-form>
</p-dialog>
