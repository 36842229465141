import { Component, Input, OnInit } from "@angular/core";
import RecordReminderPropertiesModel from "@app/@shared/models/record/record-reminder-properties.model";

@Component({
  selector: "record-reminders-calendar",
  templateUrl: "./record-reminders-calendar.component.html",
  styleUrls: ["./record-reminders-calendar.component.scss"],
})
export class RecordRemindersCalendarComponent implements OnInit {
  @Input() recordReminderProperties: RecordReminderPropertiesModel = new RecordReminderPropertiesModel();
  @Input() reminderMinDate: Date = null;
  @Input() reminderMaxDate: Date = null;
  constructor() {}
  ngOnInit(): void {}
  get minDate() {
    if (this.recordReminderProperties.dates.length > 0) {
      return new Date(this.recordReminderProperties.dates[0]);
    } else {
      return null;
    }
  }
  get maxDate() {
    if (this.recordReminderProperties.dates.length > 0) {
      let date = new Date(this.recordReminderProperties.dates[this.recordReminderProperties.dates.length - 1]);
      return date;
    } else {
      return null;
    }
  }
}
