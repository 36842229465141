import BaseModel from "@shared/models/base.model";
import UnitModel from "@shared/models/domain/unit.model";
import IdentityModel from "@shared/models/user/identity.model";

export default class SignatureModel extends BaseModel {
  public signatureIdentifier: string | null = null;
  public recordIdentifier?: string;
  public recordMessageIdentifier?: string;

  public provider: string | null = null;
  public signatureMethod?: string;
  public signatureStatus?: string;

  public metadata: Object = {};

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
