<div
  pFocusTrap
  class="data-type-dialog__container"
  [attr.data-grid-view-for]="formItem ? (formItemEditionView ? 'edition' : 'configuration') : 'selection'"
>
  <!-- Form Item Selection Panel -->
  <aside class="data-type-dialog__selection" *ngIf="!formItemEditionView">
    <record-data-types-list [dataTypes]="dataTypes" (onSelect)="handleDataTypeSelect($event)"></record-data-types-list>
  </aside>
  <!-- Form Item Header with label and description -->
  <header *ngIf="formItem" class="data-type-dialog__header">
    <span class="font-medium">
      {{ originalLabel || formItem.label }}
    </span>
    <small class="text-color-secondary" *ngIf="originalDescription">
      {{ originalDescription }}
    </small>
  </header>
  <!-- Form Item Configuration Panel -->
  <div *ngIf="formItem" class="data-type-dialog__config">
    <record-form-item-properties-form
      [recordTitle]="recordTitle"
      [formItem]="formItem"
      (formItemPreviewChange)="formItemPreview = $event"
      (onSubmit)="handleFormItemPropertiesSubmit($event)"
      (onCancel)="cancel()"
    ></record-form-item-properties-form>
  </div>
  <!-- Form Item Preview Panel -->
  <aside class="data-type-dialog__preview">
    <div class="flex flex-column gap-3 h-full">
      <form [formGroup]="previewForm" *ngIf="formItem; else formItemEmptyMessage">
        <record-form-item-preview
          *ngIf="formItemPreview"
          formGroupName="preview"
          [formItem]="formItemPreview"
        ></record-form-item-preview>
      </form>
      <ng-template #formItemEmptyMessage>
        <div class="flex flex-column flex-grow-1 pt-8 align-items-center text-center">
          <i class="pi pi-arrow-left text-primary text-2xl"></i>
          <h2 class="h4 mb-0 mt-1">
            {{ "MASTERDATA.data-types.selection.empty.title" | translate : { "default": "Insert anything" } }}
          </h2>
          <p class="text-color-secondary mb-0 mt-1">
            {{
              "MASTERDATA.data-types.selection.empty.description"
                | translate : { "default": "Search for any item and insert it into your message." }
            }}
          </p>
        </div>
      </ng-template>
    </div>
  </aside>
</div>
