import { Injectable } from "@angular/core";
import { ContactOrderEnum } from "@app/@shared/enums/contact-order.enum";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import ContactsResultModel from "@app/@shared/models/contacts/search-contacts-result.model";
import { AddressBookAPIService } from "@app/@shared/services/address-book-api.service";
import { Logger } from "@core";
import { BehaviorSubject, combineLatest, Observable, Subscription, switchMap, tap } from "rxjs";

const log = new Logger("ContactsService");

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  DEFAULT_LIMIT = -1;

  // contacts observable
  private _contacts$: BehaviorSubject<ContactModel[]> = new BehaviorSubject([]);
  contacts$: Observable<ContactModel[]> = this._contacts$.asObservable();

  get contacts(): ContactModel[] {
    return this._contacts$.getValue();
  }
  set contacts(contacts: ContactModel[]) {
    this._contacts$.next(contacts);
  }

  // searchText observable
  private _searchText$: BehaviorSubject<string> = new BehaviorSubject(null);
  searchText$: Observable<string> = this._searchText$.asObservable();

  get searchText(): string {
    return this._searchText$.getValue();
  }
  set searchText(searchText: string) {
    this._searchText$.next(searchText);
  }

  // tags observable
  private _tags$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  tags$: Observable<string[]> = this._tags$.asObservable();

  get tags(): string[] {
    return this._tags$.getValue();
  }
  set tags(tags: string[]) {
    this._tags$.next(tags);
  }
  // order observable
  private _order$: BehaviorSubject<ContactOrderEnum> = new BehaviorSubject(null);
  order$: Observable<ContactOrderEnum> = this._order$.asObservable();

  get order(): ContactOrderEnum {
    return this._order$.getValue();
  }
  set order(order: ContactOrderEnum) {
    this._order$.next(order);
  }

  // scope observable
  private _scope$: BehaviorSubject<ContactScopeEnum> = new BehaviorSubject(ContactScopeEnum.USER);
  scope$: Observable<ContactScopeEnum> = this._scope$.asObservable();

  get scope(): ContactScopeEnum {
    return this._scope$.getValue();
  }
  set scope(scope: ContactScopeEnum) {
    this._scope$.next(scope);
  }

  // offset observable
  private _offset$: BehaviorSubject<number> = new BehaviorSubject(0);
  offset$: Observable<number> = this._offset$.asObservable();

  get offset(): number {
    return this._offset$.getValue();
  }
  set offset(offset: number) {
    this._offset$.next(offset);
  }

  // limit observable
  private _limit$: BehaviorSubject<number> = new BehaviorSubject(this.DEFAULT_LIMIT);
  limit$: Observable<number> = this._limit$.asObservable();

  get limit(): number {
    return this._limit$.getValue();
  }
  set limit(limit: number) {
    this._limit$.next(limit);
  }

  // total observable
  private _total$: BehaviorSubject<number> = new BehaviorSubject(0);
  total$: Observable<number> = this._total$.asObservable();

  get total(): number {
    return this._total$.getValue();
  }

  // totalPages observable
  private _totalPages$: BehaviorSubject<number> = new BehaviorSubject(0);
  totalPages$: Observable<number> = this._totalPages$.asObservable();

  get totalPages(): number {
    return this._totalPages$.getValue();
  }

  // isLoading observable
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();

  // isSaving observable
  private _isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSaving$ = this._isSaving$.asObservable();

  // isDeleting observable
  private _isDeleting$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isDeleting$ = this._isDeleting$.asObservable();

  private subscription: Subscription;

  constructor(private addressBookAPIService: AddressBookAPIService) {}

  initialize(
    offset: number = 0,
    limit: number = this.DEFAULT_LIMIT,
    scope: ContactScopeEnum = ContactScopeEnum.USER,
    order: ContactOrderEnum = null,
    searchText: string = null,
    tags: string[] = null,
  ) {
    if (this.subscription) this.subscription.unsubscribe();

    if (offset !== this.offset) this._offset$.next(offset);
    if (limit !== this.limit) this._limit$.next(limit);
    if (scope !== this.scope) this._scope$.next(scope);
    if (order !== this.order) this._order$.next(order);
    if (searchText !== this.searchText) this._searchText$.next(searchText);
    if (tags !== this.tags) this._tags$.next(tags);

    this.subscription = combineLatest([
      this.offset$,
      this.limit$,
      this.scope$,
      this.order$,
      this.searchText$,
      this.tags$,
    ])
      .pipe(switchMap(() => this.searchContacts([ResponseLevelEnum.ALL])))
      .subscribe((contacts: ContactModel[]) => {});
  }

  searchContacts(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE]): Observable<ContactModel[]> {
    this._isLoading$.next(true);

    return this.addressBookAPIService
      .searchContacts(
        { searchText: this.searchText, scope: this.scope, orderBy: this.order, tags: this.tags },
        this.offset,
        this.limit,
        responseLevels,
      )
      .pipe(
        tap((result: ContactsResultModel) => {
          this.updatePageInfo(result);
          this._contacts$.next(result.contacts);
        }),
        tap(() => this._isLoading$.next(false)),
        switchMap(() => this.contacts$),
      );
  }

  saveContacts(
    params: {
      contacts: ContactModel[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ContactModel[]> {
    this._isSaving$.next(true);

    return this.addressBookAPIService.saveContacts(params, responseLevels).pipe(
      tap(() => this._isSaving$.next(false)),
      switchMap(() => this.resetPage(true)),
    );
  }

  deleteContacts(
    params: {
      contacts: ContactModel[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ContactModel[]> {
    this._isDeleting$.next(true);

    return this.addressBookAPIService.deleteContacts(params, responseLevels).pipe(
      tap(() => this._isDeleting$.next(false)),
      switchMap(() => this.resetPage(true)),
    );
  }

  /**
   * Pagination
   */
  updatePageInfo(result: ContactsResultModel) {
    this._total$.next(result.totalCount);

    const pages = this.limit <= 0 ? 0 : Math.ceil(result.totalCount / this.limit);
    this._totalPages$.next(pages);
  }

  nextPage(): Observable<ContactModel[]> {
    if (this.offset + this.limit < this.total) {
      this._offset$.next(this.offset + this.limit);
    }

    return this.contacts$;
  }

  prevPage(): Observable<ContactModel[]> {
    if (this.offset != 0) {
      if (this.offset - this.limit < 0) {
        this.resetPage();
      } else {
        this._offset$.next(this.offset - this.limit);
      }
    }

    return this.contacts$;
  }

  resetPage(force: boolean = false): Observable<ContactModel[]> {
    if (this.offset > 0 || force) {
      this._offset$.next(0);
    }

    return this.contacts$;
  }
}
