import { MessageAnswerTypeEnum } from "@app/@shared/enums/message-answer-type.enum";
import BaseModel from "../base.model";
import RecordMessageAnswerContentModel from "./record-message-answer-content.model";
import UserModel from "../user/user.model";
import RecordMessageAttachmentModel from "./record-message-attachment.model";
import RecordMessageFormItemModel from "./record-message-form-item.model";

export default class RecordMessageAnswerModel extends BaseModel {
  public answerIdentifier: string | null = null;
  public type: MessageAnswerTypeEnum | null = null;
  public content: RecordMessageAnswerContentModel;
  public isValid: boolean | null = null;
  public validationMessage: string | null = null;
  public validationAt: Date | null;
  public validatedBy: UserModel | null = null;
  public itemIdentifier: string;
  public createdAt: Date | null;
  public createdBy: UserModel | null;

  public messageIdentifier: string | null = null;
  public recordIdentifier: string | null = null;

  // RECORD_ANSWER_ATTACHMENTS
  public attachments?: RecordMessageAttachmentModel[] = [];

  // RECORD_ANSWER_FORM_ITEM
  public formItem?: RecordMessageFormItemModel = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.content) this.content = new RecordMessageAnswerContentModel().deserialize(input.content);
    if (input.validatedBy) this.validatedBy = new UserModel().deserialize(input.validatedBy);
    if (input.createdBy) this.createdBy = new UserModel().deserialize(input.createdBy);
    if (input.formItem) this.formItem = new RecordMessageFormItemModel().deserialize(input.formItem);

    if (input.attachments && Array.isArray(input.attachments))
      this.attachments = input.attachments.map((attachment: any) =>
        new RecordMessageAttachmentModel().deserialize(attachment),
      );

    return this;
  }

  /**
   * Type related getters
   */
  get isFormAnswer() {
    return this.type === MessageAnswerTypeEnum.FORM;
  }

  get isSignatureAnswer() {
    return this.type === MessageAnswerTypeEnum.SIGNATURE;
  }

  get isInvitationAnswer() {
    return this.type === MessageAnswerTypeEnum.INVITATION;
  }
}
