import BaseModel from "../base.model";

export default class InboxTagModel extends BaseModel {
  public label: string;
  public count: number;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
