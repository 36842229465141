import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "@env/environment";
import { Logger } from "@core";
import * as Sentry from "@sentry/angular-ivy";
import { KeycloakProfile } from "keycloak-js";
import { KeycloakService } from "keycloak-angular";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { Title } from "@angular/platform-browser";
import { BehaviorSubject, Observable, filter, merge, throwError } from "rxjs";
import { catchError, map, mergeMap, startWith, switchMap, tap } from "rxjs/operators";
import { I18nService } from "./@shared/i18n/i18n.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { BrowserTracing } from "@sentry/tracing";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import "quill-mention";
import "quill-paste-smart";
import { default as FormItemBlot } from "@app/@shared/plugins/form-item-blot";
import { setTheme } from "ngx-bootstrap/utils";
import { LocalStorageService } from "./@shared/services/local-storage.service";
import { LocalStorageKeysEnum } from "./@shared/enums/local-storage-keys.enum";
import { ErrorCodeEnum } from "./@shared/enums/error-code.enum";
import { ThemingService } from "./@shared/services/theming.service";
import UnitModel from "./@shared/models/domain/unit.model";
import { Crisp } from "crisp-sdk-web";
import { KeycloakInitService } from "./@shared/services/keycloak-init.service";
import { NavigationBarVariantEnum } from "./@shared/enums/navigation-bar-variant.enum";
import { MatomoTracker } from "ngx-matomo-client";

const log = new Logger("AppComponent");

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("rootContainer", { static: true }) rootContainerRef: ElementRef | string = "body";
  // PWA logic
  // isOnline: boolean;
  // modalVersion: boolean;
  // modalPwaEvent: any;
  // modalPwaPlatform: string | undefined;

  displayNavbar$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoggedIn = false;
  // isInitialized = false;
  userProfile: KeycloakProfile | null = null;
  showDisclaimer: boolean = environment.disclaimer;
  showSidebarMenu = false;
  title: string = "";

  changeSidebarMenuVisibility(isVisible: boolean) {
    this.showSidebarMenu = isVisible;
  }

  constructor(
    private readonly keycloak: KeycloakService,
    private keycloakInitService: KeycloakInitService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private titleService: Title,
    private i18nService: I18nService,
    public sessionAPIService: SessionAPIService,
    public themingService: ThemingService,
    private readonly tracker: MatomoTracker,
  ) {
    setTheme("bs4"); // Set theme to BS4 for tooltips

    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data) {
              let { title, hideBottomBar, navigationBarVariant } = child.snapshot.data;
              return { title, hideBottomBar, navigationBarVariant };
            } else {
              return null;
            }
          }
          return null;
        }),
        switchMap(({ title, hideBottomBar, navigationBarVariant }) => {
          return this.sessionAPIService.unit$.pipe(
            filter((unit: UnitModel) => Boolean(unit)),
            map((unit: UnitModel) => {
              // this.enableLiveChat(!unit.hideSupport);
              return { title, unit, hideBottomBar, navigationBarVariant };
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe(({ title, unit, hideBottomBar, navigationBarVariant }) => {
        if (title) {
          this.title = unit.title
            ? this.translateService.instant(title) + " - " + unit.title
            : this.translateService.instant(title);
          this.titleService.setTitle(this.title);
        }
        this.themingService.setBottomBarVisibility(Boolean(hideBottomBar));
        this.themingService.setNavigationBarVariant(navigationBarVariant ?? NavigationBarVariantEnum.PRIMARY);
      });
  }

  async ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug("Application initialization");

    // Register Quill modules
    Quill.register("modules/magicUrl", MagicUrl);
    Quill.register(FormItemBlot);

    // this.enablePwa();
    // this.enableLiveChat();

    // Make spinner depend on sessionApiService isLoaded
    this.sessionAPIService.isLoaded$.subscribe(this.isLoaded$);

    // Check oAuth
    this.keycloakInitService.hasKeycloakInitError$
      .pipe(filter((hasError) => hasError !== undefined))
      .subscribe(async (hasError: boolean) => {
        if (hasError) {
          this.isLoaded$.next(true);
        } else {
          this.isLoggedIn = await this.keycloak.isLoggedIn();
          if (this.isLoggedIn) {
            this.userProfile = await this.keycloak.loadUserProfile();
            this.enableUserTracking(this.userProfile);

            this.loadSessionInfo();
          } else {
            this.keycloak.login();
          }
        }
      });
  }

  ngAfterViewInit() {
    this.themingService.rootContainerRef = this.rootContainerRef;
  }
  loadSessionInfo() {
    // Subscribe to selectedUnitSlug$ to swithc unit when slug change
    this.sessionAPIService.selectedUnitSlug$
      .pipe(
        filter((selectedUnitSlug: string) => Boolean(selectedUnitSlug)),
        switchMap((selectedUnitSlug: string) =>
          this.sessionAPIService.loadSessionInfo(selectedUnitSlug, [ResponseLevelEnum.ALL]),
        ),
        catchError((error) => {
          if (
            error?.error?.code &&
            [ErrorCodeEnum.PERSISTANCE_ID_NOT_FOUND, ErrorCodeEnum.SECURITY_NOT_ALLOWED].includes(error?.error?.code)
          ) {
            this.localStorageService.clear(LocalStorageKeysEnum.CURRENT_UNIT);
            return this.sessionAPIService.loadSessionInfo(environment.defaultUnit, [ResponseLevelEnum.ALL]);
          }

          return throwError(() => error);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {});

    let currentUnit = this.localStorageService.getCurrentUnit(this.userProfile.id) || environment.defaultUnit;
    this.sessionAPIService.selectedUnitSlug = currentUnit;
  }

  /*
    For now, we don't make use of updating application version
    Someday, the UI will include hints on how to add web app to home screen
    and a dialog when a new version of the app is available
  */
  // private enablePwa() {
  //   this.updateOnlineStatus();

  //   window.addEventListener("online", this.updateOnlineStatus.bind(this));
  //   window.addEventListener("offline", this.updateOnlineStatus.bind(this));

  //   if (this.swUpdate.isEnabled) {
  //     this.swUpdate.versionUpdates.pipe(
  //       filter((evt: any): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
  //       map((evt: any) => {
  //         log.info(`currentVersion= [${evt.currentVersion} | latestVersion= [${evt.latestVersion}]`);
  //         this.modalVersion = true;
  //       }),
  //     );
  //   }

  //   this.loadModalPwa();
  // }

  // private updateOnlineStatus(): void {
  //   this.isOnline = window.navigator.onLine;
  //   console.info(`isOnline=[${this.isOnline}]`);
  // }

  // updateVersion(): void {
  //   this.modalVersion = false;
  //   window.location.reload();
  // }

  // closeVersion(): void {
  //   this.modalVersion = false;
  // }

  // private loadModalPwa(): void {
  //   if (this.platform.ANDROID) {
  //     window.addEventListener("beforeinstallprompt", (event: any) => {
  //       event.preventDefault();
  //       this.modalPwaEvent = event;
  //       this.modalPwaPlatform = "ANDROID";
  //     });
  //   }

  //   if (this.platform.IOS && this.platform.SAFARI) {
  //     const isInStandaloneMode = "standalone" in window.navigator && (<any>window.navigator)["standalone"];
  //     if (!isInStandaloneMode) {
  //       this.modalPwaPlatform = "IOS";
  //     }
  //   }
  // }

  // addToHomeScreen(): void {
  //   this.modalPwaEvent.prompt();
  //   this.modalPwaPlatform = undefined;
  // }

  // closePwa(): void {
  //   this.modalPwaPlatform = undefined;
  // }

  private enableLiveChat(enable: boolean = true) {
    Crisp.configure(environment.crisp.websiteId, { autoload: false });
    Crisp.setSafeMode(true);

    if (enable) {
      Crisp.load();
    } else {
      if (Crisp.isCrispInjected()) {
        Crisp.chat.hide();
      }
    }
  }

  enableUserTracking(profile: KeycloakProfile) {
    // We can associate user profile to tracking apps here
    // to access attributes : https://github.com/mauriciovigolo/keycloak-angular/issues/89
    if (
      environment.matomo.enabled &&
      profile["attributes"]["consent-analytics"] &&
      profile["attributes"]["consent-analytics"][0] === "true"
    ) {
      this.tracker.setUserId(profile.email);
    }

    if (environment.sentry.enabled) {
      Sentry.init({
        dsn: environment.sentry.dsn,
        integrations: [
          new BrowserTracing({
            tracingOrigins: environment.sentry.tracingOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        environment: environment.sentry.environment,
        // release: environment.sentry.release, //this is not needed anymore as the webpack plugin is inferencing this from env
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentry.tracesSampleRate,
        autoSessionTracking: true,
      });
      if (profile["attributes"]["consent-debug-logs"] && profile["attributes"]["consent-debug-logs"][0] === "true") {
        Sentry.setUser({ id: profile.id, email: profile.email });
      }
    }
  }

  ngOnDestroy() {}
}
