<div class="flex flex-column pl-1">
  <span class="text-color font-medium"> {{ contact.toString }} </span>
  <div class="flex flex-wrap text-sm text-color-secondary" style="column-gap: 0.5rem">
    <div class="flex align-items-center gap-1" *ngIf="showEmail && contact.emailAddress" data-avatar--email>
      <i class="text-sm msr-icon-mail"></i>
      <span>{{ contact.emailAddress }}</span>
    </div>
    <div class="flex align-items-center gap-1" *ngIf="showPhone && contact.phoneNumber" data-avatar--phone>
      <i class="text-sm msr-icon-smartphone"></i>
      <span>{{ contact.phoneNumber }}</span>
    </div>
  </div>
</div>
