import { Component, EventEmitter, forwardRef, Input, OnInit, Output, SkipSelf, ViewChild } from "@angular/core";
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input-gg";
import { StringHelper } from "@shared/helpers/string.helper";
import RecordMessageModel from "@shared/models/record/record-message.model";

@Component({
  selector: "ui-email-input",
  templateUrl: "./email-input.component.html",
  styleUrls: ["./email-input.component.scss"],
})
export class EmailInputComponent implements OnInit, ControlValueAccessor {
  @Input() disabled: boolean = false;

  _isValid: boolean = false;
  @Output() isValid = new EventEmitter<boolean>();

  @Input() value: string | null = null;

  constructor() {}

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  checkIsValid(event) {
    this._isValid = StringHelper.isEmail(event.target.value);
    this.isValid.emit(this._isValid);
  }
}
