export const shared = {
  services: {
    methodUrls: {
      session: {
        initSession: "/session/getSessionInfo",
        getSessionInfo: "/session/{unitSlug}/getSessionInfo",
      },
      domain: {
        getUnit: "/domain/getUnit",
        getUnitUsers: "/domain/{unitSlug}/getUnitUsers",
        updateUnitUserRole: "/domain/{unitSlug}/updateUnitUserRole",
        getUnitChildrenUnits: "/domain/{unitSlug}/getUnitChildrenUnits",
        saveUnit: "/domain/{unitSlug}/saveUnit",
        getConfiguration: "/domain/{unitSlug}/getConfiguration",
        saveUnitConfiguration: "/domain/{unitSlug}/saveUnitConfiguration",
        getList: "/domain/{unitSlug}/getList",
        saveUnitList: "/domain/{unitSlug}/saveUnitList",
      },
      unit: {
        removeUnitUser: "/units/{unitSlug}/removeUser",
      },
      masterdata: {
        getFormDataTypeList: "/masterdata/{unitSlug}/getFormDataTypeList",
        getRecordMessageTemplates: "/masterdata/{unitSlug}/getRecordMessageTemplates",
        getRecordMessageTemplate: "/masterdata/{unitSlug}/getRecordMessageTemplate",
        saveRecordMessageTemplate: "/masterdata/{unitSlug}/saveRecordMessageTemplate",
        deleteRecordMessageTemplates: "/masterdata/{unitSlug}/deleteRecordMessageTemplates",
        duplicateRecordMessageTemplate: "/masterdata/{unitSlug}/duplicateRecordMessageTemplate",
        shareRecordMessageTemplate: "/masterdata/{unitSlug}/shareRecordMessageTemplate",
      },
      manageNotification: {
        searchNotificationTemplates: "/manage-notification/{unitSlug}/searchNotificationTemplates",
        saveNotificationTemplate: "/manage-notification/{unitSlug}/saveNotificationTemplate",
      },
      notification: {
        sendTestEmail: "/notification/sendTestEmail",
      },
      user: {
        updateUser: "/user/updateUser",
        updateUserUnit: "/user/updateUserUnit",
        updateUserIdentity: "/user/updateUserIdentity",
        updateUserPassword: "/user/updateUserPassword",
        attachUserToUnit: "/user/{unitSlug}/attachUserToUnit",
        saveUserConfiguration: "/user/saveUserConfiguration",
        getUserConfiguration: "/user/getUserConfiguration",
      },
      addressBook: {
        searchContacts: "/address-book/searchContacts",
        saveContacts: "/address-book/saveContacts",
        deleteContacts: "/address-book/deleteContacts",
        getContact: "/address-book/getContact",
        getTags: "/address-book/getTags",
      },
      billing: {
        getProducts: "/billing/getProducts",
        getBillingAccount: "/billing/getBillingAccount",
        getBillingAccountUsage: "/billing/getBillingAccountUsage",
        saveBillingAccount: "/billing/saveBillingAccount",
        createSubscription: "/billing/createSubscription",
        updateSubscription: "/billing/updateSubscription",
        cancelSubscription: "/billing/cancelSubscription",
        createSetupIntent: "/billing/createSetupIntent",
        detachPaymentMethod: "/billing/detachPaymentMethod",
        getPromotionCode: "/billing/getPromotionCode",
        getCustomerInvoices: "/billing/getCustomerInvoices",
      },
      onboarding: {
        upgradeFromGuest: "/onboarding/upgradeFromGuest",
      },
      dashboard: {
        getRecords: "/dashboard/{unitSlug}/getRecords",
        getRecordCells: "/dashboard/{unitSlug}/getRecordCells",
        stats: "/dashboard/{unitSlug}/stats",
        filters: "/dashboard/{unitSlug}/filters",
        sent: "/dashboard/{unitSlug}/sent",
        closed: "/dashboard/{unitSlug}/closed",
        canceled: "/dashboard/{unitSlug}/canceled",
        markAs: "/dashboard/{unitSlug}/markAs",
      },
      inbox: {
        filters: "/inbox/filters",
        received: "/inbox/received",
        sent: "/inbox/sent",
        closed: "/inbox/closed",
        canceled: "/inbox/canceled",
        markAs: "/inbox/markAs",
      },
      record: {
        getRecord: "/record/getRecord",
        searchRecords: "/record/{unitSlug}/searchRecords",
        createRecord: "/record/{unitSlug}/createRecord",
        updateRecord: "/record/{unitSlug}/updateRecord",
        updateRecordAttributes: "/record/{unitSlug}/updateRecordAttributes",
        updateRecordsAttributes: "/record/{unitSlug}/updateRecordsAttributes",
        updateRecordStatus: "/record/{unitSlug}/updateRecordStatus",
        closeRecord: "/record/{unitSlug}/closeRecord",
        unarchiveRecord: "/record/{unitSlug}/unarchiveRecord",
        cancelRecord: "/record/{unitSlug}/cancelRecord",
        sendRecord: "/record/{unitSlug}/sendRecord",
        addRecordRecipients: "/record/{unitSlug}/addRecordRecipients",
        removeRecordRecipients: "/record/{unitSlug}/removeRecordRecipients",
        addRecordMessage: "/record/{unitSlug}/addRecordMessage",
        updateRecordMessage: "/record/{unitSlug}/updateRecordMessage",
        answerRecordMessage: "/record/{unitSlug}/answerRecordMessage",
        updateRecordMessageFormItemValidity: "/record/{unitSlug}/updateRecordMessageFormItemValidity",
        addRecordAttachment: "/record/{unitSlug}/addRecordAttachment",
        addRecordAttachments: "/record/{unitSlug}/addRecordAttachments",
        getRecordsSummary: "/record/{unitSlug}/getRecordsSummary",
        getRecordsSummaryCell: "/record/{unitSlug}/getRecordsSummaryCell",
        addRecordsRecipients: "/record/{unitSlug}/addRecordsRecipients",
        addRecordsTags: "/record/{unitSlug}/addRecordsTags",
        rejectAnswers: "/record/{unitSlug}/rejectAnswers",
        validateAnswers: "/record/{unitSlug}/validateAnswers",
      },
      recordBatch: {
        broadcastRecordMessage: "/record-batch/{unitSlug}/broadcastRecordMessage",
        createBatchChildrenRecords: "/record-batch/{unitSlug}/createBatchChildrenRecords",
        getRecordBatchSummary: "/record-batch/{unitSlug}/getRecordBatchSummary",
        closeBatchRecords: "/record-batch/{unitSlug}/closeRecords",
        cancelBatchRecords: "/record-batch/{unitSlug}/cancelRecords",
        getFormItems: "/record-batch/{unitSlug}/getFormItems",
        getAnswers: "/record-batch/{unitSlug}/getAnswers",
        rejectAnswers: "/record-batch/{unitSlug}/rejectAnswers",
        validateAnswers: "/record-batch/{unitSlug}/validateAnswers",
      },
      keyvault: {
        getRecordWrappedPrivateKeys: "/record/private/getRecordWrappedPrivateKeys",
        getRecordBatchWrappedPrivateKeys: "/record/private/getRecordBatchWrappedPrivateKeys",
        getRecordPublicKeys: "/record/public/getRecordPublicKeys",
        sendUserToken: "/user/token/sendUserToken",
      },
      files: {
        recordStartUpload: "/record/startUpload",
        recordStartUploads: "/record/startUploads",
        recordUploadChunk: "/record/uploadChunk",
        recordFinishUpload: "/record/finishUpload",
        recordDownload: "/record/files/{fileIdentifier}",
        recordCreateExport: "/record/exports",
        recordDownloadExport: "/record/exports/{exportIdentifier}",
      },
      cloud: {
        getFolderContent: "/cloud/getFolderContent",
        createFolder: "/cloud/createFolder",
        search: "/cloud/search",
        internal: {
          upload: "/cloud-internal/upload/record",
          uploadExport: "/cloud-internal/exports/{exportIdentifier}",
          download: "/cloud-internal/download/record",
        },
      },
      signature: {
        createSignature: "/signature/createSignature",
        cancelSignature: "/signature/cancelSignature",
        declineSignature: "/signature/declineSignature",
        getRecipientSignature: "/signature/getRecipientSignature",
        startRecipientSignature: "/signature/startRecipientSignature",
        validateRecipientSignature: "/signature/validateRecipientSignature",
      },
      notifications: {
        initializeSession: "/notifications/initializeSession",
        getUnseenNotificationsCount: "/notifications/getUnseenNotificationsCount",
        getUnreadNotificationsCount: "/notifications/getUnreadNotificationsCount",
        getNotificationsFeed: "/notifications/getNotificationsFeed",
        markNotificationsAs: "/notifications/markNotificationsAs",
      },
      exports: {
        exportConversationAnswers: "/v1/conversations/{conversationIdentifier}/answers",
        exportCampaignAnswers: "/v1/campaigns/{campaignIdentifier}/answers",
        exportCampaignAttachments: "/v1/campaigns/{campaignIdentifier}/attachments",
        getConversationExports: "/v1/conversations/{conversationIdentifier}",
        getCampaignExports: "/v1/campaigns/{campaignIdentifier}",
        getExport: "/v1/{exportIdentifier}",
        deleteExport: "/v1/{exportIdentifier}",
        downloadExport: "/v1/{exportIdentifier}/download",
      },
      microsoftAad: {
        getAuthUrl: "/providers/microsoft/getAuthUrl",
        callback: "/providers/microsoft/callback",
      },
      providers: {
        applications: {
          getApplication: "/providers/applications/get",
          listApplications: "/providers/applications/list",
          revokeApplication: "/providers/applications/revoke",
        },
        contacts: {
          getContact: "/providers/contacts/get",
          getContactFolder: "/providers/contacts/getFolder",
          listContacts: "/providers/contacts/list",
          listContactsFolders: "/providers/contacts/listFolders",
        },
        files: {
          get: "/providers/files/get",
          list: "/providers/files/list",
          listRoots: "/providers/files/listRoots",
          createFolder: "/providers/files/createFolder",
          retrieve: "/providers/files/retrieve",
          search: "/providers/files/search",
          upload: "/providers/files/upload",
        },
      },
    },
  },
};
