export enum ProviderItemTypeEnum {
  /*
      NESTOR_DRIVE
  */
  NESTOR_DRIVE_ITEM = "nestor_drive_item",

  /*
      NEXTCLOUD
  */
  NEXTCLOUD_ITEM = "nextcloud_item",

  /*
      MICROSOFT
  */
  MICROSOFT_DRIVE = "microsoft_drive",
  MICROSOFT_DRIVE_ITEM = "microsoft_drive_item",
  MICROSOFT_SHAREPOINT_SITE = "microsoft_sharepoint_site",
}
