import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import RecordModel from "@shared/models/record/record.model";
import { RecordStatusEnum } from "@shared/enums/record-status.enum";
import RecordMessageModel from "@shared/models/record/record-message.model";
import AnswerRecordMessageRequestModel from "@app/@shared/models/record/answer-record-message-request.model";
import CreateRecordModel from "@shared/models/record/create-record.model";
import RecordMessageAttachmentModel from "@shared/models/record/record-message-attachment.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import RecordRecipientModel from "../models/record/record-recipient.model";
import { RecordTypeEnum } from "../enums/record-type.enum";
import RecordsResultModel from "../models/record/records-result.model";
import RecordReminderPropertiesModel from "../models/record/record-reminder-properties.model";
import { ErrorCodeEnum } from "../enums/error-code.enum";

@Injectable({
  providedIn: "root",
})
export class RecordAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private sessionAPIService: SessionAPIService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getRecord(
    recordIdentifier: string,
    triggerEvents: boolean = false,
    triggerRead: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url = this._baseUrl + environment.services.methodUrls.record.getRecord;
    const correlationId = v4();

    this.loaderService.addOperation("getRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          triggerEvents,
          triggerRead,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e: HttpErrorResponse) => {
          if (e.status === 403 && e.error?.code === ErrorCodeEnum.RECORD_EXPIRED) {
          } else {
            this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          }
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecord");
        }),
      );
  }

  searchRecords(
    params: {
      recordType?: RecordTypeEnum;
      recordTypes?: RecordTypeEnum[];
      recordProperties?: { [x: string]: any };
      searchText?: string;
      tags?: string[];
      parentIdentifier?: string;
      recipients?: RecordRecipientModel[];
      isClosed?: boolean;
      createdBegin?: Date;
      createdEnd?: Date;
      updatedBegin?: Date;
      updatedEnd?: Date;
      dueDateBegin?: Date;
      dueDateEnd?: Date;
    },
    orderBy: string,
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordsResultModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.searchRecords.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("searchRecords");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset,
            limitCount,
          },
          ...params,
          orderBy,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result = new RecordsResultModel();
          result.records = response["records"].map((record: any) => new RecordModel().deserialize(record));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("searchRecords");
        }),
      );
  }

  createRecord(
    record: CreateRecordModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.createRecord.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("createRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...record.toJson(),
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("createRecord");
        }),
      );
  }

  updateRecord(
    record: RecordModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecord.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          record: record.toJson(),
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecord");
        }),
      );
  }

  updateRecordAttributes(
    recordIdentifier: string,
    params: {
      title?: string;
      expirationDate?: Date;
      locale?: string;
      reminderProperties?: RecordReminderPropertiesModel;
      tags?: string[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordAttributes.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordAttributes");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordAttributes");
        }),
      );
  }

  updateRecordsAttributes(
    recordIdentifiers: string[] = [],
    params: {
      expirationDate?: Date;
      locale?: string;
      reminderProperties?: RecordReminderPropertiesModel;
      tags?: string[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<string[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordsAttributes.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordsAttributes");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifiers,
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["records"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordsAttributes");
        }),
      );
  }

  /*
    DEPRECATED
    Use sendRecord, closeRecord, cancelRecord instead
  */
  updateRecordStatus(
    recordIdentifier: string,
    status: RecordStatusEnum,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordStatus.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordStatus");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          status,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordStatus");
        }),
      );
  }

  closeRecord(
    recordIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.closeRecord.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("closeRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("closeRecord");
        }),
      );
  }

  unarchiveRecord(
    recordIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.unarchiveRecord.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("unarchiveRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("unarchiveRecord");
        }),
      );
  }

  cancelRecord(
    recordIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.cancelRecord.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("cancelRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("cancelRecord");
        }),
      );
  }

  sendRecord(
    recordIdentifier: string,
    triggerEmails: boolean = true,
    triggerNotifications: boolean = true,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.sendRecord.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("sendRecord");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          triggerEmails,
          triggerNotifications,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordModel().deserialize(response["record"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("sendRecord");
        }),
      );
  }

  addRecordRecipients(
    recordIdentifier: string,
    recipients: RecordRecipientModel[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordRecipientModel[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordRecipients.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordRecipients");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          recipients,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["recipients"].map((recipient: any) => new RecordRecipientModel().deserialize(recipient));
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordRecipients");
        }),
      );
  }

  removeRecordRecipients(
    recordIdentifier: string,
    recipients: RecordRecipientModel[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.removeRecordRecipients.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("removeRecordRecipients");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          recipients,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("removeRecordRecipients");
        }),
      );
  }

  addRecordMessage(
    recordIdentifier: string,
    parentMessageIdentifier: string,
    message: RecordMessageModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordMessage.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordMessage");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          parentMessageIdentifier: parentMessageIdentifier,
          message: message,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordMessage");
        }),
      );
  }

  updatedRecordMessage(
    recordIdentifier: string,
    message: RecordMessageModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordMessage.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordMessage");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          message: message,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordMessage");
        }),
      );
  }

  answerRecordMessage(
    answer: AnswerRecordMessageRequestModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.answerRecordMessage.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("answerRecordMessage");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...answer,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("answerRecordMessage");
        }),
      );
  }

  updateRecordMessageFormItemValidity(
    recordIdentifier: string,
    messageIdentifier: string,
    itemIdentifier?: string,
    itemIdentifiers?: string[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.updateRecordMessageFormItemValidity.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("updateRecordMessageFormItemValidity");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          messageIdentifier,
          itemIdentifier,
          itemIdentifiers,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateRecordMessageFormItemValidity");
        }),
      );
  }

  addRecordAttachment(
    recordIdentifier: string,
    messageIdentifier: string,
    attachment: RecordMessageAttachmentModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordAttachment.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordAttachment");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          messageIdentifier: messageIdentifier,
          attachment: attachment,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordAttachment");
        }),
      );
  }

  addRecordAttachments(
    recordIdentifier: string,
    messageIdentifier: string,
    attachments: RecordMessageAttachmentModel[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageAttachmentModel[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordAttachments.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordAttachments");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          messageIdentifier: messageIdentifier,
          attachments,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["attachments"].map((a) => new RecordMessageAttachmentModel().deserialize(a));
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordAttachments");
        }),
      );
  }

  addRecordsRecipients(
    recordIdentifiers: string[],
    recipients: RecordRecipientModel[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<string[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordsRecipients.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordsRecipients");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifiers,
          recipients,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["recordIdentifiers"].map((recordIdentifier: any) => recordIdentifier);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordsRecipients");
        }),
      );
  }

  addRecordsTags(
    recordIdentifiers: string[],
    tags: string[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<string[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.addRecordsTags.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("addRecordsTags");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifiers,
          tags,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["recordIdentifiers"].map((recordIdentifier: any) => recordIdentifier);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("addRecordsTags");
        }),
      );
  }

  rejectAnswers(
    answerIdentifier: string,
    answerIdentifiers: string[],
    messageIdentifier: string,
    recordIdentifier: string,
    validationMessage: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.rejectAnswers.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("rejectAnswers");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          answerIdentifier,
          answerIdentifiers,
          messageIdentifier,
          recordIdentifier,
          validationMessage,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("rejectAnswers");
        }),
      );
  }

  validateAnswers(
    answerIdentifier: string,
    answerIdentifiers: string[],
    messageIdentifier: string,
    recordIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.record.validateAnswers.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );
    const correlationId = v4();

    this.loaderService.addOperation("validateAnswers");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          answerIdentifier,
          answerIdentifiers,
          messageIdentifier,
          recordIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageModel().deserialize(response["message"]);
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("validateAnswers");
        }),
      );
  }
}
