import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { ChannelEnum } from "@app/@shared/enums/channel.enum";
import BaseModel from "../base.model";
import { isEmpty } from "lodash";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import { PhoneHelper } from "@app/@shared/helpers/phone.helper";
import RecordRecipientModel from "../record/record-recipient.model";

export default class ContactModel extends BaseModel {
  // MINIMIZE (default)
  public contactIdentifier: string | null = null;
  public scope?: ContactScopeEnum;
  public firstName?: string | null = null;
  public lastName?: string | null = null;
  public companyName?: string | null = null;
  public emailAddress?: string | null = null;
  public phoneNumber?: string | null = null;
  public defaultChannel?: ChannelEnum = ChannelEnum.EMAIL;
  public favorite?: boolean;

  // ADDRESS_BOOK_DETAILS
  public tags: string[] = [];

  constructor() {
    super();
  }

  get fullName() {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].join(" ");
    } else {
      return null;
    }
  }

  get toString() {
    return this.fullName || this.emailAddress || this.phoneNumber;
  }

  isValid(namesRequired: boolean = false, phoneRequired: boolean = false) {
    let valid = StringHelper.isEmail(this.emailAddress);

    if (namesRequired) {
      valid = valid && !isEmpty(this.firstName) && !isEmpty(this.lastName);
    }

    if (!isEmpty(this.phoneNumber) || phoneRequired) {
      valid = valid && PhoneHelper.isValidPhoneNumber(this.phoneNumber);
    }

    return valid;
  }

  static fromRecordRecipient(recipient: RecordRecipientModel): ContactModel {
    let contact = new ContactModel();
    contact.firstName = recipient.firstName?.trim();
    contact.lastName = recipient.lastName?.trim();
    contact.emailAddress = recipient.emailAddress;
    contact.phoneNumber = recipient.phoneNumber;
    return contact;
  }
}
