<ngx-intl-tel-input
  [cssClass]="
    'w-full phone-input ' +
    (parentFormGroup.get(formItem.uniqueId)?.invalid && parentFormGroup.get(formItem.uniqueId)?.touched
      ? 'ng-invalid ng-dirty'
      : '')
  "
  [enablePlaceholder]="true"
  [customPlaceholder]="placeholder"
  [searchCountryFlag]="false"
  [selectedCountryISO]="selectedCountryISO"
  [phoneValidation]="true"
  [separateDialCode]="separateDialCode"
  [numberFormat]="PhoneNumberFormat.National"
  [inputId]="inputId"
  [title]="title"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
>
</ngx-intl-tel-input>

<small
  [hidden]="parentFormGroup.get(formItem.uniqueId)?.valid || parentFormGroup.get(formItem.uniqueId)?.untouched"
  class="p-error"
>
  <span *ngIf="parentFormGroup.get(formItem.uniqueId)?.errors?.validatePhoneNumber">{{
    "COMMON.fields.phone-number.errors.validatePhoneNumber" | translate: { "default": "Phone number is invalid" }
  }}</span>
</small>

<!-- <input
  pInputText
  [name]="name"
  [id]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
/> -->

<!-- <small [hidden]="parentFormGroup.get('answer')?.valid || parentFormGroup.get('answer')?.untouched" class="p-error">
  <span *ngIf="parentFormGroup.get('answer')?.errors?.invalid">{{
    "COMMON.fields.phone-number.errors.invalid" | translate: { "default": "Phone number is invalid" }
  }}</span>
</small> -->
