import { Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import DomainModel from "@app/@shared/models/domain/domain.model";
import SessionModel from "@app/@shared/models/session/session.model";
import { BehaviorSubject, filter, Observable, tap } from "rxjs";
import { SessionAPIService } from "../session-api.service";

const log = new Logger("DomainPropertiesService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class DomainPropertiesService {
  // Initialized observable
  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  initialized$: Observable<boolean> = this._initialized$.asObservable();

  get initialized() {
    return this._initialized$.getValue();
  }

  // Domain observable
  private _domain$: BehaviorSubject<DomainModel> = new BehaviorSubject(undefined);
  domain$: Observable<DomainModel> = this._domain$.asObservable();

  get domain() {
    return this._domain$.getValue();
  }

  constructor(private sessionAPIService: SessionAPIService) {
    // Initialize watching session to set _currentUserUnit$ value
    this.sessionAPIService.session$
      .pipe(
        filter((session: SessionModel) => Boolean(session)),
        tap((session: SessionModel) => this._domain$.next(session.domain)),
        untilDestroyed(this),
      )
      .subscribe((session: SessionModel) => {
        this._initialized$.next(true);
      });
  }

  convertConversationAnswerFilesToPDF() {
    return this.domain && this.domain.convertConversationAnswerFilesToPDF;
  }
}
