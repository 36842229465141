<!-- Handling Signature Flow Rendering -->
<ng-container *ngIf="!isLoadingConfiguration; else loadingTemplate" [ngSwitch]="recipientSignature?.provider">
  <ng-container *ngSwitchCase="SignatureProviderEnum.YOUSIGN" [ngTemplateOutlet]="apiVersionTwo"></ng-container>
  <ng-container *ngSwitchCase="SignatureProviderEnum.YOUSIGN_V3" [ngTemplateOutlet]="apiVersionThree"></ng-container>
  <ng-container *ngSwitchDefault [ngTemplateOutlet]="apiVersionThree"></ng-container>
</ng-container>

<!-- YOUSIGN Version TWO FLOW -->
<ng-template #apiVersionTwo>
  <div class="signature h-full" *ngIf="message && message.attachments">
    <header class="signature-header w-full fixed z-3 top-0 py-2" style="min-height: var(--header-height)">
      <div class="container padded-container measured-container w-full">
        <!-- Signature info -->
        <div class="start flex gap-3 align-items-center">
          <button
            class="p-button-rounded p-button-sm p-button-outlined flex-shrink-0 text-color p-1"
            (click)="onAbortSignature.emit()"
            data-button="noLabel"
            icon="pi pi-arrow-left"
            pButton
            style="--p-button-width: 2rem; --p-button-height: 2rem"
            type="button"
          ></button>
          <div>
            <h2 class="text-base font-normal my-0 clamp">
              {{ "LAYOUTS.record-view.signature-action.title" | translate : { "default": "Documents signature" } }}
              <span class="text-sm md:hidden text-color-secondary">{{
                "COMPONENTS.pdf-viewer.files-indicator"
                  | translate
                    : {
                        "default": "File " + currentAttachmentReadableIndex + " of " + attachments.length,
                        "currentFile": currentAttachmentReadableIndex,
                        "totalFiles": attachments.length
                      }
              }}</span>
            </h2>
            <span
              class="flex font-medium gap-1 align-items-center"
              style="--p-button-width: 1rem; --p-button-height: 1rem"
            >
              <p-button
                styleClass="p-button-link p-button-sm p-0 text-left w-full"
                (click)="attachmentsMenu.toggle($event)"
              >
                <ng-template pTemplate="content">
                  <span class="clamp">{{ currentAttachment.attachment.file.name }}</span>
                  <i class="pi pi-chevron-circle-down ml-1"></i>
                </ng-template>
              </p-button>
            </span>
          </div>
        </div>
        <!-- Zoom actions -->
        <div class="center hidden md:block">
          <ng-container *ngTemplateOutlet="zoomActionsTemplate"> </ng-container>
        </div>
        <div class="end">
          <!-- Decline signature -->
          <ng-container>
            <button
              pButton
              class="p-button-sm text-red-500 p-button-outlined hidden sm:flex"
              type="button"
              iconPos="right"
              [label]="'COMMON.buttons.decline-signature' | translate"
              [disabled]="isPerformingStartSignature"
              [loading]="isPerformingDeclineSignature"
              (click)="promptDecline()"
            ></button>
            <button
              pButton
              data-button="noLabel"
              class="p-button-sm p-1 p-button-text sm:hidden"
              icon="pi pi-ellipsis-v"
              style="--p-button-width: 2rem; --p-button-height: 2rem"
              (click)="actionsMenu.toggle($event)"
            ></button>
          </ng-container>
        </div>
      </div>
    </header>

    <!-- File viewer -->
    <main>
      <main-pdf-viewer
        [record]="record"
        [attachment]="currentAttachment.attachment"
        (allPagesRendered)="handleAllPagesRendered($event)"
        [zoom]="zoom"
        [pdfLoaded]="pdfLoaded"
        [totalPages]="totalPages"
        (onPDFLoadedChange)="pdfLoaded = $event"
        (onTotalPagesChange)="totalPages = $event"
      ></main-pdf-viewer>
    </main>
    <footer class="shadow-2 w-full py-2">
      <!-- Signature actions -->
      <div class="container measured-container padded-container w-full">
        <div class="start">
          <div class="md:hidden">
            <ng-container *ngTemplateOutlet="zoomActionsTemplate"> </ng-container>
          </div>
          <!-- Abort signature flow -->
          <button
            pButton
            class="start p-button-sm p-button-text text-color hidden md:flex"
            type="button"
            [label]="'COMMON.buttons.cancel' | translate : { 'default': 'Cancel' }"
            icon="pi pi-chevron-left"
            (click)="onAbortSignature.emit()"
          ></button>
        </div>
        <!-- Page actions -->
        <div class="center hidden md:flex flex-row gap-2 align-items-center justify-content-center">
          <ng-container *ngIf="attachments.length > 1">
            <button
              pButton
              class="p-button-sm p-button-outlined p-button-secondary p-1"
              [label]="'COMMON.buttons.previous' | translate : { 'default': 'Previous' }"
              [disabled]="!enablePreviousAction"
              (click)="goToPrevious()"
            ></button>
            <span class="text-sm text-color-secondary">{{
              "COMPONENTS.pdf-viewer.files-indicator"
                | translate
                  : {
                      "default": "File " + currentAttachmentReadableIndex + " of " + attachments.length,
                      "currentFile": currentAttachmentReadableIndex,
                      "totalFiles": attachments.length
                    }
            }}</span>
            <!-- Next File button -->
            <!-- Shows when user reached the end of all attachments -->
            <button
              pButton
              type="button"
              class="p-button-sm p-button-outlined p-button-secondary p-1"
              [label]="'COMMON.buttons.next' | translate : { 'default': 'Next' }"
              [disabled]="!enableNextAction"
              (click)="goToNext()"
            ></button>
          </ng-container>
        </div>
        <!-- Navigation actions: next and sign -->
        <div class="end flex gap-2 align-items-center">
          <!-- Sign signature action -->
          <button
            pButton
            class="p-button-sm"
            type="button"
            [label]="'COMMON.buttons.sign' | translate : { 'default': 'Sign document' }"
            [disabled]="isPerformingDeclineSignature"
            (click)="doSign()"
            [loading]="isPerformingStartSignature"
          ></button>
        </div>
      </div>
    </footer>
  </div>
</ng-template>

<!-- YOUSIGN Version THREE FLOW -->
<ng-template #apiVersionThree>
  <header
    class="fixed w-full z-3 top-0 surface-card border-bottom-1 flex align-items-center surface-border"
    style="height: var(--header-height)"
  >
    <div class="container padded-container measured-container w-full">
      <!-- Signature info -->
      <div class="start flex gap-3 align-items-center">
        <button
          class="p-button-rounded p-button-sm p-button-outlined flex-shrink-0 text-color p-1"
          (click)="onAbortSignature.emit()"
          data-button="noLabel"
          icon="pi pi-arrow-left"
          pButton
          style="--p-button-width: 2rem; --p-button-height: 2rem"
          type="button"
        ></button>
        <div>
          <h2 class="text-base font-medium my-0 clamp">
            {{ "LAYOUTS.record-view.signature-action.title" | translate : { "default": "Documents signature" } }}
          </h2>
          <span class="block text-color-secondary text-sm clamp">
            {{ record.title }}
          </span>
        </div>
      </div>
    </div>
  </header>
</ng-template>

<!-- Container for the v3 iFrame -->
<div
  [ngClass]="{
    'hidden': !isVersionThree
  }"
  class="yousign-container"
  [id]="iframeContainerId"
></div>

<main-token-input-panel
  #tokenInput
  (onTokenCancel)="showToken = false"
  [showTokenPanel]="showToken"
  [isLoading]="isPerformingValidateSignature"
  (onTokenCompleted)="handleTokenCompleted($event)"
></main-token-input-panel>

<!-- Zoom Actions Template -->
<ng-template #zoomActionsTemplate>
  <div class="flex flex-row align-items-center justify-content-center">
    <button
      pButton
      class="p-button-text p-button-sm text-color"
      icon="pi pi-minus"
      (click)="decreaseZoom()"
      [disabled]="isMinZoom()"
    ></button>
    <button pButton class="p-button-text p-button-sm text-color" [label]="zoom + '%'" (click)="resetZoom()"></button>
    <button pButton class="p-button-text p-button-sm text-color" icon="pi pi-plus" (click)="increaseZoom()"></button>
  </div>
</ng-template>

<!-- Attachment selector Dropdown Menu -->
<p-menu styleClass="generic-menu" #attachmentsMenu [popup]="true" [model]="attachmentsMenuItems"></p-menu>
<p-menu #actionsMenu [popup]="true" [model]="actionsMenuItems"></p-menu>

<!-- Loading Template -->
<ng-template #loadingTemplate>
  <div class="h-full w-full flex align-items-center justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>
