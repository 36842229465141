<p-sidebar
  [(visible)]="showConsents"
  position="bottom"
  [baseZIndex]="10000"
  [showCloseIcon]="false"
  [style]="{ height: 'auto', '--sidebar-content-padding': 0 }"
  styleClass="consents-sidebar"
>
  <ng-template pTemplate="header">
    <header class="w-full measured-container measure-sm">
      <h2 class="h4 my-0">
        {{ "COMMON.text.consents.title" | translate : { "default": "Give your consent" } }}
      </h2>
    </header>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="measured-container measure-sm padded-container">
      <user-consents-field
        #consentsField
        [consentLogs]="user.properties['consent-debug-logs'] || false"
        [consentAnalytics]="user.properties['consent-analytics'] || false"
        [consentNewsletter]="user.properties['consent-newsletters'] || false"
      ></user-consents-field>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <footer class="surface-card measured-container measure-sm flex flex-column sm:flex-row gap-2">
      <button
        pButton
        (click)="doAcceptAll()"
        class="p-button-sm justify-content-center"
        data-attr="consents-accept-all"
        [label]="'COMMON.buttons.accept-all' | translate : { 'default': 'Accept all' }"
      ></button>
      <button
        pButton
        (click)="doUpdateUser()"
        class="p-button-outlined p-button-sm justify-content-center"
        data-attr="consents-update"
        [label]="'COMMON.text.consents.buttons.accept-preferences' | translate : { 'default': 'Update' }"
      ></button>
    </footer>
  </ng-template>
</p-sidebar>
