import { Component, Input, OnInit } from "@angular/core";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import { AvailbleBSPositions } from "ngx-bootstrap/positioning";

@Component({
  selector: "ui-recipient-avatar",
  templateUrl: "./recipient-avatar.component.html",
  styleUrls: ["./recipient-avatar.component.scss"],
})
export class RecipientAvatarComponent implements OnInit {
  @Input() recipient: RecordRecipientModel;

  @Input() size: string;
  @Input() showTooltip: boolean = false;
  @Input() showAvatar: boolean = true;
  @Input() tooltipPlacement: AvailbleBSPositions = "top";

  constructor() {}

  ngOnInit(): void {}
}
