import { Injectable } from "@angular/core";
import { DateTime, Duration } from "luxon";

@Injectable({
  providedIn: "root",
})
export class DateHelper {
  constructor() {}

  static parseDate(value: any): Date | null {
    const parsedDate = Date.parse(value);

    if (!Number.isNaN(parsedDate)) {
      return new Date(parsedDate);
    }

    return null;
  }

  static addDays = (date: Date, days: number): Date => {
    return DateTime.fromJSDate(date).plus({ days }).toJSDate();
  };

  static beginningOfDay = (date: Date): Date => {
    return DateTime.fromJSDate(date).startOf("day").toJSDate();
  };

  static endOfDay = (date: Date): Date => {
    return DateTime.fromJSDate(date).endOf("day").toJSDate();
  };

  static dateDiff = (from: Date, to: Date): Duration => {
    return DateTime.fromJSDate(from).diff(DateTime.fromJSDate(to));
  };

  static humanizeDate = (value: Date, locale: string) => {
    return DateTime.fromJSDate(value).toRelative({ locale: locale });
  };
  /**
   * Generate an array of dates between two dates with a fixed interval of days
   * Start and End dates are included
   */
  static getDatesBetween(startDate: Date, endDate: Date, interval: number = 7, time: Date): Date[] {
    if (!startDate || !endDate || !interval || !time) {
      return [];
    }
    let hours = time.getUTCHours(),
      minutes = time.getUTCMinutes();
    let dates: Date[] = [];
    let currentDate = startDate;
    while (currentDate < this.endOfDay(endDate)) {
      dates.push(currentDate);
      currentDate = this.addDays(currentDate, interval);
    }
    // For all dates set the time
    return dates.map((date) => new Date(date.setUTCHours(hours, minutes)));
  }

  static forceUtc(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  }

  /**
   *
   * @param startDate Start date of the duration
   * @param endDate End of the duration
   * @param threshold Between 0 and 1 - Determines the threshold above which the result is concidered urgent. 0.8 by default
   * @returns
   */
  static checkDurationThreshold(startDate: Date, endDate: Date, threshold: number = 0.8): Boolean {
    if (!startDate || !endDate || startDate > endDate) return false;
    const start = new Date(startDate),
      end = new Date(endDate),
      current = new Date(),
      totalDuration = end.getTime() - start.getTime(),
      elapsedDuration = current.getTime() - start.getTime(),
      thresholdDuration = threshold * totalDuration;
    return elapsedDuration > thresholdDuration;
  }

  /**
   *
   * @param startDate
   * @param endDate
   * @param target Date until which the progression is computed
   * @returns Ration between 0 and 1
   */
  static getProgressionBetweenDates(startDate: Date, endDate: Date, target: Date = new Date()): number {
    if (!startDate || !endDate || startDate > endDate) return null;
    const start = new Date(startDate),
      end = new Date(endDate),
      current = target,
      totalDuration = end.getTime() - start.getTime(),
      elapsedDuration = current.getTime() - start.getTime(),
      ratio = elapsedDuration / totalDuration;
    return ratio;
  }
}
