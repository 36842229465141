import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import { RecordRoleEnum } from "@shared/enums/record-role.enum";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import { MenuItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "record-recipients-list-edit",
  templateUrl: "./recipients-list-edit.component.html",
  styleUrls: ["./recipients-list-edit.component.scss"],
})
export class RecipientsListEditComponent implements OnInit, OnDestroy {
  @Input() allowSelf: boolean = true;
  @Input() allowOnlyEmail: boolean = true;
  @Input() signatureRecipientEmails: string[] = [];
  @Input() isSignerRequired: boolean = false;
  @Input() enableValidation: boolean = true;
  @Input() disabled: boolean = false;
  @Input() recordType: "form" | "signature" | "transfer" = "transfer";
  @Input() recipientRole: RecordRoleEnum = RecordRoleEnum.READER;
  @Input() recipients: RecordRecipientModel[] = [];
  @Input() enableSignature: boolean = false;

  @Input() descriptionText: string;
  @Input() buttonText: string;
  @Input() dialogTitleText: string;

  @Output() onSignatureRecipientEmailsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  onRecipientsChanged: EventEmitter<RecordRecipientModel[]> = new EventEmitter<RecordRecipientModel[]>();

  @Input() showSelfSign: boolean = false;
  @Output() onAddSelfAsSigner: EventEmitter<any> = new EventEmitter<any>();
  isEmailValid: boolean = false;
  showRecipientEditForm: boolean = false;
  showContactsManager: boolean = false;
  selectedRecipient: RecordRecipientModel;
  addRecipientsItems: MenuItem[];
  showProviderContactPicker: boolean = false;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.setAddRecipientsItems();
  }

  ngOnDestroy() {}

  filterRecipientsByRole(recipients: RecordRecipientModel[], recipientRole): RecordRecipientModel[] {
    return recipients.filter((recipient) => {
      return recipient.role == recipientRole;
    });
  }

  add() {
    this.showContactsManager = true;
  }

  remove(event: Event, recipient: RecordRecipientModel) {
    event.stopPropagation();
    this.recipients = this.recipients.filter((r: RecordRecipientModel) => r !== recipient);
    this.onRecipientsChanged.emit(this.recipients);
  }

  doEdit(event: Event, recipient: RecordRecipientModel) {
    if (event) event.stopPropagation();
    this.selectedRecipient = recipient;
    this.showRecipientEditForm = true;
  }

  handleEditClose() {
    this.showRecipientEditForm = false;
    this.selectedRecipient = undefined;
  }

  handleProviderContactClose() {
    this.showProviderContactPicker = false;
  }

  handleProviderContactSubmit(recipients: RecordRecipientModel[]) {
    let newRecipients = [...new Set([...recipients, ...this.recipients])]; // concatenate and remove duplicates
    this.onRecipientsChanged.emit(newRecipients);
    this.handleProviderContactClose();
  }

  onSaveRecipient(recipient: RecordRecipientModel) {
    if (this.recipients.indexOf(this.selectedRecipient) > -1) {
      this.recipients[this.recipients.indexOf(this.selectedRecipient)] = recipient;
    } else {
      this.recipients.push(recipient);
    }
    this.selectedRecipient = null;
    this.showRecipientEditForm = false;
    this.onRecipientsChanged.emit(this.recipients);
  }

  handleContactsSelected(contacts: ContactModel[]) {
    let newRecipients: RecordRecipientModel[] = contacts.map((contact: ContactModel) =>
      RecordRecipientModel.fromContact(contact, this.recipientRole),
    );

    this.recipients = [...this.recipients, ...newRecipients];
    this.onRecipientsChanged.emit(this.recipients);
    this.handleContactsManagerCancel();
  }

  handleContactsManagerCancel() {
    this.showContactsManager = false;
  }

  handleSignatureRecipientChange(emails: string[], recipient: RecordRecipientModel) {
    let possibleEmails = this.recipients.map((r) => r.emailAddress),
      signersEmails = emails.filter((email) => possibleEmails.includes(email));
    this.onSignatureRecipientEmailsChange.emit(signersEmails);
  }

  setAddRecipientsItems() {
    this.addRecipientsItems = [
      {
        label: this.translateService.instant("COMMON.buttons.add-from-internal-contacts", {
          default: "Add internal contacts",
        }),
        command: () => (this.showContactsManager = true),
        icon: "pi pi-users",
      },
      {
        label: this.translateService.instant("MAIN.provider-contacts.import-from-provider", {
          default: "From your Nestor Address Book",
        }),
        command: () => (this.showProviderContactPicker = true),
        icon: "pi pi-link",
      },
    ];
  }
}
