import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { SidebarItemProps } from "../sidebar-link/sidebar-link.component";

@UntilDestroy()
@Component({
  selector: "main-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() showSidebarMenu: boolean = false;
  @Output() onSidebarMenuChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  items: SidebarItemProps[] = [];

  constructor(private translateService: TranslateService) {
    this.setItems();

    // Subscribe to onLangChange to have items updated when locale is changing in profile form
    translateService.onLangChange.pipe(untilDestroyed(this)).subscribe((event: LangChangeEvent) => {
      this.setItems();
    });
  }

  ngOnInit(): void {}

  setItems() {
    this.items = [
      // {
      //   label: this.translateService.instant("SIDEBAR.menu.home.label", { default: "Home" }),
      //   primeIcon: PrimeIcons.HOME,
      //   link: "/",
      // },
      // {
      //   label: this.translateService.instant("SIDEBAR.menu.new.label", { default: "New" }),
      //   separator: true,
      // },
      {
        label: this.translateService.instant("SIDEBAR.menu.transfer.label", { default: "Transfer" }),
        msrIcon: "draft",
        link: "/record/new-transfer",
        tooltip: this.translateService.instant("SIDEBAR.menu.transfer.tooltip", {
          default: "Secure and fast transfer",
        }),
      },
      {
        label: this.translateService.instant("SIDEBAR.menu.batch-transfer.label", { default: "Batch Transfer" }),
        msrIcon: "file_copy",
        link: "/record-batch/new-transfer",
        tooltip: this.translateService.instant("SIDEBAR.menu.batch-transfer.tooltip", {
          default: "Secure and fast transfer campain",
        }),
      },

      {
        separator: true,
      },

      {
        label: this.translateService.instant("SIDEBAR.menu.e-sign.label", { default: "Signature" }),
        msrIcon: "draw",
        link: "/record/new-signature",
        tooltip: this.translateService.instant("SIDEBAR.menu.e-sign.tooltip", {
          default: "Send a document for signature",
        }),
      },
      {
        separator: true,
      },
      {
        label: this.translateService.instant("SIDEBAR.menu.collect.label", { default: "Collect" }),
        msrIcon: "list_alt",
        link: "/record/new-collect",
        tooltip: this.translateService.instant("SIDEBAR.menu.collect.tooltip", {
          default: "Request for information",
        }),
      },
      {
        label: this.translateService.instant("SIDEBAR.menu.batch-collect.label", { default: "Batch Collect" }),
        msrIcon: "list_alt",
        link: "/record-batch/new-collect",
        tooltip: this.translateService.instant("SIDEBAR.menu.batch-collect.tooltip", {
          default: "Request for information",
        }),
      },
      {
        separator: true,
      },
      {
        label: this.translateService.instant("SIDEBAR.menu.summary.label", { default: "Summary" }),
        msrIcon: "manage_search",
        link: "/search/records/records_sent",
        tooltip: this.translateService.instant("SIDEBAR.menu.summary.tooltip", { default: "Summary of your records" }),
      },
    ];
  }

  handleSidebarVisibility(isVisible: boolean): void {
    this.onSidebarMenuChange.emit(isVisible);
  }
}
