import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import { PhoneHelper } from "@app/@shared/helpers/phone.helper";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { Papa, ParseMeta, ParseResult } from "ngx-papaparse";
import { FileWithPath } from "file-selector";
import { BehaviorSubject, tap } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { InvalidContactModel } from "@app/contacts/layouts/import/import.layout";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import { isEmpty } from "lodash";
import { Logger } from "@app/@core";
import { ThemingService } from "@app/@shared/services/theming.service";
import { AddressBookAPIService } from "@app/@shared/services/address-book-api.service";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";

// Taken from docs https://primeng.org/autocomplete#api.autocomplete.events.AutoCompleteCompleteEvent
type AutoCompleteCompleteEvent = {
  originalEvent: Event;
  query: string;
};

type ContactHeader = "emailAddress" | "firstName" | "lastName" | "phoneNumber";
type NestorHeader = {
  key: ContactHeader;
  label: string;
};

const log = new Logger("Contact Import Component");

@UntilDestroy()
@Component({
  selector: "contacts-contact-import",
  templateUrl: "./contact-import.component.html",
  styleUrls: ["./contact-import.component.scss"],
})
export class ContactImportComponent implements OnInit {
  showInvalidContactsModal: boolean;
  enableContactTags: boolean = false;
  contactTags: string[] = []; // selected tags
  filteredTags: string[] = []; //
  suggestions: string[] = []; // entire tags

  @ViewChild("uploadContactsInput") uploadContactsInput: ElementRef<HTMLInputElement>;

  file$: BehaviorSubject<File> = new BehaviorSubject(null);
  validContacts$: BehaviorSubject<ContactModel[]> = new BehaviorSubject(null);
  invalidContacts$: BehaviorSubject<InvalidContactModel[]> = new BehaviorSubject(null);
  data$: BehaviorSubject<any[]> = new BehaviorSubject(null);
  meta$: BehaviorSubject<ParseMeta> = new BehaviorSubject(null);

  isProcessingContacts$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() displayTitle: boolean = true;

  get file() {
    return this.file$.getValue();
  }
  get data() {
    return this.data$.getValue();
  }
  get validContacts() {
    return this.validContacts$.getValue();
  }
  get invalidContacts() {
    return this.invalidContacts$.getValue();
  }
  // INPUTS
  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;
  // OUTPUTS
  @Output() onContactsImported: EventEmitter<ContactModel[]> = new EventEmitter<ContactModel[]>();
  @Output() onInvalidContacts: EventEmitter<InvalidContactModel[]> = new EventEmitter<InvalidContactModel[]>();
  @Output() onCancelContactsImport: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  headers: NestorHeader[];
  headerOptions: string[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService,
    private papaService: Papa,
    private confirmationService: ConfirmationService,
    public themingService: ThemingService,
    private contactsService: AddressBookAPIService,
  ) {}

  ngOnInit(): void {
    this.setNestorHeaders();
    this.buildform();
    this.file$
      .pipe(
        untilDestroyed(this),
        tap((f) => {
          if (!f) {
            this.meta$.next(null);
            this.data$.next(null);
          }
        }),
      )
      .subscribe((file) => this.papaParseFile(file));

    this.meta$.pipe(untilDestroyed(this)).subscribe((meta) => {
      this.generateHeaderOptions(meta?.fields ?? null);
    });

    this.form.valueChanges
      .pipe(
        tap(() => this.isProcessingContacts$.next(true)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        let validContacts: ContactModel[] = [];
        let invalidContacts: InvalidContactModel[] = [];
        if (!this.data?.length) {
          this.isProcessingContacts$.next(false);
          return null;
        }
        this.data.forEach((line, index) => {
          let contact = new ContactModel();
          let rowId: number = index + 1;
          this.headers.forEach((header) => {
            contact[header.key] = line[this.form.get(header.key).value]?.trim();
          });
          if (contact.isValid(false, false)) {
            contact.phoneNumber = contact.phoneNumber
              ? PhoneHelper.parseInternationalPhoneNumber(contact.phoneNumber).e164Number
              : null;
            validContacts.push(contact);
          } else {
            let invalidFields = this.getContactInvalidFields(contact);
            let invalidContact: InvalidContactModel = Object.assign({ rowId, line, invalidFields }, contact);
            invalidContacts.push(invalidContact);
          }
        });

        this.validContacts$.next(validContacts);
        this.invalidContacts$.next(invalidContacts);
        this.isProcessingContacts$.next(false);
      });

    this.contactsService
      .getTags({ scope: ContactScopeEnum.USER }, [ResponseLevelEnum.MINIMIZE])
      .pipe(untilDestroyed(this))
      .subscribe((tags) => {
        this.suggestions = tags.map(({ label }) => label);
      });
  }

  getContactInvalidFields(contact: ContactModel): string[] {
    let invalidFields = [];
    if (!StringHelper.isEmail(contact.emailAddress)) invalidFields.push("emailAddress");
    if (!isEmpty(contact.phoneNumber) && !PhoneHelper.isValidPhoneNumber(contact.phoneNumber))
      invalidFields.push("phoneNumber");
    return invalidFields.length ? invalidFields : null;
  }

  displayInvalidContactsModal() {
    this.showInvalidContactsModal = true;
  }

  handleFilesDropped(files: FileList | (FileWithPath | DataTransferItem)[]) {
    if (files?.length > 0) this.file$.next(files[0] as File);
  }

  handleRemoveFile() {
    this.file$.next(null);
  }
  setNestorHeaders() {
    this.headers = [
      {
        key: "emailAddress",
        label: this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" }),
      },
      {
        key: "firstName",
        label: this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" }),
      },
      {
        key: "lastName",
        label: this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" }),
      },
      {
        key: "phoneNumber",
        label: this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" }),
      },
    ];
  }

  private buildform() {
    this.form = this.formBuilder.group({
      emailAddress: [null, [Validators.required]],
      firstName: [null, []],
      lastName: [null, []],
      phoneNumber: [null, []],
    });
  }

  // Set the computed headers and apply defaults
  generateHeaderOptions(fields: string[]) {
    this.headerOptions = fields;
    let defaultValues = {};
    this.headers.forEach(({ key }, index) => {
      if (fields?.[index]) defaultValues[key] = fields[index];
    });
    this.form.patchValue(defaultValues);
  }

  submit() {
    this.onInvalidContacts.emit(this.invalidContacts);
    if (this.validContacts.length > 0) {
      let taggedContacts = this.validContacts.map((contact) =>
        Object.assign(contact, { tags: this.enableContactTags ? this.contactTags : null }),
      );
      this.validContacts$.next(taggedContacts);
      if (this.invalidContacts?.length) {
        this.confirmationService.confirm({
          header: this.translateService.instant("ADDRESS-BOOK.actions.import-with-invalids.header", {
            default: "Incorrect entries will be skipped",
          }),
          message: this.translateService.instant("ADDRESS-BOOK.actions.import-with-invalids.body", {
            default: "Incorrect entries have been detected and will be ignored",
          }),
          acceptLabel: this.translateService.instant("ADDRESS-BOOK.contact-import.buttons.confirm-import"),
          rejectLabel: this.translateService.instant("COMMON.buttons.cancel"),
          acceptButtonStyleClass: "p-button-sm",
          acceptIcon: "hidden",
          rejectIcon: "hidden",
          rejectButtonStyleClass: "p-button-outlined p-button-sm",
          accept: () => {
            this.onContactsImported.emit(this.validContacts);
          },
        });
      } else {
        this.onContactsImported.emit(this.validContacts);
      }
    } else {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("ADDRESS-BOOK.import-modal.errors.import-failed.title"),
          this.translateService.instant("ADDRESS-BOOK.import-modal.errors.import-failed.details"),
        ),
      );
    }
  }

  handleFileUpload(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files && element.files.length > 0) {
      const file = element.files[0];
      this.file$.next(file);
    }
  }

  papaParseFile(file: File) {
    if (!file) return null;
    this.papaService.parse(file, {
      header: true,
      skipEmptyLines: true,
      error: (error, file) => {
        log.error(error, file);
      },
      complete: (result: ParseResult) => {
        this.data$.next(result.data);
        this.meta$.next(result.meta);
        this.generateHeaderOptions(result.meta.fields);
      },
    });
  }

  /* CONTACTS */
  onKeyUp(event: any) {
    event.preventDefault();
    let tokenInput = event.target as HTMLInputElement;

    if (tokenInput.value) {
      let newValue = event.key == "," ? tokenInput.value.slice(0, -1) : tokenInput.value;
      let newTags = [...this.contactTags, newValue];
      this.contactTags = newTags;
      tokenInput.value = "";
    }
  }

  searchSuggestions(event: AutoCompleteCompleteEvent) {
    let unselectedTags: string[] = this.suggestions.filter((tag) => !this.contactTags.includes(tag));
    let queryResultsTags = unselectedTags.filter((tag) => tag.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
    this.filteredTags = queryResultsTags;
  }
}
