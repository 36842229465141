<p-dropdown
  [name]="name"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  appendTo="body"
  [filter]="false"
  optionLabel="name"
  [options]="countries"
  [styleClass]="'country-input ' + styleClass"
  [ngModel]="selectedValue"
  (ngModelChange)="handleChange($event)"
>
  <ng-template pTemplate="selectedItem">
    <div class="country-item country-item-value" *ngIf="selectedValue">
      <img src="/assets/images/flag_placeholder.png" [class]="'flag flag-' + selectedValue?.code?.toLowerCase()" />
      <div>{{ selectedValue.name }}</div>
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="country-item">
      <img src="/assets/images/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" />
      <div>{{ country.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
