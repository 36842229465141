import BaseModel from "@shared/models/base.model";
import PaymentMethodCardModel from "./payment-method/card.model";
import PaymentMethodSepaDebitModel from "./payment-method/sepa-debit.model";

export default class SetupIntentModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public clientSecret: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
