import Quill from "quill";

const Embed = Quill.import("blots/embed");

class FormItemBlot extends Embed {
  constructor(scroll, node) {
    super(scroll, node);
    this.clickHandler = null;
    this.mounted = false;
  }

  static create(value) {
    let node = super.create(value);

    node.textContent = value.text;
    node.dataset["identifier"] = value.identifier;
    node.dataset["format"] = value.format;
    node.setAttribute("tabindex", "0");
    return node;
  }

  static value(domNode) {
    return { ...domNode.dataset, text: domNode.textContent };
  }

  attach() {
    super.attach();

    if (!this.mounted) {
      this.mounted = true;
      this.clickHandler = this.getClickHandler();

      this.domNode.addEventListener("click", this.clickHandler, false);
    }
  }

  detach() {
    super.detach();
    this.mounted = false;
    if (this.clickHandler) {
      this.domNode.removeEventListener("click", this.clickHandler);
      this.clickHandler = null;
    }
  }

  getClickHandler() {
    return (e) => {
      const event = this.buildEvent("form-item-click", e);
      window.dispatchEvent(event);
      e.preventDefault();
    };
  }

  buildEvent(name, e) {
    const event = new Event(name, {
      bubbles: true,
      cancelable: true,
    });
    event.value = Object.assign({}, this.domNode.dataset);
    event.event = e;
    return event;
  }
}
FormItemBlot.blotName = "form-item";
FormItemBlot.className = "ql-form-item";
FormItemBlot.tagName = "span";

export default FormItemBlot;
