import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import IdentityModel from "@app/@shared/models/user/identity.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { Observable } from "rxjs";

@Component({
  selector: "record-avatar-group",
  templateUrl: "./record-avatar-group.component.html",
  styleUrls: ["./record-avatar-group.component.scss"],
})
export class RecordAvatarGroupComponent implements OnInit, OnChanges {
  @Input() showFirstRecipient: boolean = false;
  @Input() recipients: RecordRecipientModel[];
  @Input() sortedRecipients: RecordRecipientModel[] = [];
  @Input() tootlipPlacement: "top" | "bottom" | "right" | "left" = "top";
  @Input() maxAvatars = 6;
  @Input() showAvatar: boolean = true;

  currentIdentity$: Observable<IdentityModel>;

  constructor(private sessionAPIService: SessionAPIService) {}

  ngOnInit(): void {
    this.currentIdentity$ = this.sessionAPIService.currentIdentity$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recipients) {
      this.sortedRecipients = this.recipients
        .filter((r: RecordRecipientModel) => r.role !== RecordRoleEnum.BOT)
        .sort((a: RecordRecipientModel, b: RecordRecipientModel) => {
          if (a.role === RecordRoleEnum.OWNER) {
            return -1;
          } else if (b.role === RecordRoleEnum.OWNER) {
            return Infinity;
          } else {
            return a.toString.localeCompare(b.toString);
          }
        });
    }
  }
}
