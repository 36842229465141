<div
  class="flex flex-column justify-content-center align-items-center py-8 px-3"
  *ngIf="(currentApplication$ | async) == null && !(isLoadingApplications$ | async); else haveActiveProviderApplication"
>
  <div class="flex justify-content-center gap-2 opacity-80">
    <i class="pi pi-microsoft text-7xl"></i>
    <i class="pi pi-folder text-7xl"></i>
    <i class="pi pi-google text-7xl"></i>
  </div>
  <p class="text-center">
    {{
      "MAIN.empty-states.providers.subtitle"
        | translate : { default: "Link Nestor to your external drives to easily import files" }
    }}
  </p>
  <a
    pButton
    [routerLink]="['/user-settings', 'apps']"
    class="p-button-sm no-underline"
    icon="pi pi-link"
    [label]="'MAIN.empty-states.providers.button' | translate : { default: 'Go to applications' }"
  ></a>
  <!-- Else there is an active app -->
</div>

<ng-template #haveActiveProviderApplication>
  <!-- MAIN -->
  <div class="h-full" [style]="{ 'min-height': '25rem' }">
    <!-- Application providers selection -->
    <provider-file-switcher
      class="sticky top-0 z-3"
      *ngIf="applications$ | async"
      [applications]="applications$ | async"
      [isLoadingApplications]="isLoadingApplications$ | async"
      [currentApplication]="currentApplication$ | async"
      (onSelectApplication)="handleSelectApplication($event)"
    ></provider-file-switcher>

    <!-- Provider roots selection -->
    <div class="provider-file-picker" *ngIf="roots$ | async as roots">
      <aside class="provider-root-list__selection">
        <p-tree
          [value]="rootsTree$ | async"
          (onNodeExpand)="onRootTypeParentExpand($event)"
          (onNodeSelect)="onRootTypeParentSelect($event)"
          selectionMode="single"
          [loading]="isLoadingRoots$ | async"
          [(selection)]="selectedRootItem"
          [filter]="false"
          styleClass="root-item-dialog__list"
          class="relative w-full flex"
          [ngStyle]="{ 'min-height': '35vh', 'height': 'max-content' }"
        >
          <ng-template let-node pTemplate="rootItem">
            <div class="flex flex-row gap-3 align-items-center root-item">
              <div class="flex w-full align-items-center">
                <img class="root-item__icon" [src]="node.iconSrc" width="18" *ngIf="node.iconSrc" />
                <div class="flex flex-column root-item__name">
                  {{ node.label }}
                </div>
              </div>
            </div>
          </ng-template>
        </p-tree>
      </aside>

      <ng-container
        *ngIf="
          (currentApplication$ | async) && !(isLoadingRoots$ | async) && !(isLoadingItems$ | async);
          else loadingSpinner
        "
      >
        <ul
          *ngIf="(roots$ | async) !== null; else noRootsEmptyState"
          class="provider-item-list__selection list-none m-0"
        >
          <!-- Breadcrumbs -->
          <li
            class="surface-card sticky z-1 py-3 pl-1 pr-3 surface-border border-bottom-1 flex align-items-center justify-content-between gap-1"
            style="top: var(--provider-header-height, 4rem)"
          >
            <ol class="list-none inline-flex items-center space-x-1 md:space-x-3 p-0">
              <li>
                <div class="flex align-items-center">
                  <button
                    pButton
                    icon="pi pi-home"
                    [label]="'MAIN.cloud-file-picker.root-directory' | translate : { 'default': 'Root' }"
                    class="p-1 p-button-text p-button-secondary p-button-sm"
                    (click)="backToRootItem()"
                    type="button"
                  ></button>
                </div>
              </li>
              <li *ngFor="let breadcrumbsItem of breadcrumbsItems$ | async; let index = index">
                <div class="flex align-items-center">
                  <i class="msr-icon-navigate_next icon-14px"></i>
                  <button
                    type="button"
                    pButton
                    [label]="breadcrumbsItem?.name"
                    class="p-1 p-button-text p-button-secondary p-button-sm ml-"
                    (click)="navigateToBreadcrumb(breadcrumbsItem, index)"
                    [ngClass]="
                      index === breadcrumbsItems.length - 1 ? 'text-color-secondary' : 'text-color cursor-pointer'
                    "
                  ></button>
                </div>
              </li>
            </ol>
          </li>

          <!-- New folder creation -->
          <ng-container *ngIf="enableFolderCreation">
            <li
              class="py-3 pl-2 pr-3 surface-border hover:surface-hover cursor-pointer flex align-items-center justify-content-between gap-1 border-bottom-1"
              (click)="isCreationModeEnabled ? cancelCreationMode($event) : triggerNewFolderAction()"
            >
              <div class="flex align-items-center gap-3">
                <i class="pi text-sm text-primary" [ngClass]="isCreationModeEnabled ? 'pi-times' : 'pi-plus'"></i>
                <div class="font-medium text-primary">
                  {{
                    isCreationModeEnabled
                      ? ("MAIN.cloud-file-picker.new-folder.cancel" | translate : { default: "Cancel folder creation" })
                      : ("MAIN.cloud-file-picker.new-folder.label" | translate : { default: "Create new folder" })
                  }}
                </div>
              </div>
            </li>
          </ng-container>

          <!-- Folder creation mode -->
          <ng-container *ngIf="isCreationModeEnabled">
            <li
              class="py-3 pl-2 pr-3 surface-border flex align-items-center justify-content-between gap-1 border-bottom-1"
            >
              <form class="flex align-items-center gap-3 w-full" (ngSubmit)="submitCreationMode($event)">
                <input
                  autofocus
                  #newFolderInput
                  class="flex-grow-1"
                  pInputText
                  name="folderName"
                  [(ngModel)]="newFolderName"
                  (ngModelChange)="onNewFolderNameChange($event)"
                  [attr.aria-label]="
                    'MAIN.cloud-file-picker.new-folder.placeholder' | translate : { default: 'Create new folder' }
                  "
                  [placeholder]="
                    'MAIN.cloud-file-picker.new-folder.placeholder' | translate : { default: 'Create new folder' }
                  "
                  [ngClass]="{
                    'ng-invalid ng-dirty text-red-500': newFolderInvalid$ | async
                  }"
                  (keydown.escape)="cancelCreationMode($event)"
                />
                <div class="flex align-items-center gap-1">
                  <button
                    pButton
                    class="p-button-sm p-button-sm"
                    icon="pi pi-arrow-right"
                    type="submit"
                    [disabled]="isCreatingFolder$ | async"
                  ></button>
                </div>
              </form>
            </li>
          </ng-container>

          <!-- Items list -->
          <ng-container *ngIf="(items$ | async).length; else emptyList">
            <ng-container *ngFor="let item of items$ | async as items; let index = index">
              <li
                class="file-item py-3 pl-2 pr-3 surface-border hover:surface-hover cursor-pointer flex align-items-center justify-content-between gap-1"
                [ngClass]="{
                  'border-bottom-1': index !== items.length - 1,
                  'surface-hover': item === selectedItem,
                  'disabled': pdfOnly && item.mimeType !== 'application/pdf' && !item.folder
                }"
                (click)="select($event, item)"
              >
                <!-- Icon + name -->
                <div class="flex align-items-center gap-3">
                  <ng-container *ngIf="item.folder; else mimeTypeIcon">
                    <i
                      [ngClass]="selectedItem === item ? 'msr-icon-check_circle text-primary' : 'msr-icon-folder'"
                      class="icon-18px"
                    ></i>
                  </ng-container>
                  <ng-template #mimeTypeIcon>
                    <i
                      class="icon-18px"
                      [ngClass]="
                        selectedItem === item
                          ? 'msr-icon-check_circle text-primary'
                          : (item.name?.split('.')?.pop() | getExtensionIcon)
                      "
                    ></i>
                  </ng-template>
                  <div [ngClass]="selectedItem === item ? 'text-primary' : 'text-color'" class="font-medium">
                    {{ item.name }}
                  </div>
                </div>

                <!-- Metadata or arrow-->
                <div class="text-color-secondary text-xs" *ngIf="!item.folder; else navigationArrow">
                  {{ item.size | filesize }}
                </div>
                <ng-template #navigationArrow>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-angle-right"
                    style="--p-button-width: 2rem; --p-button-height: 2rem"
                    class="p-button-text p-button-sm p-1 border-circle"
                    (click)="openFolder($event, item)"
                  ></button>
                </ng-template>
              </li>
            </ng-container>
          </ng-container>

          <!-- Empty list -->
          <ng-template #emptyList>
            <li class="py-3 pl-2 pr-3 surface-border flex align-items-center justify-content-between gap-1">
              <span class="text-color-secondary">
                {{ "MAIN.cloud-file-picker.empty-list" | translate : { "default": "Directory is empty" } }}
              </span>
            </li>
          </ng-template>
        </ul>
      </ng-container>
    </div>
  </div>
  <!-- FOOTER -->
  <div class="dialog-nested-footer flex gap-3 justify-content-end mt-3">
    <button
      type="button"
      pButton
      class="p-button-sm p-button-outlined"
      [label]="'COMMON.buttons.cancel' | translate : { 'default': 'Cancel' }"
      (click)="cancel()"
    ></button>
    <button
      type="button"
      pButton
      class="p-button-sm"
      [label]="
        mode === 'folders'
          ? (hasDeliberatelySelectedFolder$ | async)
            ? ('COMMON.buttons.select' | translate : { default: 'Select' })
            : ('MAIN.export-to-cloud.buttons.submit' | translate : { 'default': 'Select current' })
          : submitLabel
      "
      [disabled]="mode === 'files' && !selectedItem"
      (click)="submit()"
    ></button>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div class="flex py-4 justify-content-center">
    <ui-spinner [size]="60"></ui-spinner>
  </div>
</ng-template>

<ng-template #noRootsEmptyState>
  <div class="flex flex-column justify-content-center align-items-center py-8 px-3">
    <div class="flex justify-content-center gap-2 opacity-80">
      <i class="pi pi-folder-open text-7xl"></i>
    </div>
    <p class="text-center">
      {{
        "USER.errors.provider-picker.no-roots.subtitle"
          | translate
            : {
                default: "Sorry, you can't access the drive with this account.",
                drive: "USER.settings.apps.available." + (currentApplication$ | async).provider + ".drive" | translate
              }
      }}
    </p>
    <a
      pButton
      [routerLink]="['/user-settings', 'apps']"
      class="p-button-sm no-underline"
      icon="pi pi-link"
      [label]="'USER.errors.provider-picker.no-roots.button' | translate : { default: 'Go to applications' }"
    ></a>
  </div>
</ng-template>
