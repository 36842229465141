<div class="flex flex-column">
  <p-table
    [value]="sortedRecipients"
    [(selection)]="selectedRecipients"
    (selectionChange)="handleSelectionChanged()"
    selectionMode="multiple"
    dataKey="identityIdentifier"
    styleClass="p-datatable-sm"
    responsiveLayout="scroll"
    #recipientsListTable
  >
    <ng-template pTemplate="body" let-recipient let-rowIndex="rowIndex">
      <tr
        class="relative"
        [ngStyle]="{ '--background-hover-color': recipient.isValid(true) ? 'inherit' : 'var(--surface-card)' }"
        [ngClass]="{
          'p-disabled': isSubmitting,
          'cursor-pointer': recipient.isValid(true),
          'surface-100': !recipient.isValid(true),
        }"
      >
        <td class="w-1rem" [ngClass]="{ 'pointer-events-none': !recipient.isValid(true) }">
          <p-tableCheckbox
            #checkbox
            [value]="recipient"
            [disabled]="!recipient.isValid(true) || isSubmitting"
          ></p-tableCheckbox>
        </td>
        <td
          class="pl-0"
          (click)="checkbox.onClick($event)"
          [ngClass]="{ 'pointer-events-none': !recipient.isValid(true) }"
        >
          <div
            class="flex flex-column md:gap-1 justify-content-start md:flex-row md:justify-content-between align-items-center"
          >
            <div class="flex align-items-center gap-2 w-full" [ngClass]="{ 'opacity-60': !recipient.isValid(true) }">
              <span
                class="custom-marker hidden md:flex"
                [attr.data-origin]="
                  (currentIdentity$ | async).identityIdentifier === recipient.identityIdentifier ? 'self' : 'other'
                "
                >{{ recipient | getRecipientInitials }}
              </span>
              <div>
                <span class="block clamp font-medium text-color">{{ recipient.emailAddress }} </span>
                <span class="block clamp" *ngIf="recipient.fullName">({{ recipient.fullName }})</span>
              </div>
            </div>
            <button
              *ngIf="!recipient.isValid(true)"
              (click)="doEdit($event, recipient)"
              type="button"
              [label]="'RECORD.recipients.fill-out-recipient' | translate : { 'default': 'Fill out missing details' }"
              pButton
              class="p-button-text p-0 p-button-secondary text-color underline mr-auto md:mr-0 md:ml-auto md:mr-0 p-button-sm py-1 md:flex-shrink-0 pointer-events-auto"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- Dialog for updating invalid recipient -->
<p-dialog
  [(visible)]="showRecipientEditForm"
  appendTo="body"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 1rem))' }"
  [style]="themingService.customStyles$ | async"
  styleClass="dialog-nested"
  (onHide)="handleEditClose()"
>
  <ng-template pTemplate="header">
    <header>
      <h3 class="my-0">
        {{ "RECORD.recipients.fill-out-recipient" | translate : { "default": "Fill out missing details" } }}
      </h3>
    </header>
  </ng-template>
  <record-recipient-form
    *ngIf="selectedRecipient"
    [recipient]="selectedRecipient"
    [allowOnlyEmail]="false"
    [readonlyEmail]="true"
    (onSubmit)="onSaveRecipient($event)"
    (onCancel)="handleEditClose()"
  ></record-recipient-form>
</p-dialog>
