import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { UploadxModule } from "ngx-uploadx";
import { NgsContenteditableModule } from "@ng-stack/contenteditable";
import { MainModule } from "@app/main/main.module";
import { SharedModule } from "@app/@shared/shared.module";
import { UiModule } from "@app/@ui/ui.module";
import { COMPONENTS } from "./components";
import { ContactsWithoutRoutingModule } from "@app/contacts/contacts-without-routing.module";
import { EditorModule } from "@app/editor/editor.module";
import { GUARDS } from "./guards";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Other modules
    UploadxModule,
    NgsContenteditableModule,

    // Custom modules
    MainModule,
    SharedModule,
    EditorModule,
    ContactsWithoutRoutingModule,
    UiModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
  providers: [GUARDS],
})
export class RecordModule {}
