import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "@app/@shared/shared.module";
import { UiModule } from "@app/@ui/ui.module";
import { COMPONENTS } from "./components";
import { ImageCropperModule } from "ngx-image-cropper";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    // Custom modules
    SharedModule,
    UiModule,
    FormsModule,
    ImageCropperModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class SessionModule {}
