import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import { ExportTypeEnum } from "../enums/export-type.enum";
import ExportResultModel from "../models/export/export-result.model";
import ExportModel from "../models/export/export-v1.model";

@Injectable({
  providedIn: "root",
})
export class ExportsAPIService {
  private _baseUrl: string = environment.services.baseUrls.exportsApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  exportConversationAnswers(
    conversationIdentifier: string,
    params: {
      format?: string;
      disposition?: string;
      delimiter?: string;
      fileName?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<Blob> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.exports.exportConversationAnswers.replace(
        "{conversationIdentifier}",
        conversationIdentifier,
      );
    this.loaderService.addOperation("exportConversationAnswers");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.get(url, { headers, params, responseType: "blob", observe: "response" }).pipe(
      map((response: any) => {
        return new File([response.body], response.headers.get(HttpHeadersEnum.FILE_NAME), {
          type: response.headers.get(HttpHeadersEnum.FILE_MIME_TYPE),
        });
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("exportConversationAnswers");
      }),
    );
  }

  exportCampaignAnswers(
    campaignIdentifier: string,
    params: {
      conversationIdentifiers?: string[];
      format?: string;
      delimiter?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ExportModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.exports.exportCampaignAnswers.replace("{campaignIdentifier}", campaignIdentifier);
    this.loaderService.addOperation("exportCampaignAnswers");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.post(url, { ...params }, { headers }).pipe(
      map((response: any) => {
        return new ExportModel().deserialize(response);
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("exportCampaignAnswers");
      }),
    );
  }

  exportCampaignAttachments(
    campaignIdentifier: string,
    params: {
      conversationIdentifiers?: string[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ExportModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.exports.exportCampaignAttachments.replace(
        "{campaignIdentifier}",
        campaignIdentifier,
      );
    this.loaderService.addOperation("exportCampaignAttachments");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.post(url, { ...params }, { headers }).pipe(
      map((response: any) => {
        return new ExportModel().deserialize(response);
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("exportCampaignAttachments");
      }),
    );
  }

  getCampaignExports(
    campaignIdentifier: string,
    params: {
      type?: ExportTypeEnum;
      offset?: number;
      limit?: number;
      order?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ExportResultModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.exports.getCampaignExports.replace("{campaignIdentifier}", campaignIdentifier);
    this.loaderService.addOperation("getCampaignExports");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.get(url, { headers, params }).pipe(
      map((response: any) => {
        return new ExportResultModel().deserialize(response);
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("getCampaignExports");
      }),
    );
  }

  getExport(
    exportIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ExportModel> {
    const url =
      this._baseUrl + environment.services.methodUrls.exports.getExport.replace("{exportIdentifier}", exportIdentifier);
    this.loaderService.addOperation("getExport");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.get(url, { headers }).pipe(
      map((response: any) => {
        return new ExportModel().deserialize(response);
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("getExport");
      }),
    );
  }

  downloadExport(
    exportIdentifier: string,
    params: {
      filename?: string;
      disposition?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<Blob> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.exports.downloadExport.replace("{exportIdentifier}", exportIdentifier);
    this.loaderService.addOperation("downloadExport");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.get(url, { headers, params, responseType: "blob", observe: "response" }).pipe(
      map((response: any) => {
        return new File([response.body], response.headers.get(HttpHeadersEnum.FILE_NAME), {
          type: response.headers.get(HttpHeadersEnum.FILE_MIME_TYPE),
        });
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("downloadExport");
      }),
    );
  }

  deleteExport(
    exportIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.exports.deleteExport.replace("{exportIdentifier}", exportIdentifier);
    this.loaderService.addOperation("deleteExport");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken())
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.RESPONSE_LEVELS, responseLevels.join(","));

    return this.httpService.delete(url, { headers }).pipe(
      map((response: any) => {
        return response["done"];
      }),
      catchError((error) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.removeOperation("deleteExport");
      }),
    );
  }
}
