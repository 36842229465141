<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="dialog-nested-content form__main">
    <fieldset class="border-none m-0 p-0">
      <legend class="h3 px-0 mb-4">
        {{
          "LAYOUTS.adress-book.contact-edit.mandatory-fields.title" | translate : { "default": "Required information" }
        }}
      </legend>
      <div class="field">
        <label for="emailAddress">{{
          "COMMON.fields.email-address.label" | translate : { "default": "Email address" }
        }}</label>
        <input
          pInputText
          type="email"
          id="emailAddress"
          autofocus
          formControlName="emailAddress"
          class="w-full"
          [ngClass]="{
            'ng-invalid ng-dirty': form.get('emailAddress')?.invalid && form.get('emailAddress')?.touched
          }"
        />
        <div [hidden]="form.get('emailAddress')?.valid || form.get('emailAddress')?.untouched">
          <small class="p-error flex flex-column">
            <span *ngIf="form.get('emailAddress')?.errors?.required">{{
              "COMMON.fields.email-address.errors.required" | translate : { "default": "Email address is required" }
            }}</span>
            <span *ngIf="form.get('emailAddress')?.errors?.email">{{
              "COMMON.fields.email-address.errors.invalid" | translate : { "default": "Email address must be valid" }
            }}</span>
            <span *ngIf="form.get('emailAddress')?.errors?.emailAlreadyUsed">{{
              "COMMON.fields.email-address.errors.already-used"
                | translate : { "default": "Email address is already used" }
            }}</span>
            <span *ngIf="form.get('emailAddress')?.errors?.isSelfEmail">{{
              "COMMON.fields.email-address.errors.is-self-email"
                | translate : { "default": "Email address can't be yours" }
            }}</span>
          </small>
        </div>
      </div>
    </fieldset>

    <p-divider></p-divider>

    <fieldset class="border-none m-0 p-0">
      <legend class="h3 px-0" style="margin-bottom: 4px">
        {{
          "LAYOUTS.adress-book.contact-edit.additional-fields.title"
            | translate : { "default": "Additional information" }
        }}
      </legend>
      <p class="mb-3 mt-0 text-color-secondary">
        {{
          "LAYOUTS.adress-book.contact-edit.additional-fields.description"
            | translate : { "default": "Additional information" }
        }}
      </p>

      <!-- Phone number input -->
      <div class="field">
        <label for="phoneNumber" class="block">{{
          "COMMON.fields.phone-number.label" | translate : { "default": "Phone number" }
        }}</label>
        <ui-phone-input
          id="phoneNumber"
          formControlName="phoneNumber"
          [selectedCountryISO]="phoneCountryISO"
          [enablePlaceholder]="true"
          [readonly]="isLoading"
          [ngClass]="{ 'ng-invalid ng-dirty': form.get('phoneNumber')?.invalid && form.get('phoneNumber')?.touched }"
        ></ui-phone-input>
        <div [hidden]="form.get('phoneNumber')?.valid || form.get('phoneNumber')?.untouched">
          <small class="p-error flex flex-column">
            <span *ngIf="form.get('phoneNumber')?.errors?.required">{{
              "COMMON.fields.phone-number.errors.required" | translate : { "default": "Phone number is required" }
            }}</span>
            <span *ngIf="form.get('phoneNumber')?.errors?.valaidatePhoneNumber">{{
              "COMMON.fields.phone-number.errors.invalid"
                | translate
                  : {
                      "default":
                        "Phone number must be
                    valid"
                    }
            }}</span>
          </small>
        </div>
      </div>

      <div class="flex flex-column sm:flex-row sm:gap-3">
        <!-- Field: Firstname -->
        <div class="field sm:w-full">
          <label for="firstName">{{
            "COMMON.fields.first-name.label" | translate : { "default": "First name" }
          }}</label>
          <input
            pInputText
            type="text"
            id="firstName"
            formControlName="firstName"
            class="w-full"
            [ngClass]="{
              'ng-invalid ng-dirty': form.get('firstName')?.invalid && form.get('firstName')?.touched
            }"
          />
          <div [hidden]="form.get('firstName')?.valid || form.get('firstName')?.untouched">
            <small class="p-error flex flex-column">
              <span *ngIf="form.get('firstName')?.errors?.required">{{
                "COMMON.fields.first-name.errors.required" | translate : { "default": "First name is required" }
              }}</span>
            </small>
          </div>
        </div>

        <!-- Field: Lastname -->
        <div class="field sm:w-full">
          <label for="lastName">{{ "COMMON.fields.last-name.label" | translate : { "default": "Last name" } }}</label>
          <input
            pInputText
            type="text"
            id="lastName"
            formControlName="lastName"
            class="w-full"
            [ngClass]="{
              'ng-invalid ng-dirty': form.get('lastName')?.invalid && form.get('lastName')?.touched
            }"
          />
          <div [hidden]="form.get('lastName')?.valid || form.get('lastName')?.untouched">
            <small class="p-error flex flex-column">
              <span *ngIf="form.get('lastName')?.errors?.required">{{
                "COMMON.fields.last-name.errors.required" | translate : { "default": "Last name is required" }
              }}</span>
            </small>
          </div>
        </div>
      </div>
    </fieldset>

    <p-divider></p-divider>

    <fieldset class="border-none m-0 p-0">
      <legend class="h3 px-0" style="margin-bottom: 4px">
        {{ "LAYOUTS.adress-book.contact-edit.more-fields.title" | translate : { "default": "More" } }}
      </legend>
      <p class="mb-3 mt-0 text-color-secondary">
        {{
          "LAYOUTS.adress-book.contact-edit.more-fields.description"
            | translate : { "default": "Add tags to your contacts to organize them into groups." }
        }}
      </p>
      <!-- Tags -->
      <div class="field">
        <label for="contactTags" class="block">{{
          "LAYOUTS.adress-book.contact-edit.tags.label" | translate : { "default": "Tags" }
        }}</label>
        <div class="flex flex-column gap-2 p-fluid">
          <p-autoComplete
            inputId="contactTags"
            styleClass="contactTagsAutocomplete"
            (keydown.enter)="onKeyUp($event)"
            formControlName="tags"
            [suggestions]="filteredTags"
            (completeMethod)="searchSuggestions($event)"
            [dropdown]="true"
            [multiple]="true"
            appendTo="body"
            [panelStyle]="themingService.customStyles$ | async"
            panelStyleClass="contactTagsAutocompletePanel"
          >
            <ng-template pTemplate="removetokenicon">
              <i class="pi pi-times-circle text-xs"></i>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
    </fieldset>
  </div>
  <footer class="dialog-nested-footer flex flex-wrap gap-3 justify-content-end border-round-bottom mt-3">
    <button
      pButton
      [label]="'COMMON.buttons.cancel' | translate : { 'default': 'Cancel' }"
      type="button"
      *ngIf="!isUpdatingExisting"
      (click)="cancel()"
      class="p-button-outlined p-button-sm"
      [disabled]="isLoading || isSubmitting"
    ></button>
    <button
      pButton
      [label]="
        isUpdatingExisting
          ? ('LAYOUTS.adress-book.contact-list.buttons.update' | translate : { 'default': 'Save changes' })
          : ('LAYOUTS.adress-book.contact-list.buttons.create-and-add'
            | translate : { 'default': 'Create and add contact' })
      "
      type="submit"
      class="p-button-sm"
      [loading]="isSubmitting"
      [disabled]="isLoading"
    ></button>
  </footer>
</form>
