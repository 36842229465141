import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateHelper } from "@app/@shared/helpers/date.helper";
import RecordReminderPropertiesModel from "@app/@shared/models/record/record-reminder-properties.model";
import { TranslateService } from "@ngx-translate/core";
import { max } from "lodash";

type RecordRemindersFormValue = {
  startDate: Date;
  endDate: Date;
  daysInterval: number;
  time: Date;
  content: string;
  enabled: boolean;
};

@Component({
  selector: "record-reminders-form",
  templateUrl: "./record-reminders-form.component.html",
  styleUrls: ["./record-reminders-form.component.scss"],
})
export class RecordRemindersFormComponent implements OnInit {
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReminderPropertiesChange: EventEmitter<RecordReminderPropertiesModel> =
    new EventEmitter<RecordReminderPropertiesModel>();

  // From current date
  @Input() reminderMinDate: Date = new Date();
  @Input() reminderMaxDate: Date;
  // Form defaults values
  @Input() recordReminderProperties: RecordReminderPropertiesModel = new RecordReminderPropertiesModel();
  // Interval limit
  maxInterval: number = null;

  @Input() showReminderManagementToggle: boolean = false;

  form: FormGroup;
  // View-only calendar modal
  displayRemindersCalendarModal: boolean = false;

  constructor(private formBuilder: FormBuilder, private translateService: TranslateService) {}
  ngOnInit(): void {
    this.buildForm();
    // this.onChanges();
  }

  get enabled() {
    return this.form.get("enabled").value;
  }

  // Handle expiration date updates - adapt end of reminders
  ngOnChanges(simpleChange: SimpleChanges) {
    if (
      this.form &&
      simpleChange.reminderMaxDate &&
      this.recordReminderProperties.endDate &&
      this.reminderMaxDate < this.recordReminderProperties.endDate
    ) {
      this.form.patchValue({
        endDate: this.reminderMaxDate,
      });
      // let properties = this.getFormReminderProperties(this.form.value);
      // this.onReminderPropertiesChange.emit(properties);
    }
  }
  buildForm() {
    this.form = this.formBuilder.group({
      startDate: [
        this.recordReminderProperties.startDate ?? max([this.reminderMinDate, new Date()]),
        [Validators.required],
      ],
      endDate: [this.recordReminderProperties.endDate ?? this.reminderMaxDate, [Validators.required]],
      daysInterval: [this.recordReminderProperties.interval ?? 7, [Validators.required]],
      time: [this.recordReminderProperties.time ?? new Date(new Date().setHours(12, 0)), [Validators.required]],
      content: [
        this.recordReminderProperties.content ??
          this.translateService.instant("RECORD.record-reminders-form.content.default", {
            "default": "A reminder has been set by the creator of this conversation",
          }),
        [Validators.required],
      ],
      enabled: [this.recordReminderProperties.enabled, []],
    });
  }
  // onChanges(): void {
  //   this.form.valueChanges.subscribe((values) => {
  //     this.recordReminderProperties = this.getFormReminderProperties(values);
  //   });
  // }
  submit() {
    let properties = this.getFormReminderProperties(this.form.value);
    this.onReminderPropertiesChange.emit(properties);
  }
  cancel() {
    this.buildForm();
    this.onCancel.emit();
  }

  getFormReminderProperties(values: RecordRemindersFormValue): RecordReminderPropertiesModel {
    let { startDate, endDate, daysInterval, time, content, enabled }: RecordRemindersFormValue =
      values as RecordRemindersFormValue;
    let properties = new RecordReminderPropertiesModel();
    properties.startDate = startDate;
    properties.endDate = endDate;
    properties.interval = daysInterval;
    properties.time = time;
    properties.content = content;
    properties.enabled = enabled;
    properties.dates = DateHelper.getDatesBetween(startDate, endDate, daysInterval, time);
    return properties;
  }
}
