<p-listbox
  [name]="name"
  [title]="title"
  [id]="inputId"
  [disabled]="disabled"
  [readonly]="readonly"
  [options]="options"
  [multiple]="multiple"
  [filter]="filter"
  [checkbox]="checkbox"
  [showToggleAll]="showToggleAll"
  [ngModel]="selectedValue"
  (ngModelChange)="handleChange($event)"
  (onChange)="handleChange($event)"
>
  <ng-template let-option pTemplate="item">
    <div class="flex flex-column">
      <span>{{ option.label }}</span>
      <span class="text-sm text-gray-500 clamp" *ngIf="option.description">
        {{ option.description }}
      </span>
    </div>
  </ng-template>
</p-listbox>
