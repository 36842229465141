import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Logger } from "@app/@core";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { ProviderCategoryEnum } from "@app/@shared/enums/provider-category.enum";
import { ProviderRootTypeEnum } from "@app/@shared/enums/provider-root-type.enum";
import { FileHelper } from "@app/@shared/helpers/file.helper";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import ProviderApplicationModel from "@app/@shared/models/providers/provider-application.model";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";
import ProviderRootModel from "@app/@shared/models/providers/provider-root.model";
import { ProviderApplicationsService } from "@app/@shared/services/providers/provider-applications.service";
import { ProviderFilesService } from "@app/@shared/services/providers/provider-files.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { isEmpty } from "lodash";
import { MessageService, TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  debounceTime,
  filter,
  map,
  mergeMap,
  of,
  startWith,
  switchMap,
  tap,
} from "rxjs";
import { v4 } from "uuid";

interface RootTreeItem {
  label: string;
  data: ProviderRootModel | ProviderItemModel;
  children?: any[];
  leaf: boolean;
}

const LOGGER = new Logger("Main/ProviderFilePickerComponent");

@UntilDestroy()
@Component({
  selector: "provider-file-picker",
  templateUrl: "./provider-file-picker.component.html",
  styleUrls: ["./provider-file-picker.component.scss"],
})
export class ProviderFilePickerComponent implements OnInit {
  @ViewChild(Tree) rootsTreeComponent: Tree;

  // Folder creation mode
  @Input() enableFolderCreation: boolean = false;
  @Input() foldersOnly: boolean = false; // Only list folders
  @Input() pdfOnly: boolean = false;

  @Input() submitLabel: string;
  @Input() mode: "files" | "folders" = "files"; // Folder upload not supported
  @Input() accept: string[] = null;
  @Input() includeShared: boolean;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<ProviderItemModel> = new EventEmitter();

  breadcrumbsItems$: BehaviorSubject<ProviderItemModel[]> = new BehaviorSubject([]);
  isLoadingItems$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentApplication$: BehaviorSubject<ProviderApplicationModel> = new BehaviorSubject(undefined);
  applications$: BehaviorSubject<ProviderApplicationModel[]> = new BehaviorSubject(undefined);
  items$: BehaviorSubject<ProviderItemModel[]> = new BehaviorSubject([]);
  currentItem$: BehaviorSubject<ProviderItemModel> = new BehaviorSubject(undefined);
  selectedItem$: BehaviorSubject<ProviderItemModel> = new BehaviorSubject(undefined);
  isCreatingFolder$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingApplications$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  refreshItemsToken$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  hasDeliberatelySelectedFolder$: Observable<boolean>; // only used to display appropriate button label
  roots$: BehaviorSubject<ProviderRootModel[]> = new BehaviorSubject(undefined);
  isLoadingRoots$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  rootsTree$: BehaviorSubject<RootTreeItem[]> = new BehaviorSubject([]);

  currentRoot$: BehaviorSubject<ProviderRootModel> = new BehaviorSubject(undefined);
  selectedRootItem: RootTreeItem; // Dummy variable to enable highlight
  isCreationModeEnabled: boolean = false;
  newFolderName: string;
  newFolderName$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  newFolderInvalid$: Observable<boolean>;
  onNewFolderNameChange(newName: string): void {
    this.newFolderName$.next(newName);
  }

  get rootsTree() {
    return this.rootsTree$.getValue();
  }
  get currentApplication() {
    return this.currentApplication$.getValue();
  }
  get currentRoot() {
    return this.currentRoot$.getValue();
  }
  get currentItem() {
    return this.currentItem$.getValue();
  }
  get selectedItem() {
    return this.selectedItem$.getValue();
  }
  get breadcrumbsItems() {
    return this.breadcrumbsItems$.getValue();
  }

  constructor(
    private providerApplicationsService: ProviderApplicationsService,
    private providerFilesService: ProviderFilesService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.providerApplicationsService
      .listApplications({ categories: [ProviderCategoryEnum.FILE_STORAGE], revoked: false })
      .pipe(
        tap(() => this.isLoadingApplications$.next(true)),
        catchError(() => {
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((applications) => {
        if (applications.length > 0) {
          this.currentApplication$.next(applications[0]);
        }
        this.applications$.next(applications);
        this.isLoadingApplications$.next(false);
      });

    // LIST ITEMS AGAIN BASED ON CURRENT (FOLDER, ROOT) CHANGES
    combineLatest([this.refreshItemsToken$, this.currentRoot$, this.currentItem$])
      .pipe(
        filter(([_, currentRoot, currentItem]) => Boolean(this.currentApplication) && Boolean(currentRoot)),
        debounceTime(100),
        tap((currentItem) => {
          this.isLoadingItems$.next(true);
          this.selectedItem$.next(undefined);
          if (!currentItem) this.breadcrumbsItems$.next([]);
        }),
        mergeMap(([_, currentRoot, currentItem]) => {
          return this.providerFilesService.list({
            applicationIdentifier: this.currentApplication.applicationIdentifier,
            rootType: currentRoot.type,
            itemId: currentItem?.id,
            driveId: currentItem
              ? currentItem.rootId
              : [
                  ProviderRootTypeEnum.MICROSOFT_ONEDRIVE,
                  ProviderRootTypeEnum.MICROSOFT_ONEDRIVE_SHARED_WITH_ME,
                ].includes(currentRoot.type)
              ? currentRoot.id
              : currentItem.rootId,
            foldersOnly: this.foldersOnly,
          });
        }),
        tap((items: ProviderItemModel[]) => {
          this.items$.next(items);
          if (this.mode === "folders") {
            this.selectedItem$.next(this.currentItem);
          }
        }),
        catchError((error) => {
          LOGGER.error(error);
          this.isLoadingItems$.next(false);
          return [];
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.setBreadcrumb();
        this.isLoadingItems$.next(false);
      });

    // RETRIEVE PROVIDER ROOTS
    this.currentApplication$
      .pipe(
        filter((application) => Boolean(application)),
        tap(() => this.isLoadingRoots$.next(true)),
        switchMap((application) =>
          this.providerFilesService
            .listRoots({
              applicationIdentifier: application.applicationIdentifier,
              includeShared: this.includeShared,
            })
            .pipe(
              catchError((error) => {
                // If error unknown display it
                if (error instanceof this.providerFilesService.NoRootsError == false) {
                  this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
                }
                return of(null);
              }),
              untilDestroyed(this),
            ),
        ),
        untilDestroyed(this),
      )
      .subscribe((roots) => {
        this.roots$.next(roots);
        this.isLoadingRoots$.next(false);

        if (roots && roots.length) {
          setTimeout(() => this.selectRoot(roots[0]));
        }
      });

    this.hasDeliberatelySelectedFolder$ = combineLatest([this.items$, this.selectedItem$]).pipe(
      map(([items, selectedItem]) => items.some((item) => item.id === selectedItem?.id)),
      startWith(false),
    );

    this.newFolderInvalid$ = combineLatest([this.newFolderName$, this.items$]).pipe(
      map(([newFolderName, items]) => {
        if (!newFolderName) {
          return false;
        }
        const lowercasedNames = new Set(items.map((item: ProviderItemModel) => item.name.toLowerCase()));
        return lowercasedNames.has(newFolderName.toLowerCase());
      }),
      startWith(false),
    );

    // Create roots tree depending on fetched roots
    this.roots$
      .pipe(
        filter((roots: ProviderRootModel[]) => Boolean(roots)),
        map((roots: ProviderRootModel[]) => {
          return roots.map((root: ProviderRootModel) => ({
            label: root.name,
            data: root,
            children: root.type === ProviderRootTypeEnum.MICROSOFT_SHAREPOINT_SITE ? [] : null,
            leaf: root.type === ProviderRootTypeEnum.MICROSOFT_SHAREPOINT_SITE ? false : true,
            type: "rootItem",
            iconSrc: root.iconSrc,
          }));
        }),
        untilDestroyed(this),
      )
      .subscribe(this.rootsTree$);
  }

  ngOnInit(): void {
    if (!this.submitLabel) {
      this.submitLabel = this.translateService.instant("COMMON.buttons.select", { default: "Select" });
    }
  }

  navigateToBreadcrumb(breadcrumb: ProviderItemModel, index: number) {
    const breadcrumbsItems = this.breadcrumbsItems.slice(0, index);
    this.breadcrumbsItems$.next(breadcrumbsItems);
    this.currentItem$.next(breadcrumb);
  }
  private setBreadcrumb() {
    if (this.currentItem) {
      const breadcrumbsItems: ProviderItemModel[] = [...this.breadcrumbsItems, this.currentItem];
      this.breadcrumbsItems$.next([...new Set(breadcrumbsItems)]);
    }
  }

  handleSelectApplication(application: ProviderApplicationModel) {
    this.currentApplication$.next(application);
  }

  onRootTypeParentExpand(event: any) {
    if (event.node) {
      const subscription: Subscription = this.providerFilesService
        .listRoots({
          applicationIdentifier: this.currentApplication.applicationIdentifier,
          includeShared: this.includeShared,
          siteId: event.node.data.id,
        })
        .pipe(
          map((items: ProviderRootModel[]) => {
            return items.map((item: ProviderRootModel) => ({
              label: item.name,
              data: item,
              leaf: true,
              type: "rootItem",
              iconSrc: item.iconSrc,
            }));
          }),
        )
        .subscribe((items: RootTreeItem[]) => {
          event.node.children = items;
          subscription.unsubscribe();
        });
    }
  }

  onRootTypeParentSelect(event: any) {
    if (event.node) {
      if (event.node.leaf) {
        this.currentRoot$.next(event.node.data);
        this.backToRootItem();
      } else {
        event.node.expanded = !event.node.expanded;
        if (event.node.expanded) this.onRootTypeParentExpand(event);
      }
    }
  }

  selectRoot(root: ProviderRootModel) {
    this.backToRootItem();
    this.currentRoot$.next(root);
    const selectedNode = this.rootsTree.find((i: RootTreeItem) => i.data === root);
    this.selectedRootItem = selectedNode;
  }

  backToRootItem() {
    this.currentItem$.next(undefined);
  }

  select(event: Event, item: ProviderItemModel) {
    if (item?.folder && this.mode !== "folders") {
      this.openFolder(event, item);
    } else {
      let valid = !(Boolean(this.accept) && !FileHelper.isProviderItemAccepted(item, this.accept));
      if (valid) {
        this.selectedItem$.next(item);
      } else {
        this.messageService.add({
          severity: MessageSeverityEnum.SEVERITY_INFO,
          summary: this.translateService.instant("MAIN.messages.file-not-supported.title", {
            default: "This file is not supported",
          }),
          detail: this.translateService.instant("MAIN.messages.file-not-supported.subtitle", {
            default: "Please select a file in the supported format",
            accept: this.accept,
          }),
        });
      }
    }
  }

  openFolder(event: Event, folder: ProviderItemModel) {
    event.stopPropagation();
    this.selectedItem$.next(undefined);
    this.currentItem$.next(folder);
  }

  triggerNewFolderAction() {
    this.isCreationModeEnabled = true;
  }

  submitCreationMode(event: Event) {
    if (this.newFolderName && !isEmpty(this.newFolderName)) {
      this.providerFilesService
        .createFolder({
          applicationIdentifier: this.currentApplication.applicationIdentifier,
          name: this.newFolderName,
          driveId: this.currentItem?.rootId ?? this.currentRoot?.id,
          parentItemId: this.currentItem?.id,
        })
        .pipe(
          tap(() => this.isCreatingFolder$.next(true)),
          untilDestroyed(this),
        )
        .subscribe((item) => {
          if (item) {
            this.messageService.add({
              severity: MessageSeverityEnum.SEVERITY_SUCCESS,
              summary: this.translateService.instant("MAIN.messages.folder-created.title"),
              detail: this.translateService.instant("MAIN.messages.folder-created.subtitle"),
            });
            this.cancelCreationMode(event);
            this.reloadItems();
          }
          this.isCreatingFolder$.next(false);
        });
    }
  }

  cancelCreationMode(event: Event) {
    event.stopPropagation();
    this.newFolderName = undefined;
    this.isCreationModeEnabled = false;
  }

  submit() {
    if (this.selectedItem) {
      let resource = Object.assign(new ProviderItemModel(), this.selectedItem);
      resource.applicationIdentifier = this.currentApplication.applicationIdentifier;
      this.onSubmit.emit(resource);
    } else {
      if (this.mode === "folders" && this.currentRoot?.id) {
        // Submit the virtual root item with no id for file uploads
        let resource = new ProviderItemModel();
        resource.applicationIdentifier = this.currentApplication.applicationIdentifier;
        resource.rootId = this.currentRoot.id;
        this.onSubmit.emit(resource);
      }
    }
  }
  cancel() {
    this.onCancel.emit();
  }

  reloadItems() {
    this.refreshItemsToken$.next(v4());
  }
}
