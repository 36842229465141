<div class="dialog-nested-content my-2">
  <div>{{ "RECORD.record-confirmation.record-link" | translate : { "default": "Share this link" } }}</div>
  <div class="flex flex-column gap-2 my-2 w-full">
    <textarea
      rows="4"
      readonly
      [id]="id"
      [name]="name"
      type="text"
      [value]="value"
      class="text-color w-auto"
      pInputTextarea
    ></textarea>
  </div>
</div>

<footer class="dialog-nested-footer">
  <button
    class="p-button-sm w-full sm:w-auto p-button-secondary"
    [ngClass]="{ 'p-button-success': copySuccess }"
    type="button"
    pButton
    [icon]="copySuccess ? 'pi pi-check-circle' : 'pi pi-copy'"
    (click)="doCopyToClipboard()"
    [label]="label"
    data-attr="record-link-to-clipboard"
  ></button>
</footer>
