export class ClassHelper {
  constructor() {}

  static splitClass = (value: string): string[] => {
    return value.split(".");
  };

  static normalizeClass = (...args: string[]): string => {
    return args.reduce((classes: string[], arg: string) => [...classes, arg], []).join(".");
  };
}
