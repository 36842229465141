<ng-container *ngIf="parentMessage; else emptyMessage">
  <div
    class="message message--parent gap-2"
    [attr.data-parent-message-sender]="
      sessionAPIService.currentUser && message.isSender(sessionAPIService.currentUser!.userIdentifier!)
        ? 'self'
        : 'other'
    "
  >
    <div class="message__content stack" (click)="handleClick()">
      <!-- Parent message with text -->
      <ng-container *ngIf="parentMessage.body">
        <div class="message__body flex flex-column">
          <ng-container *ngIf="!displayLoading && !isDecrypting; else loadingTemplate">
            <span class="mb-1 text-color-secondary" style="margin-left: -2px"
              >{{ parentMessage.sender! | getUserFullName }} - {{ parentMessage.createdAt | date : "short" }}
            </span>
            <span
              class="message__body__html clamp lines-3 overflow-y-hidden"
              [innerHTML]="formattedDecryptedMessage | inertHtml | safeHtml"
              *ngIf="decryptedMessage"
            >
            </span>
          </ng-container>
        </div>
      </ng-container>

      <!-- Only attachment parent message -->
      <ng-container *ngIf="!parentMessage.body && parentMessage.attachments.length">
        <div class="message__body flex flex-column">
          <span
            >{{
              sessionAPIService.currentUser && parentMessage.isSender(sessionAPIService.currentUser!.userIdentifier!)
                ? ("RECORD.record-message.you" | translate : { "default": "You" })
                : (parentMessage.sender! | getUserFullName)
            }}
          </span>
          <span class="message__body__html clamp border-left-1 px-2 font-italic flex align-items-center">
            <i class="msr-icon-attach_file icon-12px py-1"></i>
            <span>{{ "COMMON.attachment" | translate : { "default": "Attachment(s)" } }}</span>
          </span>
        </div>
      </ng-container>

      <!-- Empty parent message -->
      <ng-container *ngIf="!parentMessage.body && !parentMessage.attachments.length">
        <div class="message__body flex flex-column">
          <span
            >{{
              sessionAPIService.currentUser && parentMessage.isSender(sessionAPIService.currentUser!.userIdentifier!)
                ? ("RECORD.record-message.you" | translate : { "default": "You" })
                : (parentMessage.sender! | getUserFullName)
            }}
          </span>
          <span class="message__body__html clamp border-left-1 px-2 font-italic">{{
            "COMMON.empty-message" | translate : { "default": "No message" }
          }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #emptyMessage>
  <span class="text-gray-500">{{ "COMMON.empty-message" | translate : { "default": "No message" } }}</span>
</ng-template>

<ng-template #loadingTemplate>
  <div class="flex flex-row gap-2 align-items-center h-full">
    <ui-spinner [size]="20"></ui-spinner>
    <span class="text-gray-500">{{
      "COMPONENTS.record-message.decrypting" | translate : { "default": "Decrypting message..." }
    }}</span>
  </div>
</ng-template>
