<div class="flex align-items-center gap-5 h-full">
  <div
    *ngIf="showInlineMenu"
    class="align-self-end fixed md:static md:shadow-none md:w-auto md:block md:border-top-none bottom-0 left-0 shadow-1 w-full border-top-1 surface-border"
    [ngClass]="{ 'hidden': hideBottomBar }"
  >
    <p-tabMenu styleClass="bottom-bar-tabmenu" [model]="inlineMenuItems">
      <ng-template pTemplate="item" let-item let-i="index">
        <div class="flex flex-column align-items-center">
          <i class="inline-block opacity-80 sm:text-xl md:text-base md:hidden" [ngClass]="item.icon"></i>
          <span class="text-xs sm:text-base sm:inline-flex align-items-center"
            >{{ item.label }}
            <i
              *ngIf="item.badge"
              class="pi pi-star-fill text-xs ml-1"
              style="color: var(--enterprise-color, #1b1680)"
            ></i>
          </span>
        </div>
      </ng-template>
    </p-tabMenu>
  </div>
</div>
