import { BillingPriceRecurringAggregateUsageEnum } from "@app/@shared/enums/billing/price-recurring-aggregate-usage.enum";
import { BillingPriceRecurringIntervalEnum } from "@app/@shared/enums/billing/price-recurring-interval.enum";
import { BillingPriceRecurringUsageTypeEnum } from "@app/@shared/enums/billing/price-recurring-usage-type.enum";
import BaseModel from "@shared/models/base.model";

export default class BillingPriceRecurringModel extends BaseModel {
  // MINIMIZE (default)
  public aggregateUsage: BillingPriceRecurringAggregateUsageEnum;
  public interval: BillingPriceRecurringIntervalEnum;
  public intervalCount: number | null = null;
  public trialPeriodDays: number | null = null;
  public usageType: BillingPriceRecurringUsageTypeEnum;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }

  /**
   * Helpers
   */
  get isYearly(): boolean {
    return this.interval === BillingPriceRecurringIntervalEnum.YEAR;
  }

  get isMonthly(): boolean {
    return this.interval === BillingPriceRecurringIntervalEnum.MONTH;
  }
}
