<form (ngSubmit)="submit()">
  <div class="dialog-nested-content field" style="height: clamp(10rem, 30rem, 100vh)">
    <label for="recipients">
      {{ "RECORD.fields.recipients.label" | translate : { "default": "Recipients" } }}
    </label>
    <span class="block text-color-secondary text-sm">
      {{
        "RECORD.fields.recipients.description"
          | translate
            : { "default": "Search your address book to add a recipient to the conversation, or create a new one." }
      }}
    </span>
    <div class="flex flex-column md:flex-row align-items-stretch justify-content-between gap-3 mt-1">
      <span class="p-input-icon-left flex-grow-1">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="search"
          #searchRecipientsInput
          name="recipientSearch"
          class="p-inputtext-sm w-full h-full"
          (keydown.enter)="handleSearchRecipientKeyUp($event)"
          [(ngModel)]="searchRecipient"
          (ngModelChange)="handleSearchRecipientChange($event)"
          (focus)="handleSearchRecipientChange(searchRecipient)"
          [placeholder]="'ADDRESS-BOOK.manager.table.search.label' | translate : { 'default': 'Search' }"
        />
      </span>
      <!-- Create new contact or Import from external providers -->
      <p-splitButton
        (onClick)="displayContactCreationModal()"
        menuStyleClass="filesSplitButtonMenu"
        [model]="contactCreationItems"
        styleClass="p-button-outlined flex-shrink-0 w-full md:w-auto"
        icon="pi pi-user-plus text-lg"
        [label]="'ADDRESS-BOOK.manager.create-modal.header' | translate"
      ></p-splitButton>
    </div>
    <!-- Recipients list -->
    <new-record-recipients
      [recipients]="recipients"
      (onDisplayContactCreationModal)="displayContactCreationModal()"
      (onRecipientsChanged)="handleRecipientsChanged($event)"
    ></new-record-recipients>
  </div>

  <!-- Footer -->
  <footer class="dialog-nested-footer w-full flex flex-column gap-3 sm:flex-row align-items-center mt-5">
    <span class="text-color-secondary block text-center sm:text-left">
      {{
        recipients?.length > 0
          ? ("RECORD.recipients.count" | translate : { count: recipients?.length })
          : ("RECORD.recipients.empty-selection" | translate : { default: "No recipient selected" })
      }}
    </span>
    <button
      class="w-full sm:ml-auto sm:w-auto"
      pButton
      [disabled]="searchRecipientsPanel.render || isLoading"
      [loading]="isSubmitting"
      [label]="'COMMON.buttons.add-selection' | translate : { 'default': 'Add selection' }"
      type="submit"
    ></button>
  </footer>
</form>

<!-- OVERLAY COMPONENTS -->
<!-- Creation Modal -->
<p-dialog
  [(visible)]="showContactCreationModal"
  [modal]="true"
  position="center"
  [header]="'ADDRESS-BOOK.manager.create-modal.header' | translate : { 'default': 'Create contacts' }"
  [draggable]="false"
  [maximizable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="!isSubmitting"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="{ 'height': '100%', 'max-height': 'unset', 'width': '100%' }"
  styleClass="dialog-nested"
>
  <ng-template pTemplate="content">
    <contacts-contact-form
      [isLoading]="isCreatingContact$ | async"
      [isSubmitting]="isSubmitting"
      (onCreateContact)="handleCreateContact($event)"
      [suggestions]="allContactTagsLabels"
      (onCancel)="hideContactCreationModal()"
    ></contacts-contact-form>
  </ng-template>
</p-dialog>

<!-- Contacts Research Panel -->
<p-overlayPanel
  #searchRecipientsPanel
  styleClass="generic-overlay-panel searchRecipientsPanel"
  (onHide)="handleHideSearchRecipientsPanel()"
  [style]="themingService.customStyles$ | async"
>
  <ng-template pTemplate="content">
    <main class="searchRecipientsPanel__main" [ngClass]="{ 'noGroups': !(allContactTags?.length > 0) }">
      <!-- SEARCH > USERS -->
      <div class="searchRecipientsPanel__users">
        <header>
          {{ "RECORD.recipients.search.headers.users" | translate : { default: "Users" } }}
        </header>
        <p-listbox
          [options]="contacts$ | async"
          [multiple]="true"
          [metaKeySelection]="false"
          [checkbox]="true"
          [showToggleAll]="false"
          [readonly]="isLoadingContacts$ | async"
          [(ngModel)]="selectedContacts"
          optionLabel="emailAddress"
          [style]="themingService.customStyles$ | async"
        >
          <ng-template let-contact pTemplate="item">
            <contacts-contact-card [contact]="contact"></contacts-contact-card>
          </ng-template>
          <ng-template pTemplate="empty">
            <!-- EMPTY FILTER -->
            <ng-container *ngIf="searchRecipient">
              <div
                class="w-full flex flex-column text-color-secondary text-center justify-content-center align-items-center text-sm"
                *ngIf="!isValidRecipientEmail"
              >
                <ng-container *ngIf="!(isLoadingContacts$ | async); else loadingSpinner">
                  <i class="pi pi-user-plus text-3xl"></i>
                  <span class="mt-2">
                    {{
                      "RECORD.recipients.search.empty-filter.title"
                        | translate : { default: "We could not find an existing contact for " }
                    }}
                    <span class="font-bold text-color" *ngIf="searchRecipient">{{ searchRecipient }}</span>
                  </span>
                  <span class="mt-1">
                    {{
                      "RECORD.recipients.search.empty-filter.subtitle"
                        | translate : { default: "You can also enter a valid email" }
                    }}
                  </span>
                </ng-container>
              </div>
              <p-button
                *ngIf="isValidRecipientEmail"
                type="button"
                styleClass="text-left p-button-text w-full flex border-noround"
                [style]="{ 'background-color': 'var(--background-hover-color)' }"
                (click)="handleCreateContactFromEmail(searchRecipient)"
              >
                <ng-template pTemplate="content">
                  <div class="flex px-1 align-items-baseline">
                    <span class="font-medium text-color">
                      {{
                        "RECORD.recipients.search.buttons.create-contact"
                          | translate : { default: "Create new contact for : " }
                      }}
                      <span class="font-bold">{{ searchRecipient }}</span>
                    </span>
                  </div>
                </ng-template>
              </p-button>
            </ng-container>
            <ng-container *ngIf="!searchRecipient">
              <div class="w-full flex flex-column justify-content-center align-items-center px-2 text-center text-sm">
                <ng-container *ngIf="!(isLoadingContacts$ | async); else loadingSpinner">
                  <i class="text-color-secondary pi pi-user-plus text-3xl"></i>
                  <span class="text-color-secondary mt-2">
                    {{
                      "RECORD.recipients.search.empty-contacts.title"
                        | translate : { default: "Search for existing contacts or enter a valid email " }
                    }}
                  </span>
                </ng-container>
              </div>
            </ng-container>
          </ng-template>
        </p-listbox>
      </div>
      <!-- SEARCH > GROUPS -->
      <div class="searchRecipientsPanel__groups" *ngIf="allContactTags?.length > 0">
        <header>{{ "RECORD.recipients.search.headers.groups" | translate : { default: "Groups" } }}</header>
        <p-listbox
          [options]="filteredContactTags"
          [(ngModel)]="selectedTag"
          (ngModelChange)="searchRecipientsPanel.hide()"
          optionLabel="label"
        >
          <ng-template pTemplate="item" let-tag>
            <div class="group-item" style="border-radius: 8px">
              <ui-badge cssMaxWidth="18ch" variant="tag" size="small" [label]="tag.label"></ui-badge>
              <span class="text-sm text-color-secondary">
                {{
                  "RECORD.recipients.search.users-count"
                    | translate : { default: tag.count + " users", count: tag.count }
                }}
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="empty">
            <div class="w-full flex flex-column justify-content-center align-items-center px-2 text-sm text-center">
              <ng-container *ngIf="!(isLoadingContacts$ | async); else loadingSpinner">
                <i class="text-color-secondary pi pi-tag text-3xl"></i>
                <span class="text-color-secondary mt-2">
                  {{
                    "RECORD.recipients.search.empty-groups.title"
                      | translate : { default: "Search for existing groups of contacts" }
                  }}
                </span>
              </ng-container>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </main>
  </ng-template>
</p-overlayPanel>

<!-- Provider Contact Picker -->
<p-dialog
  [(visible)]="showProviderContactPicker"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '960px': 'var(--small-measure)', '640px': '100vw' }"
  [style]="{ 'height': '100%', 'max-height': 'unset', 'width': '100%' }"
  styleClass="dialog-nested"
  (onHide)="handleProviderContactClose()"
>
  <ng-template pTemplate="header">
    <header>
      <h3 class="my-0">
        {{ "MAIN.provider-contacts.import-from-provider" | translate : { "default": "Import from Microsoft" } }}
      </h3>
    </header>
  </ng-template>
  <provider-contact-picker
    *ngIf="showProviderContactPicker"
    class="h-full flex flex-column"
    [recipientRole]="recipientRole"
    (onSubmitProviderRecipients)="handleProviderContactSubmit($event)"
    (onCancel)="handleProviderContactClose()"
  ></provider-contact-picker>
</p-dialog>

<!-- CSV Contacts Import -->
<p-dialog
  [(visible)]="showContactImportModal"
  [modal]="true"
  position="center"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))', '2048px': 'var(--medium-measure)' }"
  [resizable]="false"
  styleClass="dialog-nested"
  [style]="{ 'height': '100%', 'max-height': 'unset' }"
  (onHide)="showContactImportModal = false"
>
  <ng-template pTemplate="header">
    <header>
      <h3 class="my-0">
        {{ "ADDRESS-BOOK.contact-import.header.title" | translate : { "default": "Schéma d’importation de contacts" } }}
      </h3>
    </header>
  </ng-template>
  <contacts-contact-import
    [displayTitle]="false"
    (onCancelContactsImport)="showContactImportModal = false"
    (onContactsImported)="handleContactsImported($event)"
  ></contacts-contact-import>
</p-dialog>
<!-- Loading Spinner -->
<ng-template #loadingSpinner>
  <div class="flex py-4 justify-content-center align-items-center">
    <ui-spinner [size]="40"></ui-spinner>
  </div>
</ng-template>
