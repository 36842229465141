import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService } from "@app/@core/loader";
import { Logger, UntilDestroy } from "@core";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { ResponseLevelEnum } from "../enums/response-level.enum";
import { environment } from "@env/environment";
import { v4 } from "uuid";
import { HttpHeadersEnum } from "../enums/http-headers.enum";
import { ContentTypesEnum } from "../enums/content-types.enum";
import { AuthorizationTypesEnum } from "../enums/authorization-types.enum";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { MessageHelper } from "../helpers/message.helper";
import { MessageSeverityEnum } from "../enums/message-severity.enum";
import { NotificationMessageModel } from "../models/notification/notification-message.model";

const log = new Logger("NotificationsAPIService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class NotificationsAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  initializeSession(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]): Observable<string> {
    const url = this._baseUrl + environment.services.methodUrls.notifications.initializeSession;
    const correlationId = v4();

    this.loaderService.addOperation("initializeSession");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["token"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("initializeSession");
        }),
      );
  }

  getUnseenNotificationsCount(
    seen: boolean = null,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<number> {
    const url = this._baseUrl + environment.services.methodUrls.notifications.getUnseenNotificationsCount;
    const correlationId = v4();

    this.loaderService.addOperation("getUnseenNotificationsCount");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          seen,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["count"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getUnseenNotificationsCount");
        }),
      );
  }

  getUnreadNotificationsCount(
    read: boolean = null,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<number> {
    const url = this._baseUrl + environment.services.methodUrls.notifications.getUnreadNotificationsCount;
    const correlationId = v4();

    this.loaderService.addOperation("getUnreadNotificationsCount");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          read,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["count"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getUnreadNotificationsCount");
        }),
      );
  }

  getNotificationsFeed(
    page: number = null,
    seen: boolean = null,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<NotificationMessageModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.notifications.getNotificationsFeed;
    const correlationId = v4();

    this.loaderService.addOperation("getNotificationsFeed");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          page,
          seen,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["feed"].map((m: any) => new NotificationMessageModel().deserialize(m));
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getNotificationsFeed");
        }),
      );
  }

  markNotificationsAs(
    params: {
      messageIds: string[];
      seen?: boolean;
      read?: boolean;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<NotificationMessageModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.notifications.markNotificationsAs;
    const correlationId = v4();

    this.loaderService.addOperation("markNotificationsAs");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["feed"].map((m: any) => new NotificationMessageModel().deserialize(m));
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("markNotificationsAs");
        }),
      );
  }
}
