import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "main-disclaimer-panel",
  templateUrl: "./disclaimer-panel.component.html",
  styleUrls: ["./disclaimer-panel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DisclaimerPanelComponent implements OnInit {
  @Input() showDisclaimer: boolean;

  constructor() {}

  ngOnInit(): void {}

  closeDisclaimer() {
    this.showDisclaimer = false;
  }
}
