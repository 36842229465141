import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import FileModel from "@shared/models/file/file.model";
import FileExportModel from "../models/file/file-export.model";
import { ContentTypesEnum } from "../enums/content-types.enum";

export type StartUploadParam = {
  fileName: string;
  fileMimeType: string;
  fileSize: number;
  convertToPdf?: boolean;
  uploadId: string;
};

export type StartUploadResult = {
  identifier: string;
  uploadId: string;
};

@Injectable({
  providedIn: "root",
})
export class FilesAPIService {
  private _baseUrl: string = environment.services.baseUrls.filesApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  initSession(): Observable<Boolean> {
    const url = this._baseUrl + environment.services.methodUrls.session.initSession;
    const correlationId = v4();

    this.loaderService.addOperation("filesInitSession");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const options = { headers: headers };

    return this.httpService.post(url, null, { headers, observe: "response" }).pipe(
      map((response: any) => {
        return true;
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("filesInitSession");
      }),
    );
  }

  recordStartUpload(
    fileName: string,
    fileMimeType: string,
    fileSize: number,
    convertToPdf: boolean = false,
  ): Observable<string> {
    const url = this._baseUrl + environment.services.methodUrls.files.recordStartUpload;
    const correlationId = v4();

    this.loaderService.addOperation("recordStartUpload");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("fileMimeType", fileMimeType);
    formData.append("fileSize", fileSize.toString());
    formData.append("convertToPdf", convertToPdf.toString()); // Temporary force conversion

    return this.httpService.post(url, formData, { headers, observe: "response" }).pipe(
      map((response: any) => {
        const fileIdentifier: string = response.headers.get(HttpHeadersEnum.FILE_IDENTIFIER);
        return fileIdentifier;
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordStartUpload");
      }),
    );
  }

  recordStartUploads(files: StartUploadParam[]): Observable<StartUploadResult[]> {
    const url = this._baseUrl + environment.services.methodUrls.files.recordStartUploads;
    const correlationId = v4();

    this.loaderService.addOperation("recordStartUploads");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          files,
        },
        { headers, observe: "response" },
      )
      .pipe(
        map((response: HttpResponse<StartUploadResult[]>) => {
          return response.body;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("recordStartUploads");
        }),
      );
  }

  recordFinishUpload(
    fileIdentifier: string,
    recordIdentifier: string,
    fileHash: string,
    chunkHashes: string[] = [],
  ): Observable<FileModel> {
    const url = this._baseUrl + environment.services.methodUrls.files.recordFinishUpload;
    const correlationId = v4();

    this.loaderService.addOperation("recordFinishUpload");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("fileIdentifier", fileIdentifier);
    formData.append("recordIdentifier", recordIdentifier);
    formData.append("fileHash", fileHash);
    chunkHashes.forEach((i) => {
      formData.append("chunkHashes[]", i);
    });

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return new FileModel().deserialize(response);
      }),
      // catchError((e) => {
      //   this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
      //   return throwError(() => e);
      // }),
      finalize(() => {
        this.loaderService.removeOperation("recordFinishUpload");
      }),
    );
  }

  recordDownloadFile(file: FileModel, recordIdentifier: string): Observable<Blob> {
    this.loaderService.addOperation("recordDownloadFile");

    const headers = new HttpHeaders().set(
      HttpHeadersEnum.AUTHORIZATION,
      AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken(),
    );

    const recordUrl = new URL(
      this._baseUrl +
        environment.services.methodUrls.files.recordDownload.replace("{fileIdentifier}", file.fileIdentifier),
    );
    recordUrl.searchParams.append("recordIdentifier", recordIdentifier);

    return this.httpService.get(recordUrl.href, { headers, responseType: "blob" }).pipe(
      map((response: any) => {
        return new File([response], file.name, {
          type: file.mimeType,
        });
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordDownloadFile");
      }),
    );
  }

  recordCreateExport(fileIdentifiers: string[], recordIdentifier: string): Observable<FileExportModel> {
    const url = this._baseUrl + environment.services.methodUrls.files.recordCreateExport;
    this.loaderService.addOperation("recordCreateExport");

    const headers = new HttpHeaders().set(
      HttpHeadersEnum.AUTHORIZATION,
      AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken(),
    );

    const formData = new FormData();
    fileIdentifiers.forEach((i) => {
      formData.append("fileIdentifiers[]", i);
    });
    formData.append("recordIdentifier", recordIdentifier);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return new FileExportModel().deserialize(response);
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordCreateExport");
      }),
    );
  }

  recordDownloadExport(fileExport: FileExportModel, filename?: string) {
    this.loaderService.addOperation("recordDownloadExport");

    const headers = new HttpHeaders().set(
      HttpHeadersEnum.AUTHORIZATION,
      AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken(),
    );

    const recordUrl = new URL(
      this._baseUrl +
        environment.services.methodUrls.files.recordDownloadExport.replace(
          "{exportIdentifier}",
          fileExport.exportIdentifier,
        ),
    );
    if (filename) recordUrl.searchParams.append("filename", filename);

    return this.httpService.get(recordUrl.href, { headers, responseType: "blob" }).pipe(
      map((response: any) => {
        return new File([response], filename, {
          type: "application/zip",
        });
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("recordDownloadExport");
      }),
    );
  }
}
