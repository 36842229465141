import { AbstractControl, ValidationErrors } from "@angular/forms";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";

export function recipientsNotEmptyValidator(control: AbstractControl): ValidationErrors | null {
  let recipients: RecordRecipientModel[] = control.value;

  let recipientsEmpty =
    recipients.filter((recipient) => {
      return [RecordRoleEnum.WRITER, RecordRoleEnum.OWNER, RecordRoleEnum.REVIEWER].includes(recipient.role);
    }).length === 0;

  if (recipientsEmpty) {
    return { recipientsEmpty: true };
  }

  return null;
}
