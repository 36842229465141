import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { Logger } from "@app/@core";
import { v4 as v4 } from "uuid";
import { isEmpty } from "lodash";
import FileModel from "../models/file/file.model";
import ProviderItemModel from "../models/providers/provider-item.model";

const log = new Logger("CloudAPIService");

@Injectable({
  providedIn: "root",
})
export class CloudAPIService {
  private _baseUrl: string = environment.services.baseUrls.filesApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getFolderContent(
    path: string,
    directoriesOnly: boolean = false,
    pdfOnly: boolean = false,
  ): Observable<ProviderItemModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.cloud.getFolderContent;
    const correlationId = v4();

    this.loaderService.addOperation("getFolderContent");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("path", path);
    formData.append("directoriesOnly", directoriesOnly.toString());
    formData.append("pdfOnly", pdfOnly.toString());

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return response["resources"].map((e: any) => new ProviderItemModel().deserialize(e));
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("getFolderContent");
      }),
    );
  }

  createFolder(path: string, folderName: string): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.cloud.createFolder;
    const correlationId = v4();

    this.loaderService.addOperation("createFolder");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("path", path);
    formData.append("folderName", folderName);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return response["done"];
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("createFolder");
      }),
    );
  }

  search(path: string, query: string): Observable<ProviderItemModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.cloud.search;
    const correlationId = v4();

    this.loaderService.addOperation("search");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("path", path);
    formData.append("query", query);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return response["resources"].map((e: any) => new ProviderItemModel().deserialize(e));
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("search");
      }),
    );
  }

  uploadInternal(fileIdentifier: string, recordIdentifier: string, folder?: string): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.cloud.internal.upload;
    const correlationId = v4();

    this.loaderService.addOperation("uploadInternal");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("fileIdentifier", fileIdentifier);
    formData.append("recordIdentifier", recordIdentifier);
    if (folder && !isEmpty(folder)) formData.append("folder", folder);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return response["done"];
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("uploadInternal");
      }),
    );
  }

  downloadInternal(path: string, recordIdentifier: string): Observable<FileModel> {
    const url = this._baseUrl + environment.services.methodUrls.cloud.internal.download;
    const correlationId = v4();

    this.loaderService.addOperation("downloadInternal");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("path", path);
    formData.append("recordIdentifier", recordIdentifier);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return new FileModel().deserialize(response);
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("downloadInternal");
      }),
    );
  }

  uploadExportInternal(
    exportIdentifier: string,
    recordIdentifier: string,
    folderName: string,
    folder?: string,
  ): Observable<boolean> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.cloud.internal.uploadExport.replace("{exportIdentifier}", exportIdentifier);
    const correlationId = v4();

    this.loaderService.addOperation("uploadExportInternal");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.APPLICATION, environment.application)
      .set(HttpHeadersEnum.CORRELATION_ID, correlationId)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    const formData = new FormData();
    formData.append("exportIdentifier", exportIdentifier);
    formData.append("recordIdentifier", recordIdentifier);
    if (folderName && !isEmpty(folderName)) formData.append("folderName", folderName);
    if (folder && !isEmpty(folder)) formData.append("folder", folder);

    return this.httpService.post(url, formData, { headers }).pipe(
      map((response: any) => {
        return response["done"];
      }),
      catchError((e) => {
        this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
        return throwError(() => e);
      }),
      finalize(() => {
        this.loaderService.removeOperation("uploadExportInternal");
      }),
    );
  }
}
