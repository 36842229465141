import { ProviderRootTypeEnum } from "@app/@shared/enums/provider-root-type.enum";
import BaseModel from "../base.model";

export default class ProviderRootModel extends BaseModel {
  public id: string | null = null;
  public name: string | null = null;
  public type: ProviderRootTypeEnum | null = null;
  public isShared: boolean | null = null;

  constructor() {
    super();
  }

  get iconSrc() {
    switch (this.type) {
      case ProviderRootTypeEnum.MICROSOFT_ONEDRIVE:
      case ProviderRootTypeEnum.MICROSOFT_ONEDRIVE_SHARED_WITH_ME:
        return "assets/images/providers/onedrive.svg";
      case ProviderRootTypeEnum.MICROSOFT_SHAREPOINT_SITE:
        return "assets/images/providers/sharepoint.svg";
      default:
        return null;
    }
  }
}
