import { kebabCase } from "lodash";
import { v4 as v4 } from "uuid";
import FormDataModel from "../form-data.model";
import DataTypeModel from "../masterdata/data-type.model";

export default class RecordMessageFormItemModel extends FormDataModel {
  // DEFAULT (default)
  public itemIdentifier: string = null;
  public itemClass: string = "";
  public label: string = null;
  public description: string = null;
  public isValid: boolean = null;
  public position: number = null;

  // UI usage only
  public _uniqueId: string;

  constructor() {
    super();
  }

  static fromDataType(dataType: DataTypeModel): RecordMessageFormItemModel {
    let formItem = new RecordMessageFormItemModel();
    formItem.label = dataType.label.slice();
    formItem.description = dataType.description.slice();
    formItem.itemClass = dataType.dataTypeClass.slice();
    formItem.format = dataType.format;
    formItem.properties = { ...dataType.properties };
    return formItem;
  }

  static fromFormItem(source: RecordMessageFormItemModel): RecordMessageFormItemModel {
    let formItem = new RecordMessageFormItemModel();
    formItem.label = source.label.slice();
    formItem.description = source.description?.slice();
    formItem.itemClass = source.itemClass.slice();
    formItem.format = source.format;
    formItem.position = source.position;
    formItem.properties = { ...source.properties };
    return formItem;
  }

  get uniqueId() {
    if (this.properties && this.properties["uniqueId"]) {
      return this.properties["uniqueId"].toString();
    } else if (this._uniqueId) {
      return this._uniqueId;
    }

    return this.generateId();
  }

  generateId() {
    this._uniqueId = kebabCase(this.label) + "-" + v4();

    if (this.properties) this.properties["uniqueId"] = this._uniqueId;

    return this._uniqueId;
  }
}
