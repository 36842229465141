import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import { NotificationMessageModel } from "@app/@shared/models/notification/notification-message.model";
import { ThemingService } from "@app/@shared/services/theming.service";
import { NotificationsService } from "@app/notifications/services/notifications.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { orderBy } from "lodash";
import { MessageService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { Observable, map } from "rxjs";

@UntilDestroy()
@Component({
  selector: "notifications-menu",
  templateUrl: "./notifications-menu.component.html",
  styleUrls: ["./notifications-menu.component.scss"],
})
export class NotificationsMenuComponent {
  @ViewChild(OverlayPanel) notificationsPanel: OverlayPanel;

  isLoading$: Observable<boolean>;
  items$: Observable<NotificationMessageModel[]>;
  unreadCount$: Observable<number>;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    public themingService: ThemingService,
  ) {
    this.isLoading$ = this.notificationsService.isLoadingMenu$;
    this.unreadCount$ = this.notificationsService.unreadCount$;
    this.items$ = this.notificationsService.menuNotifications$.pipe(
      map((items: NotificationMessageModel[]) =>
        orderBy(
          items,
          [(item: NotificationMessageModel) => item.read, (item: NotificationMessageModel) => item.createdAt],
          ["asc", "desc"],
        ),
      ),
    );

    this.notificationsService.onUnreadCountChanged().subscribe(() => {});
    this.notificationsService.onNotificationReceived().subscribe((message: NotificationMessageModel) => {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_NOTIFICATION,
          null,
          this.translateService.instant("NOTIFICATIONS.messages.new-notification.body", {
            content: message.content,
          }),
          {
            notificationMessage: message,
          },
          undefined,
          5000,
        ),
      );
    });
  }

  ngOnInit() {}

  handleClick(event: any) {
    if (this.notificationsPanel) {
      this.notificationsPanel.toggle(event);
    }
  }

  handleOnShow(event: any) {
    this.notificationsService
      .getMenuNotifications()
      .pipe(untilDestroyed(this))
      .subscribe(() => {});
  }

  handleItemClick(event: any) {
    if (this.notificationsPanel) {
      this.notificationsPanel.hide();
    }
  }

  handleMarkAllAsRead(event: any) {
    this.notificationsService
      .markMenuNotificationsAs(true, true)
      .pipe(untilDestroyed(this))
      .subscribe(() => {});
  }

  handleViewAllClick(event: any) {
    if (this.notificationsPanel) {
      this.notificationsPanel.hide();
    }
    this.router.navigate(["/notifications"]);
  }
}
