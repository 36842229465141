<div class="flex flex-column h-full">
  <form [formGroup]="form" class="w-full h-full">
    <ng-container [ngSwitch]="step">
      <ng-container *ngSwitchCase="'recipients'" [ngTemplateOutlet]="recipientsTemplate"></ng-container>
      <ng-container *ngSwitchDefault [ngTemplateOutlet]="contentTemplate"></ng-container>
    </ng-container>
  </form>

  <!-- Content template -->
  <ng-template #contentTemplate [formGroup]="form">
    <section class="flex flex-column h-full">
      <div class="dialog-nested-content flex flex-column">
        <div class="field">
          <label for="signUpload"
            ><span>{{
              "RECORD.record-signature.upload-sign-dropzone.label" | translate : { "default": "Documents to sign" }
            }}</span>
            <i
              class="pi pi-lock text-sm ml-1"
              [tooltip]="
                'COMPONENTS.file-upload.sub-text' | translate : { 'default': 'Files are server-side encrypted' }
              "
            ></i>
          </label>
          <main-files-upload
            id="signUpload"
            [enableFoldersUpload]="false"
            [displayOtherProviders]="true"
            (onFilesAdded)="handleSignatureFilesAdded($event)"
            (onUploadRemove)="handleUploadRemove($event)"
            [uploads]="signatureUploads"
            [cloudFiles]="cloudFiles"
            [signatureUploadIds]="signatureUploadIds"
            [signatureResources]="signatureResources"
            (onSignatureUploadIdsChange)="signatureUploadIds = $event"
            (onSignatureResourcesChange)="signatureResources = $event"
            (onCloudFileRemove)="handleCloudFileRemove($event)"
            (onImportFromCloudClick)="handleImportFromCloudClick()"
            (onImportFromProviderClick)="displayImportFromProviderModal = true"
            [accept]="authorizedFileTypes"
            [maxFileSize]="45 * 1024 * 1024"
            [title]="
              'RECORD.record-signature.upload-sign-dropzone.title'
                | translate : { 'default': 'Click here to add a file for sign, or drag it' }
            "
          ></main-files-upload>
          <div class="mt-3 text-sm">
            <i class="pi pi-info-circle text-sm"></i>
            {{
              "RECORD.record-signature.upload-sign-dropzone.limit" | translate : { "default": "PDF only, 45Mo max." }
            }}
          </div>
        </div>

        <!-- Toggle signature message field -->
        <div class="field-checkbox mb-0 pb-3 align-items-start" style="border-color: var(--border-color)">
          <p-checkbox
            type="checkbox"
            inputId="signatureMessageToggle"
            name="signatureMessageToggle"
            [(ngModel)]="enableSignatureMessage"
            [binary]="true"
            [ngModelOptions]="{ standalone: true }"
          ></p-checkbox>
          <!-- <div class="flex justify-content-between gap-5 flex-wrap align-items-center"> -->
          <label class="cursor-pointer" for="signatureMessageToggle">
            <span>
              {{
                "RECORD.fields.signature-message-toggle.label"
                  | translate : { "default": "Add a text message to the signature" }
              }}
            </span>
          </label>
        </div>
        <!-- Reminder settings form -->
        <ng-container *ngIf="enableSignatureMessage">
          <div class="field pl-4">
            <label for="body"
              ><span>{{ "RECORD.fields.message.label" | translate : { "default": "Message" } }}</span>
              <span class="text-color text-sm">
                {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
              >
              <i
                class="pi pi-lock text-sm ml-1"
                [tooltip]="
                  'RECORD.fields.message.tooltip' | translate : { default: 'This message is client-side encrypted.' }
                "
              ></i
            ></label>
            <div class="message-editor">
              <div class="message-editor__input">
                <!-- Label only visible to screen readers -->
                <label for="body" class="sr-only" translate>{{
                  "RECORD.record-message-form.fields.message-body.label" | translate : { "default": "Message" }
                }}</label>
                <editor-message-editor
                  #messageEditor
                  id="body"
                  formControlName="messageBody"
                  [placeholder]="
                    'RECORD.fields.message.placeholder.basic' | translate : { 'default': 'Write your message here' }
                  "
                  [style]="{ 'min-height': '10rem' }"
                  (onContentChanged)="handleContentChanged($event)"
                  (onSelectionChanged)="handleSelectionChanged($event)"
                  (onSubmitHotkeys)="handleSubmitHotkeys()"
                ></editor-message-editor>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="dialog-nested-footer w-full flex flex-wrap justify-content-end z-2 mt-auto">
        <button
          class="p-button p-button-sm"
          pButton
          icon="pi pi-arrow-right"
          iconPos="right"
          [label]="'COMMON.buttons.continue' | translate : { 'default': 'Continue' }"
          type="submit"
          (click)="submitContent()"
        ></button>
      </div>
    </section>
  </ng-template>

  <!-- Recipients template -->
  <ng-template #recipientsTemplate [formGroup]="form">
    <section
      class="flex flex-column h-full p-3"
      style="
        --table-border-width: 1px;
        --table-border-radius: var(--border-radius);
        --table-border-color: var(--border-color);
      "
    >
      <p class="font-medium mt-0 mb-2">{{ "RECORD.record-signature.signers-selection.label" | translate }}</p>
      <record-recipients-selector
        [isSubmitting]="isSubmitting"
        (onRecipientsChanged)="handleRecipientsChanged($event)"
      ></record-recipients-selector>
    </section>
    <footer class="dialog-nested-footer w-full flex flex-wrap justify-content-between gap-3 mt-auto">
      <button
        pButton
        class="p-button-outlined p-button-sm"
        type="button"
        icon="pi pi-arrow-left"
        [label]="'COMMON.buttons.back' | translate : { 'default': 'Back' }"
        (click)="cancelRecipients()"
        [disabled]="isSubmitting"
      ></button>
      <button
        class="p-button p-button-sm"
        pButton
        icon="msr-icon-draw text-lg"
        iconPos="right"
        [label]="'COMMON.buttons.invite-to-sign' | translate : { 'default': 'Invite to sign' }"
        type="button"
        [loading]="isSubmitting"
        (click)="submitRecipients()"
        [disabled]="form.invalid"
      ></button>
    </footer>
  </ng-template>
</div>

<!-- Dialog import from cloud -->
<p-dialog
  [(visible)]="displayImportFromCloudModal"
  [modal]="true"
  position="center"
  [header]="'MAIN.import-from-cloud.title' | translate : { 'default': 'Select a file or directory to import' }"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="{ width: 'var(--xsmall-measure)' }"
  styleClass="dialog-nested"
  (onHide)="handleImportFromCloudClose()"
>
  <ng-template pTemplate="content">
    <main-cloud-file-picker
      [accept]="authorizedFileTypes"
      [pdfOnly]="true"
      (onSubmit)="handleImportFromCloudSubmitted($event)"
      (onCancel)="handleImportFromCloudClose()"
    ></main-cloud-file-picker>
  </ng-template>
</p-dialog>

<!-- PROVIDER FILE PICKER -->
<p-dialog
  [(visible)]="displayImportFromProviderModal"
  appendTo="body"
  [modal]="true"
  position="center"
  [header]="'MAIN.import-from-provider.title' | translate : { 'default': 'Select a file or directory to import' }"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  [style]="{ 'max-width': 'var(--large-measure)', 'width': 'calc(100vw - var(--space-xs, 2rem))' }"
  [style]="customDialogStyles$ | async"
  styleClass="dialog-nested"
  (onHide)="handleImportFromProviderClose()"
>
  <ng-template pTemplate="content">
    <provider-file-picker
      [accept]="authorizedFileTypes"
      [pdfOnly]="true"
      (onSubmit)="handleImportFromProviderSubmitted($event)"
      (onCancel)="handleImportFromProviderClose()"
    ></provider-file-picker>
  </ng-template>
</p-dialog>
