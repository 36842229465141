import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import { DateHelper } from "@app/@shared/helpers/date.helper";
import RecordModel from "@app/@shared/models/record/record.model";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { RecordAPIService } from "@app/@shared/services/record-api.service";
import { RecordAuthorizationService } from "@app/@shared/services/record-authorization.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import { RecordService } from "@app/record/services/record.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { Calendar } from "primeng/calendar";

type RecordUpdateRecordValue = {
  title: string;
  expirationDate: Date;
  remindBeforeExpirationDate: boolean;
};

@UntilDestroy()
@Component({
  selector: "record-update-record",
  templateUrl: "./record-update-record.component.html",
  styleUrls: ["./record-update-record.component.scss"],
})
export class RecordUpdateRecordComponent implements OnInit {
  _record: RecordModel;

  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  @ViewChild("dueDateCalendar") public dueCalendar: Calendar;
  @ViewChild("expirationCalendar") public expirationCalendar: Calendar;

  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  minExpirationDate: Date = DateHelper.endOfDay(DateHelper.addDays(new Date(), +1));
  // Expiration date
  maxExpirationDate: Date = null;
  enableExpirationReminder: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private recordService: RecordService,
    private recordAuthorizationService: RecordAuthorizationService,
    private authorizationService: AuthorizationService,
    public themingService: ThemingService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}
  ngOnInit(): void {
    this.buildForm();
    this.authorizationService.initialized$.pipe(untilDestroyed(this)).subscribe(() => {
      this.maxExpirationDate = DateHelper.addDays(new Date(), +this.authorizationService.getMaxExpirationPeriod());
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      title: [this.record.title, [Validators.required]],
      expirationDate: [this.record.expirationDate, [Validators.required]],
      remindBeforeExpirationDate: [this.record.reminderProperties.remindBeforeExpirationDate, []],
    });
  }

  get isOwner() {
    return this.recordAuthorizationService.isOwner(this.record);
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      let { title, expirationDate, remindBeforeExpirationDate }: RecordUpdateRecordValue = this.form
        .value as RecordUpdateRecordValue;

      expirationDate = DateHelper.forceUtc(expirationDate);
      this._record.expirationDate = expirationDate;

      let { reminderProperties } = this._record;
      reminderProperties.remindBeforeExpirationDate = remindBeforeExpirationDate;

      if (remindBeforeExpirationDate) {
        reminderProperties.expirationReminderDates = [DateHelper.addDays(this._record.expirationDate, -3)];
      }

      this.recordService
        .updateRecordAttributes({ title, reminderProperties, expirationDate }, [ResponseLevelEnum.ALL])
        .subscribe((record) => {
          this._record = record;
          this.onCancel.emit();
          window.location.reload();
        });
    }
  }

  cancel() {
    this.onCancel.emit();
  }
}
