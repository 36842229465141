import { Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import RecordModel from "@app/@shared/models/record/record.model";
import SessionModel from "@app/@shared/models/session/session.model";
import { BehaviorSubject, combineLatest, filter, Observable } from "rxjs";
import { SessionAPIService } from "../session-api.service";
import { DomainPropertiesService } from "./domain-properties.service";
import { UnitPropertiesService } from "./unit-properties.service";

const log = new Logger("RecordPropertiesService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class RecordPropertiesService {
  // Initialized observable
  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  initialized$: Observable<boolean> = this._initialized$.asObservable();

  get initialized() {
    return this._initialized$.getValue();
  }

  constructor(private unitPropertiesService: UnitPropertiesService) {
    // Initialize watching session to set _currentUserUnit$ value
    this.unitPropertiesService.initialized$
      .pipe(
        filter((uInitialized) => Boolean(uInitialized)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this._initialized$.next(true);
      });
  }

  convertConversationAnswerFilesToPDF(record: RecordModel) {
    return (
      this.unitPropertiesService.convertConversationAnswerFilesToPDF() || record.convertConversationAnswerFilesToPDF
    );
  }
}
