import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@core";
import DataTypeModel from "@app/@shared/models/masterdata/data-type.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { FormItemPropertiesFormValue } from "../form-item-properties-form/form-item-properties-form.component";
import { I18nService } from "@app/@shared/i18n/i18n.service";

@UntilDestroy()
@Component({
  selector: "record-data-type-selector",
  templateUrl: "./data-type-selector.component.html",
  styleUrls: ["./data-type-selector.component.scss"],
})
export class RecordDataTypeSelectorComponent implements OnInit {
  @Input() dataTypes: DataTypeModel[] = [];

  @Output() onSubmit: EventEmitter<RecordMessageFormItemModel> = new EventEmitter<RecordMessageFormItemModel>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  @Input() recordTitle: string; // needed for providing examples of file renaming patterns
  @Input() formItem: RecordMessageFormItemModel;
  @Input() formItemEditionView: boolean = false; // determines if editing an existing item
  formValueChangesSubscription$: Subscription;
  formItemPreview: RecordMessageFormItemModel;
  previewForm: UntypedFormGroup;
  selectedDataType: DataTypeModel;

  constructor(private formBuilder: UntypedFormBuilder, private i18nService: I18nService) {}

  ngOnInit(): void {
    this.buildPreviewForm();
  }

  get originalDescription(): string {
    if (!this.formItem || !(this.dataTypes.length > 0)) return null;
    let dataType = this.dataTypes.find((datatype) => this.formItem?.itemClass === datatype.dataTypeClass);
    return dataType ? dataType.getDescription(this.i18nService.language) : null;
  }
  get originalLabel(): string {
    if (!this.formItem || !(this.dataTypes.length > 0)) return null;
    let dataType = this.dataTypes.find((datatype) => this.formItem?.itemClass === datatype.dataTypeClass);
    return dataType ? dataType.getLabel(this.i18nService.language) : null;
  }

  buildForm() {
    this.formItem = RecordMessageFormItemModel.fromDataType(this.selectedDataType);
    this.formItem.properties["uniqueId"] = this.formItem.uniqueId;

    this.form = this.formBuilder.group({
      label: [this.formItem.label, [Validators.required]],
      description: [this.formItem.description, []],
    });

    if (this.formValueChangesSubscription$) {
      this.formValueChangesSubscription$.unsubscribe();
    }

    this.formValueChangesSubscription$ = this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const { label, description } = value;
      this.formItem.label = label.trim();
      this.formItem.description = description.trim();
    });
  }

  buildPreviewForm() {
    this.previewForm = this.formBuilder.group({
      preview: this.formBuilder.group({}),
    });
  }

  handleDataTypeSelect(dataType: DataTypeModel) {
    this.selectedDataType = dataType;

    this.buildForm();
  }

  cancel() {
    this.selectedDataType = undefined;
    this.onCancel.emit();
  }

  // Attach properties to the form Item
  handleFormItemPropertiesSubmit(value: FormItemPropertiesFormValue) {
    let newFormItem = RecordMessageFormItemModel.fromFormItem(this.formItem);
    newFormItem.label = value.label;
    newFormItem.description = value.description;
    newFormItem.properties = { ...newFormItem.properties, ...value.properties };

    this.onSubmit.emit(newFormItem);
  }
}
