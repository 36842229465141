import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import { isEmpty } from "lodash";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "new-record-recipients",
  templateUrl: "./new-record-recipients.component.html",
  styleUrls: ["./new-record-recipients.component.scss"],
})
export class NewRecordRecipientsComponent {
  @ViewChild("searchRecipientsInput") searchRecipientsInput: ElementRef<HTMLInputElement>;

  showRecipientEditModal: boolean = false;
  selectedRecipient: RecordRecipientModel;

  @Input() recipients: RecordRecipientModel[] = null;
  @Input() readonlyEmail: boolean = false;
  @Input() isSelectingSigners: boolean = false;
  @Input() displaySignatureIndicator: boolean = false;
  @Input() markAsInvalid: boolean = false;
  @Input() signatureRecipientEmails: string[] = [];

  searchRecipient: string;
  searchRecipient$: BehaviorSubject<string> = new BehaviorSubject("");

  @Output() onRecipientsChanged: EventEmitter<RecordRecipientModel[]> = new EventEmitter();
  @Output() onRecipientCreated: EventEmitter<RecordRecipientModel> = new EventEmitter();
  @Output() onSignatureRecipientsEmailsChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() onDisplayContactCreationModal: EventEmitter<any> = new EventEmitter();
  constructor() {}

  get filteredRecipients(): RecordRecipientModel[] {
    let searchText = this.searchRecipientsInput?.nativeElement.value;
    if (!isEmpty(searchText)) {
      let filteredRecipients = this.recipients.filter(({ emailAddress, firstName, lastName }) =>
        [emailAddress, firstName, lastName].some(
          (field) => !isEmpty(field) && field.toLowerCase().indexOf(searchText) !== -1,
        ),
      );
      return filteredRecipients;
    } else {
      return [...this.recipients];
    }
  }

  get isValidRecipientEmail() {
    return StringHelper.isEmail(this.searchRecipient);
  }

  handleSearchRecipient(event: Event) {
    event.preventDefault();
    if (this.isValidRecipientEmail) {
      let recipient = Object.assign(new RecordRecipientModel(), { emailAddress: this.searchRecipient });
      this.onRecipientCreated.emit(recipient);
      // this.handleEditRecipient(recipient);
      this.searchRecipient = null;
      this.searchRecipientsInput.nativeElement.blur();
    }
  }

  handleRemoveRecipient(recipient: RecordRecipientModel) {
    let recipients = this.recipients.filter((r: RecordRecipientModel) => r !== recipient);
    this.onRecipientsChanged.emit(recipients);
  }

  handleEditRecipient(recipient: RecordRecipientModel) {
    this.selectedRecipient = recipient;
    this.showRecipientEditModal = true;
  }

  handleSaveRecipient(recipient: RecordRecipientModel) {
    if (this.recipients.indexOf(this.selectedRecipient) > -1) {
      this.recipients[this.recipients.indexOf(this.selectedRecipient)] = recipient;
    } else {
      this.recipients.push(recipient);
    }
    this.selectedRecipient = null;
    this.showRecipientEditModal = false;
  }

  displayContactCreationModal() {
    this.onDisplayContactCreationModal.emit();
  }

  handleEditRecipientClose() {
    this.showRecipientEditModal = false;
    this.selectedRecipient = undefined;
  }

  handleSignatureRecipientChange(emails: string[]) {
    let allRecipientsEmails = [...new Set(this.recipients.map((r) => r.emailAddress))];
    let signersEmails = emails.filter((signerEmail) => allRecipientsEmails.includes(signerEmail));
    this.onSignatureRecipientsEmailsChanged.emit(signersEmails);
  }
}
