import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { v4 as v4 } from "uuid";

@Component({
  selector: "record-form-item-format-masked-text",
  templateUrl: "./item-format-masked-text.component.html",
  styleUrls: ["./item-format-masked-text.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatMaskedTextComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatMaskedTextComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Mask options
  @Input() mask: string = null;
  @Input() characterPattern: string = "[A-Za-z]";
  @Input() slotChar: string = "_";
  @Input() autoClear: boolean = true;
  @Input() unmask: boolean = false;
  @Input() maxLength: number;
  @Input() showClear: boolean = false;

  // HTML5
  @Input() type: string = "text";
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() placeholder: string = "";
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text
  @Output() onComplete: EventEmitter<any> = new EventEmitter<any>(); // Only for input mask (onComplete event)

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    // Retrieve placeholder
    if (this.formItem.getPlaceholders()) {
      let placeholder = this.formItem.getPlaceholder(this.i18nService.language);
      placeholder && (this.placeholder = placeholder);
    }

    // Retrieve formatters
    if (this.formItem.getFormatters()) {
      // Mask formatter
      let maskFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MASK);
      if (maskFormatter && maskFormatter.value) {
        this.mask = maskFormatter.value.toString();
      }

      // Mask character pattern formatter
      let maskCharacterPatternFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MASK_CHARACTER_PATTERN);
      if (maskCharacterPatternFormatter && maskCharacterPatternFormatter.value) {
        this.characterPattern = maskCharacterPatternFormatter.value.toString();
      }

      // Max length formatter
      let maxLengthFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MAX_LENGTH);
      if (maxLengthFormatter && maxLengthFormatter.value) {
        let maxLength = Number.parseInt(maxLengthFormatter.value.toString());
        this.maxLength = maxLength;
      }
    }
  }

  handleChange(value) {
    this.onModelTouched();
    this.onModelChange(value);
    this.writeValue(value);
    this.onChange.emit(value);
  }
}
