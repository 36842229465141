import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { RecordService } from "@app/record/services/record.service";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import { BehaviorSubject, Observable, map, mergeMap, tap } from "rxjs";
import { FilesAPIService } from "@app/@shared/services/files-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import FileExportModel from "@app/@shared/models/file/file-export.model";
import { CloudAPIService } from "@app/@shared/services/cloud-api.service";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { MenuItem, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";
import { ProviderItemTypeEnum } from "@app/@shared/enums/provider-item-type.enum";

@UntilDestroy()
@Component({
  selector: "record-all-files",
  templateUrl: "./record-all-files.component.html",
  styleUrls: ["./record-all-files.component.scss"],
})
export class RecordAllFilesComponent implements OnInit, OnDestroy {
  attachments$: BehaviorSubject<RecordMessageAttachmentModel[]> = new BehaviorSubject([]);
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  selectedAttachments: RecordMessageAttachmentModel[] = [];

  displayCloudFolderPickerModal: boolean = false;
  displayProviderFolderPickerModal: boolean = false;
  isUploading: boolean = false;
  isDownloading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  downloadButtonItems: MenuItem[];
  get attachments(): RecordMessageAttachmentModel[] {
    return [...this.attachments$.getValue()].reverse();
  }
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  cancel() {
    this.onCancel.emit();
  }
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    private recordService: RecordService,
    private filesAPIService: FilesAPIService,
    private cloudAPIService: CloudAPIService,
    public authorizationService: AuthorizationService,
    public themingService: ThemingService,
  ) {
    this.recordService.record$.subscribe(this.record$);
    this.recordService.attachments$.subscribe((attachments) => {
      this.attachments$.next(attachments);
      this.selectedAttachments = attachments;
    });
  }

  ngOnInit(): void {
    this.setDownloadButtonMenuItems();
  }
  setDownloadButtonMenuItems() {
    this.downloadButtonItems = [
      {
        label: this.translateService.instant("FILES.actions.download", { default: "Download" }),
        command: () => this.handleDownloadSelection(),
        disabled: this.isDownloading$.getValue(),
        icon: "pi pi-download",
      },
      {
        label: this.translateService.instant("FILES.actions.export-to-cloud", { default: "Export to drive" }),
        command: () => this.handleExportToCloudClick(),
        visible: this.authorizationService.canAccessNestorDrive(),
        disabled: this.isDownloading$.getValue(),
        icon: "pi pi-cloud-upload",
      },
      // FIXME: Activate once export handled
      // {
      //   label: this.translateService.instant("FILES.actions.export-to-provider", { default: "Export to Onedrive" }),
      //   command: () => this.handleExportToProviderClick(),
      //   visible: this.authorizationService.canAccessExternalDrive(),
      //   disabled: this.isDownloading$.getValue(),
      // },
    ];
  }

  ngOnDestroy() {}

  handleDownloadFiles(filesIdentifier: string[], recordIdentifier: string) {
    this.isDownloading$.next(true);
    this.filesAPIService
      .recordCreateExport(filesIdentifier, recordIdentifier)
      .pipe(
        mergeMap((fileExport: FileExportModel) =>
          this.filesAPIService.recordDownloadExport(fileExport, this.record$.getValue().title + ".zip"),
        ),
        untilDestroyed(this),
      )
      .subscribe((file: File) => {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.rel = "noopener";
        link.download = "download";

        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.removeEventListener("click", clickHandler);
          }, 150);
        };
        link.addEventListener("click", clickHandler, false);
        link.click();

        this.isDownloading$.next(false);
      });
  }

  handleDownloadSelection() {
    this.handleDownloadFiles(
      this.selectedAttachments.map((a: RecordMessageAttachmentModel) => a.file.fileIdentifier),
      this.record$.getValue().recordIdentifier,
    );
  }

  handleExportToCloudClick() {
    this.displayCloudFolderPickerModal = true;
  }

  handleExportToProviderClick() {
    this.displayProviderFolderPickerModal = true;
  }

  // TODO: Handle different provider based on resource type
  handleFolderPickerSubmitted(resource: ProviderItemModel) {
    const fileIdentifiers = this.selectedAttachments.map((a: RecordMessageAttachmentModel) => a.file.fileIdentifier);
    if (resource.type === ProviderItemTypeEnum.NESTOR_DRIVE_ITEM) {
      this.filesAPIService
        .recordCreateExport(fileIdentifiers, this.record$.getValue().recordIdentifier)
        .pipe(
          tap(() => (this.isUploading = true)),
          mergeMap((fileExport: FileExportModel) =>
            this.cloudAPIService.uploadExportInternal(
              fileExport.exportIdentifier,
              this.record$.getValue().recordIdentifier,
              null,
              resource.path,
            ),
          ),
          untilDestroyed(this),
        )
        .subscribe((done: boolean) => {
          this.isUploading = false;
          this.handleCloudFolderPickerClose();
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_SUCCESS,
            summary: this.translateService.instant("FILES.export-export-to-cloud.messages.success.title", {
              "default": "Exported to cloud",
            }),
            detail: this.translateService.instant("FILES.export-export-to-cloud.messages.success.body", {
              "default":
                "Your files are being exported to Nestor Drive. Please note that depending on the size of the files, it may take some time.",
            }),
          });
        });
    } else {
      this.handleProviderFolderPickerClose();
      // TODO: Handle batch exports to Onedrive
      // gateway.uploadExternal()
    }
  }

  handleCloudFolderPickerClose() {
    this.displayCloudFolderPickerModal = false;
  }
  handleProviderFolderPickerClose() {
    this.displayProviderFolderPickerModal = false;
  }
}
