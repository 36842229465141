import UnitModel from "@shared/models/domain/unit.model";
import UserModel from "@shared/models/user/user.model";
import { RecordStatusEnum } from "@shared/enums/record-status.enum";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordEventModel from "@shared/models/record/record-event.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { RecordTypeEnum } from "@shared/enums/record-type.enum";
import { RecordPropertiesEnum } from "@app/@shared/enums/record-properties.enum";
import RecordReminderPropertiesModel from "./record-reminder-properties.model";
import WithPropertiesModel from "../with-properties.model";
import RecordRecipientMetadataModel from "./record-recipient-metadata.model";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";

export default class RecordModel extends WithPropertiesModel {
  // MINIMIZE (default)
  public recordIdentifier: string | null = null;
  public parentIdentifier?: string | null = null;
  public title: string | null = "";
  public topic?: string = "";
  public type: RecordTypeEnum = RecordTypeEnum.STANDARD;
  public status: RecordStatusEnum = RecordStatusEnum.CREATED;
  public isCancelled: boolean = false;
  public isClosed: boolean = false;
  public updatedAt: Date | null = null;
  public createdAt: Date | null = null;
  public dueDate: Date | null = null;
  public expirationDate: Date | null = null;
  public locale?: string;

  public unit: UnitModel | null = null;
  public owner: UserModel | null = null;
  public recipients: RecordRecipientModel[] = [];

  // RECORD_MESSAGES
  public messages: RecordMessageModel[] = [];

  // RECORD_TAGS
  public tags: string[] = [];
  // RECORD_EVENTS
  public events: RecordEventModel[] = [];

  // RECORD_REMINDERS
  public reminderProperties: RecordReminderPropertiesModel = new RecordReminderPropertiesModel();

  // RECORD_BATCH_DETAILS
  // public childrenCount: number | null = null;

  // RECORD_CURRENT_RECIPIENT_METADATA
  public currentRecipientMetadata: RecordRecipientMetadataModel = new RecordRecipientMetadataModel();

  // RECORD_LAST_MESSAGE
  public lastMessage: RecordMessageModel | null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.updatedAt) this.updatedAt = DateHelper.parseDate(input.updatedAt);

    if (input.dueDate) this.dueDate = DateHelper.parseDate(input.dueDate);

    if (input.expirationDate) this.expirationDate = DateHelper.parseDate(input.expirationDate);

    if (input.unit) this.unit = new UnitModel().deserialize(input.unit);
    if (input.owner) this.owner = new UserModel().deserialize(input.owner);

    if (input.recipients && Array.isArray(input.recipients))
      this.recipients = input.recipients.map((recipient: any) => new RecordRecipientModel().deserialize(recipient));

    if (input.messages && Array.isArray(input.messages))
      this.messages = input.messages.map((message: any) => new RecordMessageModel().deserialize(message));

    if (input.lastMessage) this.lastMessage = new RecordMessageModel().deserialize(input.lastMessage);

    if (input.events && Array.isArray(input.events))
      this.events = input.events.map((event: any) => new RecordEventModel().deserialize(event));

    if (input.reminderProperties)
      this.reminderProperties = new RecordReminderPropertiesModel().deserialize(input.reminderProperties);

    if (input.currentRecipientMetadata)
      this.currentRecipientMetadata = new RecordRecipientMetadataModel().deserialize(input.currentRecipientMetadata);

    return this;
  }

  isOwner(userIdentifier: string): boolean {
    return this.owner && this.owner.userIdentifier === userIdentifier;
  }

  get broadcastedMessages(): RecordMessageModel[] {
    return this.messages.filter((m: RecordMessageModel) => m.isBroadcasted);
  }

  get internalMessages(): RecordMessageModel[] {
    return this.messages.filter((m: RecordMessageModel) => !m.isBroadcasted);
  }

  /**
   * Properties related helpers
   */
  containsSignature(): boolean {
    return this.containsProperty(RecordPropertiesEnum.CONTAINS_SIGNATURE);
  }

  containsInvitation(): boolean {
    return this.containsProperty(RecordPropertiesEnum.CONTAINS_INVITATION);
  }

  containsForm(): boolean {
    return this.containsProperty(RecordPropertiesEnum.CONTAINS_FORM);
  }

  containsFormAnswer(): boolean {
    return this.containsProperty(RecordPropertiesEnum.CONTAINS_FORM_ANSWER);
  }

  containsAttachments(): boolean {
    return this.containsProperty(RecordPropertiesEnum.CONTAINS_ATTACHMENTS);
  }

  /*
   * Last message helpers
   */
  get read(): boolean {
    return (
      Boolean(this.currentRecipientMetadata?.read) &&
      this.currentRecipientMetadata?.lastReadAt >= this.lastMessage?.createdAt
    );
  }

  /*
   * Batch helpers
   */
  get isBatch() {
    return this.type === RecordTypeEnum.BATCH;
  }

  get isBatchCompleted() {
    return this.isBatch && this.status !== RecordStatusEnum.DISPATCHING;
  }

  get humanRecipients() {
    return this.recipients.filter((r: RecordRecipientModel) => r.role !== RecordRoleEnum.BOT);
  }

  get guestRecipients() {
    return this.humanRecipients.filter((r) => [RecordRoleEnum.READER, RecordRoleEnum.WRITER].includes(r.role));
  }
  get reviewerRecipients() {
    return this.humanRecipients.filter((r) => [RecordRoleEnum.REVIEWER].includes(r.role));
  }

  get mainRecipient() {
    return this.guestRecipients.length > 0
      ? this.guestRecipients.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0]
      : this.reviewerRecipients.length > 0
      ? this.reviewerRecipients.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())[0]
      : null;
  }
}
