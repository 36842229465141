import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "getExtensionIcon",
})
export class GetExtensionIconPipe implements PipeTransform {
  transform(extension: string) {
    switch (extension) {
      case "pdf":
        return "pi pi-file-pdf";
      case "text/plain":
        return "pi pi-file";
      case "text/html":
      case "text/css":
      case "js":
        return "pi pi-code";
      case "application/zip":
      case "application/x-7z-compressed":
      case "application/x-bzip":
      case "application/x-bzip2":
      case "application/x-rar-compressed":
      case "application/x-tar":
        return "pi pi-folder";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/vnd.oasis.opendocument.text":
        return "pi pi-file-word";
      case "application/csv":
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "application/vnd.oasis.opendocument.spreadsheet":
        return "pi pi-file-excel";
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      case "application/vnd.oasis.opendocument.presentation":
        return "pi pi-file";
      case "gif":
      case "png":
      case "jpeg":
      case "bmp":
      case "webp":
      case "ico":
      case "svg":
      case "tiff":
        return "pi pi-image";
      case "video/x-msvideo":
      case "video/mpeg":
      case "video/ogg":
      case "webm":
      case "mp4":
        return "pi pi-video";
      case "audio/aac":
      case "audio/midi":
      case "audio/mpeg":
      case "audio/webm":
      case "audio/ogg":
      case "audio/wav":
      case "wav":
        return "pi pi-file";
      default:
        return "pi pi-file";
    }
  }
}
