import PaginationModel from "@shared/models/pagination.model";
import ProviderContactModel from "../providers/provider-contact.model";

export default class ProviderContactsResultModel extends PaginationModel {
  public contacts: ProviderContactModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.contacts && Array.isArray(input.contacts))
      this.contacts = input.contacts.map((contact: any) => new ProviderContactModel().deserialize(contact));

    return this;
  }
}
