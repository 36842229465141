import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import { ContactsService } from "@app/contacts/services/contacts.service";
import { Observable } from "rxjs";
import { UntilDestroy } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { isEmpty } from "lodash";
import { ContactOrderEnum } from "@app/@shared/enums/contact-order.enum";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { MessageService } from "primeng/api";

@UntilDestroy()
@Component({
  selector: "contacts-contacts-list",
  templateUrl: "./contacts-list.component.html",
  styleUrls: ["./contacts-list.component.scss"],
})
export class ContactsListComponent implements OnInit {
  @ViewChild("searchInput") searchinput: ElementRef<HTMLInputElement>;

  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;

  contacts$: Observable<ContactModel[]>;
  isLoading$: Observable<boolean>;

  selectedContacts: ContactModel[] = [];

  @Output() onContactsSelected: EventEmitter<ContactModel[]> = new EventEmitter<ContactModel[]>();
  @Output() onContactSearched: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  cols: any;
  searchText: string = "";

  DEFAULT_LIMIT = 8;

  constructor(
    private contactsService: ContactsService,
    private translateService: TranslateService,

    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.contactsService.isLoading$;
    this.contacts$ = this.contactsService.contacts$;
    this.contactsService.initialize(0, this.DEFAULT_LIMIT, ContactScopeEnum.USER);

    this.cols = [
      {
        field: "firstName",
        header: this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" }),
      },
      {
        field: "lastName",
        header: this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" }),
      },
      {
        field: "phoneNumber",
        header: this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" }),
      },
      {
        field: "emailAddress",
        header: this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" }),
      },
    ];
  }

  handleFiltering(event: Event) {
    event.preventDefault();
    this.searchText = this.searchinput.nativeElement.value;
    if (!isEmpty(this.searchText)) {
      this.contactsService.initialize(0, -1, ContactScopeEnum.USER, null, this.searchText);
    } else {
      this.contactsService.initialize(0, this.DEFAULT_LIMIT, ContactScopeEnum.USER);
    }
  }

  submit() {
    if (!(this.selectedContacts?.length > 0)) {
      this.messageService.add({
        severity: MessageSeverityEnum.SEVERITY_WARN,
        detail: this.translateService.instant("ADDRESS-BOOK.messages.contacts-not-selected.detail", {
          default: "Select at least one contact to add",
        }),
      });
      return null;
    }
    this.onContactsSelected.emit(this.selectedContacts);
  }

  cancel() {
    this.onCancel.emit();
  }
}
