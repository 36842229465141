import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";

export default class RecordReminderPropertiesModel extends BaseModel {
  public enabled: boolean = false;
  public content: string | null = null;
  public dates: Date[] = [];
  public lastReminderAt: Date | null = null;
  public startDate: Date | null = null;
  public endDate: Date | null = null;
  public time: Date | null = null;
  public interval: number | null = null;

  // Reminder for expiration date
  public remindBeforeExpirationDate: boolean = false;
  public expirationReminderDates: Date[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.startDate) this.startDate = DateHelper.parseDate(input.startDate);
    if (input.endDate) this.endDate = DateHelper.parseDate(input.endDate);
    if (input.time) this.time = DateHelper.parseDate(input.time);

    if (input.dates && Array.isArray(input.dates))
      this.dates = input.dates.map((event: any) => DateHelper.parseDate(event));

    if (input.lastReminderAt) this.lastReminderAt = DateHelper.parseDate(input.lastReminderAt);

    return this;
  }
}
