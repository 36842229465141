<div class="flex flex-column">
  <label class="switch-field consent-field" for="consent-debug-logs">
    <p-checkbox
      inputId="consent-debug-logs"
      styleClass="block"
      [readonly]="isLoading"
      [ngClass]="{ 'p-disabled': isLoading }"
      [(ngModel)]="consentLogs"
      [binary]="true"
    ></p-checkbox>
    <div class="field-text">
      <span class="field-text__title">
        {{ "USER.fields.consents.debug-logs.label" | translate : { "default": "Send errors logs" } }}
      </span>
      <span class="field-text__subtitle">{{
        "USER.fields.consents.debug-logs.tooltip"
          | translate
            : {
                "default":
                  "We self-host and protect your
          data."
              }
      }}</span>
    </div>
  </label>
  <label class="switch-field consent-field" for="consent-analytics">
    <p-checkbox
      inputId="consent-analytics"
      styleClass="block"
      [readonly]="isLoading"
      [ngClass]="{ 'p-disabled': isLoading }"
      [(ngModel)]="consentAnalytics"
      [binary]="true"
    ></p-checkbox>
    <div class="field-text">
      <span class="field-text__title">
        {{ "USER.fields.consents.analytics.label" | translate : { "default": "Send usage" } }}
      </span>
      <span class="field-text__subtitle">
        {{
          "USER.fields.consents.analytics.tooltip"
            | translate
              : {
                  "default":
                    "We self-host and protect your
          data."
                }
        }}
      </span>
    </div>
  </label>
  <label class="switch-field consent-field" for="consent-newsletters">
    <p-checkbox
      inputId="consent-newsletters"
      styleClass="block"
      [readonly]="isLoading"
      [ngClass]="{ 'p-disabled': isLoading }"
      [(ngModel)]="consentNewsletter"
      [binary]="true"
    ></p-checkbox>
    <div class="field-text">
      <span class="field-text__title">
        {{ "USER.fields.consents.newsletters.label" | translate : { "default": "Receive newsletters" } }}
      </span>
      <span class="field-text__subtitle">
        {{
          "USER.fields.consents.newsletters.tooltip"
            | translate
              : {
                  "default":
                    "You will receive some news from
          us."
                }
        }}
      </span>
    </div>
  </label>
</div>
