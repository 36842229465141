import { CouponDurationEnum } from "@app/@shared/enums/billing/coupon-duration.enum";
import BaseModel from "@shared/models/base.model";

export default class CouponModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public amountOff: number | null = null;
  public duration: CouponDurationEnum;
  public durationInMonths: number | null = null;
  public name: string | null = null;
  public percentOff: number | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
