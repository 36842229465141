<form class="h-full" [formGroup]="form" (ngSubmit)="submit()">
  <!-- Content - will adapt whenever nested inside a modal -->
  <div class="dialog-nested-content h-full">
    <header *ngIf="showReminderManagementToggle">
      <div class="field-checkbox">
        <p-inputSwitch inputId="enabledReminder" name="enabledReminder" formControlName="enabled"></p-inputSwitch>
        <label for="enabledReminder">
          {{ "RECORD.fields.reminders.enabled.label" | translate : { "default": "Enable reminder" } }}
        </label>
      </div>
      <p-divider *ngIf="enabled"></p-divider>
    </header>
    <div class="switcher" style="--threshold: 45rem; --switcher-space: 2rem" *ngIf="enabled">
      <div class="fields">
        <!-- Field: Start date -->
        <div class="field start">
          <label for="startDate"
            >{{ "RECORD.fields.reminders.start-date.label" | translate : { "default": "Starts on" } }}
          </label>
          <p-calendar
            styleClass="w-full"
            [minDate]="reminderMinDate"
            [maxDate]="reminderMaxDate"
            formControlName="startDate"
          ></p-calendar>
        </div>
        <!-- Field: End date -->
        <div class="field end">
          <label for="endDate"
            >{{ "RECORD.fields.reminders.end-date.label" | translate : { "default": "Ends on" } }}
          </label>
          <p-calendar
            styleClass="w-full"
            [minDate]="form.get('startDate').value ?? reminderMinDate"
            [maxDate]="reminderMaxDate"
            formControlName="endDate"
          ></p-calendar>
        </div>
        <!-- Field: Days interval -->
        <div class="field days">
          <label for="daysInterval"
            >{{ "RECORD.fields.reminders.days-interval.label" | translate : { "default": "Send every" } }}
          </label>
          <div class="p-inputgroup">
            <p-inputNumber formControlName="daysInterval" [min]="1" [max]="maxInterval"></p-inputNumber>
            <span class="p-inputgroup-addon">
              {{ "COMMON.days" | translate : { "default": "Days" } }}
            </span>
          </div>
        </div>
        <!-- Field: Reminder time -->
        <div class="field time">
          <label for="time">{{ "RECORD.fields.reminders.time.label" | translate : { "default": "At" } }} </label>
          <p-calendar styleClass="w-full" [timeOnly]="true" formControlName="time"></p-calendar>
        </div>
        <!-- Field: Message -->
        <div class="field content">
          <label for="reminder-content" class="block">{{
            "RECORD.fields.reminders.content.label" | translate : { "default": "Reminder message" }
          }}</label>
          <textarea
            formControlName="content"
            name="reminder-content"
            id="reminder-content"
            class="w-full"
            rows="7"
            pInputTextarea
          ></textarea>
        </div>
        <!-- Summarized -->
        <output class="summary" for="startDate endDate daysInterval time reminder-content">
          <div
            class="flex gap-2 p-2 align-items-baseline border-round border-1 border-blue-500 bg-blue-100 text-blue-600"
            style="width: fit-content"
          >
            <span>
              <i class="pi pi-question-circle text-lg font-medium" style="vertical-align: middle"></i>
            </span>
            <span>
              {{
                "RECORD.record-reminders-form.summary"
                  | translate
                    : {
                        "count": recordReminderProperties.dates.length,
                        "from": (recordReminderProperties.startDate | date),
                        "to": (recordReminderProperties.endDate | date)
                      }
              }}
            </span>
          </div>
        </output>
      </div>
      <record-reminders-calendar
        [reminderMinDate]="reminderMinDate"
        [reminderMaxDate]="reminderMaxDate"
        [recordReminderProperties]="recordReminderProperties"
      ></record-reminders-calendar>
    </div>
  </div>
  <!-- Reminder summary -->
  <footer class="dialog-nested-footer justify-content-between gap-3 flex-wrap flex">
    <button
      pButton
      [label]="'COMMON.buttons.back-to-conversation' | translate : { 'default': 'Back to conversation' }"
      type="button"
      icon="pi pi-arrow-left"
      type="button"
      (click)="cancel()"
      class="p-button-outlined p-button-secondary p-button-sm hidden md:flex"
    ></button>
    <button
      type="submit"
      pButton
      class="p-button-sm p-button-secondary w-full md:w-auto"
      [label]="'COMMON.buttons.schedule-reminders' | translate : { 'default': 'Schedule reminder' }"
    ></button>
  </footer>
</form>
