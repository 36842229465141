import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { COMPONENTS } from "./components";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { QuillModule } from "ngx-quill";
import { MainModule } from "@app/main/main.module";
import { SharedModule } from "@app/@shared/shared.module";
import { UiModule } from "@app/@ui/ui.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    QuillModule.forRoot({ suppressGlobalRegisterWarning: true }),

    // Custom modules
    MainModule,
    SharedModule,
    UiModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class EditorModule {}
