<!-- Regular toasts -->
<p-toast
  [style]="{ fontSize: '10pt' }"
  position="bottom-right"
  [preventOpenDuplicates]="true"
  [showTransformOptions]="'translateX(100%)'"
  [showTransitionOptions]="'300ms'"
  [hideTransitionOptions]="'300ms'"
  [hideTransformOptions]="'translateX(200%)'"
>
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-center flex-1 gap-2">
      <span
        [class]="'p-toast-message-icon align-self-center icon-22px' + (message.icon ? ' ' + message.icon : '')"
        [ngClass]="{
          'msr-icon-info': message.severity == 'info',
          'msr-icon-warning': message.severity == 'warn',
          'msr-icon-error': message.severity == 'error',
          'msr-icon-check_circle': message.severity == 'success',
          'msr-icon-notifications': message.severity == 'notification'
        }"
      ></span>
      <div class="flex flex-column" style="flex: 1">
        <strong class="p-toast-summary" *ngIf="message.summary">{{ message.summary }}</strong>
        <div class="p-toast-detail text-color" *ngIf="message.detail" [innerHtml]="message.detail | safeHtml"></div>

        <div *ngIf="message.severity == 'notification' && message.data?.notificationMessage">
          <button
            type="button"
            pButton
            class="p-button-sm p-toast-button"
            [label]="'NOTIFICATIONS.buttons.view-notification' | translate : { 'default': 'View notification' }"
            (click)="handleNotificationClick($event, message)"
          ></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
