import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Logger } from "@app/@core";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakEvent, KeycloakEventType, KeycloakService } from "keycloak-angular";
import { ConfirmationService } from "primeng/api";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

const log = new Logger("ErrorsService");

@Injectable({
  providedIn: "root",
})
export class KeycloakInitService {
  private _hasKeycloakInitError$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  hasKeycloakInitError$: Observable<boolean> = this._hasKeycloakInitError$.asObservable();

  get hasKeycloakInitError(): boolean {
    return this._hasKeycloakInitError$.getValue();
  }
  set hasKeycloakInitError(hasKeycloakInitError: boolean) {
    this._hasKeycloakInitError$.next(hasKeycloakInitError);
  }

  private _keycloakInitError$: BehaviorSubject<boolean> = new BehaviorSubject(undefined);
  keycloakInitError$: Observable<boolean> = this._keycloakInitError$.asObservable();

  get keycloakInitError(): boolean {
    return this._keycloakInitError$.getValue();
  }
  set keycloakInitError(keycloakInitError: boolean) {
    this._keycloakInitError$.next(keycloakInitError);
  }

  constructor(private keycloak: KeycloakService, private router: Router) {}

  init() {
    this.keycloak
      .init({
        config: {
          url: environment.keycloak.url,
          realm: environment.keycloak.realm,
          clientId: environment.keycloak.clientId,
        },
        initOptions: {
          onLoad: "check-sso",
          silentCheckSsoRedirectUri: window.location.origin + "/assets/silent-check-sso.html",
          enableLogging: true,
        },
      })
      .then(
        (done: boolean) => {
          this._hasKeycloakInitError$.next(false);
        },
        (reason: any) => {
          this._hasKeycloakInitError$.next(true);
          this._keycloakInitError$.next(reason);
          this.router.navigate(["/errors", "service-unavailable"]);
        },
      );
  }

  subscribeEvents(): Subscription {
    // Reload page to re-init login when Authentication fails
    return this.keycloak.keycloakEvents$.subscribe((event: KeycloakEvent) => {
      console.log("[Keycloak event]", event);
      if (event.type == KeycloakEventType.OnAuthError) {
        window.location.reload();
      } else if (event.type == KeycloakEventType.OnTokenExpired) {
        this.keycloak.updateToken(1);
      } else if ([KeycloakEventType.OnAuthLogout, KeycloakEventType.OnAuthRefreshError].includes(event.type)) {
        this.keycloak.login();
      }
    });
  }
}
