import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MessageFormatEnum } from "@app/@shared/enums/message-format.enum";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import RecordModel from "@app/@shared/models/record/record.model";
import WrappedKeyModel from "@app/@shared/models/vault/wrapped-key.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import PublicKeyModel from "@app/@shared/models/vault/public-key.model";
import { FormItemClickEvent } from "../messages/message-human-type/message-human-type.component";
import { BehaviorSubject } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@core";
import { RecordService } from "@app/record/services/record.service";

@UntilDestroy()
@Component({
  selector: "record-message",
  templateUrl: "./record-message.component.html",
  styleUrls: ["./record-message.component.scss"],
})
export class RecordMessageComponent implements OnInit, OnChanges {
  @Input() enableActions: boolean = true;
  @Input() enableReply: boolean = true;
  @Input() isLoadingKeys: boolean = false;
  @Input() message: RecordMessageModel;
  @Input() publicKey: PublicKeyModel;
  @Input() showBroadcasted: boolean = false;
  @Input() wrappedKeys: WrappedKeyModel[] = [];

  @Output() onDecryptionCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReplyClick: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();
  @Output() onInvitationReplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() onParentMessageClicked: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();
  @Output() onMessageFormAction: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();
  @Output() onMessageSignatureAction: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();
  @Output() onWithdrawSignatureAction: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();
  @Output() messageChange: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();
  @Output() onFormItemClick: EventEmitter<FormItemClickEvent> = new EventEmitter<FormItemClickEvent>();

  parentMessage: RecordMessageModel;
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);

  get record() {
    return this.record$.getValue();
  }

  constructor(public sessionAPIService: SessionAPIService, private recordService: RecordService) {
    // Put record into a BehaviorSubject to access its value at any time
    this.recordService.record$.pipe(untilDestroyed(this)).subscribe(this.record$);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      this.parentMessage = this.record.messages.find(
        (m: RecordMessageModel) => m.messageIdentifier === this.message.parentMessageIdentifier,
      );
    }
  }

  get isLoading() {
    return (
      this.isLoadingKeys &&
      [MessageFormatEnum.ENCRYPTED_HTML, MessageFormatEnum.ENCRYPTED_TEXT].includes(this.message.format)
    );
  }

  handleReplyClick(message: RecordMessageModel) {
    this.onReplyClick.emit(message);
  }

  handleInvitationReplied() {
    this.onInvitationReplied.emit();
  }

  handleParentMessageClicked(message: RecordMessageModel) {
    this.onParentMessageClicked.emit(message);
  }

  handleMessageFormAction(message: RecordMessageModel) {
    this.onMessageFormAction.emit(message);
  }

  handleMessageSignatureAction(message: RecordMessageModel) {
    this.onMessageSignatureAction.emit(message);
  }
  handleWithdrawSignatureAction(message: RecordMessageModel) {
    this.onWithdrawSignatureAction.emit(message);
  }

  handleFormItemClick(event: FormItemClickEvent) {
    this.onFormItemClick.emit(event);
  }

  handleDecryptionCompleted() {
    this.onDecryptionCompleted.emit();
  }
}
