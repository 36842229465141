export enum MatomoEventEnum {
  CAMPAIGN_CREATED = "campaign-created",
  CAMPAIGN_CONVERSATIONS_ADDED = "campaign-conversations-added",
  CONTACTS_ADDED = "contacts-added",
  CONVERSATION_CREATED = "conversation-created",
  CONVERSATION_SENT = "conversation-sent",
  FILE_UPLOADED = "file-uploaded",
  MESSAGE_FORM_ANSWERED = "message-form-answered",
  PROVIDER_FILE_UPLOADED = "file-uploaded",
  TEMPLATE_CREATED = "template-created",
  TEMPLATE_UPDATED = "template-updated",
  SIGNATURE_CREATED = "signature-created",
  WORKSPACE_INVITATION = "workspace-invitation",
  WORKSPACE_INVITATION_ACCEPTED = "workspace-invitation-accepted",
  WORKSPACE_INVITATION_DECLINED = "workspace-invitation-declined",
}
