import { Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import UnitModel from "@app/@shared/models/domain/unit.model";
import SessionModel from "@app/@shared/models/session/session.model";
import { BehaviorSubject, combineLatest, filter, Observable, tap } from "rxjs";
import { SessionAPIService } from "../session-api.service";
import { DomainPropertiesService } from "./domain-properties.service";

const log = new Logger("UnitPropertiesService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class UnitPropertiesService {
  // Initialized observable
  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  initialized$: Observable<boolean> = this._initialized$.asObservable();

  get initialized() {
    return this._initialized$.getValue();
  }

  // Domain observable
  private _unit$: BehaviorSubject<UnitModel> = new BehaviorSubject(undefined);
  unit$: Observable<UnitModel> = this._unit$.asObservable();

  get unit() {
    return this._unit$.getValue();
  }

  constructor(private sessionAPIService: SessionAPIService, private domainPropertiesService: DomainPropertiesService) {
    // Initialize watching session to set _currentUserUnit$ value
    combineLatest([this.sessionAPIService.session$, this.domainPropertiesService.initialized$])
      .pipe(
        filter(([session, initialized]) => Boolean(initialized) && Boolean(session)),
        tap(([session, initialized]) => this._unit$.next(session.unit)),
        untilDestroyed(this),
      )
      .subscribe(([session, initialized]) => {
        this._initialized$.next(true);
      });
  }

  convertConversationAnswerFilesToPDF() {
    return (
      this.domainPropertiesService.convertConversationAnswerFilesToPDF() ||
      (this.unit && this.unit.convertConversationAnswerFilesToPDF)
    );
  }
}
