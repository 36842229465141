import BaseModel from "@shared/models/base.model";
import PaymentMethodCardModel from "./payment-method/card.model";
import PaymentMethodSepaDebitModel from "./payment-method/sepa-debit.model";

export default class PaymentMethodModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public type: string | null = null;
  public card: PaymentMethodCardModel;
  public sepaDebit: PaymentMethodSepaDebitModel;
  public created: number | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.card) this.card = new PaymentMethodCardModel().deserialize(input.card);
    if (input.sepaDebit) this.sepaDebit = new PaymentMethodSepaDebitModel().deserialize(input.sepaDebit);

    return this;
  }
}
