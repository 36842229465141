export enum KeycloakClientRoleEnum {
  // Oneway roles
  ONEWAY_FREE = "oneway-free",
  ONEWAY_FULL = "oneway-full",

  // Files roles
  FILES_UPLOAD = "files-upload",
  FILES_DOWNLOAD = "files-download",
  FILES_SESSION = "files-session",

  // Partners roles
  PARTNERS_SESSION = "partners-session",
  PARTNERS_SIGNATURE = "partners-signature",
  PARTNERS_MANAGE_SIGNATURE = "partners-manage-signature",

  // Main roles
  MAIN_RECORD = "main-record",
  MAIN_SESSION = "main-session",
  MAIN_USER = "main-user",
  MAIN_MASTERDATA = "main-masterdata",
  MAIN_NOTIFICATION = "main-notification",
  MAIN_DOMAIN = "main-domain",
  MAIN_BILLING = "main-billing",
  MAIN_ADDRESSBOOK = "main-addressbook",

  // Vault roles
  VAULT_RECORD_KEYS = "vault-record-keys",
  VAULT_SESSION = "vault-session",
  VAULT_USER_KEYS = "vault-user-keys",

  // FEATURES
  FEATURE_FORMS_TEMPLATE = "forms-template",
  FEATURE_FORMS_ITEM_CUSTOM = "forms-item-custom",

  FEATURE_UNITS_MANAGEMENT = "units-management",
  FEATURE_UNITS_CUSTOM = "units-custom",

  FEATURE_RECORDS_STANDARD = "records-standard",
  FEATURE_RECORDS_BATCH = "records-batch",
}
