import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ProviderApplicationTypeEnum } from "@app/@shared/enums/provider-application-type.enum";
import ProviderApplicationModel from "@app/@shared/models/providers/provider-application.model";
import { GetFileProviderIconPipe } from "@app/@shared/pipes/get-file-provider-icon.pipe";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";

type ProviderItemLabelType = {
  title: string;
  subtitle: string;
  link: string;
};

@Component({
  selector: "provider-file-switcher",
  templateUrl: "./provider-file-switcher.component.html",
  styleUrls: ["./provider-file-switcher.component.scss"],
})
export class ProviderFileSwitcherComponent implements OnInit, OnChanges {
  @Input() isLoadingApplications: boolean;
  @Input() applications: ProviderApplicationModel[] = [];
  @Input() currentApplication: ProviderApplicationModel;
  @Output() onSelectApplication: EventEmitter<ProviderApplicationModel> = new EventEmitter<ProviderApplicationModel>();

  providerItems: MenuItem[] = [];

  constructor(private getFileProviderIconPipe: GetFileProviderIconPipe, private translateService: TranslateService) {}
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentApplication) {
      this.setProviderItems();
    }
  }

  /**
   * Generate a customized menu item
   * @param providerItem.subtitle Connected Email adress
   * @returns An HTML string for the menu label
   */
  getProviderItemLabel(providerItem: ProviderItemLabelType): string {
    let label = `
      <div class="provider-menu-label">
          <img src="${providerItem.link}" width="32" height="32"></img>
          <b>${providerItem.title}</b>
          <span>${providerItem.subtitle}</span>
      </div>
    `;
    return label;
  }
  setProviderItems() {
    if (this.applications?.length > 0) {
      let providerItems: MenuItem[] = [];
      Object.values(ProviderApplicationTypeEnum).forEach((provider) => {
        let labelItem: ProviderItemLabelType = {
          title: this.translateService.instant(`USER.settings.apps.available.${provider}.title`, { default: provider }),
          subtitle: this.currentApplication?.metadata?.["username"],
          link: this.getFileProviderIconPipe.transform(provider),
        };
        let providerItem: MenuItem = {
          label: this.getProviderItemLabel(labelItem),
          escape: false,
          items: this.applications
            ?.filter((application) => application.provider === provider)
            ?.map((application) => {
              let item: MenuItem = {
                label: application.metadata["username"],
                id: application.applicationIdentifier,
                icon: application === this.currentApplication ? "pi pi-check-circle font-bold" : undefined,
                styleClass: application === this.currentApplication ? "font-medium" : undefined,
                command: () => {
                  this.onSelectApplication.emit(application);
                },
              };
              return item;
            }),
        };
        // Hide providers with no active connection
        if (providerItem.items?.length > 0) providerItems.push(providerItem);
      });
      this.providerItems = providerItems;
    }
  }
}
