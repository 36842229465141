import { AbstractControl, ValidationErrors } from "@angular/forms";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";

export function recipientsValidForSignatureValidator(control: AbstractControl): ValidationErrors | null {
  let recipients: RecordRecipientModel[] = control.value;

  let recipientsInvalid =
    recipients.filter((recipient) => {
      return !recipient.isValid(true);
    }).length > 0;

  if (recipientsInvalid) {
    return { recipientsInvalid: true };
  }

  return null;
}
