import { Component, Input, OnInit } from "@angular/core";
import { partition } from "lodash";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import { ThemingService } from "@app/@shared/services/theming.service";

@Component({
  selector: "record-message-signature-avatar-group",
  templateUrl: "./message-signature-avatar-group.component.html",
  styleUrls: ["./message-signature-avatar-group.component.scss"],
})
export class RecordMessageSignatureAvatarGroupComponent implements OnInit {
  @Input() message: RecordMessageModel;
  @Input()
  get recipients() {
    return this._recipients;
  }
  set recipients(recipients: RecordRecipientModel[]) {
    this._recipients = recipients;
    this.onRecipientsChanged();
  }

  _recipients: RecordRecipientModel[] = [];
  recipientsSigned: RecordRecipientModel[] = [];
  recipientsNotSigned: RecordRecipientModel[] = [];

  constructor(public themingService: ThemingService) {}

  ngOnInit(): void {}

  onRecipientsChanged() {
    let [signed, notSigned] = partition(this._recipients, (recipient) => this.hasSigned(recipient));
    this.recipientsSigned = signed;
    this.recipientsNotSigned = notSigned;
  }

  hasSigned(recipient: RecordRecipientModel) {
    return Boolean(this.message) && this.message.hasSigned(recipient.identityIdentifier);
  }

  hasCompletedSignature(recipient: RecordRecipientModel) {
    return Boolean(this.message) && this.message.hasCompletedSignature(recipient.identityIdentifier);
  }

  get tooltipSignedRecipientsContent() {
    return (
      '<div class="flex flex-column">' +
      this.recipientsSigned.map((recipient) => "<span>" + recipient.emailAddress + "</span>").join("") +
      "</div>"
    );
  }

  get tooltipNotSignedRecipientsContent() {
    return (
      '<div class="flex flex-column">' +
      this.recipientsNotSigned.map((recipient) => "<span>" + recipient.emailAddress + "</span>").join("") +
      "</div>"
    );
  }
}
