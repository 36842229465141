<form class="reminders-form" [formGroup]="form" (ngSubmit)="submit()">
  <!-- Content - will adapt whenever nested inside a modal -->
  <div class="dialog-nested-content reminders-form__content">
    <!-- Title field -->
    <div class="field">
      <label for="recordTitle"
        >{{ "RECORD.fields.title.label" | translate : { "default": "Title" } }}
        <i
          class="pi pi-question-circle text-sm"
          appendTo="body"
          tooltipStyleClass="generic-tooltip"
          [pTooltip]="
            'RECORD.fields.title.tooltip'
              | translate
                : {
                    default:
                      'Warn, this data still in clear text and is populate on notifications. It\'s encrypted on our database.'
                  }
          "
        ></i>
      </label>
      <input
        pInputText
        id="recordTitle"
        class="w-full"
        formControlName="title"
        type="text"
        [ngClass]="{
          'ng-invalid ng-dirty': form.get('title')?.invalid && form.get('title')?.touched
        }"
      />

      <small [hidden]="form.get('title')?.valid || form.get('title')?.untouched" class="p-error">
        <span *ngIf="form.get('title')?.errors?.required">
          {{ "RECORD.fields.title.errors.required" | translate : { "default": "Title is required" } }}
        </span>
      </small>
    </div>

    <!-- Expiration date -->
    <div class="field mb-2">
      <label for="expiration-input" class="w-full flex justify-content-between">
        <span>
          {{ "RECORD.fields.expire.label" | translate : { "default": "Expiration date" } }}
          <i
            class="pi pi-question-circle text-sm"
            appendTo="body"
            tooltipStyleClass="generic-tooltip"
            [pTooltip]="'RECORD.fields.expire.detail' | translate"
          ></i>
        </span>
        <span class="clamp text-sm">
          {{ form.get("expirationDate").value | humanDatePipe }}
        </span>
      </label>
      <p-calendar
        #expirationCalendar
        id="expiration-input"
        [showButtonBar]="false"
        styleClass="record-update-expiration w-full"
        panelStyleClass="record-update-expiration-panel"
        inputId="expiration-input"
        [minDate]="minExpirationDate"
        [maxDate]="maxExpirationDate"
        formControlName="expirationDate"
        [inline]="false"
        appendTo="body"
        [readonlyInput]="true"
        [panelStyle]="themingService.customStyles$ | async"
        [ngClass]="{
          'ng-invalid ng-dirty': form.get('expirationDate')?.invalid && form.get('expirationDate')?.touched
        }"
      >
      </p-calendar>
    </div>
  </div>
  <!-- Footer - will adapt whenever nested inside a modal -->
  <footer class="dialog-nested-footer gap-2 flex flex-wrap justify-content-between flex-column sm:flex-row">
    <button
      class="p-button p-button-sm p-button-secondary w-full"
      pButton
      [label]="'COMMON.buttons.save-changes' | translate : { 'default': 'Save changes' }"
      type="submit"
    ></button>
  </footer>
</form>
