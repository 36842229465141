<p-checkbox
  [name]="name"
  [inputId]="inputId"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  [label]="controlTextValue"
  [binary]="true"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  [value]="value"
  (onChange)="handleChange($event)"
></p-checkbox>
