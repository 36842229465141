export enum DataTypeFormatEnum {
  TEXT = "text", // input
  LONGTEXT = "longtext", // textarea
  NUMBER = "number", // input
  CURRENCY = "currency", // input
  DATE = "date", // calendar
  DATETIME = "datetime", // calendar
  TIME = "time", // calendar
  BOOLEAN = "boolean", // checkbox or switch
  DOCUMENTS = "documents", // dropzone or button
  EMAIL = "email", // input
  PHONE = "phone", // input (ngx-intl-tel-input)
  ADDRESS = "address", // form group
  CHOICE = "choice", // radio buttons or dropdown or listbox
  MULTICHOICE = "multichoice", // checkboxes or listbox
  RANGE = "range", // slider range
  RATING = "rating", // rating
}
