export enum FormDataFormatterTypeEnum {
  MASK = "mask", // Mask for input
  MASK_CHARACTER_PATTERN = "maskCharacterPattern",

  MIN = "min", // Min for number input or range input
  MAX = "max", // Max for number input or range input
  STEP = "step", // Step for number input

  NUMBER_TYPE = "numberType", // Number type (integer, decimal, currency)
  DECIMAL_COUNT = "decimalCount", // Minimum decimal count for number
  CURRENCY = "currency", // Currency for number input
  CURRENCY_DISPLAY = "currencyDisplay", // Currency display for number input

  MAX_LENGTH = "maxLength", // Max length of an input

  MAX_FILES = "maxFiles", // Maximum number of files
  MAX_FILE_SIZE = "maxFileSize", // Maximum file size
  FILE_ACCEPT = "fileAccept", // File type accepted (array)
  FILE_RENAMING = "fileRenaming", // Slect renaming pattern
  MIN_DATE = "minDate", // Min date for date picker
  MAX_DATE = "maxDate", // Max date for date picker
  HOUR_STEP = "hourStep", // Hour step for time picker
  MINUTE_STEP = "minuteStep", // Minute step for time picker
  SPECIFIC_DATES = "specificDates", // Allow only specific dates for date picker

  PREFIX = "prefix", // Prefix for : range slider value
  SUFFIX = "suffix", // Suffix for : range slider value

  RATING_STARS = "ratingStars", // Number of stars in rating

  ORIENTATION = "orientation", // Radio & checkbox orientation
}
