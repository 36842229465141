import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";
import ProviderFolderModel from "./provider-folder.model";
import { ProviderItemTypeEnum } from "@app/@shared/enums/provider-item-type.enum";

export default class ProviderItemModel extends BaseModel {
  public id: string | null = null;
  public type: ProviderItemTypeEnum | null = null;
  public name: string | null = null;
  public path: string | null = null;
  public size: number | null = null;
  public mimeType: string | null = null;
  public rootId: string | null = null;
  public owner: string | null = null;

  public folder: ProviderFolderModel | null = null;
  public createdAt: Date | null = null;

  // Temporary fields (until nextcloud moved to Partners API)
  public applicationIdentifier: string | null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.folder) this.folder = new ProviderFolderModel().deserialize(input.folder);

    return this;
  }

  get isFolder() {
    return this.folder != null;
  }

  get isDirectory() {
    return this.isFolder;
  }

  get iconSrc() {
    switch (this.type) {
      case ProviderItemTypeEnum.MICROSOFT_DRIVE:
        return "assets/images/providers/onedrive.svg";
      case ProviderItemTypeEnum.MICROSOFT_SHAREPOINT_SITE:
        return "assets/images/providers/sharepoint.svg";
      default:
        return null;
    }
  }
}
