<div *ngIf="message; else emptyMessage" class="message message--bot flex gap-2" data-message-sender="bot">
  <ui-user-avatar [showTooltip]="true" [size]="32" [user]="message.sender"></ui-user-avatar>

  <div class="message__content">
    <div *ngIf="message.body" class="message__body">
      <span class="font-semibold" *ngIf="message.subject" translate>{{ message.subject }}</span>
      <!-- Translate message because it can contain translation key -->
      <ng-container *ngIf="message.body">
        <ng-container *ngTemplateOutlet="botMessageBody"> </ng-container>
      </ng-container>

      <ng-template #botMessageBody [ngSwitch]="message.format">
        <ng-container *ngSwitchCase="FormatEnum.TEXT">
          <div translate>{{ message.body }}</div>
        </ng-container>
        <ng-container *ngSwitchCase="FormatEnum.HTML">
          <div class="message__body__html" [innerHTML]="message.body | safeHtml"></div>
        </ng-container>
      </ng-template>
    </div>

    <!-- Attachments -->
    <div *ngIf="message?.attachments && message.attachments!.length > 0" class="auto-grid">
      <div class="border-1 surface-border border-round" *ngFor="let attachment of message.attachments">
        <main-file-download [file]="attachment.file!" [record]="record$ | async"></main-file-download>
      </div>
    </div>

    <div class="message__header flex flex-wrap justify-content-between align-items-center gap-2">
      <div class="message__metadata">
        <ng-container>
          <span>{{ message.sender! | getUserFullName }} </span>
          <span>-</span>
        </ng-container>
        <span class="underline-dotted" [tooltip]="message.createdAt | date : 'medium'">{{
          message.createdAt | date : "shortTime"
        }}</span>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyMessage>
  <span class="text-gray-500">{{ "COMMON.empty-message" | translate : { "default": "No message" } }}</span>
</ng-template>
