import { Component, Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from "@angular/router";
import { Observable, of } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { Logger, UntilDestroy, untilDestroyed } from "@core";
import { NavigationService } from "../services/navigation.service";

const log = new Logger("Shared/CanDeactivateGuard");

/**
 * Provides a guard for components that can be accessed only when a unit has a billing account
 */
@UntilDestroy()
@Injectable()
export class CanDeactivateGuard implements CanDeactivate<Component> {
  constructor(private router: Router, private navigationService: NavigationService) {}

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.navigationService.preventNavigation$.pipe(
      mergeMap((preventNavigation: boolean) => {
        if (!preventNavigation) {
          return of(true);
        }

        return this.navigationService.launchPreventNavigationDialog$().pipe(
          filter((accepted: boolean) => accepted !== undefined),
          map((accepted: boolean) => accepted),
          untilDestroyed(this),
        );
      }),
      map((canNavigate: boolean) => canNavigate),
      untilDestroyed(this),
    );
  }
}
