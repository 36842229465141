import { AbstractControl, ValidationErrors } from "@angular/forms";
import { PhoneHelper } from "../helpers/phone.helper";

export function internationalPhoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  if (!value) {
    return null;
  }

  try {
    return PhoneHelper.isValidPhoneNumber(value) ? null : { invalid: true };
  } catch (e) {}

  return { invalid: true };
}
