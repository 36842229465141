<ng-container *ngIf="record$ | async as record">
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <ng-container *ngIf="events$ | async as events">
      <p-timeline *ngIf="events.length > 0; else eventsEmpty" [value]="events" data-timeline="truncated opposite:none">
        <ng-template pTemplate="marker" let-event>
          <ui-user-avatar
            [user]="event.user"
            [showTooltip]="true"
            [size]="32"
            [attr.data-origin]="currentUser.userIdentifier !== event.user.userIdentifier ? 'other' : 'self'"
          ></ui-user-avatar>
        </ng-template>
        <ng-template pTemplate="content" let-event>
          <div class="flex flex-column">
            <span class="text-sm text-color">{{
              "RECORD.events.codes." + event.code | translate: { default: event.code }
            }}</span>
            <span
              class="text-sm text-color-secondary flex align-items-center"
              *ngIf="!(currentUser.userIdentifier !== event.user.userIdentifier)"
              >{{ "RECORD.record-events.you" | translate: { "default": "You" } }},
              {{ event.statusDate | timeAgo | async }}
              <i class="pi pi-calendar text-sm ml-1" [tooltip]="event.statusDate | date: 'medium'"></i>
            </span>
            <span
              class="text-sm text-color-secondary flex align-items-center"
              *ngIf="currentUser.userIdentifier !== event.user.userIdentifier"
              >{{ event.user | getUserFullName }}, {{ event.statusDate | timeAgo | async }}
              <i class="pi pi-calendar text-sm ml-1" [tooltip]="event.statusDate | date: 'medium'"></i>
            </span>
          </div>
        </ng-template>
      </p-timeline>

      <!-- Empty state -->
      <ng-template #eventsEmpty>
        <div class="border-round flex flex-column align-items-center py-4 lg:py-8 justify-content-center">
          <i class="msr-icon-event icon-3rem text-primary"></i>
          <h3 class="mb-0 px-3 text-center">
            {{ "RECORD.empty-states.events.title" | translate: { default: "Nothing to show here" } }}
          </h3>
          <p class="text-center mb-0 mt-2 measured-container" style="--measure: var(--legible-measure)">
            {{
              "RECORD.empty-states.events.subtitle"
                | translate
                  : {
                      default: "Files shared in this conversation will show here"
                    }
            }}
          </p>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
