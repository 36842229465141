import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { Logger } from "@core";
import { AuthorizationService } from "@app/@shared/services/authorization.service";

const log = new Logger("Record/CanCreateRecordGuard");

/**
 * Provides a guard for components that can be accessed only when a user can create a record in current unit
 */
export function canCreateRecordGuard(): CanActivateFn {
  return () => {
    const router: Router = inject(Router);
    const authorizationService: AuthorizationService = inject(AuthorizationService);

    return authorizationService.initialized$.pipe(
      filter((initialized: boolean) => initialized),
      map(() => {
        if (authorizationService.canCreateRecord()) {
          return true;
        }

        log.debug("Current user cannot create record in current unit, redirecting...");
        router.navigate(["/inbox"], { replaceUrl: true });

        return false;
      }),
    );
  };
}
