import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import RecordModel from "@app/@shared/models/record/record.model";
import IdentityModel from "@app/@shared/models/user/identity.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { RecordService } from "@app/record/services/record.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TranslateService } from "@ngx-translate/core";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "record-recipients-list",
  templateUrl: "./recipients-list.component.html",
  styleUrls: ["./recipients-list.component.scss"],
})
export class RecipientsListComponent implements OnInit, OnDestroy {
  @Input() enableAdd: boolean = true;
  @Input() enableRemove: boolean = true;
  @Input() addRecipientLabel: string = "";

  @Output() onAddRecipientClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoveRecipientClick: EventEmitter<RecordRecipientModel> = new EventEmitter<RecordRecipientModel>();

  RecordRoleEnum = RecordRoleEnum;
  currentIdentity$: Observable<IdentityModel>;
  record: RecordModel;
  sortedRecipients: RecordRecipientModel[] = [];

  constructor(
    private sessionAPIService: SessionAPIService,
    private translateService: TranslateService,
    private recordService: RecordService,
  ) {
    this.addRecipientLabel = this.translateService.instant("RECORD.recipients.add-recipient");

    this.recordService.record$.pipe(untilDestroyed(this)).subscribe((record: RecordModel) => (this.record = record));
    this.recordService.recipients$.pipe(untilDestroyed(this)).subscribe((recipients: RecordRecipientModel[]) => {
      this.sortedRecipients = recipients
        .filter((r: RecordRecipientModel) => r.role !== RecordRoleEnum.BOT)
        .sort((a: RecordRecipientModel, b: RecordRecipientModel) => {
          if (a.role === RecordRoleEnum.OWNER) {
            return -1;
          } else if (a.role === RecordRoleEnum.REVIEWER) {
            if (b.role === RecordRoleEnum.OWNER) {
              return Infinity;
            }
            return -1;
          } else if (b.role === RecordRoleEnum.OWNER) {
            return Infinity;
          } else {
            return a.toString.localeCompare(b.toString);
          }
        });
    });
  }

  ngOnInit(): void {
    this.currentIdentity$ = this.sessionAPIService.currentIdentity$;
  }

  handleAddRecipientClick() {
    this.onAddRecipientClick.emit();
  }

  handleRemoveRecipientClick(recipient: RecordRecipientModel) {
    this.onRemoveRecipientClick.emit(recipient);
  }

  ngOnDestroy() {}
}
