import { NgModule } from "@angular/core";
import { CommonModule, PercentPipe } from "@angular/common";
import { SafeHtmlPipe } from "@shared/pipes/safe-html.pipe";
import { GetUserInitialsPipe } from "./pipes/get-user-initials.pipe";
import { GetUserFullNamePipe } from "@shared/pipes/get-user-full-name.pipe";
import { GetRecipientInitialsPipe } from "@shared/pipes/get-recipient-initials.pipe";
import { UploadDropDirective } from "./directives/upload-drop.directive";
import { GetRecipientFullNamePipe } from "@shared/pipes/get-recipient-full-name.pipe";
import { GetUnitInitialsPipe } from "@shared/pipes/get-unit-initials.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { DatePipe } from "./pipes/date.pipe";
import { ButtonHoverDirective } from "./directives/button-hover.directive";
import { HumanDatePipe } from "@shared/pipes/human-date.pipe";
import { GUARDS } from "./guards";
import { TimeAgoPipe } from "./pipes/time-ago.pipe";
import { GetMimeTypeIconPipe } from "@shared/pipes/get-mime-type-icon.pipe";
import { ObfuscatedCardNumberPipe } from "./pipes/payment-method/obfuscated-card-number.pipe";
import { ObfuscatedIBANPipe } from "./pipes/payment-method/obfuscated-iban.pipe";
import { CardExpiryPipe } from "./pipes/payment-method/card-expiry.pipe";
import { DateFromTimestampPipe } from "./pipes/date-from-timestamp.pipe";
import { PricePipe } from "./pipes/price.pipe";
import { GetColorFromRecordUrgencyPipe } from "./pipes/get-color-from-record-urgency.pipe";
import { GetProgressionBetweenDatesPipe } from "./pipes/get-progression-between-dates.pipe";
import { InertHtmlPipe } from "./pipes/inert-html.pipe";
import { GetExtensionIconPipe } from "./pipes/get-extension-icon.pipe";
import { GetFileProviderIconPipe } from "./pipes/get-file-provider-icon.pipe";
import { CapitalizeCasePipe } from "./pipes/capitalize-case.pipe";

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [
    DatePipe,
    CapitalizeCasePipe,
    DateFromTimestampPipe,
    FileSizePipe,
    SafeHtmlPipe,
    GetColorFromRecordUrgencyPipe,
    GetExtensionIconPipe,
    GetMimeTypeIconPipe,
    GetFileProviderIconPipe,
    GetProgressionBetweenDatesPipe,
    GetUserInitialsPipe,
    GetRecipientInitialsPipe,
    GetUserFullNamePipe,
    GetRecipientFullNamePipe,
    GetUnitInitialsPipe,
    InertHtmlPipe,
    UploadDropDirective,
    ButtonHoverDirective,
    HumanDatePipe,
    TimeAgoPipe,
    ObfuscatedCardNumberPipe,
    ObfuscatedIBANPipe,
    CardExpiryPipe,
    PricePipe,
  ],
  exports: [
    DatePipe,
    CapitalizeCasePipe,
    DateFromTimestampPipe,
    FileSizePipe,
    TranslateModule,
    SafeHtmlPipe,
    GetColorFromRecordUrgencyPipe,
    GetProgressionBetweenDatesPipe,
    GetExtensionIconPipe,
    GetMimeTypeIconPipe,
    GetFileProviderIconPipe,
    GetUserInitialsPipe,
    GetRecipientInitialsPipe,
    GetUserFullNamePipe,
    GetRecipientFullNamePipe,
    GetUnitInitialsPipe,
    InertHtmlPipe,
    UploadDropDirective,
    ButtonHoverDirective,
    HumanDatePipe,
    TimeAgoPipe,
    ObfuscatedCardNumberPipe,
    ObfuscatedIBANPipe,
    CardExpiryPipe,
    PricePipe,
  ],
  providers: [GUARDS, DatePipe, FileSizePipe, PercentPipe],
})
export class SharedModule {}
