import { Component } from "@angular/core";
import { NotificationMessageModel } from "@app/@shared/models/notification/notification-message.model";
import { NotificationsService } from "@app/notifications/services/notifications.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { orderBy } from "lodash";
import { BehaviorSubject, Observable, Subscription, map, switchMap } from "rxjs";

@UntilDestroy()
@Component({
  selector: "notifications-list",
  templateUrl: "./notifications-list.component.html",
  styleUrls: ["./notifications-list.component.scss"],
})
export class NotificationsListComponent {
  isLoading$: Observable<boolean>;
  isLoadingMore$: Observable<boolean>;
  notifications$: BehaviorSubject<NotificationMessageModel[]> = new BehaviorSubject([]);

  constructor(private notificationsService: NotificationsService) {
    this.isLoading$ = notificationsService.isLoadingNotifications$;
    this.isLoadingMore$ = notificationsService.isLoadingMoreNotifications$;
    notificationsService
      .getAllNotifications()
      .pipe(
        map((items: NotificationMessageModel[]) =>
          orderBy(items, (item: NotificationMessageModel) => item.createdAt, "desc"),
        ),
      )
      .subscribe(this.notifications$);
  }

  handleMarkAllAsRead(event: any) {
    this.notificationsService
      .markAllNotificationsAs(true, true)
      .pipe(untilDestroyed(this))
      .subscribe(() => {});
  }

  handleLoadMore(event: any) {
    const subscription: Subscription = this.notificationsService
      .getNextNotificationsPage()
      .pipe(untilDestroyed(this))
      .subscribe(() => {});
  }
}
