import BaseModel from "@shared/models/base.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { ExportFormatEnum } from "@app/@shared/enums/export-format.enum";
import { ExportFileTypeEnum } from "@app/@shared/enums/export-file-type.enum";
import FileModel from "./file.model";

export default class FileExportModel extends BaseModel {
  // MINIMIZE (default)
  public exportIdentifier: string | null = null;
  public size: number | null = null;
  public format: ExportFormatEnum | null = null;
  public type: ExportFileTypeEnum | null = null;
  public exportTypeIdentifier: string | null = null;
  public createdAt: Date | null = null;
  public files: FileModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.files && Array.isArray(input.files)) this.files = input.files.map((f) => new FileModel().deserialize(f));

    return this;
  }
}
