import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import UnitModel from "@shared/models/domain/unit.model";
import UnitUserModel from "../models/domain/unit-user.model";
import { SessionAPIService } from "@shared/services/session-api.service";
import ConfigurationModel from "../models/masterdata/configuration.model";
import { ErrorCodeEnum } from "../enums/error-code.enum";
import { TranslateService } from "@ngx-translate/core";
import ListModel from "../models/masterdata/list.model";

@Injectable({
  providedIn: "root",
})
export class DomainAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private sessionAPIService: SessionAPIService,
  ) {}

  getUnitByUnitSlug(
    unitSlug?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<UnitModel> {
    const url = this._baseUrl + environment.services.methodUrls.domain.getUnit;
    const correlationId = v4();

    this.loaderService.addOperation("getUnit");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          unitSlug: unitSlug,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new UnitModel().deserialize(response["unit"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getUnit");
        }),
      );
  }

  getUnitUsers(
    unitSlug?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<UnitUserModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.domain.getUnitUsers.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getUnitUsers");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          unitSlug: unitSlug,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["users"].map((user) => new UnitUserModel().deserialize(user));
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getUnitUsers");
        }),
      );
  }

  updateUnitUserRole(
    unitSlug?: string,
    userIdentifier?: string,
    userRole?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url =
      this._baseUrl + environment.services.methodUrls.domain.updateUnitUserRole.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("updateUnitUserRole");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          unitSlug,
          userIdentifier,
          userRole,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateUnitUserRole");
        }),
      );
  }

  getUnitChildrenUnits(
    unitSlug?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<UnitModel[]> {
    const url =
      this._baseUrl + environment.services.methodUrls.domain.getUnitChildrenUnits.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getUnitChildrenUnits");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          unitSlug: unitSlug,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["units"].map((unit) => new UnitModel().deserialize(unit));
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getUnitChildrenUnits");
        }),
      );
  }

  saveUnit(unit: UnitModel, responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE]): Observable<UnitModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.domain.saveUnit.replace(
        "{unitSlug}",
        (this.sessionAPIService.unit && this.sessionAPIService.unit.unitSlug) || environment.defaultUnit,
      );

    const correlationId = v4();

    this.loaderService.addOperation("saveUnit");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          unit: unit,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new UnitModel().deserialize(response["unit"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveUnit");
        }),
      );
  }

  getConfiguration(
    unitSlug?: string,
    configClass?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ConfigurationModel> {
    const url = this._baseUrl + environment.services.methodUrls.domain.getConfiguration.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getConfiguration");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          configClass,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new ConfigurationModel().deserialize(response["configuration"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getConfiguration");
        }),
      );
  }

  saveUnitConfiguration(
    unitSlug?: string,
    configuration?: ConfigurationModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ConfigurationModel> {
    const url =
      this._baseUrl + environment.services.methodUrls.domain.saveUnitConfiguration.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("saveUnitConfiguration");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          configuration,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new ConfigurationModel().deserialize(response["configuration"]);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error && error.error) {
            const { code } = error.error;
            if (code === ErrorCodeEnum.SMTP_CONFIGURATION_ERROR) {
              this.messageService.add(
                MessageHelper.createTextMessage(
                  MessageSeverityEnum.SEVERITY_ERROR,
                  this.translateService.instant("ERRORS.SMTP_CONFIGURATION_ERROR.title"),
                  error.error.message,
                ),
              );
            } else {
              this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
            }
          }

          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveUnitConfiguration");
        }),
      );
  }

  getList(
    unitSlug?: string,
    listClass?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ListModel> {
    const url = this._baseUrl + environment.services.methodUrls.domain.getList.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getList");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          listClass,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new ListModel().deserialize(response["list"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getList");
        }),
      );
  }

  saveUnitList(
    unitSlug?: string,
    list?: ListModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ListModel> {
    const url = this._baseUrl + environment.services.methodUrls.domain.saveUnitList.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("saveUnitList");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          list,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new ListModel().deserialize(response["list"]);
        }),
        catchError((error: HttpErrorResponse) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveUnitList");
        }),
      );
  }
}
