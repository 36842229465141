import { ErrorCodeEnum } from "@shared/enums/error-code.enum";
import BaseModel from "@shared/models/base.model";

export default class ErrorModel extends BaseModel {
  status?: number;
  code?: ErrorCodeEnum;
  message?: string;
  devMessage?: string;

  constructor() {
    super();
  }
}
