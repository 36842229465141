import BaseModel from "@shared/models/base.model";
import PaginationModelV1 from "../pagination-v1.model";
import ExportModel from "./export.model";

export default class ExportResultModel extends BaseModel {
  // MINIMIZE (default)
  public data: ExportModel[] = null;
  public metadata: PaginationModelV1 = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.data && Array.isArray(input.data))
      this.data = input.data.map((exportObj: any) => new ExportModel().deserialize(exportObj));

    return this;
  }
}
