<ng-container *ngIf="isLoaded$ | async; else loadingTemplate">
  <div
    #rootContainer
    [style]="themingService.customStyles$ | async"
    class="root-container surface-brand text-color min-h-screen flex flex-column"
    [ngClass]="{ 'hidden-navigation-header': (themingService.navigationBarVariant$ | async) == 'hidden' }"
  >
    <main-header></main-header>

    <main class="root-container__main flex flex-column w-full flex-grow-1">
      <router-outlet></router-outlet>
    </main>
    <!-- <main-footer></main-footer> -->
    <ui-toaster></ui-toaster>
    <ui-loader class="loader"></ui-loader>
    <main-consents-panel [user]="this.sessionAPIService.currentUser"></main-consents-panel>
    <main-disclaimer-panel [showDisclaimer]="showDisclaimer" (close)="showDisclaimer = false"></main-disclaimer-panel>
    <!-- Confirm dialog -->
    <p-confirmDialog
      [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
      [style]="{ width: 'var(--xsmall-measure)' }"
      [blockScroll]="true"
    >
    </p-confirmDialog>
  </div>
</ng-container>

<ng-template #loadingTemplate>
  <div class="h-full w-full flex align-items-center justify-content-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>
