import { Injectable } from "@angular/core";
import { Logger } from "@app/@core";
import { environment } from "@env/environment";
import { Socket, SocketIoConfig } from "ngx-socket-io";
import * as io from "socket.io-client";

const log = new Logger("NotificationsWSService");

@Injectable({
  providedIn: "root",
})
export class NotificationsWSService extends Socket {
  private socketConfig: SocketIoConfig;

  constructor() {
    const socketConfig: SocketIoConfig = {
      url: `${environment.services.baseUrls.novuWSUrl}`,
      options: {
        autoConnect: false,
        reconnectionDelayMax: 10000,
        transports: ["websocket"],
      },
    };

    super(socketConfig);
    this.socketConfig = socketConfig;
  }

  /**
   * Providing a connectWithToken method allows to add a token in socket config
   */
  connectWithToken(token: string) {
    this.socketConfig = {
      ...this.socketConfig,
      options: {
        ...this.socketConfig.options,
        query: {
          token,
        },
      },
    };

    // from https://github.com/rodgc/ngx-socket-io/blob/861252520964f8cbd6d1d0946628d40d811589e3/src/socket-io.service.ts#L23
    const ioFunc = (io as any).default ? (io as any).default : io;
    this.ioSocket = ioFunc(this.socketConfig.url, this.socketConfig.options);
    return this.connect();
  }
}
