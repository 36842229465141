<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="dialog-nested-content">
    <ng-container *ngIf="isEditingSigner">
      <p class="text-lg font-medium mb-1 mt-0">
        {{ "RECORD.recipients.fill-out-signer.title" | translate }}
      </p>
      <div
        class="flex gap-2 p-2 align-items-baseline border-round border-1 border-blue-500 bg-blue-100 text-blue-600 mb-4"
        style="width: fit-content"
      >
        <span>
          <i class="pi pi-question-circle text-lg font-medium" style="vertical-align: middle"></i>
        </span>
        <span class="text-sm">
          {{ "RECORD.recipients.contact-not-updated" | translate }}
        </span>
      </div>
    </ng-container>
    <!-- Recipient Email address -->
    <div class="field">
      <label for="emailAddress">{{
        "COMMON.fields.email-address.label" | translate : { "default": "Email address" }
      }}</label>
      <input
        pInputText
        type="email"
        id="emailAddress"
        formControlName="emailAddress"
        class="w-full"
        [readOnly]="readonlyEmail"
        [placeholder]="'COMMON.fields.email-address.placeholder' | translate"
        [ngClass]="{
          'ng-invalid ng-dirty': form.get('emailAddress')?.invalid,
          'surface-100 cursor-not-allowed': readonlyEmail
        }"
      />
      <div [hidden]="form.get('emailAddress')?.valid">
        <small class="p-error flex flex-column">
          <span *ngIf="form.get('emailAddress')?.errors?.required">{{
            "COMMON.fields.email-address.errors.required" | translate : { "default": "Email address is required" }
          }}</span>
          <span *ngIf="form.get('emailAddress')?.errors?.email">{{
            "COMMON.fields.email-address.errors.invalid" | translate : { "default": "Email address must be valid" }
          }}</span>
          <span *ngIf="form.get('emailAddress')?.errors?.emailAlreadyUsed">{{
            "COMMON.fields.email-address.errors.already-used"
              | translate : { "default": "Email address is already used" }
          }}</span>
          <span *ngIf="form.get('emailAddress')?.errors?.isSelfEmail">{{
            "COMMON.fields.email-address.errors.is-self-email"
              | translate : { "default": "Email address can't be yours" }
          }}</span>
        </small>
      </div>
    </div>

    <!-- Phone number input -->
    <div class="field">
      <label for="phoneNumber" class="block">{{
        "COMMON.fields.phone-number.label" | translate : { "default": "Phone number" }
      }}</label>
      <ui-phone-input
        id="phoneNumber"
        formControlName="phoneNumber"
        [selectedCountryISO]="phoneCountryISO"
        [enablePlaceholder]="true"
        [ngClass]="{ 'ng-invalid ng-dirty': form.get('phoneNumber')?.invalid }"
      ></ui-phone-input>
      <div [hidden]="form.get('phoneNumber')?.valid">
        <small class="p-error flex flex-column">
          <span *ngIf="form.get('phoneNumber')?.errors?.required">{{
            "COMMON.fields.phone-number.errors.required" | translate : { "default": "Phone number is required" }
          }}</span>
          <span *ngIf="form.get('phoneNumber')?.errors?.validatePhoneNumber">{{
            "COMMON.fields.phone-number.errors.invalid"
              | translate
                : {
                    "default":
                      "Phone number must be
            valid"
                  }
          }}</span>
        </small>
      </div>
    </div>

    <div class="flex flex-column sm:flex-row sm:gap-3">
      <!-- Field: Firstname -->
      <div class="field sm:w-full">
        <label for="firstName">{{ "COMMON.fields.first-name.label" | translate : { "default": "First name" } }}</label>
        <input
          pInputText
          type="text"
          id="firstName"
          formControlName="firstName"
          class="w-full"
          [ngClass]="{
            'ng-invalid ng-dirty': form.get('firstName')?.invalid
          }"
        />
        <div [hidden]="form.get('firstName')?.valid">
          <small class="p-error flex flex-column">
            <span *ngIf="form.get('firstName')?.errors?.required">{{
              "COMMON.fields.first-name.errors.required" | translate : { "default": "First name is required" }
            }}</span>
          </small>
        </div>
      </div>

      <!-- Field: Lastname -->
      <div class="field sm:w-full">
        <label for="lastName">{{ "COMMON.fields.last-name.label" | translate : { "default": "Last name" } }}</label>
        <input
          pInputText
          type="text"
          id="lastName"
          formControlName="lastName"
          class="w-full"
          [ngClass]="{
            'ng-invalid ng-dirty': form.get('lastName')?.invalid
          }"
        />
        <div [hidden]="form.get('lastName')?.valid">
          <small class="p-error flex flex-column">
            <span *ngIf="form.get('lastName')?.errors?.required">{{
              "COMMON.fields.last-name.errors.required" | translate : { "default": "Last name is required" }
            }}</span>
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="dialog-nested-footer flex flex-column justify-content-end">
    <div
      class="flex gap-2 p-2 align-items-baseline border-round border-1 border-red-500 bg-red-100 text-red-600 mb-4"
      style="width: fit-content"
      *ngIf="isEditingSigner && !recipient.isValid(true)"
    >
      <span>
        <i class="pi pi-exclamation-circle text-lg font-medium" style="vertical-align: middle"></i>
      </span>
      <span class="text-sm">
        {{ "RECORD.recipients.required-for-signing" | translate }}
      </span>
    </div>
    <div class="flex flex-wrap gap-3 justify-content-end">
      <button
        pButton
        [label]="'COMMON.buttons.cancel' | translate : { 'default': 'Cancel' }"
        type="button"
        (click)="cancel()"
        class="p-button-outlined p-button-sm"
      ></button>
      <button
        pButton
        [label]="'COMMON.buttons.update' | translate : { 'default': 'Update' }"
        type="submit"
        class="p-button-sm"
      ></button>
    </div>
  </div>
</form>
