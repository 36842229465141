<p-avatarGroup *ngIf="sortedRecipients.length > maxAvatars">
  <div *ngIf="showFirstRecipient">{{ sortedRecipients[0] | getRecipientFullName }}</div>
  <ui-recipient-avatar
    *ngFor="let recipient of sortedRecipients.slice(0, maxAvatars)"
    size="small"
    [showAvatar]="showAvatar"
    [recipient]="recipient"
    [showTooltip]="true"
    [tooltipPlacement]="tootlipPlacement"
  ></ui-recipient-avatar>
  <p-avatar
    shape="circle"
    [label]="'+' + (sortedRecipients.length - maxAvatars)"
    [style]="{
      'background-color': 'var(--cyan-100)',
      'color': 'var(--surface-800)',
      'margin-left': maxAvatars > 0 ? '-1rem' : '0'
    }"
  ></p-avatar>
</p-avatarGroup>
<p-avatarGroup *ngIf="sortedRecipients.length <= maxAvatars">
  <div *ngIf="showFirstRecipient">{{ sortedRecipients[0] | getRecipientFullName }}</div>
  <ui-recipient-avatar
    *ngFor="let recipient of sortedRecipients"
    [showAvatar]="showAvatar"
    size="small"
    [recipient]="recipient"
    [showTooltip]="true"
    [tooltipPlacement]="tootlipPlacement"
  ></ui-recipient-avatar>
</p-avatarGroup>
