import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "@shared/models/base.model";

export default class RecordRecipientMetadataModel extends BaseModel {
  public lastReadAt: Date | null = null;
  public read: boolean = false;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.lastReadAt) this.lastReadAt = DateHelper.parseDate(input.lastReadAt);

    return this;
  }
}
