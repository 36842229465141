import PaginationModel from "../pagination.model";
import RecordModel from "../record/record.model";

export default class InboxResultModel extends PaginationModel {
  public records: RecordModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.records) this.records = input.records.map((r) => new RecordModel().deserialize(r));

    return this;
  }
}
