import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import RecordModel from "@app/@shared/models/record/record.model";
import IdentityModel from "@app/@shared/models/user/identity.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import { RecordService } from "@app/record/services/record.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";
import { Observable } from "rxjs";

@UntilDestroy()
@Component({
  selector: "record-recipients-selector",
  templateUrl: "./recipients-selector.component.html",
  styleUrls: ["./recipients-selector.component.scss"],
})
export class RecipientsSelectorComponent implements OnInit, OnDestroy {
  showRecipientEditForm: boolean = false;
  selectedRecipient: RecordRecipientModel;

  @Output() onRecipientsChanged: EventEmitter<RecordRecipientModel[]> = new EventEmitter<RecordRecipientModel[]>();
  @Input() isSubmitting: boolean = false;
  RecordRoleEnum = RecordRoleEnum;
  currentIdentity$: Observable<IdentityModel>;
  record: RecordModel;
  selectedRecipients: RecordRecipientModel[] = [];
  sortedRecipients: RecordRecipientModel[] = [];

  constructor(
    private sessionAPIService: SessionAPIService,
    private recordService: RecordService,
    public themingService: ThemingService,
  ) {
    this.recordService.record$.pipe(untilDestroyed(this)).subscribe((record: RecordModel) => (this.record = record));
    this.recordService.recipients$.pipe(untilDestroyed(this)).subscribe((recipients: RecordRecipientModel[]) => {
      this.sortedRecipients = recipients
        .filter((r: RecordRecipientModel) => r.role !== RecordRoleEnum.BOT)
        .sort((a: RecordRecipientModel, b: RecordRecipientModel) => {
          if (a.role === RecordRoleEnum.OWNER) {
            return -1;
          } else if (b.role === RecordRoleEnum.OWNER) {
            return Infinity;
          } else {
            return a.toString.localeCompare(b.toString);
          }
        });
    });
  }

  ngOnInit(): void {
    this.currentIdentity$ = this.sessionAPIService.currentIdentity$;
  }

  handleSelectionChanged() {
    this.onRecipientsChanged.emit(this.selectedRecipients);
  }
  onSaveRecipient(recipient: RecordRecipientModel) {
    if (this.sortedRecipients.indexOf(this.selectedRecipient) > -1) {
      let reference = this.sortedRecipients.find((r) => r === this.selectedRecipient);
      // Retrive the old referecne and edit values
      let updatedRecipient = Object.assign(new RecordRecipientModel(), reference);
      updatedRecipient.firstName = recipient.firstName;
      updatedRecipient.lastName = recipient.lastName;
      updatedRecipient.phoneNumber = recipient.phoneNumber;
      this.sortedRecipients[this.sortedRecipients.indexOf(this.selectedRecipient)] = updatedRecipient;
    }
    this.selectedRecipient = null;
    this.showRecipientEditForm = false;
  }
  handleEditClose() {
    this.showRecipientEditForm = false;
    this.selectedRecipient = null;
  }
  doEdit(event: Event, recipient: RecordRecipientModel) {
    if (event) event.stopPropagation();
    this.selectedRecipient = recipient;
    this.showRecipientEditForm = true;
  }

  ngOnDestroy() {}
}
