import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationMessageModel } from "@app/@shared/models/notification/notification-message.model";
import { NotificationsService } from "@app/notifications/services/notifications.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Message, MessageService } from "primeng/api";

@UntilDestroy()
@Component({
  selector: "ui-toaster",
  templateUrl: "./toaster.component.html",
  styleUrls: ["./toaster.component.scss"],
})
export class ToasterComponent implements OnInit {
  constructor(
    private router: Router,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {}

  handleNotificationClick(event: any, message: Message) {
    const notificationMessage: NotificationMessageModel = message.data.notificationMessage;

    if (notificationMessage) {
      this.notificationsService
        .markNotificationAs(notificationMessage, true, true)
        .pipe(untilDestroyed(this))
        .subscribe((result: NotificationMessageModel) => {
          if (result.ctaUrl) {
            this.router.navigate([result.ctaUrl], { replaceUrl: true, onSameUrlNavigation: "reload" });
            this.messageService.clear();
          } else {
          }
        });
    } else {
      this.messageService.clear();
    }
  }
}
