import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import SignatureAttachmentModel from "@app/@shared/models/signature/signature-attachment.model";
import SignatureModel from "@app/@shared/models/signature/signature.model";
import { TranslateService } from "@ngx-translate/core";
import GetRecipientSignatureModel from "../models/signature/get-recipient-signature.model";

@Injectable({
  providedIn: "root",
})
export class SignatureAPIService {
  private _baseUrl: string = environment.services.baseUrls.partnerApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  createSignature(
    recordIdentifier: string,
    recordMessageIdentifier: string,
    method: string,
    attachments: SignatureAttachmentModel[],
    signers: string[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<SignatureModel> {
    const url = this._baseUrl + environment.services.methodUrls.signature.createSignature;
    const correlationId = v4();

    this.loaderService.addOperation("createSignature");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier,
          recordMessageIdentifier,
          method,
          attachments,
          signers,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new SignatureModel().deserialize(response["signature"]);
        }),
        catchError((error) => {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_ERROR,
            summary: this.translateService.instant("RECORD.record-signature.errors.unidentified.title"),
            detail: this.translateService.instant("RECORD.record-signature.errors.unidentified.subtitle"),
          });
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("createSignature");
        }),
      );
  }
  cancelSignature(
    recordIdentifier: string,
    recordMessageIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.signature.cancelSignature;
    const correlationId = v4();

    this.loaderService.addOperation("cancelSignature");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          recordMessageIdentifier: recordMessageIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("cancelSignature");
        }),
      );
  }

  declineSignature(
    recordIdentifier: string,
    recordMessageIdentifier: string,
    identityIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.signature.declineSignature;
    const correlationId = v4();

    this.loaderService.addOperation("declineSignature");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          recordMessageIdentifier: recordMessageIdentifier,
          identityIdentifier: identityIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("declineSignature");
        }),
      );
  }

  startRecipientSignature(
    recordIdentifier: string,
    recordMessageIdentifier: string,
    identityIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.signature.startRecipientSignature;
    const correlationId = v4();

    this.loaderService.addOperation("startRecipientSignature");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          recordMessageIdentifier: recordMessageIdentifier,
          identityIdentifier: identityIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("startRecipientSignature");
        }),
      );
  }

  validateRecipientSignature(
    recordIdentifier: string,
    recordMessageIdentifier: string,
    identityIdentifier: string,
    code: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.signature.validateRecipientSignature;
    const correlationId = v4();

    this.loaderService.addOperation("validateRecipientSignature");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          recordMessageIdentifier: recordMessageIdentifier,
          identityIdentifier: identityIdentifier,
          code: code,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("validateRecipientSignature");
        }),
      );
  }

  getRecipientSignature(
    recordIdentifier: string,
    recordMessageIdentifier: string,
    identityIdentifier: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<GetRecipientSignatureModel> {
    const url = this._baseUrl + environment.services.methodUrls.signature.getRecipientSignature;
    const correlationId = v4();

    this.loaderService.addOperation("getRecipientSignature");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifier: recordIdentifier,
          recordMessageIdentifier: recordMessageIdentifier,
          identityIdentifier: identityIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new GetRecipientSignatureModel().deserialize(response);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecipientSignature");
        }),
      );
  }
}
