import BaseModel from "@shared/models/base.model";
import { get } from "lodash";
import BillingPriceModel from "./price.model";

export default class BillingProductModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public name: string | null = null;
  public description: string | null = null;
  public defaultPrice: BillingPriceModel;
  public signaturePrice: BillingPriceModel;
  public metadata: JsonValue = {};

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.defaultPrice) this.defaultPrice = new BillingPriceModel().deserialize(input.defaultPrice);

    if (input.signaturePrice) this.signaturePrice = new BillingPriceModel().deserialize(input.signaturePrice);

    return this;
  }

  /**
   * Helpers
   */
  get hasSignature(): boolean {
    return get(this.metadata, ["hasSignature"], false) === "true";
  }

  get hasAPI(): boolean {
    return get(this.metadata, ["hasAPI"], false) === "true";
  }

  get order(): number {
    let order = get(this.metadata, ["order"], Infinity).toString();
    return parseInt(order);
  }

  get isYearlyPrice(): boolean {
    return this.defaultPrice.isYearly;
  }

  get isMonthlyPrice(): boolean {
    return this.defaultPrice.isMonthly;
  }
}
