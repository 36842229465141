<!-- 
    Contact Manager
 -->
<header class="flex flex-wrap align-items-center justify-content-between gap-3 mb-4">
  <!-- CONTACTS SELECTED  -->
  <div
    class="custom-button-set flex align-items-center border-round border-1"
    style="border-color: var(--border-color)"
    *ngIf="selectedContacts?.length > 0"
  >
    <!-- Contacts count -->
    <button
      type="button"
      pButton
      (click)="unselectAllContacts()"
      [label]="'LAYOUTS.adress-book.contact-list.count' | translate : { count: selectedContacts?.length }"
      class="hidden sm:flex p-button-text p-button-secondary text-primary p-button-sm flex-grow-1 md:flex-grow-0 border-noround no-shadow"
    ></button>
    <!-- Manage labels -->
    <button
      type="button"
      pButton
      (click)="handleTagsManagementClick($event)"
      icon="pi pi-tag"
      [label]="'ADDRESS-BOOK.manager.table.buttons.add-tags' | translate"
      class="p-button-text p-button-secondary p-button-sm flex-grow-1 md:flex-grow-0 border-noround no-shadow"
    ></button>
    <!-- Export As CSV -->
    <button
      type="button"
      pButton
      (click)="exportSelectedContacts()"
      icon="pi pi-file-export"
      [label]="
        'ADDRESS-BOOK.manager.table.buttons.export-contacts' | translate : { default: 'Export selected contacts' }
      "
      class="p-button-text p-button-secondary p-button-sm flex-grow-1 md:flex-grow-0 border-noround no-shadow"
    ></button>
    <!-- Delete contacts -->
    <button
      type="button"
      pButton
      (click)="handleDeleteContacts()"
      icon="pi pi-trash"
      [label]="'ADDRESS-BOOK.manager.table.buttons.delete-contact' | translate : { 'default': 'Delete' }"
      class="p-button-text p-button-secondary p-button-sm flex-grow-1 md:flex-grow-0 border-noround no-shadow"
    ></button>
  </div>

  <!-- NO SELECTION -->
  <div
    class="flex flex-column md:flex-row gap-3 w-full justify-content-between border-1"
    style="border-color: transparent"
    *ngIf="!(selectedContacts?.length > 0)"
  >
    <span class="p-input-icon-left w-full md:w-20rem">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="search"
        style="--border-radius: 20px"
        class="p-inputtext-sm w-full"
        (input)="contactsTable.filterGlobal($any($event.target).value, 'equals')"
        [placeholder]="'ADDRESS-BOOK.manager.table.search.label' | translate : { 'default': 'Search' }"
      />
    </span>

    <div class="flex flex-wrap-reverse align-items-end gap-3 w-full md:w-auto">
      <button
        type="button"
        pButton
        [routerLink]="['/contacts/import']"
        [label]="'ADDRESS-BOOK.manager.table.buttons.import-contact' | translate : { 'default': 'Import contacts' }"
        class="p-button-outlined p-button-sm flex-grow-1 md:flex-grow-0"
      ></button>
      <button
        type="button"
        pButton
        (click)="displayContactCreationModal()"
        [label]="'ADDRESS-BOOK.manager.table.buttons.create-contact' | translate : { 'default': 'Create contact' }"
        class="flex-grow-1 md:flex-grow-0 p-button-sm"
      ></button>
    </div>
  </div>
</header>
<p-table
  [value]="contacts$ | async"
  [(selection)]="selectedContacts"
  selectionMode="multiple"
  dataKey="contactIdentifier"
  responsiveLayout="scroll"
  [loading]="isLoading$ | async"
  [rowHover]="true"
  [lazy]="true"
  (onLazyLoad)="loadContactsLazy($event)"
  [paginator]="total > 0"
  [rows]="10"
  [totalRecords]="total"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="paginationTemplate"
  [rowsPerPageOptions]="[10, 25, 50, 100]"
  #contactsTable
  styleClass="p-datatable-sm contactsTable"
>
  <ng-template pTemplate="header">
    <tr *ngIf="total > 0">
      <th style="width: 2rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of cols" [pSortableColumn]="col.field">
        <div class="flex align-items-center">
          {{ col.header }} <p-sortIcon class="flex-shrink-0" [field]="col.field"></p-sortIcon>
        </div>
      </th>
      <!-- Tags Header -->
      <th>
        <div class="flex align-items-center">
          <span>
            {{ "RECORD.fields.tags.label" | translate : { default: "Tags" } }}
          </span>
          <button
            pButton
            type="button"
            [appendTo]="'body'"
            tooltipStyleClass="generic-tooltip"
            [showDelay]="300"
            [pTooltip]="
              filteredTags?.length > 0
                ? ('ADDRESS-BOOK.contact-tags.filter.active' | translate : { default: 'Your contacts are filtered' })
                : ('ADDRESS-BOOK.contact-tags.filter.prompt' | translate : { default: 'Click here to filter by tags' })
            "
            icon="pi pi-filter text-xs"
            style="--p-button-width: 1.5rem; --p-button-height: 1.5rem; --border-radius: 2px"
            class="p-column-filter-menu-button p-button-secondary p-button-text p-0 ml-1"
            (click)="handleTagsFilterClick($event)"
            [ngClass]="{ 'filter-active': filteredTags?.length > 0 }"
          ></button>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contact>
    <tr class="py-1 sm:py-0">
      <td class="sm:py-2 md:flex lg:py-3">
        <p-tableCheckbox [value]="contact"></p-tableCheckbox>
      </td>
      <td
        class="cursor-pointer py-1 sm:py-2 lg:py-3"
        [attr.data-label]="col.header"
        *ngFor="let col of cols"
        [ngClass]="col.classnames"
        (click)="viewContact(contact)"
      >
        {{ contact[col.field] }}
      </td>
      <!-- TAGS -->
      <td
        class="cursor-pointer py-1 sm:py-2 lg:py-3"
        [attr.data-label]="'COMMON.fields.tags.label' | translate : { default: 'lastName' }"
        (click)="viewContact(contact)"
      >
        <div class="flex gap-1 lg:pr-2">
          <ui-badge
            cssMaxWidth="18ch"
            *ngIf="contact.tags?.[0]"
            variant="tag"
            size="small"
            [label]="contact.tags?.[0]"
          ></ui-badge>
          <ui-badge
            [tooltip]="contact.tags?.slice(1).join(', ')"
            [delay]="300"
            [tooltipAppendToBody]="true"
            *ngIf="contact.tags?.length > 1"
            variant="tag"
            size="small"
            [label]="'+' + (contact.tags?.length - 1)"
          ></ui-badge>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <div
      class="surface-card border-round flex flex-column align-items-center py-4 lg:py-8 justify-content-center"
      style="min-height: 20rem"
    >
      <i class="pi pi-users text-4xl"></i>
      <h3 class="mb-0 px-3 text-center">
        {{
          hasActiveFilters
            ? ("ADDRESS-BOOK.search.empty" | translate : { default: "No contacts found" })
            : ("ADDRESS-BOOK.empty-states.table.title" | translate : { default: "ADDRESS-BOOK" })
        }}
      </h3>
      <p
        *ngIf="!hasActiveFilters"
        class="text-center mb-0 mt-2 measured-container"
        style="--measure: var(--legible-measure)"
      >
        {{
          "ADDRESS-BOOK.empty-states.table.subtitle"
            | translate
              : {
                  default: "Manage your contacts and send new conversations faster."
                }
        }}
      </p>
      <!-- CLEAR ALL FILTERS -->
      <button
        pButton
        type="button"
        class="p-button-sm p-button-outlined py-2 mt-3"
        *ngIf="hasActiveFilters"
        (click)="clearFilters()"
        [label]="'COMMON.buttons.reset-filters' | translate : { default: 'Reset filters' }"
      ></button>
    </div>
  </ng-template>
</p-table>

<!-- Creation Modal -->
<p-dialog
  [(visible)]="showContactCreationModal"
  [modal]="true"
  position="center"
  [header]="'ADDRESS-BOOK.manager.create-modal.header' | translate : { 'default': 'Create contacts' }"
  [draggable]="false"
  [maximizable]="true"
  [baseZIndex]="10000"
  [dismissableMask]="!isSubmitting"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="{ width: 'var(--xsmall-measure)' }"
  styleClass="dialog-nested"
>
  <ng-template pTemplate="content">
    <contacts-contact-form
      [isLoading]="isLoading$ | async"
      [isSubmitting]="isSubmitting"
      (onCreateContact)="handleCreateContact($event)"
      [suggestions]="allContactTags"
      (onCancel)="hideContactCreationModal()"
    ></contacts-contact-form>
  </ng-template>
</p-dialog>

<!-- Tags Filtering -->
<p-overlayPanel
  #tagsFilterPanel
  styleClass="generic-overlay-panel tagsFilterPanel"
  [style]="themingService.customStyles$ | async"
>
  <ng-template pTemplate="content">
    <div class="generic-overlay-panel__main">
      <p-listbox
        [options]="allContactTagsOptions"
        [(ngModel)]="filteredTags"
        (onChange)="handleFilterByTags($event)"
        [checkbox]="true"
        [filter]="true"
        filterBy="label"
        optionLabel="label"
        [showToggleAll]="false"
        optionValue="value"
        [multiple]="true"
        [metaKeySelection]="false"
        [emptyFilterMessage]="'primeng.emptyFilterMessage' | translate"
        #tagsFilterListbox
      >
        <ng-template pTemplate="filter" let-options="options">
          <div class="p-listbox-filter-container">
            <div class="field">
              <label class="text-sm" for="tagsFilterInput">
                {{ "ADDRESS-BOOK.contact-tags.filter.label" | translate : { default: "Contacts that contains" } }}
              </label>
              <p-autoComplete
                [attr.aria-label]="
                  'ADDRESS-BOOK.contact-tags.filter.label' | translate : { default: 'Contacts that contains' }
                "
                (input)="options.filter($event)"
                [(ngModel)]="filteredTags"
                (ngModelChange)="handleAutocompleteTagChange($event)"
                [forceSelection]="false"
                [completeOnFocus]="false"
                optionLabel="label"
                inputId="tagsFilterInput"
                [showClear]="true"
                [unique]="true"
                [multiple]="true"
                (onClear)="handleClearFilterAutocompleteInput()"
              >
                <ng-template pTemplate="clearicon">
                  <i class="pi pi-times text-sm"></i>
                </ng-template>
                <ng-template pTemplate="loadingicon">
                  <!-- Leave empty to hide the spinner -->
                </ng-template>
                <ng-template pTemplate="removetokenicon">
                  <i class="pi pi-times-circle text-xs"></i>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        </ng-template>
      </p-listbox>
    </div>
  </ng-template>
</p-overlayPanel>

<!-- Add tags to selected Contacts -->
<p-overlayPanel
  #addTagsPanel
  styleClass="generic-overlay-panel tagsFilterPanel"
  [style]="themingService.customStyles$ | async"
>
  <ng-template pTemplate="content">
    <div class="generic-overlay-panel__main">
      <p-listbox
        [options]="allContactTagsOptions"
        [ngModel]="selectedContactsNewTags"
        (ngModelChange)="handleAddNewTagsChange($event)"
        [checkbox]="true"
        [filter]="true"
        filterBy="label"
        optionLabel="label"
        [showToggleAll]="false"
        optionValue="value"
        [multiple]="true"
        [metaKeySelection]="false"
        [emptyFilterMessage]="'primeng.emptyFilterMessage' | translate"
        #addTagsListbox
      >
        <ng-template pTemplate="filter" let-options="options">
          <div class="p-listbox-filter-container">
            <div class="field mb-0">
              <label class="text-sm" for="addTagsInput">
                {{
                  "ADDRESS-BOOK.contact-tags.add.label" | translate : { default: "Select tags to add to the contacts" }
                }}
              </label>
              <p-autoComplete
                [attr.aria-label]="
                  'ADDRESS-BOOK.contact-tags.add.label' | translate : { default: 'Select tags to add to the contacts' }
                "
                (input)="options.filter($event)"
                [ngModel]="selectedContactsNewTags"
                (ngModelChange)="handleAddNewTagsChange($event)"
                [forceSelection]="false"
                [completeOnFocus]="false"
                optionLabel="label"
                inputId="addTagsInput"
                [showClear]="true"
                [unique]="true"
                [multiple]="true"
                (onClear)="handleClearAddAutocompleteInput()"
              >
                <ng-template pTemplate="clearicon">
                  <i class="pi pi-times text-sm"></i>
                </ng-template>
                <ng-template pTemplate="loadingicon">
                  <!-- Leave empty to hide the spinner -->
                </ng-template>
                <ng-template pTemplate="removetokenicon">
                  <i class="pi pi-times-circle text-xs"></i>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="empty">
          <ng-container *ngIf="addTagsListbox.filterValue">
            <p-button
              type="button"
              styleClass="text-left p-button-sm p-button-text py-2 px-1 flex  w-full border-noround"
              [style]="{ 'background-color': 'rgba(var(--primary-color-rgb), 0.08)', 'margin-bottom': '0.75rem' }"
              (click)="handleCreateAndAddTag(addTagsListbox.filterValue)"
            >
              <ng-template pTemplate="content">
                <div class="flex px-1 align-items-baseline w-full">
                  <span class="font-medium">
                    {{ "RECORD.record-tags.buttons.create" | translate : { default: "Create tag" } }}
                  </span>
                </div>
                <ui-badge
                  cssMaxWidth="20ch"
                  size="small"
                  variant="suggestion"
                  icon="pi pi-plus-circle text-sm opacity-70"
                  class="ml-1"
                  [label]="addTagsListbox.filterValue"
                ></ui-badge>
              </ng-template>
            </p-button>
          </ng-container>
          <ng-container *ngIf="!addTagsListbox.filterValue">
            {{ "primeng.emptyFilterMessage" | translate }}
          </ng-container>
        </ng-template>
        <ng-template pTemplate="footer">
          <button
            type="button"
            pButton
            (click)="handleAddNewTagsSubmit()"
            [disabled]="!(selectedContactsNewTags?.length > 0) || (isLoading$ | async)"
            class="p-button-sm w-full"
            [label]="
              'ADDRESS-BOOK.manager.table.buttons.add-tags-to-contacts'
                | translate : { default: 'Add tags to selected contacts' }
            "
          ></button>
        </ng-template>
      </p-listbox>
    </div>
  </ng-template>
</p-overlayPanel>
