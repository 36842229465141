import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { UntilDestroy, untilDestroyed } from "@core";

type AddressFormValue = {
  addressCity: string;
  addressPostalCode: string;
  addressState: string;
  addressCountry: string;
  addressLine1: string;
  addressLine2: string;
};

@UntilDestroy()
@Component({
  selector: "record-form-item-format-address-form",
  templateUrl: "./item-format-address-form.component.html",
  styleUrls: ["./item-format-address-form.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatAddressFormComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatAddressFormComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // HTML5
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onChange: EventEmitter<AddressFormValue> = new EventEmitter<AddressFormValue>();

  form: UntypedFormGroup;
  parentFormGroup: UntypedFormGroup;
  value: AddressFormValue = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled || changes.readonly) {
      this.buildForm();
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.form);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {}

  handleChange(value: AddressFormValue) {
    this.onModelTouched();
    this.onModelChange(value);
    this.writeValue(value);
    this.onChange.emit(value);
  }

  buildForm() {
    this.form = this.formBuilder.group({
      addressCity: [{ value: null, disabled: this.disabled }, []],
      addressPostalCode: [{ value: null, disabled: this.disabled }, []],
      addressState: [{ value: null, disabled: this.disabled }, []],
      addressCountry: [{ value: null, disabled: this.disabled }, []],
      addressLine1: [{ value: null, disabled: this.disabled }, []],
      addressLine2: [{ value: null, disabled: this.disabled }, []],
      addressLine3: [{ value: null, disabled: this.disabled }, []],
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value: AddressFormValue) => {
      this.handleChange(value);
    });
  }

  get valid(): boolean {
    return this.form.valid;
  }

  get invalid(): boolean {
    return this.form.invalid;
  }

  reset() {
    this.form.markAsUntouched();
    this.form.reset();
    this.handleChange(null);
  }
}
