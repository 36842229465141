import BaseModel from "../base.model";
import RecordRecipientModel from "../record/record-recipient.model";
import RecordModel from "../record/record.model";
import InboxTagModel from "./inbox-tag.model";

export default class InboxFiltersResultModel extends BaseModel {
  public receivedCount: number | null = null;
  public recipients: RecordRecipientModel[] = [];
  public tags: InboxTagModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
