import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { UploadDropDirective } from "@app/@shared/directives/upload-drop.directive";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { FileHelper } from "@app/@shared/helpers/file.helper";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import RecordModel from "@app/@shared/models/record/record.model";
import { FileSizePipe } from "@app/@shared/pipes/file-size.pipe";
import { UploadListVariant } from "@app/main/components/file/file-upload-list/files-upload-list.component";
import { UntilDestroy } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { FileWithPath } from "file-selector";
import { UploadState, UploadxService } from "ngx-uploadx";
import { MessageService } from "primeng/api";
import { v4 as v4 } from "uuid";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import { DeviceDetectorService } from "ngx-device-detector";

@UntilDestroy()
@Component({
  selector: "record-form-item-format-upload-dropzone",
  templateUrl: "./item-format-upload-dropzone.component.html",
  styleUrls: ["./item-format-upload-dropzone.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatUploadDropzoneComponent),
      multi: true,
    },
    UploadxService,
    UploadDropDirective,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RecordFormItemFormatUploadDropzoneComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() record: RecordModel;
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Upload options
  @Input() label: string;
  @Input() max: number = null;
  @Input() maxFileSize: number = null;
  @Input() accept: string[] = null;
  @Input() renamingPattern: string = null;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() isUploading: boolean = false;
  @Input() uploadListVariant: UploadListVariant = "box";
  @Input() uploads: UploadState[] = [];

  @Output() onFilesAdded = new EventEmitter<File[]>();
  @Output() onUploadRemove = new EventEmitter<string>();

  enableFoldersUpload: boolean = false;
  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private fileSizePipe: FileSizePipe,
    private translateService: TranslateService,
    private messageService: MessageService,
    private sessionAPIService: SessionAPIService,
    private uploadxService: UploadxService,
    private i18nService: I18nService,
    private deviceDetectorService: DeviceDetectorService,
  ) {}

  ngOnInit(): void {
    this.initControl();
    if (this.deviceDetectorService.isDesktop()) {
      this.enableFoldersUpload = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    if (this.formItem.getFormatters()) {
      // Max files formatter
      let maxFilesFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MAX_FILES);
      if (maxFilesFormatter && maxFilesFormatter.value) {
        let maxFiles = Number.parseFloat(maxFilesFormatter.value.toString());
        this.max = maxFiles;
      }

      // Max file size formatter
      let maxFileSizeFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.MAX_FILE_SIZE);
      if (maxFileSizeFormatter && maxFileSizeFormatter.value) {
        let maxSize = Number.parseFloat(maxFileSizeFormatter.value.toString());
        this.maxFileSize = maxSize;
      }

      // File accept formatter
      let fileAcceptFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.FILE_ACCEPT);
      if (fileAcceptFormatter && fileAcceptFormatter.value) {
        let fileAccept = fileAcceptFormatter.value as string[];
        this.accept = fileAccept;
      }

      // File renaming formatter
      let fileRenamingFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.FILE_RENAMING);
      if (fileRenamingFormatter && fileRenamingFormatter.value) {
        let fileRenaming = fileRenamingFormatter.value as string;
        this.renamingPattern = fileRenaming;
      }
    }
  }

  handleRemove(uploadId?: string): void {
    this.onUploadRemove.emit(uploadId);
  }

  validMaxFiles(fileIndex: number): boolean {
    /**
     * Check file number limit
     */
    if (Boolean(this.max) && this.uploads.length + fileIndex + 1 > this.max) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.max-files-reached.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.max-files-reached.body", { count: this.max }),
        ),
      );

      return false;
    }

    return true;
  }

  validFile(file: File): boolean {
    /**
     * Check is dot file
     */
    if (FileHelper.isDotFile(file.name)) {
      return false;
    }

    /**
     * Check file size limit
     */
    if (Boolean(this.maxFileSize) && file.size > this.maxFileSize) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.body", {
            limit: this.fileSizePipe.transform(this.maxFileSize),
          }),
        ),
      );

      return false;
    }

    /**
     * Check file type
     */
    if (Boolean(this.accept) && !FileHelper.isAccepted(file, this.accept)) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.wrong-type.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.wrong-type.body", {
            limit: this.fileSizePipe.transform(this.maxFileSize),
          }),
        ),
      );

      return false;
    }

    return true;
  }

  handleChange(event, folderUpload = false): void {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files) {
      this.addFiles(element.files, folderUpload);
      element.value = ""; // Reset the input file element to allow the change event to trigger again for the same files
    }
  }

  handleFilesDropped(files: FileList | (FileWithPath | DataTransferItem)[]) {
    this.addFiles(files);
  }

  private async addFiles(files: FileList | (FileWithPath | DataTransferItem)[], folderUpload = false) {
    let newFiles = [];

    if (folderUpload) {
      const zip: File = await FileHelper.zipFiles(Array.from(files));
      newFiles.push(zip);
    } else {
      Array.from(files).forEach((file: File, index: number) => {
        if (this.validFile(file) && this.validMaxFiles(index)) {
          let existingFilenames = [
            ...this.uploads.map((upload: UploadState) => upload.file.name),
            ...newFiles.map((f: File) => f.name),
          ];
          let renamedFilename: string = this.renamingPattern
            ? FileHelper.formatFilename(this.renamingPattern, {
                extension: FileHelper.getExtension(file.name),
                item_label: this.formItem.label,
                uploader_name_or_email: this.sessionAPIService.currentUser.toString(),
                conversation_title: this.record.title,
              })
            : null;
          const newFilename = FileHelper.generateAvailableName(renamedFilename ?? file.name, existingFilenames);
          const newFile = new File([file], newFilename, { type: file.type });
          newFiles.push(newFile);
        }
      });
    }
    this.onFilesAdded.emit(newFiles);
  }

  /**
   * Returns whether multiple files can be attached
   */
  get multiple() {
    return this.max !== null || this.max !== undefined || this.max > 1;
  }

  /**
   * Returns a well formatted value for [accept] HTML attribute
   */
  get htmlAccept() {
    return this.accept
      ? this.accept
          .map((accepted: string) => {
            if (Object.keys(FileHelper.FILE_TYPES).includes(accepted)) {
              let fileTypes = FileHelper.FILE_TYPES[accepted];
              return fileTypes.map((type: string) => (type.includes("*") ? type : "." + type)).join(",");
            }
            return accepted;
          })
          .join(",")
      : "";
  }
}
