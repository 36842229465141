<textarea
  pInputTextarea
  [name]="name"
  [id]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  [rows]="rows"
  [cols]="cols"
  [autoResize]="autoResize"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  [value]="value"
></textarea>
