import { BillingAccountScopeEnum } from "@app/@shared/enums/billing-account-scope.enum";
import { SubscriptionStatusEnum } from "@app/@shared/enums/billing/subscription-status.enum";
import BaseModel from "@shared/models/base.model";
import BillingAccountMemberModel from "./billing-account-member.model";
import BillingAccountUsageModel from "./billing-account-usage.model";
import BillingInvoiceModel from "./invoice.model";
import PaymentMethodModel from "./payment-method.model";
import BillingProductModel from "./product.model";
import SubscriptionModel from "./subscription.model";

export default class BillingAccountModel extends BaseModel {
  // MINIMIZE (default)
  public billingAccountIdentifier: string | null = null;
  public scope?: BillingAccountScopeEnum;
  public firstName?: string;
  public lastName?: string;
  public companyName?: string;
  public emailAddress?: string;
  public phone?: string;
  public addressCity?: string;
  public addressPostalCode?: string;
  public addressState?: string;
  public addressCountry?: string;
  public addressLine1?: string;
  public addressLine2?: string;
  public metadata: JsonValue = {};

  // BILLING_ACCOUNT_SUBSCRIPTION
  public subscribedProduct: BillingProductModel;
  public subscription: SubscriptionModel;

  // BILLING_ACCOUNT_PAYMENT_METHOD
  public paymentMethod: PaymentMethodModel;

  // BILLING_ACCOUNT_UPCOMING_INVOICE
  public upcomingInvoice: BillingInvoiceModel;

  // BILLING_ACCOUNT_MEMBERS
  public members: BillingAccountMemberModel[];

  // BILLING_ACCOUNT_USAGE
  public usage: BillingAccountUsageModel;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.members)
      this.members = input.members.map((member: any) => new BillingAccountMemberModel().deserialize(member));

    if (input.subscribedProduct)
      this.subscribedProduct = new BillingProductModel().deserialize(input.subscribedProduct);

    if (input.subscription) this.subscription = new SubscriptionModel().deserialize(input.subscription);

    if (input.paymentMethod) this.paymentMethod = new PaymentMethodModel().deserialize(input.paymentMethod);

    if (input.upcomingInvoice) this.upcomingInvoice = new BillingInvoiceModel().deserialize(input.upcomingInvoice);

    if (input.usage) this.usage = new BillingAccountUsageModel().deserialize(input.usage);

    return this;
  }

  get fullName() {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].join(" ");
    } else {
      return null;
    }
  }

  /**
   * Helpers
   */
  get hasSubscription(): boolean {
    return this.subscription != null;
  }

  get hasRunningSubscription(): boolean {
    return (
      this.hasSubscription &&
      [
        SubscriptionStatusEnum.ACTIVE,
        SubscriptionStatusEnum.PAST_DUE,
        SubscriptionStatusEnum.TRIALING,
        SubscriptionStatusEnum.UNPAID,
      ].includes(this.subscription.status)
    );
  }
}
