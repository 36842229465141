<p-sidebar
  [(visible)]="showTokenPanel"
  [showCloseIcon]="false"
  [closeOnEscape]="false"
  [dismissible]="false"
  [modal]="true"
  position="bottom"
  [baseZIndex]="10000"
  [style]="{ height: 'auto' }"
  styleClass="token-input-sidebar"
>
  <div class="flex flex-column gap-2 align-items-center">
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <h3 class="mt-0">{{ "COMPONENTS.token-input-panel.title" | translate : { "default": "Token verification" } }}</h3>
      <p>
        {{
          "COMPONENTS.token-input-panel.description"
            | translate : { "default": "Please enter the verification you received on your mobile" }
        }}
      </p>

      <ngx-otp-input #codeInput [config]="otpInputConfig" (fill)="handleTokenCompleted($event)"></ngx-otp-input>
    </ng-container>

    <ng-template #loadingTemplate>
      <div class="flex flex-column gap-2 align-items-center justify-content-center h-full">
        <ui-spinner [size]="60"></ui-spinner>
        <span class="text-gray-500">{{
          "COMPONENTS.token-input-panel.validating" | translate : { "default": "Validating code..." }
        }}</span>
      </div>
    </ng-template>
  </div>
  <ng-template pTemplate="footer">
    <div class="measured-container measure-sm">
      <button
        pButton
        type="button"
        icon="pi pi-arrow-left"
        (click)="onTokenCancel.emit()"
        class="p-button-outlined p-button-sm"
        [label]="'COMMON.buttons.go-back' | translate : { default: 'Go back' }"
      ></button>
    </div>
  </ng-template>
</p-sidebar>
