import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { intersection } from "lodash";
import RecordMessageModel, { Signer } from "@shared/models/record/record-message.model";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import RecordModel from "@shared/models/record/record.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import IdentityModel from "@app/@shared/models/user/identity.model";
import { SignatureAPIService } from "@app/@shared/services/signature-api.service";
import { BehaviorSubject, catchError, finalize, mergeMap, of, throwError } from "rxjs";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { TokenInputPanelComponent } from "@app/main/components";
import { TranslateService } from "@ngx-translate/core";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import { RecordService } from "@app/record/services/record.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DomainAPIService } from "@app/@shared/services/domain-api.service";
import GetRecipientSignatureModel from "@app/@shared/models/signature/get-recipient-signature.model";
import { SignatureProviderEnum } from "@app/@shared/enums/signature-provider.enum";
import { YousignIframeService } from "@app/record/services/yousign-iframe.service";
import { Logger } from "@app/@core";

type SignatureAttachment = {
  attachment: RecordMessageAttachmentModel;
  acknowledged: boolean;
  disabled: boolean;
};

const log = new Logger("RecordMessageSignatureActionComponent");

@UntilDestroy()
@Component({
  selector: "record-message-signature-action",
  templateUrl: "./message-signature-action.component.html",
  styleUrls: ["./message-signature-action.component.scss"],
})
export class RecordMessageSignatureActionComponent implements OnInit, OnDestroy {
  _message: RecordMessageModel;
  @Output() messageChange = new EventEmitter<RecordMessageModel>();
  @Input()
  set message(message: RecordMessageModel) {
    this._message = message;
    this.onMessageChange(this._message);
    this.messageChange.emit(this._message);
  }
  get message(): RecordMessageModel {
    return this._message;
  }

  @ViewChild(TokenInputPanelComponent) tokenInput: TokenInputPanelComponent;

  @Output() onActionPerformed: EventEmitter<any> = new EventEmitter();
  @Output() onAbortSignature: EventEmitter<any> = new EventEmitter();

  attachments: SignatureAttachment[] = [];
  attachmentsMenuItems: MenuItem[] = [];
  actionsMenuItems: MenuItem[] = [];
  currentAttachment: SignatureAttachment;
  currentIdentityIdentifier: string;
  isLoadingConfiguration: boolean = false;
  isPerformingStartSignature: boolean = false;
  isPerformingDeclineSignature: boolean = false;
  isPerformingValidateSignature: boolean = false;
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  showToken: boolean = false;
  // Default pdf viewer zoom
  zoom: number = 35;
  pdfLoaded: boolean = false;
  totalPages: number;
  recipientSignature: GetRecipientSignatureModel;
  iframeContainerId: string = "yousign-iframe-container";

  SignatureProviderEnum = SignatureProviderEnum;

  get record() {
    return this.record$.getValue();
  }

  get yousignInstance() {
    return this.yousignService.getYousignInstance();
  }

  get isVersionThree() {
    return this.recipientSignature?.provider === SignatureProviderEnum.YOUSIGN_V3;
  }

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private sessionAPIService: SessionAPIService,
    private recordService: RecordService,
    private signatureAPIService: SignatureAPIService,
    private yousignService: YousignIframeService,
    private confirmationService: ConfirmationService,
  ) {
    // Put record into a BehaviorSubject to access its value at any time
    this.recordService.record$.pipe(untilDestroyed(this)).subscribe(this.record$);
  }

  ngOnInit(): void {
    this.setActionsMenuItems();
    this.getSignatureConfiguration();
  }

  ngOnDestroy() {
    this.yousignService.getYousignInstance()?.removeMessageListener();
  }

  /**
   * Retrieve the Workspace Signature config (provider)
   */
  getSignatureConfiguration() {
    this.isLoadingConfiguration = true;
    this.signatureAPIService
      .getRecipientSignature(
        this.record.recordIdentifier,
        this._message.messageIdentifier,
        this.currentIdentityIdentifier,
      )
      .pipe(untilDestroyed(this))
      .subscribe((recipientSignature: GetRecipientSignatureModel) => {
        this.recipientSignature = recipientSignature;
        this.isLoadingConfiguration = false;

        let { url } = this.recipientSignature;
        if (!url) log.warn("No signature link found");
        if (this.isSigner() && !this.hasAlreadySigned() && this.isVersionThree && url) {
          this.startYousignSignatureFlow(url);
        }
      });
  }

  /**
   * Start the Yousign v3 signature flow
   */
  startYousignSignatureFlow(signatureLink: string) {
    this.yousignService.initialize(signatureLink, this.iframeContainerId);
    if (this.yousignInstance) {
      this.yousignInstance.onStarted(() => log.info("Yousign flow started"));
      this.yousignInstance.onSuccess(() => this.handleSignatureSuccess());
      this.yousignInstance.onDeclined(() => this.handleSignatureDeclined());
    }
  }

  /**
   * Triggers when the signature was succesfully performed
   */
  handleSignatureSuccess() {
    this.messageService.add({
      severity: MessageSeverityEnum.SEVERITY_SUCCESS,
      summary: this.translateService.instant("RECORD.record-message-signature-action.signature-success.title", {
        default: "Signature success",
      }),
      detail: this.translateService.instant("RECORD.record-message-signature-action.signature-success.detail", {
        default: "You successfully signed the documents",
      }),
    });
    this.onActionPerformed.emit();
    this.message = null;
  }

  onMessageChange(message: RecordMessageModel) {
    if (message) {
      if (this.sessionAPIService.currentUser?.identities) {
        let identities: string[] = intersection(
          this.sessionAPIService.currentUser.identities.map((identity: IdentityModel) => identity.identityIdentifier),
          this.message.signers.map((s: Signer) => s.identityIdentifier),
        );

        this.currentIdentityIdentifier = identities[0];
      }

      this.initAttachments();
    }
  }

  initAttachments() {
    this.attachments = this.message.attachments
      .filter((a: RecordMessageAttachmentModel) => this.message.isSignatureAttachment(a.file.fileIdentifier))
      .map((a: RecordMessageAttachmentModel) => ({
        attachment: a,
        acknowledged: false,
        disabled: true,
      }));

    if (this.attachments.length > 0) {
      this.currentAttachment = this.attachments[0];
      this.currentAttachment.disabled = false;
      this.setAttachmentsMenuItems();
    }
  }

  get displaySignAction(): boolean {
    return this.isLastAttachment || this.enableSignAction;
  }

  get enableSignAction(): boolean {
    return this.attachments.every((a: SignatureAttachment) => a.acknowledged);
  }

  get enableNextAction(): boolean {
    return !this.isLastAttachment;
  }

  get enablePreviousAction(): boolean {
    return !this.isFirstAttachment;
  }

  get currentAttachmentReadableIndex(): number {
    return this.attachments.indexOf(this.currentAttachment) + 1;
  }
  get isFirstAttachment(): boolean {
    return this.attachments.indexOf(this.currentAttachment) === 0;
  }

  get isLastAttachment(): boolean {
    return this.attachments.indexOf(this.currentAttachment) === this.attachments.length - 1;
  }

  goToPrevious() {
    if (!this.isFirstAttachment) {
      const index = this.attachments.indexOf(this.currentAttachment);
      if (index > 0) {
        this.goToAttachment(index - 1);
      }
    }
  }

  goToNext() {
    if (!this.isLastAttachment) {
      const index = this.attachments.indexOf(this.currentAttachment);
      if (index < this.attachments.length + 1) {
        this.goToAttachment(index + 1);
      }
    }
  }

  goToAttachment(index: number) {
    let attachment = this.attachments[index];
    if (attachment && attachment !== this.currentAttachment) {
      this.pdfLoaded = false;
      this.currentAttachment = attachment;
      this.setAttachmentsMenuItems();
    }
  }

  handleAllPagesRendered(event) {
    this.currentAttachment.acknowledged = true;
  }

  isSigner(): boolean {
    if (this.currentIdentityIdentifier) {
      return this.message.isSigner(this.currentIdentityIdentifier);
    }

    return false;
  }

  hasAlreadySigned(): boolean {
    if (this.currentIdentityIdentifier) {
      return this.message.hasSigned(this.currentIdentityIdentifier);
    }

    return false;
  }

  promptDecline() {
    this.confirmationService.confirm({
      header: this.translateService.instant("LAYOUTS.record-view.actions.decline-signature.header", {
        default: "Decline signature",
      }),
      message: this.translateService.instant("LAYOUTS.record-view.actions.decline-signature.body", {
        default: "By declining this signature, the signature process will be canceled.",
      }),
      acceptLabel: this.translateService.instant("COMMON.buttons.decline-signature"),
      rejectLabel: this.translateService.instant("COMMON.buttons.cancel"),
      acceptButtonStyleClass: "p-button-sm p-button-danger hide-duplicated-icon",
      rejectButtonStyleClass: "p-button-outlined  p-button-sm",
      acceptIcon: "hidden",
      rejectIcon: "hidden",
      accept: () => {
        this.doDecline();
      },
    });
  }

  doDecline(): void {
    this.isPerformingDeclineSignature = true;
    this.signatureAPIService
      .declineSignature(this.record.recordIdentifier, this._message.messageIdentifier, this.currentIdentityIdentifier, [
        ResponseLevelEnum.ALL,
      ])
      .pipe(
        mergeMap((done: boolean) => this.recordService.reloadRecord()),
        untilDestroyed(this),
      )
      .subscribe((record) => {
        this.isPerformingDeclineSignature = false;
        if (record) this.handleSignatureDeclined();
      });
  }

  handleSignatureDeclined() {
    this.onActionPerformed.emit();
    this.message = null;
  }
  doSign(): void {
    if (this.isSigner() && !this.hasAlreadySigned()) {
      this.isPerformingStartSignature = true;
      this.signatureAPIService
        .startRecipientSignature(
          this.record.recordIdentifier,
          this._message.messageIdentifier,
          this.currentIdentityIdentifier,
          [ResponseLevelEnum.ALL],
        )
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.showToken = true;
          this.isPerformingStartSignature = false;
        });
    }
  }

  handleTokenCompleted(token: string): void {
    // if(isEmpty(token)) {this.showToken = false}
    this.isPerformingValidateSignature = true;
    this.signatureAPIService
      .validateRecipientSignature(
        this.record.recordIdentifier,
        this._message.messageIdentifier,
        this.currentIdentityIdentifier,
        token,
        [ResponseLevelEnum.ALL],
      )
      .pipe(
        mergeMap((done: boolean) => {
          if (done) {
            this.showToken = false;
            return this.recordService.reloadRecord();
          } else {
            this.showToken = true;
            // this.tokenInput?.resetToken();
            this.messageService.add({
              severity: MessageSeverityEnum.SEVERITY_ERROR,
              summary: this.translateService.instant("RECORD.record-message-signature-action.invalid-token.title", {
                default: "Invalid verification code",
              }),
              detail: this.translateService.instant("RECORD.record-message-signature-action.invalid-token.detail", {
                detail: "The verification code you entered is invalid",
              }),
            });
            return throwError(() => "Invalid token");
          }
        }),
        catchError((error) => {
          this.showToken = false;
          log.error(error);
          return of(null);
        }),
        finalize(() => (this.isPerformingValidateSignature = false)),
        untilDestroyed(this),
      )
      .subscribe((record) => {
        if (record) this.handleSignatureSuccess();
      });
  }

  // Zoom handling

  decreaseZoom() {
    if (this.zoom > 10) {
      this.zoom -= 10;
    }
  }

  increaseZoom() {
    this.zoom += 10;
  }

  resetZoom() {
    this.zoom = 50;
  }

  isMinZoom() {
    return this.zoom === 10;
  }

  // Pagination

  setAttachmentsMenuItems() {
    this.attachmentsMenuItems = this.attachments.map((attachment, index) => {
      return {
        label: attachment.attachment.file.name,
        command: () => this.goToAttachment(index),
        disabled: attachment === this.currentAttachment,
      };
    });
  }

  setActionsMenuItems() {
    this.actionsMenuItems = [
      {
        label: this.translateService.instant("COMMON.buttons.decline-signature"),
        command: () => this.doDecline(),
        disabled: this.isPerformingStartSignature,
      },
    ];
  }
}
