export enum ConfigurationClassEnum {
  CAMPAIGN_EXPORT = "campaign.export",
  CONVERSATION_EXPIRATION_PERIOD = "conversation.expiration_period",
  CONVERSATION_TAGS_MIN_ROLE = "conversation.tags.min_role",
  GLOBAL_LIMITS = "global.limits",
  INBOX_EXCLUDE_CHILDREN = "inbox.exclude_children",
  MAIL_SMTP = "mail.smtp",
  PARTNERS_SIGNATURE = "partners.signature",
  TEMPLATES_SHARING = "templates.sharing",
}
