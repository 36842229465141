import { ConfigurationScopeEnum } from "@app/@shared/enums/configuration-scope.enum";
import { ClassHelper } from "@app/@shared/helpers/class.helper";
import BaseModel from "@shared/models/base.model";

export default class ConfigurationModel extends BaseModel {
  // MINIMIZE (default)
  public configClass = "";
  public value: JSONObject = {};
  public scope?: ConfigurationScopeEnum;
  public isObfuscated = false;

  constructor() {
    super();
  }

  getConfigClass(): string[] {
    return ClassHelper.splitClass(this.configClass);
  }

  setConfigClass(...args: string[]): this {
    this.configClass = ClassHelper.normalizeClass(...args);
    return this;
  }
}
