import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import { AddressBookAPIService } from "@app/@shared/services/address-book-api.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { SharedValidators } from "@app/@shared/validators";
import { UntilDestroy, untilDestroyed } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";

@UntilDestroy()
@Component({
  selector: "contacts-contact-edit",
  templateUrl: "./contact-edit.component.html",
  styleUrls: ["./contact-edit.component.scss"],
})
export class ContactEditComponent implements OnInit {
  @Input() contact: ContactModel = new ContactModel();
  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;

  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  // We use it to hold all the contact-defined-tags
  form: UntypedFormGroup;
  scopeOptions: JsonArray;
  allContactTags: string[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sessionAPIService: SessionAPIService,
    private contactsService: AddressBookAPIService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.buildform();
    this.contactsService
      .getTags({ scope: ContactScopeEnum.USER }, [ResponseLevelEnum.MINIMIZE])
      .pipe(untilDestroyed(this))
      .subscribe((tags) => {
        this.allContactTags = tags.map(({ label }) => label);
      });
  }

  ngOnDestroy() {}

  private buildform() {
    this.form = this.formBuilder.group({
      firstName: [this.contact.firstName],
      lastName: [this.contact.lastName],
      emailAddress: [this.contact.emailAddress, [Validators.required, Validators.email, this.emailNotSelfValidator()]],
      phoneNumber: [this.contact.phoneNumber, [SharedValidators.internationalPhoneNumber]],
      channel: [this.contact.defaultChannel, []],
      tags: [this.contact.tags, []],
    });
  }

  emailNotSelfValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;
      if (this.sessionAPIService.currentUser && this.sessionAPIService.currentUser.emailAddress === value) {
        return { isSelfEmail: true };
      }
      return null;
    };
  }

  handleEditContact(contact: ContactModel) {
    let contacts = [contact];
    this.contactsService
      .saveContacts({ contacts }, [ResponseLevelEnum.ALL])
      .pipe(untilDestroyed(this))
      .subscribe(({ contacts }) => {
        if (contacts && contacts.length > 0) this.contact = contacts[0];

        if (this.contact.contactIdentifier) {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_SUCCESS,
            detail: this.translateService.instant("ADDRESS-BOOK.messages.contact-updated.detail", {
              default: "Contact updated successfully",
            }),
          });
          // TODO: Redirect to contacts
          this.router.navigate(["/contacts"]);
        }
      });
  }

  cancel() {
    this.onCancel.emit();
  }
}
