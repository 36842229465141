import { Injectable } from "@angular/core";
import { ContactOrderEnum } from "@app/@shared/enums/contact-order.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import ProviderContactsResultModel from "@app/@shared/models/contacts/provider-list-contacts-result.model";
import ProviderContactModel from "@app/@shared/models/providers/provider-contact.model";
import { ProviderContactsAPIService } from "@app/@shared/services/providers/provider-contacts-api.service";
import { Logger } from "@core";
import { BehaviorSubject, combineLatest, Observable, Subscription, switchMap, tap } from "rxjs";

const log = new Logger("ProviderContactsService");

@Injectable({
  providedIn: "root",
})
export class ProviderContactsService {
  DEFAULT_LIMIT = -1;

  // contacts observable
  private _contacts$: BehaviorSubject<ProviderContactModel[]> = new BehaviorSubject([]);
  contacts$: Observable<ProviderContactModel[]> = this._contacts$.asObservable();

  get contacts(): ProviderContactModel[] {
    return this._contacts$.getValue();
  }
  set contacts(contacts: ProviderContactModel[]) {
    this._contacts$.next(contacts);
  }
  // order observable
  private _order$: BehaviorSubject<ContactOrderEnum> = new BehaviorSubject(null);
  order$: Observable<ContactOrderEnum> = this._order$.asObservable();

  get order(): ContactOrderEnum {
    return this._order$.getValue();
  }
  set order(order: ContactOrderEnum) {
    this._order$.next(order);
  }

  // offset observable
  private _offset$: BehaviorSubject<number> = new BehaviorSubject(0);
  offset$: Observable<number> = this._offset$.asObservable();

  get offset(): number {
    return this._offset$.getValue();
  }
  set offset(offset: number) {
    this._offset$.next(offset);
  }

  // limit observable
  private _limit$: BehaviorSubject<number> = new BehaviorSubject(this.DEFAULT_LIMIT);
  limit$: Observable<number> = this._limit$.asObservable();

  get limit(): number {
    return this._limit$.getValue();
  }
  set limit(limit: number) {
    this._limit$.next(limit);
  }

  // total observable
  private _total$: BehaviorSubject<number> = new BehaviorSubject(0);
  total$: Observable<number> = this._total$.asObservable();

  get total(): number {
    return this._total$.getValue();
  }

  // totalPages observable
  private _totalPages$: BehaviorSubject<number> = new BehaviorSubject(0);
  totalPages$: Observable<number> = this._totalPages$.asObservable();

  get totalPages(): number {
    return this._totalPages$.getValue();
  }

  // isLoading observable
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();

  private subscription: Subscription;

  constructor(private providerContactsAPIService: ProviderContactsAPIService) {}

  initialize(
    applicationIdentifier: string,
    folderId?: string,
    offset: number = 0,
    limit: number = this.DEFAULT_LIMIT,
    order: ContactOrderEnum = null,
  ) {
    if (this.subscription) this.subscription.unsubscribe();
    if (offset !== this.offset) this._offset$.next(offset);
    if (limit !== this.limit) this._limit$.next(limit);
    if (order !== this.order) this._order$.next(order);

    this.subscription = combineLatest([this.offset$, this.limit$, this.order$])
      .pipe(switchMap(() => this.listContacts(applicationIdentifier, folderId, [ResponseLevelEnum.ALL])))
      .subscribe((contacts: ProviderContactModel[]) => {});
  }

  listContacts(
    applicationIdentifier: string,
    folderId?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderContactModel[]> {
    this._isLoading$.next(true);

    return this.providerContactsAPIService
      .listContacts({ applicationIdentifier, folderId }, this.offset, this.limit, responseLevels)
      .pipe(
        tap((result: ProviderContactsResultModel) => {
          this.updatePageInfo(result);
          this._contacts$.next(result.contacts);
        }),
        tap(() => this._isLoading$.next(false)),
        switchMap(() => this.contacts$),
      );
  }

  /**
   * Pagination
   */
  updatePageInfo(result: ProviderContactsResultModel) {
    this._total$.next(result.totalCount);

    const pages = this.limit <= 0 ? 0 : Math.ceil(result.totalCount / this.limit);
    this._totalPages$.next(pages);
  }

  nextPage(): Observable<ProviderContactModel[]> {
    if (this.offset + this.limit < this.total) {
      this._offset$.next(this.offset + this.limit);
    }

    return this.contacts$;
  }

  prevPage(): Observable<ProviderContactModel[]> {
    if (this.offset != 0) {
      if (this.offset - this.limit < 0) {
        this.resetPage();
      } else {
        this._offset$.next(this.offset - this.limit);
      }
    }

    return this.contacts$;
  }

  resetPage(force: boolean = false): Observable<ProviderContactModel[]> {
    if (this.offset > 0 || force) {
      this._offset$.next(0);
    }

    return this.contacts$;
  }
}
