<div class="flex flex-column gap-3">
  <div class="flex flex-column gap-2">
    <!-- 📇 FILE Upload -->
    <div
      uploadDrop
      (filesDropped)="handleFilesDropped($event)"
      class="dropzone flex justify-content-center align-items-center"
    >
      <input
        type="file"
        [name]="name"
        [id]="inputId"
        [title]="title"
        [disabled]="disabled"
        [accept]="htmlAccept"
        [multiple]="multiple ? multiple : undefined"
        (change)="handleChange($event)"
      />
      <label [for]="inputId" class="dropzone__title">
        <div class="flex gap-2 align-items-center justify-content-center">
          <span class="dropzone__title__text">
            {{
              "COMPONENTS.file-upload.title.desktop"
                | translate : { "default": "Click here to add files or drag them here" }
            }}
          </span>
        </div>
      </label>
    </div>
    <!-- 📂 FOLDER Upload-->
    <div class="folder-input-field" *ngIf="enableFoldersUpload">
      <input
        #uploadFolderInput
        type="file"
        [id]="inputId + 'directory'"
        [accept]="htmlAccept"
        [multiple]="multiple ? multiple : undefined"
        class="sr-only"
        (change)="handleChange($event, true)"
        data-attr="file-upload-folder"
        [attr.directory]="true"
        [attr.webkitdirectory]="true"
        [attr.mozdirectory]="true"
        [attr.msdirectory]="true"
        [attr.odirectory]="true"
      />
      <label [for]="inputId + 'directory'" class="p-button p-button-sm p-button-outlined p-button-secondary">
        <div class="flex align-items-center">
          <i class="pi pi-folder mr-2"></i>
          <span>
            {{ "COMPONENTS.file-upload.buttons.folders" | translate : { "default": "Add folders" } }}
          </span>
        </div>
      </label>
    </div>
  </div>
  <!-- 📋 UPLOADS List -->
  <ng-container *ngIf="uploads && uploads.length > 0"
    ><main-files-upload-list
      [disabled]="disabled"
      [isUploading]="isUploading"
      [uploads]="uploads"
      [variant]="uploadListVariant"
      (onRemove)="handleRemove($event)"
    ></main-files-upload-list
  ></ng-container>
</div>
