export enum RecordPropertiesEnum {
  CONTAINS_SIGNATURE = "containsSignature",
  CONTAINS_FORM = "containsForm",
  CONTAINS_FORM_ANSWER = "containsFormAnswer",
  CONTAINS_INVITATION = "containsInvitation",
  CONTAINS_ATTACHMENTS = "containsAttachments",

  // Completion states
  COMPLETION_STATE = "completionState",
  STATE_PENDING = "pending",
  STATE_COMPLETED = "completed",
}
