import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { COUNTRIES, Country } from "@app/@shared/data/countries.store";
import { v4 as v4 } from "uuid";

@Component({
  selector: "ui-country-input",
  templateUrl: "./country-input.component.html",
  styleUrls: ["./country-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true,
    },
  ],
})
export class CountryInputComponent implements OnInit, ControlValueAccessor {
  @Input() optionValue?: string;
  @Input() selectedValue: any = null;

  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() placeholder: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() styleClass: string = "";

  countries: Country[] = COUNTRIES;

  constructor(private controlContainer: ControlContainer) {}

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: any): void {
    if (value) {
      if (value.hasOwnProperty("name")) {
        this.selectedValue = value;
      } else if (typeof value === "object") {
        this.selectedValue = value;
      } else if (this.optionValue && typeof value === "string") {
        const objectValue = COUNTRIES.find((c) => c[this.optionValue] === value);
        this.selectedValue = objectValue;
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  handleChange(event) {
    if (event) {
      this.onTouched();

      if (this.optionValue && event.hasOwnProperty(this.optionValue)) {
        this.onChange(event[this.optionValue]);
      } else {
        this.onChange(event);
      }
      this.writeValue(event);
    }
  }
}
