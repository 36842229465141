import { Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { SessionAPIService } from "./session-api.service";
import { filter } from "rxjs";
import SessionModel from "../models/session/session.model";
import IdentityModel from "../models/user/identity.model";
import { RecordRoleEnum } from "../enums/record-role.enum";
import RecordModel from "../models/record/record.model";
import RecordRecipientModel from "../models/record/record-recipient.model";
import { some } from "lodash";

const log = new Logger("RecordAuthorizationService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class RecordAuthorizationService {
  private userIdentifier: string;
  private userIdentities: IdentityModel[] = [];

  constructor(private sessionAPIService: SessionAPIService) {
    // Initialize watching session to set _currentUserUnit$ value
    this.sessionAPIService.session$
      .pipe(
        filter((session: SessionModel) => Boolean(session)),
        untilDestroyed(this),
      )
      .subscribe((session: SessionModel) => {
        this.userIdentifier = session.user.userIdentifier;
        this.userIdentities = session.user.identities;
      });
  }

  /**
   * Get record recipients with specified role
   */
  private getIdentitiesWithRole(role: RecordRoleEnum, record: RecordModel) {
    return record.recipients
      .filter((recipient: RecordRecipientModel) => recipient.role === role)
      .map((recipient: RecordRecipientModel) => recipient.identityIdentifier);
  }

  /**
   *  Get record recipients with any specified roles
   */
  private getIdentitiesWithAnyRole(roles: RecordRoleEnum[], record: RecordModel) {
    return record.recipients
      .filter((recipient: RecordRecipientModel) => roles.includes(recipient.role))
      .map((recipient: RecordRecipientModel) => recipient.identityIdentifier);
  }

  /**
   * Returns whether user has specified role in record
   */
  hasRole(role: RecordRoleEnum, record: RecordModel): boolean {
    return some(this.userIdentities, (identity: IdentityModel) =>
      this.getIdentitiesWithRole(role, record).includes(identity.identityIdentifier),
    );
  }

  /**
   * Returns whether user has any of specified roles in record
   */
  hasAnyRole(roles: RecordRoleEnum[], record: RecordModel): boolean {
    return some(this.userIdentities, (identity: IdentityModel) =>
      this.getIdentitiesWithAnyRole(roles, record).includes(identity.identityIdentifier),
    );
  }

  /**
   * Shortcuts
   */
  isOwner(record: RecordModel) {
    return record.isOwner(this.userIdentifier) || this.hasRole(RecordRoleEnum.OWNER, record);
  }

  isWriter(record: RecordModel) {
    return this.hasRole(RecordRoleEnum.WRITER, record);
  }

  isReviewer(record: RecordModel) {
    return this.hasRole(RecordRoleEnum.REVIEWER, record);
  }

  isReader(record: RecordModel) {
    return this.hasRole(RecordRoleEnum.READER, record);
  }

  /**
   * Actions authorization
   */
  canUpdateExpiration(record: RecordModel) {
    return (
      (this.hasAnyRole([RecordRoleEnum.REVIEWER], record) || this.isOwner(record)) &&
      !record.isClosed &&
      !record.isCancelled
    );
  }

  canValidateFormItemAnswers(record: RecordModel) {
    return (
      (this.hasAnyRole([RecordRoleEnum.REVIEWER], record) || this.isOwner(record)) &&
      !record.isClosed &&
      !record.isCancelled
    );
  }

  canManageRecipients(record: RecordModel) {
    return (this.hasAnyRole([RecordRoleEnum.REVIEWER], record) || this.isOwner(record)) && !record.isCancelled;
  }

  canUpdateRecordSettings(record: RecordModel) {
    return (
      (this.hasAnyRole([RecordRoleEnum.REVIEWER], record) || this.isOwner(record)) &&
      !record.isClosed &&
      !record.isCancelled
    );
  }

  canReply(record: RecordModel) {
    return this.hasAnyRole([RecordRoleEnum.REVIEWER, RecordRoleEnum.WRITER], record) || this.isOwner(record);
  }

  canAddFormItems(record: RecordModel) {
    return this.hasAnyRole([RecordRoleEnum.REVIEWER], record) || this.isOwner(record);
  }

  canCreateSignature(record: RecordModel) {
    return this.hasAnyRole([RecordRoleEnum.REVIEWER], record) || this.isOwner(record);
  }

  hasMinRole(record: RecordModel, minRole?: RecordRoleEnum) {
    let predicate = this.isOwner(record);

    if (minRole) {
      predicate = predicate || this.hasAnyRole([minRole], record);
    }

    return predicate;
  }
}
