<span
  data-badge
  [attr.data-badge-prefix]="prefix"
  [attr.data-badge-variant]="variant"
  [attr.data-badge-size]="size"
  [attr.data-badge-hoverable]="hoverable"
  [ngStyle]="{ 'max-width': cssMaxWidth }"
  [ngClass]="{
    'clamp': cssMaxWidth
  }"
>
  <i *ngIf="icon" [ngClass]="icon" class="mr-1"></i>
  {{ label }}
</span>
