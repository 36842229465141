import BaseModel from "@shared/models/base.model";
import { ChannelEnum } from "@shared/enums/channel.enum";
import { RecordRoleEnum } from "@shared/enums/record-role.enum";
import ContactModel from "../contacts/contact.model";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import { PhoneHelper } from "@app/@shared/helpers/phone.helper";
import UserModel from "../user/user.model";
import RecordRecipientMetadataModel from "./record-recipient-metadata.model";
import { DateHelper } from "@app/@shared/helpers/date.helper";

export default class RecordRecipientModel extends BaseModel {
  // MINIMIZE (default)
  public identityIdentifier: string | null = null;
  public channel?: ChannelEnum = ChannelEnum.EMAIL;
  public firstName: string | null = null;
  public lastName: string | null = null;
  public emailAddress: string | null = null;
  public phoneNumber: string | null = null;
  public role?: RecordRoleEnum;
  public createdAt: Date | null = null;

  // RECORD_RECIPIENT_DETAILS
  public avatar: string | null = null;

  // RECORD_RECIPIENT_METADATA
  public metadata: RecordRecipientMetadataModel = new RecordRecipientMetadataModel();

  public contactSource?: ContactModel;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.metadata) this.metadata = new RecordRecipientMetadataModel().deserialize(input.metadata);

    return this;
  }

  get fullName() {
    if (this.firstName || this.lastName) {
      return [this.firstName, this.lastName].join(" ");
    } else {
      return null;
    }
  }

  get toString() {
    return this.fullName || this.emailAddress || this.phoneNumber;
  }

  get toAvatarString() {
    return this.fullName?.toLowerCase() || this.emailAddress?.toLowerCase() || this.phoneNumber?.toLowerCase();
  }

  get toOptionString() {
    return `${this.fullName ? `${this.fullName} (${this.emailAddress})` : this.emailAddress}`;
  }

  get mainContactInfo(): string {
    return this.emailAddress || this.phoneNumber;
  }

  static fromContact(contact: ContactModel, role: RecordRoleEnum = RecordRoleEnum.WRITER): RecordRecipientModel {
    let recipient = new RecordRecipientModel();
    recipient.firstName = contact.firstName?.trim();
    recipient.lastName = contact.lastName?.trim();
    recipient.emailAddress = contact.emailAddress;
    recipient.phoneNumber = contact.phoneNumber;
    recipient.channel = contact.defaultChannel;
    recipient.contactSource = contact;
    if (role) recipient.role = role;
    return recipient;
  }

  static fromUser(user: UserModel, role: RecordRoleEnum = RecordRoleEnum.WRITER): RecordRecipientModel {
    let recipient = new RecordRecipientModel();
    recipient.firstName = user.givenName;
    recipient.lastName = user.familyName;
    recipient.emailAddress = user.mainIdentity?.emailAddress || user.emailAddress;
    recipient.phoneNumber = user.mainIdentity?.phoneNumber;
    recipient.channel = ChannelEnum.EMAIL;
    recipient.identityIdentifier = user.mainIdentity?.identityIdentifier;
    if (role) recipient.role = role;
    return recipient;
  }

  isValid(signature: boolean = false) {
    const validEmail = this.emailAddress && StringHelper.isEmail(this.emailAddress);

    if (signature) {
      const validPhoneNumber = this.phoneNumber && PhoneHelper.isValidPhoneNumber(this.phoneNumber);
      return (
        validEmail && validPhoneNumber && !StringHelper.isBlank(this.firstName) && !StringHelper.isBlank(this.lastName)
      );
    }

    return validEmail;
  }
}
