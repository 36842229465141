import { PhoneNumber, PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { CountryISO } from "ngx-intl-tel-input-gg";

export type ParsedPhoneNumber = {
  nationalNumber: string;
  countryCode: string;
  regionCode: string;
  countryISO: CountryISO;
  e164Number: string;
};

export class PhoneHelper {
  constructor() {}

  static isValidPhoneNumber(value: string) {
    try {
      const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
      const phoneNumber: PhoneNumber = phoneUtil.parse(value, "ZZ");
      const regionCode: string = phoneUtil.getRegionCodeForCountryCode(phoneNumber.getCountryCode());

      return phoneUtil.isValidNumberForRegion(phoneNumber, regionCode);
    } catch (error) {
      return false;
    }
  }

  static parseInternationalPhoneNumber(value: string): ParsedPhoneNumber {
    const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    const phoneNumber: PhoneNumber = phoneUtil.parse(value, "ZZ");
    const regionCode: string = phoneUtil.getRegionCodeForCountryCode(phoneNumber.getCountryCode());
    const e164Number: string = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
    let countryISO = null;

    if (regionCode) {
      const entry: [string, CountryISO] = Object.entries(CountryISO).find((v) => v[1] === regionCode.toLowerCase());
      countryISO = CountryISO[entry[0]];
    }

    return {
      nationalNumber: phoneNumber.getNationalNumber().toString(),
      countryCode: phoneNumber.getCountryCode().toString(),
      regionCode,
      countryISO,
      e164Number,
    };
  }
}
