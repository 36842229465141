import { ElementRef, Injectable } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { BehaviorSubject, filter, tap } from "rxjs";
import UnitModel from "../models/domain/unit.model";
import chroma from "chroma-js";
import { SessionAPIService } from "./session-api.service";
import { NavigationBarVariantEnum } from "../enums/navigation-bar-variant.enum";

const log = new Logger("ThemingService");

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class ThemingService {
  // isApplying observable
  private _isApplying$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isApplying$ = this._isApplying$.asObservable();

  // isApplied observable
  private _isApplied$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isApplied$ = this._isApplied$.asObservable();

  // logo URL observable
  private _logoUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  logoUrl$ = this._logoUrl$.asObservable();
  // Avatar URL observable
  private _avatarUrl$: BehaviorSubject<string> = new BehaviorSubject(null);
  avatarUrl$ = this._avatarUrl$.asObservable();

  // Custom Styles Observable
  private _customStyles$: BehaviorSubject<JsonValue> = new BehaviorSubject(null);
  customStyles$ = this._customStyles$.asObservable();

  private _hideBottomMenuBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hideBottomMenubar$ = this._hideBottomMenuBar$.asObservable();

  private _navigationBarVariant$: BehaviorSubject<NavigationBarVariantEnum> = new BehaviorSubject(
    NavigationBarVariantEnum.PRIMARY,
  );
  navigationBarVariant$ = this._navigationBarVariant$.asObservable();

  // Reference kept for styling purposes
  rootContainerRef: ElementRef | string = "body";

  constructor(private sessionAPIService: SessionAPIService) {
    // Initialize watching session to set _currentUnit$ value
    this.sessionAPIService.unit$
      .pipe(
        filter((unit: UnitModel) => Boolean(unit)),
        tap((unit: UnitModel) => {
          this.loadThemeInfo(unit);
        }),
        untilDestroyed(this),
      )
      .subscribe((unit: UnitModel) => {});
  }

  getCustomStyles() {
    return this._customStyles$.getValue();
  }

  setBottomBarVisibility(hideBottomBar: boolean) {
    this._hideBottomMenuBar$.next(hideBottomBar);
  }
  setNavigationBarVariant(variant: NavigationBarVariantEnum) {
    this._navigationBarVariant$.next(variant);
  }
  getContrastRatio(color: string, background: string): number {
    return chroma.contrast(color, background);
  }
  isReadable(color: string, background: string, requirement = 4.3): boolean {
    return this.getContrastRatio(color, background) >= requirement;
  }
  getReadableTextColor(background: string): string {
    return this.isReadable("white", background) ? "white" : "black";
  }
  getRGBString(color: string): string {
    return chroma(color).rgb().join(",");
  }
  isColorValid(color: string): boolean {
    return chroma.valid(color);
  }

  loadThemeInfo(unit: UnitModel) {
    let customStyles = {};

    // Extract styles from Unit properties
    log.info("Setting custom theme " + unit.name + " from session unit");
    // Setting up avatar and logo
    this._logoUrl$.next(unit.logo ?? null);
    this._avatarUrl$.next(unit.avatar ?? null);
    // Setting up colors
    if (Object.keys(unit.styles).length > 0) {
      let styles = unit.styles;
      log.info("Setting custom theme for unit");

      // Set other supplied variables
      for (const [variable, value] of Object.entries(styles)) {
        if (value && this.isColorValid(value)) {
          customStyles[`--${variable}`] = value;
        }
      }

      // Handling primary color
      let customPrimary = styles["primary-color"];
      if (Boolean(customPrimary) && this.isColorValid(customPrimary)) {
        // Generate primary color variants
        let customPrimaryRGB = this.getRGBString(customPrimary);
        let customPrimaryLigher = chroma(customPrimary).brighten();
        customStyles["--primary-color-rgb"] = customPrimaryRGB;
        customStyles["--primary-color-hover"] = `${customPrimaryLigher}`;

        //  Generate a palette of 10 colors
        let scale = chroma.scale(["white", customPrimary, "black"]).colors(10);
        scale.forEach((value: any, index: number) => {
          let suffix = index === 0 ? 50 : index * 100;
          customStyles[`--primary-${suffix}`] = value;
        });
      }
    }
    this._customStyles$.next(customStyles);
  }
}
