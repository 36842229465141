import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import { ContactsService } from "@app/contacts/services/contacts.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, tap } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { ConfirmationService, LazyLoadEvent } from "primeng/api";
import { ContactOrderEnum } from "@app/@shared/enums/contact-order.enum";
import { AddressBookAPIService } from "@app/@shared/services/address-book-api.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { Listbox } from "primeng/listbox";
import { ThemingService } from "@app/@shared/services/theming.service";
import { MatomoEventEnum } from "@app/@shared/enums/matomo-event-enum";
import { MatomoTracker } from "ngx-matomo-client";
import { Table } from "primeng/table";
import { Papa } from "ngx-papaparse";

type TableColumn = {
  field: string;
  header: string;
  classnames?: string;
};

type ListboxChangeEvent = {
  originalEvent: Event;
  value: any;
};

type TagOption = {
  label: string;
  value: string;
};
@UntilDestroy()
@Component({
  selector: "contacts-contact-table",
  templateUrl: "./contact-table.component.html",
  styleUrls: ["./contact-table.component.scss"],
})
export class ContactTableComponent implements OnInit {
  unselectAllContacts() {
    this.selectedContacts = [];
  }

  exportSelectedContacts() {
    let filename = "nestor_contacts.csv";
    const email = this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" });
    const first = this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" });
    const last = this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" });
    const phone = this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" });
    let contacts = this.selectedContacts.map((contact) => {
      return {
        [email]: contact.emailAddress,
        [first]: contact.firstName,
        [last]: contact.lastName,
        [phone]: contact.phoneNumber,
      };
    });
    let csv = this.papaService.unparse(contacts);
    let csvFile = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(csvFile);
    let link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.rel = "noopener";

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener("click", clickHandler);
      }, 150);
    };
    link.addEventListener("click", clickHandler, false);
    link.click();
  }
  @ViewChild("contactsTable") contactsTable: Table;
  @ViewChild("tagsFilterPanel") tagsFilterPanel: OverlayPanel;
  @ViewChild("tagsFilterListbox") tagsFilterListbox: Listbox;

  @ViewChild("addTagsPanel") addTagsPanel: OverlayPanel;
  @ViewChild("addTagsListbox") addTagsListbox: Listbox;

  @Input() isCreatingContact: boolean = false;
  @Input() isSubmitting: boolean = false;

  @Output() onCreateContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() onImportContacts: EventEmitter<any> = new EventEmitter<any>();

  contacts$: Observable<ContactModel[]>;
  isLoading$: Observable<boolean>;
  isSaving$: Observable<boolean>;
  isDeleting$: Observable<boolean>;

  paginationTemplate: string;
  selectedContacts: ContactModel[];
  showContactCreationModal: boolean = false;
  cols: TableColumn[];
  allContactTags: string[] = [];
  allContactTagsOptions: TagOption[] = [];
  filteredTags: string[];

  selectedContactsNewTags: string[] = [];

  constructor(
    private contactsService: ContactsService,
    private contactsAPIService: AddressBookAPIService,
    private router: Router,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    public themingService: ThemingService,
    private readonly tracker: MatomoTracker,
    private papaService: Papa,
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.contactsService.isLoading$;
    this.isSaving$ = this.contactsService.isSaving$;
    this.isDeleting$ = this.contactsService.isDeleting$;
    this.contacts$ = this.contactsService.contacts$;
    this.contactsService.initialize(0, 10, ContactScopeEnum.USER);

    this.retrieveContactsTags();

    this.cols = [
      {
        field: "emailAddress",
        header: this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" }),
        classnames: "font-medium",
      },
      {
        field: "firstName",
        header: this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" }),
      },
      {
        field: "lastName",
        header: this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" }),
      },
      {
        field: "phoneNumber",
        header: this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" }),
      },
    ];

    this.paginationTemplate =
      "{first} " +
      this.translateService.instant("COMMON.text.to") +
      " {last} " +
      this.translateService.instant("COMMON.text.of") +
      " {totalRecords} " +
      this.translateService.instant("COMMON.text.contacts");
  }

  loadContactsLazy(event: LazyLoadEvent) {
    let order: ContactOrderEnum;

    switch (event.sortField) {
      case "emailAddress":
        order = event.sortOrder > 0 ? ContactOrderEnum.EMAIL_ASC : ContactOrderEnum.EMAIL_DESC;
        break;
      case "phoneNumber":
        order = event.sortOrder > 0 ? ContactOrderEnum.PHONE_ASC : ContactOrderEnum.PHONE_DESC;
        break;
      case "firstName":
        order = event.sortOrder > 0 ? ContactOrderEnum.FIRSTNAME_ASC : ContactOrderEnum.FIRSTNAME_DESC;
        break;
      case "lastName":
        order = event.sortOrder > 0 ? ContactOrderEnum.LASTNAME_ASC : ContactOrderEnum.LASTNAME_DESC;
        break;
      default:
        order = null;
        break;
    }

    this.contactsService.initialize(
      event.first,
      event.rows,
      ContactScopeEnum.USER,
      order,
      event.globalFilter,
      this.filteredTags,
    );
  }

  get total() {
    return this.contactsService.total;
  }

  retrieveContactsTags() {
    this.contactsAPIService
      .getTags({ scope: ContactScopeEnum.USER }, [ResponseLevelEnum.MINIMIZE])
      .pipe(untilDestroyed(this))
      .subscribe((tags) => {
        this.allContactTags = tags.map(({ label }) => label);
        this.allContactTagsOptions = tags.map(({ label }) => ({ label: label, value: label }));
      });
  }

  saveContacts(contacts: ContactModel[]): Observable<ContactModel[]> {
    return this.contactsService.saveContacts({ contacts }, [ResponseLevelEnum.MINIMIZE]).pipe(
      tap(() => this.retrieveContactsTags()),
      untilDestroyed(this),
    );
  }

  deleteContacts(contacts: ContactModel[]): Observable<ContactModel[]> {
    return this.contactsService.deleteContacts({ contacts }, [ResponseLevelEnum.MINIMIZE]).pipe(
      tap(() => this.retrieveContactsTags()),
      untilDestroyed(this),
    );
  }

  handleCreateContact(contact: ContactModel) {
    this.saveContacts([contact])
      .pipe(
        tap((result) => this.hideContactCreationModal()),
        untilDestroyed(this),
      )
      .subscribe((contacts) => {
        this.tracker.trackEvent("Contacts", "Added", MatomoEventEnum.CONTACTS_ADDED, 1);
      });
  }

  viewContact(contact: ContactModel) {
    this.router.navigate(["/contacts", contact.contactIdentifier]);
  }

  handleDeleteContacts() {
    this.confirmationService.confirm({
      header: this.translateService.instant("ADDRESS-BOOK.actions.remove-contacts.header", {
        default: "Are you sure ?",
      }),
      message: this.translateService.instant("ADDRESS-BOOK.actions.remove-contacts.body", {
        default: "These contacts wil be removed from your adress book.",
      }),
      acceptLabel: this.translateService.instant("COMMON.buttons.delete"),
      rejectLabel: this.translateService.instant("COMMON.buttons.cancel"),
      acceptButtonStyleClass: "p-button-sm p-button-danger hide-duplicated-icon",
      rejectButtonStyleClass: "p-button-outlined  p-button-sm",
      acceptIcon: "pi pi-trash",
      rejectIcon: "hidden",
      accept: () => {
        this.deleteContacts(this.selectedContacts)
          .pipe(untilDestroyed(this))
          .subscribe((contacts) => {
            this.selectedContacts = [];
          });
      },
    });
  }

  displayContactCreationModal() {
    this.showContactCreationModal = true;
  }

  hideContactCreationModal() {
    this.showContactCreationModal = false;
  }

  handleTagsFilterClick(event: MouseEvent) {
    this.tagsFilterPanel.toggle(event);
  }
  handleTagsManagementClick(event: MouseEvent) {
    this.addTagsPanel.toggle(event);
  }

  handleAutocompleteTagChange(tags: string[]) {
    this.contactsService.tags = tags;
  }

  handleFilterByTags(event: ListboxChangeEvent) {
    this.contactsService.tags = event.value;
    this.handleClearFilterAutocompleteInput();
  }

  handleAddNewTagsChange(event: string[]) {
    this.selectedContactsNewTags = event;
  }

  handleCreateAndAddTag(tag: string) {
    this.selectedContactsNewTags = [tag, ...(this.selectedContactsNewTags ?? [])];
    this.handleClearAddAutocompleteInput();
  }

  handleAddNewTagsSubmit() {
    let updatedContacts = this.selectedContacts.map((contact) => {
      let tags = [...new Set([...this.selectedContactsNewTags, ...contact.tags])];
      let updatedContact = Object.assign(new ContactModel(), contact, { tags });
      return updatedContact;
    });

    this.saveContacts(updatedContacts)
      .pipe(untilDestroyed(this))
      .subscribe((contacts) => {
        // Clean up
        this.addTagsPanel.hide();
        this.selectedContacts = [];
        this.selectedContactsNewTags = [];
      });
  }

  handleClearAddAutocompleteInput() {
    this.addTagsListbox.filterOptions.reset();
    this.addTagsListbox._filterValue = "";
    let inputElement = document.querySelector("#addTagsInput") as HTMLInputElement;
    if (inputElement) {
      inputElement.value = "";
    }
  }

  handleClearFilterAutocompleteInput() {
    this.tagsFilterListbox.filterOptions.reset();
    this.tagsFilterListbox._filterValue = "";
    let inputElement = document.querySelector("#tagsFilterInput") as HTMLInputElement;
    if (inputElement) {
      inputElement.value = "";
    }
  }

  // FILTERS
  get hasActiveFilters() {
    return Boolean(this.contactsService.searchText || this.contactsService.tags?.length > 0);
  }
  clearFilters() {
    this.contactsService.tags = undefined;
    this.contactsService.searchText = undefined;
    this.filteredTags = undefined;
  }
}
