import BaseModel from "../base.model";
import RecordMessageAnswerContentInvitationModel from "./record-message-answer-content-invitation.model";
import RecordMessageAnswerContentSignatureModel from "./record-message-answer-content-signature.model";

export default class RecordMessageAnswerContentModel extends BaseModel {
  /**
   * Form item answer related properties
   */
  public value: string;
  public files: string[]; // Files will be put in a separate attribute to facilitate answer handling

  /**
   * Signature answer related properties
   */
  public signature: RecordMessageAnswerContentSignatureModel;

  /**
   * Invitation answer related properties
   */
  public invitation: RecordMessageAnswerContentInvitationModel;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.signature) this.signature = new RecordMessageAnswerContentSignatureModel().deserialize(input.signature);

    if (input.invitation)
      this.invitation = new RecordMessageAnswerContentInvitationModel().deserialize(input.invitation);

    return this;
  }
}
