import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { UiModule } from "@app/@ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@app/@shared/shared.module";
import { COMPONENTS } from "./components";
import { MainModule } from "@app/main/main.module";

// This module can safely be imported inside other components
// without creating routing conflicts.

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // Custom modules
    MainModule,
    SharedModule,
    UiModule,
  ],
  declarations: [COMPONENTS],
  exports: [COMPONENTS],
})
export class ContactsWithoutRoutingModule {}
