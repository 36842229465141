<ng-container *ngIf="!(isLoading$ | async); else loadingSpinner">
  <div class="dialog-nested-content h-full">
    <ul class="list-none m-0 pl-0">
      <!-- Breadcrumbs -->
      <li class="py-3 pl-1 pr-3 surface-border border-bottom-1 flex align-items-center justify-content-between gap-1">
        <ol class="list-none inline-flex items-center space-x-1 md:space-x-3 p-0">
          <li>
            <div class="flex align-items-center">
              <a
                [ngClass]="currentPath === '/' ? 'text-color-secondary' : 'text-color cursor-pointer'"
                (click)="navigateToPath('/')"
                >{{ "MAIN.cloud-file-picker.root-directory" | translate : { "default": "Root" } }}</a
              >
            </div>
          </li>
          <li *ngFor="let crumb of crumbs$ | async; let index = index">
            <div class="flex align-items-center">
              <i class="msr-icon-navigate_next icon-14px"></i>
              <a
                class="ml-1"
                [ngClass]="index === crumbs.length - 1 ? 'text-color-secondary' : 'text-color cursor-pointer'"
                (click)="navigateToCrumb(index)"
                >{{ crumb }}</a
              >
            </div>
          </li>
        </ol>
      </li>

      <!-- New folder creation -->
      <ng-container *ngIf="enableFolderCreation">
        <li
          class="py-3 pl-2 pr-3 surface-border hover:bg-primary-100 cursor-pointer flex align-items-center justify-content-between gap-1 border-bottom-1"
          (click)="handleNewFolderClick()"
        >
          <div class="flex align-items-center gap-3">
            <i class="msr-icon-add icon-18px text-primary"></i>
            <div class="font-medium text-primary">
              {{ "MAIN.cloud-file-picker.new-folder.label" | translate : { default: "Create new folder" } }}
            </div>
          </div>
        </li>
      </ng-container>

      <!-- Folder creation mode -->
      <ng-container *ngIf="isCreationModeEnabled">
        <li class="py-3 pl-2 pr-3 surface-border flex align-items-center justify-content-between gap-1 border-bottom-1">
          <div class="flex align-items-center gap-3 w-full">
            <input
              #newFolderInput
              class="flex-grow-1"
              pInputText
              name="folderName"
              [(ngModel)]="newFolderName"
              [attr.aria-label]="
                'MAIN.cloud-file-picker.new-folder.placeholder' | translate : { default: 'Create new folder' }
              "
              [placeholder]="
                'MAIN.cloud-file-picker.new-folder.placeholder' | translate : { default: 'Create new folder' }
              "
              [ngClass]="{
                'ng-invalid ng-dirty text-red-500': newFolderInvalid
              }"
              (keydown.enter)="submitCreationMode($event)"
              (keydown.escape)="cancelCreationMode($event)"
            />
            <div class="flex align-items-center gap-1">
              <button
                pButton
                class="p-button-sm"
                icon="msr-icon-done"
                (click)="submitCreationMode($event)"
                [disabled]="isCreatingFolder"
              ></button>
              <button
                pButton
                class="p-button-sm"
                icon="msr-icon-close"
                (click)="cancelCreationMode($event)"
                [disabled]="isCreatingFolder"
              ></button>
            </div>
          </div>
        </li>
      </ng-container>

      <!-- Resources list -->
      <ng-container *ngIf="(resources$ | async).length; else emptyList">
        <ng-container *ngFor="let resource of resources$ | async as resources; let index = index">
          <li
            class="py-3 pl-2 pr-3 surface-border hover:bg-primary-100 cursor-pointer flex align-items-center justify-content-between gap-1"
            [ngClass]="{
              'border-bottom-1': index !== resources.length - 1,
              'bg-primary-200': resource === selectedResource
            }"
            (click)="select($event, resource)"
          >
            <!-- Icon + name -->
            <div class="flex align-items-center gap-3">
              <ng-container *ngIf="resource.isDirectory; else mimeTypeIcon">
                <i class="msr-icon-folder icon-18px text-color"></i>
              </ng-container>
              <ng-template #mimeTypeIcon>
                <i class="text-color icon-18px" [ngClass]="resource.mimeType | getMimeTypeIcon"></i>
              </ng-template>
              <div class="flex flex-column">
                <div class="font-medium text-color">{{ resource.name }}</div>
                <div class="text-xs text-color-secondary" *ngIf="resource.owner">
                  <i class="msr-icon-person icon-sm mr-1"></i>
                  {{ resource.owner }}
                </div>
              </div>
            </div>

            <!-- Metadata or arrow-->
            <div class="text-color-secondary text-xs" *ngIf="!resource.isDirectory; else navigationArrow">
              {{ resource.size | filesize }} - {{ resource.mimeType }}
            </div>
            <ng-template #navigationArrow>
              <a
                class="text-color-secondary hover:bg-gray-200 border-circle flex justify-content-center align-items-center"
                (click)="navigate($event, resource)"
              >
                <i class="msr-icon-navigate_next icon-18px"></i>
              </a>
            </ng-template>
          </li>
        </ng-container>
      </ng-container>

      <!-- Empty list -->
      <ng-template #emptyList>
        <li class="py-3 pl-2 pr-3 surface-border flex align-items-center justify-content-between gap-1">
          <span class="text-color-secondary">
            {{ "MAIN.cloud-file-picker.empty-list" | translate : { "default": "Directory is empty" } }}
          </span>
        </li>
      </ng-template>
    </ul>
  </div>
  <div class="dialog-nested-footer flex gap-3 justify-content-end mt-3">
    <button
      type="button"
      pButton
      class="p-button-sm p-button-outlined"
      [label]="'COMMON.buttons.cancel' | translate : { 'default': 'Cancel' }"
      (click)="cancel()"
    ></button>
    <button
      type="button"
      pButton
      class="p-button-sm p-button-primary"
      [label]="submitLabel"
      [disabled]="!selectedResource"
      (click)="submit()"
    ></button>
  </div>
</ng-container>

<ng-template #loadingSpinner>
  <div class="flex py-4 justify-content-center">
    <ui-spinner [size]="60"></ui-spinner>
  </div>
</ng-template>
