<header
  *ngIf="(displayNavbar$ | async) || !((navigationBarVariant$ | async) === NavigationBarVariantEnum.HIDDEN)"
  class="padded-container flex align-items-center fixed w-full left-0 top-0"
  [ngClass]="{ 'hidden sm:flex': (navigationBarVariant$ | async) === NavigationBarVariantEnum.RESPONSIVE }"
  [ngSwitch]="navigationBarVariant$ | async"
>
  <!-- PRIMARY VARIANT -->
  <ng-container *ngSwitchCase="'primary'" [ngTemplateOutlet]="defaultHeaderContent"> </ng-container>
  <!-- BACK TO NESTOR VARIANT -->
  <ng-container *ngSwitchCase="NavigationBarVariantEnum.BACK_TO_NESTOR">
    <div class="w-full h-full measured-container" style="display: grid; grid-template-columns: 10rem 1fr 10rem">
      <a
        [routerLink]="['/']"
        pButton
        class="p-button-text sm:no-underline p-button-secondary p-1 md:flex"
        [label]="'COMMON.buttons.back-to-nestor' | translate : { default: 'Back to Nestor' }"
      ></a>
    </div>
  </ng-container>

  <!-- 
    - HIDDEN VARIANT
   -->
  <ng-container *ngSwitchCase="NavigationBarVariantEnum.HIDDEN"></ng-container>

  <!-- LOGO VARIANT -->
  <ng-container *ngSwitchCase="NavigationBarVariantEnum.LOGO_ONLY">
    <div class="w-full h-full measured-container flex justify-content-center">
      <!-- Logo image -->
      <a [routerLink]="['/']" pButton class="p-button-text no-underline p-1 md:flex">
        <img height="36px" [src]="'/assets/images/logo-colored.svg'" class="logo-small" alt="" />
      </a>
    </div>
  </ng-container>

  <!-- RESPONSIVE VARIANT -->
  <ng-container *ngSwitchCase="NavigationBarVariantEnum.RESPONSIVE" [ngTemplateOutlet]="defaultHeaderContent">
  </ng-container>
</header>

<p-dialog
  [(visible)]="showStartCampaignInOtherUnit"
  [modal]="true"
  position="center"
  [header]="
    'RECORD-BATCH.new-campaign.change-unit.title' | translate : { 'default': 'Select a workspace for your campaign' }
  "
  [draggable]="false"
  [maximizable]="true"
  [baseZIndex]="10000"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="{ width: 'var(--xsmall-measure)' }"
>
  <ng-template pTemplate="content">
    <ng-container *ngIf="campaignCapableUnits.length > 0; else noCampaignCapableUnits">
      <p>
        {{
          "RECORD-BATCH.new-campaign.change-unit.description"
            | translate : { "default": "Please select a unit below for creating your campaign" }
        }}
      </p>
      <div class="auto-grid" style="--grid-min-item-size: 10rem">
        <p-button
          (click)="selectUnit(userUnit.unit)"
          styleClass="p-button-outlined w-full h-full justify-content-center py-5"
          *ngFor="let userUnit of campaignCapableUnits"
        >
          <ng-template pTemplate="content">
            <div class="flex flex-column align-items-center justify-content-center" style="min-height: 5rem">
              <img
                width="50"
                class="border-round"
                *ngIf="userUnit.unit.avatar"
                [src]="userUnit.unit.avatar"
                [alt]="userUnit.unit.name"
              />
              <div
                class="w-3rem h-3rem surface-100 border-round flex align-items-center justify-content-center"
                *ngIf="!userUnit.unit.avatar"
              >
                <span class="text-color p-1 text-lg font-bold">{{ userUnit.unit.name[0].toUpperCase() }}</span>
              </div>
              <span class="text-color p-1 text-lg clamp">{{ userUnit.unit.name }}</span>
            </div>
          </ng-template>
        </p-button>
      </div>
    </ng-container>
    <ng-template #noCampaignCapableUnits>
      <p>
        {{
          "RECORD-BATCH.new-campaign.change-unit.no-private-unit"
            | translate : { "default": "A private workspace is necessary to create a new campaign." }
        }}
      </p>
      <button
        pButton
        (click)="handleCreateUnit()"
        type="button"
        [label]="'UNIT.titles.create-unit' | translate : { 'default': 'Create child unit' }"
      ></button>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      pButton
      class="p-button-outlined p-button-sm"
      [label]="'COMMON.buttons.close' | translate : { 'default': 'Close' }"
      (click)="this.showStartCampaignInOtherUnit = false"
    ></button>
  </ng-template>
</p-dialog>

<ng-template #defaultHeaderContent>
  <div class="flex align-items-center justify-content-between w-full h-full measured-container">
    <div
      *ngIf="sessionAPIService.isLoading$ | async; else loadedSessionMenu"
      style="
        --skeleton-background: rgba(var(--primary-color-rgb), 0.08);
        --surface-border: rgba(var(--primary-color-rgb), 0.3);
      "
    >
      <p-skeleton width="8rem" height="2.75rem" styleClass="border-1 surface-border"></p-skeleton>
    </div>
    <ng-template #loadedSessionMenu>
      <div *ngIf="!(sessionAPIService.isLoading$ | async)" class="flex gap-3 flex-wrap align-items-center">
        <!-- UNIT MENU Selector -->
        <ng-container *ngIf="sessionAPIService.currentUser?.units?.length > 1; else defaultLogoTemplate">
          <session-unit-menu></session-unit-menu>
        </ng-container>
        <!-- Content to display when the user can't manage Units -->
        <ng-template #defaultLogoTemplate>
          <a [routerLink]="['/']" pButton class="p-button-text no-underline p-1 md:flex">
            <img
              width="36px"
              height="36px"
              [src]="(themingService.avatarUrl$ | async) ?? '/assets/images/logo-colored-icon.svg'"
              class="logo-small"
              alt=""
            />
            <!-- For Guest users display the unit name -->
            <span
              class="ml-2 font-medium text-color clamp"
              [ngClass]="{
                'hidden lg:block': !authorizationService.isGuest(),
                ' sm:block': authorizationService.isGuest()
              }"
              *ngIf="sessionAPIService.session$ | async as session"
            >
              {{ session.unit.isPublic ? ("COMMON.nestor" | translate : { default: "Nestor" }) : session.unit.name }}
            </span>
          </a>
        </ng-template>
        <!-- Fallback Home button -->
        <ng-container *ngIf="(displayBackToInbox$ | async) && !canViewPeripheralElements()">
          <span
            aria-hidden
            class="text-xs font-bold text-color-secondary"
            *ngIf="!homeButton.classList.contains('hidden')"
            >/</span
          >
          <button
            #homeButton
            [routerLink]="['/inbox']"
            routerLinkActive="hidden"
            pButton
            class="p-button-text p-button-sm p-button-secondary font-normal py-2 px-0"
            [label]="'SESSION.user-menu.transfers.summary.label' | translate"
          ></button>
        </ng-container>
      </div>
    </ng-template>
    <!-- Navigation MENU - Conversations | Campaigns | Contacts | Templates | New  -->
    <div class="flex align-items-center gap-5 h-full">
      <main-navigation-menu
        [showInlineMenu]="showInlineMenu"
        [inlineMenuItems]="inlineMenuItems"
        [hideBottomBar]="hideBottomBar$ | async"
      ></main-navigation-menu>

      <!-- NEW RECORD MENU -->
      <main-create-record-menu
        [showCreateMenu]="showCreateMenu"
        [recordMenuItems]="recordMenuItems"
      ></main-create-record-menu>
    </div>

    <!-- Notifications & User menu -->
    <div class="flex align-items-center gap-3">
      <!-- Notifications menu -->
      <notifications-menu></notifications-menu>
      <!-- User Menu - Profile | Billing etc... -->
      <session-user-menu></session-user-menu>
    </div>
  </div>
</ng-template>

<!-- HTML TEMPLATES -->
<!-- Not rendered by default, used as a shell to create custom menu items -->
<template id="menu-label-template">
  <div class="menu-item">
    <div class="image"></div>
    <h3 class="title"></h3>
    <span class="badge"></span>
    <p class="subtitle"></p>
    <small class="description"></small>
  </div>
</template>
