import BaseModel from "@shared/models/base.model";
import { DateHelper } from "@shared/helpers/date.helper";

export default class FileModel extends BaseModel {
  // MINIMIZE (default)
  public fileIdentifier: string | null = null;
  public originalFilename: string | null = null;
  public hash: string | null = null;
  public extension: string | null = null;
  public name: string | null = null;
  public url: string | null = null;
  public mimeType: string | null = null;
  public size: number | null = null;
  public statusCode: string | null = null;
  public statusDate: Date | null = null;
  public storageType: string | null = null;
  public storageProperties: JsonValue = {};

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.statusDate) this.statusDate = DateHelper.parseDate(input.statusDate);

    return this;
  }
}
