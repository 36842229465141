import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import UserModel from "@app/@shared/models/user/user.model";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "user-consents-field",
  templateUrl: "./user-consents-field.component.html",
  styleUrls: ["./user-consents-field.component.scss"],
})
export class UserConsentsFieldComponent implements OnInit {
  @Input() isLoading: boolean = false;

  @Input() consentLogs: boolean = false;
  @Input() consentAnalytics: boolean = false;
  @Input() consentNewsletter: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
