import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";

export default class ProviderContactModel extends BaseModel {
  public id: string | null = null;
  public businessAddress: string | null = null;
  public company: string | null = null;
  public displayName: string | null = null;
  public givenName: string | null = null;
  public surname: string | null = null;
  public homeAddress: string | null = null;
  public jobTitle: string | null = null;
  public parentFolder: string | null = null;
  public emailAddresses: string[] = [];
  public phoneNumbers: string[] = [];

  public createdAt: Date | null = null;
  public birthday: Date | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.birthday) this.birthday = DateHelper.parseDate(input.birthday);

    return this;
  }
}
