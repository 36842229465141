<!-- Spinner -->
<div class="flex flex-column gap-2 align-items-center justify-content-center h-full" *ngIf="!this.pdfLoaded">
  <ui-spinner></ui-spinner>
  <span class="text-color-secondary text-sm">{{
    "COMPONENTS.pdf-viewer.loading-message" | translate: { "default": "Loading file..." }
  }}</span>
</div>

<!-- PDF viewer -->
<pdf-viewer
  class="pdf-viewer"
  [ngStyle]="{ 'visibility': this.pdfLoaded ? 'visible' : 'hidden', 'height': this.pdfLoaded ? null : 0 }"
  [src]="fileUrl + '?recordIdentifier=' + record.recordIdentifier + '&access_token=' + this.accessToken"
  [render-text]="true"
  [show-all]="true"
  [show-borders]="true"
  [original-size]="false"
  [zoom]="currentZoom"
  [external-link-target]="'blank'"
  [render-text-mode]="2"
  [(page)]="currentPage"
  (after-load-complete)="onLoadComplete($event)"
  (text-layer-rendered)="onTextLayerRendered($event)"
  (page-rendered)="onPageRendered($event)"
  (pages-initialized)="onPagesInitialized($event)"
></pdf-viewer>
