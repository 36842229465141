import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Logger, UntilDestroy, untilDestroyed } from "@app/@core";
import { ProviderCategoryEnum } from "@app/@shared/enums/provider-category.enum";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import { PhoneHelper } from "@app/@shared/helpers/phone.helper";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import ProviderApplicationModel from "@app/@shared/models/providers/provider-application.model";
import ProviderContactModel from "@app/@shared/models/providers/provider-contact.model";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import { ProviderApplicationsService } from "@app/@shared/services/providers/provider-applications.service";
import { ProviderContactsService } from "@app/contacts/services/provider-contacts.service";
import { BehaviorSubject, Observable, catchError, filter, of, tap } from "rxjs";

const LOGGER = new Logger("Contacts/ProviderContactPickerComponent");

@UntilDestroy()
@Component({
  selector: "provider-contact-picker",
  templateUrl: "./provider-contact-picker.component.html",
  styleUrls: ["./provider-contact-picker.component.scss"],
})
export class ProviderContactPickerComponent {
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSubmitProviderRecipients: EventEmitter<RecordRecipientModel[]> = new EventEmitter();

  applications$: BehaviorSubject<ProviderApplicationModel[]> = new BehaviorSubject(undefined);
  isLoadingApplications$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  currentApplication$: BehaviorSubject<ProviderApplicationModel> = new BehaviorSubject(undefined);
  refreshItemsToken$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  isLoadingContacts$: Observable<boolean>;

  @Input() recipientRole: RecordRoleEnum = RecordRoleEnum.READER;
  contacts$: Observable<ProviderContactModel[]>;

  selectedContacts: ProviderContactModel[];

  get currentApplication() {
    return this.currentApplication$.getValue();
  }

  constructor(
    private providerApplicationsService: ProviderApplicationsService,
    private providerContactsService: ProviderContactsService,
  ) {}

  ngOnInit() {
    this.isLoadingContacts$ = this.providerContactsService.isLoading$;
    this.contacts$ = this.providerContactsService.contacts$;

    // Retrieve provider applications
    this.providerApplicationsService
      .listApplications({ categories: [ProviderCategoryEnum.CONTACTS_PROVIDER], revoked: false })
      .pipe(
        tap(() => this.isLoadingApplications$.next(true)),
        catchError(() => {
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe((applications) => {
        if (applications.length > 0) {
          this.currentApplication$.next(applications[0]);
        }
        this.applications$.next(applications);
        this.isLoadingApplications$.next(false);
      });

    // Retrieve contacts
    this.currentApplication$
      .pipe(
        filter((application) => Boolean(application)),
        tap((application) => this.providerContactsService.initialize(application.applicationIdentifier)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.selectedContacts = [];
      });
  }

  submit() {
    if (this.selectedContacts?.length > 0) {
      let recipients = this.selectedContacts.map(({ emailAddresses, givenName, surname, phoneNumbers }) => {
        const emailAddress = emailAddresses?.[0];
        const phoneNumber = phoneNumbers?.[0];
        const validEmail = emailAddress && StringHelper.isEmail(emailAddress);
        const validPhoneNumber = phoneNumber && PhoneHelper.isValidPhoneNumber(phoneNumber);

        let recipient = new RecordRecipientModel();
        recipient.emailAddress = emailAddress;
        recipient.firstName = givenName;
        recipient.lastName = surname;
        recipient.phoneNumber = validPhoneNumber ? phoneNumber : null;
        recipient.role = this.recipientRole;
        return recipient;
      });
      this.onSubmitProviderRecipients.emit(recipients);
    }
  }

  handleSelectApplication(application: ProviderApplicationModel) {
    this.currentApplication$.next(application);
  }
}
