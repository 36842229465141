import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import { InboxFilterTypeEnum } from "../enums/inbox-filter-type.enum";
import InboxFiltersResultModel from "../models/inbox/inbox-filters-result.model";
import RecordRecipientModel from "../models/record/record-recipient.model";
import { RecordTypeEnum } from "../enums/record-type.enum";
import InboxResultModel from "../models/inbox/inbox-result.model";
import RecordModel from "../models/record/record.model";
import { InboxFilters } from "../types/inbox-filters.type";
import InboxTagModel from "../models/inbox/inbox-tag.model";

@Injectable({
  providedIn: "root",
})
export class InboxAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getFilters(
    types: InboxFilterTypeEnum[] = [],
    query: string = null,
    guest: boolean = false,
    excludeChildren: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<InboxFiltersResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.inbox.filters;
    const correlationId = v4();

    this.loaderService.addOperation("getFilters");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          types,
          query,
          guest,
          excludeChildren,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result: InboxFiltersResultModel = new InboxFiltersResultModel();
          result.receivedCount = response["receivedCount"];
          if (response["recipients"])
            result.recipients = response["recipients"].map((r) => new RecordRecipientModel().deserialize(r));
          if (response["tags"]) result.tags = response["tags"].map((t) => new InboxTagModel().deserialize(t));
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getFilters");
        }),
      );
  }

  getReceived(
    filters: InboxFilters = {},
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<InboxResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.inbox.received;
    const correlationId = v4();

    this.loaderService.addOperation("getReceived");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset: limitOffset,
            limitCount: limitCount,
          },
          filters,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result: InboxResultModel = new InboxResultModel();
          result.records = response["records"].map((r) => new RecordModel().deserialize(r));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getReceived");
        }),
      );
  }

  getSent(
    filters: InboxFilters = {},
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<InboxResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.inbox.sent;
    const correlationId = v4();

    this.loaderService.addOperation("getSent");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset: limitOffset,
            limitCount: limitCount,
          },
          filters,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result: InboxResultModel = new InboxResultModel();
          result.records = response["records"].map((r) => new RecordModel().deserialize(r));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getSent");
        }),
      );
  }

  getClosed(
    filters: InboxFilters = {},
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<InboxResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.inbox.closed;
    const correlationId = v4();

    this.loaderService.addOperation("getClosed");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset: limitOffset,
            limitCount: limitCount,
          },
          filters,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result: InboxResultModel = new InboxResultModel();
          result.records = response["records"].map((r) => new RecordModel().deserialize(r));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getClosed");
        }),
      );
  }

  getCanceled(
    filters: InboxFilters = {},
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<InboxResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.inbox.canceled;
    const correlationId = v4();

    this.loaderService.addOperation("getCanceled");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset: limitOffset,
            limitCount: limitCount,
          },
          filters,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result: InboxResultModel = new InboxResultModel();
          result.records = response["records"].map((r) => new RecordModel().deserialize(r));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getCanceled");
        }),
      );
  }

  markAs(
    recordIdentifiers: string[],
    read: boolean,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.inbox.markAs;
    const correlationId = v4();

    this.loaderService.addOperation("markAs");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          recordIdentifiers,
          read,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("markAs");
        }),
      );
  }
}
