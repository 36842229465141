export enum HttpHeadersEnum {
  CONTENT_TYPE = "Content-Type",
  AUTHORIZATION = "Authorization",
  FILE_IDENTIFIER = "FileIdentifier",

  /* APP Headers */
  APPLICATION = "Application",
  CORRELATION_ID = "CorrelationId",
  DOMAIN = "Domain",
  LOCALE = "Locale",
  RESPONSE_LEVELS = "Response-Levels",

  /* File Headers */
  EXPORT_IDENTIFIER = "ExportIdentifier",
  FILE_MIME_TYPE = "File-MimeType",
  FILE_NAME = "FileName",
}
