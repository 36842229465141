import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MessageFormatEnum } from "@app/@shared/enums/message-format.enum";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import { SignatureAttachmentTypeEnum } from "@app/@shared/enums/signature-attachment-type.enum";
import { SignatureMethodEnum } from "@app/@shared/enums/signature-method.enum";
import { UploadTypeEnum } from "@app/@shared/enums/upload-type.enum";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import FileModel from "@app/@shared/models/file/file.model";
import SignatureAttachmentModel from "@app/@shared/models/signature/signature-attachment.model";
import SignatureModel from "@app/@shared/models/signature/signature.model";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import RecordModel from "@app/@shared/models/record/record.model";
import PublicKeyModel from "@app/@shared/models/vault/public-key.model";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { CryptographyService } from "@app/@shared/services/cryptography.service";
import { FilesAPIService, StartUploadParam, StartUploadResult } from "@app/@shared/services/files-api.service";
import { RecordAPIService } from "@app/@shared/services/record-api.service";
import { SignatureAPIService } from "@app/@shared/services/signature-api.service";
import { UploadService } from "@app/@shared/services/upload.service";
import { RecordValidators } from "@app/record-standard/validators";
import { RecordService } from "@app/record/services/record.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { ContentChange, SelectionChange } from "ngx-quill";
import { Metadata, UploadState, UploadxService } from "ngx-uploadx";
import { MessageService } from "primeng/api";
import {
  BehaviorSubject,
  catchError,
  map,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";
import { ProviderGatewayService } from "@app/@shared/services/providers/provider-gateway.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import { MatomoEventEnum } from "@app/@shared/enums/matomo-event-enum";
import { MatomoTracker } from "ngx-matomo-client";

type Step = "content" | "recipients";

type RecordSignatureMessageFormValue = {
  recipients: RecordRecipientModel[];
  messageBody: string;
};

@UntilDestroy()
@Component({
  selector: "record-signature-message-form",
  templateUrl: "./record-signature-message-form.component.html",
  styleUrls: ["./record-signature-message-form.component.scss"],
})
export class RecordSignatureMessageFormComponent implements OnInit {
  @Input() publicKey: PublicKeyModel;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onSignatureMessageCreated: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();

  cloudFiles: ProviderItemModel[] = [];
  customDialogStyles$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  displayImportFromCloudModal: boolean = false;
  displayImportFromProviderModal: boolean = false;
  enableSignatureMessage: boolean = false;
  encryptedMessage: string;
  files: FileModel[] = [];
  form: UntypedFormGroup;
  isLoading$: Observable<boolean>;
  isSubmitting: boolean = false;
  isUploading: boolean = false;
  message: RecordMessageModel;
  recipients: RecordRecipientModel[] = [];
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  step: Step = "content";
  signatureUploadIds: string[] = [];
  signatureResources: ProviderItemModel[] = [];
  uploads: UploadState[] = [];
  uploadState$: Observable<UploadState>;
  authorizedFileTypes: string[] = ["pdf"]; // auhtorized files types

  get record() {
    return this.record$.getValue();
  }

  get customDialogStyles() {
    return this.customDialogStyles$.getValue();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private uploadxService: UploadxService,
    private uploadService: UploadService,
    private messageService: MessageService,
    private themingService: ThemingService,
    private cryptographyService: CryptographyService,
    private filesApiService: FilesAPIService,
    private recordAPIService: RecordAPIService,
    private recordService: RecordService,
    private providerGatewayService: ProviderGatewayService,
    private signatureAPIService: SignatureAPIService,
    private authorizationService: AuthorizationService,
    private readonly tracker: MatomoTracker,
  ) {
    // Put this in a BehaviorSubject to access its value at any time
    this.recordService.record$.subscribe(this.record$);
  }

  ngOnInit(): void {
    // Initialize upload state
    this.uploadState$ = this.uploadxService.init(this.uploadService.getDefaultOptions());

    this.uploadState$.pipe(untilDestroyed(this)).subscribe((state) => {
      this.handleUploadStateChanged(state);
    });

    // Build form controls
    this.buildForm();

    // Build custom dialog styles
    this.themingService.customStyles$
      .pipe(
        map((value) => ({
          ...{ width: "var(--large-measure)" },
          ...value,
        })),
      )
      .subscribe(this.customDialogStyles$);
  }
  get isPaid() {
    return this.authorizationService.isPaidUser();
  }
  buildForm() {
    this.form = this.formBuilder.group({
      recipients: [[], [RecordValidators.recipientsNotEmpty, RecordValidators.recipientsValidForSignature]],
      messageBody: [null, []],
    });
  }

  get formContentInvalid() {
    return this.signatureUploads.length === 0 && this.signatureResources.length === 0;
  }

  setStep(step: Step) {
    this.step = step;
  }

  /**
   * Documents
   */
  cancelUpload(uploadId?: string): void {
    this.uploadxService.control({ action: "cancel", uploadId });
  }

  pauseUpload(uploadId?: string): void {
    this.uploadxService.control({ action: "pause", uploadId });
  }

  uploadUpload(uploadId?: string): void {
    this.uploadxService.control({ action: "upload", uploadId });
  }

  handleUploadRemove(uploadId: string) {
    this.uploadxService.control({ action: "cancel", uploadId });
  }

  handleSignatureFilesAdded(files: File[]) {
    this.uploadxService.handleFiles(files, { metadata: { type: UploadTypeEnum.SIGNATURE } });
  }

  /**
   * Import from cloud
   */
  handleImportFromCloudClick() {
    this.displayImportFromCloudModal = true;
  }

  handleImportFromCloudSubmitted(resource: ProviderItemModel) {
    this.cloudFiles.push(resource);
    this.signatureResources.push(resource);
    this.handleImportFromCloudClose();
  }
  handleImportFromProviderSubmitted(resource: ProviderItemModel) {
    this.cloudFiles.push(resource);
    this.signatureResources.push(resource);
    this.handleImportFromProviderClose();
  }
  handleImportFromProviderClose() {
    this.displayImportFromProviderModal = false;
  }

  handleImportFromCloudClose() {
    this.displayImportFromCloudModal = false;
  }

  handleCloudFileRemove(resource: ProviderItemModel) {
    this.cloudFiles = this.cloudFiles.filter((r) => r !== resource);
    this.signatureResources = this.signatureResources.filter((r) => r !== resource);
  }

  get signatureUploads(): UploadState[] {
    return this.getUploads(UploadTypeEnum.SIGNATURE);
  }

  getUploads(type: UploadTypeEnum) {
    let uploadIds = this.uploadService.getUploadIdsByUploadType(type, this.uploadxService.queue);
    return this.uploads.filter((upload: UploadState) => uploadIds.includes(upload.uploadId));
  }

  handleUploadStateChanged(state: UploadState) {
    // Update uploads when state change
    const target = this.uploads.find((item) => item.uploadId === state.uploadId);

    if (target) {
      Object.assign(target, state);

      if (state.status === "cancelled") {
        this.uploads = this.uploads.filter((item) => item.uploadId !== state.uploadId);
      } else if (state.status == "complete") {
        let metadata: Metadata = this.uploadService.getQueueUploaderMetadata(state.uploadId, this.uploadxService.queue);
        let fileIdentifier = metadata["fileIdentifier"].toString();
        let chunkHashes = metadata["hashes"] as string[];

        const subscription: Subscription = this.filesApiService
          .recordFinishUpload(fileIdentifier, this.record.recordIdentifier, "", chunkHashes)
          .pipe(untilDestroyed(this))
          .subscribe((file: FileModel) => {
            this.files.push(file);
            this.tracker.trackEvent("Files", "Uploaded", MatomoEventEnum.FILE_UPLOADED, file.size);

            if (this.uploadFinished) {
              this.handleUploadCompleted();
            }

            subscription.unsubscribe();
          });
      }
    } else {
      // Add file to uploads if not already added
      this.uploads.push(state);
    }
  }

  get uploadFinished() {
    let remainingUploads = this.uploads.filter((upload: UploadState) =>
      ["added", "queue", "uploading", "paused", "retry"].includes(upload.status),
    );
    let completedUploads = this.uploads.filter((upload: UploadState) => ["complete"].includes(upload.status));

    // If no remaining uploads & all uploads completed
    return remainingUploads.length === 0 && this.files.length === completedUploads.length + this.cloudFiles.length;
  }

  async startUpload() {
    if (this.record) {
      this.isUploading = true;

      await Promise.all([this.handleStandardUploads(), this.handleCloudUploads()]);
    }
  }

  private handleStandardUploads() {
    return new Promise((resolve) => {
      let startUploadParams: StartUploadParam[] = this.uploads
        .filter((upload) => upload.status == "added")
        .map((upload) => {
          let param: StartUploadParam = {
            uploadId: upload.uploadId,
            fileName: upload.file.name,
            fileMimeType: upload.file.type,
            fileSize: upload.file.size,
          };
          return param;
        });

      if (startUploadParams.length > 0) {
        const subscription: Subscription = this.filesApiService
          .recordStartUploads(startUploadParams)
          .subscribe((results: StartUploadResult[]) => {
            results.forEach((r: StartUploadResult) => {
              this.uploadService.setQueueUploaderMetadata(
                r.uploadId,
                { fileIdentifier: r.identifier },
                this.uploadxService.queue,
              );
              this.uploadUpload(r.uploadId);
            });
            subscription.unsubscribe();
          });
      }

      resolve(true);
    });
  }

  private handleCloudUploads() {
    return new Promise((resolve) => {
      this.cloudFiles.forEach((resource: ProviderItemModel) => {
        const subscription: Subscription = this.providerGatewayService
          .download(this.record.recordIdentifier, resource)
          .pipe(untilDestroyed(this))
          .subscribe((file: FileModel) => {
            this.files.push(file);
            this.tracker.trackEvent("Files", "Uploaded", MatomoEventEnum.PROVIDER_FILE_UPLOADED, file.size);

            if (this.uploadFinished) {
              this.handleUploadCompleted();
            }

            subscription.unsubscribe();
          });
      });
      return resolve(true);
    });
  }

  handleUploadCompleted() {
    this.isUploading = false;

    // Add attachments message
    let message = new RecordMessageModel();
    message.format = MessageFormatEnum.ENCRYPTED_HTML;
    message.body = this.encryptedMessage;
    message.attachments = this.files.map((file: FileModel) => {
      let attachment = new RecordMessageAttachmentModel();
      attachment.file = file;
      return attachment;
    });

    // Add message with attachments
    const subscription: Subscription = this.recordService
      .addRecordMessage(null, message, [ResponseLevelEnum.ALL])
      .pipe(
        switchMap((message: RecordMessageModel) => {
          let attachments: SignatureAttachmentModel[] = message.attachments.map(
            (attachment: RecordMessageAttachmentModel) => {
              let signatureAttachment = new SignatureAttachmentModel();
              signatureAttachment.type = SignatureAttachmentTypeEnum.SIGNABLE;
              signatureAttachment.attachmentIdentifier = attachment.attachmentIdentifier;

              return signatureAttachment;
            },
          );

          let signers = this.recipients.map((recipient: RecordRecipientModel) => recipient.identityIdentifier);

          return this.signatureAPIService
            .createSignature(
              this.record.recordIdentifier,
              message.messageIdentifier,
              SignatureMethodEnum.SMS,
              attachments,
              signers,
              [ResponseLevelEnum.MINIMIZE],
            )
            .pipe(
              tap((signature: SignatureModel) => {
                this.tracker.trackEvent("Signatures", "Created", MatomoEventEnum.SIGNATURE_CREATED, signers.length);
              }),
              map(() => message),
            );
        }),
        catchError((error) => {
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_ERROR,
            summary: this.translateService.instant("RECORD.record-signature.errors.unidentified.title"),
            detail: this.translateService.instant("RECORD.record-signature.errors.unidentified.subtitle"),
          });
          this.isSubmitting = false;
          return throwError(() => error);
        }),
        untilDestroyed(this),
      )
      .subscribe((message: RecordMessageModel) => {
        this.isSubmitting = false;
        this.messageService.add({
          severity: MessageSeverityEnum.SEVERITY_SUCCESS,
          summary: this.translateService.instant("RECORD.messages.signature-message-created.title"),
          detail: this.translateService.instant("TEMPLATES.messages.signature-message-created.detail"),
        });
        this.onSignatureMessageCreated.emit(message);
        subscription.unsubscribe();
      });
  }

  /**
   * Content
   */
  handleContentChanged(event: ContentChange) {}

  handleSelectionChanged(event: SelectionChange) {}

  handleSubmitHotkeys() {
    if (!this.formContentInvalid) {
      this.submitContent();
    }
  }

  submitContent() {
    if (this.formContentInvalid) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("RECORD.record-signature.errors.missing-document.title"),
          null,
        ),
      );
    } else {
      this.setStep("recipients");
    }
  }

  /**
   * Recipients step
   */
  handleRecipientsChanged(recipients: RecordRecipientModel[]) {
    this.recipients = recipients;
    this.form.get("recipients").patchValue(recipients);
  }

  submitRecipients() {
    this.isSubmitting = true;

    let messageBody = this.enableSignatureMessage
      ? (this.form.value as RecordSignatureMessageFormValue).messageBody
      : null;

    let haveRecipientsChanged = this.recipients.some((selectedRecipient) => {
      const { identityIdentifier } = selectedRecipient;
      const matchingRecipient = this.record.recipients.find(
        (recordRecipient) => recordRecipient.identityIdentifier === identityIdentifier,
      );
      return matchingRecipient !== selectedRecipient;
    });

    of({ messageBody, publicKey: this.publicKey })
      .pipe(
        mergeMap(({ messageBody, publicKey }) => {
          if (haveRecipientsChanged) {
            let selectedIdentifier = this.recipients.map((selectedRecipient) => selectedRecipient.identityIdentifier);
            let newRecipients = [
              ...this.record.recipients.filter(
                (recordRecipient) => !selectedIdentifier.includes(recordRecipient.identityIdentifier),
              ),
              ...this.recipients,
            ];
            let newRecord = Object.assign(new RecordModel(), this.record);
            newRecord.recipients = newRecipients;
            return this.recordAPIService
              .updateRecord(newRecord, [ResponseLevelEnum.MINIMIZE])
              .pipe(map(() => ({ messageBody, publicKey })));
          }
          return of({ messageBody, publicKey });
        }),
        mergeMap(({ messageBody, publicKey }) => {
          if (messageBody && messageBody.length > 0) {
            return this.cryptographyService.encryptText(publicKey, messageBody);
          } else {
            return of(messageBody);
          }
        }),
        untilDestroyed(this),
      )
      .subscribe((encryptedText: string) => {
        this.encryptedMessage = encryptedText;
        this.startUpload();
      });
  }

  cancelRecipients() {
    this.setStep("content");
  }

  cancel() {
    this.onCancel.emit();
  }

  ngOnDestroy() {
    this.uploadxService.disconnect();
  }
}
