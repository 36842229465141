import BaseModel from "@shared/models/base.model";

export default class BillingAccountUsageModel extends BaseModel {
  // MINIMIZE (default)
  public record: number | null = null;
  public recordBatch: number | null = null;
  public signature: number | null = null;
  public total: number | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
