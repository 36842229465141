<div [formGroup]="form" class="formgrid grid form-item__control__address" [id]="formItem.uniqueId">
  <div class="col-12 field">
    <label for="addressLine1">{{ "COMMON.fields.address-line-1.label" | translate: { "default": "Line 1" } }}</label>
    <input
      pInputText
      type="text"
      formControlName="addressLine1"
      id="addressLine1"
      class="w-full"
      [readonly]="readonly"
    />
  </div>
  <div class="col-12 field">
    <label class="flex flex-row gap-1" for="addressLine2">
      <span>{{ "COMMON.fields.address-line-2.label" | translate: { "default": "Line 2" } }}</span>
    </label>
    <input
      pInputText
      type="text"
      formControlName="addressLine2"
      id="addressLine2"
      class="w-full"
      [readonly]="readonly"
    />
  </div>
  <div class="col-12 field">
    <label class="flex flex-row gap-1" for="addressLine3">
      <span>{{ "COMMON.fields.address-line-3.label" | translate: { "default": "Line 3" } }}</span>
    </label>
    <input
      pInputText
      type="text"
      formControlName="addressLine2"
      id="addressLine3"
      class="w-full"
      [readonly]="readonly"
    />
  </div>
  <div class="col-12 md:col-6 field">
    <label for="addressCity">{{ "COMMON.fields.address-city.label" | translate: { "default": "City" } }}</label>
    <input
      pInputText
      type="text"
      formControlName="addressCity"
      id="addressCity"
      class="w-full"
      [readonly]="readonly"
      [ngClass]="{
        'ng-invalid ng-dirty': form.get('addressCity')?.invalid && form.get('addressCity')?.touched
      }"
    />

    <small [hidden]="form.get('addressCity')?.valid || form.get('addressCity')?.untouched" class="p-error">
      <span *ngIf="form.get('addressCity')?.errors?.required">{{
        "COMMON.fields.address-city.errors.required" | translate: { "default": "City is required" }
      }}</span>
    </small>
  </div>
  <div class="col-12 md:col-6 field">
    <label for="addressPostalCode">{{
      "COMMON.fields.address-zipcode.label" | translate: { "default": "ZipCode" }
    }}</label>
    <input
      pInputText
      type="text"
      formControlName="addressPostalCode"
      id="addressPostalCode"
      class="w-full"
      [readonly]="readonly"
      [ngClass]="{
        'ng-invalid ng-dirty': form.get('addressPostalCode')?.invalid && form.get('addressPostalCode')?.touched
      }"
    />

    <small [hidden]="form.get('addressPostalCode')?.valid || form.get('addressPostalCode')?.untouched" class="p-error">
      <span *ngIf="form.get('addressPostalCode')?.errors?.required">{{
        "COMMON.fields.address-zipcode.errors.required" | translate: { "default": "ZipCode is
          required" }
      }}</span>
    </small>
  </div>
  <div class="col-12 md:col-6 field">
    <label for="addressCountry">{{
      "COMMON.fields.address-country.label" | translate: { "default": "Country" }
    }}</label>
    <input
      pInputText
      type="text"
      formControlName="addressCountry"
      id="addressCountry"
      class="w-full"
      [readonly]="readonly"
      [ngClass]="{
        'ng-invalid ng-dirty': form.get('addressCountry')?.invalid && form.get('addressCountry')?.touched
      }"
    />
    <small [hidden]="form.get('addressCountry')?.valid || form.get('addressCountry')?.untouched" class="p-error">
      <span *ngIf="form.get('addressCountry')?.errors?.required">{{
        "COMMON.fields.address-country.errors.required" | translate: { "default": "Country is
          required" }
      }}</span>
    </small>
  </div>
  <div class="col-12 md:col-6 field">
    <label class="flex flex-row gap-1" for="addressState">
      <span>{{ "COMMON.fields.address-state.label" | translate: { "default": "State" } }}</span>
      <span class="text-gray-500 text-sm">{{ "COMMON.fields.optional" | translate: { "default": "(optional)" } }}</span>
    </label>
    <input
      pInputText
      type="text"
      formControlName="addressState"
      id="addressState"
      class="w-full"
      [readonly]="readonly"
    />
  </div>
</div>
