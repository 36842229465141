import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import SessionModel from "@app/@shared/models/session/session.model";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import UnitModel from "@shared/models/domain/unit.model";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "session-unit-card",
  templateUrl: "./unit-card.component.html",
  styleUrls: ["./unit-card.component.scss"],
})
/**
 * This component displays informations on the current user.
 */
export class UnitCardComponent implements OnInit, OnDestroy {
  @Input() unit: UnitModel;
  session$: BehaviorSubject<SessionModel> = new BehaviorSubject(undefined);

  @Input() userRole: string;

  constructor(public sessionAPIService: SessionAPIService, public authorizationService: AuthorizationService) {}

  ngOnInit(): void {
    this.sessionAPIService.session$.subscribe(this.session$);
  }
  ngOnDestroy() {}
}
