import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import UserModel from "@shared/models/user/user.model";
import IdentityModel from "@shared/models/user/identity.model";
import { UserRoleEnum } from "@shared/enums/user-role.enum";
import ConfigurationModel from "../models/masterdata/configuration.model";
import { ErrorCodeEnum } from "../enums/error-code.enum";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationScopeEnum } from "../enums/configuration-scope.enum";

@Injectable({
  providedIn: "root",
})
export class UserAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  updateUser(
    user: UserModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<UserModel> {
    const url = this._baseUrl + environment.services.methodUrls.user.updateUser;
    const correlationId = v4();

    this.loaderService.addOperation("updateUser");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          user: user,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new UserModel().deserialize(response["user"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateUser");
        }),
      );
  }

  updateUserUnit(
    unitIdentifier: string,
    userIdentifier?: string,
    userRole?: string,
    isFavorite?: boolean,
    isArchived?: boolean,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.user.updateUserUnit;
    const correlationId = v4();

    this.loaderService.addOperation("updateUserUnit");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          unitIdentifier,
          userIdentifier,
          userRole,
          isFavorite,
          isArchived,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateUserUnit");
        }),
      );
  }

  updateUserUnitIsFavorite(
    unitIdentifier: string,
    isFavorite: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    return this.updateUserUnit(unitIdentifier, undefined, undefined, isFavorite, undefined, responseLevels);
  }

  updateUserUnitIsArchived(
    unitIdentifier: string,
    isArchived: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    return this.updateUserUnit(unitIdentifier, undefined, undefined, undefined, isArchived, responseLevels);
  }

  updateUserUnitRole(
    unitIdentifier: string,
    userIdentifier?: string,
    userRole: string = UserRoleEnum.USER,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    return this.updateUserUnit(unitIdentifier, userIdentifier, userRole, undefined, undefined, responseLevels);
  }

  updateUserIdentity(
    userIdentifier: string,
    identity: IdentityModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<UserModel> {
    const url = this._baseUrl + environment.services.methodUrls.user.updateUserIdentity;
    const correlationId = v4();

    this.loaderService.addOperation("updateUserIdentity");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          userIdentifier: userIdentifier,
          identity: identity,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new UserModel().deserialize(response["user"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateUserIdentity");
        }),
      );
  }

  updateUserPassword(
    newPassword: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.user.updateUserPassword;
    const correlationId = v4();

    this.loaderService.addOperation("updateUserPassword");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          newPassword: newPassword,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateUserPassword");
        }),
      );
  }

  attachUserToUnit(
    user: UserModel,
    userRole: UserRoleEnum,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.user.attachUserToUnit.replace("{unitSlug}", environment.defaultUnit);
    const correlationId = v4();

    this.loaderService.addOperation("attachUserToUnit");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          user: user,
          userRole: userRole,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("attachUserToUnit");
        }),
      );
  }

  saveUserConfiguration(
    configuration?: ConfigurationModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ConfigurationModel> {
    const url = this._baseUrl + environment.services.methodUrls.user.saveUserConfiguration;
    const correlationId = v4();

    this.loaderService.addOperation("saveUserConfiguration");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          configuration,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new ConfigurationModel().deserialize(response["configuration"]);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error && error.error) {
            const { code } = error.error;
            if (code === ErrorCodeEnum.INBOX_CONFIGURATION_ERROR) {
              this.messageService.add(
                MessageHelper.createTextMessage(
                  MessageSeverityEnum.SEVERITY_ERROR,
                  this.translateService.instant("ERRORS.INBOX_CONFIGURATION_ERROR.title"),
                  error.error.message,
                ),
              );
            } else {
              this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
            }
          }

          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveUserConfiguration");
        }),
      );
  }

  getUserConfiguration(
    configClass?: string,
    scope?: ConfigurationScopeEnum,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ConfigurationModel> {
    const url = this._baseUrl + environment.services.methodUrls.user.getUserConfiguration;
    const correlationId = v4();

    this.loaderService.addOperation("getUserConfiguration");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          configClass,
          scope,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new ConfigurationModel().deserialize(response["configuration"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getUserConfiguration");
        }),
      );
  }
}
