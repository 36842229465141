import { Injectable } from "@angular/core";
import { FilesUploader } from "@app/@shared/uploaders/files-uploader";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { Metadata, Uploader, UploadxOptions, UploadxService } from "ngx-uploadx";
import { UploadTypeEnum } from "../enums/upload-type.enum";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  constructor(private uploadxService: UploadxService, private keycloakService: KeycloakService) {}

  getDefaultOptions(): UploadxOptions {
    return {
      endpoint: environment.services.baseUrls.filesApiUrl + environment.services.methodUrls.files.recordUploadChunk,
      token: () => this.keycloakService.getToken(),
      authorize: (request, token) => {
        request.headers["Authorization"] = `Bearer ${token}`;
        request.headers["application"] = environment.application;
        request.headers["correlationId"] = "";
        return request;
      },
      autoUpload: false,
      chunkSize: 10485760,
      // maxChunkSize: 10485760,
      concurrency: 4,
      storeIncompleteHours: 24,
      retryConfig: {
        maxAttempts: 30,
        maxDelay: 60_000,
        shouldRetry: (code, attempts) => {
          return code === 503 || ((code < 400 || code >= 500) && attempts < 5);
        },
      },
      uploaderClass: FilesUploader,
      metadata: {},
    };
  }

  getQueueUploaderMetadata(uploadId: string, queue?: Uploader[]): Metadata {
    if (!queue) queue = this.uploadxService.queue;
    return queue.find((uploader: Uploader) => uploader.uploadId == uploadId)?.metadata;
  }

  setQueueUploaderMetadata(uploadId: string, metadata: Metadata, queue?: Uploader[]) {
    if (!queue) queue = this.uploadxService.queue;
    let index = queue.findIndex((uploader: Uploader) => uploader.uploadId == uploadId);

    if (index > -1) {
      let existingMetadata = queue[index].metadata;
      queue[index].metadata = { ...existingMetadata, ...metadata };
    }
  }

  getUploadIdsByUploadType(type: UploadTypeEnum, queue?: Uploader[]): string[] {
    if (!queue) queue = this.uploadxService.queue;
    return queue
      .filter(
        (uploader: Uploader) =>
          uploader.metadata && uploader.metadata["type"] === type && uploader.status !== "cancelled",
      )
      .map((uploader: Uploader) => uploader.uploadId);
  }

  getUploadIdsByFormItemIdentifier(itemIdentifier: string, queue?: Uploader[]): string[] {
    if (!queue) queue = this.uploadxService.queue;
    return queue
      .filter(
        (uploader: Uploader) =>
          uploader.metadata &&
          uploader.metadata["itemIdentifier"] === itemIdentifier &&
          uploader.status !== "cancelled",
      )
      .map((uploader: Uploader) => uploader.uploadId);
  }
}
