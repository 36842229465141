import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@core";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthorizationService } from "@shared/services/authorization.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import { BehaviorSubject, Observable, filter, map, mergeMap, switchMap, tap } from "rxjs";
import IdentityModel from "@app/@shared/models/user/identity.model";
import { environment } from "@env/environment";
import { MenuItem, MessageService } from "primeng/api";
import { Router } from "@angular/router";
import UnitModel from "@app/@shared/models/domain/unit.model";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { UserRoleEnum } from "@app/@shared/enums/user-role.enum";
import { BillingService } from "@app/billing/services/billing.service";
import { KeycloakInitService } from "@app/@shared/services/keycloak-init.service";
import { NavigationBarVariantEnum } from "@app/@shared/enums/navigation-bar-variant.enum";

type MenuItemLabelType = {
  title: string;
  subtitle: string;
  description: string;
  image: string;
  badge?: string;
};

@UntilDestroy()
@Component({
  selector: "main-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  displayNavbar$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  navigationBarVariant$: BehaviorSubject<NavigationBarVariantEnum> = new BehaviorSubject(
    NavigationBarVariantEnum.PRIMARY,
  );

  recordMenuItems: MenuItem[] = [];
  inlineMenuItems = [];
  hideBottomBar$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  showCreateMenu: boolean = false;
  showInlineMenu: boolean = false;

  showStartCampaignInOtherUnit: boolean = false;

  notificationSubscriberId$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  displayBackToInbox$: Observable<boolean>;
  NavigationBarVariantEnum = NavigationBarVariantEnum;

  constructor(
    public sessionAPIService: SessionAPIService,
    private translateService: TranslateService,
    public authorizationService: AuthorizationService,
    private keycloakInitService: KeycloakInitService,
    public themingService: ThemingService,
    private router: Router,
    private messageService: MessageService,
    private billingService: BillingService,
  ) {
    this.sessionAPIService.currentIdentity$
      .pipe(
        filter((identity: IdentityModel) => Boolean(identity)),
        map((identity: IdentityModel) => identity.identityIdentifier),
      )
      .subscribe(this.notificationSubscriberId$);

    this.displayBackToInbox$ = this.billingService.billingAccount$.pipe(
      map((billingAccount) => Boolean(billingAccount)),
      untilDestroyed(this),
    );
  }

  ngOnInit(): void {
    // Check oAuth
    this.keycloakInitService.hasKeycloakInitError$
      .pipe(filter((hasError) => hasError !== undefined))
      .subscribe(async (hasError: boolean) => {
        if (hasError) {
          this.displayNavbar$.next(false);
        }
      });
    this.themingService.hideBottomMenubar$.pipe(untilDestroyed(this)).subscribe(this.hideBottomBar$);
    this.themingService.navigationBarVariant$.pipe(untilDestroyed(this)).subscribe(this.navigationBarVariant$);
    this.authorizationService.initialized$
      .pipe(
        filter((initialized) => Boolean(initialized)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.setItems();
      });
  }

  setItems() {
    this.recordMenuItems = [
      {
        label: this.setItemLabel({
          title: this.translateService.instant("SIDEBAR.menu.standard.title", { default: "Commencez à échanger !" }),
          subtitle: this.translateService.instant("SIDEBAR.menu.standard.subtitle", {
            default:
              "Envoyez vos documents, Demandez des informations à vos contacts, faites signez un document. Tout commence ici.",
          }),
          description: this.translateService.instant("SIDEBAR.menu.standard.description", {
            default: "Ajoutez jusqu’à 10 destinataires dans chaque échange.",
          }),
          image: `<i class="pi pi-comment"></i>`,
          badge: null,
        }),
        escape: false,
        routerLink: "/record/new",
        tooltip: this.translateService.instant("SIDEBAR.menu.standard.tooltip", {
          default: "Standard",
        }),
        visible: this.authorizationService.canAccessRecordsStandard(),
      },
      {
        label: this.setItemLabel({
          title: this.translateService.instant("SIDEBAR.menu.campaign.title", { default: "Mode campagne" }),
          subtitle: this.translateService.instant("SIDEBAR.menu.campaign.subtitle", {
            default:
              "Besoin de gérer des centaines ou des milliers de dossiers clients et des collectes à grande échelle ? Par ici.",
          }),
          description: this.translateService.instant("SIDEBAR.menu.campaign.description", {
            default: "Créez des échanges isolés avec chacun de vos interlocuteurs.",
          }),
          image: `<i class="pi pi-comments"></i>`,
          badge: this.authorizationService.canAccessRecordsBatch()
            ? null
            : this.translateService.instant("BILLING.offers.entreprise.name", { default: "Business" }),
        }),
        escape: false,
        command: () => {
          this.authorizationService.canAccessRecordsBatch()
            ? this.sessionAPIService.unit.isPublic
              ? this.startCampaignInPrivateUnit()
              : this.router.navigate(["/record-batch/new"])
            : this.router.navigate(["/billing/offers"]);
        },
        tooltip: this.translateService.instant("SIDEBAR.menu.campaign.tooltip", {
          default: "campaign",
        }),
      },
    ];

    this.inlineMenuItems = [
      {
        label: this.translateService.instant("SESSION.user-menu.transfers.summary.label", {
          default: "Summary",
        }),
        routerLink: "/inbox",
        icon: "pi pi-comment",
        visible: this.canViewPeripheralElements(),
      },
      {
        label: this.translateService.instant("SESSION.user-menu.campaign.summary.label", {
          default: "Campaign",
        }),
        routerLink: "/record-batch",
        queryParams: this.authorizationService.canAccessRecordsBatch() ? { type: "ongoing" } : null,
        icon: "pi pi-comments",
        badge: this.authorizationService.canAccessRecordsBatch()
          ? ""
          : this.translateService.instant("BILLING.offers.entreprise.name", { default: "Business" }),
        visible: this.canViewPeripheralElements(),
      },
      {
        label: this.translateService.instant("SESSION.user-menu.address-book.contacts.label", {
          default: "Contacts",
        }),
        routerLink: "/contacts",
        icon: "pi pi-users",
        visible: this.canViewPeripheralElements(),
      },
      {
        label: this.translateService.instant("SESSION.user-menu.templates.label", {
          default: "Templates",
        }),
        routerLink: "/templates",
        icon: "pi pi-th-large",
        visible: this.canViewPeripheralElements(),
      },
    ];

    this.showCreateMenu =
      this.recordMenuItems.filter((item) => {
        return item.visible == true;
      }).length > 0 && this.authorizationService.canCreateRecord();

    this.showInlineMenu =
      this.inlineMenuItems.filter((item) => {
        return item.visible == true;
      }).length > 0;
  }
  startCampaignInPrivateUnit() {
    this.showStartCampaignInOtherUnit = true;
  }
  selectUnit(unit: UnitModel): void {
    this.sessionAPIService
      .selectUnit(unit)
      .pipe(untilDestroyed(this))
      .subscribe((session) => {
        this.router.navigate(["/record-batch/new"]);

        this.messageService.add({
          severity: MessageSeverityEnum.SEVERITY_SUCCESS,
          summary: this.translateService.instant("UNIT.messages.unit-changed.title"),
          detail: this.translateService.instant("UNIT.messages.unit-changed.detail", { unit: session.unit.name }),
        });
      });
  }

  // Navigation
  canViewPeripheralElements() {
    return this.sessionAPIService.unit.isPublic
      ? !this.authorizationService.isGuest()
      : !this.authorizationService.isUnitGuest();
  }
  handleCreateUnit() {
    this.showStartCampaignInOtherUnit = false;
    this.router.navigate(["units/create-root"]);
  }
  // Unit where campaign might be created
  get campaignCapableUnits() {
    return this.sessionAPIService.currentUser.units.filter(
      (userUnit) => !userUnit.unit.isPublic && userUnit.userRole !== UserRoleEnum.GUEST,
    );
  }
  /**
   * Generate a customized menu item
   * @param menuItem
   * @returns An HTML string for the menu label
   * See : /header.component.html
   */
  setItemLabel(menuItem: MenuItemLabelType): string {
    const template = document.getElementById("menu-label-template") as HTMLTemplateElement;
    const importedNode = document.importNode(template, true) as HTMLTemplateElement;
    const menuLabel = importedNode.content.querySelector(".menu-item").cloneNode(true) as HTMLElement;
    for (const [key, value] of Object.entries(menuItem)) {
      if (value) {
        menuLabel.querySelector(`.${key}`).innerHTML = value;
      } else {
        let element = menuLabel.querySelector(`.${key}`);
        if (element) element.remove();
      }
    }
    return menuLabel.outerHTML.toString();
  }
}
