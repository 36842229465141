import { Component, Input, OnInit } from "@angular/core";
import UserModel from "@shared/models/user/user.model";
import { AvailbleBSPositions } from "ngx-bootstrap/positioning";

@Component({
  selector: "ui-user-avatar",
  templateUrl: "./user-avatar.component.html",
  styleUrls: ["./user-avatar.component.scss"],
})
export class UserAvatarComponent implements OnInit {
  @Input() user: UserModel;

  @Input() size: number = 32;
  @Input() showTooltip: boolean = false;
  @Input() tooltipPlacement: AvailbleBSPositions = "top";

  constructor() {}

  ngOnInit(): void {}
}
