import { Component, Input, OnInit } from "@angular/core";
import UnitModel from "@shared/models/domain/unit.model";
import { AvailbleBSPositions } from "ngx-bootstrap/positioning";

@Component({
  selector: "ui-unit-avatar",
  templateUrl: "./unit-avatar.component.html",
  styleUrls: ["./unit-avatar.component.scss"],
})
export class UnitAvatarComponent implements OnInit {
  @Input() unit: UnitModel;

  @Input() size: string;
  @Input() showTooltip: boolean = false;
  @Input() tooltipPlacement: AvailbleBSPositions = "top";

  constructor() {}

  ngOnInit(): void {}
}
