<p-button
  type="button"
  styleClass="notification p-button-text p-button-sm"
  [ngClass]="{ 'notification--unseen': !message.seen, 'notification--unread': !message.read }"
  (click)="handleClick($event)"
>
  <ng-template pTemplate="content">
    <div class="notification__image">
      <img
        *ngIf="message?.unit?.['avatar']; else unitImageFallback"
        [src]="message?.unit?.['avatar']"
        [alt]="message?.unit?.['name']"
      />
      <ng-template #unitImageFallback>
        <img src="/assets/images/favicon.svg" alt="" />
      </ng-template>
    </div>
    <div class="notification__content" [innerHtml]="message.content | safeHtml"></div>
    <div class="notification__footer">
      <small
        class="clamp"
        [style]="{ 'max-width': '20ch' }"
        *ngIf="message?.unit?.['name']"
        >{{message?.unit?.['name']}}</small
      >
      <small class="ml-auto">{{ message.createdAt | timeAgo | async }}</small>
    </div>
  </ng-template>
</p-button>
