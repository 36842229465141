import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService } from "@app/@core/loader";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { ResponseLevelEnum } from "../../enums/response-level.enum";
import { v4 } from "uuid";
import { AuthorizationTypesEnum } from "../../enums/authorization-types.enum";
import { ContentTypesEnum } from "../../enums/content-types.enum";
import { HttpHeadersEnum } from "../../enums/http-headers.enum";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { MessageSeverityEnum } from "../../enums/message-severity.enum";
import { MessageHelper } from "../../helpers/message.helper";
import ProviderItemModel from "../../models/providers/provider-item.model";
import { ProviderRootTypeEnum } from "../../enums/provider-root-type.enum";
import ErrorModel from "../../models/error.model";
import FileModel from "../../models/file/file.model";
import { Logger } from "@app/@core";
import { CloudAPIService } from "../cloud-api.service";
import { ProviderFilesService } from "./provider-files.service";
import { ProviderItemTypeEnum } from "@app/@shared/enums/provider-item-type.enum";

const log = new Logger("ProviderGatewayService");

@Injectable({
  providedIn: "root",
})
export class ProviderGatewayService {
  constructor(private cloudAPIService: CloudAPIService, private providerFilesService: ProviderFilesService) {}

  /**
   * Gateway function for upload to Nestor Drive OR Provider
   */
  upload(fileIdentifier: string, recordIdentifier: string, item: ProviderItemModel): Observable<boolean> {
    if (item.type === ProviderItemTypeEnum.NESTOR_DRIVE_ITEM) {
      return this.cloudAPIService.uploadInternal(fileIdentifier, recordIdentifier, item.path);
    } else {
      return this.providerFilesService.upload({
        applicationIdentifier: item.applicationIdentifier,
        driveId: item.rootId,
        fileIdentifier,
        recordIdentifier,
        itemId: item.id,
      });
    }
  }

  /**
   * Gateway function for download from Nestor Drive OR Provider
   */
  download(recordIdentifier: string, item: ProviderItemModel): Observable<FileModel> {
    if (item.type === ProviderItemTypeEnum.NESTOR_DRIVE_ITEM) {
      return this.cloudAPIService.downloadInternal(item.path, recordIdentifier);
    } else {
      return this.providerFilesService.retrieve({
        applicationIdentifier: item.applicationIdentifier,
        recordIdentifier,
        driveId: item.rootId,
        itemId: item.id,
      });
    }
  }
}
