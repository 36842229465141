import { Pipe, PipeTransform } from "@angular/core";
import UnitModel from "@shared/models/domain/unit.model";

@Pipe({
  name: "getUnitInitials",
})
export class GetUnitInitialsPipe implements PipeTransform {
  transform(unit: UnitModel) {
    if (unit?.name) {
      return unit.name[0].toUpperCase();
    } else {
      return "NA";
    }
  }
}
