import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import FormDataModel from "@app/@shared/models/form-data.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { v4 as v4 } from "uuid";

@Component({
  selector: "record-form-item-format-rating",
  templateUrl: "./item-format-rating.component.html",
  styleUrls: ["./item-format-rating.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatRatingComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatRatingComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Rating options
  @Input() stars: number = FormDataModel.DEFAULT.rating;
  @Input() cancel: boolean = false;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(private formBuilder: UntypedFormBuilder, private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    if (this.formItem.getFormatters()) {
      // rating stars formatter
      let ratingStarsFormatter = this.formItem.getFormatter(FormDataFormatterTypeEnum.RATING_STARS);
      if (ratingStarsFormatter && ratingStarsFormatter.value) {
        let ratingStars = Number.parseFloat(ratingStarsFormatter.value.toString());
        this.stars = ratingStars;
      }
    }
  }

  handleChange(event) {
    if (event) {
      this.onModelTouched();
      this.onModelChange(event.value);
      this.writeValue(event.value);
      this.onChange.emit(event.value);
    }
  }
}
