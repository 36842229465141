import { Component, Input, OnInit } from "@angular/core";
import ContactModel from "@app/@shared/models/contacts/contact.model";

@Component({
  selector: "contacts-contact-card",
  templateUrl: "./contact-card.component.html",
  styleUrls: ["./contact-card.component.scss"],
})
export class ContactCardComponent implements OnInit {
  @Input() contact: ContactModel;
  @Input() showEmail: boolean = true;
  @Input() showPhone: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
