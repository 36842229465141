import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";

type CTA = {
  data?: {
    url?: string;
  };
};

export class NotificationMessageModel extends BaseModel {
  id: string | null = null;
  createdAt: Date | null = null;
  content: string | null = null;
  seen: boolean = false;
  read: boolean = false;
  title: string | null = null;
  subject: string | null = null;
  payload: JSONObject = {};
  cta: CTA = {};
  unit: JSONObject | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    return this;
  }

  get ctaUrl() {
    return this.cta?.data?.url;
  }
}
