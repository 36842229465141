<p-table
  [value]="contacts"
  [(selection)]="selectedContacts"
  selectionMode="multiple"
  dataKey="id"
  [metaKeySelection]="false"
  responsiveLayout="stack"
  [loading]="isLoadingContacts"
  [rowHover]="false"
  (onLazyLoad)="loadContactsLazy($event)"
  [paginator]="total > 0"
  [rows]="10"
  [totalRecords]="total"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="paginationTemplate"
  [rowsPerPageOptions]="[10, 25, 50]"
  #providerContactTable
  styleClass="p-datatable-sm contactsTable p-3"
>
  <ng-template pTemplate="header">
    <tr *ngIf="total > 0">
      <th style="width: 2rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of cols" [pSortableColumn]="col.field">
        <div>
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contact>
    <tr class="py-1 sm:py-0">
      <td class="hidden sm:py-2 md:flex lg:py-3">
        <p-tableCheckbox [value]="contact"></p-tableCheckbox>
      </td>
      <td
        class="py-1 sm:py-2 lg:py-3 prefix-label"
        [attr.data-label]="col.header"
        *ngFor="let col of cols"
        [ngClass]="col.classnames"
      >
        {{ contact[col.field] }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <div class="surface-card border-round flex flex-column align-items-center py-4 lg:py-8 justify-content-center">
      <i class="pi pi-users text-4xl"></i>
      <h3 class="mb-0 px-3 text-center">
        {{ "MAIN.provider-contacts.no-contacts" | translate : { default: "No contact" } }}
      </h3>
    </div>
  </ng-template>
</p-table>
