<!-- 
  Readonly calendar for viewing reminder dates
 -->
<div>
  <span class="flex flex-wrap font-medium gap-1 pb-2">
    {{ "RECORD.record-reminders-calendar.label" | translate: { "default": "Reminders calendar" } }}
  </span>
  <p-calendar
    [showOtherMonths]="false"
    [readonlyInput]="true"
    [ngModel]="recordReminderProperties.dates"
    [ngModelOptions]="{ standalone: true }"
    (onSelect)="(null)"
    [inline]="true"
    selectionMode="multiple"
    [minDate]="reminderMinDate"
    [maxDate]="reminderMaxDate"
  >
    <ng-template pTemplate="date" let-date>
      <span [ngStyle]="{ pointerEvents: 'none' }">{{ date.day }}</span>
    </ng-template>
  </p-calendar>
  <aside class="flex flex-wrap gap-1 py-2">
    <span class="inline-flex align-items-center gap-1">
      <span class="reminder-active"></span>
      <span>
        {{ "RECORD.record-reminders-calendar.legend.enabled" | translate: { "default": "Reminder enabled" } }}
      </span>
    </span>
  </aside>
</div>
