import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService } from "@app/@core/loader";
import { Logger } from "@core";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { catchError, finalize, map, Observable, throwError } from "rxjs";
import { v4 as v4 } from "uuid";
import { ContactScopeEnum } from "../enums/contact-scope.enum";
import { AuthorizationTypesEnum } from "../enums/authorization-types.enum";
import { ContentTypesEnum } from "../enums/content-types.enum";
import { HttpHeadersEnum } from "../enums/http-headers.enum";
import { MessageSeverityEnum } from "../enums/message-severity.enum";
import { ResponseLevelEnum } from "../enums/response-level.enum";
import { MessageHelper } from "../helpers/message.helper";
import ContactModel from "../models/contacts/contact.model";
import ContactsResultModel from "../models/contacts/search-contacts-result.model";
import { ContactOrderEnum } from "../enums/contact-order.enum";

const log = new Logger("AddressBookAPIService");

@Injectable({
  providedIn: "root",
})
export class AddressBookAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private loaderService: LoaderService,
    private httpService: HttpClient,
    private messageService: MessageService,
    private keycloakService: KeycloakService,
  ) {}

  deleteContacts(
    params: {
      contacts: ContactModel[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.addressBook.deleteContacts;
    const correlationId = v4();

    this.loaderService.addOperation("deleteContacts");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("deleteContacts");
        }),
      );
  }
  getContact(
    params: {
      contactIdentifier: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ContactModel> {
    const url = this._baseUrl + environment.services.methodUrls.addressBook.getContact;
    const correlationId = v4();

    this.loaderService.addOperation("getContact");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          // return response["done"];
          return new ContactModel().deserialize(response.contact);
        }),
        catchError((e) => {
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getContact");
        }),
      );
  }

  searchContacts(
    params: {
      emailAddress?: string;
      searchText?: string;
      scope?: ContactScopeEnum;
      orderBy?: ContactOrderEnum;
      tags?: string[];
    },
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ContactsResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.addressBook.searchContacts;
    const correlationId = v4();

    this.loaderService.addOperation("searchContacts");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset,
            limitCount,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result = new ContactsResultModel();
          result.contacts = response["contacts"].map((contact: any) => new ContactModel().deserialize(contact));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("searchContacts");
        }),
      );
  }

  saveContacts(
    params: {
      contacts: ContactModel[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ContactsResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.addressBook.saveContacts;
    const correlationId = v4();

    this.loaderService.addOperation("saveContacts");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result = new ContactsResultModel();
          result.contacts = response["contacts"].map((contact: any) => new ContactModel().deserialize(contact));
          result.addPagination(response);
          return result;
        }),
        catchError((e) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, e));
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveContacts");
        }),
      );
  }

  getTags(
    params: {
      scope: ContactScopeEnum;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<{ label: string; count: number }[]> {
    const url = this._baseUrl + environment.services.methodUrls.addressBook.getTags;
    const correlationId = v4();

    this.loaderService.addOperation("getTags");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["tags"];
        }),
        catchError((e) => {
          return throwError(() => e);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getTags");
        }),
      );
  }
}
