import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Sources } from "quill";
import Delta from "quill-delta";
import { ContentChange, QuillEditorComponent, SelectionChange } from "ngx-quill";
import { OverlayPanel } from "primeng/overlaypanel";
import { EmojiData, EmojiEvent } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { EmojiSearch } from "@ctrl/ngx-emoji-mart";
import { isEmpty } from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject } from "rxjs";

export type EditorFormItemClickEvent = {
  dataset: DOMStringMap;
  blotIndex: number;
};

type MentionItem = {
  type: "emoji";
};

type EmojiMentionItem = MentionItem & {
  id: string;
  colon: string;
};

@UntilDestroy()
@Component({
  selector: "editor-message-editor",
  templateUrl: "./message-editor.component.html",
  styleUrls: ["./message-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MessageEditorComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class MessageEditorComponent implements OnInit, ControlValueAccessor {
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
  @ViewChild(OverlayPanel, { static: true }) emojiPanel: OverlayPanel;

  @Input() classList: string;
  @Input() placeholder: string;
  @Input() readonly: boolean = false;
  @Input() style: any;

  /**
   * Quill related inputs
   */
  @Input() modules: any = {};

  @Output() onInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onContentChanged = new EventEmitter<ContentChange>();
  @Output() onSelectionChanged: EventEmitter<SelectionChange> = new EventEmitter<SelectionChange>();
  @Output() onSubmitHotkeys: EventEmitter<any> = new EventEmitter<any>();

  emojiTranslations$: BehaviorSubject<any> = new BehaviorSubject({});
  editorModules: any = {};
  isFocused: boolean = false;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};
  searchTerm: string = "";

  EMOJI_MENTION_CHARS = [":"];

  mentionsConfig = {
    allowedChars: /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/,
    mentionDenotationChars: ["#", "@", "/", ":"],
    positioningStrategy: "normal",
    onSelect: (item: DOMStringMap, insertItem) => {
      if (this.EMOJI_MENTION_CHARS.includes(item.denotationChar)) {
        this.onEmojiSelect(item, insertItem);
      }
    },
    renderItem: (item: EmojiMentionItem, searchTerm: string) => {
      if (item.type === "emoji") {
        return this.renderEmojiItem(item as EmojiMentionItem, searchTerm);
      }

      return null;
    },
    source: async (searchTerm: string, renderList, mentionChar: string) => {
      if (this.EMOJI_MENTION_CHARS.includes(mentionChar) && !isEmpty(searchTerm)) {
        this.searchTerm = searchTerm;
        const emojis = await this.emojiSearch.search(searchTerm).map((data: EmojiData) => ({
          id: data.native,
          colon: data.colons,
          type: "emoji",
        }));
        renderList(emojis, this.searchTerm);
      }
    },
  };

  constructor(private translateService: TranslateService, private emojiSearch: EmojiSearch) {
    this.translateService
      .get("EMOJIS")
      .pipe(untilDestroyed(this))
      .subscribe((result: any) => {
        this.emojiTranslations$.next({
          search: result.search(),
          emojilist: result.emojilist(),
          notfound: result.notfound(),
          clear: result.clear(),
          categories: {
            search: result.categories.search(),
            recent: result.categories.recent(),
            people: result.categories.people(),
            nature: result.categories.nature(),
            foods: result.categories.foods(),
            activity: result.categories.activity(),
            places: result.categories.places(),
            objects: result.categories.objects(),
            symbols: result.categories.symbols(),
            flags: result.categories.flags(),
            custom: result.categories.custom(),
          },
          skintones: {
            "1": result.skintones["1"](),
            "2": result.skintones["2"](),
            "3": result.skintones["3"](),
            "4": result.skintones["4"](),
            "5": result.skintones["5"](),
            "6": result.skintones["6"](),
          },
        });
      });
  }

  ngOnInit(): void {
    this.initModules();
  }

  writeValue(value: any): void {
    this.editor.writeValue(value);
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  initModules() {
    this.editorModules = {
      ...this.modules,
      magicUrl: true,
      mention: this.mentionsConfig,
    };
  }

  handleEditorCreated() {
    // Adding a matcher that removes form-item from delta ops when being pasted into editor
    this.editor.quillEditor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
      const ops = delta.ops.map((op) => {
        if (op.insert && op.insert["form-item"]) {
          return { insert: op.insert["form-item"].text };
        }
        return op;
      });
      return new Delta(ops);
    });
  }

  handleContentChanged(event: ContentChange) {
    this.onModelTouched();
    this.onModelChange(event.html);
    this.onContentChanged.emit(event);
  }

  handleSelectionChanged(event: SelectionChange) {
    this.onSelectionChanged.emit(event);
  }

  handleEmojiToolbarClick(event: Event) {
    if (!this.readonly && this.emojiPanel) {
      this.emojiPanel.toggle(event);
    }
  }

  handleAddEmoji(event: EmojiEvent) {
    if (!this.readonly) {
      const range = this.editor.quillEditor.getSelection(true);
      this.editor.quillEditor.insertText(range.index, event.emoji.native, "api");
      this.emojiPanel.toggle(event);
    }
  }

  @HostListener("keydown", ["$event"])
  handleKeydown(event: KeyboardEvent) {
    // Bind ctrl + ENTER / CMD + Enter to submit
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter" && !this.readonly) {
      this.onSubmitHotkeys.emit();
    }
  }

  @HostListener("click", ["$event"])
  handleClick(event) {
    this.setIsFocused(true);
  }

  handleFocus(event) {
    this.setIsFocused(true);
  }

  handleBlur(event) {
    this.setIsFocused(false);
  }

  setIsFocused(value: boolean) {
    if (value && !this.editor.quillEditor.hasFocus()) {
      this.editor.quillEditor.focus();
    }
    this.isFocused = value;
  }

  /**
   * API for quill editor (proxy)
   */
  setSelection(index: number, length: number, source?: Sources) {
    setTimeout(() => this.editor?.quillEditor?.setSelection(index, length, source), 0);
  }

  insertText(index: number, text: string, source?: Sources) {
    this.editor?.quillEditor?.insertText(index, text, source);
  }

  /**
   * Emojis handling
   */
  onEmojiSelect(item: DOMStringMap, insertItem) {
    const range = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.deleteText(range.index - this.searchTerm.length - 1, this.searchTerm.length + 1, "api"); // Take mention char into account when removing text
    this.editor.quillEditor.insertText(range.index - this.searchTerm.length - 1, item.id, "api");
    this.searchTerm = "";
  }

  renderEmojiItem(item: EmojiMentionItem, searchTerm: string) {
    return (
      `<div class="flex w-full gap-3 align-items-center">` +
      `<span>${item.id}</span>` +
      `<div class="flex flex-column gap-1">` +
      `<span class="font-semibold">${item.colon}</span>` +
      `</div>` +
      `</div>`
    );
  }
}
