<p-inputNumber
  [name]="name"
  [id]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  [min]="min"
  [max]="max"
  [step]="step"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  [format]="format"
  [mode]="mode"
  [prefix]="prefix"
  [suffix]="suffix"
  [locale]="locale"
  [currency]="currency"
  [currencyDisplay]="currencyDisplay"
  [minFractionDigits]="minFractionDigits"
  [maxFractionDigits]="maxFractionDigits"
  [useGrouping]="true"
  [allowEmpty]="allowEmpty"
></p-inputNumber>
