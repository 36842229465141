import BaseModel from "../base.model";

export default class ProviderContactFolderModel extends BaseModel {
  public id: string | null = null;
  public name: string | null = null;
  public parentFolder: string | null = null;

  constructor() {
    super();
  }
}
