import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import BillingAccountModel from "../models/billing/billing-account.model";
import BillingProductModel from "../models/billing/product.model";
import CouponModel from "../models/billing/coupon.model";
import PromotionCodeModel from "../models/billing/promotion-code.model";
import BillingInvoiceModel from "../models/billing/invoice.model";
import BillingAccountUsageModel from "../models/billing/billing-account-usage.model";
import { SubscriptionCancelDelayEnum } from "../enums/billing/subscription-cancel-delay.enum";
import { BillingPaymentMethodTypeEnum } from "../enums/billing/payment-method-type.enum";
import SetupIntentModel from "../models/billing/setup-intent.model";

@Injectable({
  providedIn: "root",
})
export class BillingAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getProducts(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE]): Observable<BillingProductModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.billing.getProducts;
    const correlationId = v4();

    this.loaderService.addOperation("getProducts");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["products"].map((product: any) => new BillingProductModel().deserialize(product));
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getProducts");
        }),
      );
  }

  getBillingAccount(
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.getBillingAccount;
    const correlationId = v4();

    this.loaderService.addOperation("getBillingAccount");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (response["billingAccount"]) {
            return new BillingAccountModel().deserialize(response["billingAccount"]);
          }

          return null;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getBillingAccount");
        }),
      );
  }

  getBillingAccountUsage(
    fromDate?: Date,
    toDate?: Date,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountUsageModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.getBillingAccountUsage;
    const correlationId = v4();

    this.loaderService.addOperation("getBillingAccountUsage");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          fromDate,
          toDate,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (response["usage"]) {
            return new BillingAccountUsageModel().deserialize(response["usage"]);
          }

          return null;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getBillingAccountUsage");
        }),
      );
  }

  saveBillingAccount(
    billingAccount: BillingAccountModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.saveBillingAccount;
    const correlationId = v4();

    this.loaderService.addOperation("saveBillingAccount");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          billingAccount,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new BillingAccountModel().deserialize(response["billingAccount"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveBillingAccount");
        }),
      );
  }

  createSubscription(
    stripeProductId: string,
    stripePromotionCodeId: string = undefined,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.createSubscription;
    const correlationId = v4();

    this.loaderService.addOperation("createSubscription");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          stripeProductId,
          stripePromotionCodeId,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new BillingAccountModel().deserialize(response["billingAccount"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("createSubscription");
        }),
      );
  }

  updateSubscription(
    stripeProductId: string,
    stripePromotionCodeId: string = undefined,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.updateSubscription;
    const correlationId = v4();

    this.loaderService.addOperation("updateSubscription");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          stripeProductId,
          stripePromotionCodeId,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new BillingAccountModel().deserialize(response["billingAccount"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("updateSubscription");
        }),
      );
  }

  cancelSubscription(
    cancelDelay: SubscriptionCancelDelayEnum = SubscriptionCancelDelayEnum.IMMEDIATELY,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.cancelSubscription;
    const correlationId = v4();

    this.loaderService.addOperation("cancelSubscription");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          cancelDelay,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new BillingAccountModel().deserialize(response["billingAccount"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("cancelSubscription");
        }),
      );
  }

  createSetupIntent(
    paymentMethodTypes: BillingPaymentMethodTypeEnum[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<SetupIntentModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.createSetupIntent;
    const correlationId = v4();

    this.loaderService.addOperation("createSetupIntent");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          paymentMethodTypes,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new SetupIntentModel().deserialize(response["setupIntent"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("createSetupIntent");
        }),
      );
  }

  detachPaymentMethod(
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<BillingAccountModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.detachPaymentMethod;
    const correlationId = v4();

    this.loaderService.addOperation("detachPaymentMethod");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new BillingAccountModel().deserialize(response["billingAccount"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("detachPaymentMethod");
        }),
      );
  }

  getPromotionCode(
    code: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<PromotionCodeModel> {
    const url = this._baseUrl + environment.services.methodUrls.billing.getPromotionCode;
    const correlationId = v4();

    this.loaderService.addOperation("getPromotionCode");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          code,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (response["promotionCode"]) {
            return new PromotionCodeModel().deserialize(response["promotionCode"]);
          }

          return null;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getPromotionCode");
        }),
      );
  }

  getCustomerInvoices(
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<BillingInvoiceModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.billing.getCustomerInvoices;
    const correlationId = v4();

    this.loaderService.addOperation("getCustomerInvoices");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (response["invoices"]) {
            return response["invoices"].map((i) => new BillingInvoiceModel().deserialize(i));
          }

          return null;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getCustomerInvoices");
        }),
      );
  }
}
