import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { ChannelEnum } from "@app/@shared/enums/channel.enum";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { SharedValidators } from "@app/@shared/validators";
import { TranslateService } from "@ngx-translate/core";
import { CountryISO } from "ngx-intl-tel-input-gg";
import { ParsedPhoneNumber, PhoneHelper } from "@app/@shared/helpers/phone.helper";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ThemingService } from "@app/@shared/services/theming.service";

type ContactFormValue = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: { [x: string]: string };
  defaultChannel: ChannelEnum;
  scope: ContactScopeEnum;
  contactIdentifier: string;
  tags: string[];
};

// Taken from docs https://primeng.org/autocomplete#api.autocomplete.events.AutoCompleteCompleteEvent
type AutoCompleteCompleteEvent = {
  originalEvent: Event;
  query: string;
};

@UntilDestroy()
@Component({
  selector: "contacts-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.scss"],
})
export class ContactFormComponent implements OnInit, OnChanges {
  @Input() allowOnlyEmail: boolean = true;
  @Input() allowSelf: boolean = true;
  @Input() contact: ContactModel = new ContactModel();
  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;
  @Input() isUpdatingExisting: boolean = false;

  @Output() onCreateContact: EventEmitter<ContactModel> = new EventEmitter<ContactModel>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  phoneCountryISO: CountryISO = CountryISO.France;
  @Input() suggestions: string[] = [];
  filteredTags: string[] = [];
  scopeItems: JsonArray;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sessionAPIService: SessionAPIService,
    private translateService: TranslateService,
    public themingService: ThemingService,
  ) {}

  ngOnInit() {
    this.buildform();
    this.onTagChanges();
    this.scopeItems = this.getScopeItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contact) {
      this.buildform();
    }
  }

  ngOnDestroy() {}

  private buildform() {
    let parsedPhoneNumber: ParsedPhoneNumber = null;
    if (this.contact.phoneNumber) {
      try {
        parsedPhoneNumber = PhoneHelper.parseInternationalPhoneNumber(this.contact.phoneNumber);
        if (parsedPhoneNumber.countryISO) this.phoneCountryISO = parsedPhoneNumber.countryISO;
      } catch (error) {}
    }

    if (this.allowOnlyEmail) {
      this.form = this.formBuilder.group({
        firstName: [this.contact.firstName],
        lastName: [this.contact.lastName],
        emailAddress: [
          this.contact.emailAddress,
          [Validators.required, Validators.email, this.emailNotSelfValidator()],
        ],
        phoneNumber: [parsedPhoneNumber?.nationalNumber ?? null, []],
        channel: [this.contact.defaultChannel, []],
        tags: [this.contact.tags, []],
        contactIdentifier: [this.contact.contactIdentifier, []],
        scope: [this.contact.scope, []],
      });
    } else {
      this.form = this.formBuilder.group({
        firstName: [this.contact.firstName, [Validators.required]],
        lastName: [this.contact.lastName, [Validators.required]],
        emailAddress: [
          this.contact.emailAddress,
          [Validators.required, Validators.email, this.emailNotSelfValidator()],
        ],
        phoneNumber: [parsedPhoneNumber?.nationalNumber ?? null, [Validators.required]],
        channel: [this.contact.defaultChannel, []],
        tags: [this.contact.tags, []],
        contactIdentifier: [this.contact.contactIdentifier, []],
        scope: [this.contact.scope, []],
      });
    }
  }

  getScopeItems(): JsonArray {
    return Object.keys(ContactScopeEnum).map((scope: string) => {
      return {
        value: scope,
        label: this.translateService.instant("ADDRESS-BOOK.scope." + scope + ".label"),
        labelAbbr: this.translateService.instant("ADDRESS-BOOK.scope." + scope + ".abbr"),
      };
    });
  }

  emailNotSelfValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;

      if (this.sessionAPIService.currentUser && this.sessionAPIService.currentUser.emailAddress === value) {
        return { isSelfEmail: true };
      }

      return null;
    };
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      let {
        emailAddress,
        firstName,
        lastName,
        phoneNumber,
        defaultChannel,
        scope,
        contactIdentifier,
        tags,
      }: ContactFormValue = this.form.value as ContactFormValue;

      let contact = new ContactModel();
      contact.emailAddress = emailAddress.toLowerCase();
      contact.firstName = firstName?.trim();
      contact.lastName = lastName?.trim();
      contact.phoneNumber = phoneNumber?.e164Number;
      contact.scope = scope;
      contact.tags = tags;
      contact.defaultChannel = defaultChannel;
      contact.contactIdentifier = contactIdentifier;

      this.onCreateContact.emit(contact);
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  searchSuggestions(event: AutoCompleteCompleteEvent) {
    let selectedTags = this.form.get("tags").value;
    let unselectedTags: string[] = this.suggestions.filter((tag) => !selectedTags.includes(tag));
    let queryResultsTags = unselectedTags.filter((tag) => tag.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
    this.filteredTags = queryResultsTags;
  }

  onKeyUp(event: any) {
    event.preventDefault();
    let tokenInput = event.target as HTMLInputElement;
    let { tags }: ContactFormValue = this.form.value as ContactFormValue;
    if (tokenInput.value) {
      let newValue = event.key == "," ? tokenInput.value.slice(0, -1) : tokenInput.value;
      let newTags = [...tags, newValue];
      this.form.controls["tags"].setValue(newTags);
      tokenInput.value = "";
    }
  }

  onTagChanges(): void {
    this.form
      .get("tags")
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((selectedTags) => {
        let filteredTags = this.suggestions.filter((tag) => !selectedTags.includes(tag));
        this.filteredTags = filteredTags;
      });
  }
}
