<div class="uploads-wrapper border-round" [ngClass]="{ 'uploads-wrapper--box': variant == 'box' }">
  <ul
    class="uploads stack-grid gap-3"
    style="grid-template-columns: 1fr 1fr"
    [ngClass]="{ 'scrollable': addHeightConstraints }"
  >
    <li
      class="uploads__file align-items-center"
      [ngClass]="{ 'p-disabled': disabled || isUploading }"
      *ngFor="let item of uploads"
    >
      <div class="flex gap-3 min-w-0 align-self-start">
        <div class="file__icon align-items-center justify-content-center border-round hidden sm:flex">
          <ng-template #fileIcon>
            <i [class]="item?.file?.type | getMimeTypeIcon"></i>
          </ng-template>
          <ng-container *ngIf="isUploading; else fileIcon">
            <ui-spinner [size]="20" *ngIf="item.status === 'uploading'"></ui-spinner>
            <i class="pi pi-file text-lg" *ngIf="!['complete', 'error', 'uploading'].includes(item.status)"></i>
            <i class="pi pi-check text-green-500 text-lg" *ngIf="item.status === 'complete'"></i>
            <i class="pi pi-times p-error text-lg" *ngIf="item.status === 'error'"></i>
          </ng-container>
        </div>
        <div class="file__content stack-grid">
          <span [tooltip]="item?.name" placement="top" [delay]="1000" class="file__name clamp">{{ item.name }}</span>
          <span class="file__description clamp"
            >{{ item.size | filesize }} -
            {{ item?.file?.type || ("COMMON.unknown-mime-type" | translate : { "default": "Unknown" }) }}</span
          >
        </div>
      </div>
      <!-- Actions (sign & close) -->
      <div class="flex ml-2 gap-3 align-items-center">
        <!-- Signature Indicator -->
        <div
          class="flex align-items-center justify-content-center"
          *ngIf="displaySignatureIndicator && signatureUploadIds.includes(item.uploadId)"
          [pTooltip]="
            'RECORD.fields.signature-uploads.to-sign'
              | translate : { default: 'A signature will be requested for this document' }
          "
          tooltipStyleClass="generic-tooltip"
        >
          <i class="msr-icon-draw icon-22px font-light text-primary msr-icon-fw"></i>
        </div>

        <!-- Signature Trigger -->
        <div
          *ngIf="displaySignatureTrigger && item.file.type === 'application/pdf' && item.file.size < 45 * 1024 * 1024"
        >
          <!-- Signature checkbox -->
          <p-checkbox
            name="enableSignature"
            [checkboxIcon]="'msr-icon-draw icon-20px msr-icon-fw'"
            class="signature-checkbox sr-only"
            (ngModelChange)="handleSignatureUploadChange($event)"
            [value]="item.uploadId"
            [(ngModel)]="signatureUploadIds"
            [inputId]="item.uploadId"
          ></p-checkbox>
          <label
            [for]="item.uploadId"
            style="
              --border-radius: 4px;
              border-color: var(--secondary-color);
              background-color: var(--surface-b);
              width: 2.5rem;
              height: 2.5rem;
            "
            class="cursor-pointer border-1 border-round flex align-items-center justify-content-center"
            [ngClass]="
              signatureUploadIds.includes(item.uploadId) ? 'bg-primary text-white border-primary' : 'text-secondary'
            "
          >
            <i class="msr-icon-draw icon-20px font-light msr-icon-fw"></i>
          </label>
        </div>

        <!-- Button icon to close  -->
        <button
          [disabled]="isUploading || disabled"
          (click)="remove(item.uploadId)"
          pButton
          type="button"
          data-button="noLabel"
          icon="pi pi-times"
          class="p-button-text p-button-secondary p-button-sm file__button flex-shrink-0"
          data-attr="file-upload-remove"
          *ngIf="allowRemove"
        ></button>

        <p-progressBar
          styleClass="file__upload-progress"
          *ngIf="isUploading && item.status === 'uploading'"
          [value]="item.progress"
          [showValue]="false"
          [style]="{ 'height': '6px', 'width': '100%' }"
        ></p-progressBar>
      </div>
    </li>
  </ul>
</div>
