import BaseModel from "@shared/models/base.model";

export default class PaymentMethodCardModel extends BaseModel {
  // MINIMIZE (default)
  public brand: string | null = null;
  public expMonth: number | null = null;
  public expYear: number | null = null;
  public fingerprint: string | null = null;
  public last4: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
