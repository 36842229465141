import { Component, Input } from "@angular/core";
import { ThemingService } from "@app/@shared/services/theming.service";

@Component({
  selector: "main-create-record-menu",
  templateUrl: "./create-record-menu.component.html",
  styleUrls: ["./create-record-menu.component.scss"],
})
export class CreateRecordMenuComponent {
  @Input() showCreateMenu: boolean = false;
  @Input() recordMenuItems = [];

  constructor(public themingService: ThemingService) {}
}
