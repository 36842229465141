import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Logger } from "@app/@core";
import { LoaderService } from "@app/@core/loader";
import { AuthorizationTypesEnum } from "@app/@shared/enums/authorization-types.enum";
import { ContentTypesEnum } from "@app/@shared/enums/content-types.enum";
import { HttpHeadersEnum } from "@app/@shared/enums/http-headers.enum";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import ProviderContactsResultModel from "@app/@shared/models/contacts/provider-list-contacts-result.model";
import ProviderContactFolderModel from "@app/@shared/models/providers/provider-contact-folder.model";
import ProviderContactModel from "@app/@shared/models/providers/provider-contact.model";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import { v4 } from "uuid";

const log = new Logger("ProviderContactsService");

@Injectable({
  providedIn: "root",
})
export class ProviderContactsAPIService {
  private _baseUrl: string = environment.services.baseUrls.partnerApiUrl;

  constructor(
    private loaderService: LoaderService,
    private httpService: HttpClient,
    private keycloakService: KeycloakService,
    private messageService: MessageService,
  ) {}

  getContact(
    params: { applicationIdentifier: string; contactId: string; folderId: string },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderContactModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.contacts.getContact;
    this.loaderService.addOperation("getContact");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new ProviderContactModel().deserialize(response["contact"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          log.error(error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getContact");
        }),
      );
  }

  listContacts(
    params: { applicationIdentifier: string; folderId?: string },
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderContactsResultModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.contacts.listContacts;
    this.loaderService.addOperation("listContacts");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset,
            limitCount,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          let result = new ProviderContactsResultModel();
          result.contacts = response["contacts"].map((contact: any) => new ProviderContactModel().deserialize(contact));
          result.addPagination(response);
          return result;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          log.error(error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("listContacts");
        }),
      );
  }

  listContactsFolders(
    params: { applicationIdentifier: string; folderId: string },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderContactModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.providers.contacts.listContactsFolders;
    this.loaderService.addOperation("listContactsFolders");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return response["contacts"].map((contact: any) => new ProviderContactModel().deserialize(contact));
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          log.error(error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("listContactsFolders");
        }),
      );
  }

  getContactFolder(
    params: { applicationIdentifier: string; folderId: string },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderContactFolderModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.contacts.getContactFolder;
    this.loaderService.addOperation("getContactFolder");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new ProviderContactFolderModel().deserialize(response["contactFolder"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          log.error(error);
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getContactFolder");
        }),
      );
  }
}
