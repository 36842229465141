import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export type SidebarItemProps = {
  label?: string;
  primeIcon?: string;
  msrIcon?: string;
  link?: string;
  tooltip?: string;
  separator?: boolean;
};

@Component({
  selector: "main-sidebar-link",
  templateUrl: "./sidebar-link.component.html",
  styleUrls: ["./sidebar-link.component.scss"],
})
export class SidebarLinkComponent implements OnInit {
  @Input() sidebarItem: SidebarItemProps;
  @Output() onMenuItemClick: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}
  ngOnInit(): void {}
  handleMenuItemClick(event: any) {
    this.onMenuItemClick.emit(event);
  }
}
