import { SignatureProviderEnum } from "@app/@shared/enums/signature-provider.enum";
import BaseModel from "@shared/models/base.model";

export default class GetRecipientSignatureModel extends BaseModel {
  public provider: SignatureProviderEnum | null = null;
  public status: string | null = null;
  public url: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
