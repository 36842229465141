export enum FormDataControlEnum {
  ADDRESS_FORM = "addressForm",
  CHECKBOX = "checkbox",
  SWITCH = "switch",
  CALENDAR = "calendar",
  CHECKBOXES = "checkboxes",
  DROPDOWN = "dropdown",
  EMAIL = "email",
  LISTBOX = "listbox",
  MASKED_TEXT = "maskedText",
  NUMBER = "number",
  PHONE = "phone",
  RADIO_BUTTONS = "radioButtons",
  RATING = "rating",
  SLIDER_RANGE = "sliderRange",
  TEXTAREA = "textarea",
  UPLOAD_BUTTON = "button",
  UPLOAD_DROPZONE = "dropzone",
}
