<p-button type="button" styleClass="p-button-sm p-button-text p-2" (click)="handleClick($event)">
  <ng-container *ngIf="(unreadCount$ | async) > 0; else noCountIcon">
    <i class="msr-icon-notifications text-color-secondary p-overlay-badge" pBadge severity="danger"></i>
  </ng-container>
  <ng-template #noCountIcon>
    <i class="msr-icon-notifications text-color-secondary"></i>
  </ng-template>
</p-button>

<p-overlayPanel
  #notificationsPanel
  styleClass="notifications-menu"
  (onShow)="handleOnShow($event)"
  [style]="themingService.customStyles$ | async"
>
  <ng-template pTemplate="content">
    <ng-container *ngIf="items$ | async as items">
      <div class="notifications-menu__container">
        <header class="notifications-menu__header">
          <div class="h4 px-3 py-2">
            {{ "NOTIFICATIONS.header.title" | translate : { "default": "Notifications" } }}
          </div>
          <div class="notifications-menu__mark-all-as-read" *ngIf="(unreadCount$ | async) > 0">
            <button
              pButton
              type="button"
              class="p-button-text p-button-sm"
              [label]="'NOTIFICATIONS.buttons.mark-all-as-read' | translate : { 'default': 'Mark all as read' }"
              (click)="handleMarkAllAsRead($event)"
            ></button>
          </div>
        </header>

        <ng-container *ngIf="!(isLoading$ | async); else loadingTemplate">
          <ng-container *ngIf="items.length > 0; else emptyNotifications">
            <div class="notifications-menu__list">
              <notifications-item
                *ngFor="let item of items"
                [message]="item"
                (onClick)="handleItemClick($event)"
              ></notifications-item>
            </div>
          </ng-container>

          <!-- Empty state -->
          <ng-template #emptyNotifications>
            <div
              class="surface-card border-round flex flex-column align-items-center px-2 py-4 lg:py-8 justify-content-center"
            >
              <i class="pi pi-bell text-4xl"></i>
              <h3 class="mb-0 px-3 text-center">
                {{ "NOTIFICATIONS.empty-states.menu.title" | translate : { default: "Notifications" } }}
              </h3>
              <p class="text-center mb-0 mt-2 measured-container px-2" style="--measure: var(--legible-measure)">
                {{
                  "NOTIFICATIONS.empty-states.menu.subtitle"
                    | translate
                      : {
                          default: "Your last notifications will apear here"
                        }
                }}
              </p>
            </div>
          </ng-template>

          <footer class="notifications-menu__footer" *ngIf="items.length > 0">
            <button
              type="button"
              pButton
              class="p-button-text p-button-sm ml-auto"
              (click)="handleViewAllClick($event)"
              icon="pi pi-angle-right"
              iconPos="right"
              [label]="'NOTIFICATIONS.buttons.view-all' | translate : { 'default': 'View all notifications (soon)' }"
            ></button>
          </footer>
        </ng-container>
      </div>
    </ng-container>

    <!-- Loading template -->
    <ng-template #loadingTemplate>
      <div class="flex py-4 px-2 justify-content-center align-items-center h-full">
        <ui-spinner [size]="40"></ui-spinner>
      </div>
    </ng-template>
  </ng-template>
</p-overlayPanel>
