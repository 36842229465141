<div class="flex align-items-center justify-content-between" style="min-height: 2.5rem">
  <div class="flex flex-wrap align-items-center gap-2">
    <ng-template #unitImage>
      <ui-unit-avatar [unit]="unit"></ui-unit-avatar>
    </ng-template>
    <ng-container *ngIf="unit.isPublic && (session$ | async) as session; else unitImage">
      <ui-user-avatar *ngIf="!authorizationService.isGuest(); else nestorLogo" [user]="session?.user"></ui-user-avatar>
      <ng-template #nestorLogo>
        <img width="36px" height="36px" [src]="'/assets/images/logo-colored-icon.svg'" class="logo-small" />
      </ng-template>
    </ng-container>
    <p-skeleton data-skeleton--circle *ngIf="!unit" shape="square" size="2rem" class="mr-2"></p-skeleton>
    <div class="lg:flex-column align-items-start hidden xl:flex">
      <!-- Unit Name -->
      <span *ngIf="unit.name" class="font-medium clamp" data-avatar--name>{{
        unit.isPublic
          ? authorizationService.isGuest()
            ? ("COMMON.nestor" | translate : { default: "Nestor" })
            : ("SESSION.unit-menu.personal.header" | translate : { default: "Personal space" })
          : unit.name
      }}</span>
      <p-skeleton data-skeleton--text *ngIf="!unit.name" height="1.15rem" width="8rem"></p-skeleton>
    </div>
  </div>
</div>
