import { Injectable } from "@angular/core";
import { BehaviorSubject, map, mergeMap, Observable, throwError } from "rxjs";
import { BillingAPIService } from "@shared/services/billing-api.service";
import BillingAccountModel from "@app/@shared/models/billing/billing-account.model";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import BillingProductModel from "@app/@shared/models/billing/product.model";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { MessageService } from "primeng/api";
import PromotionCodeModel from "@app/@shared/models/billing/promotion-code.model";
import { SubscriptionCancelDelayEnum } from "@app/@shared/enums/billing/subscription-cancel-delay.enum";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  // billing account observable
  private _billingAccount$: BehaviorSubject<BillingAccountModel> = new BehaviorSubject(undefined);
  billingAccount$: Observable<BillingAccountModel> = this._billingAccount$.asObservable();

  get billingAccount(): BillingAccountModel {
    return this._billingAccount$.getValue();
  }
  set billingAccount(billingAccount: BillingAccountModel) {
    this._billingAccount$.next(billingAccount);
  }

  // selected offer observable
  private _selectedOffer$: BehaviorSubject<BillingProductModel> = new BehaviorSubject(undefined);
  selectedOffer$: Observable<BillingProductModel> = this._selectedOffer$.asObservable();

  get selectedOffer(): BillingProductModel {
    return this._selectedOffer$.getValue();
  }
  set selectedOffer(selectedOffer: BillingProductModel) {
    this._selectedOffer$.next(selectedOffer);
  }

  // promotionCode observable
  private _promotionCode$: BehaviorSubject<PromotionCodeModel> = new BehaviorSubject(undefined);
  promotionCode$: Observable<PromotionCodeModel> = this._promotionCode$.asObservable();

  get promotionCode(): PromotionCodeModel {
    return this._promotionCode$.getValue();
  }
  set promotionCode(promotionCode: PromotionCodeModel) {
    this._promotionCode$.next(promotionCode);
  }

  // isLoading observable
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();

  // isLoaded observable
  private _isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoaded$ = this._isLoaded$.asObservable();

  constructor(private messageService: MessageService, private billingAPIService: BillingAPIService) {}

  /**
   * Just a proxy
   */
  getProducts(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]): Observable<BillingProductModel[]> {
    return this.billingAPIService.getProducts(responseLevels);
  }

  /**
   * Just a proxy
   */
  getPromotionCode(
    code: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<PromotionCodeModel> {
    return this.billingAPIService.getPromotionCode(code, responseLevels);
  }

  getBillingAccount(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]): Observable<BillingAccountModel> {
    this._isLoading$.next(true);
    this._isLoaded$.next(false);

    return this.billingAPIService.getBillingAccount(responseLevels).pipe(
      map((billingAccount: BillingAccountModel) => {
        this._billingAccount$.next(billingAccount);
        this._isLoading$.next(false);
        this._isLoaded$.next(true);

        return billingAccount;
      }),
    );
  }

  saveBillingAccount(
    billingAccount: BillingAccountModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<BillingAccountModel> {
    this._isLoading$.next(true);
    this._isLoaded$.next(false);

    return this.billingAPIService.saveBillingAccount(billingAccount, responseLevels).pipe(
      map((billingAccount: BillingAccountModel) => {
        this._billingAccount$.next(billingAccount);
        this._isLoading$.next(false);
        this._isLoaded$.next(true);

        return billingAccount;
      }),
    );
  }

  createSubscription(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]): Observable<BillingAccountModel> {
    this._isLoading$.next(true);
    this._isLoaded$.next(false);

    return this.selectedOffer$.pipe(
      mergeMap((product: BillingProductModel) => {
        if (product) {
          return this.billingAPIService.createSubscription(
            this.selectedOffer.id,
            this.promotionCode?.id,
            responseLevels,
          );
        }

        this.messageService.add(
          MessageHelper.createTextMessage(MessageSeverityEnum.SEVERITY_ERROR, "No offer selected", "No offer selected"),
        );
        return throwError(() => "No offer selected");
      }),
      map((billingAccount: BillingAccountModel) => {
        this._billingAccount$.next(billingAccount);
        this._isLoading$.next(false);
        this._isLoaded$.next(true);

        return billingAccount;
      }),
    );
  }

  updateSubscription(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]): Observable<BillingAccountModel> {
    this._isLoading$.next(true);
    this._isLoaded$.next(false);

    return this.selectedOffer$.pipe(
      mergeMap((product: BillingProductModel) => {
        if (product) {
          return this.billingAPIService.updateSubscription(
            this.selectedOffer.id,
            this.promotionCode?.id,
            responseLevels,
          );
        }

        this.messageService.add(
          MessageHelper.createTextMessage(MessageSeverityEnum.SEVERITY_ERROR, "No offer selected", "No offer selected"),
        );
        return throwError(() => "No offer selected");
      }),
      map((billingAccount: BillingAccountModel) => {
        this._billingAccount$.next(billingAccount);
        this._isLoading$.next(false);
        this._isLoaded$.next(true);

        return billingAccount;
      }),
    );
  }

  cancelSubscription(
    cancelDelay: SubscriptionCancelDelayEnum = SubscriptionCancelDelayEnum.IMMEDIATELY,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<BillingAccountModel> {
    this._isLoading$.next(true);
    this._isLoaded$.next(false);

    return this.billingAPIService.cancelSubscription(cancelDelay, responseLevels).pipe(
      map((billingAccount: BillingAccountModel) => {
        this._billingAccount$.next(billingAccount);
        this._isLoading$.next(false);
        this._isLoaded$.next(true);

        return billingAccount;
      }),
    );
  }

  detachPaymentMethod(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]): Observable<BillingAccountModel> {
    return this.billingAPIService.detachPaymentMethod(responseLevels).pipe(
      map((billingAccount: BillingAccountModel) => {
        this._billingAccount$.next(billingAccount);
        this._isLoading$.next(false);
        this._isLoaded$.next(true);

        return billingAccount;
      }),
    );
  }
}
