import { BillingPriceBillingSchemeEnum } from "@app/@shared/enums/billing/price-billing-scheme.enum";
import { BillingPriceTiersMode } from "@app/@shared/enums/billing/price-tiers-mode.enum";
import { BillingPriceTypeEnum } from "@app/@shared/enums/billing/price-type.enum";
import BaseModel from "@shared/models/base.model";
import BillingPriceRecurringModel from "./price/price-recurring.model";
import BillingPriceTierModel from "./price/price-tier.model";

export default class BillingPriceModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public billingScheme: BillingPriceBillingSchemeEnum;
  public currency: string | null = null;
  public type: BillingPriceTypeEnum;
  public unitAmount: number | null = null;
  public recurring: BillingPriceRecurringModel;
  public tiers: BillingPriceTierModel[];
  public tiersMode: BillingPriceTiersMode;
  public metadata: JsonValue = {};

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.recurring) this.recurring = new BillingPriceRecurringModel().deserialize(input.recurring);

    if (input.tiers && Array.isArray(input.tiers))
      this.tiers = input.tiers.map((tier) => new BillingPriceTierModel().deserialize(tier));

    return this;
  }

  /**
   * Helpers
   */
  get baseAmount(): number {
    if (this.billingScheme === BillingPriceBillingSchemeEnum.PER_UNIT) {
      return this.unitAmount;
    } else {
      if (this.recurring) {
        if (this.tiers && this.tiers.length > 0) {
          const tier = this.tiers[0];
          return tier.flatAmount ? tier.flatAmount : tier.unitAmount;
        }
      }
    }

    return 0;
  }

  get monthlyAmount(): number {
    return this.isYearly ? this.baseAmount / 12.0 : this.baseAmount;
  }

  get currencyCode(): string {
    return this.currency?.toUpperCase();
  }

  get isYearly(): boolean {
    return this.recurring && this.recurring.isYearly;
  }

  get isMonthly(): boolean {
    return this.recurring && this.recurring.isMonthly;
  }
}
