import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ContactScopeEnum } from "@app/@shared/enums/contact-scope.enum";
import { MatomoEventEnum } from "@app/@shared/enums/matomo-event-enum";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import { StringHelper } from "@app/@shared/helpers/string.helper";
import ContactModel from "@app/@shared/models/contacts/contact.model";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import { AddressBookAPIService } from "@app/@shared/services/address-book-api.service";
import { ThemingService } from "@app/@shared/services/theming.service";
import { ContactsService } from "@app/contacts/services/contacts.service";
import { Logger, UntilDestroy, untilDestroyed } from "@core";
import { TranslateService } from "@ngx-translate/core";
import { isEmpty } from "lodash";
import { MatomoTracker } from "ngx-matomo-client";
import { MenuItem, MessageService } from "primeng/api";
import { OverlayPanel } from "primeng/overlaypanel";
import { BehaviorSubject, Observable, catchError, debounceTime, distinctUntilChanged, map, of, tap } from "rxjs";

type ContactTagModel = {
  label: string;
  count: number;
};

const log = new Logger("MAIN(ContactManagerComponent)");

@UntilDestroy()
@Component({
  selector: "contacts-contact-manager",
  templateUrl: "./contact-manager.component.html",
  styleUrls: ["./contact-manager.component.scss"],
})
export class ContactManagerComponent implements OnInit {
  @ViewChild("searchRecipientsPanel") searchRecipientsPanel: OverlayPanel;
  @ViewChild("searchRecipientsInput") searchRecipientsInput: ElementRef<HTMLInputElement>;

  selectedContacts: ContactModel[];

  selectedTag: ContactTagModel;

  contactCreationItems: MenuItem[];

  showRecipientEditModal: boolean = false;
  showContactCreationModal: boolean = false;
  showProviderContactPicker: boolean = false;
  showContactImportModal: boolean = false;

  contacts$: Observable<ContactModel[]>;

  isLoadingContacts$: Observable<boolean>;
  allContactTags: ContactTagModel[] = [];
  filteredContactTags: ContactTagModel[];

  isCreatingContact$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  searchRecipient: string;
  searchRecipient$: BehaviorSubject<string> = new BehaviorSubject("");

  recipients: RecordRecipientModel[] = [];

  get isValidRecipientEmail() {
    return StringHelper.isEmail(this.searchRecipient);
  }

  get allContactTagsLabels(): string[] {
    return this.allContactTags?.map(({ label }) => label);
  }
  DEFAULT_LIMIT = 8;

  @Input() recipientRole: RecordRoleEnum = RecordRoleEnum.WRITER;
  @Input() isLoading: boolean = false;
  @Input() isSubmitting: boolean = false;
  @Input() suggestions: string[] = []; // entire tags

  @Output() onContactsSelected: EventEmitter<ContactModel[]> = new EventEmitter<ContactModel[]>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private addressBookAPIService: AddressBookAPIService,
    private readonly tracker: MatomoTracker,
    private messageService: MessageService,
    private translateService: TranslateService,
    private contactsService: ContactsService,
    public themingService: ThemingService,
  ) {}

  ngOnInit(): void {
    this.setContactCreationItems();
    this.handleRecipientSearch();

    this.isLoadingContacts$ = this.contactsService.isLoading$;
    this.contacts$ = this.contactsService.contacts$;

    this.addressBookAPIService
      .getTags({ scope: ContactScopeEnum.USER }, [ResponseLevelEnum.MINIMIZE])
      .pipe(
        map((tags) => this.sortTagsAlphabetically(tags)),
        untilDestroyed(this),
      )
      .subscribe((tags) => {
        this.allContactTags = tags;
        this.filteredContactTags = tags;
      });

    // this.addressBookAPIService
    //   .getTags({ scope: ContactScopeEnum.USER }, [ResponseLevelEnum.MINIMIZE])
    //   .pipe(untilDestroyed(this))
    //   .subscribe((tags) => {
    //     this.suggestions = tags.map(({ label }) => label);
    //   });
  }
  sortTagsAlphabetically(tags: ContactTagModel[]): ContactTagModel[] {
    if (!(tags?.length > 0)) return tags;
    return tags.sort((a, b) => a.label.localeCompare(b.label));
  }

  setContactCreationItems() {
    this.contactCreationItems = [
      {
        label: this.translateService.instant("ADDRESS-BOOK.manager.create-modal.header"),
        icon: "pi pi-user-plus",
        command: () => this.displayContactCreationModal(),
      },
      {
        label: this.translateService.instant("MAIN.provider-contacts.import-from-provider", {
          default: "From your Nestor Address Book",
        }),
        icon: "pi pi-microsoft",
        command: () => (this.showProviderContactPicker = true),
      },
      {
        label: this.translateService.instant("ADDRESS-BOOK.manager.table.buttons.import-contact", {
          default: "Import contacts",
        }),
        icon: "pi pi-file-import",
        command: () => (this.showContactImportModal = true),
      },
    ];
  }
  handleRecipientSearch() {
    this.searchRecipient$
      .pipe(debounceTime(150), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((searchText) => {
        if (!isEmpty(searchText)) {
          this.contactsService.initialize(0, this.DEFAULT_LIMIT, ContactScopeEnum.USER, null, searchText);
          this.filteredContactTags = this.allContactTags.filter(
            ({ label }) => label.toLowerCase().indexOf(searchText.toLowerCase()) == 0,
          );
        } else {
          this.contactsService.initialize(0, this.DEFAULT_LIMIT, ContactScopeEnum.USER);
          this.filteredContactTags = this.allContactTags;
        }
      });
  }

  // handleCreation(contact: ContactModel) {
  //   this.isCreatingContact = true;
  //   this.addressBookAPIService
  //     .saveContacts({ contacts: [contact] }, [ResponseLevelEnum.MINIMIZE])
  //     .pipe(untilDestroyed(this))
  //     .subscribe((result) => {
  //       posthog.capture(PosthogEventEnum.CONTACTS_ADDED, { count: 1 });
  //       this.tracker.trackEvent("Contacts", "Added", MatomoEventEnum.CONTACTS_ADDED, 1);
  //       this.onContactsSelected.emit(result.contacts);
  //       this.handleCancel();
  //       this.isCreatingContact = false;
  //     });

  //   this.addressBookAPIService
  //     .getTags({ scope: ContactScopeEnum.USER }, [ResponseLevelEnum.MINIMIZE])
  //     .pipe(untilDestroyed(this))
  //     .subscribe((tags) => {
  //       this.suggestions = tags.map(({ label }) => label);
  //     });
  // }

  // handleImportContacts(contacts: ContactModel[]) {
  //   this.addressBookAPIService
  //     .saveContacts({ contacts })
  //     .pipe(
  //       tap((result) => this.handleCancel()),
  //       untilDestroyed(this),
  //     )
  //     .subscribe(({ contacts }) => {
  //       posthog.capture(PosthogEventEnum.CONTACTS_ADDED, { count: contacts.length });
  //       this.tracker.trackEvent("Contacts", "Added", MatomoEventEnum.CONTACTS_ADDED, contacts.length);
  //       this.handleSelection(contacts);
  //     });
  // }

  // handleSelection(contacts: ContactModel[]) {
  //   this.onContactsSelected.emit(contacts);
  // }

  handleRecipientsChanged(recipients: RecordRecipientModel[]) {
    this.recipients = recipients;
  }

  handleContactsImported(contacts: ContactModel[]) {
    this.isCreatingContact$.next(true);
    this.contactsService
      .saveContacts({ contacts })
      .pipe(
        catchError((error) => {
          log.error(error);
          return of(null);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.isCreatingContact$.next(false);
        this.tracker.trackEvent("Contacts", "Added", MatomoEventEnum.CONTACTS_ADDED, contacts.length);
        this.showContactImportModal = false;
        this.handleContactsAdded(contacts);
      });
  }
  handleProviderContactSubmit(recipients: RecordRecipientModel[]) {
    this.recipients = [...this.recipients, ...recipients];
    this.messageService.add({
      severity: MessageSeverityEnum.SEVERITY_SUCCESS,
      detail: this.translateService.instant("RECORD.recipients.contacts-added", {
        count: recipients.length,
      }),
    });
    this.handleProviderContactClose();
  }
  handleProviderContactClose() {
    this.showProviderContactPicker = false;
  }
  handleContactsTagSelected(tag: ContactTagModel) {
    if (!tag?.label) return null;
    this.addressBookAPIService
      .searchContacts({ scope: ContactScopeEnum.USER, tags: [tag.label] }, 0, -1, [ResponseLevelEnum.ALL])
      .pipe(untilDestroyed(this))
      .subscribe(({ contacts }) => {
        if (contacts?.length) this.handleContactsAdded(contacts);
        this.searchRecipientsPanel.hide();
      });
  }
  handleHideSearchRecipientsPanel() {
    this.handleContactsAdded(this.selectedContacts);
    this.handleContactsTagSelected(this.selectedTag);
    this.selectedContacts = [];
    this.selectedTag = null;
  }

  displayContactCreationModal() {
    this.showContactCreationModal = true;
  }

  hideContactCreationModal() {
    this.showContactCreationModal = false;
  }

  handleCreateContact(contact: ContactModel) {
    this.isCreatingContact$.next(true);
    this.addressBookAPIService
      .saveContacts({ contacts: [contact] }, [ResponseLevelEnum.MINIMIZE])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.tracker.trackEvent("Contacts", "Added", MatomoEventEnum.CONTACTS_ADDED, 1);
        this.hideContactCreationModal();
        this.handleContactsAdded([contact]);
        this.isCreatingContact$.next(false);
      });
  }

  handleSearchRecipientChange(searchText: string) {
    this.searchRecipientsPanel.show(new Event(""), this.searchRecipientsInput.nativeElement);
    this.searchRecipient$.next(searchText ?? "");
  }

  handleContactsAdded(contacts: ContactModel[]) {
    if (!(contacts?.length > 0)) return null;
    let newRecipients: RecordRecipientModel[] = contacts.map((contact: ContactModel) =>
      RecordRecipientModel.fromContact(contact, this.recipientRole),
    );
    this.recipients = [...this.recipients, ...newRecipients];
    this.messageService.add({
      severity: MessageSeverityEnum.SEVERITY_SUCCESS,
      detail: this.translateService.instant("RECORD.recipients.contacts-added", {
        count: contacts.length,
      }),
    });
  }
  handleCreateContactFromEmail(recipientEmailAddress: string) {
    let contact = Object.assign(new ContactModel(), { emailAddress: recipientEmailAddress });
    this.isCreatingContact$.next(true);
    this.addressBookAPIService
      .saveContacts({ contacts: [contact] }, [ResponseLevelEnum.MINIMIZE])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.searchRecipientsPanel.hide();
        this.handleContactsAdded([contact]);
        this.searchRecipient = null;
        this.searchRecipientsInput.nativeElement.blur();
        this.isCreatingContact$.next(false);
      });
  }
  handleSearchRecipientKeyUp(event) {
    event.preventDefault();
    if (event.key === "Enter" && this.isValidRecipientEmail) {
      this.handleCreateContactFromEmail(this.searchRecipient);
    }
  }

  submit() {
    if (!(this.recipients?.length > 0)) {
      this.messageService.add({
        severity: MessageSeverityEnum.SEVERITY_INFO,
        detail: this.translateService.instant("RECORD.fields.recipients.errors.recipientsEmpty"),
      });
    } else {
      let newContacts: ContactModel[] = this.recipients.map((recipient: RecordRecipientModel) =>
        ContactModel.fromRecordRecipient(recipient),
      );
      this.onContactsSelected.emit(newContacts);
    }
  }

  handleCancel() {
    this.onCancel.emit();
  }
}
