import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordMessageFormItemModel from "@shared/models/record/record-message-form-item.model";
import UserModel from "@shared/models/user/user.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import WrappedKeyModel from "@app/@shared/models/vault/wrapped-key.model";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, from, map, mergeMap, Observable } from "rxjs";
import { RecordService } from "@app/record/services/record.service";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import { ExportsAPIService } from "@app/@shared/services/exports-api.service";
import { RecordAuthorizationService } from "@app/@shared/services/record-authorization.service";
import { AuthorizationService } from "@app/@shared/services/authorization.service";

@UntilDestroy()
@Component({
  selector: "record-answers",
  templateUrl: "./record-answers.component.html",
  styleUrls: ["./record-answers.component.scss"],
})
export class RecordAnswersComponent implements OnInit, OnDestroy {
  @Input() wrappedKeys: WrappedKeyModel[];
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  currentUser: UserModel;

  formItems$: BehaviorSubject<RecordMessageFormItemModel[]> = new BehaviorSubject([]);
  answeredMessages$: Observable<RecordMessageModel[]>;
  record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  isSubmitting: boolean = false;
  displayStatistics = false;
  isDownloading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  answeredReviewedPercentage$: Observable<number>;

  get formItems() {
    return this.formItems$.getValue();
  }

  get record() {
    return this.record$.getValue();
  }
  constructor(
    public sessionAPIService: SessionAPIService,
    private recordService: RecordService,
    private exportsAPIService: ExportsAPIService,
    private recordAuthorizationService: RecordAuthorizationService,
    public authorizationService: AuthorizationService,
  ) {
    this.currentUser = sessionAPIService.currentUser;

    this.recordService.record$.subscribe(this.record$);
    // Put formItems into a BehaviorSubject to access its value at any time
    this.recordService.formItems$.pipe(untilDestroyed(this)).subscribe(this.formItems$);

    // Filter answered messages
    this.answeredMessages$ = this.recordService.messages$.pipe(
      map((messages: RecordMessageModel[]) => {
        return messages.filter((m) => m.answers?.some((answer) => answer.type === "FORM")).reverse();
      }),
    );

    this.answeredReviewedPercentage$ = this.answeredMessages$.pipe(
      mergeMap((answeredMessages) => {
        let answers = answeredMessages.flatMap((message) => message.answers) ?? [];
        let reviewedAnswers = answers?.filter((answer) => answer.isValid !== null) ?? [];
        if (!(answers?.length > 0)) {
          return from([0]);
        } else {
          return from([Math.round((reviewedAnswers.length / answers.length) * 100)]); // Wrap the number in an observable
        }
      }),
      untilDestroyed(this),
    );
  }

  // Targets users allowed to validate form item answers
  get canValidateFormAnswers(): boolean {
    return this.record ? this.recordAuthorizationService.canValidateFormItemAnswers(this.record) : false;
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  getAnswerAttachments(
    formItemIdentifier: string,
    message: RecordMessageModel,
  ): RecordMessageAttachmentModel[] | undefined {
    return message.getDirectFormAnswerAttachments(formItemIdentifier);
  }

  getFormItem(formItemIdentifier: string): RecordMessageFormItemModel | undefined {
    return this.formItems.find((formItem) => formItem.itemIdentifier === formItemIdentifier);
  }

  cancel() {
    this.onCancel.emit();
  }

  handleExportFormAnswers() {
    this.isDownloading$.next(true);
    this.exportsAPIService
      .exportConversationAnswers(this.record$.getValue().recordIdentifier, {
        delimiter: ";",
        disposition: "attachment",
        format: "csv",
      })
      .subscribe((file: File) => {
        const url = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        link.rel = "noopener";
        link.download = file.name;

        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.removeEventListener("click", clickHandler);
          }, 150);
        };
        link.addEventListener("click", clickHandler, false);
        link.click();

        this.isDownloading$.next(false);
      });
  }
}
