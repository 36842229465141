import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from "@angular/forms";
import Quill, { Sources } from "quill";
import Delta from "quill-delta";
import { ContentChange, QuillEditorComponent, SelectionChange } from "ngx-quill";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import DataTypeModel from "@app/@shared/models/masterdata/data-type.model";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@app/@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { EmojiData, EmojiEvent } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { EmojiSearch } from "@ctrl/ngx-emoji-mart";
import { OverlayPanel } from "primeng/overlaypanel";
import { BehaviorSubject } from "rxjs";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export type EditorFormItemClickEvent = {
  dataset: DOMStringMap;
  blotIndex: number;
};

type MentionItem = {
  type: "dataType" | "emoji";
};

type DataTypeMentionItem = MentionItem & {
  id: string;
  dataType: DataTypeModel;
  disabled?: boolean;
};

type EmojiMentionItem = MentionItem & {
  id: string;
  colon: string;
};

type EmojiReplacer = {
  regex: RegExp;
  fn: (str: string) => EmojiData;
  matchIndex: number;
  replacementIndex: number; // Workaround to support regex lookahead on all browsers
  match?: RegExpExecArray;
};

@UntilDestroy()
@Component({
  selector: "editor-record-message-editor",
  templateUrl: "./record-message-editor.component.html",
  styleUrls: ["./record-message-editor.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordMessageEditorComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RecordMessageEditorComponent implements OnInit, ControlValueAccessor {
  @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
  @ViewChild(OverlayPanel, { static: true }) emojiPanel: OverlayPanel;
  @ViewChild("editorContainer") editorContainer?: ElementRef;

  @Input() classList: string;
  @Input() dataTypes: DataTypeModel[] = [];
  @Input() enableFormItems: boolean = false;
  @Input() enableMessageTemplate: boolean = false;
  @Input() enableSignature: boolean = false;
  @Input() enableLinkBold: boolean = true;
  @Input() enableLinkItalicize: boolean = true;
  @Input() enableLinkUnderline: boolean = true;
  @Input() enableLinkStrikeThrough: boolean = true;
  @Input() enableLinkNumberedList: boolean = true;
  @Input() enableLinkBulletedList: boolean = true;
  @Input() enableLinkLink: boolean = true;
  @Input() enableLinkEmoji: boolean = true;
  @Input() placeholder: string;
  @Input() readonly: boolean = false;
  @Input() style: any;
  @Input() controlName: string = "messageBody";

  /**
   * Quill related inputs
   */
  @Input() modules: any = {};

  @Output() onInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onContentChanged = new EventEmitter<ContentChange>();
  @Output() onSelectionChanged: EventEmitter<SelectionChange> = new EventEmitter<SelectionChange>();
  @Output() onDataTypeAutocompleted: EventEmitter<DataTypeModel> = new EventEmitter<DataTypeModel>();
  @Output() onFormItemToolbarClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMessageTemplateToolbarClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSignatureToolbarClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFormItemClick: EventEmitter<EditorFormItemClickEvent> = new EventEmitter<EditorFormItemClickEvent>();
  @Output() onSubmitHotkeys: EventEmitter<any> = new EventEmitter<any>();

  editorModules: any = {};
  emojiTranslations$: BehaviorSubject<any> = new BehaviorSubject({});
  isFocused: boolean = false;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};
  searchTerm: string = "";
  parentFormGroup: UntypedFormGroup;

  EMOJI_MENTION_CHARS = [":"];
  DATA_TYPES_MENTION_CHARS = ["#", "@", "/"];
  emojiIdRegexp =
    "a-zA-Z0-9\xE0\xE1\xE2\xE4\xE3\xE8\xE9\xEA\xEB\xED\xEE\xEF\xF3\xF6\xF4\xF5\u0153\xFA\xF9\xFB\xFC\xFF\xE7\xDF\xF1\xC0\xC1\xC2\xC4\xC3\xC8\xC9\xCA\xCB\xCD\xCE\xCF\xD3\xD6\xD4\xD5\u0152\xDA\xD9\xDB\xDC\u0178\xC7\xD1\u1100-\u11FF\uA960-\uA97F\uAC00-\uD7A3\uD7B0-\uD7FF\u3139-\u318F\u3041-\u3096\u30A0-\u30FF\uFF61-\uFF9F\u3400-\u4DB5\u4E00-\u9FCB\uF900-\uFA6A\u2E80-\u2FD5\uFF41-\uFF5A\uFF10-\uFF19-_+'\uFF3F\uFF0B\u30FC\u3005";
  emojiSkinToneRegexp = "::skin-tone-[2-6](?:-[2-6])?:";
  emoticonRegexp = `(?:\\s|^)((?:8\\))|(?:\\(:)|(?:\\):)|(?::'\\()|(?::\\()|(?::\\))|(?::\\*)|(?::-\\()|(?::-\\))|(?::-\\*)|(?::-/)|(?::->)|(?::-D)|(?::-O)|(?::-P)|(?::-\\\\)|(?::-b)|(?::-o)|(?::-p)|(?::-\\|)|(?::/)|(?::>)|(?::D)|(?::O)|(?::P)|(?::\\\\)|(?::b)|(?::o)|(?::p)|(?::\\|)|(?:;\\))|(?:;-\\))|(?:;-P)|(?:;-b)|(?:;-p)|(?:;P)|(?:;b)|(?:;p)|(?:<3)|(?:</3)|(?:=\\))|(?:=-\\))|(?:>:\\()|(?:>:-\\()|(?:C:)|(?:D:)|(?:c:))(?=\\s)`;

  mentionsConfig = {
    allowedChars: /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/,
    mentionDenotationChars: [...this.DATA_TYPES_MENTION_CHARS, ...this.EMOJI_MENTION_CHARS],
    isolateCharacter: true,
    positioningStrategy: "normal", // switched to normal to benefit from custom styles inheritance
    onSelect: (item: DOMStringMap, insertItem) => {
      if (this.DATA_TYPES_MENTION_CHARS.includes(item.denotationChar) && this.enableFormItems) {
        this.onMentionSelect(item, insertItem);
      } else if (this.EMOJI_MENTION_CHARS.includes(item.denotationChar)) {
        this.onEmojiSelect(item, insertItem);
      }
    },
    renderItem: (item: DataTypeMentionItem | EmojiMentionItem, searchTerm: string) => {
      if (item.type === "dataType" && this.enableFormItems) {
        return this.renderMentionItem(item as DataTypeMentionItem, searchTerm);
      } else if (item.type === "emoji") {
        return this.renderEmojiItem(item as EmojiMentionItem, searchTerm);
      }

      return null;
    },
    source: async (searchTerm: string, renderList, mentionChar: string) => {
      if (this.DATA_TYPES_MENTION_CHARS.includes(mentionChar) && this.enableFormItems) {
        this.searchTerm = searchTerm;
        const matchedDataTypes = await this.filterDataTypes(this.searchTerm);
        renderList(matchedDataTypes, this.searchTerm);
      }

      if (this.EMOJI_MENTION_CHARS.includes(mentionChar)) {
        const emojiSearchRegexp = new RegExp(`[${this.emojiIdRegexp}]{2,}(${this.emojiSkinToneRegexp})?`, "g");
        if (emojiSearchRegexp.test(searchTerm)) {
          this.searchTerm = searchTerm;
          const emojis = await this.emojiSearch.search(searchTerm).map((data: EmojiData) => ({
            id: data.native,
            colon: data.colons,
            type: "emoji",
          }));
          renderList(emojis, this.searchTerm);
        }
      }
    },
  };

  constructor(
    public controlContainer: ControlContainer,
    private readonly cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private messageService: MessageService,
    private emojiSearch: EmojiSearch,
  ) {}

  ngOnInit(): void {
    this.initModules();

    this.translateService
      .get("EMOJIS")
      .pipe(untilDestroyed(this))
      .subscribe((result: any) => {
        this.emojiTranslations$.next({
          search: result.search(),
          emojilist: result.emojilist(),
          notfound: result.notfound(),
          clear: result.clear(),
          categories: {
            search: result.categories.search(),
            recent: result.categories.recent(),
            people: result.categories.people(),
            nature: result.categories.nature(),
            foods: result.categories.foods(),
            activity: result.categories.activity(),
            places: result.categories.places(),
            objects: result.categories.objects(),
            symbols: result.categories.symbols(),
            flags: result.categories.flags(),
            custom: result.categories.custom(),
          },
          skintones: {
            "1": result.skintones["1"](),
            "2": result.skintones["2"](),
            "3": result.skintones["3"](),
            "4": result.skintones["4"](),
            "5": result.skintones["5"](),
            "6": result.skintones["6"](),
          },
        });
      });

    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    this.cdr.detectChanges();
  }

  handleEditorCreated() {
    // Adding a matcher that removes form-item from delta ops when being pasted into editor
    // this.editor.quillEditor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
    //   console.log("the node", node);
    //   const ops = delta.ops.map((op) => {
    //     if (op.insert && op.insert["form-item"]) {
    //       return { insert: op.insert["form-item"].text };
    //     }
    //     return op;
    //   });
    //   return new Delta(ops);
    // });
  }

  writeValue(value: any): void {
    if (this.editor) {
      this.editor.writeValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  initModules() {
    this.editorModules = {
      ...this.modules,
      magicUrl: true,
      mention: this.mentionsConfig,
      history: { maxStack: 0, userOnly: true },
      clipboard: {
        allowed: {
          tags: ["a", "b", "strong", "u", "s", "i", "p", "br", "ul", "ol", "li", "span"],
          attributes: ["href", "rel", "target", "class"],
        },
        keepSelection: true,
        substituteBlockElements: true,
        magicPasteLinks: true,
        matchVisual: false, // https://github.com/quilljs/quill/issues/2905#issuecomment-683128521
        hooks: {
          beforeSanitizeAttributes(node: Node, data, config) {
            if (node.nodeName === "SPAN") {
              const nodeElement = node as Element;
              const classList: DOMTokenList = nodeElement.classList;

              if (classList.contains("ql-form-item")) {
                const textContent = nodeElement.textContent;
                nodeElement.replaceWith(textContent);
              }
            }
          },
        },
      },
    };
  }

  filterDataTypes(searchTerm: string) {
    return this.dataTypes
      .filter(
        (d: DataTypeModel) =>
          d.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
          d.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          d.keywords?.some((keyword) => keyword.toLowerCase().includes(searchTerm.toLowerCase())),
      )
      .sort((a, b) => ("" + a.label).localeCompare(b.label))
      .map((d: DataTypeModel) => ({ id: d.dataTypeClass, dataType: d, type: "dataType" }));
  }

  handleContentChanged(event: ContentChange) {
    setTimeout(() => {
      this.onModelChange(event.html);
      this.onModelTouched();
    }, 0);
    this.onContentChanged.emit(event);

    if (event.source === "user") {
      const changes = this.convertInput(this.editor.quillEditor.getContents());

      if (changes.ops.length > 0) {
        this.editor.quillEditor.updateContents(changes, "silent");
      }
    }
  }

  handleSelectionChanged(event: SelectionChange) {
    this.onSelectionChanged.emit(event);
  }

  handleEmojiToolbarClick(event: Event) {
    if (!this.readonly && this.emojiPanel) {
      this.emojiPanel.toggle(event);
    }
  }

  handleAddEmoji(event: EmojiEvent) {
    if (!this.readonly) {
      const range = this.editor.quillEditor.getSelection(true);
      this.editor.quillEditor.insertText(range.index, event.emoji.native, "api");
      this.emojiPanel.toggle(event);
    }
  }

  handleFormItemToolbarClick(event) {
    if (!this.readonly) {
      this.onFormItemToolbarClick.emit();
      this.editor.quillEditor.blur();
    }
  }

  handleMessageTemplateToolbarClick(event) {
    if (!this.readonly) {
      this.onMessageTemplateToolbarClick.emit();
      this.editor.quillEditor.blur();
    }
  }

  handleSignatureToolbarClick(event) {
    if (!this.readonly) {
      this.onSignatureToolbarClick.emit();
      this.editor.quillEditor.blur();
    }
  }

  @HostListener("keydown", ["$event"])
  handleKeydown(event: KeyboardEvent) {
    // Bing CTRL + K to form item toolbar click
    if (event.ctrlKey && event.key === "k" && !this.readonly) {
      this.onFormItemToolbarClick.emit();
    }

    // Bind ctrl + ENTER / CMD + Enter to submit
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter" && !this.readonly) {
      this.onSubmitHotkeys.emit();
    }
  }

  @HostListener("window:form-item-click", ["$event"])
  handleFormItemClick(event) {
    if (!this.readonly) {
      let element = event.event.target;
      if (!element.classList.contains("ql-form-item")) {
        element = element.parentElement;
      }

      let blot = Quill.find(element);
      let index = blot.offset(this.editor.quillEditor.scroll);

      this.onFormItemClick.emit({ dataset: event.value, blotIndex: index } as EditorFormItemClickEvent);
    }
  }

  @HostListener("click", ["$event"])
  handleClick(event) {
    // this.setIsFocused(true);
  }

  handleFocus(event) {
    // this.setIsFocused(true);
  }

  handleBlur(event) {
    // this.setIsFocused(false);
  }

  handlePaste(event: ClipboardEvent) {
    if (event.clipboardData) {
      const pastedText: string = event.clipboardData.getData("Text");

      if (pastedText.includes('class="ql-form-item"')) {
        this.messageService.add(
          MessageHelper.createTextMessage(
            MessageSeverityEnum.SEVERITY_WARN,
            this.translateService.instant("RECORD.messages.paste-not-supported.title"),
            this.translateService.instant("RECORD.messages.paste-not-supported.detail"),
          ),
        );
      }
    }
  }

  setIsFocused(value: boolean) {
    if (value && !this.editor.quillEditor.hasFocus()) {
      this.editor.quillEditor.focus();
    }
    this.isFocused = value;
  }

  updateFormItem(formItem: RecordMessageFormItemModel, blotIndex: number) {
    let [leaf, offset] = this.editor.quillEditor.getLeaf(blotIndex + 1);

    if (!leaf) {
      return;
    }

    let quillLength = this.editor.quillEditor.getLength();
    let leafIndex = leaf.offset(leaf.scroll);
    let ops = new Delta()
      .retain(leafIndex)
      .delete(1)
      .retain(quillLength - leafIndex - 1);

    this.editor.quillEditor.updateContents(ops, "silent");

    this.insertEmbed(
      leafIndex,
      "form-item",
      { text: formItem.label, identifier: formItem.uniqueId, format: formItem.format },
      "api",
    );
    this.setSelection(leafIndex + 2, 0, "user");
  }

  /**
   * API for quill editor (proxy)
   */
  setSelection(index: number, length: number, source?: Sources) {
    setTimeout(() => this.editor?.quillEditor?.setSelection(index, length, source), 0);
  }

  insertText(index: number, text: string, source?: Sources) {
    this.editor?.quillEditor?.insertText(index, text, source);
  }

  insertEmbed(index: number, type: string, value: any, source?: Sources) {
    this.editor?.quillEditor?.insertEmbed(index, type, value, source);
  }

  /**
   * Mentions handling
   */
  onMentionSelect(item, insertItem) {
    const range = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.deleteText(range.index - this.searchTerm.length - 1, this.searchTerm.length + 1, "api"); // Take mention char into account when removing text

    let selected = this.dataTypes.find((d: DataTypeModel) => d.dataTypeClass === item.id);
    this.searchTerm = "";
    this.onDataTypeAutocompleted.emit(selected);
  }

  renderMentionItem(item: DataTypeMentionItem, searchTerm: string) {
    if (item.disabled) {
      return `<div style="height:10px;line-height:10px;font-size:10px;background-color:#ccc;margin:0 -20px;padding:4px">${item.dataType.label}</div>`;
    }
    return (
      `<div class="flex w-full gap-2 align-items-center data-type-suggestion">` +
      (item.dataType.getControl()
        ? `<i class="text-primary material-symbols-rounded">${this.translateService.instant(
            "MASTERDATA.data-types.icons." + item.dataType.getControl(),
          )}</i>`
        : "") +
      `<div class="flex flex-column gap-1">` +
      `<span class="font-semibold text-color">${item.dataType.label}</span>` +
      (item.dataType.description ? `<small class="text-color-secondary">${item.dataType.description}</small>` : "") +
      `</div>` +
      `</div>`
    );
  }

  /**
   * Emojis handling
   */
  onEmojiSelect(item: DOMStringMap, insertItem) {
    const range = this.editor.quillEditor.getSelection(true);
    this.editor.quillEditor.deleteText(range.index - this.searchTerm.length - 1, this.searchTerm.length + 1, "api"); // Take mention char into account when removing text
    this.editor.quillEditor.insertText(range.index - this.searchTerm.length - 1, item.id, "api");
    this.searchTerm = "";
  }

  renderEmojiItem(item: EmojiMentionItem, searchTerm: string) {
    return (
      `<div class="flex w-full gap-3 align-items-center emoji-suggestion">` +
      `<span class="emoji-suggestion__emoji">${item.id}</span>` +
      `<div class="flex flex-column gap-1">` +
      `<span class="emoji-suggestion__label">${item.colon}</span>` +
      `</div>` +
      `</div>`
    );
  }

  convertInput(delta: any): any {
    let replacement: EmojiReplacer = {
      regex: new RegExp(this.emoticonRegexp, "g"),
      matchIndex: 1,
      replacementIndex: 1,
      fn: (str: string) => {
        const emojiId: string = this.emojiSearch.emoticonsList[str];
        if (emojiId) {
          const emoji = this.emojiSearch.emojisList[emojiId];
          return emoji ? emoji : null;
        }
        return null;
      },
    };

    const changes = new Delta();

    let position = 0;

    delta.ops.forEach((op: any, opIndex: number) => {
      if (op.insert) {
        if (typeof op.insert === "object") {
          position++;
        } else if (typeof op.insert === "string") {
          const text = opIndex === delta.ops.length - 1 ? op.insert.replace("\n", "") : op.insert; // Replace last operation newline char as it is AUTOMATICALLY added by quill

          let emoticonText = "";
          let index: number;

          // tslint:disable-next-line: no-conditional-assignment
          while ((replacement.match = replacement.regex.exec(text))) {
            // Setting the index and using the difference between the matches as a workaround for a lookahead regex
            index =
              replacement.match.index +
              (replacement.match[0].length - replacement.match[replacement.replacementIndex].length);

            emoticonText = replacement.match[replacement.matchIndex];

            const emoji: EmojiData = replacement.fn(emoticonText);

            const changeIndex = position + index;

            if (changeIndex > 0) {
              changes.retain(changeIndex);
            }

            changes.delete(replacement.match[replacement.replacementIndex].length);

            if (emoji) {
              changes.insert(emoji.native);
            }
          }

          position += op.insert.length;
        }
      }
    });

    return changes;
  }
}
