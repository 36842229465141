import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import SessionModel from "@shared/models/session/session.model";

@Component({
  selector: "session-user-role-card",
  templateUrl: "./user-role-card.component.html",
  styleUrls: ["./user-role-card.component.scss"],
})
/**
 * This component displays informations on the current user.
 */
export class UserRoleCardComponent implements OnInit, OnDestroy {
  @Input() session: SessionModel;
  @Input() showRole: boolean = true;

  constructor(private sessionAPIService: SessionAPIService, public authorizationService: AuthorizationService) {}
  ngOnInit(): void {}
  ngOnDestroy() {}
}
