<p-inputMask
  *ngIf="mask; else basicInput"
  [type]="type"
  [name]="name"
  [inputId]="inputId"
  [placeholder]="placeholder"
  [title]="title"
  [disabled]="disabled"
  [readonly]="readonly"
  [mask]="mask"
  [characterPattern]="characterPattern"
  [slotChar]="slotChar"
  [autoClear]="autoClear"
  [unmask]="unmask"
  [maxlength]="maxLength"
  [showClear]="showClear"
  [ngModel]="value"
  (ngModelChange)="handleChange($event)"
  (onInput)="handleChange($event)"
></p-inputMask>

<ng-template #basicInput>
  <input
    pInputText
    [type]="type"
    [name]="name"
    [id]="inputId"
    [placeholder]="placeholder"
    [title]="title"
    [disabled]="disabled"
    [readonly]="readonly"
    [value]="value"
    [ngModel]="value"
    (ngModelChange)="handleChange($event)"
  />
</ng-template>
