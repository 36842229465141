<!-- Untouched -->
<form
  class="generic-overlay-panel__main"
  *ngIf="createdTemplate === undefined"
  (ngSubmit)="onCreateNewTemplate.emit(newTemplateName)"
>
  <div class="field flex flex-column w-full">
    <label class="font-bold" for="newTemplateName">{{
      "TEMPLATES.fields.name.label" | translate : { "default": "Template name" }
    }}</label>
    <span class="text-sm text-color-secondary">
      {{
        "TEMPLATES.fields.name.description"
          | translate : { "default": "The content of the message with requested elements will be saved" }
      }}
    </span>
    <input
      [ngModelOptions]="{ standalone: true }"
      pInputText
      name="newTemplateName"
      id="newTemplateName"
      type="text"
      [(ngModel)]="newTemplateName"
      [disabled]="isCreatingTemplate"
      required
    />
  </div>
  <footer>
    <button
      pButton
      [loading]="isCreatingTemplate"
      type="submit"
      [label]="'COMMON.buttons.save' | translate"
      class="p-button-sm w-full"
    ></button>
  </footer>
</form>
<!-- Success -->
<div *ngIf="createdTemplate" class="generic-overlay-panel__main flex flex-column align-items-center p-4">
  <i class="pi pi-check p-4 block text-5xl font-bold bg-green-600 border-circle text-white"></i>
  <b class="text-xl mt-3 text-center">
    {{ "RECORD.messages.template-created.title" | translate : { default: "Template saved successfully !" } }}
  </b>
  <footer class="mt-3 flex flex-column justify-content-center gap-2 w-full">
    <button
      pButton
      type="button"
      (click)="onCancelSaveAsTemplate.emit()"
      [label]="'COMMON.buttons.close' | translate"
      class="p-button-sm p-button-outlined"
    ></button>
    <button
      pButton
      type="button"
      [routerLink]="['/templates', createdTemplate.templateIdentifier, 'edit']"
      [label]="'TEMPLATES.buttons.open-template' | translate : { default: 'Open template' }"
      class="p-button-sm"
    ></button>
  </footer>
</div>
<!-- Error -->
<div *ngIf="createdTemplate === null" class="generic-overlay-panel__main flex flex-column align-items-center p-4">
  <i class="pi pi-times p-4 block text-5xl font-bold bg-red-600 border-circle text-white"></i>
  <b class="text-xl mt-3 text-center">
    {{
      "RECORD.messages.template-created-error.title"
        | translate : { default: "Sorry, we could not create your template" }
    }}
  </b>
  <footer class="mt-3 flex flex-column justify-content-center gap-2 w-full">
    <button
      pButton
      type="button"
      (click)="onCancelSaveAsTemplate.emit()"
      [label]="'COMMON.buttons.close' | translate"
      class="p-button-sm p-button-outlined"
    ></button>
    <button
      pButton
      type="button"
      (click)="onRetryCreatingNewTemplate.emit()"
      [label]="'COMMON.buttons.try-again' | translate : { default: 'Try again' }"
      class="p-button-sm"
    ></button>
  </footer>
</div>
