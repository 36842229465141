import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService } from "@app/@core/loader";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { ProviderApplicationTypeEnum } from "../../enums/provider-application-type.enum";
import { ResponseLevelEnum } from "../../enums/response-level.enum";
import { v4 } from "uuid";
import { AuthorizationTypesEnum } from "../../enums/authorization-types.enum";
import { ContentTypesEnum } from "../../enums/content-types.enum";
import { HttpHeadersEnum } from "../../enums/http-headers.enum";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import ProviderApplicationModel from "../../models/providers/provider-application.model";
import { MessageSeverityEnum } from "../../enums/message-severity.enum";
import { MessageHelper } from "../../helpers/message.helper";
import ProviderItemModel from "../../models/providers/provider-item.model";
import { ProviderRootTypeEnum } from "../../enums/provider-root-type.enum";
import ErrorModel from "../../models/error.model";
import FileModel from "../../models/file/file.model";
import { Logger } from "@app/@core";
import ProviderRootModel from "@app/@shared/models/providers/provider-root.model";

const log = new Logger("ProviderFilesService");

@Injectable({
  providedIn: "root",
})
export class ProviderFilesService {
  private _baseUrl: string = environment.services.baseUrls.partnerApiUrl;
  // Custom Error Classes
  RevocationError = class RevocationError extends ErrorModel {};
  NoRootsError = class NoRootsError extends ErrorModel {};

  constructor(
    private keycloakService: KeycloakService,
    private loaderService: LoaderService,
    private httpService: HttpClient,
    private messageService: MessageService,
  ) {}

  /** List provider roots
   *
   * @param {string} params.includeShared - Fetch shared spaces or not
   * @param {string} params.applicationIdentifier - Application identifier
   * @param {string} params.siteId - Site ID to list drive of a Sharepoint site
   * @returns {Observable<ProviderRootModel[]>} Array of roots
   */
  listRoots(
    params: {
      applicationIdentifier: string;
      includeShared?: boolean;
      siteId?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderRootModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.listRoots;
    this.loaderService.addOperation("listRoots");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return response["roots"].map((root: any) => new ProviderRootModel().deserialize(root));
        }),
        catchError(({ error }) => {
          switch (error?.code) {
            case "MICROSOFT_CANNOT_ACCESS_ONEDRIVE":
              const noRootsError = Object.assign(new this.NoRootsError(), error);
              return throwError(() => noRootsError);
            default:
              this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
              return throwError(() => error);
          }
        }),
        finalize(() => {
          this.loaderService.removeOperation("listRoots");
        }),
      );
  }

  /**
   * List drive items
   *
   * @param {string} params.driveId - Id of the root folder - if (undefined with itemId) return root folder
   * @param {string} params.itemId - Id of the folder which children will be listed
   * @param {string} params.rootType - Type of data source (shared, personal etc...)
   * @returns {Observable<ProviderItemModel[]>} Array of items
   */
  list(
    params: {
      applicationIdentifier: string;
      rootType?: ProviderRootTypeEnum;
      allowExternal?: boolean;
      driveId?: string;
      itemId?: string;
      foldersOnly?: boolean;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderItemModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.list;
    this.loaderService.addOperation("list");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return response["items"].map((item: any) => new ProviderItemModel().deserialize(item));
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("list");
        }),
      );
  }

  /** Search Item in Provider
   * @param {string} params.driveId - Id of the root folder
   */
  search(
    params: {
      applicationIdentifier: string;
      query: string;
      driveId?: string;
      itemId?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderItemModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.search;
    this.loaderService.addOperation("search");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return response["items"].map((item: any) => new ProviderItemModel().deserialize(item));
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("search");
        }),
      );
  }

  /**
   * Get a single item
   *
   * @param {string} params.driveId - Parent folder Id
   * @param {string} params.itemId - Item id
   * @returns {Observable<ProviderItemModel>}
   */
  get(
    params: {
      applicationIdentifier: string;
      driveId: string;
      itemId: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderItemModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.get;
    this.loaderService.addOperation("get");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new ProviderItemModel().deserialize(response["item"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("get");
        }),
      );
  }

  /** Create a new folder
   *
   * @param {string} params.driveId - Id of the root item
   * @param {string} params.parentItemId - Id of the parent folder or undefined if currently in root
   * @returns {Observable<ProviderItemModel>}
   */
  createFolder(
    params: {
      applicationIdentifier: string;
      driveId: string;
      name: string;
      parentItemId?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderItemModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.createFolder;
    this.loaderService.addOperation("createFolder");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new ProviderItemModel().deserialize(response["item"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("createFolder");
        }),
      );
  }

  /** Retrieve content from provider and upload to Files API */
  retrieve(
    params: {
      applicationIdentifier: string;
      recordIdentifier: string;
      driveId: string;
      itemId: string;
      filename?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<FileModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.retrieve;
    this.loaderService.addOperation("retrieve");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new FileModel().deserialize(response["file"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("retrieve");
        }),
      );
  }

  /** Upload to provider from Files API
   * @param {string} params.itemId - If null uploads to the drive root folder
   */
  upload(
    params: {
      applicationIdentifier: string;
      driveId: string;
      fileIdentifier: string;
      recordIdentifier: string;
      itemId?: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<boolean> {
    const url = this._baseUrl + environment.services.methodUrls.providers.files.upload;
    this.loaderService.addOperation("upload");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return response["done"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("upload");
        }),
      );
  }
}
