<div class="form-item-properties h-full">
  <form class="form-item-properties__content h-full" [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-item-properties__fields">
      <!-- Title & description-->
      <div class="field">
        <label class="form-item-properties__control__label" for="form-item-properties-title">
          {{ "RECORD.form-item.fields.label.label" | translate : { "default": "Title" } }}
        </label>
        <input
          id="form-item-properties-title"
          pInputText
          pAutoFocus
          [autofocus]="true"
          [attr.placeholder]="
            'RECORD.form-item.fields.label.placeholder' | translate : { 'default': 'Form item label' }
          "
          formControlName="label"
        />
      </div>
      <div class="field rich-text">
        <label class="form-item-properties__control__label" for="form-item-properties-description">
          {{ "RECORD.form-item.fields.description.label" | translate : { "default": "Hint" } }}
        </label>
        <editor-record-message-editor
          [enableLinkBulletedList]="false"
          [enableLinkStrikeThrough]="false"
          [enableLinkNumberedList]="false"
          [enableLinkEmoji]="false"
          id="form-item-properties-description"
          controlName="description"
          placeholder=""
          [style]="{ 'min-height': '135px' }"
        ></editor-record-message-editor>
      </div>

      <div class="flex flex-column mt-4 gap-5">
        <ng-container formGroupName="properties">
          <!-- Form Item Control -->
          <div class="form-item-properties__control" *ngIf="displayFormControl('control')">
            <label class="form-item-properties__control__label" for="form-item-properties-control">{{
              "RECORD.form-item.properties.fields.control.label" | translate : { "default": "Control" }
            }}</label>
            <p-selectButton
              inputId="form-item-properties-control"
              [title]="'RECORD.form-item.properties.fields.control.label' | translate : { 'default': 'Control' }"
              appendTo="body"
              [options]="controlOptions"
              formControlName="control"
            >
            </p-selectButton>
          </div>
          <!-- File Renaming -->
          <div
            class="form-item-properties__control"
            *ngIf="
              displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.FILE_RENAMING)
            "
          >
            <div class="form-item-properties__control">
              <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.FILE_RENAMING)">
                <label class="form-item-properties__control__label" for="form-item-properties-file-renaming">
                  {{
                    "RECORD.form-item.properties.fields.file-renaming.label"
                      | translate : { "default": "Rename documents automatically" }
                  }}
                </label>
                <p-dropdown
                  inputId="form-item-properties-file-renaming"
                  [title]="
                    'RECORD.form-item.properties.fields.file-renaming.label'
                      | translate : { 'default': 'Rename documents automatically' }
                  "
                  appendTo="body"
                  [options]="fileRenamingOptions"
                  [autoDisplayFirst]="false"
                  [showClear]="true"
                  formControlName="value"
                >
                </p-dropdown>
                <span
                  class="text-sm mt-1"
                  *ngIf="getFormatterFormGroup(FormDataFormatterTypeEnum.FILE_RENAMING)?.value?.value"
                >
                  {{
                    "RECORD.form-item.properties.fields.file-renaming.hint"
                      | translate
                        : {
                            "default": "Files will be renamed accordingly. For example: ",
                            "example": getRenamedExample
                          }
                  }}
                </span>
              </ng-container>
            </div>
          </div>
          <!-- Options -->
          <div class="form-item-properties__control" *ngIf="displayFormControl('options')">
            <label class="form-item-properties__control__label" for="form-item-properties-control">{{
              "RECORD.form-item.properties.fields.options.groupLabel" | translate : { "default": "Options" }
            }}</label>
            <ng-container formArrayName="options">
              <ng-container *ngFor="let optionGroup of getControlArray('options').controls; let index = index">
                <div class="formgroup-inline align-items-center w-full" [formGroupName]="index">
                  <div class="field flex-1">
                    <label class="text-sm text-gray-400" [for]="'form-item-properties-options-label-' + index">{{
                      "RECORD.form-item.properties.fields.options.label.label" | translate : { "default": "Label" }
                    }}</label>
                    <input
                      pInputText
                      [id]="'form-item-properties-options-label-' + index"
                      type="text"
                      [placeholder]="
                        'RECORD.form-item.properties.fields.options.label.placeholder'
                          | translate : { 'default': 'Option label' }
                      "
                      formControlName="label"
                      [ngClass]="{
                        'ng-invalid ng-dirty':
                          getOptionFormGroup(index)?.get('label')?.invalid &&
                          getOptionFormGroup(index)?.get('label')?.touched
                      }"
                    />
                  </div>
                  <!-- <div class="field">
                      <label class="text-sm text-gray-400" [for]="'form-item-properties-options-value-' + index">{{
                        "RECORD.form-item.properties.fields.options.value.label" | translate: { "default": "Value" }
                      }}</label>
                      <input
                        pInputText
                        [id]="'form-item-properties-options-value-' + index"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.options.value.placeholder'
                            | translate: { 'default': 'Option value' }
                        "
                        formControlName="value"
                        [ngClass]="{
                          'ng-invalid ng-dirty':
                            getOptionFormGroup(index)?.get('value')?.invalid &&
                            getOptionFormGroup(index)?.get('value')?.touched
                        }"
                      />
                    </div> -->
                  <button
                    pButton
                    type="button"
                    class="p-button-text p-button-secondary"
                    icon="pi pi-times-circle"
                    (click)="deleteFormOption(index)"
                  ></button>
                </div>
              </ng-container>
            </ng-container>
            <button
              pButton
              type="button"
              class="p-button-sm p-button-link"
              icon="pi pi-plus"
              [label]="
                'RECORD.form-item.properties.fields.options.add-option' | translate : { 'default': 'Add option' }
              "
              (click)="addFormOption()"
            ></button>
          </div>
        </ng-container>
      </div>

      <!-- TABS -->
      <div class="form-item-properties__tabs" formGroupName="properties" *ngIf="false">
        <p-tabView>
          <!-- General properties -->
          <p-tabPanel
            [header]="'RECORD.form-item.properties.tabs.general.title' | translate : { 'default': 'General' }"
            leftIcon="pi pi-cog"
          >
            <ng-template pTemplate="content">
              <div class="flex flex-column gap-5">
                <!-- Control -->
                <div class="form-item-properties__control" *ngIf="displayFormControl('control')">
                  <label class="form-item-properties__control__label" for="form-item-properties-control">{{
                    "RECORD.form-item.properties.fields.control.label" | translate : { "default": "Control" }
                  }}</label>
                  <p-dropdown
                    inputId="form-item-properties-control"
                    [title]="'RECORD.form-item.properties.fields.control.label' | translate : { 'default': 'Control' }"
                    appendTo="body"
                    [options]="controlOptions"
                    [autoDisplayFirst]="false"
                    formControlName="control"
                  >
                  </p-dropdown>
                </div>

                <!-- Options -->
                <div class="form-item-properties__control" *ngIf="displayFormControl('options')">
                  <label class="form-item-properties__control__label" for="form-item-properties-control">{{
                    "RECORD.form-item.properties.fields.options.groupLabel" | translate : { "default": "Options" }
                  }}</label>
                  <ng-container formArrayName="options">
                    <ng-container *ngFor="let optionGroup of getControlArray('options').controls; let index = index">
                      <div class="formgroup-inline align-items-center" [formGroupName]="index">
                        <div class="field">
                          <label class="text-sm text-gray-400" [for]="'form-item-properties-options-label-' + index">{{
                            "RECORD.form-item.properties.fields.options.label.label"
                              | translate : { "default": "Label" }
                          }}</label>
                          <input
                            pInputText
                            [id]="'form-item-properties-options-label-' + index"
                            type="text"
                            [placeholder]="
                              'RECORD.form-item.properties.fields.options.label.placeholder'
                                | translate : { 'default': 'Option label' }
                            "
                            formControlName="label"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                getOptionFormGroup(index)?.get('label')?.invalid &&
                                getOptionFormGroup(index)?.get('label')?.touched
                            }"
                          />
                        </div>
                        <div class="field">
                          <label class="text-sm text-gray-400" [for]="'form-item-properties-options-value-' + index">{{
                            "RECORD.form-item.properties.fields.options.value.label"
                              | translate : { "default": "Value" }
                          }}</label>
                          <input
                            pInputText
                            [id]="'form-item-properties-options-value-' + index"
                            type="text"
                            [placeholder]="
                              'RECORD.form-item.properties.fields.options.value.placeholder'
                                | translate : { 'default': 'Option value' }
                            "
                            formControlName="value"
                            [ngClass]="{
                              'ng-invalid ng-dirty':
                                getOptionFormGroup(index)?.get('value')?.invalid &&
                                getOptionFormGroup(index)?.get('value')?.touched
                            }"
                          />
                        </div>
                        <button
                          pButton
                          type="button"
                          class="p-button-sm p-button-link"
                          icon="pi pi-trash"
                          (click)="deleteFormOption(index)"
                        ></button>
                      </div>
                    </ng-container>
                  </ng-container>
                  <button
                    pButton
                    type="button"
                    class="p-button-sm p-button-link"
                    icon="pi pi-plus"
                    [label]="
                      'RECORD.form-item.properties.fields.options.add-option' | translate : { 'default': 'Add option' }
                    "
                    (click)="addFormOption()"
                  ></button>
                </div>

                <!-- Importance -->
                <div class="form-item-properties__control" *ngIf="displayFormControl('importance')">
                  <label class="form-item-properties__control__label" for="form-item-properties-importance">
                    <span>{{
                      "RECORD.form-item.properties.fields.importance.label" | translate : { "default": "Importance" }
                    }}</span>
                    <span class="text-gray-500 text-sm">
                      {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                    ></label
                  >
                  <p-dropdown
                    inputId="form-item-properties-importance"
                    [title]="
                      'RECORD.form-item.properties.fields.importance.label' | translate : { 'default': 'Importance' }
                    "
                    appendTo="body"
                    [options]="importanceOptions"
                    [autoDisplayFirst]="false"
                    [showClear]="true"
                    formControlName="importance"
                  >
                  </p-dropdown>
                </div>
              </div>
            </ng-template>
          </p-tabPanel>

          <!-- Advanced properties -->
          <p-tabPanel
            [header]="'RECORD.form-item.properties.tabs.advanced.title' | translate : { 'default': 'Advanced' }"
            leftIcon="pi pi-sliders-h"
            *ngIf="displayAdvancedTab"
          >
            <ng-template pTemplate="content">
              <div class="formgrid grid w-full">
                <!-- Formatters : Mask -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.MASK)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MASK)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-mask"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-mask.label" | translate : { "default": "Mask" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <input
                        pInputText
                        inputId="form-item-properties-formatters-mask"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-mask.placeholder'
                            | translate : { 'default': 'Mask' }
                        "
                        formControlName="value"
                      />
                    </ng-container>
                  </div>
                </div>
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.FILE_RENAMING)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.FILE_RENAMING)">
                      <label class="form-item-properties__control__label" for="form-item-properties-file-renaming">
                        {{
                          "RECORD.form-item.properties.fields.file-renaming.label"
                            | translate : { "default": "Rename documents automatically" }
                        }}
                      </label>
                      <p-dropdown
                        inputId="form-item-properties-file-renaming"
                        [title]="
                          'RECORD.form-item.properties.fields.file-renaming.label'
                            | translate : { 'default': 'Rename documents automatically' }
                        "
                        appendTo="body"
                        [options]="fileRenamingOptions"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        formControlName="value"
                      >
                      </p-dropdown>
                      <span
                        class="text-sm mt-1"
                        *ngIf="getFormatterFormGroup(FormDataFormatterTypeEnum.FILE_RENAMING)?.value?.value"
                      >
                        {{
                          "RECORD.form-item.properties.fields.file-renaming.hint"
                            | translate
                              : {
                                  "default": "Files will be renamed accordingly. For example: ",
                                  "example": getRenamedExample
                                }
                        }}
                      </span>
                    </ng-container>
                  </div>
                </div>
                <!-- Formatters : Mask character pattern -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.MASK_CHARACTER_PATTERN)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MASK_CHARACTER_PATTERN)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-mask-character-pattern"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-mask-character-pattern.label"
                            | translate : { "default": "Mask character" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <input
                        pInputText
                        inputId="form-item-properties-formatters-mask-character-pattern"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-mask-character-pattern.placeholder'
                            | translate : { 'default': 'Mask character' }
                        "
                        formControlName="value"
                      />
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Min -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.MIN)"
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MIN)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-min"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-min.label"
                            | translate : { "default": "Minimum value" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-min"
                        [mode]="'decimal'"
                        [maxFractionDigits]="9"
                        [format]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-min.placeholder'
                            | translate : { 'default': 'Minimum value' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Max -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.MAX)"
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MAX)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-max"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-max.label"
                            | translate : { "default": "Maximum value" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-max"
                        [mode]="'decimal'"
                        [maxFractionDigits]="9"
                        [format]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-max.placeholder'
                            | translate : { 'default': 'Maximum value' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Step -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.STEP)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.STEP)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-step"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-step.label" | translate : { "default": "Step" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-step"
                        [mode]="'decimal'"
                        [maxFractionDigits]="9"
                        [format]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-step.placeholder'
                            | translate : { 'default': 'Step' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Currency -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.CURRENCY)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.CURRENCY)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-currency"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-currency.label"
                            | translate : { "default": "Currency code" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <input
                        inputId="form-item-properties-formatters-currency"
                        pInputText
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-currency.placeholder'
                            | translate : { 'default': 'Currency code' }
                        "
                        formControlName="value"
                      />
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Currency display -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.CURRENCY_DISPLAY)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.CURRENCY_DISPLAY)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-currency-display"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-currency-display.label"
                            | translate : { "default": "Currency display" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-dropdown
                        inputId="form-item-formatters-currency-display"
                        [title]="
                          'RECORD.form-item.properties.fields.formatters-currency-display.placeholder'
                            | translate : { 'default': 'Currency display' }
                        "
                        appendTo="body"
                        [options]="currencyDisplayOptions"
                        [autoDisplayFirst]="false"
                        [showClear]="true"
                        formControlName="value"
                      >
                      </p-dropdown>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Min date -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.MIN_DATE)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MIN_DATE)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-min-date"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-min-date.label"
                            | translate : { "default": "Minimum date" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-calendar
                        inputId="form-item-formatters-min-date"
                        [title]="
                          'RECORD.form-item.properties.fields.formatters-min-date.placeholder'
                            | translate : { 'default': 'Minimum date' }
                        "
                        appendTo="body"
                        view="date"
                        [showTime]="false"
                        [showButtonBar]="true"
                        formControlName="value"
                      >
                      </p-calendar>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Max date -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.MAX_DATE)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MAX_DATE)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-max-date"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-max-date.label"
                            | translate : { "default": "Maximum date" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-calendar
                        inputId="form-item-formatters-max-date"
                        [title]="
                          'RECORD.form-item.properties.fields.formatters-max-date.placeholder'
                            | translate : { 'default': 'Maximum date' }
                        "
                        appendTo="body"
                        view="date"
                        [showTime]="false"
                        [showButtonBar]="true"
                        formControlName="value"
                      >
                      </p-calendar>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Specific dates -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.SPECIFIC_DATES)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.SPECIFIC_DATES)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-specific-dates"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-specific-dates.label"
                            | translate : { "default": "Specific dates" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-calendar
                        inputId="form-item-formatters-specific-dates"
                        [title]="
                          'RECORD.form-item.properties.fields.formatters-specific-dates.placeholder'
                            | translate : { 'default': 'Specific dates' }
                        "
                        appendTo="body"
                        view="date"
                        selectionMode="multiple"
                        [readonlyInput]="true"
                        [showTime]="false"
                        [showButtonBar]="false"
                        formControlName="value"
                      >
                      </p-calendar>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Hour step -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.HOUR_STEP)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.HOUR_STEP)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-hour-step"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-hour-step.label"
                            | translate : { "default": "Hour step (time)" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-hour-step"
                        [mode]="'decimal'"
                        [maxFractionDigits]="0"
                        [min]="1"
                        [max]="12"
                        [step]="1"
                        [format]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-hour-step.placeholder'
                            | translate : { 'default': 'Hour step (time)' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Minute step -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.MINUTE_STEP)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MINUTE_STEP)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-minute-step"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-minute-step.label"
                            | translate : { "default": "Minute step (time)" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-minute-step"
                        [mode]="'decimal'"
                        [maxFractionDigits]="0"
                        [min]="0"
                        [max]="30"
                        [step]="1"
                        [format]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-minute-step.placeholder'
                            | translate : { 'default': 'Minute step (time)' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Max files -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.MAX_FILES)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MAX_FILES)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-max-files"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-max-files.label"
                            | translate : { "default": "Maximum files count" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-max-files"
                        [mode]="'decimal'"
                        [maxFractionDigits]="0"
                        [min]="1"
                        [step]="1"
                        [format]="true"
                        [allowEmpty]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-max-files.placeholder'
                            | translate : { 'default': 'Maximum files count' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Max file size -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.MAX_FILE_SIZE)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.MAX_FILE_SIZE)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-max-file-size"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-max-file-size.label"
                            | translate : { "default": "Maximum file size (bytes)" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-max-file-size"
                        [mode]="'decimal'"
                        [maxFractionDigits]="0"
                        [min]="1"
                        [step]="1"
                        [format]="true"
                        [allowEmpty]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-max-file-size.placeholder'
                            | translate : { 'default': 'Maximum file size (bytes)' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : File accept -->

                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.FILE_ACCEPT)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.FILE_ACCEPT)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-file-accept"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-file-accept.label"
                            | translate : { "default": "Accepted files" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-listbox
                        inputId="form-item-properties-formatters-file-accept"
                        [options]="fileAcceptOptions"
                        [multiple]="true"
                        [filter]="true"
                        [checkbox]="true"
                        [showToggleAll]="true"
                        appendTo="body"
                        formControlName="value"
                      ></p-listbox>
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Prefix -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.PREFIX)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.PREFIX)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-prefix"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-prefix.label"
                            | translate : { "default": "Prefix" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <input
                        pInputText
                        inputId="form-item-properties-formatters-prefix"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-prefix.placeholder'
                            | translate : { 'default': 'Prefix' }
                        "
                        formControlName="value"
                      />
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Suffix -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') && displayFormatterFormControl(FormDataFormatterTypeEnum.SUFFIX)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.SUFFIX)">
                      <label class="form-item-properties__control__label" for="form-item-properties-formatters-suffix"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-suffix.label"
                            | translate : { "default": "Suffix" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <input
                        pInputText
                        inputId="form-item-properties-formatters-suffix"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-suffix.placeholder'
                            | translate : { 'default': 'Suffix' }
                        "
                        formControlName="value"
                      />
                    </ng-container>
                  </div>
                </div>

                <!-- Formatters : Rating stars -->
                <div
                  class="field col-12 md:col-6"
                  *ngIf="
                    displayFormControl('formatters') &&
                    displayFormatterFormControl(FormDataFormatterTypeEnum.RATING_STARS)
                  "
                >
                  <div class="form-item-properties__control">
                    <ng-container [formGroup]="getFormatterFormGroup(FormDataFormatterTypeEnum.RATING_STARS)">
                      <label
                        class="form-item-properties__control__label"
                        for="form-item-properties-formatters-rating-stars"
                        ><span>{{
                          "RECORD.form-item.properties.fields.formatters-rating-stars.label"
                            | translate : { "default": "Rating max" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate : { "default": "(optional)" } }}</span
                        ></label
                      >
                      <p-inputNumber
                        inputId="form-item-properties-formatters-rating-stars"
                        [mode]="'decimal'"
                        [maxFractionDigits]="0"
                        [min]="1"
                        [max]="15"
                        [step]="1"
                        [format]="true"
                        [locale]="language"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.formatters-rating-stars.placeholder'
                            | translate : { 'default': 'Rating max' }
                        "
                        formControlName="value"
                      ></p-inputNumber>
                    </ng-container>
                  </div>
                </div>

                <!-- Default Value -->
                <!-- <div class="field col-12" *ngIf="displayFormControl('defaultValue')">
                    <div class="form-item-properties__control">
                      <label class="form-item-properties__control__label" for="form-item-properties-defaultValue"
                        ><span>{{
                          "RECORD.form-item.properties.fields.defaultValue.label"
                            | translate: { "default": "Default value" }
                        }}</span
                        ><span class="text-gray-500 text-sm">
                          {{ "COMMON.fields.optional" | translate: { "default": "(optional)" } }}</span
                        ></label
                      >
                      <input
                        pInputText
                        inputId="form-item-properties-defaultValue"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.defaultValue.placeholder'
                            | translate: { 'default': 'Default value' }
                        "
                        formControlName="defaultValue"
                      />
                    </div>
                  </div> -->
              </div>
            </ng-template>
          </p-tabPanel>

          <!-- Content -->
          <p-tabPanel
            [header]="'RECORD.form-item.properties.tabs.content.title' | translate : { 'default': 'Content' }"
            leftIcon="pi pi-pencil"
          >
            <ng-template pTemplate="content">
              <!-- Labels -->
              <!-- <div class="form-item-properties__control" *ngIf="displayFormControl('labels')">
                <label class="form-item-properties__control__label" for="form-item-properties-labels">{{
                  "RECORD.form-item.properties.fields.labels.label" | translate : { "default": "Control labels" }
                }}</label>
                <div class="grid formgrid w-full" formGroupName="labels">
                  <ng-container *ngFor="let language of languages">
                    <div class="field col-12 md:col-6">
                      <label class="text-sm text-gray-400" [for]="'form-item-properties-labels-label-' + language">{{
                        "LANGUAGES." + language + ".fullName" | translate
                      }}</label>
                      <input
                        pInputText
                        [id]="'form-item-properties-labels-label-' + language"
                        type="text"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.labels.placeholder'
                            | translate
                              : {
                                  'default': 'Option label',
                                  language: ('LANGUAGES.' + language + '.fullName' | translate)
                                }
                        "
                        [formControlName]="language"
                      />
                    </div>
                  </ng-container>
                </div>
              </div> -->

              <!-- Descriptions -->
              <!-- <div class="form-item-properties__control" *ngIf="displayFormControl('descriptions')">
                <label class="form-item-properties__control__label" for="form-item-properties-descriptions">{{
                  "RECORD.form-item.properties.fields.descriptions.label"
                    | translate : { "default": "Control descriptions" }
                }}</label>
                <div class="grid formgrid w-full" formGroupName="descriptions">
                  <ng-container *ngFor="let language of languages">
                    <div class="field col-12 md:col-6">
                      <label
                        class="text-sm text-gray-400"
                        [for]="'form-item-properties-descriptions-label-' + language"
                        >{{ "LANGUAGES." + language + ".fullName" | translate }}</label
                      >
                      <textarea
                        pInputTextarea
                        [id]="'form-item-properties-descriptions-label-' + language"
                        rows="2"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.descriptions.placeholder'
                            | translate
                              : {
                                  'default': 'Option label',
                                  language: ('LANGUAGES.' + language + '.fullName' | translate)
                                }
                        "
                        [formControlName]="language"
                      ></textarea>
                    </div>
                  </ng-container>
                </div>
              </div> -->

              <!-- Placeholders -->
              <div class="form-item-properties__control" *ngIf="displayFormControl('placeholders')">
                <label class="form-item-properties__control__label" for="form-item-properties-placeholders">{{
                  "RECORD.form-item.properties.fields.placeholders.label"
                    | translate : { "default": "Control placeholders" }
                }}</label>
                <div class="grid formgrid w-full" formGroupName="placeholders">
                  <ng-container *ngFor="let language of languages">
                    <div class="field col-12 md:col-6">
                      <label
                        class="text-sm text-gray-400"
                        [for]="'form-item-properties-placeholders-label-' + language"
                        >{{ "LANGUAGES." + language + ".fullName" | translate }}</label
                      >
                      <textarea
                        pInputTextarea
                        [id]="'form-item-properties-placeholders-label-' + language"
                        rows="2"
                        [placeholder]="
                          'RECORD.form-item.properties.fields.placeholders.placeholder'
                            | translate
                              : {
                                  'default': 'Option label',
                                  language: ('LANGUAGES.' + language + '.fullName' | translate)
                                }
                        "
                        [formControlName]="language"
                      ></textarea>
                    </div>
                  </ng-container>
                </div>
              </div>

              <!-- ControlTexts -->
              <div class="form-item-properties__control" *ngIf="displayFormControl('controlTexts')">
                <label class="form-item-properties__control__label">{{
                  "RECORD.form-item.properties.fields.controlTexts.groupLabel"
                    | translate : { "default": "Control texts" }
                }}</label>
                <ng-container formArrayName="controlTexts">
                  <ng-container *ngFor="let language of languages">
                    <div class="formgrid grid w-full" [formGroupName]="language">
                      <label class="col-12">{{ "LANGUAGES." + language + ".fullName" | translate }}</label>
                      <!-- TRUE -->
                      <div class="field col-12 md:col-6">
                        <label
                          class="text-sm text-gray-400"
                          [for]="'form-item-properties-controlTexts-label-' + language + '-true'"
                          >{{ "MASTERDATA.boolean.true" | translate : { "default": "Checked text" } }}</label
                        >
                        <input
                          pInputText
                          [id]="'form-item-properties-controlTexts-label-' + language + '-true'"
                          type="text"
                          [placeholder]="'MASTERDATA.boolean.true' | translate : { 'default': 'Checked text' }"
                          formControlName="true"
                        />
                      </div>
                      <!-- FALSE -->
                      <div class="field col-12 md:col-6">
                        <label
                          class="text-sm text-gray-400"
                          [for]="'form-item-properties-controlTexts-label-' + language + '-false'"
                          >{{ "MASTERDATA.boolean.false" | translate : { "default": "Unchecked text" } }}</label
                        >
                        <input
                          pInputText
                          [id]="'form-item-properties-controlTexts-label-' + language + '-false'"
                          type="text"
                          [placeholder]="'MASTERDATA.boolean.false' | translate : { 'default': 'Unchecked text' }"
                          formControlName="false"
                        />
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
          </p-tabPanel>

          <!-- Preview -->
          <p-tabPanel
            [header]="'RECORD.form-item.properties.tabs.preview.title' | translate : { 'default': 'Preview' }"
            leftIcon="pi pi-eye"
          >
            <ng-template pTemplate="content">
              <form [formGroup]="previewForm">
                <record-form-item-preview
                  *ngIf="formItem"
                  formGroupName="preview"
                  [formItem]="formItemPreview"
                ></record-form-item-preview>
              </form>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>

    <footer class="form-item-properties__buttons flex flex-column sm:justify-content-between sm:flex-row">
      <button
        pButton
        class="p-button-sm p-button-outlined"
        type="button"
        icon="pi pi-arrow-left"
        [label]="'COMMON.buttons.return' | translate : { 'default': 'Cancel' }"
        (click)="cancel()"
      ></button>
      <button
        pButton
        class="p-button-sm"
        type="submit"
        [disabled]="form.invalid"
        [label]="'COMMON.buttons.insert-request' | translate : { 'default': 'Insert request' }"
      ></button>
    </footer>
  </form>
</div>
