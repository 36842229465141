import { get, set } from "lodash";
import { InheritedPropertiesEnum } from "../enums/inherited-properties.enum";
import { RecordPropertiesEnum } from "../enums/record-properties.enum";
import BaseModel from "./base.model";

export default class WithPropertiesModel extends BaseModel {
  public properties: JsonValue = {};

  /**
   * Helpers
   */
  getProperty(key: string | string[] | InheritedPropertiesEnum | RecordPropertiesEnum, defaultValue: any = false): any {
    const path = Array.isArray(key) ? [...key] : key;
    return get(this.properties, path, defaultValue);
  }

  setProperty(key: string | string[] | InheritedPropertiesEnum | RecordPropertiesEnum, value: any): any {
    if (!this.properties) this.properties = {};

    const path = Array.isArray(key) ? [...key] : key;
    return set(this.properties, path, value);
  }

  containsProperty(property: InheritedPropertiesEnum | RecordPropertiesEnum): boolean {
    return Boolean(this.getProperty(property));
  }

  /**
   * Domain /Unit / Record inherited properties
   */
  get convertConversationAnswerFilesToPDF(): boolean {
    return this.getProperty(InheritedPropertiesEnum.CONVERT_CONVERSATION_ANSWER_FILES_TO_PDF);
  }

  set convertConversationAnswerFilesToPDF(value: boolean) {
    this.setProperty(InheritedPropertiesEnum.CONVERT_CONVERSATION_ANSWER_FILES_TO_PDF, value);
  }
}
