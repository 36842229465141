<div class="flex align-items-center justify-content-between">
  <div class="flex align-items-center gap-2">
    <div
      class="hidden xl:pl-2 xl:flex-column xl:flex"
      [ngClass]="{
        'md:flex': authorizationService.isGuest()
      }"
    >
      <ng-container *ngIf="showRole">
        <span *ngIf="session?.userRole" data-avatar--role>{{
          "USER.roles." + session.userRole + ".label" | translate : { default: session.userRole }
        }}</span>
        <p-skeleton data-skeleton--text *ngIf="!session?.userRole" width="3rem" height="1.15rem"></p-skeleton>
      </ng-container>
      <span data-avatar--name class="font-medium clamp block" style="max-width: 38ch">{{
        session?.user?.emailAddress
      }}</span>
      <p-skeleton data-skeleton--text *ngIf="!session?.user" height="1.15rem" width="8rem"></p-skeleton>
    </div>
    <span class="xl:pr-2">
      <ui-user-avatar *ngIf="session?.user" [user]="session?.user"></ui-user-avatar>
    </span>
    <p-skeleton data-skeleton--circle *ngIf="!session?.user" shape="circle" size="2rem" class="ml-2"></p-skeleton>
  </div>
</div>
