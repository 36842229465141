import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { finalize, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LoaderService } from "@core/loader";
import { environment } from "@env/environment";
import { HttpHeadersEnum } from "@shared/enums/http-headers.enum";
import { ContentTypesEnum } from "@shared/enums/content-types.enum";
import { AuthorizationTypesEnum } from "@shared/enums/authorization-types.enum";
import { KeycloakService } from "keycloak-angular";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import DataTypeModel from "@shared/models/masterdata/data-type.model";
import RecordMessageTemplateModel from "../models/masterdata/record-message-template.model";
import { RecordMessageTemplateScopeEnum } from "../enums/record-message-template-scope.enum";
import { RecordMessageTemplateOrderEnum } from "../enums/record-message-template-order.enum";
import RecordMessageTemplatesResultModel from "../models/masterdata/record-message-templates-result.model";

@Injectable({
  providedIn: "root",
})
export class MasterDataAPIService {
  private _baseUrl: string = environment.services.baseUrls.mainApiUrl;

  constructor(
    private readonly keycloakService: KeycloakService,
    private httpService: HttpClient,
    private loaderService: LoaderService,
    private messageService: MessageService,
  ) {}

  getFormDataType(
    unitSlug?: string,
    dataTypeClass?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<DataTypeModel[]> {
    const url =
      this._baseUrl + environment.services.methodUrls.masterdata.getFormDataTypeList.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getFormDataType");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          dataTypeClass: dataTypeClass,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          if (!response["dataTypes"]) return null;

          return response["dataTypes"].map((data: any) => {
            return new DataTypeModel().deserialize(data);
          });
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getFormDataType");
        }),
      );
  }

  getRecordMessageTemplates(
    unitSlug?: string,
    params: {
      searchText?: string;
      scope?: RecordMessageTemplateScopeEnum;
      orderBy?: RecordMessageTemplateOrderEnum;
    } = { scope: RecordMessageTemplateScopeEnum.USER },
    limitOffset: number = 0,
    limitCount: number = -1,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplatesResultModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.masterdata.getRecordMessageTemplates.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getRecordMessageTemplates");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
            limitOffset,
            limitCount,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          let result = new RecordMessageTemplatesResultModel();
          result.templates = response["templates"].map((template: any) =>
            new RecordMessageTemplateModel().deserialize(template),
          );
          result.addPagination(response);
          return result;
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecordMessageTemplates");
        }),
      );
  }

  getRecordMessageTemplate(
    unitSlug?: string,
    templateIdentifier?: string,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.masterdata.getRecordMessageTemplate.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("getRecordMessageTemplate");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          templateIdentifier,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageTemplateModel().deserialize(response["template"]);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getRecordMessageTemplate");
        }),
      );
  }

  saveRecordMessageTemplate(
    unitSlug?: string,
    template?: RecordMessageTemplateModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.masterdata.saveRecordMessageTemplate.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("saveRecordMessageTemplate");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          template,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageTemplateModel().deserialize(response["template"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("saveRecordMessageTemplate");
        }),
      );
  }

  deleteRecordMessageTemplates(
    unitSlug: string,
    params: {
      templates: RecordMessageTemplateModel[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<string[]> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.masterdata.deleteRecordMessageTemplates.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("deleteRecordMessageTemplates");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return response["templates"];
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("deleteRecordMessageTemplates");
        }),
      );
  }

  duplicateRecordMessageTemplate(
    unitSlug?: string,
    template?: RecordMessageTemplateModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.masterdata.duplicateRecordMessageTemplate.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("duplicateRecordMessageTemplate");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          template,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageTemplateModel().deserialize(response["template"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("duplicateRecordMessageTemplate");
        }),
      );
  }

  shareRecordMessageTemplate(
    unitSlug?: string,
    template?: RecordMessageTemplateModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageTemplateModel> {
    const url =
      this._baseUrl +
      environment.services.methodUrls.masterdata.shareRecordMessageTemplate.replace("{unitSlug}", unitSlug);
    const correlationId = v4();

    this.loaderService.addOperation("shareRecordMessageTemplate");

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());
    const options = { headers: headers };

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          template,
        },
        options,
      )
      .pipe(
        map((response: any) => {
          return new RecordMessageTemplateModel().deserialize(response["template"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("shareRecordMessageTemplate");
        }),
      );
  }
}
