<p-tree
  [value]="dataTypesTree$ | async"
  selectionMode="single"
  [filter]="true"
  filterMode="strict"
  filterBy="label,keywords"
  [filterPlaceholder]="
    'MASTERDATA.data-types.fields.search.placeholder' | translate : { 'default': 'Search data types' }
  "
  styleClass="data-type-dialog__list"
  class="relative h-full w-full flex"
  [ngStyle]="{ 'min-height': '35vh' }"
  [(selection)]="selectedNode"
  (onNodeSelect)="handleNodeSelect($event)"
  (onNodeUnselect)="handleNodeUnselect($event)"
>
  <ng-template let-node pTemplate="default">
    <div>
      <ng-container *ngIf="node.label; else labelCommon">{{
        "MASTERDATA.groups." + node.label | translate
      }}</ng-container>
      <ng-template #labelCommon>{{ "MASTERDATA.groups.COMMON" | translate : { "default": "COMMON" } }}</ng-template>
    </div>
  </ng-template>
  <ng-template let-node pTemplate="dataType"
    ><div class="flex flex-row gap-3 align-items-center data-type">
      <div class="flex w-full align-items-center">
        <i
          class="mr-2 material-symbols-rounded"
          [tooltip]="'MASTERDATA.data-types.formats.' + node.data.format | translate"
          >{{ "MASTERDATA.data-types.icons." + node.data.getControl() | translate }}</i
        >
        <div class="flex flex-column data-type__information">
          <span class="font-semibold" style="color: var(--primary-800)">{{ node.data.label }}</span>
          <small *ngIf="node?.data?.description" class="text-color-secondary">{{ node.data.description }}</small>
        </div>
      </div>
    </div>
  </ng-template>
</p-tree>
