import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordModel from "@shared/models/record/record.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { RecordService } from "@app/record/services/record.service";
import { Observable } from "rxjs";
import { MessageFormatEnum } from "@app/@shared/enums/message-format.enum";

@Component({
  selector: "record-message-bot-type",
  templateUrl: "./message-bot-type.component.html",
  styleUrls: ["./message-bot-type.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecordMessageBotTypeComponent implements OnInit, OnDestroy {
  @Input() message: RecordMessageModel;

  @Output() onReplyClick: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();

  FormatEnum = MessageFormatEnum;
  record$: Observable<RecordModel>;

  constructor(public sessionAPIService: SessionAPIService, private recordService: RecordService) {
    this.record$ = this.recordService.record$;
  }

  ngOnInit(): void {}

  ngOnDestroy() {}

  handleReplyClick() {
    this.onReplyClick.emit(this.message);
  }
}
