import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { NotificationMessageModel } from "@app/@shared/models/notification/notification-message.model";
import { NotificationsService } from "@app/notifications/services/notifications.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "notifications-item",
  templateUrl: "./notification-item.component.html",
  styleUrls: ["./notification-item.component.scss"],
})
export class NotificationItemComponent {
  @Input() message: NotificationMessageModel;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private notificationsService: NotificationsService) {}

  ngOnInit() {}

  handleClick(event: Event) {
    this.notificationsService
      .markNotificationAs(this.message, true, true)
      .pipe(untilDestroyed(this))
      .subscribe((message: NotificationMessageModel) => {
        if (this.message.ctaUrl) {
          this.router.navigate([this.message.ctaUrl], { replaceUrl: true });
          this.onClick.emit();
        } else {
          this.onClick.emit();
        }
      });
  }
}
