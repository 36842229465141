import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { NgxOtpInputComponent, NgxOtpInputConfig } from "ngx-otp-input";

@Component({
  selector: "main-token-input-panel",
  templateUrl: "./token-input-panel.component.html",
  styleUrls: ["./token-input-panel.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TokenInputPanelComponent implements OnInit {
  @ViewChild(NgxOtpInputComponent) codeInput: NgxOtpInputComponent;

  @Input() showTokenPanel = false;
  @Input() isLoading = false;

  @Output() onTokenCompleted = new EventEmitter<string>();
  @Output() onTokenCancel = new EventEmitter<any>();

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    numericInputMode: true,
  };

  constructor() {}

  ngOnInit(): void {}

  // this called only if user entered full code
  handleTokenCompleted(token: string) {
    this.onTokenCompleted.emit(token);
  }

  resetToken() {
    this.codeInput?.clear();
  }
}
