import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoaderService } from "@app/@core/loader";
import { environment } from "@env/environment";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { ProviderApplicationTypeEnum } from "../../enums/provider-application-type.enum";
import { ResponseLevelEnum } from "../../enums/response-level.enum";
import { v4 } from "uuid";
import { AuthorizationTypesEnum } from "../../enums/authorization-types.enum";
import { ContentTypesEnum } from "../../enums/content-types.enum";
import { HttpHeadersEnum } from "../../enums/http-headers.enum";
import { Observable, catchError, finalize, map, throwError } from "rxjs";
import ProviderApplicationModel from "../../models/providers/provider-application.model";
import { MessageSeverityEnum } from "../../enums/message-severity.enum";
import { MessageHelper } from "../../helpers/message.helper";
import ErrorModel from "../../models/error.model";
import { Logger } from "@app/@core";
import { ProviderCategoryEnum } from "@app/@shared/enums/provider-category.enum";

const log = new Logger("ProviderApplicationsService");

@Injectable({
  providedIn: "root",
})
export class ProviderApplicationsService {
  private _baseUrl: string = environment.services.baseUrls.partnerApiUrl;
  // Custom Error Classes
  RevocationError = class RevocationError extends ErrorModel {};
  constructor(
    private keycloakService: KeycloakService,
    private loaderService: LoaderService,
    private httpService: HttpClient,
    private messageService: MessageService,
  ) {}

  /** List provider applications */
  listApplications(
    params: {
      categories?: ProviderCategoryEnum[];
      provider?: ProviderApplicationTypeEnum;
      revoked?: boolean;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderApplicationModel[]> {
    const url = this._baseUrl + environment.services.methodUrls.providers.applications.listApplications;
    this.loaderService.addOperation("listApplications");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return response["applications"].map((application: any) =>
            new ProviderApplicationModel().deserialize(application),
          );
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("listApplications");
        }),
      );
  }

  /** Get provider application */
  getApplication(
    params: {
      applicationIdentifier: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderApplicationModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.applications.getApplication;
    this.loaderService.addOperation("getApplication");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new ProviderApplicationModel().deserialize(response["application"]);
        }),
        catchError((error) => {
          this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
          return throwError(() => error);
        }),
        finalize(() => {
          this.loaderService.removeOperation("getApplication");
        }),
      );
  }

  /** Revoke provider application */
  revokeApplication(
    params: {
      applicationIdentifier: string;
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<ProviderApplicationModel> {
    const url = this._baseUrl + environment.services.methodUrls.providers.applications.revokeApplication;
    this.loaderService.addOperation("revokeApplication");
    const correlationId = v4();

    const headers = new HttpHeaders()
      .set(HttpHeadersEnum.CONTENT_TYPE, ContentTypesEnum.APPLICATION_JSON)
      .set(HttpHeadersEnum.AUTHORIZATION, AuthorizationTypesEnum.BEARER + " " + this.keycloakService.getToken());

    return this.httpService
      .post(
        url,
        {
          header: {
            application: environment.application,
            correlationId: correlationId,
            responseLevel: responseLevels,
          },
          ...params,
        },
        { headers },
      )
      .pipe(
        map((response: any) => {
          return new ProviderApplicationModel().deserialize(response["application"]);
        }),
        catchError((error: any) => {
          switch (error?.status) {
            case 403:
              const revocationError = Object.assign(new this.RevocationError(), error);
              return throwError(() => revocationError);
            default:
              this.messageService.add(MessageHelper.createErrorMessage(MessageSeverityEnum.SEVERITY_ERROR, error));
              return throwError(() => error);
          }
        }),
        finalize(() => {
          this.loaderService.removeOperation("revokeApplication");
        }),
      );
  }
}
