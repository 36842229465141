<div
  [ngClass]="{ 'isActive': userUnit.unit.unitIdentifier === (session$ | async).unit.unitIdentifier }"
  class="unit-card"
  (click)="handleClick()"
>
  <!-- IMAGE -->
  <ng-template #unitImage>
    <div class="unit__image" [innerHtml]="image | safeHtml"></div>
  </ng-template>
  <ng-container *ngIf="userUnit.unit.isPublic && (session$ | async) as session; else unitImage">
    <ui-user-avatar *ngIf="!authorizationService.isGuest(); else nestorLogo" [user]="session?.user"></ui-user-avatar>

    <ng-template #nestorLogo>
      <img
        width="var(--menu-item-image-size, 32px)"
        height="var(--menu-item-image-size, 32px)"
        [src]="'/assets/images/logo-colored-icon.svg'"
        class="logo-small border-noround"
      />
    </ng-template>
  </ng-container>
  <!-- NAME -->
  <span class="unit__name clamp">
    {{
      userUnit.unit.isPublic
        ? authorizationService.isGuest()
          ? ("COMMON.nestor" | translate : { default: "Nestor" })
          : ("SESSION.unit-menu.personal.header" | translate : { default: "Personal space" })
        : userUnit.unit.name
    }}
  </span>
  <!-- BADGE -->
  <span class="unit__badge" [ngClass]="{ 'free': userUnit.unit.isPublic }">
    {{
      userUnit.unit.isPublic
        ? ("SESSION.unit-menu.personal.badge" | translate : { default: "Free" })
        : ("UNIT.roles." + userUnit.userRole + ".label" | translate)
    }}
  </span>
  <!-- SETTINGS -->
  <span class="unit__options">
    <button
      type="button"
      pButton
      *ngIf="authorizationService.canManageUnit(userUnit)"
      class="p-button-text p-button-sm p-button-secondary"
      style="--p-button-width: 2.28rem; --p-button-height: 2.28rem"
      icon="pi pi-ellipsis-v"
      (click)="showWorkspaceMenu($event)"
    ></button>
  </span>
</div>
