import BaseModel from "@shared/models/base.model";

export default class BillingPriceTierModel extends BaseModel {
  // MINIMIZE (default)
  public flatAmount: number | null = null;
  public flatAmountDecimal: number | null = null;
  public unitAmount: number | null = null;
  public unitAmountDecimal: number | null = null;
  public upTo: number | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
