import { Pipe, PipeTransform } from "@angular/core";
import RecordRecipientModel from "@shared/models/record/record-recipient.model";

@Pipe({
  name: "getRecipientInitials",
})
export class GetRecipientInitialsPipe implements PipeTransform {
  transform(recipient: RecordRecipientModel) {
    if (recipient?.lastName && recipient?.firstName) {
      return recipient.firstName[0].toUpperCase() + recipient.lastName[0].toUpperCase();
    } else if (recipient?.emailAddress) {
      return `${recipient.emailAddress[0].toUpperCase()}`;
    } else {
      return "";
    }
  }
}
