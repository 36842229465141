import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  _operations: string[] = [];

  private _visible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  visible$: Observable<boolean> = this._visible$.asObservable();

  get visible() {
    return this._visible$.getValue();
  }

  constructor() {}

  addOperation(origin: string) {
    this._operations.push(origin);
    this._toggle();
  }

  removeOperation(origin: string) {
    while (this._operations.indexOf(origin) > -1) {
      this._operations.splice(this._operations.indexOf(origin), 1);
    }
    this._toggle();
  }

  _toggle() {
    let visible = this._operations.length > 0;
    this._visible$.next(visible);
  }
}
