import { Injectable } from "@angular/core";
import { NotificationsAPIService } from "@app/@shared/services/notifications-api.service";
import { Logger, UntilDestroy } from "@core";
import { BehaviorSubject, Observable, filter, map, of, switchMap, tap } from "rxjs";
import { NotificationsWSService } from "./notifications-ws.service";
import { NotificationMessageModel } from "@app/@shared/models/notification/notification-message.model";

const log = new Logger("NotificationsService");

type UnseenCountMessage = {
  unseenCount: number;
};

type UnreadCountMessage = {
  unreadCount: number;
};

type NotificationReceivedMessage = {
  message: NotificationMessageModel;
};

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  // unseenCount observable
  private _unseenCount$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  unseenCount$ = this._unseenCount$.asObservable();

  get unseenCount() {
    return this._unseenCount$.getValue();
  }

  set unseenCount(value: number) {
    this._unseenCount$.next(value);
  }

  // unreadCount observable
  private _unreadCount$: BehaviorSubject<number> = new BehaviorSubject(undefined);
  unreadCount$ = this._unreadCount$.asObservable();

  get unreadCount() {
    return this._unreadCount$.getValue();
  }

  set unreadCount(value: number) {
    this._unreadCount$.next(value);
  }

  // menuNotifications observable
  private _menuNotifications$: BehaviorSubject<NotificationMessageModel[]> = new BehaviorSubject([]);
  menuNotifications$ = this._menuNotifications$.asObservable();

  get menuNotifications() {
    return this._menuNotifications$.getValue();
  }

  set menuNotifications(value: NotificationMessageModel[]) {
    this._menuNotifications$.next(value);
  }

  // allNotifications observable
  private _allNotifications$: BehaviorSubject<NotificationMessageModel[]> = new BehaviorSubject([]);
  allNotifications$ = this._allNotifications$.asObservable();

  get allNotifications() {
    return this._allNotifications$.getValue();
  }

  set allNotifications(value: NotificationMessageModel[]) {
    this._allNotifications$.next(value);
  }

  // allNotifications observable
  private _page$: BehaviorSubject<number> = new BehaviorSubject(0);
  page$ = this._page$.asObservable();

  // reloadFeed observable
  private _reloadFeed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  reloadFeed$ = this._reloadFeed$.asObservable();

  // isLoading observable
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();

  // isLoaded observable
  private _isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoaded$ = this._isLoaded$.asObservable();

  // isLoadingMenu observable
  private _isLoadingMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingMenu$ = this._isLoadingMenu$.asObservable();

  // isLoadingNotifications observable
  private _isLoadingNotifications$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingNotifications$ = this._isLoadingNotifications$.asObservable();

  // isLoadingMoreNotifications observable
  private _isLoadingMoreNotifications$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoadingMoreNotifications$ = this._isLoadingMoreNotifications$.asObservable();

  constructor(
    private notificationsAPIService: NotificationsAPIService,
    private notificationsWSService: NotificationsWSService,
  ) {}

  initializeSession(): Observable<boolean> {
    this._isLoading$.next(true);
    this._isLoaded$.next(false);

    return this.notificationsAPIService.initializeSession().pipe(
      tap((token: string) => {
        // Initialize websocket connection
        this.notificationsWSService.connectWithToken(token);
      }),
      switchMap(() => {
        return this.notificationsAPIService.getUnreadNotificationsCount().pipe(
          tap((count: number) => {
            this.unreadCount = count;
          }),
        );
      }),
      tap(() => {
        this._isLoading$.next(false);
        this._isLoaded$.next(true);
      }),
      map(() => true),
    );
  }

  getMenuNotifications(): Observable<NotificationMessageModel[]> {
    this._isLoadingMenu$.next(true);
    return this.notificationsAPIService.getNotificationsFeed().pipe(
      switchMap((messages: NotificationMessageModel[]) => {
        if (messages.length > 0) {
          return this.notificationsAPIService.markNotificationsAs({
            messageIds: messages.map((n: NotificationMessageModel) => n.id),
            seen: true,
          });
        } else {
          return of(messages);
        }
      }),
      tap(() => this._isLoadingMenu$.next(false)),
      tap((messages: NotificationMessageModel[]) => (this.menuNotifications = messages)),
    );
  }

  getAllNotifications(): Observable<NotificationMessageModel[]> {
    this._isLoadingNotifications$.next(true);
    this._page$.next(0);

    return this.notificationsAPIService.getNotificationsFeed(this._page$.getValue()).pipe(
      switchMap((messages: NotificationMessageModel[]) => {
        if (messages.length > 0) {
          return this.notificationsAPIService.markNotificationsAs({
            messageIds: messages.map((n: NotificationMessageModel) => n.id),
            seen: true,
          });
        } else {
          return of(messages);
        }
      }),
      tap(() => this._isLoadingNotifications$.next(false)),
      tap((messages: NotificationMessageModel[]) => {
        this.allNotifications = messages;
      }),
      switchMap(() => this.allNotifications$),
    );
  }

  getNextNotificationsPage(): Observable<NotificationMessageModel[]> {
    this._isLoadingMoreNotifications$.next(true);
    this._page$.next(this._page$.getValue() + 1);

    return this.notificationsAPIService.getNotificationsFeed(this._page$.getValue()).pipe(
      switchMap((messages: NotificationMessageModel[]) => {
        if (messages.length > 0) {
          return this.notificationsAPIService.markNotificationsAs({
            messageIds: messages.map((n: NotificationMessageModel) => n.id),
            seen: true,
          });
        }
        return of(messages);
      }),
      tap(() => this._isLoadingMoreNotifications$.next(false)),
      tap((messages: NotificationMessageModel[]) => {
        this._allNotifications$.next([...this._allNotifications$.getValue(), ...messages]);
      }),
      switchMap(() => this.allNotifications$),
    );
  }

  markMenuNotificationsAs(seen?: boolean, read?: boolean): Observable<NotificationMessageModel[]> {
    let unreadMessages: NotificationMessageModel[] = this._menuNotifications$
      .getValue()
      .filter((m: NotificationMessageModel) => m.read === false || m.seen === false);

    if (unreadMessages.length > 0) {
      return this.notificationsAPIService
        .markNotificationsAs({
          messageIds: unreadMessages.map((n: NotificationMessageModel) => n.id),
          seen,
          read,
        })
        .pipe(
          tap(() => {
            this.menuNotifications.forEach((m: NotificationMessageModel) => {
              if (seen !== undefined) m.seen = seen;
              if (read !== undefined) m.read = read;
            });
          }),
        );
    } else {
      return of([]);
    }
  }

  markAllNotificationsAs(seen?: boolean, read?: boolean): Observable<NotificationMessageModel[]> {
    let unreadMessages: NotificationMessageModel[] = this._allNotifications$
      .getValue()
      .filter((m: NotificationMessageModel) => m.read === false || m.seen === false);

    if (unreadMessages.length > 0) {
      return this.notificationsAPIService
        .markNotificationsAs({
          messageIds: unreadMessages.map((n: NotificationMessageModel) => n.id),
          seen,
          read,
        })
        .pipe(
          tap(() => {
            this.allNotifications.forEach((m: NotificationMessageModel) => {
              if (seen !== undefined) m.seen = seen;
              if (read !== undefined) m.read = read;
            });
          }),
        );
    } else {
      return of([]);
    }
  }

  markNotificationAs(
    message: NotificationMessageModel,
    seen?: boolean,
    read?: boolean,
  ): Observable<NotificationMessageModel> {
    return this.notificationsAPIService
      .markNotificationsAs({
        messageIds: [message.id],
        seen,
        read,
      })
      .pipe(
        map((messages: NotificationMessageModel[]) => {
          let updatedMessage = messages[0];
          if (updatedMessage) {
            let index = this.menuNotifications.findIndex((m: NotificationMessageModel) => m.id === updatedMessage.id);
            if (index > -1) {
              let notifications = [...this.menuNotifications];
              notifications[index] = updatedMessage;
              this.menuNotifications = notifications;
            }

            return updatedMessage;
          }
          return null;
        }),
      );
  }

  /**
   * Returns an observable that looks for an 'unseen_count_changed'
   */
  onUnseenCountChanged(): Observable<UnseenCountMessage> {
    return this.isLoaded$.pipe(
      filter((loaded) => Boolean(loaded)),
      switchMap(() => {
        return this.notificationsWSService.fromEvent("unseen_count_changed").pipe(
          tap((message: UnseenCountMessage) => {
            if (message.unseenCount !== undefined) {
              this.unseenCount = message.unseenCount;
            }
          }),
        );
      }),
    );
  }

  onUnreadCountChanged(): Observable<UnreadCountMessage> {
    return this.isLoaded$.pipe(
      filter((loaded) => Boolean(loaded)),
      switchMap(() => {
        return this.notificationsWSService.fromEvent("unread_count_changed").pipe(
          tap((message: UnreadCountMessage) => {
            if (message.unreadCount !== undefined) {
              this.unreadCount = message.unreadCount;
            }
          }),
        );
      }),
    );
  }

  /**
   * Returns an observable that looks for notification received
   */
  onNotificationReceived(): Observable<NotificationMessageModel> {
    return this.isLoaded$.pipe(
      filter((loaded) => Boolean(loaded)),
      switchMap(() => {
        return this.notificationsWSService.fromEvent("notification_received").pipe(
          map((message: NotificationReceivedMessage) => {
            return new NotificationMessageModel().deserialize(message.message);
          }),
        );
      }),
    );
  }
}
