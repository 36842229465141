import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FilesAPIService, StartUploadParam, StartUploadResult } from "./files-api.service";
import RecordModel from "../models/record/record.model";
import { Logger } from "@app/@core";
import { RecordPropertiesService } from "./properties/record-properties.service";

const log = new Logger("FilesService");

@Injectable({
  providedIn: "root",
})
export class FilesService {
  constructor(private recordPropertiesService: RecordPropertiesService, private filesAPIService: FilesAPIService) {}

  /**
   * Proxy method that will evaluate whether convert file to pdf depending on unit or record properties
   */
  recordStartAnswerUpload(
    record: RecordModel,
    fileName: string,
    fileMimeType: string,
    fileSize: number,
    convertToPdf: boolean = false,
  ): Observable<string> {
    let convertFileToPdf = convertToPdf || this.recordPropertiesService.convertConversationAnswerFilesToPDF(record);
    return this.filesAPIService.recordStartUpload(fileName, fileMimeType, fileSize, convertFileToPdf);
  }

  /**
   * Proxy method that will evaluate whether convert file to pdf depending on unit or record properties
   */
  recordStartAnswerUploads(record: RecordModel, files: StartUploadParam[]): Observable<StartUploadResult[]> {
    files.forEach((f) => {
      let convertFileToPdf = f.convertToPdf || this.recordPropertiesService.convertConversationAnswerFilesToPDF(record);
      f.convertToPdf = convertFileToPdf;
    });
    return this.filesAPIService.recordStartUploads(files);
  }
}
