<div class="notifications-list">
  <ng-container *ngIf="!(isLoading$ | async); else loadingTemplate">
    <ng-container *ngIf="notifications$ | async as notifications">
      <ng-container *ngIf="notifications.length > 0; else emptyNotifications">
        <div class="notifications-list__container">
          <header class="notifications-list__header mb-2">
            <h1 class="h2 m-0">
              {{ "LAYOUTS.notifications.list.header.title" | translate : { "default": "Your notifications" } }}
            </h1>
            <button
              type="button"
              pButton
              class="p-button-text p-button-sm"
              (click)="handleMarkAllAsRead($event)"
              [label]="'NOTIFICATIONS.buttons.mark-all-as-read' | translate : { 'default': 'Mark all as read' }"
            ></button>
          </header>

          <div class="notifications-list__list">
            <notifications-item
              *ngFor="let notification of notifications"
              [message]="notification"
            ></notifications-item>
          </div>

          <div class="notifications-list__footer">
            <button
              pButton
              [loading]="isLoadingMore$ | async"
              type="button"
              class="p-button-text p-button-sm"
              (click)="handleLoadMore($event)"
              [label]="'NOTIFICATIONS.buttons.load-more' | translate : { 'default': 'Load more' }"
            ></button>
          </div>
        </div>
      </ng-container>

      <ng-template #emptyNotifications>
        <div
          class="surface-card shadow-1 border-round flex flex-column align-items-center py-4 lg:py-8 justify-content-center"
        >
          <i class="pi pi-bell text-4xl"></i>
          <h3 class="mb-0 px-3 text-center">
            {{ "NOTIFICATIONS.empty-states.menu.title" | translate : { default: "Notifications" } }}
          </h3>
          <p class="text-center mb-0 mt-2 measured-container px-2" style="--measure: var(--legible-measure)">
            {{
              "NOTIFICATIONS.empty-states.menu.subtitle"
                | translate
                  : {
                      default: "Your last notifications will apear here"
                    }
            }}
          </p>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #loadingTemplate>
    <div class="p-3">
      <ui-page-spinner></ui-page-spinner>
    </div>
  </ng-template>
</div>
