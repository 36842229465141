<div class="flex flex-row justify-content-between gap-3">
  <div class="flex flex-column gap-1" *ngIf="recipientsSigned.length">
    <span class="text-sm">{{ "RECORD.record-message-signature.signed" | translate : { "default": "Signed" } }}</span>
    <p-avatarGroup
      [pTooltip]="tooltipSignedRecipientsContent"
      [escape]="false"
      tooltipStyleClass="generic-tooltip"
      [showDelay]="300"
      tooltipPosition="top"
    >
      <p-avatar
        *ngFor="let recipient of recipientsSigned"
        shape="circle"
        [label]="recipient | getRecipientInitials"
        [ngClass]="{ 'declined': !hasCompletedSignature(recipient) }"
      ></p-avatar>
    </p-avatarGroup>
  </div>

  <div class="flex flex-column gap-1" *ngIf="recipientsNotSigned.length">
    <span class="text-sm">{{
      "RECORD.record-message-signature.not-signed" | translate : { "default": "Not signed" }
    }}</span>

    <p-avatarGroup
      [pTooltip]="tooltipNotSignedRecipientsContent"
      [escape]="false"
      tooltipStyleClass="generic-tooltip"
      [showDelay]="300"
      tooltipPosition="top"
    >
      <p-avatar
        *ngFor="let recipient of recipientsNotSigned"
        shape="circle"
        [label]="recipient | getRecipientInitials"
        class="inactive"
      ></p-avatar>
    </p-avatarGroup>
  </div>
</div>
