import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UploadState } from "ngx-uploadx";
import { MenuItem, MessageService } from "primeng/api";
import { MessageHelper } from "@shared/helpers/message.helper";
import { MessageSeverityEnum } from "@shared/enums/message-severity.enum";
import { v4 as v4 } from "uuid";
import { UploadDropDirective } from "@app/@shared/directives/upload-drop.directive";
import { TranslateService } from "@ngx-translate/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { FileSizePipe } from "@app/@shared/pipes/file-size.pipe";
import { FileHelper } from "@app/@shared/helpers/file.helper";
import { UploadListVariant } from "../file-upload-list/files-upload-list.component";

@Component({
  selector: "main-files-upload-button",
  templateUrl: "./files-upload-button.component.html",
  styleUrls: ["./files-upload-button.component.scss"],
  providers: [UploadDropDirective],
  encapsulation: ViewEncapsulation.None,
})
export class FilesUploadButtonComponent implements OnInit, OnDestroy {
  @ViewChild("uploadInput") input: ElementRef<HTMLInputElement>;

  items: MenuItem[];
  enableFoldersUpload: boolean = false;
  uploadFolders?: boolean = null;

  @Output() onFilesAdded = new EventEmitter<File[]>();
  @Output() onUploadRemove = new EventEmitter<string>();

  @Input() id: string = v4();
  @Input() title: string;
  @Input() maxFileSize: number;
  @Input() accept: string[] = null;
  @Input() max: number = null;
  @Input() showUploadProgress: boolean = true;
  @Input() disabled: boolean = false;
  @Input() isUploading: boolean = false;
  @Input() uploads: UploadState[] = [];
  @Input() uploadListVariant: UploadListVariant = "inline";

  constructor(
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private fileSizePipe: FileSizePipe,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    if (this.deviceDetectorService.isDesktop()) {
      this.enableFoldersUpload = true;
    }

    this.items = [
      {
        "label": this.translateService.instant("COMMON.buttons.add-folder", { default: "Add folder" }),
        command: () => {
          this.handleFoldersUpload(true);
        },
      },
    ];
  }

  ngOnDestroy() {}

  handleRemove(uploadId?: string): void {
    this.onUploadRemove.emit(uploadId);
  }

  validMaxFiles(fileIndex: number): boolean {
    /**
     * Check file number limit
     */
    if (Boolean(this.max) && this.uploads.length + fileIndex + 1 > this.max) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.max-files-reached.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.max-files-reached.body", { count: this.max }),
        ),
      );

      return false;
    }

    return true;
  }

  validFile(file: File): boolean {
    /**
     * Check is dot file
     */
    if (FileHelper.isDotFile(file.name)) {
      return false;
    }

    /**
     * Check file size limit
     */
    if (Boolean(this.maxFileSize) && file.size > this.maxFileSize) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.file-size-limit.body", {
            limit: this.fileSizePipe.transform(this.maxFileSize),
          }),
        ),
      );

      return false;
    }

    /**
     * Check file type
     */
    if (Boolean(this.accept) && !FileHelper.isAccepted(file, this.accept)) {
      this.messageService.add(
        MessageHelper.createTextMessage(
          MessageSeverityEnum.SEVERITY_WARN,
          this.translateService.instant("COMPONENTS.file-upload.errors.wrong-type.title"),
          this.translateService.instant("COMPONENTS.file-upload.errors.wrong-type.body", {
            limit: this.fileSizePipe.transform(this.maxFileSize),
          }),
        ),
      );

      return false;
    }

    return true;
  }

  handleChange(event): void {
    const element = event.currentTarget as HTMLInputElement;
    if (element.files) {
      this.addFiles(element.files);
      element.value = ""; // Reset the input file element to allow the change event to trigger again for the same files
    }
  }

  // handleFilesDropped(files: FileList) {
  //   this.addFiles(files);
  // }

  handleFoldersUpload(uploadFolderState = null) {
    this.uploadFolders = uploadFolderState ? true : null;
    setTimeout(() => {
      this.input.nativeElement.click();
    }, 0);
  }

  private addFiles(files: FileList) {
    let newFiles = [];

    Array.from(files).forEach((file: File, index: number) => {
      if (this.validFile(file) && this.validMaxFiles(index)) {
        let existingFilenames = [
          ...this.uploads.map((upload: UploadState) => upload.file.name),
          ...newFiles.map((f: File) => f.name),
        ];
        const newFilename = FileHelper.generateAvailableName(file.name, existingFilenames);
        const newFile = new File([file], newFilename, { type: file.type });
        newFiles.push(newFile);
      }
    });
    this.onFilesAdded.emit(newFiles);
  }

  /**
   * Returns whether multiple files can be attached
   */
  get multiple() {
    return this.max !== null || this.max !== undefined || this.max > 1;
  }

  /**
   * Returns a well formatted value for [accept] HTML attribute
   */
  get htmlAccept() {
    return this.accept
      ? this.accept
          .map((accepted: string) => {
            if (Object.keys(FileHelper.FILE_TYPES).includes(accepted)) {
              let fileTypes = FileHelper.FILE_TYPES[accepted];
              return fileTypes.map((type: string) => (type.includes("*") ? type : "." + type)).join(",");
            }
            return accepted;
          })
          .join(",")
      : "";
  }
}
