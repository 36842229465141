import BaseModel from "@shared/models/base.model";

export default class PaymentMethodSepaDebitModel extends BaseModel {
  // MINIMIZE (default)
  public fingerprint: string | null = null;
  public last4: string | null = null;
  public bankCode: string | null = null;
  public branchCode: string | null = null;
  public country: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
