import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  { path: "", redirectTo: "inbox", pathMatch: "full" },
  { path: "billing", loadChildren: () => import("./billing/billing.module").then((m) => m.BillingModule) },
  {
    path: "units",
    loadChildren: () => import("./unit/unit.module").then((m) => m.UnitModule),
    canActivate: [],
  },
  {
    path: "user-settings",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [],
  },
  {
    path: "record",
    loadChildren: () => import("./record-standard/record-standard.module").then((m) => m.RecordStandardModule),
    // Guards are inside Record routing, to enable GUEST access
  },
  {
    path: "record-batch",
    loadChildren: () => import("./record-batch/record-batch.module").then((m) => m.RecordBatchModule),
    canActivate: [],
  },
  {
    path: "contacts",
    loadChildren: () => import("./contacts/contacts.module").then((m) => m.ContactsModule),
    canActivate: [],
  },
  {
    path: "templates",
    loadChildren: () => import("./templates/templates.module").then((m) => m.TemplatesModule),
    canActivate: [],
  },
  {
    path: "inbox",
    loadChildren: () => import("./inbox/inbox.module").then((m) => m.InboxModule),
  },
  {
    path: "notifications",
    loadChildren: () => import("./notifications/notifications.module").then((m) => m.NotificationsModule),
  },
  {
    path: "errors",
    loadChildren: () => import("./errors/errors.module").then((m) => m.ErrorsModule),
  },
  { path: "**", redirectTo: "inbox", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
