<div
  class="editor-container"
  [formGroup]="parentFormGroup"
  #editorContainer
  [style]="{
    '--ql-tooltip-label': '\'' + ('COMMON.editor.link-label' | translate : { default: 'Enter link' }) + '\'',
    '--ql-tooltip-action': '\'' + ('COMMON.editor.link-action' | translate : { default: 'Save' }) + '\'',
    '--ql-tooltip-edit': '\'' + ('COMMON.editor.link-edit' | translate : { default: 'Edit' }) + '\'',
    '--ql-tooltip-remove': '\'' + ('COMMON.editor.link-remove' | translate : { default: 'Remove' }) + '\''
  }"
>
  <quill-editor
    trackChanges="all"
    [modules]="editorModules"
    [bounds]="editorContainer"
    [placeholder]="placeholder"
    [readOnly]="readonly"
    [preserveWhitespace]="true"
    [sanitize]="false"
    [required]="true"
    [minLength]="1"
    [formControlName]="controlName"
    [trimOnValidation]="true"
    [linkPlaceholder]="'COMMON.editor.link-placeholder' | translate : { default: 'Enter link' }"
    [styles]="style"
    [classList]="classList ?? ''"
    [ngClass]="{ 'quill-disabled': readonly, 'focused': isFocused }"
    (onContentChanged)="handleContentChanged($event)"
    (onSelectionChanged)="handleSelectionChanged($event)"
    (onFocus)="handleFocus($event)"
    (onBlur)="handleBlur($event)"
    (onEditorCreated)="handleEditorCreated()"
    (paste)="handlePaste($event)"
  >
    <div class="flex p-1" id="toolbar" quill-editor-toolbar>
      <span class="ql-formats mr-0 w-full flex flex-column md:flex-row md:align-items-center">
        <!-- FORMATTING Actions -->
        <div class="flex flex-wrap mr-0 align-items-center">
          <!-- Insert bold -->
          <button
            type="button"
            tabindex="-1"
            class="ql-bold"
            *ngIf="enableLinkBold"
            [tooltip]="'COMMON.editor.link-bold' | translate"
            [attr.aria-label]="'COMMON.editor.link-bold' | translate"
            [delay]="600"
          ></button>
          <!-- Insert Italic -->
          <button
            type="button"
            tabindex="-1"
            class="ql-italic"
            *ngIf="enableLinkItalicize"
            [tooltip]="'COMMON.editor.link-italicize' | translate"
            [attr.aria-label]="'COMMON.editor.link-italicize' | translate"
            [delay]="600"
          ></button>
          <!-- Insert underline -->
          <button
            type="button"
            tabindex="-1"
            class="ql-underline"
            *ngIf="enableLinkUnderline"
            [tooltip]="'COMMON.editor.link-underline' | translate"
            [attr.aria-label]="'COMMON.editor.link-underline' | translate"
            [delay]="600"
          ></button>
          <!-- Insert Strikethrough -->
          <button
            type="button"
            tabindex="-1"
            class="ql-strike"
            *ngIf="enableLinkStrikeThrough"
            [tooltip]="'COMMON.editor.link-strike-through' | translate"
            [attr.aria-label]="'COMMON.editor.link-strike-through' | translate"
            [delay]="600"
          ></button>
          <p-divider styleClass="mx-2 p-0 block" layout="vertical" *ngIf="enableLinkLink"></p-divider>
          <!-- Insert link -->
          <button
            type="button"
            tabindex="-1"
            class="ql-link"
            *ngIf="enableLinkLink"
            [tooltip]="'COMMON.editor.link-link' | translate"
            [attr.aria-label]="'COMMON.editor.link-link' | translate"
            [delay]="600"
          ></button>
          <p-divider
            styleClass="mx-2 p-0 block"
            layout="vertical"
            *ngIf="enableLinkNumberedList || enableLinkBulletedList"
          ></p-divider>
          <!-- Insert ordered list -->
          <button
            type="button"
            tabindex="-1"
            class="ql-list"
            *ngIf="enableLinkNumberedList"
            value="ordered"
            [tooltip]="'COMMON.editor.link-numbered-list' | translate"
            [attr.aria-label]="'COMMON.editor.link-numbered-list' | translate"
            [delay]="600"
          ></button>
          <!-- Insert unordered list -->
          <button
            type="button"
            tabindex="-1"
            class="ql-list"
            *ngIf="enableLinkBulletedList"
            value="bullet"
            [tooltip]="'COMMON.editor.link-bulleted-list' | translate"
            [attr.aria-label]="'COMMON.editor.link-bulleted-list' | translate"
            [delay]="600"
          ></button>
          <p-divider styleClass="mx-2 p-0 block" *ngIf="enableLinkEmoji" layout="vertical"></p-divider>
          <!-- Emojis -->
          <button
            type="button"
            tabindex="-1"
            class="ql-emoji"
            *ngIf="enableLinkEmoji"
            [tooltip]="'COMMON.editor.link-emoji' | translate"
            [attr.aria-label]="'COMMON.editor.link-emoji' | translate"
            [delay]="600"
            (click)="handleEmojiToolbarClick($event)"
          >
            <svg viewBox="0 0 24 24">
              <rect fill="none" height="24" width="24" />
              <path
                class="ql-fill"
                d="M7,9.5C7,8.67,7.67,8,8.5,8S10,8.67,10,9.5c0,0.83-0.67,1.5-1.5,1.5S7,10.33,7,9.5z M12,17.5c2.33,0,4.31-1.46,5.11-3.5 H6.89C7.69,16.04,9.67,17.5,12,17.5z M15.5,11c0.83,0,1.5-0.67,1.5-1.5C17,8.67,16.33,8,15.5,8S14,8.67,14,9.5 C14,10.33,14.67,11,15.5,11z M22,1h-2v2h-2v2h2v2h2V5h2V3h-2V1z M20,12c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8 c1.46,0,2.82,0.4,4,1.08V2.84C14.77,2.3,13.42,2,11.99,2C6.47,2,2,6.48,2,12c0,5.52,4.47,10,9.99,10C17.52,22,22,17.52,22,12 c0-1.05-0.17-2.05-0.47-3h-2.13C19.78,9.93,20,10.94,20,12z"
              />
            </svg>
          </button>
        </div>

        <!-- CUSTOM ACTIONS -->
        <div class="flex md:ml-auto sm:mt-2 md:mt-0 md:gap-1">
          <!-- Add form items -->
          <button
            *ngIf="enableFormItems"
            type="button"
            tabindex="-1"
            class="ql-form-items flex-grow-1 md:flex-grow-0 w-auto md:px-2"
            [style]="{
              'background-color': 'rgba(var(--primary-color-rgb, 255, 255, 255), 0.04)',
              'color': 'var(--primary-color)',
              'border': '1px solid rgba(var(--primary-color-rgb, 255, 255, 255), 1 )',
              'height': '32px'
            }"
            [tooltip]="'COMMON.editor.link-form-items' | translate"
            [delay]="600"
            (click)="handleFormItemToolbarClick($event)"
          >
            <div class="flex align-items-center font-medium justify-content-center md:justify-content-start">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                class="hidden sm:block"
                [style]="{ 'fill': 'var(--primary-color)' }"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 17.5C8.51664 17.5 7.0666 17.0601 5.83323 16.236C4.59986 15.4119 3.63856 14.2406 3.07091 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53683C2.9335 7.08197 3.64781 5.7456 4.6967 4.6967C5.7456 3.64781 7.08197 2.9335 8.53683 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.07091C14.2406 3.63856 15.4119 4.59986 16.236 5.83323C17.0601 7.0666 17.5 8.51664 17.5 10C17.5 11.9891 16.7098 13.8968 15.3033 15.3033C13.8968 16.7098 11.9891 17.5 10 17.5ZM10 3.75C8.76387 3.75 7.5555 4.11656 6.52769 4.80332C5.49988 5.49008 4.6988 6.46619 4.22576 7.60823C3.75271 8.75027 3.62894 10.0069 3.8701 11.2193C4.11125 12.4317 4.70651 13.5453 5.58059 14.4194C6.45466 15.2935 7.56831 15.8888 8.78069 16.1299C9.99307 16.3711 11.2497 16.2473 12.3918 15.7743C13.5338 15.3012 14.5099 14.5001 15.1967 13.4723C15.8834 12.4445 16.25 11.2361 16.25 10C16.25 8.3424 15.5915 6.75269 14.4194 5.58059C13.2473 4.40848 11.6576 3.75 10 3.75Z"
                />
                <path
                  d="M10 13.9584C9.83491 13.9563 9.67719 13.8897 9.56045 13.773C9.4437 13.6562 9.37716 13.4985 9.375 13.3334V6.66675C9.375 6.50099 9.44085 6.34202 9.55806 6.22481C9.67527 6.1076 9.83424 6.04175 10 6.04175C10.1658 6.04175 10.3247 6.1076 10.4419 6.22481C10.5592 6.34202 10.625 6.50099 10.625 6.66675V13.3334C10.6228 13.4985 10.5563 13.6562 10.4396 13.773C10.3228 13.8897 10.1651 13.9563 10 13.9584Z"
                />
                <path
                  d="M13.3333 10.625H6.66666C6.5009 10.625 6.34192 10.5592 6.22471 10.4419C6.1075 10.3247 6.04166 10.1658 6.04166 10C6.04166 9.83424 6.1075 9.67527 6.22471 9.55806C6.34192 9.44085 6.5009 9.375 6.66666 9.375H13.3333C13.4991 9.375 13.6581 9.44085 13.7753 9.55806C13.8925 9.67527 13.9583 9.83424 13.9583 10C13.9583 10.1658 13.8925 10.3247 13.7753 10.4419C13.6581 10.5592 13.4991 10.625 13.3333 10.625Z"
                />
              </svg>
              <span class="inline-block sm:ml-1">
                {{ "COMMON.editor.link-form-items" | translate : { default: "Request" } }}
              </span>
            </div>
          </button>

          <!-- Load message templates -->
          <button
            *ngIf="enableFormItems && enableMessageTemplate"
            type="button"
            tabindex="-1"
            class="ql-message-templates flex-grow-1 md:flex-grow-0 w-auto md:px-2"
            [style]="{
              'background-color': 'rgba(var(--primary-color-rgb, 255, 255, 255), 0.04)',
              'color': 'var(--primary-color)',
              'border': '1px solid rgba(var(--primary-color-rgb, 255, 255, 255), 1 )',
              'height': '32px'
            }"
            [tooltip]="'COMMON.editor.link-templates' | translate"
            [delay]="600"
            (click)="handleMessageTemplateToolbarClick($event)"
          >
            <div class="flex align-items-center font-medium justify-content-center md:justify-content-start">
              <svg
                class="hidden sm:block"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2908_6397)">
                  <path
                    d="M4.99999 15.0001L15 15.0001M11.6667 8.33341L15 8.33341M11.6667 5.00008L15 5.00008M4.99999 11.6667L15 11.6667M6.59999 8.33341L6.73332 8.33341C7.29338 8.33341 7.5734 8.33341 7.78731 8.22442C7.97548 8.12855 8.12846 7.97557 8.22433 7.7874C8.33332 7.57349 8.33332 7.29347 8.33332 6.73341L8.33332 6.60008C8.33332 6.04003 8.33332 5.76 8.22433 5.54609C8.12846 5.35793 7.97548 5.20495 7.78731 5.10907C7.5734 5.00008 7.29338 5.00008 6.73332 5.00008L6.59999 5.00008C6.03994 5.00008 5.75991 5.00008 5.546 5.10907C5.35784 5.20495 5.20486 5.35793 5.10898 5.54609C4.99999 5.76 4.99999 6.04003 4.99999 6.60008L4.99999 6.73341C4.99999 7.29347 4.99999 7.57349 5.10898 7.7874C5.20486 7.97557 5.35784 8.12855 5.546 8.22442C5.75991 8.33341 6.03994 8.33341 6.59999 8.33341ZM9.66666 18.3334L10.3333 18.3334C13.1336 18.3334 14.5337 18.3334 15.6033 17.7884C16.5441 17.3091 17.309 16.5442 17.7884 15.6034C18.3333 14.5338 18.3333 13.1337 18.3333 10.3334L18.3333 9.66674C18.3333 6.86648 18.3333 5.46635 17.7884 4.39679C17.309 3.45598 16.5441 2.69108 15.6033 2.21171C14.5337 1.66675 13.1336 1.66675 10.3333 1.66675L9.66666 1.66675C6.86639 1.66675 5.46626 1.66675 4.3967 2.21171C3.45589 2.69108 2.69099 3.45598 2.21162 4.3968C1.66666 5.46635 1.66666 6.86649 1.66666 9.66675L1.66666 10.3334C1.66666 13.1337 1.66666 14.5338 2.21163 15.6034C2.69099 16.5442 3.4559 17.3091 4.39671 17.7884C5.46627 18.3334 6.8664 18.3334 9.66666 18.3334Z"
                    [style]="{ 'stroke': 'var(--primary-color)' }"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2908_6397">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span class="inline-block sm:ml-1">
                {{ "COMMON.editor.link-templates" | translate : { default: "Templates" } }}
              </span>
            </div>
          </button>

          <!-- Create signature -->
          <button
            *ngIf="enableSignature"
            tabindex="-1"
            class="ql-signature flex-grow-1 md:flex-grow-0 w-auto md:px-2"
            [style]="{
              'background-color': 'rgba(var(--primary-color-rgb, 255, 255, 255), 0.04)',
              'color': 'var(--primary-color)',
              'border': '1px solid rgba(var(--primary-color-rgb, 255, 255, 255), 1 )',
              'height': '32px'
            }"
            type="button"
            [tooltip]="'COMMON.editor.link-signature' | translate"
            [delay]="600"
            (click)="handleSignatureToolbarClick($event)"
          >
            <div class="flex align-items-center font-medium justify-content-center md:justify-content-start">
              <svg
                class="hidden sm:block"
                width="20px"
                height="20px"
                viewBox="0 0 48 48"
                [style]="{ 'fill': 'var(--primary-color)' }"
              >
                <path
                  [style]="{ 'stroke': 'var(--primary-color)' }"
                  stroke-width="0.5"
                  d="M28 42q-.6 0-1.05-.45-.45-.45-.45-1.05 0-.65.45-1.075Q27.4 39 28 39q2.95 0 4.975-1.2Q35 36.6 35 35q0-1.15-1.475-2.25t-3.975-1.7l2.35-2.35q3.15.95 4.625 2.625T38 35q0 3.35-3.05 5.175Q31.9 42 28 42ZM12 27.3q-3.2-.7-4.6-2.2Q6 23.6 6 22q0-1.75 1.3-3.15t6-3.1q3.3-1.2 4.25-1.95.95-.75.95-1.75 0-1.25-1.1-2.15Q16.3 9 14 9q-1.35 0-2.3.35-.95.35-1.7 1.1-.4.4-1.025.475Q8.35 11 7.85 10.6q-.55-.4-.575-1-.025-.6.375-1.05.85-1.1 2.55-1.825Q11.9 6 14 6q3.4 0 5.45 1.625Q21.5 9.25 21.5 12.05q0 2.05-1.425 3.475Q18.65 16.95 14.5 18.5q-3.35 1.25-4.425 1.975Q9 21.2 9 22q0 .8 1.35 1.525 1.35.725 4.05 1.375Zm24.8-7.7-6.4-6.4 2.25-2.25q.9-.9 2-.9t2 .9l2.4 2.4q.9.9.9 2t-.9 2ZM11 39h2.1l17.25-17.25-2.1-2.1L11 36.9Zm-3 3v-6.4l20.25-20.25 6.4 6.4L14.4 42Zm20.25-22.35 2.1 2.1Z"
                />
              </svg>
              <span class="inline-block">
                {{ "COMMON.editor.link-signature" | translate : { default: "Signature" } }}
              </span>
            </div>
          </button>
        </div>
      </span>
    </div>
  </quill-editor>
</div>

<p-overlayPanel #emojiPanel appendTo="body" styleClass="emoji-panel">
  <ng-template pTemplate="content">
    <emoji-mart
      (emojiClick)="handleAddEmoji($event)"
      [isNative]="true"
      [useButton]="true"
      color="var(--primary-color)"
      [emojiSize]="20"
      [i18n]="emojiTranslations$ | async"
    ></emoji-mart>
  </ng-template>
</p-overlayPanel>
