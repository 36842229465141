import { RecordMessageTemplateScopeEnum } from "@app/@shared/enums/record-message-template-scope.enum";
import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";
import { RecordMessageTemplateContentModel } from "./record-message-template/record-message-template-content.model";
import UserModel from "../user/user.model";

export default class RecordMessageTemplateModel extends BaseModel {
  // MINIMIZE (default)
  public templateClass: string | null = null;
  public templateIdentifier: string | null = null;
  public name: string | null = null;
  public description: string | null = null;
  public locale?: string;
  public scope?: RecordMessageTemplateScopeEnum = RecordMessageTemplateScopeEnum.USER;
  public createdAt?: Date | null = null;
  public updatedAt?: Date | null = null;
  public lastUpdatedAt?: Date | null = null;
  public deleted: boolean = false;

  public content: RecordMessageTemplateContentModel = new RecordMessageTemplateContentModel();
  public properties: JsonValue = {};

  // RECORD_MESSAGE_TEMPLATE_DETAILS
  public tags: string[] = [];
  public public: boolean = false;
  public owner: UserModel | null = null;
  public originalTemplate: RecordMessageTemplateModel | null = null;
  public duplicateCount: number | null = null;
  public metadata: JSONObject | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.updatedAt) this.updatedAt = DateHelper.parseDate(input.updatedAt);

    if (input.lastUpdatedAt) this.lastUpdatedAt = DateHelper.parseDate(input.lastUpdatedAt);

    if (input.content) this.content = new RecordMessageTemplateContentModel().deserialize(input.content);

    if (input.owner) this.owner = new UserModel().deserialize(input.owner);

    if (input.originalTemplate)
      this.originalTemplate = new RecordMessageTemplateModel().deserialize(input.originalTemplate);

    return this;
  }
}
