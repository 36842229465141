<p-table [value]="invalidContacts" styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="400px">
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{ "COMMON.fields.row.label" | translate : { default: "Row" } }}
      </th>
      <th *ngFor="let col of cols">
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-invalidContact>
    <tr>
      <td>{{ invalidContact.rowId }}</td>
      <td [ngClass]="{ 'invalid-contact-field': invalidContact?.invalidFields?.includes('emailAddress') }">
        <div class="flex align-items-center">
          <span class="clamp inline-block" *ngIf="invalidContact.emailAddress">
            {{ invalidContact.emailAddress }}
          </span>
          <i
            tooltipStyleClass="generic-tooltip"
            [pTooltip]="
              invalidContact.emailAddress
                ? ('ADDRESS-BOOK.contact-import.errors.email-address.invalid'
                  | translate : { 'default': 'Email address must be valid' })
                : ('ADDRESS-BOOK.contact-import.errors.email-address.required'
                  | translate : { 'default': 'Email address is required' })
            "
            class="pi pi-exclamation-circle text-red-500 ml-auto font-bold"
            *ngIf="invalidContact?.invalidFields?.includes('emailAddress')"
          ></i>
        </div>
      </td>
      <td>
        {{ invalidContact.firstName }}
      </td>
      <td>
        {{ invalidContact.lastName }}
      </td>
      <td [ngClass]="{ 'invalid-contact-field': invalidContact?.invalidFields?.includes('phoneNumber') }">
        <div class="flex align-items-center">
          <span class="clamp inline-block" *ngIf="invalidContact.phoneNumber">
            {{ invalidContact.phoneNumber }}
          </span>
          <i
            tooltipStyleClass="generic-tooltip"
            [pTooltip]="
              'ADDRESS-BOOK.contact-import.errors.phone-number.invalid'
                | translate : { 'default': 'Enter a valid international phone number' }
            "
            class="pi pi-exclamation-circle text-red-500 ml-auto font-bold"
            *ngIf="invalidContact?.invalidFields?.includes('phoneNumber')"
          ></i>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<!-- Button for doloading invalid contacts -->
<button
  type="button"
  pButton
  (click)="exportInvalidContacts()"
  [disabled]="!(invalidContacts?.length > 0)"
  class="p-button-sm mt-3 p-button-outlined"
  icon="pi pi-file-export"
  [label]="'ADDRESS-BOOK.manager.table.buttons.export-contacts-invalid' | translate : { default: 'Export contact' }"
></button>
