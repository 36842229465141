import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { UploadxModule } from "ngx-uploadx";
import { COMPONENTS } from "./components";
import { SessionModule } from "@app/session/session.module";
import { SharedModule } from "@app/@shared/shared.module";
import { UiModule } from "@ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CloudFileUploadListComponent } from "./components/file/cloud-file-upload-list/cloud-file-upload-list.component";
import { NgxOtpInputModule } from "ngx-otp-input";
import { NotificationsWithoutRoutingModule } from "@app/notifications/notifications-without-routing.module";
import { GetFileProviderIconPipe } from "@app/@shared/pipes/get-file-provider-icon.pipe";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    // Other modules
    PdfViewerModule,
    UploadxModule,
    NgxOtpInputModule,

    // Custom modules
    SessionModule,
    SharedModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationsWithoutRoutingModule,
  ],
  declarations: [COMPONENTS, CloudFileUploadListComponent],
  exports: [COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [GetFileProviderIconPipe],
})
export class MainModule {}
