import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import SessionModel from "@app/@shared/models/session/session.model";
import UserUnitModel from "@app/@shared/models/user/user-unit.model";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { BehaviorSubject } from "rxjs";

type MenuItemLabelType = {
  title: string;
  image: string;
};

@Component({
  selector: "session-unit-menu-item",
  templateUrl: "./unit-menu-item.component.html",
  styleUrls: ["./unit-menu-item.component.scss"],
})
export class UnitMenuItemComponent implements OnInit {
  @Input() userUnit: UserUnitModel;
  session$: BehaviorSubject<SessionModel> = new BehaviorSubject(undefined);

  @Output() onClick: EventEmitter<UserUnitModel> = new EventEmitter<UserUnitModel>();
  @Output() onShowWorkspaceOptions: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  image: string;

  constructor(public sessionAPIService: SessionAPIService, public authorizationService: AuthorizationService) {}

  ngOnInit() {
    this.sessionAPIService.session$.subscribe(this.session$);

    this.image = this.userUnit.unit.avatar
      ? `<img src="${this.userUnit.unit.avatar}" alt="${this.userUnit.unit.name}">`
      : `<span>${this.userUnit.unit.name[0].toUpperCase()}</span>`;
  }

  handleClick() {
    this.onClick.emit(this.userUnit);
  }

  showWorkspaceMenu(event: MouseEvent) {
    event.stopPropagation();
    this.onShowWorkspaceOptions.emit(event);
  }

  /**
   * Generate a customized menu item
   * @param menuItem
   * @returns An HTML string for the MenuItem label property
   */
  createMenuItemLabel(menuItem: MenuItemLabelType): string {
    const template = document.getElementById("unit-menu-label-template") as HTMLTemplateElement;
    const importedNode = document.importNode(template, true) as HTMLTemplateElement;
    const menuLabel = importedNode.content.querySelector(".menu-item").cloneNode(true) as HTMLElement;
    for (const [key, value] of Object.entries(menuItem)) {
      if (value) {
        menuLabel.querySelector(`.${key}`).innerHTML = value;
      } else {
        let element = menuLabel.querySelector(`.${key}`);
        if (element) element.remove();
      }
    }
    return menuLabel.outerHTML.toString();
  }
}
