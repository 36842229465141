import { Component, Input } from "@angular/core";
import { InvalidContactModel } from "@app/contacts/layouts/import/import.layout";
import { TranslateService } from "@ngx-translate/core";
import { Papa } from "ngx-papaparse";

type TableColumn = {
  field: string;
  header: string;
  classnames?: string;
};

@Component({
  selector: "contact-invalid-table",
  templateUrl: "./contact-invalid-table.component.html",
  styleUrls: ["./contact-invalid-table.component.scss"],
})
export class ContactInvalidTableComponent {
  @Input() invalidContacts: InvalidContactModel[];
  cols: TableColumn[];

  constructor(private translateService: TranslateService, private papaService: Papa) {}

  ngOnInit() {
    this.setColumns();
  }

  setColumns() {
    this.cols = [
      {
        field: "emailAddress",
        header: this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" }),
        classnames: "font-medium",
      },
      {
        field: "firstName",
        header: this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" }),
      },
      {
        field: "lastName",
        header: this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" }),
      },
      {
        field: "phoneNumber",
        header: this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" }),
      },
    ];
  }

  exportInvalidContacts() {
    let filename = "nestor_contacts_invalid.csv";
    const email = this.translateService.instant("COMMON.fields.email-address.label", { default: "emailAddress" });
    const first = this.translateService.instant("COMMON.fields.first-name.label", { default: "firstName" });
    const last = this.translateService.instant("COMMON.fields.last-name.label", { default: "lastName" });
    const phone = this.translateService.instant("COMMON.fields.phone-number.label", { default: "phoneNumber" });
    let contacts = this.invalidContacts.map((contact) => {
      return {
        [email]: contact.emailAddress,
        [first]: contact.firstName,
        [last]: contact.lastName,
        [phone]: contact.phoneNumber,
      };
    });
    let csv = this.papaService.unparse(contacts);
    let csvFile = new Blob([csv], { type: "text/csv" });
    let url = window.URL.createObjectURL(csvFile);
    let link = document.createElement("a");
    link.download = filename;
    link.href = url;
    link.rel = "noopener";

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        link.removeEventListener("click", clickHandler);
      }, 150);
    };
    link.addEventListener("click", clickHandler, false);
    link.click();
  }
}
