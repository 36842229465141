import { Component, OnDestroy, OnInit } from "@angular/core";
import UserModel from "@app/@shared/models/user/user.model";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { Observable } from "rxjs";
import { RecordService } from "@app/record/services/record.service";
import RecordModel from "@app/@shared/models/record/record.model";
import RecordEventModel from "@app/@shared/models/record/record-event.model";

@Component({
  selector: "record-events",
  templateUrl: "./record-events.component.html",
  styleUrls: ["./record-events.component.scss"],
})
export class RecordEventsComponent implements OnInit, OnDestroy {
  currentUser$: Observable<UserModel>;
  events$: Observable<RecordEventModel[]>;
  record$: Observable<RecordModel>;

  constructor(public sessionAPIService: SessionAPIService, private recordService: RecordService) {
    this.currentUser$ = sessionAPIService.currentUser$;

    this.record$ = this.recordService.record$;
    this.events$ = this.recordService.events$;
  }

  ngOnInit(): void {}

  ngOnDestroy() {}
}
