import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import RecordModel from "@shared/models/record/record.model";
import { RecordAPIService } from "@app/@shared/services/record-api.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { ResponseLevelEnum } from "@shared/enums/response-level.enum";
import { OverlayPanel } from "primeng/overlaypanel";
import { finalize } from "rxjs";
import { RecordAuthorizationService } from "@app/@shared/services/record-authorization.service";
import { DomainAPIService } from "@app/@shared/services/domain-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConfigurationClassEnum } from "@app/@shared/enums/configuration-class.enum";
import ConfigurationModel from "@app/@shared/models/masterdata/configuration.model";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import { TagsFormComponent } from "@app/main/components";
import { ThemingService } from "@app/@shared/services/theming.service";

@UntilDestroy()
@Component({
  selector: "record-tags",
  templateUrl: "./record-tags.component.html",
  styleUrls: ["./record-tags.component.scss"],
})
export class RecordTagsComponent implements OnInit, OnDestroy {
  _record: RecordModel;
  maxDisplayedTags: number = 1;
  isTagsPanelOpen = false; // Used only for styling
  @ViewChild("tagsPanel") tagsPanel: OverlayPanel;
  @ViewChild("tagsForm") tagsForm: TagsFormComponent;

  @Output() recordChange = new EventEmitter<RecordModel>();
  @Input()
  set record(record: RecordModel) {
    this._record = record;
    this.recordChange.emit(record);
  }
  get record(): RecordModel {
    return this._record;
  }

  @Input() editedTags: string[] = [];
  isEditing: boolean = false;
  showTagCreationModal: boolean = false;
  minRoleForManagingTags: RecordRoleEnum.OWNER | RecordRoleEnum.REVIEWER = RecordRoleEnum.OWNER;

  constructor(
    private recordAPIService: RecordAPIService,
    public sessionAPIService: SessionAPIService,
    private recordAuthorizationService: RecordAuthorizationService,
    private domainAPIService: DomainAPIService,
    public themingService: ThemingService,
  ) {}

  ngOnInit() {
    this.editedTags = [...this.record.tags];
    this.domainAPIService
      .getConfiguration(this.sessionAPIService.unit.unitSlug, ConfigurationClassEnum.CONVERSATION_TAGS_MIN_ROLE)
      .pipe(untilDestroyed(this))
      .subscribe((configuration: ConfigurationModel) => {
        this.minRoleForManagingTags = configuration?.value?.["minRole"] ?? RecordRoleEnum.OWNER;
      });
  }

  ngOnDestroy() {}

  get authorizeTagEdition() {
    if (this.record.isClosed) {
      return false;
    }

    if (this.minRoleForManagingTags) {
      return this.recordAuthorizationService.hasMinRole(this.record, this.minRoleForManagingTags);
    }

    return this.recordAuthorizationService.isOwner(this.record) && !this.record.isClosed;
  }

  handleTogglePanel($event: MouseEvent) {
    if (!this.authorizeTagEdition) return;
    this.tagsPanel.toggle($event);
  }

  handleShowPanel() {
    setTimeout(() => {
      this.isTagsPanelOpen = true;
    }, 150);
  }
  // Investigate https://github.com/primefaces/primeng/issues/12933
  // Happened once but could not reproduce
  handleHidePanel() {
    setTimeout(() => {
      this.isTagsPanelOpen = false;
    }, 150);
  }

  handleTagsChange(tags: string[]) {
    let haveTagsChanged = !(
      this.record.tags?.length === tags.length && this.record?.tags.every((tag) => tags.includes(tag))
    );
    // Update with new changes
    if (haveTagsChanged) {
      this.isEditing = true;
      this.recordAPIService
        .updateRecordAttributes(this.record.recordIdentifier, { tags }, [
          ResponseLevelEnum.MINIMIZE,
          ResponseLevelEnum.RECORD_TAGS,
        ])
        .pipe(
          finalize(() => {
            this.isEditing = false;
          }),
        )
        .subscribe((record) => {
          this.record.tags = record.tags;
          this.editedTags = record.tags;
          this.isEditing = false;
        });
    }
  }
}
