import { Injectable } from "@angular/core";
import { RecordStatusEnum } from "@app/@shared/enums/record-status.enum";
import { ResponseLevelEnum } from "@app/@shared/enums/response-level.enum";
import AnswerRecordMessageRequestModel from "@app/@shared/models/record/answer-record-message-request.model";
import RecordEventModel from "@app/@shared/models/record/record-event.model";
import RecordMessageAnswerModel from "@app/@shared/models/record/record-message-answer.model";
import RecordMessageAttachmentModel from "@app/@shared/models/record/record-message-attachment.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import RecordReminderPropertiesModel from "@app/@shared/models/record/record-reminder-properties.model";
import RecordModel from "@app/@shared/models/record/record.model";
import { RecordAPIService } from "@app/@shared/services/record-api.service";
import { Logger } from "@core";
import { BehaviorSubject, filter, map, Observable, switchMap, tap } from "rxjs";

const log = new Logger("Record/RecordService");

@Injectable({
  providedIn: "root",
})
export class RecordService {
  /**
   * Record observable
   */
  private _record$: BehaviorSubject<RecordModel> = new BehaviorSubject(undefined);
  public record$: Observable<RecordModel> = this._record$.asObservable();
  get record(): RecordModel {
    return this._record$.getValue();
  }
  set record(value: RecordModel) {
    this._record$.next(value);
  }

  /**
   * Record members observables
   */
  private _recipients$: BehaviorSubject<RecordRecipientModel[]> = new BehaviorSubject([]);
  public recipients$: Observable<RecordRecipientModel[]> = this._recipients$.asObservable();
  get recipients(): RecordRecipientModel[] {
    return this._recipients$.getValue();
  }
  set recipients(value: RecordRecipientModel[]) {
    this._recipients$.next(value);
  }

  private _messages$: BehaviorSubject<RecordMessageModel[]> = new BehaviorSubject([]);
  public messages$: Observable<RecordMessageModel[]> = this._messages$.asObservable();
  get messages(): RecordMessageModel[] {
    return this._messages$.getValue();
  }
  set messages(value: RecordMessageModel[]) {
    this._messages$.next(value);
  }

  private _events$: BehaviorSubject<RecordEventModel[]> = new BehaviorSubject([]);
  public events$: Observable<RecordEventModel[]> = this._events$.asObservable();
  get events(): RecordEventModel[] {
    return this._events$.getValue();
  }
  set events(value: RecordEventModel[]) {
    this._events$.next(value);
  }

  private _attachments$: BehaviorSubject<RecordMessageAttachmentModel[]> = new BehaviorSubject([]);
  public attachments$: Observable<RecordMessageAttachmentModel[]> = this._attachments$.asObservable();
  get attachments(): RecordMessageAttachmentModel[] {
    return this._attachments$.getValue();
  }
  set attachments(value: RecordMessageAttachmentModel[]) {
    this._attachments$.next(value);
  }

  private _formItems$: BehaviorSubject<RecordMessageFormItemModel[]> = new BehaviorSubject([]);
  public formItems$: Observable<RecordMessageFormItemModel[]> = this._formItems$.asObservable();
  get formItems(): RecordMessageFormItemModel[] {
    return this._formItems$.getValue();
  }
  set formItems(value: RecordMessageFormItemModel[]) {
    this._formItems$.next(value);
  }

  /**
   * Operationnal & processing observables
   */
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoading$ = this._isLoading$.asObservable();

  private _isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSaving$ = this._isSaving$.asObservable();

  constructor(private recordAPIService: RecordAPIService) {
    this.record$.pipe(filter((record: RecordModel) => Boolean(record))).subscribe((record: RecordModel) => {
      this.recipients = record.recipients;
      this.messages = record.messages;
      this.events = record.events;

      // Set all form items
      this.formItems = record.messages.flatMap((message) => message.formItems);

      // Set all attachments
      this.attachments = record.messages.flatMap((messages) => messages.attachments);
    });
  }

  /**
   * Get record from API
   */
  getRecord(
    recordIdentifier: string,
    triggerEvents: boolean = false,
    triggerRead: boolean = false,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<RecordModel> {
    return this.recordAPIService.getRecord(recordIdentifier, triggerEvents, triggerRead, responseLevels).pipe(
      tap((record: RecordModel) => {
        this.record = record;
      }),
      switchMap(() => this._record$),
    );
  }

  /**
   * Reload current record
   */
  reloadRecord(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]) {
    return this.getRecord(this.record.recordIdentifier, false, false, responseLevels);
  }

  /**
   * Update record status
   */
  updateRecordStatus(status: RecordStatusEnum, responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]) {
    this._isSaving$.next(true);
    return this.recordAPIService.updateRecordStatus(this.record.recordIdentifier, status, responseLevels).pipe(
      tap((record: RecordModel) => (this.record = record)),
      tap(() => this._isSaving$.next(false)),
      switchMap(() => this._record$),
    );
  }

  /**
   * Update record attributes
   */
  updateRecordAttributes(
    params: {
      title?: string;
      expirationDate?: Date;
      locale?: string;
      reminderProperties?: RecordReminderPropertiesModel;
      tags?: string[];
    },
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ) {
    this._isSaving$.next(true);
    return this.recordAPIService.updateRecordAttributes(this.record.recordIdentifier, params, responseLevels).pipe(
      tap((record: RecordModel) => (this.record = record)),
      tap(() => this._isSaving$.next(false)),
      switchMap(() => this._record$),
    );
  }

  /**
   * Close record
   */
  closeRecord(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]) {
    this._isSaving$.next(true);
    return this.recordAPIService.closeRecord(this.record.recordIdentifier, responseLevels).pipe(
      tap((record: RecordModel) => (this.record = record)),
      tap(() => this._isSaving$.next(false)),
      switchMap(() => this._record$),
    );
  }

  /**
   * Unarchive record
   */
  unarchiveRecord(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]) {
    this._isSaving$.next(true);
    return this.recordAPIService.unarchiveRecord(this.record.recordIdentifier, responseLevels).pipe(
      tap((record: RecordModel) => (this.record = record)),
      tap(() => this._isSaving$.next(false)),
      switchMap(() => this._record$),
    );
  }

  /**
   * Cancel record
   */
  cancelRecord(responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL]) {
    this._isSaving$.next(true);
    return this.recordAPIService.cancelRecord(this.record.recordIdentifier, responseLevels).pipe(
      tap((record: RecordModel) => (this.record = record)),
      tap(() => this._isSaving$.next(false)),
      switchMap(() => this._record$),
    );
  }

  /**
   * Add a message to a record
   * For now, it reloads the whole record afterwards
   */
  addRecordMessage(
    parentMessageIdentifier: string,
    message: RecordMessageModel,
    responseLevels: ResponseLevelEnum[] = [],
  ): Observable<RecordMessageModel> {
    this._isSaving$.next(true);
    return this.recordAPIService
      .addRecordMessage(this.record.recordIdentifier, parentMessageIdentifier, message, responseLevels)
      .pipe(
        tap(() => this._isSaving$.next(false)),
        switchMap((message: RecordMessageModel) =>
          this.getRecord(this.record.recordIdentifier, false, true, [ResponseLevelEnum.ALL]).pipe(map(() => message)),
        ),
      );
  }

  /**
   * Add an attachment to a record's message
   * For now, it reloads the whole record afterwards
   */
  addRecordAttachment(
    messageIdentifier: string,
    attachment: RecordMessageAttachmentModel,
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageModel> {
    this._isSaving$.next(true);
    return this.recordAPIService
      .addRecordAttachment(this.record.recordIdentifier, messageIdentifier, attachment, responseLevels)
      .pipe(tap(() => this._isSaving$.next(false)));
  }

  addRecordAttachments(
    messageIdentifier: string,
    attachments: RecordMessageAttachmentModel[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.MINIMIZE],
  ): Observable<RecordMessageAttachmentModel[]> {
    this._isSaving$.next(true);
    return this.recordAPIService
      .addRecordAttachments(this.record.recordIdentifier, messageIdentifier, attachments, responseLevels)
      .pipe(
        tap(() => this._isSaving$.next(false)),
        switchMap((attachments: RecordMessageAttachmentModel[]) =>
          this.getRecord(this.record.recordIdentifier, false, true, [ResponseLevelEnum.ALL]).pipe(
            map(() => attachments),
          ),
        ),
      );
  }

  /**
   * Answer a record's message
   * For now, it reloads the whole record afterwards
   */
  answerRecordMessage(
    parentMessageIdentifier: string,
    answerMessage: RecordMessageModel,
    answers: RecordMessageAnswerModel[] = [],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<RecordMessageModel> {
    let answerRequest: AnswerRecordMessageRequestModel = new AnswerRecordMessageRequestModel();
    answerRequest.answers = answers;
    answerRequest.message = answerMessage;
    answerRequest.parentMessageIdentifier = parentMessageIdentifier;
    answerRequest.recordIdentifier = this.record.recordIdentifier;

    this._isSaving$.next(true);
    return this.recordAPIService
      .answerRecordMessage(answerRequest, responseLevels)
      .pipe(tap(() => this._isSaving$.next(false)));
  }

  /**
   * Add recipient to record
   * For now, it reloads the whole record afterwards
   */
  addRecordRecipients(
    recipients: RecordRecipientModel[],
    responseLevels: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ): Observable<RecordRecipientModel[]> {
    this._isSaving$.next(true);
    return this.recordAPIService.addRecordRecipients(this.record.recordIdentifier, recipients, responseLevels).pipe(
      tap(() => this._isSaving$.next(false)),
      switchMap((recipients: RecordRecipientModel[]) =>
        this.getRecord(this.record.recordIdentifier, false, false, [ResponseLevelEnum.ALL]).pipe(map(() => recipients)),
      ),
    );
  }

  /**
   * Remove recipients from record
   * For now, it reloads the whole record afterwards
   */
  removeRecordRecipients(
    recipients: RecordRecipientModel[],
    responseLevel: ResponseLevelEnum[] = [ResponseLevelEnum.ALL],
  ) {
    this._isSaving$.next(true);
    return this.recordAPIService.removeRecordRecipients(this.record.recordIdentifier, recipients, responseLevel).pipe(
      tap(() => this._isSaving$.next(false)),
      switchMap((done: boolean) =>
        this.getRecord(this.record.recordIdentifier, false, false, [ResponseLevelEnum.ALL]).pipe(map(() => done)),
      ),
    );
  }

  /**
   * Validate record form answers
   */
  validateAnswers(
    answerIdentifier: string,
    answerIdentifiers: string[],
    messageIdentifier: string,
    recordIdentifier: string,
  ) {
    this._isSaving$.next(true);
    return this.recordAPIService
      .validateAnswers(answerIdentifier, answerIdentifiers, messageIdentifier, recordIdentifier)
      .pipe(
        tap(() => this._isSaving$.next(false)),
        switchMap((message: RecordMessageModel) =>
          this.getRecord(this.record.recordIdentifier, false, false, [ResponseLevelEnum.ALL]).pipe(map(() => message)),
        ),
      );
  }

  /**
   * Reject record form itrem answers
   */
  rejectAnswers(
    answerIdentifier: string,
    answerIdentifiers: string[],
    messageIdentifier: string,
    recordIdentifier: string,
    validationMessage: string,
  ) {
    this._isSaving$.next(true);

    return this.recordAPIService
      .rejectAnswers(answerIdentifier, answerIdentifiers, messageIdentifier, recordIdentifier, validationMessage)
      .pipe(
        tap(() => this._isSaving$.next(false)),
        switchMap((message: RecordMessageModel) =>
          this.getRecord(this.record.recordIdentifier, false, false, [ResponseLevelEnum.ALL]).pipe(map(() => message)),
        ),
      );
  }
}
