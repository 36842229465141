import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { v4 as v4 } from "uuid";

@Component({
  selector: "record-form-item-format-textarea",
  templateUrl: "./item-format-textarea.component.html",
  styleUrls: ["./item-format-textarea.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatTextareaComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatTextareaComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Textarea options
  @Input() autoResize: boolean = false;
  @Input() rows: number = 5; // Needed for autoresize
  @Input() cols: number = 50; // Needed for autoresize

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() placeholder: string = "";
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: any = null;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;

    // Retrieve placeholder
    if (this.formItem.getPlaceholders()) {
      let placeholder = this.formItem.getPlaceholder(this.i18nService.language);
      placeholder && (this.placeholder = placeholder);
    }
  }

  handleChange(value) {
    this.onModelTouched();
    this.onModelChange(value);
    this.writeValue(value);
    this.onChange.emit(value);
  }
}
