import { Pipe, PipeTransform } from "@angular/core";
import { ProviderApplicationTypeEnum } from "../enums/provider-application-type.enum";

@Pipe({
  name: "getFileProviderIcon",
})
export class GetFileProviderIconPipe implements PipeTransform {
  transform(provider: ProviderApplicationTypeEnum) {
    switch (provider) {
      case ProviderApplicationTypeEnum.MICROSOFT:
        return "/assets/images/providers/microsoft.svg";
      case ProviderApplicationTypeEnum.GOOGLE:
        return "/assets/images/providers/google.svg";
      default:
        return "/assets/images/logo-colored-icon.svg";
    }
  }
}
