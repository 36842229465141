import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";
import { UploadxService } from "ngx-uploadx";
import { FileWithPath, fromEvent } from "file-selector";

@Directive({ selector: "[uploadDrop]" })
export class UploadDropDirective {
  @HostBinding("class.uploadx-drop-active") active = false;

  @Output() filesDropped: EventEmitter<(FileWithPath | DataTransferItem)[]> = new EventEmitter<
    (FileWithPath | DataTransferItem)[]
  >();
  @Output() filesDraggedOver: EventEmitter<any> = new EventEmitter();
  @Output() filesDraggedLeave: EventEmitter<any> = new EventEmitter();

  constructor(private uploadService: UploadxService) {}

  @HostListener("drop", ["$event"])
  async dropHandler(event: DragEvent): Promise<void> {
    // https://github.com/react-dropzone/file-selector/blob/master/src/file-selector.ts
    // event not assigned to a variable, function will run a reader that will flatten files from a directory
    // into an array of files. Directories are now supported in dropzone and button
    const dataTransfer = await fromEvent(event);

    this._stopEvents(event);
    this.active = false;
    if (dataTransfer && dataTransfer.length) {
      this.filesDropped.emit(dataTransfer);
    }
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent): void {
    this._stopEvents(event);
    if (event.dataTransfer && event.dataTransfer.types.length > 0) {
      event.dataTransfer.dropEffect = "copy";
      this.active = true;
      this.filesDraggedOver.emit();
    }
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave(event: DragEvent): void {
    this._stopEvents(event);
    this.active = false;
    this.filesDraggedLeave.emit();
  }

  protected _stopEvents(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
