import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FilesizeConfig {
  FILESIZE_OPTIONS = {
    "fr-FR": {
      symbols: {
        B: "octets",
        kB: "ko",
        MB: "Mo",
        GB: "Go",
        TB: "To",
        PB: "Po",
        EB: "Eo",
        ZB: "Zo",
        YB: "Yo",
      },
      round: 2,
      base: 2,
      standard: "jedec",
    },
    "en-GB": {
      symbols: {
        B: "bytes",
        kB: "kB",
        MB: "MB",
        GB: "GB",
        TB: "TB",
        PB: "PB",
        EB: "EB",
        ZB: "ZB",
        YB: "YB",
      },
      round: 2,
      base: 2,
      standard: "jedec",
    },
  };

  getConfig(locale?: string) {
    if (locale && Object.keys(this.FILESIZE_OPTIONS).includes(locale)) {
      return this.FILESIZE_OPTIONS[locale];
    } else {
      return this.FILESIZE_OPTIONS["en-GB"];
    }
  }
}
