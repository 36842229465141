import PaginationModel from "@shared/models/pagination.model";
import ContactModel from "./contact.model";

export default class ContactsResultModel extends PaginationModel {
  public contacts: ContactModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.contacts && Array.isArray(input.contacts))
      this.contacts = input.contacts.map((contact: any) => new ContactModel().deserialize(contact));

    return this;
  }
}
