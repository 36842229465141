import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import FileModel from "@shared/models/file/file.model";
import { FilesAPIService } from "@app/@shared/services/files-api.service";
import { KeycloakService } from "keycloak-angular";
import RecordModel from "@shared/models/record/record.model";
import { FileHelper } from "@app/@shared/helpers/file.helper";
import { MenuItem, MessageService } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { CloudAPIService } from "@shared/services/cloud-api.service";
import { UntilDestroy, untilDestroyed } from "@core";
import { MessageSeverityEnum } from "@app/@shared/enums/message-severity.enum";
import { Menu } from "primeng/menu";
import { AuthorizationService } from "@app/@shared/services/authorization.service";
import { filter } from "rxjs";
import { FilePreviewComponent } from "../file-preview/file-preview.component";
import { environment } from "@env/environment";
import { ThemingService } from "@app/@shared/services/theming.service";
import ProviderItemModel from "@app/@shared/models/providers/provider-item.model";
import { ProviderGatewayService } from "@app/@shared/services/providers/provider-gateway.service";
import { ProviderItemTypeEnum } from "@app/@shared/enums/provider-item-type.enum";

@UntilDestroy()
@Component({
  selector: "main-file-download",
  templateUrl: "./file-download.component.html",
  styleUrls: ["./file-download.component.scss"],
})
export class FileDownloadComponent implements OnInit, OnDestroy {
  @ViewChild(Menu) menu: Menu;
  @ViewChild(FilePreviewComponent) filePreviewer: FilePreviewComponent;

  _file: FileModel;
  @Output() fileChange = new EventEmitter<FileModel>();
  @Input()
  set file(file: FileModel) {
    this._file = file;
    this.fileChange.emit(file);
  }
  get file(): FileModel {
    return this._file;
  }

  @Input() record: RecordModel;
  @Input() isSignable: boolean = false;
  @Input() displayDate: boolean = false;
  @Input() showValidationBadge: boolean = false;
  @Input() isValidated: boolean = null;
  displayCloudFolderPickerModal: boolean = false;
  displayProviderFolderPickerModal: boolean = false;
  downloadActions: MenuItem[];
  isLoading: boolean = false;
  isPreviewable: boolean = false;
  token: string;

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService,
    private keycloakService: KeycloakService,
    private filesApiService: FilesAPIService,
    private providerGatewayService: ProviderGatewayService,
    private authorizationService: AuthorizationService,
    public themingService: ThemingService,
  ) {}

  ngOnInit(): void {
    this.keycloakService.getToken().then((token) => {
      this.token = token;
    });
    this.isPreviewable = FileHelper.isPreviewable(this.file.mimeType);

    this.authorizationService.initialized$
      .pipe(
        filter((initialized) => Boolean(initialized)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.setDownloadActions();
      });
  }

  setDownloadActions() {
    this.downloadActions = [
      {
        label: this.translateService.instant("FILES.actions.preview", { default: "Preview" }),
        command: () => this.doPreview(),
        icon: "pi pi-eye text-color-secondary text-xl",
        visible: this.isPreviewable,
      },
      {
        label: this.translateService.instant("FILES.actions.download", { default: "Download" }),
        command: () => this.doDownload(),
        icon: "pi pi-download text-color-secondary text-xl",
      },
      {
        label: this.translateService.instant("FILES.actions.export-to-cloud", { default: "Export to cloud" }),
        command: () => (this.displayCloudFolderPickerModal = true),
        icon: "pi pi-cloud-upload text-color-secondary text-xl",
        visible: this.authorizationService.canAccessNestorDrive(),
      },
      {
        label: this.translateService.instant("FILES.actions.export-to-provider", { default: "Export to Onedrive" }),
        command: () => (this.displayProviderFolderPickerModal = true),
        icon: "pi pi-globe text-color-secondary text-xl",
        visible: this.authorizationService.isPaidUser(),
      },
    ];
  }

  showActions(event: Event) {
    event.stopPropagation();
    if (this.menu) {
      this.menu.toggle(event);
    }
  }

  handleClick() {
    if (this.isPreviewable) {
      this.doPreview();
    } else {
      this.doDownload();
    }
  }

  doDownload() {
    this.filesApiService.recordDownloadFile(this.file, this.record.recordIdentifier).subscribe((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = url;
      link.rel = "noopener";
      link.download = this.file.name || "download";

      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          link.removeEventListener("click", clickHandler);
        }, 150);
      };
      link.addEventListener("click", clickHandler, false);
      link.click();
    });
  }

  doPreview() {
    if (this.filePreviewer) {
      this.filePreviewer.open(this.getDownloadUrl(), this.file.name);
    } else {
      this.filesApiService.recordDownloadFile(this.file, this.record.recordIdentifier).subscribe((response) => {
        const url = window.URL.createObjectURL(response);
        let newTab = window.open(url);
        let fileName = this.file.name;

        setTimeout(function () {
          newTab.document.title = fileName;
        }, 10);
      });
    }
  }

  getDownloadUrl(): string {
    const url = new URL(
      environment.services.baseUrls.filesApiUrl +
        environment.services.methodUrls.files.recordDownload.replace("{fileIdentifier}", this.file.fileIdentifier),
    );
    url.searchParams.set("recordIdentifier", this.record.recordIdentifier);
    url.searchParams.set("access_token", this.token);
    url.searchParams.set("disposition", "inline");
    return url.toString();
  }

  handleCloudFolderPickerSubmitted(resource: ProviderItemModel) {
    this.isLoading = true;
    this.providerGatewayService
      .upload(this.file.fileIdentifier, this.record.recordIdentifier, resource)
      .pipe(untilDestroyed(this))
      .subscribe((done: boolean) => {
        this.isLoading = false;

        if (resource.type === ProviderItemTypeEnum.NESTOR_DRIVE_ITEM) {
          this.handleCloudFolderPickerClose();
          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_SUCCESS,
            summary: this.translateService.instant("FILES.export-to-cloud.messages.success.title", {
              "default": "Exported to Nestor Drive",
            }),
            detail: this.translateService.instant("FILES.export-to-cloud.messages.success.body", {
              "default": "Your file was exported to Nestor Drive",
            }),
          });
        } else {
          this.handleProviderFolderPickerClose();

          this.messageService.add({
            severity: MessageSeverityEnum.SEVERITY_SUCCESS,
            summary: this.translateService.instant("FILES.export-to-provider.messages.success.title", {
              "default": "Exported successfully",
            }),
            detail: this.translateService.instant("FILES.export-to-provider.messages.success.body", {
              "default": "Your file was exported to your Drive",
            }),
          });
        }
      });
  }

  handleCloudFolderPickerClose() {
    this.displayCloudFolderPickerModal = false;
  }

  handleProviderFolderPickerClose() {
    this.displayProviderFolderPickerModal = false;
  }

  ngOnDestroy() {}
}
