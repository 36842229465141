import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";
import { ProviderApplicationTypeEnum } from "@app/@shared/enums/provider-application-type.enum";
import { ProviderCategoryEnum } from "@app/@shared/enums/provider-category.enum";

export default class ProviderApplicationModel extends BaseModel {
  public applicationIdentifier: string | null = null;
  public metadata: Object = {};
  public provider: ProviderApplicationTypeEnum | null = null;

  public scopes: string[] = [];
  public categories: ProviderCategoryEnum[] = [];

  public createdAt: Date | null = null;
  public updatedAt: Date | null = null;

  public revokedAt: Date | null = null;
  public revoked: boolean = false;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.updatedAt) this.updatedAt = DateHelper.parseDate(input.updatedAt);

    if (input.revokedAt) this.revokedAt = DateHelper.parseDate(input.revokedAt);

    return this;
  }
}
