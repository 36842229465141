import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ui-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
})
export class BadgeComponent implements OnInit {
  @Input() variant: string = "info";
  @Input() label: string;
  @Input() size: "small" | "base" = "base";
  @Input() prefix = "";
  @Input() hoverable: boolean = false;
  @Input() icon: string = null;
  @Input() cssMaxWidth: string = "null"; // Provide a CSS max-width value (ie: 20ch, 20px etc...)

  constructor() {}

  ngOnInit(): void {}
}
