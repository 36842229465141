import { PhoneNumber, PhoneNumberUtil } from "google-libphonenumber";
import { isEmpty } from "lodash";

export class StringHelper {
  constructor() {}

  static splitClassValue(value: string): string[] {
    return value.split(".");
  }

  static normalizeClass(...args: string[]): string {
    return args.reduce((classes: string[], arg: string) => [...classes, arg], []).join(".");
  }

  static isEmail(value: string): boolean {
    const EMAIL_VALIDATOR = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    return EMAIL_VALIDATOR.test(value);
  }

  static isEmpty(value: string): boolean {
    return value == null || value == "";
  }

  static escapeHtml(value: string) {
    return value
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  static isBlank(value: string) {
    if (value === null || value === undefined) return true;
    const noBlankString = value.replace(" ", "");
    return isEmpty(noBlankString);
  }

  static isEditorEmpty(value: string) {
    if (value === null || value === undefined) return true;
    return this.trimEditorContent(value).length === 0;
  }

  static trimEditorContent(value: string) {
    return value.replaceAll("\n", "").replaceAll("\t", "").trim();
  }
}
