import BaseModel from "@shared/models/base.model";

export default class PromotionCodeModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public code: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    return this;
  }
}
