<ng-container *ngIf="attachments">
  <div class="flex flex-column gap-3">
    <ng-container *ngIf="attachments.length > 0; else attachmentsEmpty">
      <p-table
        [value]="attachments"
        [(selection)]="selectedAttachments"
        selectionMode="multiple"
        dataKey="attachmentIdentifier"
        styleClass="record-attachments-table dialog-nested-content"
      >
        <ng-template pTemplate="caption">
          <div class="flex align-items-center">
            <span class="py-2 px-2">
              <p-tableHeaderCheckbox #headerCheckbox></p-tableHeaderCheckbox>
            </span>
            <span class="text-color-secondary text-sm font-normal" (click)="headerCheckbox.onClick($event)">
              <ng-container *ngIf="selectedAttachments.length > 0; else attachmentsOptionEmptyState">
                {{
                  "FILES.selection.count"
                    | translate : { default: "{count} files selected", count: selectedAttachments.length }
                }}
              </ng-container>
              <ng-template #attachmentsOptionEmptyState>
                {{ "FILES.selection.prompt" | translate : { default: "Select files to see options" } }}
              </ng-template>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="body" let-attachment>
          <tr style="background-color: transparent">
            <td #checkbox class="w-1rem py-0 px-2">
              <p-tableCheckbox [value]="attachment"></p-tableCheckbox>
            </td>
            <td class="p-0" style="--surface-overlay: transparent">
              <main-file-download
                [file]="attachment.file"
                [record]="record$ | async"
                [displayDate]="true"
              ></main-file-download>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="dialog-nested-footer flex flex-wrap align-items-center justify-content-between gap-2">
        <button
          pButton
          [label]="'COMMON.buttons.back-to-conversation' | translate : { 'default': 'Back to conversation' }"
          icon="pi pi-arrow-left"
          type="button"
          (click)="cancel()"
          class="p-button-outlined p-button-secondary p-button-sm hidden md:flex"
        ></button>
        <p-splitButton
          appendTo="body"
          styleClass="p-button-sm p-button-secondary w-full md:w-auto"
          class="w-full md:w-auto"
          [label]="'FILES.actions.download-selection' | translate : { default: 'Download selection' }"
          icon="pi pi-download"
          (onClick)="handleDownloadSelection()"
          menuStyleClass="filesSplitButtonMenu"
          [model]="downloadButtonItems"
          [disabled]="!(selectedAttachments?.length > 0) || (isDownloading$ | async)"
        ></p-splitButton>
      </div>
    </ng-container>

    <!-- Empty state -->
    <ng-template #attachmentsEmpty>
      <div class="border-round flex flex-column align-items-center py-4 lg:py-8 justify-content-center">
        <i class="msr-icon-attach_file icon-3rem text-primary"></i>
        <h3 class="mb-0 px-3 text-center">
          {{ "RECORD.empty-states.attachments.title" | translate : { default: "Nothing to show here" } }}
        </h3>
        <p class="text-center mb-0 mt-2 measured-container" style="--measure: var(--legible-measure)">
          {{
            "RECORD.empty-states.attachments.subtitle"
              | translate
                : {
                    default: "Files shared in this conversation will show here"
                  }
          }}
        </p>
      </div>
    </ng-template>
  </div>
</ng-container>

<!-- NESTOR DRIVE EXPORT  -->
<p-dialog
  [appendTo]="'body'"
  [(visible)]="displayCloudFolderPickerModal"
  [modal]="true"
  position="center"
  [header]="'MAIN.export-to-cloud.title' | translate : { 'default': 'Select a directory where to import the file' }"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))' }"
  [style]="themingService.customStyles$ | async"
  styleClass="dialog-nested"
  (onHide)="handleCloudFolderPickerClose()"
>
  <ng-template pTemplate="content">
    <main-cloud-file-picker
      mode="folders"
      [enableFolderCreation]="true"
      (onSubmit)="handleFolderPickerSubmitted($event)"
      (onCancel)="handleCloudFolderPickerClose()"
    ></main-cloud-file-picker>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="displayProviderFolderPickerModal"
  [modal]="true"
  position="center"
  [header]="'MAIN.export-to-cloud.title' | translate : { 'default': 'Select a directory where to import the file' }"
  [draggable]="false"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  maximizeIcon="msr-icon-fullscreen icon-18px"
  minimizeIcon="msr-icon-close_fullscreen icon-18px"
  [style]="{ 'max-width': 'var(--large-measure)', 'width': 'calc(100vw - var(--space-xs, 2rem))' }"
  styleClass="dialog-nested"
  (onHide)="handleProviderFolderPickerClose()"
>
  <ng-template pTemplate="content">
    <provider-file-picker
      mode="folders"
      [foldersOnly]="true"
      [enableFolderCreation]="true"
      [includeShared]="false"
      (onSubmit)="handleFolderPickerSubmitted($event)"
      (onCancel)="handleProviderFolderPickerClose()"
    ></provider-file-picker>
  </ng-template>
</p-dialog>
