import BaseModel from "@shared/models/base.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { ExportFormatEnum } from "@app/@shared/enums/export-format.enum";
import { ExportTypeEnum } from "@app/@shared/enums/export-type.enum";
import { ExportStatusEnum } from "@app/@shared/enums/export-status.enum";

export default class ExportModel extends BaseModel {
  // MINIMIZE (default)
  public createdAt: Date | null = null;
  public exportIdentifier: string | null = null;
  public format: ExportFormatEnum | null = null;
  public type: ExportTypeEnum | null = null;
  public status: ExportStatusEnum | null = null;
  public target: string | null = null;
  public metadata: JsonValue = {};

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);
    return this;
  }
}
