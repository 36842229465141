<p-sidebar
  [(visible)]="showDisclaimer"
  position="bottom"
  [baseZIndex]="10000"
  [style]="{ height: 'auto' }"
  styleClass="disclaimer-sidebar"
  [showCloseIcon]="false"
>
  <ng-template pTemplate="header">
    <header class="w-full measured-container measure-sm">
      <h2 class="h3 my-0">
        {{ "COMMON.text.disclaimer.title" | translate : { "default": "Disclaimer" } }}
      </h2>
    </header>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="measured-container measure-sm">
      <p class="mb-1">
        {{ "COMMON.text.disclaimer.description" | translate : { "default": "Staging environment, only for demo." } }}
      </p>

      <a target="_blank" href="https://app-nestor.com" pButton class="p-button-sm p-button-link pl-0 py-2">
        {{ "COMMON.text.disclaimer.link" | translate : { "default": "Use production version instead" } }}
      </a>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <footer class="surface-card measured-container measure-sm flex flex-column sm:flex-row gap-2">
      <button
        class="p-button-sm justify-content-center"
        pButton
        (click)="closeDisclaimer()"
        data-attr="disclaimer-close"
        [label]="'COMMON.text.disclaimer.button' | translate : { 'default': 'I understand' }"
      ></button>
    </footer>
  </ng-template>
</p-sidebar>
