<div
  class="surface-card border-bottom-1 surface-border padded-container"
  style="padding-top: var(--layout-margin); padding-bottom: 2.2857rem"
>
  <div class="measured-container measure-sm">
    <h1 class="mt-0 h2" *ngIf="displayTitle">
      {{ "ADDRESS-BOOK.contact-import.header.title" | translate : { "default": "Schéma d’importation de contacts" } }}
    </h1>
    <p class="text-color-secondary">
      {{ "ADDRESS-BOOK.contact-import.header.description" | translate : { "default": "Importer un fichier .csv" } }}
    </p>
    <!-- DROPZONE -->
    <div
      class="dropzone flex flex-column align-items-center justify-content-center"
      uploadDrop
      (filesDropped)="handleFilesDropped($event)"
      *ngIf="!(file$ | async)"
    >
      <input
        #uploadContactsInput
        type="file"
        id="csvFile"
        name="csvFile"
        accept=".csv"
        (change)="handleFileUpload($event)"
      />
      <i class="text-5xl block text-primary msr-icon-attach_file"></i>
      <label class="dropzone__title" for="csvFile">
        <span class="text-primary text-lg">
          {{ "ADDRESS-BOOK.contact-import.buttons.dropzone.label" | translate : { "default": "Upload a .csv file" } }}
        </span>

        <span class="text-color-secondary" style="margin-top: 4px">
          {{ "ADDRESS-BOOK.contact-import.buttons.dropzone.notice" | translate : { "default": "Support csv file" } }}
        </span>
      </label>
    </div>

    <!-- Uploaded file preview -->
    <output
      class="flex border-1 align-items-center surface-border p-3"
      style="border-radius: 16px"
      *ngIf="file$ | async as file"
    >
      <i class="pi pi-file text-primary text-5xl"></i>
      <div class="flex flex-column ml-3 mr-3">
        <span class="font-medium clamp">{{ file.name }}</span>
        <span class="text-color-secondary text-sm">
          <span *ngIf="data$ | async as data">
            {{
              ("ADDRESS-BOOK.contact-import.entries.count"
                | translate : { "default": data.length + " entries", count: data.length }) + " - "
            }}
          </span>
          <span>{{ file.type }}</span>
        </span>
      </div>
      <button
        pButton
        type="button"
        icon="pi pi-times"
        class="p-button-sm text-color p-button-text ml-auto p-2"
        style="--p-button-width: 1rem; --p-button-height: 1rem; --border-radius: 2px"
        (click)="handleRemoveFile()"
      ></button>
    </output>
    <!-- Add tags to imported contacts -->

    <div
      class="surface-border border-1 border-top-none border-round-bottom mx-5"
      *ngIf="file$ | async as file"
      style="background-color: var(--surface-b)"
    >
      <!-- Toggle tags input -->
      <div class="field-checkbox mb-0 p-3 align-items-start surface-border">
        <p-checkbox
          type="checkbox"
          inputId="reminderToggle"
          name="reminderToggle"
          [(ngModel)]="enableContactTags"
          [binary]="true"
          [ngModelOptions]="{ standalone: true }"
        ></p-checkbox>
        <label
          for="reminderToggle"
          class="cursor-pointer font-medium"
          [ngClass]="{ 'text-color-secondary': !enableContactTags }"
        >
          {{ "ADDRESS-BOOK.contact-import.buttons.assign-tags" | translate : { "default": "Add tags" } }}
        </label>
      </div>
      <aside class="p-3 pt-0" *ngIf="enableContactTags">
        <div class="field mb-0">
          <label for="contactTags" class="hidden">{{
            "LAYOUTS.adress-book.contact-edit.tags.label" | translate : { "default": "Tags" }
          }}</label>
          <div class="flex flex-column gap-2 p-fluid" style="max-width: 50ch">
            <p-autoComplete
              inputId="contactTags"
              styleClass="contactTagsAutocomplete"
              (keydown.enter)="onKeyUp($event)"
              [(ngModel)]="contactTags"
              [suggestions]="filteredTags"
              [completeOnFocus]="true"
              (completeMethod)="searchSuggestions($event)"
              [multiple]="true"
              appendTo="body"
              [dropdown]="suggestions.length > 0"
              [panelStyle]="themingService.customStyles$ | async"
              panelStyleClass="contactTagsAutocompletePanel"
            >
              <ng-template pTemplate="removetokenicon">
                <i class="pi pi-times-circle text-xs"></i>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
      </aside>
    </div>
  </div>
</div>
<form
  class="measured-container measure-md border-round flex flex-column"
  style="margin-top: 2.2857rem"
  [formGroup]="form"
  *ngIf="headerOptions"
  (ngSubmit)="submit()"
>
  <div class="padded-container">
    <h2 class="h5 my-0">
      {{ "ADDRESS-BOOK.contact-import.match-columns.title" | translate : { "default": "Match your columns" } }}
    </h2>
    <p class="text-color-secondary">
      {{
        "ADDRESS-BOOK.contact-import.match-columns.description"
          | translate : { "default": "Match your column headings with the requested fields." }
      }}
    </p>
    <div class="header-columns" *ngIf="headers?.length">
      <div class="header-column" *ngFor="let header of headers">
        <h3 class="my-0 h6">
          {{ header.label }}
        </h3>
        <p-dropdown
          [showClear]="true"
          appendTo="body"
          [placeholder]="'ADDRESS-BOOK.contact-import.match-columns.placeholder' | translate"
          [formControlName]="header.key"
          [options]="headerOptions"
          panelStyleClass="match-columns-panel"
        ></p-dropdown>
        <!-- First 3 items displayed as sample -->
        <ol *ngIf="data$ | async as data">
          <li
            class="text-color-secondary text-sm clamp"
            style="text-wrap: nowrap; display: list-item"
            *ngFor="let line of data | slice : 0 : 3"
          >
            {{ line[form.get(header.key)?.value] }}
          </li>
        </ol>
      </div>
    </div>
  </div>
  <!-- Confirm and import -->
  <footer class="dialog-nested-footer padded-container flex flex-wrap gap-2 align-items-center">
    <button
      type="button"
      pButton
      [loading]="isProcessingContacts$ | async"
      class="p-button-sm underline p-button-danger p-button-text py-1 px-0 border-noround"
      icon="pi pi-exclamation-triangle"
      (click)="displayInvalidContactsModal()"
      *ngIf="(invalidContacts$ | async)?.length > 0; else validContactNotice"
      [label]="
        'ADDRESS-BOOK.contact-import.entries.invalid-count' | translate : { count: (invalidContacts$ | async).length }
      "
    ></button>
    <ng-template #validContactNotice>
      <span class="text-green-600 font-medium" *ngIf="(validContacts$ | async).length > 0">
        <i class="pi pi-check-circle text-sm"></i>
        <span class="text-sm">
          {{
            "ADDRESS-BOOK.contact-import.entries.valid-count" | translate : { count: (validContacts$ | async).length }
          }}
        </span>
      </span>
    </ng-template>
    <button
      pButton
      icon="pi pi-upload"
      iconPos="right"
      [label]="'ADDRESS-BOOK.contact-import.buttons.confirm-import' | translate : { 'default': 'Import contacts' }"
      class="p-button-sm ml-auto"
      [disabled]="isProcessingContacts$ | async"
    ></button>
  </footer>
</form>

<!-- Import Modal -->
<p-dialog
  [(visible)]="showInvalidContactsModal"
  [header]="'ADDRESS-BOOK.contact-import.invalid-modal.header' | translate : { 'default': 'These errors were found' }"
  [modal]="true"
  [draggable]="false"
  [maximizable]="true"
  position="center"
  [baseZIndex]="10000"
  [dismissableMask]="true"
  [breakpoints]="{ '640px': 'calc(100vw - var(--space-s, 2rem))', '2048px': 'var(--medium-measure)' }"
  appendTo="body"
  [resizable]="false"
  [style]="themingService.customStyles$ | async"
  styleClass="h-full"
>
  <ng-template pTemplate="content">
    <p>
      {{
        "ADDRESS-BOOK.contact-import.invalid-modal.description" | translate : { "default": "These errors were found" }
      }}
    </p>
    <contact-invalid-table [invalidContacts]="invalidContacts$ | async"></contact-invalid-table>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      pButton
      class="p-button-outlined p-button-sm"
      [label]="'COMMON.buttons.close' | translate : { 'default': 'Close' }"
      (click)="showInvalidContactsModal = false"
    ></button>
  </ng-template>
</p-dialog>
