import BaseModel from "@shared/models/base.model";
import UserModel from "@shared/models/user/user.model";
import RecordMessageAttachmentModel from "@shared/models/record/record-message-attachment.model";
import { DateHelper } from "@shared/helpers/date.helper";
import { Dictionary, get, groupBy, last, sortBy } from "lodash";
import { MessageFormatEnum } from "@app/@shared/enums/message-format.enum";
import { MessageInvitationTypeEnum } from "@app/@shared/enums/message-invitation-type.enum";
import { MessageInvitationStatusEnum } from "@app/@shared/enums/message-invitation-status.enum";
import RecordMessageFormItemModel from "./record-message-form-item.model";
import { DateTime } from "luxon";
import RecordMessageAnswerModel from "./record-message-answer.model";
import { DataTypeFormatEnum } from "@app/@shared/enums/data-type-format.enum";

const SIGNATURE_KEY = "signature";
const INVITATION_KEY = "invitation";

export type Signer = {
  identityIdentifier: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export default class RecordMessageModel extends BaseModel {
  // DEFAULT (default)
  public messageIdentifier: string | null = null;
  public subject?: string;
  public body: string | null = "";
  public format?: MessageFormatEnum;
  public sender: UserModel | null = null;
  public metadata: JsonValue = {};

  public createdAt: Date | null = null;
  public updatedAt: Date | null = null;

  // RECORD_MESSAGES
  public replyMessages: RecordMessageModel[] = [];

  // RECORD_MESSAGE_FORM_ITEMS
  public formItems: RecordMessageFormItemModel[] = [];

  // RECORD_MESSAGE_ANSWERS
  public answers: RecordMessageAnswerModel[] = [];

  // RECORD_ATTACHMENTS
  public attachments?: RecordMessageAttachmentModel[] = [];

  // RECORD_MESSAGE_PARENT_MESSAGE_IDENTIFIER
  public parentMessageIdentifier: string | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.sender) this.sender = new UserModel().deserialize(input.sender);

    if (input.createdAt) this.createdAt = DateHelper.parseDate(input.createdAt);

    if (input.updatedAt) this.updatedAt = DateHelper.parseDate(input.updatedAt);

    if (input.replyMessages && Array.isArray(input.replyMessages))
      this.replyMessages = input.replyMessages.map((message: any) => new RecordMessageModel().deserialize(message));

    if (input.attachments && Array.isArray(input.attachments))
      this.attachments = input.attachments.map((attachment: any) =>
        new RecordMessageAttachmentModel().deserialize(attachment),
      );

    if (input.formItems && Array.isArray(input.formItems))
      this.formItems = input.formItems.map((formItem: any) => new RecordMessageFormItemModel().deserialize(formItem));

    if (input.answers && Array.isArray(input.answers))
      this.answers = input.answers.map((answer: any) => new RecordMessageAnswerModel().deserialize(answer));

    return this;
  }

  get createdAtDay() {
    return this.createdAt ? DateTime.fromJSDate(this.createdAt).startOf("day").toJSDate() : null;
  }

  isSender(userIdentifier: string): boolean {
    return this.sender && this.sender.userIdentifier === userIdentifier;
  }

  get isChild(): boolean {
    return Boolean(this.parentMessageIdentifier);
  }

  /**
   * Metadata related getters
   */
  get isBroadcasted(): boolean {
    return Boolean(get(this.metadata, ["broadcasted"], false));
  }

  get isBotMessage(): boolean {
    return Boolean(get(this.metadata, ["bot"], false));
  }

  get hasForm(): boolean {
    return this.formItems.length > 0;
  }

  get hasFormAnswer(): boolean {
    return this.answers.length > 0;
  }

  get hasSignature(): boolean {
    return Boolean(get(this.metadata, [SIGNATURE_KEY], null));
  }

  get hasInvitation(): boolean {
    return Boolean(get(this.metadata, [INVITATION_KEY], null));
  }

  /**
   * Form related getters
   */
  get isAnswerAvailable(): boolean {
    return this.hasForm && !this.answerCompleted;
  }

  get hasAnswers(): boolean {
    return this.answers.length > 0;
  }

  // Get answers grouped by itemIdentifier (FORM)
  get formAnswerGroups(): Dictionary<RecordMessageAnswerModel[]> {
    return this.hasForm
      ? groupBy(
          this.replyMessages
            .filter((message: RecordMessageModel) => message.hasAnswers)
            .flatMap((message: RecordMessageModel) =>
              message.answers
                .filter((a: RecordMessageAnswerModel) => a.isFormAnswer)
                .map((a: RecordMessageAnswerModel) => {
                  // Set a createdAt attribute to answer to allow sorting
                  a.createdAt = message.createdAt;
                  return a;
                }),
            ),
          "itemIdentifier",
        )
      : null;
  }

  // Get all answers for a formItem (FORM)
  getFormAnswers(formItemIdentifier: string): RecordMessageAnswerModel[] {
    return get(this.formAnswerGroups, formItemIdentifier, []);
  }

  getDirectFormAnswers(formItemIdentifier: string): RecordMessageAnswerModel[] {
    return this.answers.filter((a: RecordMessageAnswerModel) => a.itemIdentifier === formItemIdentifier);
  }

  // Get last answer for a formItem (FORM)
  getLastFormAnswer(formItemIdentifier: string): RecordMessageAnswerModel {
    let formAnswers = this.getFormAnswers(formItemIdentifier);

    if (formAnswers.length > 0) {
      formAnswers = sortBy(formAnswers, "createdAt");
      return last(formAnswers);
    }

    return undefined;
  }

  getDirectLastFormAnswer(formItemIdentifier: string): RecordMessageAnswerModel {
    let formAnswers = this.getDirectFormAnswers(formItemIdentifier);

    if (formAnswers.length > 0) {
      formAnswers = sortBy(formAnswers, "createdAt");
      return last(formAnswers);
    }

    return undefined;
  }

  // Get files identifier from answer for a formItem (FORM)
  getFormAnswerAttachments(formItemIdentifier: string): RecordMessageAttachmentModel[] | undefined {
    let formAnswers = this.getFormAnswers(formItemIdentifier);

    if (formAnswers.length > 0) {
      let fileIdentifiers = formAnswers.flatMap((answer: RecordMessageAnswerModel) => answer.content.files);
      return this.replyMessages
        .filter((message: RecordMessageModel) => message.hasAnswers)
        .flatMap((message: RecordMessageModel) =>
          message.attachments.filter((attachment: RecordMessageAttachmentModel) =>
            fileIdentifiers.includes(attachment.file?.fileIdentifier),
          ),
        );
    }

    return undefined;
  }

  getDirectFormAnswerAttachments(formItemIdentifier: string): RecordMessageAttachmentModel[] | undefined {
    let formAnswers = this.getDirectFormAnswers(formItemIdentifier);

    if (formAnswers.length > 0) {
      let fileIdentifiers = formAnswers.flatMap((answer: RecordMessageAnswerModel) => answer.content.files);
      return this.attachments.filter((attachment: RecordMessageAttachmentModel) =>
        fileIdentifiers.includes(attachment.file?.fileIdentifier),
      );
    }

    return undefined;
  }

  getAllDirectFormAnswerAttachments(): RecordMessageAttachmentModel[] {
    let fileIdentifiers = this.answers.flatMap((answer: RecordMessageAnswerModel) => answer.content.files);
    return this.attachments.filter((attachment: RecordMessageAttachmentModel) =>
      fileIdentifiers.includes(attachment.file?.fileIdentifier),
    );
  }

  // Check if attachment is part of a form answer
  isFormAnswerAttachment(attachment: RecordMessageAttachmentModel) {
    let formAnswerAttachments = this.getAllDirectFormAnswerAttachments();
    return (
      formAnswerAttachments.find(
        (a: RecordMessageAttachmentModel) => a.attachmentIdentifier === attachment.attachmentIdentifier,
      ) !== undefined
    );
  }

  get hasDocumentsItems(): boolean {
    return this.formItems.some((formItem) => formItem.format === DataTypeFormatEnum.DOCUMENTS);
  }

  // Get all answers from a message (FORM_ANSWER)
  get answerCompleted(): boolean {
    if (!this.hasForm) {
      return false;
    }

    let formItemIdentifiers: string[] = this.formItems.map(
      (formItem: RecordMessageFormItemModel) => formItem.itemIdentifier,
    );
    let answersFormItemIdentifiers: string[] = this.formItems
      .flatMap((formItem: RecordMessageFormItemModel) => this.getFormAnswers(formItem.itemIdentifier))
      .map((answer: RecordMessageAnswerModel) => answer.itemIdentifier);

    return formItemIdentifiers.every((identifier: string) => answersFormItemIdentifiers.includes(identifier));
  }

  /**
   * Signature related getters
   */
  get signers(): Signer[] {
    return this.hasSignature ? get(this.metadata, ["signature", "recipients"], []) : [];
  }

  get signatureAttachments(): string[] {
    return this.hasSignature ? get(this.metadata, ["signature", "attachments"], []) : [];
  }

  findSignerByIdentifier(identityIdentifier: string): Signer {
    return this.hasSignature
      ? this.signers.find((s: Signer) => s.identityIdentifier === identityIdentifier)
      : undefined;
  }

  isSigner(identityIdentifier: string): boolean {
    return this.findSignerByIdentifier(identityIdentifier) !== undefined;
  }

  findSignatureAttachmentByIdentifier(fileIdentifier: string): string {
    return this.hasSignature ? this.signatureAttachments.find((s: string) => s === fileIdentifier) : undefined;
  }

  isSignatureAttachment(fileIdentifier: string): boolean {
    return this.findSignatureAttachmentByIdentifier(fileIdentifier) !== undefined;
  }

  get signatureAnswers() {
    return this.hasSignature
      ? this.replyMessages
          .filter((message: RecordMessageModel) => message.isBotMessage)
          .flatMap((message: RecordMessageModel) =>
            message.answers
              .filter((a: RecordMessageAnswerModel) => a.isSignatureAnswer)
              .map((a: RecordMessageAnswerModel) => {
                // Set a createdAt attribute to answer to allow sorting
                a.createdAt = message.createdAt;
                return a;
              }),
          )
      : null;
  }

  findSignatureRecipientAnswer(identityIdentifier: string): RecordMessageAnswerModel {
    return this.signatureAnswers.find(
      (answer: RecordMessageAnswerModel) => answer.content.signature.identityIdentifier === identityIdentifier,
    );
  }

  findSignatureAnswerWithStatus(property: string, status: boolean) {
    return this.signatureAnswers.find(
      (answer: RecordMessageAnswerModel) =>
        answer.content.signature.hasOwnProperty(property) && answer.content.signature[property] === status,
    );
  }

  hasSigned(identityIdentifier: string): boolean {
    if (!this.hasSignature || !this.isSigner(identityIdentifier)) {
      return false;
    }

    let recipientAnswer: RecordMessageAnswerModel = this.findSignatureRecipientAnswer(identityIdentifier);
    return Boolean(recipientAnswer);
  }

  hasCompletedSignature(identityIdentifier: string): boolean {
    if (!this.hasSignature || !this.isSigner(identityIdentifier)) {
      return false;
    }

    let recipientAnswer: RecordMessageAnswerModel = this.findSignatureRecipientAnswer(identityIdentifier);
    return Boolean(recipientAnswer) && recipientAnswer.content.signature.recipientSigned === true;
  }

  get isSignatureAvailable(): boolean {
    return this.hasSignature && !(this.isSignatureCompleted || this.isSignatureInactive);
  }

  get isSignatureInactive(): boolean {
    return this.isSignatureDeclined || this.isSignatureExpired || this.isSignatureError || this.isSignatureCanceled;
  }

  get isSignatureCompleted(): boolean {
    if (!this.hasSignature) {
      return false;
    }

    let answer: RecordMessageAnswerModel = this.findSignatureAnswerWithStatus("completed", true);
    return Boolean(answer);
  }

  get isSignatureDeclined(): boolean {
    if (!this.hasSignature) {
      return false;
    }
    let answer: RecordMessageAnswerModel = this.findSignatureAnswerWithStatus("declined", true);
    return Boolean(answer);
  }

  get isSignatureCanceled(): boolean {
    if (!this.hasSignature) {
      return false;
    }

    let answer: RecordMessageAnswerModel = this.findSignatureAnswerWithStatus("canceled", true);
    return Boolean(answer);
  }

  get isSignatureExpired(): boolean {
    if (!this.hasSignature) {
      return false;
    }

    let answer: RecordMessageAnswerModel = this.findSignatureAnswerWithStatus("expired", true);
    return Boolean(answer);
  }

  get isSignatureError(): boolean {
    if (!this.hasSignature) {
      return false;
    }

    let answer: RecordMessageAnswerModel = this.findSignatureAnswerWithStatus("error", true);
    return Boolean(answer);
  }

  /**
   * Invitation related getters
   */
  get invitationType(): MessageInvitationTypeEnum | undefined {
    if (this.hasInvitation) {
      return get(this.metadata, ["invitation", "type"]) as MessageInvitationTypeEnum;
    }

    return undefined;
  }

  get invitedIdentities(): string[] {
    return this.hasInvitation ? get(this.metadata, ["invitation", "identities"], []) : [];
  }

  isInvitedIdentity(identityIdentifier: string): boolean {
    return this.hasInvitation && this.invitedIdentities.includes(identityIdentifier);
  }

  isInvitationAvailable(identityIdentifier: string): boolean {
    return (
      this.hasInvitation &&
      !(this.hasAcceptedInvitation(identityIdentifier) || this.hasDeclinedInvitation(identityIdentifier))
    );
  }

  get invitationAnswers() {
    return this.hasInvitation
      ? this.replyMessages.flatMap((message: RecordMessageModel) =>
          message.answers
            .filter((a: RecordMessageAnswerModel) => a.isInvitationAnswer)
            .map((a: RecordMessageAnswerModel) => {
              // Set a createdAt attribute to answer to allow sorting
              a.createdAt = message.createdAt;
              return a;
            }),
        )
      : null;
  }

  findInvitationRecipientAnswer(identityIdentifier: string): RecordMessageAnswerModel {
    return this.invitationAnswers.find(
      (answer: RecordMessageAnswerModel) => answer.content.invitation.identityIdentifier === identityIdentifier,
    );
  }

  findInvitationAnswerWithStatus(status: MessageInvitationStatusEnum) {
    return this.invitationAnswers.find(
      (answer: RecordMessageAnswerModel) =>
        answer.content.invitation.hasOwnProperty("status") && answer.content.invitation.status === status,
    );
  }

  isInvitationInactive(identityIdentifier: string): boolean {
    return this.hasDeclinedInvitation(identityIdentifier);
  }

  hasAnsweredInvitation(identityIdentifier: string): boolean {
    if (!this.hasInvitation || !this.isInvitedIdentity(identityIdentifier)) {
      return false;
    }

    let recipientAnswer: RecordMessageAnswerModel = this.findInvitationRecipientAnswer(identityIdentifier);
    return Boolean(recipientAnswer);
  }

  hasPendingInvitation(identityIdentifier: string): boolean {
    if (!this.hasInvitation || !this.isInvitedIdentity(identityIdentifier)) {
      return false;
    }

    let recipientAnswer: RecordMessageAnswerModel = this.findInvitationAnswerWithStatus(
      MessageInvitationStatusEnum.PENDING,
    );
    return Boolean(recipientAnswer);
  }

  hasAcceptedInvitation(identityIdentifier: string): boolean {
    if (!this.hasInvitation || !this.isInvitedIdentity(identityIdentifier)) {
      return false;
    }

    let recipientAnswer: RecordMessageAnswerModel = this.findInvitationAnswerWithStatus(
      MessageInvitationStatusEnum.ACCEPTED,
    );
    return Boolean(recipientAnswer);
  }

  hasDeclinedInvitation(identityIdentifier: string): boolean {
    if (!this.hasInvitation || !this.isInvitedIdentity(identityIdentifier)) {
      return false;
    }

    let recipientAnswer: RecordMessageAnswerModel = this.findInvitationAnswerWithStatus(
      MessageInvitationStatusEnum.DECLINED,
    );
    return Boolean(recipientAnswer);
  }
}
