import BaseModel from "@shared/models/base.model";
import RecordMessageModel from "@shared/models/record/record-message.model";
import RecordMessageAnswerModel from "./record-message-answer.model";

export default class AnswerRecordMessageRequestModel extends BaseModel {
  public recordIdentifier: string;
  public parentMessageIdentifier: string;
  public message: RecordMessageModel;
  public answers: RecordMessageAnswerModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    this.message = new RecordMessageModel().deserialize(input.message);

    if (input.answers && Array.isArray(input.answers))
      this.answers = input.answers.map((answer: any) => new RecordMessageAnswerModel().deserialize(answer));

    return this;
  }
}
