import { Injectable } from "@angular/core";
import Hash from "js-crypto-hash";
import { HashTypes } from "js-crypto-hash/dist/params";
import RSA from "js-crypto-rsa";
import { cipherTypes } from "js-crypto-aes/dist/params";
import AES from "js-crypto-aes";
import { Base64 } from "js-base64";
import { Key } from "js-crypto-key-utils/dist/key";

@Injectable({
  providedIn: "root",
})
export class CryptoHelper {
  constructor() {}

  static async toJWK(key: Key, algorithm?: string): Promise<JsonWebKey> {
    const jwk = (await key.export("jwk")) as JsonWebKey;
    if (algorithm) jwk.alg = algorithm;

    return jwk;
  }

  /**
   * Encrypts data through RSA (asymmetric)
   * @param data Data provided for encryption
   * @param jwk JSON Web Key that will be used to encrypt (public key)
   * @param hash Hash type (default SHA-256)
   * @returns a Promise wrapping the encrypted data
   */
  static RSAEncrypt(data: Uint8Array, jwk: JsonWebKey, hash: HashTypes = "SHA-256"): Promise<Uint8Array> {
    return RSA.encrypt(data, jwk, hash);
  }

  /**
   * Decrypts data through RSA (asymmetric)
   * @param data Data provided for decryption
   * @param jwk JSON Web Key that will be used to decrypt (private key)
   * @param hash Hash type (default SHA-256)
   * @returns a Promise wrapping the decrypted data
   */
  static RSADecrypt(data: Uint8Array, jwk: JsonWebKey, hash: HashTypes = "SHA-256"): Promise<Uint8Array> {
    return RSA.decrypt(data, jwk, hash);
  }

  /**
   * Encrypts data through AES (symmetric)
   * @param data Data provided for encryption
   * @param key the key used for encryption
   * @param cipherType Cipher type (default AES-CBC)
   * @param iv The IV (default empty unsigned 8 integer array, length : 16)
   * @returns a Promise wrapping the encrypted data
   */
  static AESEncrypt(
    data: Uint8Array,
    key: Uint8Array,
    cipherType: cipherTypes = "AES-CBC",
    iv: Uint8Array = new Uint8Array(16),
  ): Promise<Uint8Array> {
    return AES.encrypt(data, key, { name: cipherType, iv });
  }

  /**
   * Decrypts data through AES (symmetric)
   * @param data Data provided for decryption
   * @param key the key used for decryption
   * @param cipherType Cipher type (default AES-CBC)
   * @param iv The IV (default empty unsigned 8 bits integer array, length : 16)
   * @returns a Promise wrapping the decrypted data
   */
  static AESDecrypt(
    data: Uint8Array,
    key: Uint8Array,
    cipherType: cipherTypes = "AES-CBC",
    iv: Uint8Array = new Uint8Array(16),
  ): Promise<Uint8Array> {
    return AES.decrypt(data, key, {
      name: cipherType,
      iv,
    });
  }

  /**
   * Encodes a string into a unsigned 8 bits integer array
   * @param data Data provided for encoding
   * @returns an unsigned 8 bits integer array
   */
  static encode(data: string): Uint8Array {
    return new TextEncoder().encode(data);
  }

  /**
   * Decodes a string from a Uint8Array
   * @param data Data provided for decoding
   * @returns a string
   */
  static decode(data: Uint8Array): string {
    return new TextDecoder().decode(data);
  }

  /**
   * Transforms a Uint8Array into a base64 stirng
   * @param data the Uint8Array to be transformed
   * @returns a base64 string
   */
  static toBase64(data: Uint8Array): string {
    return Base64.fromUint8Array(data);
  }

  /**
   * Transforms a base64 string to a Uint8Array
   * @param data the base64 string to be transformed
   * @returns a Uint8Array
   */
  static fromBase64(data: string): Uint8Array {
    return Base64.toUint8Array(data);
  }

  /**
   * Encodes an UTF-8 string into base64
   * @param data the UTF-8 string to encode in Base64
   * @returns a base64 encoded string
   */
  static toBase64String(data: string) {
    return Base64.encode(data);
  }

  /**
   * Transforms a Uint8Array into a hex string
   * @param data the Uint8Array to be transformed
   * @returns a hex string
   */
  static toHex(data: Uint8Array): string {
    return data.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");
  }

  /**
   * Transforms a hex string into a Uint8Array
   * @param data the hex string to be transformed
   * @returns a Uint8Array
   */
  static fromHex(data: string): Uint8Array {
    const matches = data.match(/.{1,2}/g);
    return matches ? new Uint8Array(matches.map((byte) => parseInt(byte, 16))) : new Uint8Array();
  }

  /**
   * Encodes an UTF-8 string into Hexadecimal
   * @param data the UTF-8 string to encode
   * @returns a hex encoded string
   */
  static toHexString(data: string) {
    let h = "";
    for (let i = data.length - 1; i >= 0; i--) h = "%" + data.charCodeAt(i).toString(16) + h;
    return h;
  }

  /**
   * Decodes a Base64 string into UTF-8
   * @param data the base64 string to encode in UTF-8
   * @returns an UTF-8 decoded string
   */
  static fromBase64String(data: string) {
    return Base64.decode(data);
  }

  /**
   * Computes a hash from a message and a hashing type
   * @param message the message to be hashed
   * @param hash the hash type (default SHA-256)
   * @returns a Promise wrapping the hashed data
   */
  static computeHash(message: Uint8Array, hash: HashTypes = "SHA-256"): Promise<Uint8Array> {
    return Hash.compute(message, hash);
  }

  /**
   * Reads a blob into an ArrayBuffer
   * @param body the blob to be read
   * @returns a Promise wrapping the ArrayBuffer
   */
  static readBlob(body: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(body);
    });
  }

  /**
   * Computes a hash for a given Blob
   * @param body the Blob to be hashed
   * @param algorithm the hash algorithm (default SHA-256)
   * @returns a Promise wrapping the hex hash
   */
  static blobToHexHash(body: Blob, algorithm: HashTypes = "MD5"): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const arrayBuffer = await this.readBlob(body);
      const hashArray = await this.computeHash(new Uint8Array(arrayBuffer), algorithm);

      resolve(this.toHex(hashArray));
    });
  }
}
