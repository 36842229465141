import { shared } from "./shared";

export const environment = {
  production: true,
  disclaimer: false,
  hmr: false,
  application: "paprwork-v3-ui",
  applicationUrl: "https://app-nestor.com",
  defaultLanguage: "fr-FR",
  supportedLanguages: ["en-GB", "fr-FR"],
  defaultUnit: "common-unit",
  services: {
    baseUrls: {
      mainApiUrl: "https://api.service.app-nestor.com/main/api",
      keyvaultApiUrl: "https://api.service.app-nestor.com/keyvault/keys",
      filesApiUrl: "https://api.service.app-nestor.com/files",
      partnerApiUrl: "https://api.service.app-nestor.com/partners/partners",
      exportsApiUrl: "https://api.service.app-nestor.com/exports/exports",
      novuWSUrl: "https://novu-ws.service.app-nestor.com",
    },
    methodUrls: {
      // Shared method URLs
      ...shared.services.methodUrls,
    },
  },
  keycloak: {
    url: "https://auth.service.app-nestor.com/auth",
    realm: "paprwork",
    clientId: "paprwork-client-v3",
  },
  sentry: {
    enabled: true,
    dsn: "https://8a6623392a8c42428b753d6f6b3f7e0d@traces.service.app-nestor.com/7",
    tracingOrigins: ["api.service.app-nestor.com"],
    tracesSampleRate: 0.25,
    environment: "prod",
    release: "v3.1.1",
  },
  crisp: {
    websiteId: "d9611cfe-73a4-40f5-a1fc-a577ae226569",
  },
  posthog: {
    enabled: true,
    host: "https://analytics.service.app-nestor.com",
    apiKey: "phc_cJ6uIpLroTS335olozNzRjOJaD5CASdJxXA0aAQEFeO",
    environment: "prod",
    app: "v3",
  },
  matomo: {
    enabled: true,
    host: "https://matomo.nestor.tools",
    siteId: 4,
  },
  stripe: {
    publishableKey:
      "pk_live_51M94V6KK6GoXalLqIz83wXBi1qBE6CazrF9fSVRl7DEMbBUq0zT0xQguynm4NFaMxdMn9nL21ZAccx1fVGBqmpQw00gghjuE9k",
  },
  cloud: {
    url: "https://drive.app-nestor.com/",
  },
  yousignV3: {
    isSandbox: false,
  },
};
