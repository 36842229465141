import UnitModel from "@shared/models/domain/unit.model";
import UserModel from "@shared/models/user/user.model";
import FormDataModel from "../form-data.model";

export default class DataTypeModel extends FormDataModel {
  // MINIMIZE (default)
  public dataTypeClass: string | null = null;

  public label: string | null = null;
  public description?: string;
  public group?: string;
  public scope: string | null = null;
  public keywords?: string[];

  public unit: UnitModel;
  public user: UserModel;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.unit) this.unit = new UnitModel().deserialize(input.unit);
    if (input.user) this.user = new UserModel().deserialize(input.user);

    return this;
  }

  get isCustom(): boolean {
    return this.group === "CUSTOM";
  }
}
