<div class="flex flex-column gap-1">
  <ng-container *ngIf="sortedRecipients.length > 0; else emptyItem">
    <!-- Add recipient item -->
    <div
      *ngIf="enableAdd"
      class="recipient-item border-primary justify-content-center"
      (click)="handleAddRecipientClick()"
    >
      <div class="flex flex-column align-items-center gap-2">
        <i class="pi pi-user-plus text-2xl text-primary"></i>
        <span class="font-medium">{{ addRecipientLabel }}</span>
      </div>
    </div>

    <!-- Recipients list -->
    <div *ngFor="let recipient of sortedRecipients" class="recipient-item">
      <div class="flex align-items-center gap-2">
        <span
          class="custom-marker"
          [attr.data-origin]="
            recipient.role === RecordRoleEnum.OWNER || recipient.role === RecordRoleEnum.REVIEWER ? 'self' : 'other'
          "
        >
          {{ recipient | getRecipientInitials }}
        </span>
        <div>
          <span class="font-medium text-color">{{ recipient.fullName || recipient.emailAddress }} </span>
          <span class="block md:inline-block text-sm text-color-secondary" *ngIf="recipient.fullName"
            >({{ recipient.emailAddress }})</span
          >

          <span
            *ngIf="(currentIdentity$ | async).identityIdentifier === recipient.identityIdentifier"
            class="font-normal text-color-secondary text-sm"
          >
            ({{ "COMMON.you" | translate : { default: "You" } }})</span
          >
          <div
            class="text-sm text-color-secondary"
            [tooltip]="'RECORD.recipient-roles.' + record.type + '.' + recipient.role + '.description' | translate"
          >
            {{ "RECORD.recipient-roles." + record.type + "." + recipient.role + ".label" | translate }}
          </div>
        </div>
      </div>
      <div
        *ngIf="
          enableRemove &&
          (currentIdentity$ | async).identityIdentifier !== recipient.identityIdentifier &&
          recipient.role !== RecordRoleEnum.OWNER
        "
      >
        <button
          type="button"
          pButton
          icon="pi pi-times"
          [tooltip]="'COMMON.buttons.remove' | translate"
          class="p-button-text p-button-danger p-button-sm w-1rem h-1rem p-2"
          style="
            background: rgba(var(--danger-color-rgb), 0.08);
            border-color: rgba(var(--danger-color-rgb), 0.09);
            border-radius: 2px;
          "
          (click)="handleRemoveRecipientClick(recipient)"
        ></button>
      </div>
    </div>
  </ng-container>

  <ng-template #emptyItem>
    <div class="flex align-items-center gap-2 py-1 text-color">
      {{ "RECORD.recipients.empty" | translate : { "default": "No recipients" } }}
    </div>
  </ng-template>
</div>
