import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DataTypeFormatEnum } from "@app/@shared/enums/data-type-format.enum";
import { FormDataControlEnum } from "@app/@shared/enums/form-data/form-data-control.enum";
import { FormDataFormatterTypeEnum } from "@app/@shared/enums/form-data/form-data-formatter-type.enum";
import { FormDataImportanceEnum } from "@app/@shared/enums/form-data/form-data-importance.enum";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import {
  FormDataControlTexts,
  FormDataDescriptions,
  FormDataFormatter,
  FormDataLabels,
  FormDataOption,
  FormDataPlaceholders,
} from "@app/@shared/models/form-data.model";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { TranslateService } from "@ngx-translate/core";
import { CurrencyDisplay } from "../form-items/item-format-number/item-format-number.component";
import { FileHelper } from "@shared/helpers/file.helper";
import { debounceTime, pairwise, startWith } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DomainAPIService } from "@app/@shared/services/domain-api.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { ConfigurationClassEnum } from "@app/@shared/enums/configuration-class.enum";
import { ListClassEnum } from "@app/@shared/enums/list-class.enum";

export type FormItemPropertiesFormValue = {
  label: string;
  description: string;
  properties: {
    control?: FormDataControlEnum;
    defaultValue?: string | number | boolean;
    options?: FormDataOption[];
    formatters?: FormDataFormatter[];
    // labels?: FormDataLabels[];
    // descriptions?: FormDataDescriptions[];
    placeholders?: FormDataPlaceholders[];
    controlTexts?: FormDataControlTexts[];
    importance?: FormDataImportanceEnum;
  };
};

type ControlOption = {
  label: string;
  value: FormDataControlEnum;
};

type ImportanceOption = {
  label: string;
  value: FormDataImportanceEnum;
};

type CurrencyDisplayOption = {
  label: string;
  value: CurrencyDisplay;
};

type FileAcceptOption = {
  label: string;
  value: string;
};

type FileRenamingOption = {
  label: string;
  value: string;
};
@UntilDestroy()
@Component({
  selector: "record-form-item-properties-form",
  templateUrl: "./form-item-properties-form.component.html",
  styleUrls: ["./form-item-properties-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecordFormItemPropertiesFormComponent implements OnInit, OnChanges {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() recordTitle: string = null;
  @Output() onSubmit: EventEmitter<FormItemPropertiesFormValue> = new EventEmitter<FormItemPropertiesFormValue>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  _formItemPreview: RecordMessageFormItemModel;
  @Output() formItemPreviewChange = new EventEmitter<RecordMessageFormItemModel>();
  set formItemPreview(formItemPreview: RecordMessageFormItemModel) {
    this._formItemPreview = formItemPreview;
    this.formItemPreviewChange.emit(formItemPreview);
  }
  get formItemPreview(): RecordMessageFormItemModel {
    return this._formItemPreview;
  }

  controlOptions: ControlOption[] = [];
  currencyDisplayOptions: CurrencyDisplayOption[] = [];
  fileAcceptOptions: FileAcceptOption[] = [];
  fileRenamingOptions: FileRenamingOption[] = [];
  importanceOptions: ImportanceOption[] = [];
  form: UntypedFormGroup;
  FormDataFormatterTypeEnum = FormDataFormatterTypeEnum;
  language: string;
  languages: string[] = [];
  previewForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private translateService: TranslateService,
    private domainAPIService: DomainAPIService,
    private sessionAPIService: SessionAPIService,
  ) {}

  ngOnInit(): void {
    this.languages = this.i18nService.languages;
    this.language = this.i18nService.language;
    this.buildForm();
    this.buildPreviewForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem) {
      this.buildForm();
      this.buildPreviewForm();
      let currentValue = changes.formItem.currentValue as RecordMessageFormItemModel;
      if (currentValue.hasControlFormatter(FormDataFormatterTypeEnum.FILE_RENAMING)) {
        this.getFileRenamingOptions();
      }
    }
  }

  private getFileRenamingOptions() {
    this.domainAPIService
      .getList(this.sessionAPIService.unit.unitSlug, ListClassEnum.RULES_FILE_RENAMING)
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        let listValues: FileRenamingOption[] = result["values"] as FileRenamingOption[];
        let values = listValues.map(({ label, value }) => ({
          label: this.translateService.instant(`FILES.renaming-patterns.${label}.label`),
          value,
        }));
        if (values) {
          let options: FileRenamingOption[] = [
            {
              label: this.translateService.instant("COMMON.none"),
              value: null,
            },
            ...values,
          ];
          this.fileRenamingOptions = options;
        }
      });
  }
  private buildForm() {
    this.form = this.formBuilder.group({
      label: [this.formItem.label, [Validators.required]],
      description: [this.formItem.description, []],
      properties: this.formBuilder.group({}),
    });

    this.buildFormProperties();
    this.setControlOptions();
    this.setImportanceOptions();
    this.setCurrencyDisplayOptions();
    this.setFileAcceptOptions();
    this.generateFormItemPreview();

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.generateFormItemPreview();
    });

    // Previously used to update localized fields
    // this.form.valueChanges
    //   .pipe(startWith(this.form.value), pairwise(), debounceTime(500), untilDestroyed(this))
    //   .subscribe(([prevValue, newValue]) => {
    //     if (prevValue?.label !== newValue?.label || prevValue?.description !== newValue?.description)
    //       this.updateLocalizedFields(newValue);
    //   });
  }

  // updateLocalizedFields(formValue: any) {
  //   const currentLocale = this.i18nService.language;

  //   if (this.formItem.hasControlProperty("labels") && this.formPropertiesControlGroup.get("labels")) {
  //     const labelsFormGroup: UntypedFormGroup = this.formPropertiesControlGroup.get("labels") as UntypedFormGroup;
  //     const localizedLabel: AbstractControl = labelsFormGroup.get(currentLocale);
  //     localizedLabel && localizedLabel.setValue(formValue.label);
  //   }

  //   if (this.formItem.hasControlProperty("descriptions") && this.formPropertiesControlGroup.get("descriptions")) {
  //     const descriptionsFormGroup: UntypedFormGroup = this.formPropertiesControlGroup.get(
  //       "descriptions",
  //     ) as UntypedFormGroup;
  //     const localizedDescription: AbstractControl = descriptionsFormGroup.get(currentLocale);
  //     localizedDescription && localizedDescription.setValue(formValue.description);
  //   }
  // }

  /**
   * Build form groups for each property required for this data format
   */
  private buildFormProperties() {
    if (this.formItem.hasControlProperty("control")) {
      this.formPropertiesControlGroup.addControl("control", new UntypedFormControl(this.formItem.getControl(), []));
    }

    if (this.formItem.hasControlProperty("defaultValue")) {
      this.formPropertiesControlGroup.addControl("defaultValue", new UntypedFormControl(null, []));
    }

    if (this.formItem.hasControlProperty("options")) {
      this.formPropertiesControlGroup.addControl("options", this.buildFormOptions());
    }

    if (this.formItem.hasControlProperty("formatters")) {
      this.formPropertiesControlGroup.addControl("formatters", this.buildFormFormatters());
    }

    // if (this.formItem.hasControlProperty("labels")) {
    //   this.formPropertiesControlGroup.addControl("labels", this.buildFormLabels());
    // }

    // if (this.formItem.hasControlProperty("descriptions")) {
    //   this.formPropertiesControlGroup.addControl("descriptions", this.buildFormDescriptions());
    // }

    if (this.formItem.hasControlProperty("placeholders")) {
      this.formPropertiesControlGroup.addControl("placeholders", this.buildFormPlaceholders());
    }

    if (this.formItem.hasControlProperty("controlTexts")) {
      this.formPropertiesControlGroup.addControl("controlTexts", this.buildFormControlTexts());
    }

    if (this.formItem.hasControlProperty("importance")) {
      this.formPropertiesControlGroup.addControl(
        "importance",
        new UntypedFormControl(this.formItem.getImportance(), []),
      );
    }
  }

  private buildFormOptions(): UntypedFormArray {
    let formOptions: UntypedFormArray = this.formBuilder.array([]);

    if (this.formItem.getOptions() && this.formItem.getOptions().length > 0) {
      this.formItem
        .getOptions()
        .forEach((option: FormDataOption) => formOptions.push(this.newFormOption(option.label, option.value)));
    } else {
      formOptions.push(this.newFormOption());
    }

    return formOptions;
  }

  public getOptionFormGroup(index: number): UntypedFormGroup {
    return this.getControlArray("options").controls[index] as UntypedFormGroup;
  }

  private buildFormFormatters(): UntypedFormArray {
    let formFormatters = this.formBuilder.array([]),
      formattersForFormat = this.formItem.getControlFormatters();

    formattersForFormat.forEach((formatterType: FormDataFormatterTypeEnum) => {
      let formItemFormatter = this.formItem.getFormatter(formatterType);
      formFormatters.push(
        this.formBuilder.group({
          type: [formatterType.toString(), [Validators.required]],
          value: [this.formItem.parseFormatterTypeValue(formatterType, formItemFormatter?.value) || null, []],
        }),
      );
    });

    return formFormatters;
  }

  public getFormatterFormGroup(formatterType: FormDataFormatterTypeEnum): UntypedFormGroup {
    return this.getControlArray("formatters").controls.find(
      (control: UntypedFormGroup) => control.value?.type === formatterType.toString(),
    ) as UntypedFormGroup;
  }

  // private buildFormLabels(): UntypedFormGroup {
  //   let formLabels = this.formBuilder.group({}),
  //     languages = this.languages;

  //   languages.forEach((language: string) => {
  //     let formItemLabel = this.formItem.getLabel(language);
  //     formLabels.addControl(
  //       language,
  //       new UntypedFormControl(
  //         formItemLabel || (language === this.i18nService.language ? this.formItem.label : null),
  //         [],
  //       ),
  //     );
  //   });

  //   return formLabels;
  // }

  // private buildFormDescriptions(): UntypedFormGroup {
  //   let formDescriptions = this.formBuilder.group({}),
  //     languages = this.languages;

  //   languages.forEach((language: string) => {
  //     let formItemDescription = this.formItem.getDescription(language);
  //     formDescriptions.addControl(
  //       language,
  //       new UntypedFormControl(
  //         formItemDescription || (language === this.i18nService.language ? this.formItem.description : null),
  //         [],
  //       ),
  //     );
  //   });

  //   return formDescriptions;
  // }

  private buildFormPlaceholders(): UntypedFormGroup {
    let formPlaceholders = this.formBuilder.group({}),
      languages = this.languages;

    languages.forEach((language: string) => {
      let formItemPlaceholder = this.formItem.getPlaceholder(language);
      formPlaceholders.addControl(language, new UntypedFormControl(formItemPlaceholder || null, []));
    });

    return formPlaceholders;
  }

  private buildFormControlTexts(): UntypedFormGroup {
    let formControlTexts = this.formBuilder.group({}),
      languages = this.languages;

    languages.forEach((language: string) => {
      let formItemControlTextValueTrue = this.formItem.getControlTextValue(language, "true"),
        formItemControlTextValueFalse = this.formItem.getControlTextValue(language, "false");

      formControlTexts.addControl(
        language,
        this.formBuilder.group({
          true: [formItemControlTextValueTrue || null, []],
          false: [formItemControlTextValueFalse || null, []],
        }),
      );
    });

    return formControlTexts;
  }

  buildPreviewForm() {
    this.previewForm = this.formBuilder.group({
      preview: this.formBuilder.group({}),
    });
  }

  generateFormItemPreview() {
    let { label, description, properties } = this.form.value as FormItemPropertiesFormValue;
    let formItem = new RecordMessageFormItemModel();
    formItem._uniqueId = this.formItem.uniqueId;
    formItem.label = label;
    formItem.description = description;
    formItem.format = this.formItem.format;
    formItem.properties = properties;

    this.formItemPreview = formItem;
  }

  /**
   * Form groups & form arrays getters
   */
  get formPropertiesControlGroup() {
    return this.form.get("properties") as UntypedFormGroup;
  }

  public getControlGroup(groupName: string): UntypedFormGroup {
    return this.formPropertiesControlGroup.get(groupName) as UntypedFormGroup;
  }

  public getControlArray(arrayName: string): UntypedFormArray {
    return this.formPropertiesControlGroup.get(arrayName) as UntypedFormArray;
  }

  public newFormOption(label?: string, value?: string) {
    return this.formBuilder.group({
      label: [label || null, [Validators.required]],
      // value: [value || null, [Validators.required]],
    });
  }

  public addFormOption() {
    this.getControlArray("options").push(this.newFormOption());
  }

  public deleteFormOption(index: number) {
    this.getControlArray("options").removeAt(index);
  }

  /**
   * UI helpers
   */
  displayFormControl(propertyType: string) {
    return this.formItem.getControlProperties().includes(propertyType);
  }

  get displayAdvancedTab() {
    return this.formItem.getControlFormatters().length > 0;
  }

  get getRenamedExample() {
    let renamingPattern = this.getFormatterFormGroup(FormDataFormatterTypeEnum.FILE_RENAMING)?.value?.value;
    if (renamingPattern) {
      return FileHelper.formatFilename(renamingPattern, {
        extension: "pdf",
        item_label: this.form.value?.label || this.formItem.getLabel(this.i18nService.language) || this.formItem.label,
        uploader_name_or_email: this.sessionAPIService.currentUser.toString(),
        conversation_title: this.recordTitle || "record.title",
      });
    }
    return "default example";
  }

  displayFormatterFormControl(formatterType: FormDataFormatterTypeEnum) {
    return this.formItem.getControlFormatters().includes(formatterType);
  }

  handleChange(event) {
    console.log("control change", event);
  }

  /**
   * Controls options getters
   */
  setControlOptions() {
    switch (this.formItem.format) {
      case DataTypeFormatEnum.BOOLEAN:
        this.controlOptions = [
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.checkbox"),
            value: FormDataControlEnum.CHECKBOX,
          },
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.switch"),
            value: FormDataControlEnum.SWITCH,
          },
        ];
        break;
      case DataTypeFormatEnum.CHOICE:
        this.controlOptions = [
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.dropdown"),
            value: FormDataControlEnum.DROPDOWN,
          },
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.radioButtons"),
            value: FormDataControlEnum.RADIO_BUTTONS,
          },
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.listbox"),
            value: FormDataControlEnum.LISTBOX,
          },
        ];
        break;
      case DataTypeFormatEnum.DOCUMENTS:
        this.controlOptions = [
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.button"),
            value: FormDataControlEnum.UPLOAD_BUTTON,
          },
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.dropzone"),
            value: FormDataControlEnum.UPLOAD_DROPZONE,
          },
        ];
        break;
      case DataTypeFormatEnum.MULTICHOICE:
        this.controlOptions = [
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.checkboxes"),
            value: FormDataControlEnum.CHECKBOXES,
          },
          {
            label: this.translateService.instant("MASTERDATA.data-types.controls.listbox"),
            value: FormDataControlEnum.LISTBOX,
          },
        ];
        break;
      default:
        this.controlOptions = null;
        break;
    }
  }

  setImportanceOptions() {
    this.importanceOptions = [
      {
        label: this.translateService.instant("MASTERDATA.data-types.importances." + FormDataImportanceEnum.LOW),
        value: FormDataImportanceEnum.LOW,
      },
      {
        label: this.translateService.instant("MASTERDATA.data-types.importances." + FormDataImportanceEnum.MEDIUM),
        value: FormDataImportanceEnum.MEDIUM,
      },
      {
        label: this.translateService.instant("MASTERDATA.data-types.importances." + FormDataImportanceEnum.HIGH),
        value: FormDataImportanceEnum.HIGH,
      },
    ];
  }

  setCurrencyDisplayOptions() {
    this.currencyDisplayOptions = [
      {
        label: this.translateService.instant("MASTERDATA.data-types.currency-displays." + "symbol"),
        value: "symbol",
      },
      {
        label: this.translateService.instant("MASTERDATA.data-types.currency-displays." + "code"),
        value: "code",
      },
    ];
  }

  setFileAcceptOptions() {
    this.fileAcceptOptions = Object.keys(FileHelper.FILE_TYPES).map((fileType) => {
      return {
        label: this.translateService.instant("MASTERDATA.data-types.file-accept-types." + fileType),
        value: fileType,
      };
    });
  }

  submit() {
    if (this.form.valid) {
      this.onSubmit.emit(this.form.value as FormItemPropertiesFormValue);
    }
  }

  cancel() {
    this.onCancel.emit();
  }
}
