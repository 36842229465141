<ng-container *ngIf="message; else emptyMessage">
  <div
    class="message flex gap-2"
    [ngClass]="{
      'message--signature': message.hasSignature,
      'message--inactive': displaySignatureInactive,
      'message--form': message.hasForm,
      'message--invitation': message.hasInvitation,
      'message--form-answer': message.hasFormAnswer
    }"
    [attr.data-message-sender]="
      sessionAPIService.currentUser && message.isSender(sessionAPIService.currentUser!.userIdentifier!)
        ? 'self'
        : 'other'
    "
  >
    <ui-user-avatar
      *ngIf="
        (message.body && decryptedMessage) ||
        (message?.attachments && message.attachments!.length > 0) ||
        (message?.answers && message.answers!.length > 0) ||
        message?.metadata?.['invitation']
      "
      [showTooltip]="true"
      [size]="32"
      [user]="message.sender"
    ></ui-user-avatar>

    <div class="message__content">
      <div
        *ngIf="
          message.body ||
          (enableSignature && message.hasSignature) ||
          (enableInvitation && message.hasInvitation) ||
          message.hasFormAnswer
        "
        class="message__body"
      >
        <ng-container *ngIf="!displayLoading && !isDecrypting; else loadingTemplate">
          <record-message-decrypted
            [message]="message"
            [decryptedMessage]="decryptedMessage"
            *ngIf="decryptedMessage"
            wrapperClassnames="message__body__html"
          ></record-message-decrypted>
          <!-- Signature actions -->
          <div
            class="flex flex-column gap-2 surface-c border-1 p-2 border-round"
            [ngClass]="{
              'border-300 border-dashed': displaySignatureButton,
              'surface-border': !displaySignatureButton
            }"
            *ngIf="enableSignature && message.hasSignature"
          >
            <span class="font-medium">
              {{
                "RECORD.record-message-signature.label"
                  | translate : { "default": "Invitation to sign documents", count: signatureAttachments?.length }
              }}
            </span>
            <record-message-signature-avatar-group
              [recipients]="signers"
              [message]="message"
              *ngIf="displaySignatureAvatars"
            ></record-message-signature-avatar-group>
            <!-- Error Message -->
            <span class="text-color-secondary" *ngIf="displaySignatureInactive">
              <i class="pi pi-exclamation-circle text-sm"></i>
              <span class="text-sm font-italic">
                <ng-container
                  [ngTemplateOutlet]="
                    message.isSignatureCanceled
                      ? signatureCanceled
                      : message.isSignatureDeclined
                      ? signatureDeclined
                      : message.isSignatureExpired
                      ? signatureExpired
                      : signatureInactive
                  "
                >
                </ng-container>
              </span>
              <ng-template #signatureCanceled>
                {{
                  "RECORD.record-message-signature.errors.canceled"
                    | translate : { "default": "The signature was canceled by the sender" }
                }}
              </ng-template>
              <ng-template #signatureDeclined>
                {{
                  "RECORD.record-message-signature.errors.declined"
                    | translate : { "default": "One or more signers declined this signature request" }
                }}
              </ng-template>
              <ng-template #signatureExpired>
                {{
                  "RECORD.record-message-signature.errors.expired"
                    | translate : { "default": "The signature has expired" }
                }}
              </ng-template>
              <ng-template #signatureInactive>
                {{
                  "RECORD.record-message-signature.errors.inactive"
                    | translate : { "default": "The signature is no longer active" }
                }}
              </ng-template>
            </span>
            <!-- Start signing | Cancel signature buttons -->
            <div
              class="flex flex-column md:flex-row align-items-baseline md:gap-3 md:align-items-center"
              *ngIf="displaySignatureButton || displayCancelSignatureAction"
            >
              <button
                pButton
                class="p-button-sm py-2"
                icon="msr-icon-draw icon-16px hidden md:inline-block"
                type="button"
                [label]="'COMMON.buttons.start-sign' | translate : { 'default': 'Start signature' }"
                *ngIf="displaySignatureButton"
                (click)="doViewSign()"
              ></button>
              <button
                pButton
                type="button"
                style="--border-radius: 1px"
                class="p-button-sm p-button-secondary p-button-text underline mt-2 md:mt-0 p-0"
                [label]="'COMMON.buttons.cancel-sign' | translate : { default: 'Cancel signature' }"
                *ngIf="displayCancelSignatureAction"
                (click)="doWithdrawSign()"
              ></button>
            </div>
          </div>

          <!-- Form actions -->
          <div class="flex align-items-center justify-content-end gap-3" *ngIf="displayAnswerButton">
            <p-button [styleClass]="'p-button-outlined p-button-sm'" type="button" (click)="showAnswerModal()">
              <ng-template pTemplate="content">
                <span class="font-medium">
                  {{ "COMMON.buttons.answer-or-view-asnwers" | translate : { "default": "Answer or view answers" } }}
                </span>
              </ng-template>
            </p-button>
          </div>

          <!-- Form answers -->
          <div class="stack gap-3" *ngIf="message.answers?.length > 0">
            <ng-container *ngFor="let answer of message.answers">
              <div *ngIf="answer.isFormAnswer">
                <!-- Form item answer -->
                <record-form-item-answer
                  [answerValue]="answer"
                  [answerAttachments]="getAnswerAttachments(answer.itemIdentifier)"
                  [formItem]="getFormItem(answer.itemIdentifier)"
                  [record]="record"
                  [wrappedKeys]="wrappedKeys"
                  (onShowAnswerForm)="handleShortcutClick($event)"
                ></record-form-item-answer>
              </div>
            </ng-container>
          </div>

          <!-- Invitation actions -->
          <div class="flex flex-column gap-3" *ngIf="enableInvitation && message.hasInvitation">
            <record-message-invitation-avatar-group
              [recipients]="invitedIdentities"
              [message]="message"
              *ngIf="displayInvitationAvatars"
            ></record-message-invitation-avatar-group>

            <div class="flex justify-content-end gap-2" *ngIf="displayInvitationButtons">
              <!-- Invitation answer buttons -->
              <button
                pButton
                class="p-button-sm p-button-danger"
                type="button"
                [label]="'COMMON.buttons.decline-invitation' | translate : { 'default': 'Decline invitation' }"
                (click)="declineInvitation()"
              ></button>
              <button
                pButton
                class="p-button-sm p-button-success"
                type="button"
                [label]="'COMMON.buttons.accept-invitation' | translate : { 'default': 'Accept invitation' }"
                (click)="acceptInvitation()"
              ></button>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Attachments -->
      <div class="auto-grid" *ngIf="message?.attachments && message.attachments!.length > 0">
        <ng-container *ngFor="let attachment of message.attachments">
          <div class="border-1 border-200 border-round" *ngIf="!message.isFormAnswerAttachment(attachment)">
            <main-file-download
              [isSignable]="message.isSignatureAttachment(attachment.file.fileIdentifier)"
              [file]="attachment.file!"
              [record]="record"
            ></main-file-download>
          </div>
        </ng-container>
      </div>

      <!-- Broadcasted message -->
      <div
        class="message-broadcasted__message"
        *ngIf="message.isBroadcasted && showBroadcasted && !displayLoading && !isDecrypting"
      >
        <i class="material-symbols-rounded">campaign</i>
        <span>{{
          "RECORD-BATCH.record-batch-conversation.messages.message-broadcasted.tooltip"
            | translate : { "default": "This message has been broadcasted to every batch recipients" }
        }}</span>
      </div>

      <!-- Header -->
      <div
        *ngIf="
          (message.body && decryptedMessage) ||
          (message?.attachments && message.attachments!.length > 0) ||
          (message?.answers && message.answers!.length > 0) ||
          (message?.metadata?.['invitation'])
        "
        class="message__header flex flex-column gap-1"
      >
        <div class="message__metadata">
          <ng-container
            *ngIf="sessionAPIService.currentUser && !message.isSender(sessionAPIService.currentUser!.userIdentifier!)"
          >
            <span>{{ message.sender! | getUserFullName }} </span>
            <span>-</span>
          </ng-container>
          <span class="underline-dotted" [delay]="300" [tooltip]="message.createdAt | date : 'medium'">{{
            message.createdAt | date : "shortTime"
          }}</span>
        </div>
      </div>
    </div>

    <!-- Message actions -->
    <div class="message__actions align-self-center" *ngIf="!isLoading && (showMessageMenuItems || enableReply)">
      <button
        pButton
        type="button"
        [pTooltip]="'COMMON.buttons.more-actions' | translate : { 'default': 'More actions' }"
        tooltipStyleClass="generic-tooltip"
        [showDelay]="150"
        class="p-button-sm p-button-secondary p-button-outlined p-0"
        style="--p-button-width: 2rem; --p-button-height: 2rem; --border-radius: 50%"
        (click)="messageMenu.toggle($event)"
        icon="pi pi-ellipsis-v font-bold"
        *ngIf="showMessageMenuItems"
      ></button>
      <button
        pButton
        type="button"
        [pTooltip]="'COMMON.buttons.reply' | translate : { 'default': 'Reply' }"
        class="p-button-sm p-button-secondary p-button-outlined p-0"
        tooltipStyleClass="generic-tooltip"
        style="--p-button-width: 2rem; --p-button-height: 2rem; --border-radius: 50%"
        (click)="handleReplyClick()"
        [showDelay]="150"
        *ngIf="enableReply"
        icon="msr-icon-reply font-bold"
      ></button>
    </div>
  </div>
</ng-container>

<!-- Empty message -->
<ng-template #emptyMessage>
  <span class="text-gray-500">{{ "COMMON.empty-message" | translate : { "default": "No message" } }}</span>
</ng-template>

<!-- Loading message -->
<ng-template #loadingTemplate>
  <div class="flex flex-row gap-2 align-items-center h-full">
    <ui-spinner [size]="20"></ui-spinner>
    <span class="text-gray-500">{{
      "COMPONENTS.record-message.decrypting" | translate : { "default": "Decrypting message..." }
    }}</span>
  </div>
</ng-template>

<!-- Message Menu Actions -->
<p-menu styleClass="generic-actions-menu" [model]="messageMenuItems" [popup]="true" #messageMenu></p-menu>
<!-- Panel for saving a message as a template -->
<p-overlayPanel
  #saveAsTemplatePanel
  styleClass="generic-overlay-panel"
  (onHide)="createdTemplate = undefined"
  [appendTo]="themingService.rootContainerRef"
>
  <ng-template pTemplate="content">
    <save-message-as-template
      [createdTemplate]="createdTemplate"
      [isCreatingTemplate]="isCreatingTemplate"
      [newTemplateName]="record.title"
      (onRetryCreatingNewTemplate)="createdTemplate = undefined"
      (onCreateNewTemplate)="handleCreateNewTemplate($event)"
      (onCancelSaveAsTemplate)="saveAsTemplatePanel.hide()"
    ></save-message-as-template>
  </ng-template>
</p-overlayPanel>
