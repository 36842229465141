import BaseModel from "@shared/models/base.model";
import IdentityModel from "@shared/models/user/identity.model";
import UserUnitModel from "./user-unit.model";

export default class UserModel extends BaseModel {
  // MINIMIZE (default)
  public userIdentifier: string | null = null;
  public userName: string | null = null;

  // USER_DETAILS
  public familyName: string | null = null;
  public givenName: string | null = null;
  public nickName: string | null = null;
  public emailAddress?: string;
  public emailVerified?: boolean;
  public properties: Object = {};
  public locale?: string;
  public avatar?: string;
  public avatarContent?: string;
  public deleteAvatar?: boolean;
  public units: UserUnitModel[] = [];
  public identities: IdentityModel[] = [];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.units && Array.isArray(input.units))
      this.units = input.units.map((unit: any) => new UserUnitModel().deserialize(unit));

    if (input.identities && Array.isArray(input.identities))
      this.identities = input.identities.map((identity: any) => new IdentityModel().deserialize(identity));

    return this;
  }

  get fullName() {
    if (this.givenName || this.familyName) {
      return [this.givenName, this.familyName].join(" ");
    } else {
      return null;
    }
  }

  get mainIdentity() {
    return this.identities.find((i: IdentityModel) => i.isMain);
  }

  toString() {
    return this.fullName || this.emailAddress;
  }

  toAvatarString() {
    return this.fullName?.toLowerCase() || this.emailAddress?.toLowerCase();
  }
}
