export enum ResponseLevelEnum {
  ALL = "ALL",
  MINIMIZE = "MINIMIZE",
  NOTHING = "NOTHING",
  PAGING = "PAGING",

  /**
   * Configuration related levels
   */
  CONFIGURATION_DETAILS = "CONFIGURATION_DETAILS",

  /**
   * Data type related levels
   */
  DATATYPE_DETAILS = "DATATYPE_DETAILS",

  /**
   * Domain related levels
   */
  DOMAIN_DETAILS = "DOMAIN_DETAILS",

  /**
   * Record related levels
   */
  RECORD_DETAILS = "RECORD_DETAILS",
  RECORD_TAGS = "RECORD_TAGS",
  RECORD_EVENTS = "RECORD_EVENTS",
  RECORD_MESSAGES = "RECORD_MESSAGES",
  RECORD_ATTACHMENTS = "RECORD_ATTACHMENTS",
  RECORD_MESSAGE_FORM_ITEMS = "RECORD_MESSAGE_FORM_ITEMS",
  RECORD_MESSAGE_METADATA = "RECORD_MESSAGE_METADATA",
  RECORD_MESSAGE_PARENT_MESSAGE_IDENTIFIER = "RECORD_MESSAGE_PARENT_MESSAGE_IDENTIFIER",
  RECORD_BATCH_DETAILS = "RECORD_BATCH_DETAILS",
  RECORD_REMINDERS = "RECORD_REMINDERS",

  RECORD_RECIPIENT_METADATA = "RECORD_RECIPIENT_METADATA",
  RECORD_CURRENT_RECIPIENT_METADATA = "RECORD_CURRENT_RECIPIENT_METADATA",

  RECORD_ANSWER_ATTACHMENTS = "RECORD_ANSWER_ATTACHMENTS",
  RECORD_ANSWER_FORM_ITEM = "RECORD_ANSWER_FORM_ITEM",
  /**
   * Template related levels
   */
  TEMPLATE_CONTENT = "TEMPLATE_CONTENT",

  /**
   * Record message template related levels
   */
  RECORD_MESSAGE_TEMPLATE_DETAILS = "RECORD_MESSAGE_TEMPLATE_DETAILS",

  /*
    Unit related levels
  */
  UNIT_DETAILS = "UNIT_DETAILS",
  UNIT_PARENT = "UNIT_PARENT",
  UNIT_CHILDREN = "UNIT_CHILDREN",
  UNIT_BILLING_ACCOUNT = "UNIT_BILLING_ACCOUNT",

  /*
    User related levels
  */
  USER_DETAILS = "USER_DETAILS",
}
