import { SubscriptionCollectionMethodEnum } from "@app/@shared/enums/billing/subscription-collection-method.enum";
import { SubscriptionStatusEnum } from "@app/@shared/enums/billing/subscription-status.enum";
import BaseModel from "@shared/models/base.model";
import DiscountModel from "./discount.model";

export default class SubscriptionModel extends BaseModel {
  // MINIMIZE (default)
  public id: string | null = null;
  public billingCycleAnchor: number | null = null;
  public cancelAt: number | null = null;
  public cancelAtPeriodEnd: boolean;
  public canceledAt: number | null = null;
  public collectionMethod: SubscriptionCollectionMethodEnum;
  public created: number | null = null;
  public currency: string | null = null;
  public currentPeriodEnd: number | null = null;
  public currentPeriodStart: number | null = null;
  public daysUntilDue: number | null = null;
  public discount: DiscountModel;
  public endedAt: number | null = null;
  public startDate: number | null = null;
  public status: SubscriptionStatusEnum;
  public trialEnd: number | null = null;
  public trialStart: number | null = null;
  public metadata: JsonValue = {};

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.discount) this.discount = new DiscountModel().deserialize(input.discount);

    return this;
  }

  get active(): boolean {
    return [
      SubscriptionStatusEnum.ACTIVE,
      SubscriptionStatusEnum.INCOMPLETE,
      SubscriptionStatusEnum.INCOMPLETE_EXPIRED,
      SubscriptionStatusEnum.PAST_DUE,
      SubscriptionStatusEnum.TRIALING,
      SubscriptionStatusEnum.UNPAID,
    ].includes(this.status);
  }

  get canceled(): boolean {
    return this.status === SubscriptionStatusEnum.CANCELED;
  }

  get isBeingCanceled(): boolean {
    return Boolean(this.cancelAt) || Boolean(this.cancelAtPeriodEnd);
  }

  get willBeCanceledAt(): number {
    return this.cancelAt || (this.cancelAtPeriodEnd ? this.currentPeriodEnd : null);
  }
}
