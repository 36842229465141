import { Uploader } from "ngx-uploadx";
import { CryptoHelper } from "../helpers/crypto.helper";

export class FilesUploader extends Uploader {
  hashes: string[] = [];
  offset = 0;
  response = "";
  responseStatus = 200;

  async getFileUrl(): Promise<string> {
    return this.endpoint;
  }

  async getOffset(): Promise<number> {
    return this.offset;
  }

  async sendFileContent(): Promise<number> {
    const { end, body } = this.getChunk();

    const fileIdentifier: string = this.metadata["fileIdentifier"]?.toString();
    const chunkHash = await CryptoHelper.blobToHexHash(body);

    const headers = {
      "Content-Type": "application/octet-stream",
      "Content-Range": `bytes ${this.offset}-${end - 1}/${this.size}`,
      "FileIdentifier": fileIdentifier,
      "Chunk-Hash": chunkHash,
    };

    await this.request({
      method: "POST",
      url: this.url,
      headers,
      body: body,
    });
    this.metadata["hashes"] = this.getHashFromResponse();
    return this.getOffsetFromResponse();
  }

  private getOffsetFromResponse(): number {
    if (this.responseHeaders && this.responseStatus === 200) {
      let range: number = +this.responseHeaders["range"];
      if (isNaN(range)) {
        throw new Error("Range is missing!");
      }

      this.offset = range + 1;
    }

    return this.offset;
  }

  private getHashFromResponse(): string[] {
    if (this.responseHeaders && this.responseStatus === 200) {
      let hash: string = this.responseHeaders["chunk-hash"];
      this.hashes.push(hash);
    }

    return this.hashes;
  }

  // abort not supported
  abort(): void {}
}
