import { Pipe, PipeTransform } from "@angular/core";
import { DateHelper } from "@shared/helpers/date.helper";
import { I18nService } from "@shared/i18n/i18n.service";

@Pipe({
  name: "humanDatePipe",
})
export class HumanDatePipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: Date) {
    return DateHelper.humanizeDate(value, this.i18nService.language);
  }
}
