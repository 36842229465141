import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { Logger } from "@app/@core";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmationService } from "primeng/api";
import { BehaviorSubject, Observable } from "rxjs";
import { Location } from "@angular/common";

const log = new Logger("NavigationService");

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  // session observable
  private _preventNavigation$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  preventNavigation$: Observable<boolean> = this._preventNavigation$.asObservable();

  get preventNavigation(): boolean {
    return this._preventNavigation$.getValue();
  }
  set preventNavigation(preventNavigation: boolean) {
    this._preventNavigation$.next(preventNavigation);
  }

  private history: string[] = [];
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private location: Location,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  /**
   * Redirects to the specified URI
   * Useful when trying to reload a route, as navigation to the same route does not reload the state
   * @param uri the URI to redirect to, accepts the same parameters as router.navigate function
   */
  redirectTo(commands: any[], extras?: NavigationExtras) {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate(commands, extras));
  }

  /**
   * Launch a confirm dialog that warns the user to prevent navigation
   * @returns an observable determining whether the user has accepted the confirm dialog or not
   */
  launchPreventNavigationDialog$(): Observable<boolean> {
    const accepted$ = new BehaviorSubject<boolean>(undefined);

    this.confirmationService.confirm({
      header: this.translateService.instant("COMMON.prevent-navigation.title", {
        default: "Are you sure ?",
      }),
      message: this.translateService.instant("COMMON.prevent-navigation.message", {
        default: "Are you sure you want to leave this page ?",
      }),
      blockScroll: true,
      acceptLabel: this.translateService.instant("COMMON.prevent-navigation.buttons.yes", { default: "Stay" }),
      acceptButtonStyleClass: "p-button-sm px-4",
      rejectLabel: this.translateService.instant("COMMON.prevent-navigation.buttons.no", { default: "Leave" }),
      rejectButtonStyleClass: "p-button-outlined p-button-sm",
      acceptIcon: "hidden",
      rejectIcon: "hidden",
      closeOnEscape: true,
      dismissableMask: true,

      accept: () => {
        accepted$.next(true);
      },
      reject: () => {
        accepted$.next(false);
      },
    });

    return accepted$.asObservable();
  }

  /**
   * Go back in the history
   * @param fallbackURL URL to return to when there's nothing in the history
   */
  back(fallbackURL: string = "/"): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallbackURL);
    }
  }
}
