import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { InboxFilterTypeEnum } from "@app/@shared/enums/inbox-filter-type.enum";
import InboxFiltersResultModel from "@app/@shared/models/inbox/inbox-filters-result.model";
import InboxTagModel from "@app/@shared/models/inbox/inbox-tag.model";
import { InboxAPIService } from "@app/@shared/services/inbox-api.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, map } from "rxjs";
import { Listbox } from "primeng/listbox";
@UntilDestroy()
@Component({
  selector: "tags-form",
  templateUrl: "./tags-form.component.html",
  styleUrls: ["./tags-form.component.scss"],
})
export class TagsFormComponent implements OnInit, OnDestroy, OnChanges {
  _tags: string[];
  @Input() isTagsPanelOpen = false;
  @Output() tagsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @ViewChild("tagsListbox") tagsListbox: Listbox;

  @Input()
  set tags(tags: string[]) {
    this._tags = tags;
    this.tagsChange.emit(tags);
  }
  get tags(): string[] {
    return this._tags;
  }
  // Tags used for autocomplete
  get filteredTagSuggestions() {
    let items = this.tagsList$.value.filter(({ label }) => !this.tags.includes(label));
    return items;
  }
  @Input() isEditing: boolean = false;
  MOST_USED_COUNT = 5;
  displayCreateModal: boolean = false;
  tagsList$: BehaviorSubject<InboxTagModel[]> = new BehaviorSubject([]);
  isLoadingTagsList$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(public sessionAPIService: SessionAPIService, private inboxAPIService: InboxAPIService) {
    this.isLoadingTagsList$.next(true);
    this.inboxAPIService
      .getFilters([InboxFilterTypeEnum.TAGS])
      .pipe(
        map((result: InboxFiltersResultModel) => result.tags),
        untilDestroyed(this),
      )
      .subscribe((items: InboxTagModel[]) => {
        let tags = items.sort((a: InboxTagModel, b: InboxTagModel) => b.count - a.count);
        this.tagsList$.next(tags);
        this.isLoadingTagsList$.next(false);
      });
  }

  ngOnInit() {}

  // Autofocus the tag field once panel displayed
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isTagsPanelOpen && changes.isTagsPanelOpen.currentValue) {
      setTimeout(() => {
        let inputElement = document.querySelector("#tagsFormInput") as HTMLInputElement;
        if (inputElement) {
          inputElement.focus();
        }
      }, 300);
    }
  }

  ngOnDestroy() {}

  handleTagAdded(event: any) {
    if (event.value) {
      let inboxTag = Object.assign(new InboxTagModel(), { label: event.value, count: Infinity });
      this.tagsList$.next([inboxTag, ...this.tagsList$.value]);
    }
    this.tagsListbox.filterOptions.reset();
    this.tagsListbox._filterValue = "";
  }
  handleCreateNewTag(tag: string) {
    if (tag) {
      let newTags = [tag, ...this.tags];
      let uniqueTags = [...new Set(newTags)];
      this.tags = uniqueTags;

      let inboxTag = Object.assign(new InboxTagModel(), { label: tag, count: Infinity });
      this.tagsList$.next([inboxTag, ...this.tagsList$.value]);
      this.tagsListbox.filterOptions.reset();
      this.tagsListbox._filterValue = "";
      // Clear chips iput
      let inputElement = document.querySelector("#tagsFormInput") as HTMLInputElement;
      if (inputElement) {
        inputElement.value = "";
      }
    }
  }
}
