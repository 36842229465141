<div class="dropzone-wrapper flex flex-column flex-grow-1">
  <!-- Dropzone element -->
  <div
    uploadDrop
    (filesDropped)="handleFilesDropped($event)"
    class="dropzone flex flex-column gap-3 justify-content-center align-items-center"
  >
    <input
      #uploadInput
      [id]="id"
      type="file"
      [accept]="htmlAccept"
      [multiple]="multiple ? multiple : undefined"
      (change)="handleChange($event)"
    />
    <div class="flex-grow-1 flex flex-column justify-content-center py-1">
      <i class="pi pi-paperclip text-3xl text-primary"></i>
      <label [for]="id" class="dropzone__title">
        <div class="flex gap-1 align-items-center justify-content-center">
          <span *ngIf="enableFoldersUpload" class="font-normal">
            {{
              title ||
                ("COMPONENTS.file-upload.title.desktop"
                  | translate : { "default": "Click here to add files or drag them here" })
            }}
          </span>
          <span *ngIf="!enableFoldersUpload" class="font-normal">
            {{
              title ||
                ("COMPONENTS.file-upload.title.mobile"
                  | translate : { "default": "Click here to add files or drag them here" })
            }}
          </span>
        </div>
      </label>
    </div>
  </div>
  <!-- Other file upload triggers -->
  <footer class="flex flex-column sm:flex-row flex-wrap gap-2 mt-3">
    <!-- 🖋️ Signature Upload -->
    <div class="hidden-input-field" *ngIf="displaySignatureTrigger">
      <input
        #uploadSignatureInput
        [id]="id + 'signature-upload'"
        type="file"
        [accept]="signatureAuthorizedFileTypes"
        [multiple]="multiple ? multiple : undefined"
        (change)="handleChange($event, false, true)"
        data-attr="file-upload-signature"
      />
      <label [for]="id + 'signature-upload'" class="p-button p-button-sm p-button-outlined p-button-secondary">
        <div class="flex w-full align-items-center">
          <i class="msr-icon-draw font-light mr-2" style="font-size: 17.5px"></i>
          <span class="flex-grow-1">
            {{ "COMPONENTS.file-upload.buttons.signature" | translate }}
          </span>
        </div>
      </label>
    </div>
    <!-- 📂 FOLDER -->
    <div class="hidden-input-field" *ngIf="foldersUploadSupported && enableFoldersUpload">
      <input
        #uploadFolderInput
        type="file"
        [id]="id + 'directory'"
        [accept]="htmlAccept"
        [multiple]="multiple ? multiple : undefined"
        class="sr-only"
        (change)="handleChange($event, true)"
        data-attr="file-upload-folder"
        [attr.directory]="true"
        [attr.webkitdirectory]="true"
        [attr.mozdirectory]="true"
        [attr.msdirectory]="true"
        [attr.odirectory]="true"
      />
      <label [for]="id + 'directory'" class="p-button p-button-sm p-button-outlined p-button-secondary">
        <div class="flex w-full align-items-center">
          <i class="pi pi-folder mr-2"></i>
          <span class="flex-grow-1">
            {{ "COMPONENTS.file-upload.buttons.folders" | translate : { "default": "Add folders" } }}
          </span>
        </div>
      </label>
    </div>
    <!-- 🌥️ Nestor Drive -->
    <button
      (click)="handleCloudUpload()"
      pButton
      type="button"
      icon="pi pi-cloud text-xl"
      class="p-button-sm p-button-outlined p-button-secondary"
      data-attr="file-upload-cloud"
      [label]="'COMPONENTS.file-upload.buttons.cloud' | translate : { 'default': 'Nestor Drive' }"
      *ngIf="displayOtherProviders"
    ></button>
    <!-- 🌥️ Onedrive Drive  -->
    <button
      *ngIf="displayOtherProviders && authorizationService.canAccessExternalDrive()"
      (click)="handleProviderUpload()"
      pButton
      type="button"
      icon="pi pi-cloud text-xl"
      class="p-button-sm p-button-outlined p-button-secondary"
      data-attr="file-upload-provider"
      [label]="'COMPONENTS.file-upload.buttons.provider' | translate : { 'default': 'Others...' }"
    ></button>
  </footer>
</div>
<div
  class="mt-5 stack-grid gap-3 scrollable"
  style="--scrollable-max-height: 25rem"
  *ngIf="uploads.length > 0 || cloudFiles.length"
>
  <main-files-upload-list
    [addHeightConstraints]="false"
    [disabled]="disabled"
    [isUploading]="isUploading"
    [uploads]="uploads"
    [signatureUploadIds]="signatureUploadIds"
    [displaySignatureTrigger]="displaySignatureTrigger"
    (onSignatureUploadIdsChange)="handleSignatureUploadIdsChange($event)"
    *ngIf="uploads.length > 0"
    [variant]="uploadListVariant"
    (onRemove)="handleRemove($event)"
  ></main-files-upload-list>
  <main-cloud-file-upload-list
    [addHeightConstraints]="false"
    [disabled]="disabled"
    [isUploading]="isUploading"
    [cloudFiles]="cloudFiles"
    [signatureResources]="signatureResources"
    [displaySignatureTrigger]="displaySignatureTrigger"
    (onSignatureResourcesChange)="handleSignatureResourcesChange($event)"
    *ngIf="cloudFiles.length > 0"
    [variant]="uploadListVariant"
    (onRemove)="handleCloudFileRemove($event)"
  ></main-cloud-file-upload-list>
</div>
