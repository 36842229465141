import { AbstractControl, ValidationErrors } from "@angular/forms";
import { internationalPhoneNumberValidator } from "./international-phone-number.validator";
import { smtpoHostnameValidator } from "./smtp-hostname.validator";

export class SharedValidators {
  /**
   * Validates an international phone number (must contain prefix to be parsed & valid)
   * Can be used in form groups as SharedValidators.internationalPhoneNumber
   * @param control
   * @returns
   */
  static internationalPhoneNumber(control: AbstractControl): ValidationErrors | null {
    return internationalPhoneNumberValidator(control);
  }

  /**
   * Validates an IP address or a hostname for SMTP config
   * Can be used in form groups as SharedValidators.smtpHostname
   * @param control
   * @returns
   */
  static smtpHostname(control: AbstractControl): ValidationErrors | null {
    return smtpoHostnameValidator(control);
  }
}
