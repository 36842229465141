<div class="flex gap-2" [ngClass]="{ 'flex-column': orientation === 'horizontal' }">
  <p-slider
    [name]="name"
    [id]="inputId"
    [title]="title"
    [disabled]="disabled"
    [min]="min"
    [max]="max"
    [step]="step"
    [range]="true"
    [orientation]="orientation"
    [animate]="true"
    [ngModel]="value"
    (ngModelChange)="handleChange($event)"
    (onChange)="handleChange($event)"
  ></p-slider>

  <div
    class="flex justify-content-between font-medium"
    [ngClass]="{ 'flex-column-reverse': orientation === 'vertical' }"
    *ngIf="value"
  >
    <span>{{ lowerBound }}</span>
    <span>{{ higherBound }}</span>
  </div>
</div>
