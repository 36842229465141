import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { from, groupBy, map, mergeMap, Observable, reduce, toArray } from "rxjs";
import { UntilDestroy, untilDestroyed } from "@core";
import DataTypeModel from "@app/@shared/models/masterdata/data-type.model";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import { TreeNode } from "primeng/api";
import { isEqual, sortBy } from "lodash";
import { Tree } from "primeng/tree";

@UntilDestroy()
@Component({
  selector: "record-data-types-list",
  templateUrl: "./data-types-list.component.html",
  styleUrls: ["./data-types-list.component.scss"],
})
export class RecordDataTypesListComponent implements OnInit, OnChanges {
  @ViewChild(Tree) tree: Tree;

  @Input() dataTypes: DataTypeModel[] = [];

  @Output() onSelect: EventEmitter<DataTypeModel> = new EventEmitter<DataTypeModel>();

  dataTypesTree$: Observable<{ label: string; data: string; children: any[] }[]>;
  previouslySelectedNode: TreeNode;
  selectedNode: TreeNode;

  constructor(private i18nService: I18nService) {}

  ngOnInit(): void {
    // Autofocus filter input
    setTimeout(() => {
      let inputElement = document.querySelector(".p-tree-filter.p-inputtext") as HTMLInputElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 150);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTypes) {
      this.dataTypesTree$ = from(this.dataTypes).pipe(
        groupBy((dataType: DataTypeModel) => dataType.group),
        mergeMap((group) =>
          group.pipe(
            reduce(
              (acc, cur) => {
                let item = {
                  key: `${acc.key}-${cur.dataTypeClass}`,
                  label: cur.getLabel(this.i18nService.language) || cur.label,
                  data: cur,
                  type: "dataType",
                  tags: cur.keywords,
                };
                acc.children.push(item);
                return acc;
              },
              {
                key: group.key,
                label: group.key,
                data: group.key,
                children: [],
              },
            ),
            map((group) => {
              return {
                ...group,
                children: sortBy(group.children, "label"),
                expanded: true, // Uncollapse all tree groups
              };
            }),
          ),
        ),
        toArray(),
        map((groups) =>
          groups.sort((a, b) => {
            if (a.data === "CUSTOM") {
              return -2;
            } else if (a.data === undefined) {
              return -1;
            } else if (b.data === undefined) {
              return Infinity;
            } else {
              return ("" + a.data).localeCompare(b.data);
            }
          }),
        ),
        untilDestroyed(this),
      );
    }
  }

  handleNodeSelect(event) {
    if (event.node.type === "dataType") {
      this.previouslySelectedNode = event.node;
      this.onSelect.emit(event.node.data);
    } else {
      this.selectedNode = this.previouslySelectedNode;
    }
  }

  handleNodeUnselect(event) {}
}
