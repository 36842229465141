import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { I18nService } from "@app/@shared/i18n/i18n.service";
import RecordMessageFormItemModel from "@app/@shared/models/record/record-message-form-item.model";
import { v4 as v4 } from "uuid";

@Component({
  selector: "record-form-item-format-boolean-checkbox",
  templateUrl: "./item-format-boolean-checkbox.component.html",
  styleUrls: ["./item-format-boolean-checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecordFormItemFormatBooleanCheckboxComponent),
      multi: true,
    },
  ],
})
export class RecordFormItemFormatBooleanCheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formItem: RecordMessageFormItemModel;
  @Input() previewMode: boolean = false;

  // Checkbox options
  @Input() label: string;

  // HTML5
  @Input() name: string = v4();
  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>(); // (onInput) event for input mask, (onChange) event for input text

  control: UntypedFormControl;
  parentFormGroup: UntypedFormGroup;
  value: boolean = false;
  onModelChange: Function = () => {};
  onModelTouched: Function = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private controlContainer: ControlContainer,
    private i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this.initControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formItem || changes.previewMode) {
      this.initControl();
    }
  }

  writeValue(value: any): void {
    if (value == null) {
      value = false;
    }
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  initControl() {
    this.parentFormGroup = this.controlContainer.control as UntypedFormGroup;
    if (this.previewMode) {
      this.control = this.formBuilder.control(null, []);
      this.parentFormGroup.addControl(this.formItem.uniqueId, this.control);
    }
    this.updateControlConfiguration();
  }

  updateControlConfiguration() {
    this.inputId = this.formItem.uniqueId;
    this.title = this.formItem.label;
    this.name = this.formItem.uniqueId;
    this.label = this.formItem.label;
  }

  get controlTextValues(): { [x: string]: string } {
    if (this.formItem && this.formItem.getControlTexts()) {
      return this.formItem.getControlTextValues(this.i18nService.language);
    }

    return undefined;
  }

  get controlTextValue(): string {
    if (this.controlTextValues) {
      let value = this.formItem.getControlTextValue(this.i18nService.language, this.value.toString());
      return value || this.label;
    }

    return undefined;
  }

  handleChange(event) {
    if (event) {
      this.onModelTouched();
      this.onModelChange(event.checked);
      this.writeValue(event.checked);
      this.onChange.emit(event.checked);
    }
  }
}
