import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";

export default class RecordMessageAnswerContentSignatureModel extends BaseModel {
  public identityIdentifier: string;
  public recipientSigned: boolean;
  public recipientSignedAt: Date;
  public recipientDeclined: boolean;
  public recipientDeclinedAt: Date;
  public recipientError: boolean;
  public recipientErrorAt: Date;

  public completed: boolean;
  public completedAt: Date;
  public expired: boolean;
  public expiredAt: Date;
  public declined: boolean;
  public declinedAt: Date;
  public error: boolean;
  public errorAt: Date;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.recipientSignedAt) this.recipientSignedAt = DateHelper.parseDate(input.recipientSignedAt);
    if (input.recipientDeclinedAt) this.recipientDeclinedAt = DateHelper.parseDate(input.recipientDeclinedAt);
    if (input.recipientErrorAt) this.recipientErrorAt = DateHelper.parseDate(input.recipientErrorAt);

    if (input.completedAt) this.completedAt = DateHelper.parseDate(input.completedAt);
    if (input.expiredAt) this.expiredAt = DateHelper.parseDate(input.expiredAt);
    if (input.declinedAt) this.declinedAt = DateHelper.parseDate(input.declinedAt);
    if (input.errorAt) this.errorAt = DateHelper.parseDate(input.errorAt);

    return this;
  }
}
