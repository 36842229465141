import { MessageInvitationStatusEnum } from "@app/@shared/enums/message-invitation-status.enum";
import { DateHelper } from "@app/@shared/helpers/date.helper";
import BaseModel from "../base.model";

export default class RecordMessageAnswerContentInvitationModel extends BaseModel {
  public identityIdentifier: string;
  public status: MessageInvitationStatusEnum;
  public statusUpdatedAt: Date;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.statusUpdatedAt) this.statusUpdatedAt = DateHelper.parseDate(input.statusUpdatedAt);

    return this;
  }
}
