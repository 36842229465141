import RecordModel from "@shared/models/record/record.model";
import PaginationModel from "@shared/models/pagination.model";

export default class RecordsResultModel extends PaginationModel {
  public records: RecordModel[];

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.records && Array.isArray(input.records))
      this.records = input.records.map((record: any) => new RecordModel().deserialize(record));

    return this;
  }
}
