import BaseModel from "@shared/models/base.model";
import UserModel from "../user/user.model";

export default class UnitUserModel extends BaseModel {
  // MINIMIZE (default)
  public userRole?: string;
  public user: UserModel | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.user) this.user = new UserModel().deserialize(input.user);

    return this;
  }

  get userIdentifier() {
    return this.user?.userIdentifier;
  }
}
