import { Component, forwardRef, Input, OnInit, SkipSelf, ViewChild } from "@angular/core";
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CountryISO, NgxIntlTelInputComponent, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input-gg";
import { v4 as v4 } from "uuid";

@Component({
  selector: "ui-phone-input",
  templateUrl: "./phone-input.component.html",
  styleUrls: ["./phone-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new SkipSelf(), ControlContainer]],
    },
  ],
  host: {
    "[class.readonly]": "readonly",
  },
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild(NgxIntlTelInputComponent, { static: false }) telInput!: NgxIntlTelInputComponent;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  @Input() inputId: string = v4();
  @Input() title: string = "";
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() cssClass: string = "";
  @Input() separateDialCode: boolean = true;
  @Input() phoneValidation: boolean = true;
  @Input() maxLength: number = 15;
  @Input() enablePlaceholder: boolean = true;
  @Input() customPlaceholder: string = "";
  @Input() selectedCountryISO: CountryISO = CountryISO.France;
  @Input() formControlName: string;

  /**
   * Component variables
   */
  value: string | null = null;

  onChange: any = () => {};
  onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
    this.telInput?.setDisabledState(disabled);
  }
}
