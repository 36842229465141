<div class="align-items-center flex" *ngIf="showCreateMenu">
  <p-button
    type="button"
    styleClass="p-button-sm p-button-outlined p-button-rounded w-2rem h-2rem md:w-auto md:h-auto md:block flex align-items-center justify-content-center"
    (click)="mainMenu.toggle($event)"
  >
    <ng-template pTemplate="content">
      <div class="flex">
        <i class="pi pi-plus"></i>
        <span class="font-medium ml-1 hidden lg:inline-block">
          {{ "COMMON.buttons.create" | translate : { "default": "Create" } }}
        </span>
      </div>
    </ng-template>
  </p-button>
  <p-menu
    #mainMenu
    appendTo="body"
    [popup]="true"
    [model]="recordMenuItems"
    styleClass="header-new-record-menu"
    [style]="themingService.getCustomStyles()"
  ></p-menu>
</div>
