<div class="flex align-items-center gap-2">
  <p-inputSwitch
    [name]="name"
    [inputId]="inputId"
    [title]="title"
    [disabled]="disabled"
    [readonly]="readonly"
    [ngModel]="value"
    (ngModelChange)="handleChange($event)"
    (onChange)="handleChange($event)"
  ></p-inputSwitch>
  <label [for]="inputId" *ngIf="controlTextValue">{{ controlTextValue }}</label>
</div>
