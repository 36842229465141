import BaseModel from "@shared/models/base.model";
import UnitModel from "../domain/unit.model";

export default class BillingAccountMemberModel extends BaseModel {
  // MINIMIZE (default)
  public unit: UnitModel | null = null;

  constructor() {
    super();
  }

  deserialize(input: any): this {
    Object.assign(this, input);

    if (input.unit) this.unit = new UnitModel().deserialize(input.unit);

    return this;
  }
}
