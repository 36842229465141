import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { MessageFormatEnum } from "@app/@shared/enums/message-format.enum";
import RecordMessageModel from "@app/@shared/models/record/record-message.model";
import WrappedKeyModel from "@app/@shared/models/vault/wrapped-key.model";
import { SafeHtmlPipe } from "@app/@shared/pipes/safe-html.pipe";
import { CryptographyService } from "@app/@shared/services/cryptography.service";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { TranslateService } from "@ngx-translate/core";
import { convert } from "html-to-text";

@Component({
  selector: "record-parent-message",
  templateUrl: "./record-parent-message.component.html",
  styleUrls: ["./record-parent-message.component.scss"],
  providers: [SafeHtmlPipe],
})
export class RecordParentMessageComponent implements OnInit, OnChanges {
  @Input() isLoading: boolean = false;
  @Input() message: RecordMessageModel;
  @Input() parentMessage: RecordMessageModel;
  @Input() wrappedKeys: WrappedKeyModel[];

  @Output() onDecryptionCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<RecordMessageModel> = new EventEmitter<RecordMessageModel>();

  decryptedMessage: string;
  formattedDecryptedMessage: string;
  isDecrypting: boolean = false;

  constructor(
    private translateService: TranslateService,
    private cryptographyService: CryptographyService,
    public sessionAPIService: SessionAPIService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.record || changes.message) {
      this.decrypt();
    }

    if (changes.wrappedKeys) {
      this.decrypt();
    }
  }

  handleClick() {
    this.onClick.emit(this.parentMessage);
  }

  get displayLoading() {
    return (
      Object.values<string>([MessageFormatEnum.ENCRYPTED_TEXT, MessageFormatEnum.ENCRYPTED_HTML]).includes(
        this.parentMessage.format,
      ) &&
      (this.isLoading || this.isDecrypting)
    );
  }

  decrypt() {
    let body = this.parentMessage.body;

    if (body) {
      if (
        [MessageFormatEnum.ENCRYPTED_TEXT, MessageFormatEnum.ENCRYPTED_HTML].includes(this.parentMessage.format) &&
        this.wrappedKeys != null
      ) {
        this.isDecrypting = true;
        this.cryptographyService
          .decryptText(this.wrappedKeys, body)
          .then((decrypted) => {
            this.decryptedMessage = decrypted;
            this.formatDecryptedMessage();
          })
          .finally(() => {
            this.isDecrypting = false;
            this.onDecryptionCompleted.emit();
          });
      } else {
        this.decryptedMessage = body;
        this.formatDecryptedMessage();
      }
    }
  }

  formatDecryptedMessage() {
    if (this.parentMessage.isBotMessage || this.parentMessage.hasAnswers) {
      this.formattedDecryptedMessage = this.translateService.instant(this.decryptedMessage);
    } else {
      this.formattedDecryptedMessage = this.decryptedMessage;

      // this.formattedDecryptedMessage = convert(this.decryptedMessage, {
      //   wordwrap: 230,
      //   limits: { ellipsis: "...", maxBaseElements: 1, maxChildNodes: 5 }
      // });
    }
  }
}
