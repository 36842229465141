import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import RecordRecipientModel from "@app/@shared/models/record/record-recipient.model";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ChannelEnum } from "@app/@shared/enums/channel.enum";
import { RecordRoleEnum } from "@app/@shared/enums/record-role.enum";
import { SessionAPIService } from "@app/@shared/services/session-api.service";
import { CountryISO } from "ngx-intl-tel-input-gg";
import { ParsedPhoneNumber, PhoneHelper } from "@app/@shared/helpers/phone.helper";

type RecipientFormValue = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: { [x: string]: string };
  channel: ChannelEnum;
  role: RecordRoleEnum;
};

@Component({
  selector: "record-recipient-form",
  templateUrl: "./recipient-form.component.html",
  styleUrls: ["./recipient-form.component.scss"],
})
export class RecipientFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() allowSelf: boolean = true;
  @Input() allowOnlyEmail: boolean = true;
  @Input() readonlyEmail: boolean = false;
  @Input() recipient: RecordRecipientModel;
  @Input() isEditingSigner: boolean = false;

  @Output() onSubmit: EventEmitter<RecordRecipientModel> = new EventEmitter<RecordRecipientModel>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  phoneCountryISO: CountryISO = CountryISO.France;

  constructor(private formBuilder: UntypedFormBuilder, private sessionAPIService: SessionAPIService) {}

  ngOnInit() {
    this.buildform();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnDestroy() {}

  private buildform() {
    let parsedPhoneNumber: ParsedPhoneNumber = null;
    if (this.recipient.phoneNumber) {
      try {
        parsedPhoneNumber = PhoneHelper.parseInternationalPhoneNumber(this.recipient.phoneNumber);
        if (parsedPhoneNumber.countryISO) this.phoneCountryISO = parsedPhoneNumber.countryISO;
      } catch (error) {}
    }

    if (this.allowOnlyEmail) {
      this.form = this.formBuilder.group({
        firstName: [this.recipient.firstName],
        lastName: [this.recipient.lastName],
        emailAddress: [
          this.recipient.emailAddress,
          [Validators.required, Validators.email, this.emailNotSelfValidator()],
        ],
        phoneNumber: [parsedPhoneNumber?.nationalNumber ?? null, []],
        channel: [this.recipient.channel, []],
        role: [this.recipient.role, []],
      });
    } else {
      this.form = this.formBuilder.group({
        firstName: [this.recipient.firstName, [Validators.required]],
        lastName: [this.recipient.lastName, [Validators.required]],
        emailAddress: [
          this.recipient.emailAddress,
          [Validators.required, Validators.email, this.emailNotSelfValidator()],
        ],
        phoneNumber: [parsedPhoneNumber?.nationalNumber ?? null, [Validators.required]],
        channel: [this.recipient.channel, []],
        role: [this.recipient.role, []],
      });
    }
  }

  emailNotSelfValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.allowSelf) return null;

      let value = control.value;

      if (this.sessionAPIService.currentUser && this.sessionAPIService.currentUser.emailAddress === value) {
        return { isSelfEmail: true };
      }

      return null;
    };
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      let { emailAddress, firstName, lastName, phoneNumber, channel, role }: RecipientFormValue = this.form
        .value as RecipientFormValue;

      let recipient = new RecordRecipientModel();
      recipient.emailAddress = emailAddress.toLowerCase();
      recipient.firstName = firstName?.trim();
      recipient.lastName = lastName?.trim();
      recipient.phoneNumber = phoneNumber?.e164Number;
      recipient.channel = channel;
      recipient.role = role;

      this.onSubmit.emit(recipient);
    }
  }

  cancel() {
    this.onCancel.emit();
  }
}
